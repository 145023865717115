import React from 'react';
import { withServices } from 'reaf';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { noop } from 'lodash';
import DisplayAssetType from '../DisplayAssetType/DisplayAssetType';
import { CompanyTemplatesPageLink, ProjectDocumentsPageLink } from '../../core/adminLinks/links';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import EditProject from '../EditProject/EditProject';
import EditDictionary from '../EditDictionary/EditDictionary';
import IconButton from '../../core/Button/IconButton';
import WithTooltip from '../../core/WithTooltip/WithTooltip';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import BulkProjectUpload from '../BulkProjectUpload/BulkProjectUpload';
import BulkUpdateProjectUpload from '../BulkUpdateProjectUpload/BulkUpdateProjectUpload';
import BulkProjectJson from '../BulkProjectJson/BulkProjectJson';
import { projectActionValues } from '../../../constants';

const ProjectList = ({ queries, apiClient, router, projectsService }) => {
  const [showBulkProjectUpload, setShowBulkProjectUpload] = React.useState(false);
  const [showBulkProjectUpdate, setShowBulkProjectUpdate] = React.useState(false);
  const [showBulkProjectJson, setShowBulkProjectJson] = React.useState(false);
  const [currentProjectAction, setCurrentProjectAction] = React.useState(projectActionValues[0])
  const {
    data: projects,
    loading,
    pageCount,
    setData: setProjects,
    onDownloadCSV,
    refreshData,
    fetchData: fetchProjects,
  } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    routeName: "superAdminProjectPage",
    resourceEndPoint: "/admin/projects",
    csvDownloadEndPoint: "/admin/projects/downloadCSV",
  });

  const updateProject = React.useCallback((updatedProject) => {
    const index = projects.findIndex(project => project.uuid === updatedProject.uuid);
    const updateProjectsArray = [...projects];
    updateProjectsArray[index] = updatedProject;
    setProjects(updateProjectsArray);
  }, [projects]);

  const onSelectProjectAction = React.useCallback((action) => {
    switch (action.value) {
      case 'showBulkProjectUpload':
        setShowBulkProjectUpload(true);
        break;
      case 'showBulkProjectUpdate':
        setShowBulkProjectUpdate(true);
        break;
      case 'showBulkProjectJson':
        setShowBulkProjectJson(true);
        break;
      default:
        break;
    }
  }, [showBulkProjectUpload, showBulkProjectJson])

  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      fixedWidth: 200
    },
    {
      Header: 'Owner',
      accessor: 'Users.firstName',
      fixedWidth: 160,
      Cell: ({ row: { original: { Users } } }) => (Users && Users[0] ? <WithTooltip tooltipText={Users[0].email}><span>{`${Users[0].firstName} ${Users[0].lastName}`}</span></WithTooltip> : 'Deleted')
    },
    {
      Header: 'Company',
      accessor: 'Company.name',
      fixedWidth: 180,
      Cell: ({ row: { original: { Company } } }) => (Company ? `${Company.name}` : 'Deleted')
    },
    {
      Header: 'Asset',
      accessor: 'assetType',
      fixedWidth: 160,
      Cell: ({ value: assetType }) => <DisplayAssetType AssetType={assetType} />
    },
    {
      Header: 'Template',
      fixedWidth: 180,
      accessor: 'TemplateMapping.templateName',
      Cell: ({ row }) => <CompanyTemplatesPageLink 
        text={row.original.TemplateMapping.templateName} 
        companyUUID={row.original.Company.uuid} 
      />
    },
    {
      Header: 'Created On',
      accessor: 'createdAt',
      disableFilters: true,
      Cell: ({ value }) => <DisplayDate date={value} />
    },
    {
      Header: 'Actions',
      accessor: 'action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { original: project } }) => (
        (
          <div className="d-flex flex-row">
            <ProjectDocumentsPageLink title="List Documents" text="Documents" project={project} />
            <EditProject onEditSuccess={(updatedProject) => updateProject(updatedProject)} project={project} label={<IconButton className="smallIconButton" placement="left" title="Edit Project" icon={faEdit} />} company={project.Company} />
            <EditDictionary onEditSuccess={ noop } project={ project } />
          </div>
        )
      )
    }
  ], [projects]);

  return (
    <>
      {
        showBulkProjectUpload &&
        <BulkProjectUpload
          showModal={showBulkProjectUpload}
          onHide={() => {
            setShowBulkProjectUpload(false)
            setCurrentProjectAction(projectActionValues[0])
          }}
          refreshData={refreshData}
        />
      }
      {
        showBulkProjectUpdate &&
        <BulkUpdateProjectUpload
          showModal={showBulkProjectUpdate}
          onHide={() => setShowBulkProjectUpdate(false)}
          refreshData={refreshData}
        />
      }
      {
        showBulkProjectJson && 
        <BulkProjectJson 
          showModal={showBulkProjectJson}
          projects={projects}
          onHide={() => setShowBulkProjectJson(false)}
          refreshData={refreshData}
        />
      }
      <ReactTable
        columns={columns}
        data={projects}
        title="Projects"
        onSelectProjectAction={onSelectProjectAction}
        projectActionValues={projectActionValues}
        currentProjectAction={currentProjectAction}
        onDownloadCSV={onDownloadCSV}
        queries={queries}
        loading={loading}
        fetchData={fetchProjects}
        pageCount={pageCount}
      />
    </>
  )
};

export default withServices('apiClient', 'router', 'projectsService')(ProjectList);
