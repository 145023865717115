import React from 'react';
import ProjectFormModal from '../ProjectFormModal/ProjectFormModal';
import ProjectInfoFormModal from '../ProjectInfoFormModal/ProjectInfoFormModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import NeedSupportFormModal from '../NeedSupportFormModal/NeedSupportFormModal';
import UpdateProjectFormModal from '../UpdateProjectFormModal/UpdateProjectFormModal';
import UpdateDictionaryModal from '../UpdateDictionaryModal/UpdateDictionaryModal';
import UpdateDDConfirmationMessage from '../UpdateDDConfirmationMessage/UpdateDDConfirmationMessage';
import ShareReportLinkModal from '../ShareReportLinkModal/ShareReportLinkModal';
import ApiFrequencyFormModal from '../ApiFrequencyFormModal/ApiFrequencyFormModal';

function ProjectPopups({
  project, onHide, company, needSupport, showProjectAssumption, showEditProject,
  showUpdateProject, showDeleteConfirm, onDeleteConfirm, showClearTaggingConfirm,
  showUpdateDDConfirm, onUpdateDDConfirm, onClearTaggingConfirm, onEditSuccess,
  showPropertyReportLink, showAddProjectModal, sharedReportLinkDetail, showEditDictionary,
  data, isLoading, setShowEditDictionary, showApiConfigModal, setShowApiConfigModal,
  documentsUuidsForModel, integrationConfigrationUuid, readOnlyAccess
}) {
  return (
    <div>
      {
        showProjectAssumption && <ProjectInfoFormModal project={project} onHide={onHide} show={showProjectAssumption} readOnlyAccess={readOnlyAccess} />
      }

      {
        showUpdateProject && <UpdateProjectFormModal 
          onEditSuccess={onEditSuccess} 
          project={project} 
          show={showUpdateProject} 
          company={company} 
          onHide={onHide} 
        />
      }

      {
        showAddProjectModal && <ProjectFormModal show={showAddProjectModal} onHide={onHide} />
      }
      {
        showApiConfigModal && 
        <ApiFrequencyFormModal 
          show={showApiConfigModal}
          onHide={onHide}
          integrationConfigrationUuid={integrationConfigrationUuid}
          project={project}
          documentsUuidsForModel={documentsUuidsForModel}
        />
      }

      {
        showEditDictionary && 
        <UpdateDictionaryModal 
          show={showEditDictionary} 
          onHide={onHide} 
          data={data}
          onEditSuccess={onEditSuccess}
          project={project}
          isLoading={isLoading}
          setShowEditDictionary={setShowEditDictionary}
        />
      }

      {
        showEditProject &&
        <ProjectFormModal project={project} onHide={onHide} show={showEditProject} />
      }
      {
        needSupport &&
        <NeedSupportFormModal project={project} onHide={onHide} show={needSupport} />
      }

      {
        showDeleteConfirm &&
        (
          <ConfirmationModal
            show={showDeleteConfirm}
            title={`Delete Property ${project.name}?`}
            message={`Are you sure you want to delete the property ${project.name}`}
            onConfirm={onDeleteConfirm}
            onCancel={onHide}
          />
        )
      }
      {
        showPropertyReportLink &&
        (
          <ShareReportLinkModal
            show={showPropertyReportLink}
            sharedReportLinkDetail={sharedReportLinkDetail}
            title={`Report link for  ${project.name}`}
            message={sharedReportLinkDetail.reportLink}
            isConfirm={false}
            isClipboard={showPropertyReportLink}
            onConfirm={onHide}
            onCancel={onHide}
          />
        )
      }
      {
        showUpdateDDConfirm &&
        (
          <ConfirmationModal
            show={showUpdateDDConfirm}
            title="Update document dictionary"
            message={<UpdateDDConfirmationMessage />}
            onConfirm={onUpdateDDConfirm}
            onCancel={onHide}
          />
        )
      }

      {
        showClearTaggingConfirm &&
        (
          <ConfirmationModal
            show={showClearTaggingConfirm}
            title={`Clear Tagging for Property ${project.name}?`}
            message={`Are you sure you want to clear tagging for the property ${project.name}`}
            onConfirm={onClearTaggingConfirm}
            onCancel={onHide}
          />
        )
      }

    </div>
  );
}

export default ProjectPopups;
