import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { activeSubscriptionsListSelector, inActiveSubscriptionsListSelector } from '../../../store/selectors'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { setActiveSubscriptions, setInActiveSubscriptions } from '../../../store/subscriptionsList';


const SubscriptionList = ({ paymentService, activeSubscriptionsList, inActiveSubscriptionsList, store }) => {
  const [currentSubscription, setCurrentSubscription] = useState({});
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const onDeleteConfirm = useCallback(async (subscription) => {
    const {id, name, startDate, endDate, status, object : subscriptionType } = subscription;
    setShowDeleteConfirmPopup(true);
    const deletedSubscription = await paymentService.deactivateSubscriptionViaAdmin(id, status, subscriptionType);

    const updatedActiveSubscriptionList = activeSubscriptionsList.filter(s => s.id !== deletedSubscription.id);
    store.dispatch(setActiveSubscriptions(updatedActiveSubscriptionList));

    deletedSubscription.name = name;
    deletedSubscription.startDate = startDate;
    deletedSubscription.endDate = endDate;

    const updatedInActiveSubscriptionsList = [deletedSubscription, ...inActiveSubscriptionsList];

    store.dispatch(setInActiveSubscriptions(updatedInActiveSubscriptionsList));
    setCurrentSubscription({});
    setShowDeleteConfirmPopup(false);
  }, [activeSubscriptionsList, inActiveSubscriptionsList, paymentService]);

  const onCancelDelete = useCallback(() => {
    setCurrentSubscription({})
    setShowDeleteConfirmPopup(false);
  }, []);

  return (
    <>
      <Tabs
        defaultActiveKey="active-subscriptions"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="active-subscriptions" title="Active Subscriptions">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: '2%' }}>S.No</th>
                <th style={{ width: '30%' }}>Company Name</th>
                <th style={{ width: '20%' }}>Status</th>
                <th style={{ width: '20%' }}>Subscription Start Date</th>
                <th style={{ width: '20%' }}>Subscription End Date</th>
                <th style={{ width: '3%' }}>Delete Subscription</th>
              </tr>
            </thead>
            <tbody>
              {activeSubscriptionsList.map((subscription, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{subscription.name}</td>
                  <td>{subscription.status}</td>
                  <td>{subscription.startDate}</td>
                  <td>{subscription.endDate}</td>
                  <td>
                    <Button
                      variant="outline-danger"
                      className="btn btn-default btn-sm"
                      onClick={e => {
                        setCurrentSubscription(subscription);
                        setShowDeleteConfirmPopup(true);
                      }}
                    >
                      Delete 
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>

        <Tab eventKey="inactive-subscriptions" title="Inactive Subscriptions" >
          <Table striped bordered hover>
            <thead>
              <tr>
                <th style={{ width: '2%' }}>S.No</th>
                <th style={{ width: '30%' }}>Company Name</th>
                <th style={{ width: '20%' }}>Subscription Start Date</th>
                <th style={{ width: '20%' }}>Subscription End Date</th>
              </tr>
            </thead>
            <tbody>
              {inActiveSubscriptionsList.map((subscription, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{subscription.name}</td>
                  <td>{subscription.startDate}</td>
                  <td>{subscription.endDate}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
      {
        showDeleteConfirmPopup && currentSubscription &&
        (
          <ConfirmationModal
            isConfirm
            isDetele={true}
            show={showDeleteConfirmPopup}
            message={`Are you sure you want to delete ${currentSubscription.name} company's Subscription`}
            title="Deactivate Company Subscription"
            onConfirm={() => onDeleteConfirm(currentSubscription)}
            onCancel={onCancelDelete}
          />
        )
      }
    </>
  );
};

const mapStateToProps = state => ({
  activeSubscriptionsList: activeSubscriptionsListSelector(state),
  inActiveSubscriptionsList: inActiveSubscriptionsListSelector(state)
});


export default connect(mapStateToProps)(withServices('paymentService', 'store')(SubscriptionList));



