import Service from './Service';
import messages from '../../locales';

class DictionaryService extends Service {
  constructor(apiClient, store, toastrService) {
    super(apiClient, store);
    this.apiClient = apiClient;
    this.store = store;
    this.toastrService = toastrService;
  }

  async updateProjectDictionary(project, projectDictionaryData, headDictionaryData) {
    try {
        await this.apiClient.put(`/admin/projects/${project.uuid}/updateProjectDictionary`, {
          projectDictionary: projectDictionaryData,
          headDictionary: headDictionaryData
        });
        this.toastrService.success(messages.toastMessage.DICTIONARY_UPDATED);
      } catch(error) {
        console.log(error)
        this.toastrService.error(messages.toastMessage.DICTIONARY_UPDATE_ERROR);
      }
  }

  async getDictionaryData(project) {
    return this.apiClient.get(`/admin/projects/${project.uuid}/dictionary`).then((response) => response?.response);
  }
}

export default DictionaryService;