import React from 'react';
import AccountsExecutiveDashboardPage from '../AccountsExecutiveDashboardPage/AccountsExecutiveDashboardPage';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import ProspectInvoicesList from '../../app/ProspectInvoicesList/ProspectInvoicesList';

function ProspectInvoicePage({ isSuperAdmin = false }) {
  return (
    isSuperAdmin
    ? <SuperAdminDashboard>
        <ProspectInvoicesList />
      </SuperAdminDashboard>
    : <AccountsExecutiveDashboardPage>
        <ProspectInvoicesList />
      </AccountsExecutiveDashboardPage>
  );
}

export default ProspectInvoicePage;
