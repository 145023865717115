import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { components } from 'reaf';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import SuperAdminDashboardCounts from '../SuperAdminDashboardCounts/SuperAdminDashboardCounts';

function SuperAdminPageHeader() {
  return (
    <Container fluid className="p-2 light border-bottom-light-blue sa-breadcrumb">
      <Row className="align-items-center">
        <Col>
          <Breadcrumbs
            rootRoute="superAdminHomePage"
            items={[{ superRoute: 'superAdminHomePage', to: '#', content: 'Home Page' }]} />
        </Col>
      </Row>
    </Container>
  );
}

function SuperAdminHomePage({ router, apiClient }) {
  return (
    <>
      <SuperAdminDashboard>
        <SuperAdminPageHeader />
        <SuperAdminDashboardCounts router={router} apiClient={apiClient} />
      </SuperAdminDashboard>
    </>
  );
}

export default components.withServices('router', 'apiClient')(SuperAdminHomePage);
