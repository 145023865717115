import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { withServices } from 'reaf';
import DatePicker from 'react-date-picker';
import { faPencilAlt as editIcon } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DocumentFileTypes } from '../../../constants';
import messages from '../../../../locales';
import style from './DocExtractionTaggingInfo.module.scss';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function DocExtractionTaggingInfo({ docType, taggingInfo, project, document, documentsService,
  toastrService, readOnlyAccess
}) {
  const [editMode, setEditMode] = useState(false);
  const [asOnDate, setAsOnDate] = useState(taggingInfo?.asOnDate);
  const [userEnteredAsOnDate, setUserEnteredAsOnDate] = useState(taggingInfo?.asOnDate ? taggingInfo?.asOnDate : new Date());

  useEffect(() => {
    setAsOnDate(taggingInfo?.asOnDate)
  }, [taggingInfo?.asOnDate])

  const formatDate = (date) => {
    return moment(date).utc().format('MM/DD/YYYY');
  }

  const onClose = useCallback(() => {
    if(userEnteredAsOnDate && new Date(asOnDate) !== new Date(userEnteredAsOnDate)) {
      updateAsOnDate(userEnteredAsOnDate);
    } else {
      setEditMode(false);
      toastrService.error(messages.errors.validationError.date, 'top');
    }
  }, [userEnteredAsOnDate]);

  const updateAsOnDate = useCallback(async(date) => {
    setEditMode(false);
    try {
      if(moment(date).isValid()) {
        await documentsService.updateTagDocument(project, document, [{
          ...taggingInfo,
          asOnDate: date
        }]);
        toastrService.success(messages.toastMessage.AS_ON_DATE_UPDATE, 'top');
      } else {
        toastrService.error(messages.errors.validationError.date, 'top');
      }
    } catch (error) {
      console.log(error)
    }
  }, [userEnteredAsOnDate]);

  if (Reflect.ownKeys(taggingInfo).length) {
    if (docType === DocumentFileTypes.RENT_ROLL.key) {
      return (
        <div className={style.taggingInfo}>
          <span className="as-on-date">
            As Of Date:&nbsp;
            {!editMode ?
              <>
                <strong>{asOnDate && moment(new Date(asOnDate), 'MM/DD/YYYY')._isValid ? moment(asOnDate).utc().format('MM/DD/YYYY') : 'Select Date'}</strong>
                <FontAwesomeIcon 
                  className={`edit-as-on-date ${readOnlyAccess ? style.deadLink : ''}`}
                  icon={editIcon} 
                  disabled={readOnlyAccess}
                  onClick={() => setEditMode(true)}
                />
              </>
            :
            <DatePicker 
              value={formatDate(asOnDate)} 
              onChange={(date) => setUserEnteredAsOnDate(date)} 
              onClickDay={(date) => updateAsOnDate(date)}
              onCalendarClose={onClose}
              showLeadingZeros
              clearIcon={null}
              require={false}
              format="MM/dd/yyyy"
            />
            }
          </span>
        </div>
      );
    } else {
      const { periodFrom, periodTo } = taggingInfo;
      return (
        <div className={style.taggingInfo}>
          <span> From:&nbsp;
            <strong>{formatDate(periodFrom)}</strong>
          </span>
          {' '}
          <small>&nbsp;</small>
          {' '}
          <span>To:&nbsp;
            <strong>{formatDate(periodTo)}</strong>
          </span>
        </div>
      );
    }
  } else {
    return null;
  }
}

export default withServices(
    'documentsService',
    'store',
    'toastrService'
  )(DocExtractionTaggingInfo);
