import React from 'react';
import { Tooltip, Card, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import style from './PasswordStrengthInfo.module.scss';

function renderTooltip(props) {
  return (
    <Tooltip id="button-tooltip" {...props}>
      <Card className={`text-light ${style.tileTooltip}`}>
        <Card.Body className="p-1 small">
          <ul>
            <li>Your password must be between 8 and 30 characters.</li>
            <li>Your password must have at least one uppercase, lowercase, number, and special symbol.</li>
          </ul>
        </Card.Body>
      </Card>
    </Tooltip>
  );
}

// eslint-disable-next-line import/prefer-default-export
export const PasswordStrengthInfo = () => (
  <OverlayTrigger
    placement="right"
    delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip}
  >
    <div className={style.icon}>
      <FontAwesomeIcon className="ml-1" icon={faInfoCircle} />
    </div>
  </OverlayTrigger>
);
