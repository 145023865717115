import React from 'react';
import Col from 'react-bootstrap/Col';
import DocumentTile from './DocumentTile';
import style from './DocumentsGridView.module.scss';

const DocumentsGridView = ({
  documents,
  onNeedSupport,
  onClearTagging,
  onDelete,
  onDownloadCSV,
  onOpen,
  onRename,
  selectDocument,
  selectedDocument
}) => (
  documents.map((document, index) => (
    <Col key={document.uuid} className={`flex-sm-grow-0 ${style.col}`}>
      <DocumentTile
        id={`Document-${index}`}
        document={document}
        onNeedSupport={() => onNeedSupport(document)}
        onClearTagging={() => onClearTagging(document)}
        onDelete={() => onDelete(document)}
        onDownloadCSV={() => onDownloadCSV(document)}
        onOpen={() => onOpen(document)}
        onRename={onRename}
        onClick={() => selectDocument(document)}
        selected={selectedDocument == document}
      />
    </Col>
  ))
);

export default DocumentsGridView;
