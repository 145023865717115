import { saveAs } from 'file-saver';
import { setTemplateModels, addTemplateModel, removeModelFromTemplate } from '../store/templateModel';
import { addStatus, updateStatus } from '../store/fileUploadStatus';
import DocumentsService from './DocumentsService';
import { setCurrentCompany } from '../store/company';
import { allowUserToUpdateTemplate } from '../store/selectors';
import { setCurrentTemplate } from '../store/companyTemplates';
import { blobToFile } from '../utils/utils';

class TemplateModelService {
  constructor(apiClient, authService, store) {
    this.apiClient = apiClient;
    this.authService = authService;
    this.store = store;
    this.uploadId = 0;
  }

  isUserWithTemplateAccess() {
    return allowUserToUpdateTemplate(this.store.getState());
  }

  getResourceUrl(companyUUID, templateUUID) {
    return `/companies/${companyUUID}/templates/${templateUUID}/models/`;
  }

  deleteResourceUrl(companyUUID, templateUUID, modelUUID) {
    if (this.authService.isSuperAdmin()) {
      return `/admin/companies/${companyUUID}/templates/${templateUUID}/models/${modelUUID}`;
    } else if(this.isUserWithTemplateAccess()) {
      return `/companies/${companyUUID}/templates/${templateUUID}/models/${modelUUID}`;
    }
  }

  async loadTemplateModelsList(companyUUID, templateUUID) {
    const result = await this.apiClient.get(
      this.getResourceUrl(companyUUID, templateUUID),
    );

    const templateModels = result.response.models;
    this.store.batchDispatch([
      setTemplateModels(templateModels),
      setCurrentCompany(companyUUID),
      setCurrentTemplate(templateUUID)
    ]);
    return templateModels;
  }
  
  async loadGlobalTemplateModelsList(templateUUID) {
    const result = await this.apiClient.get(
      `/admin/templateMappings/${templateUUID}/models`,
    );

    const templateModels = result.response.models;
    this.store.batchDispatch([
      setTemplateModels(templateModels),
      setCurrentTemplate(templateUUID)
    ]);
    return templateModels;
  }

  async updateConsoleteModelAccess(
    model,
    companyUUID,
    templateUUID,
    templateModelUuid,
    assetType,
    canDownloadConsolidatedModel
  ) {
    const urlEndPoint = this.isUserWithTemplateAccess() ? `` : `/admin`;
    model.canDownloadConsolidatedModel =  canDownloadConsolidatedModel
    await this.apiClient.post(
      `${urlEndPoint}/companies/${companyUUID}/templates/${templateUUID}/models/${templateModelUuid}/consolidatedModelAccess`,
      { assetType, canDownloadConsolidatedModel }
    );
  }

  async uploadCloneTemplateModel(companyUUID, templateUUID, modelPayload) {
    const formData = Reflect.ownKeys(modelPayload).reduce((formData, key) => {
      formData.append(key, modelPayload[key]);
      return formData;
    }, new FormData());
    
    await this.apiClient.post(
      this.getResourceUrl(companyUUID, templateUUID),
      formData
    );
  }

  async uploadTemplateModel(companyUUID, templateUUID, templateForm) {
    const uploadId = this.uploadId++;
    this.store.dispatch(
      addStatus(
        uploadId,
        templateForm.modelFile.name,
        DocumentsService.getDocumentTypeFromName(templateForm.modelFile.name)
      )
    );

    const uploadId2 = this.uploadId++;

    if (templateForm.reportTemplateFile) {

      this.store.dispatch(
        addStatus(
          uploadId2,
          templateForm.reportTemplateFile.name,
          DocumentsService.getDocumentTypeFromName(templateForm.reportTemplateFile.name)
        )
      );
    }

    const formData = Reflect.ownKeys(templateForm).reduce((formData, key) => {
      formData.append(key, templateForm[key]);
      return formData;
    }, new FormData());
    let result;

    try {
      if (!companyUUID) {
        result = await this.apiClient.post(
          `/admin/templates/${templateUUID}/models/`,
          formData
        );
      } else {
        result = await this.apiClient.post(
          this.getResourceUrl(companyUUID, templateUUID),
          formData
        );
      }
    } catch (e) {
      this.store.batchDispatch([
        updateStatus(uploadId, 'failed'),
        updateStatus(uploadId2, 'failed')
      ]);
      throw e;
    }
    const templateModel = result.response.model;
    this.store.batchDispatch([
      updateStatus(uploadId, 'success'),
      updateStatus(uploadId2, 'success'),
      addTemplateModel(templateModel)
    ]);
    return templateModel;
  }

  async updateTemplateModel(companyUUID, templateUUID, modelUUID, templateForm) {
    const uploadId = this.uploadId++;
    if (templateForm.modelFile) {
      this.store.dispatch(
        addStatus(
          uploadId,
          templateForm.modelFile.name,
          DocumentsService.getDocumentTypeFromName(templateForm.modelFile.name)
        )
      );
    }
    const uploadId2 = this.uploadId++;
    if (templateForm.reportTemplateFile) {
      this.store.dispatch(
        addStatus(
          uploadId2,
          templateForm.reportTemplateFile.name,
          DocumentsService.getDocumentTypeFromName(templateForm.reportTemplateFile.name)
        )
      );
    }

    const formData = Reflect.ownKeys(templateForm).reduce((formData, key) => {
      formData.append(key, templateForm[key]);
      return formData;
    }, new FormData());
    let result;
    try {
      if (!companyUUID) {
        result = await this.apiClient.put(
          `/admin/templates/${templateUUID}/models/${modelUUID}`,
          formData
        );
      } else {
        result = await this.apiClient.put(
          `/companies/${companyUUID}/templates/${templateUUID}/models/${modelUUID}`,
          formData,
        )
      }
    } catch (e) {
      this.store.batchDispatch([
        updateStatus(uploadId, 'failed'),
        updateStatus(uploadId2, 'failed')
      ]);
      throw e;
    }

    const templateModel = result.response.model;
    this.store.batchDispatch([
      updateStatus(uploadId, 'success'),
      updateStatus(uploadId2, 'success'),
      addTemplateModel(templateModel)
    ]);
    return templateModel;
  }

  async deleteTemplateModel(companyUUID, templateUUID, modelUUID) {
    let result;
    if(!companyUUID) {
      result = await this.apiClient.delete(`/admin/templates/${templateUUID}/models/${modelUUID}`);
    } else {
      result = await this.apiClient.delete(
        this.deleteResourceUrl(companyUUID, templateUUID, modelUUID)
      );
    }
    this.store.dispatch(removeModelFromTemplate(modelUUID));
    return result.response.model;
  }

  async getModelFileFromAdmin(companyUuid, templateUUID, modalUuid, templateName) {
    let response = null;
    try {
      response = blobToFile(await this.apiClient.get(
        `/admin/companies/${companyUuid}/templates/${templateUUID}/models/${modalUuid}`,
        { responseType: 'blob' },
      ), templateName);
    } catch (e) {}
    return response
  }

  async getReportFileFromAdmin(companyUuid, templateUUID, modalUuid, templateName) {
    let response = null;
    try {
      const {response: isReportFileExist} = await this.apiClient.get(
        `/admin/companies/${companyUuid}/templates/${templateUUID}/models/${modalUuid}/reportStatus`);
        
      if (isReportFileExist) {
        response = blobToFile(await this.apiClient.get(
          `/admin/companies/${companyUuid}/templates/${templateUUID}/models/${modalUuid}/reportTemplateFile`,
          { responseType: 'blob' },
        ), `${templateName}.xlsx`);
      }
    } catch (e) {}
    return response
  }


  async downloadTemplateModel(companyUUID, templateUUID, model) {
    const urlPrefix = this.isUserWithTemplateAccess() ? `` : `/admin`;
    const urlEndPoint = `${urlPrefix}${companyUUID ? `/companies/${companyUUID}` : ''}/templates/${templateUUID}/models/${model.uuid}`;
    const response = await this.apiClient.get(
      urlEndPoint,
      { responseType: 'blob' },
    );
    const modelName = model.modelName.replace(/_|-/g, '');
    saveAs(response, `${modelName}.xlsm`);
  }

  async downloadReportTemplate(companyUUID, templateUUID, model) {
    const urlEndPoint = `/admin${companyUUID ? `/companies/${companyUUID}` : ''}/templates/${templateUUID}/models/${model.uuid}/reportTemplateFile`;
    const response = await this.apiClient.get(
      urlEndPoint,
      { responseType: 'blob' },
    );
    const modelName = model.modelName.replace(/_|-/g, '');
    saveAs(response, `${modelName}.xlsx`);
  }

  async downloadIndividualModel(company, project, templateModel) {
    const response = await this.apiClient.get(
      `/companies/${company.uuid}/templates/${project.templateUuid}/templateModels/${templateModel.uuid}`, { responseType: 'blob' },
    );
    const modelName = templateModel.modelName.replace(/_|-/g, '');
    saveAs(response, `${modelName}.xlsm`);
  }

  async updateGlobalModelConsolidatedModelAccess(template, model, consolidatedModelAccess){
    model.allowConsolidatedModel =  consolidatedModelAccess;
    const url = `/admin/templateMappings/${template.uuid}/models/${model.uuid}/consolidatedModel`;
    return await this.apiClient.put(
        url,
        {consolidatedModelAccess},
    )
  }
}

export default TemplateModelService;
