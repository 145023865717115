import React from 'react';
import ProjectInfoForm from '../ProjectInfoForm/ProjectInfoForm';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';

function ProjectInfoFormModal({ show, onHide, readOnlyAccess=false }) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
  return (
    <FormModal entity="ProjectInfo" show={show} onSubmit={onSubmit} readOnlyAccess={readOnlyAccess} isSubmitting={isSubmitting} onHide={onHide} title="Update Assumptions" size="lg" className="modal-md">
      <ProjectInfoForm bindSubmission={bindSubmission} onSubmitSuccess={onHide} />
    </FormModal>
  );
}

export default ProjectInfoFormModal;
