import React, {
    useState, useEffect, useCallback, useMemo
  } from 'react';
  import { Form } from 'react-bootstrap';
  import ReactSelect from 'react-select';
  import { withServices } from 'reaf';
  import { isEmpty } from 'lodash';
  
  const getTemplateDropdownMap = (companyTemplates) => 
    companyTemplates.map(({ uuid: value, templateName: label }) => ({ value, label }));
  
  function CompanyGlobalTemplateAccess({
    onSubmitSuccess, bindSubmission, company, companyUuid, companyService, toastrService
  }) {
    const [selectAll, setSelectAll] = useState(false);
    const [initialTemplateAccess, setInitialTemplateAccess] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState(initialTemplateAccess);

    useEffect(() => {
      const fetchTemplates = async () => {
        const allGlobalTemplates = await companyService.loadAllGlobalTemplates();
        const selectedGlobalTemplates = await companyService.loadCompanyGlobalTemplates(companyUuid);
        
        if (allGlobalTemplates.length) {
          const globalTemplates = getTemplateDropdownMap(allGlobalTemplates);
          setInitialTemplateAccess(globalTemplates)
        }

        if (selectedGlobalTemplates.length) {
          const allowedGlobalTemplates = getTemplateDropdownMap(selectedGlobalTemplates);
          setSelectedTemplates(allowedGlobalTemplates)
        }
        
      };
      fetchTemplates();
    }, [companyUuid]);
  
    const onSubmit = useCallback(async () => {
      if(selectedTemplates && selectedTemplates.length) {
        try {
          const globalTemplatesAccess = selectedTemplates.map(({ value }) => value)
          const response = await companyService.updateCompanyGlobalTemplate(companyUuid, {company: {...company.company, globalTemplatesAccess}});
          toastrService.success(response.message);
          onSubmitSuccess();
        } catch (error) {
          console.error(error);
        }
      }
    }, [selectedTemplates]);
  
    useEffect(() => {
      bindSubmission(onSubmit, false);
    }, [onSubmit]);
  
    return (
      <>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox" checked={selectAll} onChange={e => {
              setSelectAll(e.target.checked);
              setSelectedTemplates(initialTemplateAccess);
            }} label="Allow all templates." />
        </Form.Group>
        <ReactSelect
          isMulti
          isDisabled={selectAll}
          value={selectedTemplates}
          onChange={selectedTempates => {
            setSelectedTemplates(selectedTempates);
          }}
          options={initialTemplateAccess} />
        {isEmpty(selectedTemplates) && <div className="text-danger">
            <small>Please select atleast one template</small>
        </div>}
      </>
    );
  }

  export default (withServices('companyService', 'toastrService')(CompanyGlobalTemplateAccess));
  