import React from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Container, Row, Col } from 'react-bootstrap';
import {
  currentCompanySelector,
  companiesDetailsSelector
} from '../../../store/selectors';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import ProjectExporterList from '../../app/ProjectExporterList/ProjectExporterList';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';

function ProjectExporterPageHeader({ companyUUID, companiesDetails }) {
  return (
    <Container fluid className="p-2 light border-bottom-light-blue sa-breadcrumb">
      <Row className="align-items-center">
        <Col>
          <Breadcrumbs
            rootRoute="superAdminHomePage"
            items={[
              { route: 'superAdminPage', content: 'Companies' },
              { route: 'superAdminPage', content: companiesDetails[companyUUID].name, params: {
                '_search[name]': companiesDetails[companyUUID].name
              } },
              { to: '#', content: 'Project Exporters' }]} />
        </Col>
      </Row>
    </Container>
  );
}

function SuperAdminProjectExporterPage({ companyUUID, companiesDetails }) {
  return (
    <SuperAdminDashboard>
      <ProjectExporterPageHeader companyUUID={companyUUID} companiesDetails={companiesDetails} />
      <ProjectExporterList />
    </SuperAdminDashboard>
  );
}
const mapStateToProps = state => ({
  companyUUID: currentCompanySelector(state),
  companiesDetails: companiesDetailsSelector(state)
});
export default connect(mapStateToProps)(withServices('projectExporterService')(SuperAdminProjectExporterPage));
