import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import style from './ProjectNote.module.scss'

function ProjectNote({ notes }) {
  const [show, setShow] = React.useState(true);

  return (
    <>
      <Alert show={show} size={'md'} variant="light"
        className={`alert fixed-bottom bottom border w-50 m-auto p-0 shadow ${style.projectNotes}`} onClose={() => setShow(false)} dismissible>
        <Alert.Heading className="noteHeading h6 text-black">
          <p className="pl-2 pt-2 mb-0">Property Note</p>
        </Alert.Heading>
        <p className="m-0 p-2 border-top">
          {notes}
        </p>
      </Alert>
    </>
  );
}

export default ProjectNote;
