import React from 'react';
import { connect } from 'react-redux';
import { authSelector } from '../../../store/selectors';
import profileAvatar from '../../../assets/images/profile-avatar.jpg';
import style from './UserProfile.module.scss';

function UserProfile({
  name, company, email, role
}) {
  return (
    <div className={`vertical-section ${style.userProfile}`}>
      <div className="row">
        <div className={`col-12 d-flex border-bottom ${style.sectionHeader}`}>
          <h3>Profile</h3>
        </div>
      </div>
      <div className="row">
        <div className={`col-12 d-flex ${style.sectionWrapper}`}>
            <div className={style.imageWrapper}>
               <img src={profileAvatar} alt="user profile" />
            </div>
            <div className={`ml-3 ${style.contentWrapper}`}>
              <p>
                <strong>Name</strong>
                <span>{name}</span>
              </p>
              <p>
                <strong>Email</strong>
                <span>{email}</span>
              </p>
              <p>
                <strong>Company</strong>
                <span>{company}</span>
              </p>
              <p>
                <strong>Role</strong>
                <span>{role}</span>
              </p>
            </div>
        </div>
       
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const auth = authSelector(state);
  if (auth.token) {
    return {
      name: [auth.user.firstName, auth.user.lastName].join(' '),
      company: auth.company.name,
      email: auth.user.email,
      role: auth.role === 'COMPANY_ADMIN' ? 'Admin' : 'User'
    };
  }
};

export default connect(mapStateToProps)(UserProfile);
