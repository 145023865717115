import BaseEvent from '../../../core/Spreadjs/Events/BaseEvent';
import { SOURCE_SHEET_NAME, TARGET_SHEET, TARGET_SHEET_NAME } from '../DataSpreadSheet';

export class OSCellChangedEvent extends BaseEvent {
  handle(spreadsheet, _, args) {
    const {
      sheetName, col, row, newValue, oldValue
    } = args;
    if (sheetName === TARGET_SHEET_NAME && oldValue !== newValue) {
      const headIndex = spreadsheet.datasheet.customSearchColumnWithValue(
        TARGET_SHEET, 0, 'Head',
      ).foundColumnIndex;

      const categoryIndex = spreadsheet.datasheet.customSearchColumnWithValue(
        TARGET_SHEET, 0, 'Category',
      ).foundColumnIndex;

      if (col === headIndex || col === 0) {
        // heavy operations, do only if necessary
        const [targetSheetData, sourceSheetData] = spreadsheet.datasheet.getSheetSpecificData(
          [TARGET_SHEET_NAME, SOURCE_SHEET_NAME],
        );
        const targetSheetColCount = spreadsheet.datasheet.getColumnCount(targetSheetData);
        const sourceSheetColCount = spreadsheet.datasheet.getColumnCount(sourceSheetData);

        if (col === headIndex) {
          spreadsheet.onTargetHeadValueChange(row, col, newValue, sourceSheetColCount, targetSheetColCount);
        } else if (col === 0) {
          spreadsheet.onTargetIdValueChange(row, newValue, oldValue, sourceSheetColCount);
        }
      } else if (col === categoryIndex) {
        const head = spreadsheet.spreadsheet.getCellValue(TARGET_SHEET, row, headIndex);
        spreadsheet.onTargetCategoryValueChange(row, col, head, newValue);
      }
      spreadsheet.operatingStatementEvents();
    }
  }
}

export const osCellChangedEvent = OSCellChangedEvent.get();
