import React from 'react';
import styles from './SpriteIcon.module.scss';

const SpriteIcon = ({ iconClass }) => (
  <div className={`${styles.spriteIcons} ${styles.spritePngIcons} ${iconClass}`} />
);

export const SpriteSettingsIcon = () => (
  <SpriteIcon iconClass={styles.settingsIcon} />
);
export const SpriteLogoutIcon = () => (
  <SpriteIcon iconClass={styles.logoutIcon} />
);
export const SpriteExtractedIcon = () => (
  <SpriteIcon iconClass={styles.extractedIcon} />
);
export const SpriteAddMoreTagsIcon = () => (
  <SpriteIcon iconClass={styles.addMoreTagsIcon} />
);
export const SpriteRenameIcon = () => (
  <SpriteIcon iconClass={styles.renameIcon} />
);
export const SpriteClearTaggingIcon = () => (
  <SpriteIcon iconClass={styles.clearTaggingIcon} />
);
export const SpriteDeleteIcon = () => (
  <SpriteIcon iconClass={styles.deleteIcon} />
);
export const SpriteNeedSupportIcon = () => (
  <SpriteIcon iconClass={styles.needSupportIcon} />
);
export const SpriteDownloadModelIcon = () => (
  <SpriteIcon iconClass={styles.downloadModelIcon} />
);
export const SpriteDownloadXlsxIcon = () => (
  <SpriteIcon iconClass={styles.downloadXlsxIcon} />
);
export const SpriteEmailIcon = () => (
  <SpriteIcon iconClass={styles.emailIcon} />
);
export const SpriteLockIcon = () => (
  <SpriteIcon iconClass={styles.lockIcon} />
);
export const SpriteFolderIcon = () => (
  <SpriteIcon iconClass={styles.folderIcon} />
);
export const SpriteUserIcon = () => (
  <SpriteIcon iconClass={styles.userIcon} />
);
export const SpriteCompanyIcon = () => (
  <SpriteIcon iconClass={styles.companyIcon} />
);

export default SpriteIcon;
