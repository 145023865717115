import React from 'react';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import SharedReportLinks from '../../app/SharedReportLinks/SharedReportLinks';

function SharedReportLinksPage({
  reportLinks
}) {
  return (
    <SuperAdminDashboard>
      <SharedReportLinks reportLinks={reportLinks} />
    </SuperAdminDashboard>
  );
}
export default SharedReportLinksPage;
