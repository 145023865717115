import { setAppConfigurationList } from '../store/appConfiguration';

class AppConfigurationService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  async loadAppConfigurations() {
    const appConfigurations = await this.apiClient.get(
      '/admin/appConfigurations'
    );

    this.store.dispatch(setAppConfigurationList(appConfigurations.response.appConfigurations));
  }

  async updateConfiguration(updateConfiguration, value) {
    await this.apiClient.put(
      `/admin/appConfigurations/${updateConfiguration.uuid}`, { value }
    );

    await this.loadAppConfigurations();
  }
}

export default AppConfigurationService;
