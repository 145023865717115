import React from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormModal from '../FormModal/FormModal';
import EditVideoTutorialsForm from '../VideoTutorialsForm/EditVideoTutorialsForm';
import VideoTutorialsForm from '../VideoTutorialsForm/VideoTutorialsForm';
import useModalForm from '../../../hooks/useModalForm';

function VideoTutorialPopups({
  video,
  onHide,
  onEditSuccess,
  showDeleteConfirm,
  onDeleteConfirm,
  showEditVideoForm,
  onDeleteCancel,
  showAddVideoFormPopup
}) {
  const [isSubmittingEditForm, bindSubmissionEditForm, onSubmitEditForm] = useModalForm();
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showEditVideoForm &&
        (
          <FormModal isSubmitting={isSubmittingEditForm} onSubmit={onSubmitEditForm} onHide={onHide} show={showEditVideoForm} title='Edit Video'>
            <EditVideoTutorialsForm bindSubmission={bindSubmissionEditForm} onSubmitSuccess={(video) => {
              onHide();
              onEditSuccess(video)
            }} videoTutorial={video} />
          </FormModal>
        )
      }
      {
        showDeleteConfirm &&
        (
          <ConfirmationModal
            show={showDeleteConfirm}
            title={`Delete Video ${video.title}?`}
            message={`Are you sure you want to delete the Video "${video.title}"`}
            onConfirm={onDeleteConfirm}
            onCancel={onDeleteCancel}
          />
        )
      }
      {
        showAddVideoFormPopup &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showAddVideoFormPopup} title="Add Tutorial Video">
            <VideoTutorialsForm bindSubmission={bindSubmission} onSubmitSuccess={onHide} videoTutorial={video} />
          </FormModal>
        )
      }
    </div>
  );
}

export default VideoTutorialPopups;
