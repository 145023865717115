import { combineReducers } from 'redux';

import counter from './counter';
import projects from './projects';
import tickets from './tickets';
import documents from './documents';
import auth from './auth';
import currentProject from './currentProject';
import currentDocument from './currentDocument';
import fileUploadStatus from './fileUploadStatus';
import company from './company';
import companyList from './companyList';
import companyUsers from './companyUsers';
import companyTemplates from './companyTemplates';
import templateModel from './templateModel';
import loadingBackdrop from './loadingBackdrop';
import loadingBackdropWithProgress from './loadingBackdropWithProgress';
import notificationToastr from './notificationToastr';
import productTour from './productTour';
import uiState from './uiState';
import metaData from './metadata';
import accountsExecutive from './accountsExecutive';
import prospects from './prospects';
import companyProjectExporters from './companyProjectExporters';
import appConfiguration from './appConfiguration';
import videoTutorial from './videoTutorial';
import featureFlag from './featureFlag';
import dynamicAssetType from './dynamicAssetType';
import subscriptionsList from './subscriptionsList';
import globalTemplates from './globalTemplates';
import paymentLinks from './paymentLinks';

export default combineReducers({
  auth,
  counter,
  company,
  projects,
  documents,
  companyList,
  companyUsers,
  templateModel,
  currentProject,
  loadingBackdrop,
  loadingBackdropWithProgress,
  notificationToastr,
  currentDocument,
  fileUploadStatus,
  companyTemplates,
  productTour,
  uiState,
  metaData,
  accountsExecutive,
  prospects,
  tickets,
  companyProjectExporters,
  appConfiguration,
  videoTutorial,
  featureFlag,
  dynamicAssetType,
  subscriptionsList,
  globalTemplates,
  paymentLinks
});
