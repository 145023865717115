import React from 'react';
import { Table } from 'react-bootstrap';
import FloorPlanDropDownOptions from './FloorPlanDropDownOptions';
import style from './DocExtractionFloorPlanConfig.module.scss';
import { rentRollAffixTabViewName } from '../../../constants';
import { displayNumberInUSFormat } from '../../../utils/utils';

const { CONFIG_VIEW } = rentRollAffixTabViewName;

const AffixConfigView = ({
  getAverageTotals, activeViewName, isActive,
  floorPlanConfigData, setFieldValue, setIsMenuOpen,
  floorPlanBedMapping, floorPlanBathMapping
}) => {

  if (!isActive) {
    return null;
  }

  return (
    <div className={style.floorPlan}>
      <Table>
        <thead>
          <tr>
            <th>Floor Plan</th>
            <th>#Units</th>
            <th>
              Avg. Sqft
              <small>(Min - Max)</small>
            </th>
            <th>#Beds</th>
            <th>#Baths</th>
          </tr>
        </thead>
        <tbody>
          {
            Reflect.ownKeys(floorPlanConfigData).map((floorPlan) => (
              <tr key={floorPlan}>
                <td>
                  <div className={style.fPlan}><span title={floorPlan} className="text-with-ellipsis w-100 d-inline-block">{floorPlan}</span></div>
                </td>
                <td
                  title={displayNumberInUSFormat(floorPlanConfigData[floorPlan].count)}>
                  {displayNumberInUSFormat(floorPlanConfigData[floorPlan].count)}
                </td>
                <td
                  title={getAverageTotals(floorPlan, 'squareFtTotal', 'count', false)}>
                  {getAverageTotals(floorPlan, 'squareFtTotal', 'count', false)}
                  <small>
                    {/* eslint-disable-next-line max-len */}
                    {`(${displayNumberInUSFormat(floorPlanConfigData[floorPlan].squareFtMinValue)} - ${displayNumberInUSFormat(floorPlanConfigData[floorPlan].squareFtMaxValue)})`}
                  </small>
                </td>
                <FloorPlanDropDownOptions
                  style={style}
                  fpConfigData={floorPlanConfigData}
                  floorPlan={floorPlan}
                  setCurrentFloorPlan={(dropdownValue) => {
                    setFieldValue(`['${floorPlan}']`, dropdownValue)
                  }}
                  activeViewName={activeViewName}
                  CONFIG_VIEW={CONFIG_VIEW}
                  floorPlanBedMapping={floorPlanBedMapping}
                  floorPlanBathMapping={floorPlanBathMapping}
                  onMenuClose={() => setIsMenuOpen(false)}
                  onMenuOpen={() => setIsMenuOpen(true)}
                />
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>

  )
}

export default AffixConfigView;