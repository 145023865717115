import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalWrapper({
  show, onHide, size = 'lg', title, content
}) {
  return (
    <Modal show={show} size={size} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
    </Modal>
  );
}

export default ModalWrapper;
