import React, { useState, useCallback } from 'react';
import { withServices } from 'reaf';
import { Modal } from "react-bootstrap";
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';
import style from './DocumentsList.module.scss';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import IconButton from '../../core/Button/IconButton';
import WithTooltip from '../../core/WithTooltip/WithTooltip';
import DisplayExtractionJobStatus from '../DisplayExtractionJobStatus/DisplayExtractionJobStatus';
import DocumentTypeBadge from '../DocumentTypeBadge/DocumentTypeBadge';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import { SelectDocumentTypeFilter, SelectExtractionStatusFilter } from '../../core/ReactTableFilters/ReactTableFilters';
import DocumentViewer from '../DocumentViewer/DocumentViewer';

const DocumentsList = ({ apiClient, router, queries, projectsService, documentsService }) => {
  const { data: documents, loading, pageCount, onDownloadCSV, fetchData: fetchDocuments } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    routeName: 'superAdminDocumentsPage',
    resourceEndPoint: '/admin/documents',
    fileName: 'Documents.csv',
    csvDownloadEndPoint: '/admin/documents/downloadCSV'
  });

  const downloadDocument = React.useCallback((row) => {
    documentsService.getAdminDocumentFile(row.Project.Company, row.Project, row);
  }, []);

  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const previewDocument = useCallback((document, project) => {
    setSelectedDocument(document)
    setSelectedProject(project)
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: 'Filename',
      accessor: 'fileName',
      fixedWidth: 180
    },
    {
      Header: 'Owner',
      accessor: 'Users.firstName',
      fixedWidth: 100,
      Cell: ({ row: { original: { Project: { Users } } } }) => (Users && Users[0] ? <WithTooltip tooltipText={Users[0].email}><span>{`${Users[0].firstName} ${Users[0].lastName}`}</span></WithTooltip> : 'Deleted')
    },
    {
      Header: 'Company',
      accessor: 'Company.name',
      fixedWidth: 100,
      Cell: ({ row: { original: { Project: { Company } } } }) => (Company ? `${Company.name}` : 'Deleted')
    },
    {
      Header: 'Project',
      accessor: 'Project.name',
      fixedWidth: 180,
      Cell: ({ row: { original: { Project } } }) => (Project ? `${Project.name}` : 'Deleted')
    },
    {
      Header: 'Type',
      accessor: 'TaggingData.documentType',
      width: 75,
      minWidth: 75,
      Filter: SelectDocumentTypeFilter,
      Cell: ({ row: { original: { TaggingData } } }) => TaggingData && TaggingData.documentType ? <DocumentTypeBadge documentType={TaggingData.documentType} /> : ''
    },
    {
      Header: 'Status',
      Filter: SelectExtractionStatusFilter,
      accessor: 'ExtractionJobs.status',
      Cell: ({ row: { original: { ExtractionJobs } } }) => ExtractionJobs && ExtractionJobs[0] ? <DisplayExtractionJobStatus status={ExtractionJobs[0].status} showBottmSpace={false}/> : ''
    },
    {
      Header: 'Rows',
      disableFilters: true,
      accessor: 'ExtractionJobs.rowsExtracted',
      Cell: ({ row: { original: { ExtractionJobs } } }) => ExtractionJobs && ExtractionJobs[0] ? ExtractionJobs[0].rowsExtracted : ''
    },
    {
      Header: 'Pages',
      disableFilters: true,
      accessor: 'ExtractionJobs.pagesExtracted',
      Cell: ({ row: { original: { ExtractionJobs } } }) => ExtractionJobs && ExtractionJobs[0] ? ExtractionJobs[0].pagesExtracted : ''
    },
    {
      Header: 'Created On',
      accessor: 'createdAt',
      disableFilters: true,
      Cell: ({ value }) => <DisplayDate date={value} />
    },
    {
      Header: 'Actions',
      accessor: 'action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { original: document } }) => (
        (
          <div className="d-flex justify-content-center flex-row">
            <IconButton title="Download File" icon={faDownload} variant="outline-primary" size="sm" onClick={
              () => downloadDocument(document)
            } />
            <IconButton title="Preview File" icon={faFileInvoice} variant="outline-primary" size="sm" onClick={
              () => previewDocument(document, document.Project)
            } />
          </div>
        )
      )
    }
  ], [documents]);
  return (
    <>
    <ReactTable
      columns={columns}
      data={documents}
      title="Documents"
      onDownloadCSV={onDownloadCSV}
      queries={queries}
      loading={loading}
      fetchData={fetchDocuments}
      pageCount={pageCount}
    />

    {
      selectedDocument && selectedProject && (
      <Modal
        show={!!selectedDocument}
        size="xl"
        onHide={() => {
          setSelectedDocument(null);
          setSelectedProject(null);
        }}
        className="error-modal"
        centered
        >
          <Modal.Body>
            <div
              id="DocumentPreviewWrapper"
              className={`vertical-section flex-shrink-0 bg-light ${style.toolbarViewerSpacerRight} ${style.pdfViewerWrapper}`}
              style={{ flex: '3' }}>

              <DocumentViewer
                document={selectedDocument}
                project={selectedProject}
                setPdfPageCount={() => {}}
                setXLSheetNames={() => {}}
                small
              />
              </div>
          </Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {selectedDocument.fileName}
            </Modal.Title>
          </Modal.Header>
        </Modal>
      )
    }
    </>
  );
};

export default withServices('apiClient', 'router', 'projectsService', 'documentsService')(DocumentsList);
