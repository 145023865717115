// eslint-disable-next-line import/no-cycle
import { TARGET_SHEET, TARGET_SHEET_NAME } from "../../../app/DocExtractionComponent/DataSpreadSheet";
import BaseEvent from "./BaseEvent";

export default class EditEndingEvent extends BaseEvent {
	handle(datasheet, _, args) {
		const {
			row, col, editingText, sheetName
		} = args;
		const { spreadsheet } = datasheet;

		if (row === 0 && sheetName === TARGET_SHEET_NAME) {
			const { foundColumnIndex } = datasheet.customSearchColumnWithIgnoreCaseValue(
				TARGET_SHEET,
				row, editingText
			);
			if (foundColumnIndex > -1 && datasheet.clipboardData.lastCellValue !== editingText) {
				setTimeout(() => {
					spreadsheet.setCellValue(TARGET_SHEET, row, col, '');
					datasheet.props.onToast({ type: 'danger', message: 'Column already exist in the sheet.' });
				}, 0);
			} else if (datasheet.clipboardData.lastCellValue !== editingText && datasheet.isRentRollBaseType) {
				datasheet.props.documentRef.handleCustomChargeCodeColumn(editingText, col);
			}
		}
	}
}