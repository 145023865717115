import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { sumBy, omit } from 'lodash';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import InputRange from 'react-input-range';
import DocExtractionAffixTableWrapper from '../../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import DocExtractionAffixTotalCard from '../../DocExtractionAffixTotalCard/DocExtractionAffixTotalCard';
import { DisplayAmountFormat, displayNumberInUSFormat, formatAmountTitleTooltip } from '../../../../utils/utils';
import { rentRollAffixTabViewName, affixTotalColors } from '../../../../constants';
import RentRollConfigHeader from '../../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../../DocExtractionSummary/DocExtractionWithoutSummary';
import 'react-input-range/lib/css/index.css';
import style from './DocExtractionRetailTenantType.module.scss';
import { retailTenantTypeConfigSelector, retailTenantTypeCalculationPercentSelector } from '../../../../store/selectors';
import DocExtractionRetailTenantTypeChart from './DocExtractionRetailTenantTypeChart';
import './DocExtractionRetailTenantType.scss';
import { setRetailMajorTenantTypePercent } from '../../../../store/currentDocument';
import messages from '../../../../../locales/en-US';


function DocExtractionRetailTenantType({
  tenantTypeConfig, tenantTypeCalcPercent, store, onTenantTypeCalcChange, onConfigSubmit, onSlidePaneClose
}) {
  const { TABLE_VIEW } = rentRollAffixTabViewName;
  const [chartData, setChartData] = useState([]);
  const [calculationPercentage, setCalculationPercentage] = useState(tenantTypeCalcPercent);
  const [activeViewName, setActiveViewName] = useState(TABLE_VIEW);

  const {
    RENT_ROLL: {
      MULTIFAMILY: {
        TOTAL_UNITS,
        TOTAL_MONTHLY_RENT,
        TOTAL_MARKET_RENT,
        TOTAL_SQFT
      }
    }
  } = affixTotalColors;


  useEffect(() => {
    if (tenantTypeConfig.length) {
      const finalChartData = tenantTypeConfig.map(item => omit(item, ['totalSqFtPercent', 'sqFtTotal']));
      setChartData(finalChartData);
    }
  }, [tenantTypeConfig]);

  const onTenantTypeCalculationChange = useCallback(async (percent) => {
    store.dispatch(setRetailMajorTenantTypePercent(percent));
    onTenantTypeCalcChange(percent);
    await onConfigSubmit();
  }, [onConfigSubmit, store]);

  const onSubmit = useCallback(() => {
    store.dispatch(setRetailMajorTenantTypePercent(calculationPercentage));
    onConfigSubmit();
  });

  return (
    <Formik
      key="TenantType"
      onSubmit={onSubmit}
      initialValues={[]}
      render={() => (

        <Form>
          {
            tenantTypeConfig.length ? (
              <>
                <RentRollConfigHeader
                  title="Configure Tenant Type"
                  activeViewName={activeViewName}
                  onTabViewChange={(activeView) => setActiveViewName(activeView)} />
                <Row className={style.calculationPercentage}>
                  <Col>
                    <p>
All the Major Tenants are calculated by
                      {calculationPercentage}
                      {' '}
%.
                    </p>
                    <InputRange
                      maxValue={50}
                      minValue={0}
                      step={1}
                      value={calculationPercentage}
                      onChange={value => setCalculationPercentage(value)}
                      onChangeComplete={(value) => onTenantTypeCalculationChange(value)}
                    />
                  </Col>
                </Row>
                <DocExtractionAffixTableWrapper>
                  {
                    activeViewName === TABLE_VIEW ? (
                      <>
                        <Row className={style.occupancyHeader}>
                          <Col className={`col-md-auto ${style.documentStatusHeader}`}>Tenant Type</Col>
                          <Col className={`col-md-auto ${style.sqFtHeader}`}>Square Feet</Col>
                          <Col className={`col-md-auto ${style.nra}`}>NRA%</Col>
                          <Col className={`col-md-auto ${style.rent}`}>Rent</Col>
                          <Col className={`col-md-auto ${style.recovery}`}>Recoveries</Col>
                        </Row>
                        <Row className="position-relative">
                          <Col className={style.tenantType}>
                            <Table>
                              <tbody>
                                {
                                  tenantTypeConfig.map(item => (
                                    <tr key={item.name}>
                                      <td className={style.documentStatusHeader}>
                                        <div title={item.name}><span className="text-with-ellipsis w-100 d-inline-block">{item.name}</span></div>
                                      </td>
                                      <td className={style.sqFtHeader}>
                                        <div title={displayNumberInUSFormat(item.sqFtTotal)} ><span className="text-with-ellipsis w-100 d-inline-block">{displayNumberInUSFormat(item.sqFtTotal)}</span></div>
                                      </td>
                                      <td className={style.nra}>
                                        <div title={`${(item.totalSqFtPercent).toFixed(2)} %`} ><span className="text-with-ellipsis w-100 d-inline-block">{`${(item.totalSqFtPercent).toFixed(2)} %`}</span></div>
                                      </td>
                                      <td className={style.rent}>
                                      <div 
                                        title={formatAmountTitleTooltip(item.Rent)} 
                                        className={`${style.columnNowrapRent} text-with-ellipsis`}
                                      >
                                        <DisplayAmountFormat number={item.Rent} />
                                      </div>
                                      </td>
                                      <td className={style.recovery}>
                                      <div 
                                        title={formatAmountTitleTooltip(item.Recoveries)} 
                                        className={`${style.recovery} text-with-ellipsis`}
                                      >
                                        <DisplayAmountFormat number={item.Recoveries} />
                                      </div>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>

                      </>
                    ) : <DocExtractionRetailTenantTypeChart data={chartData} />
                  }

                  <DocExtractionAffixTotalCard
                    cards={[
                      { title: 'Total Square Feet', color: TOTAL_UNITS, content: displayNumberInUSFormat(sumBy(tenantTypeConfig, 'sqFtTotal')) },
                      { title: 'Total NRA (%)', color: TOTAL_MONTHLY_RENT, content: '100.00%' },
                      { title: 'Total Rent', color: TOTAL_MARKET_RENT, content: <DisplayAmountFormat number={sumBy(tenantTypeConfig, 'Rent')} /> },
                      { title: 'Total Recoveries', color: TOTAL_SQFT, content: <DisplayAmountFormat number={sumBy(tenantTypeConfig, 'Recoveries')} /> }
                    ]}
                  />
                </DocExtractionAffixTableWrapper>
                {/* <Row className={`${style.cardsFixBottom} ${style.footerFixedBottom}`}>
                  <Button variant="outline-secondary btn-sm" onClick={onSlidePaneClose}>Cancel</Button>
                  <Button variant="primary btn-sm" type="submit">Save</Button>
                </Row> */}
              </>
            ) : <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
          }
        </Form>
      )}

    />
  );
}

const mapStateToProps = state => ({
  tenantTypeConfig: retailTenantTypeConfigSelector(state),
  tenantTypeCalcPercent: retailTenantTypeCalculationPercentSelector(state)
});

export default connect(mapStateToProps)(withServices('store')(DocExtractionRetailTenantType));
