import React from 'react';
import * as moment from 'moment';

function DisplayDate({ date, format = 'MM/DD/YYYY', utcFormat = false }) {
  return (
    <span>{ utcFormat ? moment(date).utc().format(format) : moment(date).format(format)}</span>
  );
}

export default DisplayDate;
