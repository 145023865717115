import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import style from './DocExtractionCahflowSummary.module.scss';
import TreeView from '../../core/TreeView/TreeView';
import DocExtractionSummaryTotal from '../DocExtractionSummary/DocExtractionSummaryTotal';
import { cashflowPeriodIdentifierSelector, summaryDataSelector } from '../../../store/selectors';
import { setCashFlowPeriodIdentifier, setSummaryData } from '../../../store/currentDocument';


function SummaryGroupByHead({ summaryDetail, onChange }) {
  return <TreeView 
    title="Summary" 
    treeData={summaryDetail} 
    canDrag={false} 
    rowHeight={40} 
    onChange={onChange} 
    style={{ height: `calc(100vh - 260px)`}} 
    className="summaryTreeView"/>
}

function CashFlowAffix({ summaryData, onChange, onDropdownChange }) {
  const [ selectedOption, setSelectedOption ] = useState(summaryData?.periodColumns[0])
  const { summaryDetail, summaryTotal } = summaryData;

  useEffect(() => {
   const foundPeriodIdentiferIdx = summaryData?.periodColumns.findIndex(period => period.value === selectedOption.value);
   // Handling if period indentifier column is deleted from the workbook.
   if (foundPeriodIdentiferIdx === -1) { 
    setSelectedOption(summaryData?.periodColumns[0])
   }
  }, [summaryData?.periodColumns, selectedOption]);

  return (
    <>
       {
        summaryData && (
          <div className=''>
            <div className='row p-2'>
              <div className="col-12 mb-1">
                <Select
                  placeholder="Select Period"
                  value={selectedOption}
                  options={summaryData?.periodColumns}
                  onChange={(period) => {
                    onDropdownChange(period.value)
                    setSelectedOption(period)
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                  <SummaryGroupByHead summaryDetail={summaryDetail} onChange={onChange} />
              </div>
            </div>
            <DocExtractionSummaryTotal summaryTotal={summaryTotal} />
          </div>
        )
      }
    </>
  )
}

function DocExtractionCashflowSummary({ periodIdentifier, summaryData, store, onSyncSummary }) {
  useEffect(() => {
    store.dispatch(setCashFlowPeriodIdentifier(periodIdentifier));
  }, [store, periodIdentifier]);

  const onAccordionChange =(summary) => {
    store.dispatch(setSummaryData({ ...summaryData, summaryDetail: summary}));
  };

  const onDropdownChange = useCallback((period) => {
    store.dispatch(setCashFlowPeriodIdentifier(period));
  });

  return (
    <>
      <div className={`row align-items-center d-flex ${style.sectionHeader}`}>
        <div className={`pl-2 flex-grow-1 ${style.panelHeader}`}>
          <h3 className="small-heading mb-0">Cashflow Summary</h3>
        </div>
      </div>
      <CashFlowAffix 
        summaryData={summaryData}
        onChange={onAccordionChange} 
        onDropdownChange={onDropdownChange}
      />
    </>
  )
}


const mapStateToProps = (state) => ({
  summaryData: summaryDataSelector(state),
  periodIdentifier: cashflowPeriodIdentifierSelector(state)
});

export default connect(mapStateToProps)(withServices('store')(DocExtractionCashflowSummary));
