/* eslint-disable import/no-cycle */
import BaseCommand from './BaseCommand';
import { sheetIndex } from '../../../../constants';
import { TARGET_SHEET_NAME } from '../../../app/DocExtractionComponent/DataSpreadSheet';

class PasteCommand extends BaseCommand {
  hasUndo = true;

  run(dataSpreadSheet, options) {
    const { clipboardData, spreadsheet } = dataSpreadSheet;
    const { headerNames } = options;
    const { targetSheetIndex } = sheetIndex;
    if (spreadsheet.getActiveSheetIndex() === targetSheetIndex && clipboardData.copiedSourceColumnRange) {
      const { row, col, rowCount } = spreadsheet.getSelections(targetSheetIndex)[0];
      if (row < 0) {
        const targetColumnRange = { row, col, rowCount, colCount: clipboardData.copiedSourceColumnRange.colCount };
        dataSpreadSheet.copySourceColumnsToTarget(
          targetSheetIndex, headerNames, clipboardData.copiedSourceColumnRange, targetColumnRange,
        );
        spreadsheet.bindSheetHeader(spreadsheet.getSheet(targetSheetIndex));
      }
    } else if (spreadsheet.getActiveSheetIndex() === targetSheetIndex && !clipboardData.copiedSourceColumnRange) {
      const pastedRange = spreadsheet.getSelections(targetSheetIndex)[0];
      if (pastedRange?.row < 0) {
        const sheet = spreadsheet.getSheet(targetSheetIndex);
        const workbookData = spreadsheet.getWorkbookData();
        const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
        setTimeout(() => dataSpreadSheet.copyFromSameSheet(sheet, columns, pastedRange), 0)
      }
    }
  }

  undo(dataSpreadSheet, options) {
    options.isUndo = true;
    dataSpreadSheet.validateAndSetColumnValue(options.args);
  }
}

const pasteCommand = PasteCommand.get();

export default pasteCommand;
