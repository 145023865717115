import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row, Container } from 'react-bootstrap';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons/faRedoAlt";
import { prospectInvoicesListSelector, currentProspectSelector } from '../../../store/selectors';
import { displayDateFormat, displayCurrencyFormat } from '../../../utils/utils';
import CancelProspectInvoice from '../CancelProspectInvoice/CancelProspectInvoice';
import IconButton from '../../core/Button/IconButton';
import config from '../../../config';
import style from './ProspectInvoiceList.module.scss';
import './ProspectInvoicesList.scss';
import { withServices } from "reaf";

function ProspectInvoicesList({ prospect, invoices, toastrService, prospectsService }) {
  const rowStatusBackgroundColors = {
    default: '#DCDCDC',
    open: '#FFFFE0',
    paid: '#90EE90',
    void: '#DCDCDC'
  };

  const regexFilter = useMemo(() => ({
    type: 'RegexFilter',
    placeholder: 'Filter',
    delay: 1000
  }), []);

  function rowStyleFormat(row) {
    return { 
      backgroundColor: row && rowStatusBackgroundColors[row.status] ? 
      rowStatusBackgroundColors[row.status] : 
      rowStatusBackgroundColors.default 
    };
  }

  const displayDueDate = (rowVal) => moment(rowVal).add(config.invoiceExpiryInMonths, 'months').format('MM/DD/YYYY');

  const displayInvoiceNumber = (rowVal) => rowVal.invoiceNumber;
  const displayStatus = (status) => status.charAt(0).toUpperCase() + status.slice(1);
  const displayFailedAttempts = (rowVal, row) => {
    const failedAttempts = rowVal.failedAttempts || 0;
    const errorMessage = row.errorInfo || '';
    return `${failedAttempts} ${errorMessage}`;
  };

  const refreshPaymentStatus = useCallback(async(prospectUuid) => {
    await prospectsService.loadProspectInvoices(prospectUuid);
  }, []);

  const formatAction = useCallback((_, row) => {
    const openInvoice = row.status === 'open';
    return (
      <div className="d-flex flex-row">
        { openInvoice &&
        <CancelProspectInvoice prospect={prospect[0]} invoice={row} label={<IconButton className="smallIconButton" placement="left" title="Cancel Invoice" icon={faBan} />} />
        }
        <IconButton className="smallIconButton" placement="left"
            onClick={() => {
              navigator.clipboard.writeText(row.additionalInfo?.invoicePaymentLink).then(()=> console.log);
              toastrService.success('Payment link copied');
            }}
            title="Copy Payment Link" icon={faClipboard} />
      </div>
    );
  }, []);
  return (
    <>
      <Container fluid className={`p-2 pb-3 ${style.projectsSubHeader}`}>
        <Row className="align-items-center">
          <Col sm="auto">
            <h5 className={`${style.headingAligment}`}>
              Invoices for
              {' '}
              {prospect[0].companyName}
            </h5>
          </Col>
          <Col>
            <IconButton
              id="refersh-payments-status"
              onClick={() => refreshPaymentStatus(prospect[0].uuid)}
              title="Refresh Payment status" icon={faRedoAlt}
            />
          </Col>
        </Row>
      </Container>
      <div className="prospectInvoicesTable">
        <BootstrapTable
          containerClass="bootstrapTableCustom"
          data={invoices}
          tableStyle={{ background: '#fafafa' }}
          trStyle={rowStyleFormat}
          condensed
          hover
          pagination
          version="4"
        >
          <TableHeaderColumn
            dataField="uuid"
            isKey
            hidden
            dataSort
            width="60"
            dataAlign="center"
          >
        S.N.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="additionalInfo"
            dataFormat={displayInvoiceNumber}
            filter={regexFilter}
          >
        Number
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="amount"
            dataFormat={displayCurrencyFormat}
            dataSort
          >
        Amount (USD)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="transactionStatus"
            dataSort
          >
        Payment
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            dataSort
            dataFormat={displayStatus}
          >
        Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="additionalInfo"
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
            dataFormat={displayFailedAttempts}
          >
        Failed Attempts
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="createdAt"
            dataFormat={displayDateFormat}
            dataSort
          >
        Created On
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="createdAt"
            dataFormat={displayDueDate}
            dataSort
          >
        Due Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Actions"
            dataFormat={formatAction}
            dataSort
          >
        Actions
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  invoices: prospectInvoicesListSelector(state),
  prospect: currentProspectSelector(state)
});


export default connect(mapStateToProps)(withServices('toastrService', 'prospectsService')(ProspectInvoicesList));
