import React from 'react';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import AcceptTermsAndConditionsForm from '../../app/AcceptTermsAndConditionsForm/AcceptTermsAndConditionsForm';

const AcceptTermsAndConditionsPage = () => (
  <LoggedOutUserPage>
    <div className="vertical-section dotted-bg bg-light">
      <AcceptTermsAndConditionsForm />
    </div>
  </LoggedOutUserPage>
);

export default AcceptTermsAndConditionsPage;
