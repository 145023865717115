import React from 'react';
import { faEdit, faTrash, faKey, faLock, faLockOpen, faFileExport } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  Row, Col, Button, ListGroup
} from 'react-bootstrap';
import IconButton from '../../core/Button/IconButton';
import UserRoleBadge from '../UserRoleBadge/UserRoleBadge';
import './UserListItem.scss';

function UserListItem({
  user, onDelete, onResetPassword, onEdit, onEditTemplateAccess, onToggleAccess, onToggleApiConfigAccess
}) {
  return (
    <ListGroup.Item>
      <Row>
        <Col lg="1">
          <UserRoleBadge userRole={user.UserRole.role} />
        </Col>
        <Col lg="2" className="user-list-user-name" style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', maxWidth: '100%' }}>
          {`${user.firstName} ${user.lastName}`}
        </Col>
        <Col xs lg="3" className="overflow-hidden" style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap', maxWidth: '100%' }}>
          {user.email}
        </Col>
        <Col xs lg="1" className="overflow-hidden">
          {moment(user.createdAt).format('MM/DD/YYYY')}
        </Col>
        <Col xs lg="4">
          <IconButton
            onClick={() => onToggleAccess(user)}
            variant={user.isActive ? 'outline-success': 'outline-danger'}
            className="smallIconButton ml-2"
            title={user.isActive ? 'Deactivate' : 'Activate'}
            icon={user.isActive ? faLockOpen : faLock} />
          <IconButton
            onClick={() => onToggleApiConfigAccess(user)}
            variant={user.apiConfigAccess ? 'outline-success': 'outline-danger'}
            className="smallIconButton ml-2"
            title={user.apiConfigAccess ? 'Disable API Configuration' : 'Enable API Configuration'}
            icon={faFileExport} />
          {/* <IconButton variant="outline-primary" className="ml-2" icon={faEdit} title="Edit" onClick={() => onEdit(user)} /> */}
          <IconButton variant="outline-primary" className="ml-2" icon={faKey} text="Template Access" title="Template Access" onClick={() => onEditTemplateAccess(user)} />
          {/* <Button variant="outline-primary" size="sm" onClick={() => onResetPassword(user)} className="btn-xs ml-2">Reset Password</Button> */}
        </Col>
        <Col xs lg="1">
          {user.UserRole.role !== 'COMPANY_ADMIN' &&
            <IconButton variant="outline-danger" icon={faTrash} title="Delete" onClick={() => onDelete(user)} />
          }
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default UserListItem;
