import React, { useState, useCallback, useEffect } from 'react';
import { floorPlanChartFilter, floorPlanChartDataPoints } from '../../../constants';
import DocExtractionAffixBarStackChart from '../DocExtractionAffixChart/DocExtractionAffixBarStackChart';
import DocExtractionAffixChartFilter from '../DocExtractionAffixChart/DocExtractionAffixChartFilter';

export default function DocExtractionFloorPlanChart({ style, data, xAxisKeyName, isActive }) {
  const [chartData, setChartData] = useState(data);
  const [showLineChart, setShowLineChart] = useState(true);

  useEffect(() => {
    setChartData(Reflect.ownKeys(data[0]).filter(keyName => !floorPlanChartDataPoints.VACANCY_LOSS.includes(keyName)));
  }, []);

  const onChartFilterChange = useCallback((value) => {
    if (value === floorPlanChartFilter.SQFT) {
      setChartData(Reflect.ownKeys(data[0])
      .filter(keyName => !floorPlanChartDataPoints.VACANCY_LOSS.includes(keyName)));
      setShowLineChart(true);
    } else {
      setChartData(Reflect.ownKeys(data[0]).filter(keyName => !floorPlanChartDataPoints.SQFT.includes(keyName)));
      setShowLineChart(false);
    }
  });

  if (!isActive) {
    return null;
  }

  return (
    <div className={`${style.floorPlanChartWrapper}`}>
      <DocExtractionAffixChartFilter
        filterLabel=""
        filterInitialValue={floorPlanChartFilter.SQFT}
        filterChartData={Object.values(floorPlanChartFilter)}
        onChartFilterChange={(value) => onChartFilterChange(value)}
      />
      <div className={`${style.floorPlanChartWrapperBx} floorPlanChart`}>
        <DocExtractionAffixBarStackChart
          data={data}
          filterChart={chartData}
          xAxisKeyName={xAxisKeyName}
          defaultChartColor={['#233F2F', '#26A382', '#F3583B']}
          showLineChart={showLineChart}
          lineChartKeyName="Avg. SqFt"
          height={250}
        />
      </div>

    </div>
  );
}
