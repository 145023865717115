import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { omit, debounce, isEmpty } from 'lodash';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { faSyncAlt as refreshIcon } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faInfo as infoIcon } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faBroom as clearTagIcon } from '@fortawesome/free-solid-svg-icons/faBroom';
import { faPencilAlt as editIcon } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faSearch as searchIcon } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faLink as linkIcon } from '@fortawesome/free-solid-svg-icons/faLink';
import Form from 'react-bootstrap/Form';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { DocumentFileTypes } from '../../../constants';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import ProjectPopups from '../../app/ProjectPopups/ProjectPopups';
import TourIconButton from '../../app/IconButtons/TourIconButton';
import IconButton from '../../core/Button/IconButton';
import messages from '../../../../locales';
import style from './ProjectPageHeader.module.scss';
import { companyWithCustomApiConfigFeature, KKR_COMPANY_UUID } from '../../../config';
import {
  SpriteDownloadXlsxIcon,
  SpriteDownloadModelIcon
} from '../../core/SpriteIcon/SpriteIcon';
import {
  setIsReportEnabled,
  setIsReportShareLinkEnabled
} from '../../../store/currentProject';
import ProTag from '../../app/ProTag/ProTag';
import { isFreeBieApp, showPaymentPopup } from '../../../utils/utils';
import Link from '../../core/Link/Link';
import {
  currentProjectCanDownloadConsolidatedModeldSelector,
  isDocumentTaggingActiveSelector,
  setIsDocumentValidatedSelector,
  apiConfigIntegrationSelector,
  isConsolidatedModelDownloadingSelector,
  readOnlyAccessSelector,
  isAPIIntegrationFormEmpty,
  isAPIIntegrationFormConfigured,
  loggedInUserSelector,
  loggedInCompanySelector
} from '../../../store/selectors';
import {
  DocTypeFilterChanged,
  DocumentModelDownloaded,
  DocumentSearchedByKeyword,
  DocumentTourGuideClicked,
  ProjectDownloadedInFormatted,
  ProjectMarkedCompleted,
  ProjectNormalisedJsonDownloaded,
  ProjectNormalisedJsonSentToFTPServer,
  ProjectTaggingCleared,
  ProjectXlsxDownloaded,
  RefreshDocumentsButtonClicked,
  ReportLinkGenerated,
  ShowProjectAssumptionForm
} from '../../../constants/eventTrackerMessage';

function ModelDropDownItem({ templateModels, onDownloadIndividualModel, title }) {
  return (
    <>
      <Dropdown.Header
        bsPrefix={`download-model-header ${style.dropdownHeader}`}
      >
        {title}
      </Dropdown.Header>
      {templateModels &&
        templateModels.map((item) => (
          <Dropdown.Item
            key={item.uuid}
            onClick={() => onDownloadIndividualModel(item)}
          >
            <SpriteDownloadModelIcon />
            <span className={`${style.widthTextOverflow} text-with-ellipsis`}>
              {item.modelName}
            </span>
          </Dropdown.Item>
        ))}
      <Dropdown.Divider />
    </>
  );
}

function ProjectModelDropdownMenuItem({
  templateModels,
  onModelDownload,
  onDownloadModel
}) {
  return templateModels && templateModels.length > 1 ? (
    <ModelDropDownItem
      templateModels={templateModels}
      onDownloadIndividualModel={onModelDownload}
      title="Download Model"
    />
  ) : (
    <Dropdown.Item onClick={onDownloadModel}>
      <SpriteDownloadModelIcon />
      Download Model
    </Dropdown.Item>
  );
}

function ProjectExporterDropdownMenu({ projectExporters, onDownloadInFormat }) {
  return projectExporters.map((exporter, i) => (
    <Dropdown.Item key={exporter.name} onClick={() => onDownloadInFormat(exporter)}>
      <SpriteDownloadModelIcon />
      <span className={`${style.widthTextOverflow} text-with-ellipsis`}>
        Download&nbsp;
        {exporter.name}
      </span>
    </Dropdown.Item>
  ));
}

function ProjectPageHeader({
  onSearch,
  project,
  company,
  store,
  template,
  onFilterChange,
  projectsService,
  templateModelService,
  onHelpClick,
  disableProjectCompletion,
  templateModels,
  toastrService,
  projectExporters,
  router,
  selectedModelForReportGeneration,
  enableGenerateReport,
  isDocumentTaggingActive,
  isDocumentValidated,
  canDownloadConsolidatedModel,
  eventTrackerService,
  apiConfigIntegration,
  documentsUuidsForModel = [],
  isConsolidatedModelDownloading,
  readOnlyAccess,
  isApiIntegrationFormEmpty,
  isAPIIntegrationFormConfigured,
  loggedInUser,
  currentCompany
}) {
  const [showClearTaggingConfirm, setShowClearTaggingConfirm] = useState(false);
  const [showUpdateDDConfirm, setShowUpdateDDConfirm] = useState(false);
  const [showEditProject, setShowEditProject] = useState(false);
  const [showProjectAssumption, setShowProjectAssumption] = useState(false);
  const [projectCompletedStatus, setProjectCompletedStatus] = useState(false);
  const [showPropertyReportLink, setShowPropertyReportLink] = useState(false);
  const [sharedReportLinkDetail, setSharedReportLinkDetail] = useState({});
  const [showApiConfigModal, setShowApiConfigModal] = useState(false);
  const [apiPushInProgress, setApiPushInProgress] = useState(false);
  useEffect(() => setProjectCompletedStatus(project.isCompleted), [project]);

  const refreshDocuments = useCallback(() => {
    projectsService
      .loadCurrentProjectDocuments(project.uuid)
      .catch(console.log);
    eventTrackerService.track(RefreshDocumentsButtonClicked, project);
  }, [projectsService, project]);

  const downloadXlsx = useCallback(() => {
    projectsService.downloadXlsxForModelIntegration(project, documentsUuidsForModel).catch(console.log);
    eventTrackerService.track(ProjectXlsxDownloaded, project);
  }, [projectsService, project, documentsUuidsForModel]);

  const downloadProjectNormalizedJson = useCallback(() => {
    projectsService.downloadProjectNormalizedJson(project, documentsUuidsForModel).catch(() => {
      toastrService.error(messages.toastMessage.NORMALIZED_JSON_DOWNLOAD_ERROR);
    });
    eventTrackerService.track(ProjectNormalisedJsonDownloaded, project);
  }, [projectsService, project, documentsUuidsForModel]);

  const sendProjectNormalizedJsonToFTP = useCallback(() => {
    projectsService.sendProjectNormalizedJsonToFTPServer(project, documentsUuidsForModel).catch(() => {
      toastrService.error(messages.toastMessage.NORMALIZED_JSON_SEND_ERROR);
    });
    eventTrackerService.track(ProjectNormalisedJsonSentToFTPServer, project);
  }, [projectsService, project, documentsUuidsForModel]);

  const configureApiIntegration = useCallback(async (integrationConfigrationUuid, documentsUuidsForModel) => {
    try {
      setApiPushInProgress(true)
      toastrService.info(messages.toastMessage.API_INTEGRATION_PUSH_IN_PROCESS, null, 1000, false);
      await projectsService.configureApiIntegration(project.uuid, integrationConfigrationUuid, documentsUuidsForModel);
      setApiPushInProgress(false)
      toastrService.hideInfo();
      toastrService.success(messages.toastMessage.API_INTEGRATION_PUSH_SUCCESS, null, 10000);
    } catch (error) {
      // show error toastr
      toastrService.hideInfo();
      setApiPushInProgress(false);
      toastrService.error(messages.toastMessage.API_INTEGRATION_PUSH_ERROR, null, 7000);
    }
  }, [projectsService, project]);

  const markProjectCompletion = useCallback(() => {
    onPopupHide();
    projectsService
      .markProjectCompletion(project, !project.isCompleted)
      .catch(() => {
        toastrService.error(messages.toastMessage.PROJECT_COMPLETION_ERROR);
      });
    eventTrackerService.track(ProjectMarkedCompleted, project);
  }, [projectsService, project]);

  const downloadModel = useCallback(() => {
    projectsService.downloadProjectModel(project).catch(() => {
      toastrService.error(messages.toastMessage.TEMPLATE_MODEL_ERROR);
    });
    eventTrackerService.track(DocumentModelDownloaded);
  }, [projectsService, project]);

  const downloadInFormat = useCallback(
    (exporter) => {
      projectsService.exportProjectInFormat(project, exporter).catch(() => {
        toastrService.error(messages.toastMessage.TEMPLATE_MODEL_ERROR);
      });
      eventTrackerService.track(ProjectDownloadedInFormatted, project);
    },
    [projectsService, project]
  );

  function onPopupHide() {
    setShowClearTaggingConfirm(false);
    setShowUpdateDDConfirm(false);
    setShowEditProject(false);
    setShowProjectAssumption(false);
    setShowPropertyReportLink(false);
    setShowApiConfigModal(false);
  }

  async function onClearTaggingConfirm(project) {
    onPopupHide();
    await projectsService.clearTagging(project).catch(console.log);
    eventTrackerService.track(ProjectTaggingCleared, project);
    toastrService.success(messages.toastMessage.CLEAR_PROJECT_TAG);
  }

  async function getReportLink() {
    const reportLinkDetails = await projectsService.createReportLink(
      project.uuid
    );
    eventTrackerService.track(ReportLinkGenerated, project);
    setSharedReportLinkDetail(reportLinkDetails);
    setShowPropertyReportLink(true);
  }

  const onModelDownload = (templateModel) => {
    templateModelService
      .downloadIndividualModel(company, project, templateModel)
      .catch(() => {
        toastrService.error(messages.toastMessage.TEMPLATE_MODEL_ERROR);
      });
    eventTrackerService.track(DocumentModelDownloaded);
  };

  const onProjectConsolidatedModelDownload = useCallback(() => {
    projectsService.onDownloadConsolidatedModel(
      project,
      company,
      documentsUuidsForModel
    ).catch(() => {
      toastrService.error(messages.toastMessage.TEMPLATE_MODEL_ERROR);
    });
    eventTrackerService.track(DocumentModelDownloaded);
  }, [documentsUuidsForModel, projectsService, project, company]);

  const disableReportButton = !enableGenerateReport
    ? true
    : selectedModelForReportGeneration &&
    !selectedModelForReportGeneration.hasReportTemplateFile;

  useEffect(() => {
    if (selectedModelForReportGeneration) {
      store.dispatch(
        setIsReportEnabled(
          selectedModelForReportGeneration.hasReportTemplateFile
        )
      );
      store.dispatch(setIsReportShareLinkEnabled(company.canShareReport));
    }
  }, [store, selectedModelForReportGeneration, company]);

  const trackOnSearchEvent = useCallback(
    debounce((value) => {
      eventTrackerService.track(DocumentSearchedByKeyword, {
        value
      });
    }, 2000),
    []
  );

  const canShareReport = useMemo(() => {
    if (!company.canShareReport) {
      return false;
    }
    return !showPaymentPopup(company, 'canDownloadReportWorkbook');
  }, [company]);

  return (
    <Container fluid className="bg-light border-bottom sub-header">
      <Row className="align-items-center">
        <Col id="BreadCrumbSection">
          <Breadcrumbs items={[{ to: '#', content: project.name }]} />
        </Col>
        <Col xs="auto" className="p-0">
          {!isFreeBieApp && !readOnlyAccess && (
            <IconButton
              disabled={isDocumentTaggingActive || !currentCompany.canEditProjectDetails}
              id="ShowEditProjectButton"
              title="Edit Property"
              onClick={() => setShowEditProject(true)}
              className="btn-xs mr-1 smallIconButton"
              icon={editIcon}
            />
          )}
          {template.keysToMask.length === 0 && !isFreeBieApp && !readOnlyAccess && (
            <IconButton
              id="ClearProjectTaggingButton"
              title="Clear Property Tagging"
              disabled={isDocumentTaggingActive}
              onClick={() => setShowClearTaggingConfirm(true)}
              className="btn-xs mr-1 smallIconButton"
              icon={clearTagIcon}
            />
          )}

          <IconButton
            id="projectInfo"
            className="btn-xs smallIconButton"
            title="Property Information"
            onClick={() => {
              setShowProjectAssumption(true);
              eventTrackerService.track(ShowProjectAssumptionForm);
            }}
            icon={infoIcon}
          >
            Property Info
          </IconButton>

          <IconButton
            id="RefreshProjectDocumentButton"
            title="Refresh Status"
            onClick={refreshDocuments}
            className="btn-xs smallIconButton"
            icon={refreshIcon}
          />
          {(selectedModelForReportGeneration && canShareReport) && !canDownloadConsolidatedModel && !readOnlyAccess && (
            <IconButton
              id="ReportSharableLink"
              title="Share Report Link"
              disabled={disableReportButton || isDocumentTaggingActive}
              onClick={getReportLink}
              className="btn-xs smallIconButton"
              icon={linkIcon}
            />
          )
          }
        </Col>

        {selectedModelForReportGeneration && (
          <Col xs="auto" className="p-0 mr-1 button-aligned">
            <Link
              id="projectReport"
              disabled={disableReportButton || isDocumentTaggingActive || readOnlyAccess}
              style={{
                padding: '4px 10px 5px'
              }}
              className={`btn btn-xs btn-primary ${style.markComplete} ${disableReportButton || readOnlyAccess ? `${style.deadLink} disabled` : ''
                }`}
              route="projectReportsPage"
              params={{
                projectUuid: project.uuid,
                templateUuid: template.uuid,
                modelUuid: selectedModelForReportGeneration.uuid,
                documentsUuidsForModel: documentsUuidsForModel
              }}
            >
              Property Report
            </Link>
          </Col>
        )}

        <Col xs="auto" className="p-0 mr-1 button-aligned">
          <Button
            id="MarkProjectCompletedButton"
            disabled={
              disableProjectCompletion ||
              project.isCompleted ||
              isDocumentTaggingActive ||
              readOnlyAccess
            }
            variant="secondary"
            onClick={() => setShowUpdateDDConfirm(true)}
            size="sm"
            className={`btn-xs ${style.markComplete} ${projectCompletedStatus ? 'btn-success' : ''
              } ${style.markComplete} ${projectCompletedStatus ? style.deadLink : ''
              }`}
          >
            {project.isCompleted ? 'DD Updated' : 'Update DD'}
          </Button>
          <ProTag className={style.badgePro} />
        </Col>
        <Col xs="auto" className="p-0 mr-1">
          <Form.Control
            id="DocumentTypeFilter"
            as="select"
            size="sm"
            onChange={(e) => {
              onFilterChange(e.target.value);
              eventTrackerService.track(DocTypeFilterChanged, {
                value: e.target.value
              });
            }}
            className={`form-control-xs ${style.formControlXs}`}
          >
            <option value="">All Document Types</option>
            {Reflect.ownKeys(omit(DocumentFileTypes, ['NONE']))
              .filter((key) => DocumentFileTypes[key].label)
              .filter((key) => {
                const docTypes = (company && company.documentTypes) || [];
                return docTypes.includes(key);
              })
              .map((key) => (
                <option value={key} key={key}>
                  {DocumentFileTypes[key].label}
                </option>
              ))}
            <option value="NONE">Without Tagging</option>
          </Form.Control>
        </Col>
        <Col xs="auto" className="p-0 mr-1 button-aligned">
          <DropdownButton
            id="ModelIntegrationButton"
            size="sm"
            className="dropdown-btn-xs"
            disabled={disableProjectCompletion || isDocumentTaggingActive || readOnlyAccess}
            title="Model Integration"
          >
            {templateModels &&
              templateModels.length > 0 && company?.canDownloadModel && (
                <>
                  {
                    !canDownloadConsolidatedModel && (
                      <ProjectModelDropdownMenuItem
                        templateModels={templateModels}
                        onModelDownload={onModelDownload}
                        onDownloadModel={downloadModel}
                      />
                    )
                  }
                  
                  {
                    canDownloadConsolidatedModel && (
                      <>
                        <Dropdown.Item disabled={isConsolidatedModelDownloading || readOnlyAccess} style={{ width: 250 }} onClick={onProjectConsolidatedModelDownload}>
                          <SpriteDownloadModelIcon />
                          Download Model
                        </Dropdown.Item>
                        <Dropdown.Divider />
                      </>
                    )
                  }
                </>
              )}
            {
              !canDownloadConsolidatedModel && !readOnlyAccess && (
                <Dropdown.Item onClick={downloadXlsx}>
                <SpriteDownloadXlsxIcon />
                 Download Xlsx
               </Dropdown.Item>
              )
            }
            {company && company.allowProjectJson && !readOnlyAccess && (
              <Dropdown.Item onClick={downloadProjectNormalizedJson}>
                <SpriteDownloadModelIcon />
                Download Project Json
              </Dropdown.Item>
            )}
            {company && company.allowProjectJson && !readOnlyAccess && currentCompany.uuid === KKR_COMPANY_UUID && (
              <Dropdown.Item onClick={sendProjectNormalizedJsonToFTP}>
                <SpriteDownloadModelIcon />
                Send Project Json through SFTP
              </Dropdown.Item>
            )}
            <ProjectExporterDropdownMenu
              projectExporters={projectExporters}
              onDownloadInFormat={downloadInFormat}
            />
          </DropdownButton>
          <ProTag className={style.badgePro} />
        </Col>
        {apiConfigIntegration && apiConfigIntegration.name && apiConfigIntegration.isConfigEnabled && loggedInUser.apiConfigAccess && !readOnlyAccess && (
          <Col xs="auto" className="p-0 mr-1 button-aligned">
            <Button
              disabled={
                disableProjectCompletion || isDocumentTaggingActive || isEmpty(documentsUuidsForModel)|| apiPushInProgress
                || (isAPIIntegrationFormConfigured && isApiIntegrationFormEmpty)
              }
              id="apiIntegraion"
              variant="primary"
              onClick={() => {
                if(companyWithCustomApiConfigFeature.indexOf(company.uuid) !== -1) {
                  setShowApiConfigModal(!showApiConfigModal)
                } else {
                  configureApiIntegration(apiConfigIntegration.uuid, documentsUuidsForModel)
                }
              }}
              size="sm"
              className={`btn-xs btn-primary`}
            >
              {apiConfigIntegration.name}
            </Button>
          </Col>
        )}
        <Col sm="auto" className="p-0 mr-1">
          <span className="search-icon">
            <IconButton icon={searchIcon} variant />
          </span>
          <input
            id="DocumentSearchBox"
            type="text"
            className={`
              form-control form-control-sm form-control-xs d-inline-block search-bar-inputField ${style.formControlXs}`
            }
            placeholder="Search"
            onChange={(e) => {
              const { value } = e.target;
              onSearch(value);
              trackOnSearchEvent(value);
            }}
            style={{ width: 200 }}
          />
        </Col>
        <Col sm="auto" className="pl-0 tourIconBtn">
          <TourIconButton
            onClick={(e) => {
              onHelpClick(e);
              eventTrackerService.track(DocumentTourGuideClicked);
            }}
            id="ShowTourButton"
          />
        </Col>
      </Row>
      <ProjectPopups
        project={project}
        onHide={onPopupHide}
        showProjectAssumption={showProjectAssumption}
        showEditProject={showEditProject}
        showPropertyReportLink={showPropertyReportLink}
        sharedReportLinkDetail={sharedReportLinkDetail}
        showDeleteConfirm={false}
        showUpdateDDConfirm={showUpdateDDConfirm}
        showClearTaggingConfirm={showClearTaggingConfirm}
        onUpdateDDConfirm={markProjectCompletion}
        onClearTaggingConfirm={() => onClearTaggingConfirm(project)}
        showApiConfigModal={showApiConfigModal}
        setShowApiConfigModal={setShowApiConfigModal}
        integrationConfigrationUuid={apiConfigIntegration ? apiConfigIntegration.uuid : null}
        documentsUuidsForModel={documentsUuidsForModel}
        readOnlyAccess={readOnlyAccess}
      />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  isDocumentTaggingActive: isDocumentTaggingActiveSelector(state),
  canDownloadConsolidatedModel: currentProjectCanDownloadConsolidatedModeldSelector(state),
  isDocumentValidated: setIsDocumentValidatedSelector(state),
  apiConfigIntegration: apiConfigIntegrationSelector(state),
  isConsolidatedModelDownloading: isConsolidatedModelDownloadingSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state),
  isApiIntegrationFormEmpty: isAPIIntegrationFormEmpty(state),
  isAPIIntegrationFormConfigured: isAPIIntegrationFormConfigured(state),
  loggedInUser: loggedInUserSelector(state),
  currentCompany: loggedInCompanySelector(state)
});

export default connect(mapStateToProps)(
  withServices(
    'projectsService',
    'store',
    'templateModelService',
    'router',
    'toastrService',
    'eventTrackerService'
  )(ProjectPageHeader)
);
