import { Field, Form } from 'formik';
import React from 'react';
import { Form as BSForm, Card, Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import clikLogoImg from '../../../assets/images/clikai-logo.svg';
import Link from '../../core/Link/Link';
import PasswordViewer from '../PasswordViewer/PasswordViewer';
import { PasswordStrengthInfo } from '../PasswordStrengthInfo/PasswordStrengthInfo';
import style from './ResetPasswordFormComponent.module.scss';
import {
  SpriteLockIcon,
  SpriteEmailIcon
} from '../../core/SpriteIcon/SpriteIcon';
import ResetPasswordConfirmationPopUp from './ReserPasswordFormPopUp';

function ResetPasswordFormComponent({
  status, isSubmitting, showResetConfirmation, onRedirectToLogin
}) {
  return (
    <Card className="form-card">
      <Card.Body className="form-card-body passwordGroupRow">
        <div className="loginFormLogo">
          <img src={clikLogoImg} alt="Clik Logo" />
        </div>
        {
          status && status.error &&
          (
            <Alert variant="danger">
              <div>{status.error}</div>
            </Alert>
          )
        }
        {
          status && status.success &&
          (
            <Alert variant="success">
              <div>{status.success}</div>
              <Link route="loginPage">
                Click here to continue to login page
              </Link>
            </Alert>
          )
        }

        {!showResetConfirmation ? (
          <Form>
            <BSForm.Group controlId="email" className="position-relative">
              <BSForm.Label>Email Address</BSForm.Label>
              <div className="loginFormIcon confirmLoginFormIcon">
                <SpriteEmailIcon />
              </div>
              <Field
                id="email"
                className={`form-control ${style.formControl}`}
                type="email"
                name="email"
                placeholder="Enter your email address"
                readOnly
              />
              <FieldErrorMessage name="email" />
            </BSForm.Group>

            <BSForm.Group controlId="password">
              <BSForm.Label>
                Password
                {' '}
                <PasswordStrengthInfo />
              </BSForm.Label>
              <PasswordViewer
                id="password" name="password"
                placeholder="Enter your new password" />
              <FieldErrorMessage name="password" />
            </BSForm.Group>

            <BSForm.Group
              controlId="confirmPassword"
              className="position-relative">
              <BSForm.Label>Confirm Password</BSForm.Label>
              <div className="loginFormIcon passwordIcon confirmResetPassword">
                <SpriteLockIcon />
              </div>
              <Field
                id="confirmPassword"
                className={`form-control ${style.formControl}`}
                type="password"
                name="confirmPassword"
                placeholder="Re-enter your new password"
              />
              <FieldErrorMessage name="confirmPassword" />
            </BSForm.Group>

            <Field id="token" type="hidden" name="token" />

            <Button
              className="btn btn-primary btn-block"
              type="submit"
              id="submitButton"
              disabled={isSubmitting}
            >
              Continue
            </Button>
          </Form>
        ) :
          <ResetPasswordConfirmationPopUp redirectToLogin={onRedirectToLogin} />
        }

        <small>
          <Link
            className="text-secondary text-decoration-none mt-3 d-inline-block"
            route="loginPage">
              Continue to login screen
          </Link>
        </small>
      </Card.Body>
    </Card>
  );
}

export default ResetPasswordFormComponent;
