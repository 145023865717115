import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import IconButton from '../../core/Button/IconButton';
import {
  currentDocumentTypeSelector,
  currentProjectSelector,
  dynamicAssetTypesAsObjSelector,
  currentProjectTemplateSelector,
} from '../../../store/selectors';
import { DocumentFileTypes } from '../../../constants';
import './DocExtractionAffixWrapper.scss';
import {
  isOSBaseType,
  isOSFullBaseType,
  isRetailBaseType
} from '../../../utils/utils';
import { AffixSidebarOpened } from '../../../constants/eventTrackerMessage';

function MfRRSidebar({ onAffixOpen, isAcquisition, activeTabKey, currentTemplate }) {

  const isClikIrr = useMemo(() => currentTemplate?.templateName.toLowerCase() === "clik irr", [currentTemplate]);

  return (
    <nav className="nav nav-tabs">
      <IconButton
        className={`nav-link affixVerticalNav chargeCode ${
          activeTabKey === 'chargeCode' ? 'active' : ''
        }`}
        title="Charge Code Configuration"
        onClick={() => {
          onAffixOpen('chargeCode');
        }}
        variant="link"
        id="configCC"
        showIcon={false}
      />
      <IconButton
        className={`nav-link affixVerticalNav unitStatus ${
          activeTabKey === 'unitStatus' ? 'active' : ''
        }`}
        title="Unit Status Configuration"
        onClick={() => {
          onAffixOpen('unitStatus');
        }}
        variant="link"
        id="unitStatusTabButton"
        showIcon={false}
      />
      <IconButton
        className={`nav-link affixVerticalNav floorPlan ${
          activeTabKey === 'floorPlan' ? 'active' : ''
        }`}
        title="Floor Plan Configuration"
        onClick={() => {
          onAffixOpen('floorPlan', isAcquisition ? 790 : 600);
        }}
        variant="link"
        id="floorPlanTabButton"
        showIcon={false}
      />
      {/* {isAcquisition && !isClikIrr && (
        <IconButton
          className={`nav-link affixVerticalNav documentSummary ${
            activeTabKey === 'unitMix' ? 'active' : ''
          }`}
          title="Unit Mix Configuration"
          onClick={() => {
            onAffixOpen('unitMix', 1300);
          }}
          variant="link"
          id="floorPlanTabButton"
          showIcon={false}
        />
      )} */}
      <IconButton
        className={`nav-link affixVerticalNav analysis ${
          activeTabKey === 'analysis' ? 'active' : ''
        }`}
        title="Lease Trend Analysis"
        onClick={() => {
          onAffixOpen('analysis');
        }}
        variant="link"
        id="analysisTabButton"
        showIcon={false}
      />
    </nav>
  );
}

function MfOSSidebar({ onAffixOpen }) {
  return (
    <nav className="nav nav-tabs">
      <IconButton
        className="nav-link affixVerticalNav documentSummary"
        title="Summary and Analysis"
        onClick={() => {
          onAffixOpen('documentSummary');
        }}
        variant="link"
        id="osAffixButton"
        showIcon={false}
      />
    </nav>
  );
}

function MfOSFullSidebar({ onAffixOpen }) {
  return (
    <nav className="nav nav-tabs">
      <IconButton
        className="nav-link affixVerticalNav documentSummary"
        title="Summary and Analysis"
        onClick={() => {
          onAffixOpen('documentSummary');
        }}
        variant="link"
        id="osAffixButton"
        showIcon={false}
      />
    </nav>
  );
}

function RetailRRSidebar({ onAffixOpen, activeTabKey }) {
  return (
    <nav className="nav nav-tabs">
      <IconButton
        className={`nav-link affixVerticalNav chargeCode ${
          activeTabKey === 'chargeCode' ? 'active' : ''
        }`}
        title="Charge Code Configuration"
        onClick={() => {
          onAffixOpen('chargeCode');
        }}
        variant="link"
        id="configCC"
        showIcon={false}
      />
      <IconButton
        className={`nav-link affixVerticalNav occupancy ${
          activeTabKey === 'occupancy' ? 'active' : ''
        }`}
        title="Unit Status Configuration"
        onClick={() => {
          onAffixOpen('occupancy');
        }}
        variant="link"
        id="occupancyTabButton"
        showIcon={false}
      />
      <IconButton
        className={`nav-link affixVerticalNav leaseType ${
          activeTabKey === 'leaseType' ? 'active' : ''
        }`}
        title="Lease Type Configuration"
        onClick={() => {
          onAffixOpen('leaseType');
        }}
        variant="link"
        id="leaseTypeTabButton"
        showIcon={false}
      />
      <IconButton
        className={`nav-link affixVerticalNav tenantType ${
          activeTabKey === 'tenantType' ? 'active' : ''
        }`}
        title="Lease Tenant Type Configuration"
        onClick={() => {
          onAffixOpen('tenantType');
        }}
        variant="link"
        id="tenantTypeTabButton"
        showIcon={false}
      />
      <IconButton
        className={`nav-link affixVerticalNav analysis ${
          activeTabKey === 'analysis' ? 'active' : ''
        }`}
        title="Lease Expiration Schedule"
        onClick={() => {
          onAffixOpen('analysis');
        }}
        variant="link"
        id="analysisTabButton"
        showIcon={false}
      />
    </nav>
  );
}

function DocExtractionAffixSidebar({
  onAffixOpen,
  currentDocumentType,
  isAcquisition,
  project,
  eventTrackerService,
  activeTabKey,
  dynamicAssetTypes,
  currentTemplate,
}) {
  const assetType = useMemo(() => {
    if (isRetailBaseType(project.assetType, dynamicAssetTypes)) {
      return dynamicAssetTypes.RETAIL.assetType;
    } else {
      return dynamicAssetTypes.MULTIFAMILY.assetType;
    }
  }, [project.assetType, dynamicAssetTypes]);

  const documentType = useMemo(() => {
    if (isOSBaseType(currentDocumentType)) {
      return DocumentFileTypes.OPERATING_STATEMENT.baseType;
    } else if (isOSFullBaseType(currentDocumentType)) {
      return DocumentFileTypes.OS_FULL.baseType;
    } else if (currentDocumentType === DocumentFileTypes.RENT_ROLL.key) {
      return DocumentFileTypes.RENT_ROLL.key;
    }
  }, [currentDocumentType]);

  const openAffix = useCallback(
    (...args) => {
      onAffixOpen(...args);
      eventTrackerService.track(AffixSidebarOpened, {
        tabName: args[0]
      });
    },
    [onAffixOpen]
  );

  return (
    <div className="affixVerticalTabs">
      {isOSBaseType(documentType) && (
        <MfOSSidebar onAffixOpen={openAffix} activeTabKey={activeTabKey} />
      )}

     {isOSFullBaseType(documentType) && (
        <MfOSFullSidebar onAffixOpen={openAffix} activeTabKey={activeTabKey} />
      )}

      {assetType === dynamicAssetTypes.MULTIFAMILY.assetType &&
        documentType === DocumentFileTypes.RENT_ROLL.key && (
          <MfRRSidebar
            isAcquisition={isAcquisition}
            onAffixOpen={openAffix}
            activeTabKey={activeTabKey}
            currentTemplate={currentTemplate}
          />
        )}

      {assetType === dynamicAssetTypes.RETAIL.assetType &&
        documentType === DocumentFileTypes.RENT_ROLL.key && (
          <RetailRRSidebar
            onAffixOpen={openAffix}
            activeTabKey={activeTabKey}
          />
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  project: currentProjectSelector(state),
  currentDocumentType: currentDocumentTypeSelector(state),
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state),
  currentTemplate: currentProjectTemplateSelector(state)
});

export default connect(mapStateToProps)(
  withServices('eventTrackerService')(DocExtractionAffixSidebar)
);
