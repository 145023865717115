import React, { useState, useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import { pick } from 'lodash';
import { connect } from 'react-redux';
import CompanyTemplateFormComponent from '../CompanyTemplateForm/CompanyTemplateFormComponent';
import { currentCompanySelector, companiesDetailsSelector, templateKeysToMaskSelector, dynamicAssetTypesAsObjSelector } from '../../../store/selectors';
import messages from '../../../../locales/en-US';
import { TEMPLATE_TAGS } from '../../../constants';

function CompanyTemplateUpdateForm({
  onSubmitSuccess,
  bindSubmission,
  templateService,
  companyUUID,
  template,
  companyDetail,
  keysToMask,
  dynamicAssetTypes,
  isGlobal
}) {
  const initialValues = useMemo(() => ({
    templateName: template.templateName,
    tag: template.tag,
    assetTypes: template.assetTypes || [],
    keysToMask: template.keysToMask,
    isReportEnabled: template.isReportEnabled
  }), [template]);

  const CompanyTemplateFormSchema = useMemo(() => Yup.object().shape({
    templateName: Yup.string().trim()
      .transform(value => (value === '' ? undefined : value))
      .test(
        'slashes',
        "Please remove slashes",
        (value) => (value || '').indexOf('/') < 0,
      )
      .required(messages.errors.templateName),
    tag: Yup.mixed().oneOf(Reflect.ownKeys(TEMPLATE_TAGS)),
    assetTypes: Yup.array()
      .of(Yup.mixed().oneOf(Reflect.ownKeys(dynamicAssetTypes), 'This asset type is not allowed'), 'Please select a valid asset type.')
      .nullable()
  }), [dynamicAssetTypes]);

  const companyAssetTypes = useMemo(() => {
    let companyAssetTypes = dynamicAssetTypes;
    if (!isGlobal) {
      const { assetTypes } = companyDetail;
      companyAssetTypes = assetTypes ? pick(dynamicAssetTypes, assetTypes) : dynamicAssetTypes;
    }
    template.assetTypes && template.assetTypes.forEach(assetType => {
      companyAssetTypes[assetType].disabled = true;
    });
    return companyAssetTypes;
  }, [companyDetail, dynamicAssetTypes, template.assetTypes, isGlobal]);

  const [mappingsFile, setMappingsFile] = useState(null);
  const [dictionaryFile, setDictionaryFile] = useState(null);
  const [categorySequenceFile, setCategorySequenceFile] = useState(null);
  const [summarySheetTotalConfigFile, setSummarySheetTotalConfigFile] = useState(null);
  const [isReportEnabled, setReportEnabled] = useState(template.isReportEnabled);

  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      const updateObj = {
        ...values,
        isReportEnabled,
        mappings: mappingsFile,
        dictionary: dictionaryFile,
        categorySequence: categorySequenceFile,
        summarySheetTotalConfig: summarySheetTotalConfigFile
      };
      const uploadPromise = isGlobal ?
        templateService.updateGlobalTemplate(template, updateObj) :
        templateService.updateTemplate(companyUUID, template, updateObj);
        await uploadPromise;
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }, [
    onSubmitSuccess,
    templateService,
    companyUUID,
    template,
    mappingsFile,
    dictionaryFile,
    categorySequenceFile,
    summarySheetTotalConfigFile,
    isReportEnabled,
    isGlobal
  ]);

  return (
    <Formik
      initialValues={{ ...initialValues, isReportEnabled }}
      validationSchema={CompanyTemplateFormSchema}
      onSubmit={onSubmit}
      component={formikProps => (
        <CompanyTemplateFormComponent
          {...formikProps}
          bindSubmission={bindSubmission}
          assetTypes={companyAssetTypes}
          keysToMask={keysToMask}
          isReportEnabled={isReportEnabled}
          maskedTemplate={template.keysToMask.length > 0}
          selectedTemplateFile={mappingsFile}
          selectedDictionaryFile={dictionaryFile}
          selectedCategorySequenceFile={categorySequenceFile}
          selectedSummarySheetTotalConfigFile={summarySheetTotalConfigFile}
          onTemplateFileChange={e => setMappingsFile(e.target.files[0])}
          onDictionaryFileChange={e => setDictionaryFile(e.target.files[0])}
          onRemoveTemplateFile={() => setMappingsFile(null)}
          onRemoveDictionaryFile={() => setDictionaryFile(null)}
          onCategorySequenceFileChange={e => setCategorySequenceFile(e.target.files[0])}
          onCategorySequenceRemoveFile={() => setCategorySequenceFile(null)}
          onSummarySheetTotalConfigChange={e => setSummarySheetTotalConfigFile(e.target.files[0])}
          onSummarySheetTotalConfigRemoveFile= {() => setSummarySheetTotalConfigFile(null)}
          onChangeReportEnabled={(isReportEnabled) => setReportEnabled(isReportEnabled)}
        />
      )
      }
    />
  );
}

const mapStateToProps = state => {
  const currentCompany = currentCompanySelector(state);
  return ({
    companyDetail: companiesDetailsSelector(state)[currentCompany],
    keysToMask: templateKeysToMaskSelector(state),
    dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
  });
};

export default connect(mapStateToProps)(withServices('templateService')(CompanyTemplateUpdateForm));
