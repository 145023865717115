import React, { useCallback } from 'react';
import { Card, Container } from 'react-bootstrap';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './TutorialVideoGrid.module.scss';
import { getTimestampToFormatedTime } from '../../../utils/utils';

const TutorialVideoGridItem = ({ section, router, tutorial: tutorialVideo }) => {
  const playVideo = useCallback(() => {
    router.setRoute('tutorialVideos', {
      section,
      videoUuid: tutorialVideo.uuid,
    });
  }, [tutorialVideo, router]);

  return (
    <div onClick={playVideo} className={`cursor-pointer ${style.card}`}>
      <div>
        <Card.Img variant="top" src={tutorialVideo.thumbnailUrl} />
      </div>
      <div className={style.bodyWrapper}>
        <small className={`text-muted ${style.duration}`}>
          <FontAwesomeIcon icon={faClock} />{' '}
          {getTimestampToFormatedTime(tutorialVideo.duration)}
        </small>
        <div className={style.body}>
          <div className={`${style.title}`}>{tutorialVideo.title}</div>
          <div className={`${style.subtitle}`}>{tutorialVideo.description}</div>
        </div>
      </div>
    </div>
  );
};

const TutorialVideoGrid = ({ section, router, tutorials }) => (
    <Container>
      <div className={style.gridContainer}>
        {tutorials.map((tutorial) => (
          <div key={tutorial.uuid} className={style.gridItem}>
            <TutorialVideoGridItem
              {...{ tutorial, section, router, key: tutorial.uuid }}
            />
          </div>
        ))}
      </div>
    </Container>
  );

export default TutorialVideoGrid;
