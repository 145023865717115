import React from 'react';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';
import ProspectPaymentLinkForm from '../ProspectPaymentLinksList/ProspectPaymentLinkForm';

function PaymentLinkPopup({
  paymentLink, onHide, showCreatePaymentLinkPopup,
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showCreatePaymentLinkPopup &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showCreatePaymentLinkPopup} title="Add Payment Link">
            <ProspectPaymentLinkForm 
              bindSubmission={bindSubmission} 
              onSubmitSuccess={onHide} 
              paymentLink={paymentLink} 
            />
          </FormModal>
        )
      }
    </div>
  );
}

export default PaymentLinkPopup;
