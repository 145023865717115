
import React from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import RBToast from 'react-bootstrap/Toast';
import { toastrSelector } from '../../../store/selectors';
import { hideToastr } from '../../../store/notificationToastr';

function NotificationToastr({
  show, type, message, position, store, delay = 3000, autohide = true
}) {
  const elemPosition = position === 'top' ? { top: '5px' } : { bottom: '37px' };
  return (
    <RBToast
      show={show}
      delay={delay}
      autohide={autohide}
      onClose={() => store.dispatch(hideToastr())}
      className="text-center"
      style={{ ...elemPosition, ...{ position: 'absolute', left: '50%', marginLeft: -175 } }}
    >
      <RBToast.Body className={`bg-${type} text-light pt-2 pb-2`}>
        <strong>{message}</strong>
      </RBToast.Body>
    </RBToast>
  );
}

const mapStateToProps = (state) => {
  const {
    show, type, message, position, delay, autohide
  } = toastrSelector(state);
  return {
    show,
    type,
    message,
    position,
    delay,
    autohide
  };
};

export default connect(mapStateToProps)(withServices('store')(NotificationToastr));
