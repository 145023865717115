import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import messages from '../../../../locales';
import ProspectPaymentLinkFormComponent from './ProspectPaymentLinkFormComponent';

const ProspectPaymentLinkFormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter product name.'),
    amount: Yup.number().required('Please provide a valid amount.'),
    quantity: Yup.number().required('Please provide a valid quantity.'),
});


function ProspectPaymentLinkForm({
  onSubmitSuccess, bindSubmission, paymentLink, paymentLinksService, toastrService
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      await paymentLinksService.createPaymentLink(values);
      const message = messages.toastMessage.CREATE_PAYMENT_LINK;
      onSubmitSuccess();
      toastrService.success(message);
    } catch (error) {
      const errorMessage = error.message === 'name must be unique' ? 'This name is already registered in the system' : error.message;
      actions.setStatus({ error: errorMessage });
    }
    actions.setSubmitting(false);
  }
  return (
    <Formik
      initialValues={paymentLink}
      validationSchema={ProspectPaymentLinkFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <ProspectPaymentLinkFormComponent {...formikProps} 
        paymentLink={paymentLink} bindSubmission={bindSubmission} />
      }
    />
  );
}

export default withServices('paymentLinksService', 'toastrService')(ProspectPaymentLinkForm);
