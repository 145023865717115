
class ReportLinkService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
  }

  async getSharedReportLinks(searchText) {
    const response = await this.apiClient.get(
      '/admin/reportLinks'
    );

    return response.response;
  }

  async updateAdminReportLink({ projectUuid, uuid }, reportLinkDetails){
    const result =  await this.apiClient.put(
      `/projects/${projectUuid}/reportLink/${uuid}`, reportLinkDetails
    );
    return result.response;
  }

  async deleteAdminReportLink({ uuid }){
    const result =  await this.apiClient.delete(
      `/admin/reportLinks/${uuid}`
    );
    return result.response;
  }
}

export default ReportLinkService;
