import React, { useCallback, useMemo } from 'react';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft as backButton } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft';
import Link from '../../core/Link/Link';
import { appConfig } from '../../../config';
import ChangePasswordForm from '../../app/ChangePasswordForm/ChangePasswordForm';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import UserProfile from '../../app/UserProfile/UserProfile';
import TermsAndConditions from '../../app/TermsAndConditions/TermsAndConditions';
import ReleaseNotes from '../../app/ReleaseNotes/ReleaseNotes';
import PrivacyPolicy from '../../app/PrivacyPolicy/PrivacyPolicy';
import VideoTutorial from '../../app/VideoTutorial/VideoTutorial';
import LightButton from '../../core/LightButton/LightButton';
import style from './AccountSettingsPage.module.scss';
import profileIcon from "../../../assets/images/profile.svg";
import passwordIcon from "../../../assets/images/password.svg";
import termIcon from "../../../assets/images/term.svg";
import privacyIcon from "../../../assets/images/privacy-policy.svg";
import releaseIcon from "../../../assets/images/note.svg";
import SsoConfig from '../../app/SsoConfig/SsoConfig';
import { loggedInCompanySelector, userRoleSelector } from '../../../store/selectors';
import { USER_ROLES } from '../../../constants';
import ManageUsers from '../../app/ManageUsers/ManageUsers';
import ManagePayments from '../../app/ManagePayments/ManagePayments';

function getSection(section) {
  if (section === 'terms') {
    return <TermsAndConditions style={style} />;
  } if (section === 'changePassword') {
    return <ChangePasswordForm />;
  } if (section === 'privacy') {
    return <PrivacyPolicy style={style} />;
  } if (section === 'release') {
    return <ReleaseNotes style={style} />;
  } if (section === 'tips') {
    return <VideoTutorial />;
  } if (section === 'ssoConfig') {
    return <SsoConfig style={style} />;
  } if (section === 'manageUsers') {
    return <ManageUsers style={style} />;
  } if (section === 'managePayments') {
    return <ManagePayments style={style} />;
  }
  return <UserProfile />;
}

function AccountSettingsPage({
  section, router, loggedInCompany, userRole, authService
}) {
  if (!!appConfig.isFreebieApp && section === 'changePassword') {
    changeSection('profile');
  }
  function changeSection(nextSection) {
    router.setRoute('accountSettingsPage', { section: nextSection });
  }

  const canConfigSSO = useCallback(() => (
    !!loggedInCompany.isSSOEnabled && userRole === USER_ROLES.COMPANY_ADMIN.key
  ), [loggedInCompany, userRole]);

  const routeName = useMemo(() => authService.resolveRoute(), []);
  const isCompanyAdmin = useMemo(() => userRole === USER_ROLES.COMPANY_ADMIN.key, [userRole]);
  return (
    <LoggedInUserPage>
      <div className="horizontal-section">
        <div className={`vertical-section border-right p-0  flex-grow-0 ${style.sidebarLeftCol}`}>
          <Link
            route={routeName}
            className={style.backButton}
          >
            <FontAwesomeIcon icon={backButton} className={style.backButtonIcon} /> Back to Application
          </Link>

          <LightButton customClassName={style.settingNavLinkBtn} variant="link" onClick={() => changeSection('profile')} active={section === 'profile'}>
            <span className={style.navIcons}><img src={profileIcon} alt="profile" /></span>
            Profile
          </LightButton>
          {
            isCompanyAdmin && !loggedInCompany.isEnterpriseCompany && (
              <LightButton customClassName={style.settingNavLinkBtn} variant="link" onClick={() => changeSection('managePayments')} active={section === 'managePayments'}>
                <span className={style.navIcons}><img src={profileIcon} alt="managePayments" /></span>
                Subscription Details
              </LightButton>
            )
          }
          
          {!appConfig.isFreebieApp && !(loggedInCompany.isSSOEnabled && userRole === USER_ROLES.COMPANY_USER.key) && (
            <LightButton customClassName={style.settingNavLinkBtn} onClick={() => changeSection('changePassword')} active={section === 'changePassword'}>
              <span className={style.navIcons}>
                <img src={passwordIcon} alt="Password" />
              </span>
              Change Password
            </LightButton>
          )}
          <LightButton customClassName={style.settingNavLinkBtn} onClick={() => changeSection('terms')} active={section === 'terms'}>
            <span className={style.navIcons}><img src={termIcon} alt="Terms and Conditions" /></span>
            Terms & Conditions
          </LightButton>
          {!appConfig.isFreebieApp && (
            <LightButton customClassName={style.settingNavLinkBtn} onClick={() => changeSection('release')} active={section === 'release'}>
              <span className={style.navIcons}><img src={releaseIcon} alt="Release Notes" /></span>
              Release Notes
            </LightButton>
          )}
          {/* <LightButton customClassName={style.settingNavLinkBtn} onClick={() => changeSection('tips')} active={section === 'tips'}>
            <span className={style.navIcons}><img src={privacyIcon} alt="Video tutorial" /></span>
            Tutorial
          </LightButton> */}
          <LightButton customClassName={style.settingNavLinkBtn} onClick={() => changeSection('privacy')} active={section === 'privacy'}>
            <span className={style.navIcons}><img src={privacyIcon} alt="Privacy Policy" /></span>
            Privacy Policy
          </LightButton>
          {canConfigSSO() && (
            <>
              <LightButton customClassName={style.settingNavLinkBtn} onClick={() => changeSection('ssoConfig')} active={section === 'ssoConfig'}>
                <span className={style.navIcons}>
                  <img src={passwordIcon} alt="Password" />
                </span>
                Single Sign On
              </LightButton>
              <LightButton customClassName={style.settingNavLinkBtn} onClick={() => changeSection('manageUsers')} active={section === 'manageUsers'}>
                <span className={style.navIcons}>
                  <img src={passwordIcon} alt="Password" />
                </span>
                Manage Users
              </LightButton>
            </>
          )}
        </div>
        <div className="vertical-section position-relative">
          <div className={`fill-parent ${style.wrapperScroll}`}>
            {getSection(section)}
          </div>
        </div>
      </div>
    </LoggedInUserPage>
  );
}

const mapStateToProps = (state) => ({
  loggedInCompany: loggedInCompanySelector(state),
  userRole: userRoleSelector(state)
});

export default connect(mapStateToProps)(withServices('router', 'authService')(AccountSettingsPage));
