import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import FileUploader from "../FileUploader/FileUploader";
import { VALID_TEMPLATE_MODEL_FILE_EXTENSION, VALID_TEMPLATE_REPORT_FILE_EXTENSION } from '../../../constants';

function UpdateTemplateModelFormComponent({
  status, isSubmitting, bindSubmission,
  submitForm, onFileChange, onRemoveFile,
  selectedFile, assetTypes, setReportTemplateFile,
  reportTemplateFile
}) {
  bindSubmission(submitForm, isSubmitting);

  return (
    <div>
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <span>{status.error}</span>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <label htmlFor="modelName">Model Name</label>
          <Field id="modelName" className="form-control" name="modelName" type="text" />
          <FieldErrorMessage name="modelName" />
        </div>
        <div id="AssetTypeField" className="form-group">
          <label htmlFor="assetType">Asset Type</label>
          <Field id="assetType" className="form-control" name="assetType" component="select">
            <option value="">Select a asset type</option>
            {
              Reflect.ownKeys(assetTypes).map(key => (
                <option value={key} key={key}>{assetTypes[key].label}</option>
              ))
            }
          </Field>
          <FieldErrorMessage name="assetType" />
        </div>
        <FileUploader title="Model" validExtensions={VALID_TEMPLATE_MODEL_FILE_EXTENSION} onFileChange={onFileChange} onRemoveFile={onRemoveFile} selectedFile={selectedFile} />
        <FileUploader title="ReportTemplate" validExtensions={VALID_TEMPLATE_REPORT_FILE_EXTENSION} onFileChange={e => setReportTemplateFile(e.target.files[0])} onRemoveFile={() => setReportTemplateFile(null)} selectedFile={reportTemplateFile} />
      </Form>
    </div>
  );
}

export default UpdateTemplateModelFormComponent;
