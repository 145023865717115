import React from 'react';

function withClicks(delay = 250) {
  return function (SomeComponent) {
    const createClickHandler = ((onClick, onDoubleClick) => {
      let lastTimer = null;
      return function (e) {
        if (!lastTimer) {
          lastTimer = setTimeout(() => {
            if (lastTimer) {
              clearTimeout(lastTimer);
              lastTimer = null;
              onClick(e);
            }
          }, delay);
        } else {
          clearTimeout(lastTimer);
          lastTimer = null;
          onDoubleClick(e);
        }
      };
    });

    return function ({ onClick, onDoubleClick, ...rest }) {
      const handleClicks = createClickHandler(onClick, onDoubleClick);
      return <SomeComponent onClick={handleClicks} {...rest} />;
    };
  };
}

export default withClicks;
