import { sheetIndex } from '../../../../constants';
import BaseCommand from '../../../core/Spreadjs/Commands/BaseCommand';

const { targetSheetIndex } = sheetIndex;

class OSDragFillCommand extends BaseCommand {
  hasUndo = true;

  run(osSpreadsheet, options) {
    const { spreadsheet } = osSpreadsheet;
    const { undoState } = options;
    const {
      row,
      rowCount,
      selectedCol: col,
      previousCategoryValues,
      previousHeadValues,
      categoryCol,
      isCopyCategory,
      isCopySubCategory,
      subCategoryCol,
      copyCategoryValue,
      copySubCategoryValue,
      previousSubCategoryValues
    } = undoState;
    const { targetSheetColCount, sourceSheetColCount } = options.colCount;
    const { headIndex } = options;
    if (headIndex !== col) {
      return;
    }
    spreadsheet.suspend();
    if (options.isUndo) {
      Array(rowCount).fill(0).forEach((_, index) => {
        const rowIndex = row + index;
        osSpreadsheet.onTargetHeadValueChange(rowIndex, col, previousHeadValues[index], sourceSheetColCount, targetSheetColCount);
        spreadsheet.setCellValue(targetSheetIndex, rowIndex, categoryCol, previousCategoryValues[index]);
        spreadsheet.setCellValue(targetSheetIndex, rowIndex, subCategoryCol, previousSubCategoryValues[index]);
      });
    } else {
      Array(rowCount).fill(0).forEach((_, index) => {
        const rowIndex = row + index;
        const colValue = spreadsheet.getCellValue(targetSheetIndex, rowIndex, col);
        osSpreadsheet.onTargetHeadValueChange(rowIndex, col, colValue, sourceSheetColCount, targetSheetColCount);
        if (isCopyCategory) {
          spreadsheet.setCellValue(targetSheetIndex, rowIndex, categoryCol, copyCategoryValue);
        }
        if (isCopySubCategory) {
          spreadsheet.setCellValue(targetSheetIndex, rowIndex, subCategoryCol, copySubCategoryValue);
        }
      });
    }
    spreadsheet.resume();
    }

    undo(osSS, options) {
      options.isUndo = true;
      this.run(osSS, options);
      setTimeout(() => osSS.workbook.undoManager().undo(), 0);
    }
}

export const osDragFillCommand = OSDragFillCommand.get();
