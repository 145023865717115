import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Field } from 'formik';
import FieldErrorMessage from '../../app/FieldErrorMessage/FieldErrorMessage';

const FormikFieldWithPrepend = ({
  label, name, fieldType = 'number', prependText, ...props
}) => (
  <Form.Group controlId="exampleForm">
    <Form.Label>{label}</Form.Label>
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text id="inputGroupPrepend">{prependText}</InputGroup.Text>
      </InputGroup.Prepend>
      <Field
        className="form-control"
        type={fieldType}
        name={name}
        {...props}
      />
    </InputGroup>
    <FieldErrorMessage name={name} />
  </Form.Group>
);

export default FormikFieldWithPrepend;
