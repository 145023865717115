/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import ProspectFormComponent from './ProspectFormComponent';
import messages from '../../../../locales';
import {phoneRegExp, usCanadaPhoneRegex} from '../../../constants';

const ProspectFormSchema = Yup.object().shape({
  prospect: Yup.object().shape({
    companyName: Yup.string().required('Please enter company name.'),
    companyAdminFirstName: Yup.string().required('Please provide a valid first name.'),
    companyAdminLastName: Yup.string().required('Please provide a valid last name.'),
    numberOfLicenses: Yup.number().min(1).typeError('Please provide valid number of licenses').required('Please provide number of licenses'),
    companyAdminEmail: Yup.string().email('Please provide a valid email.').required('Please provide a valid email'),
    amountPerLicense: Yup.number().typeError('Please provide a valid amount per license').required(),
    totalAmount: Yup.number().integer().required(),
    companyAddress: Yup.object().shape({
      address: Yup.string().required('Please enter company address.'),
      country: Yup.string().test('alphabets', 'Country must only contain alphabets', (value) => /^[A-Za-z]+$/.test(value)).required('Country is required.'),
      zip: Yup.string().required('Postal Code is required.')
    }),
    companyPhone: Yup.string().matches(usCanadaPhoneRegex, 'Please provide a valid phone number.').required('Please provide a valid phone number.')
  })
});


function ProspectForm({
  onSubmitSuccess, bindSubmission, prospect, prospectsService, toastrService
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      let message = '';
      if (values.prospect?.hasOwnProperty('uuid')) {
        await prospectsService.updateProspect(prospect, values);
        message = messages.toastMessage.UPDATE_PROSPECT;
      } else {
        await prospectsService.createProspect(values);
        message = messages.toastMessage.CREATE_PROSPECT;
      }
      onSubmitSuccess();
      toastrService.success(message);
    } catch (error) {
      const errorMessage = error.message === 'companyAdminEmail must be unique' ? 'This prospect email is already registered in the system' : error.message;
      actions.setStatus({ error: errorMessage });
    }
    actions.setSubmitting(false);
  }
  return (
    <Formik
      initialValues={prospect}
      validationSchema={ProspectFormSchema}
      onSubmit={onSubmit}
      component={
        formikProps => <ProspectFormComponent {...formikProps} prospect={prospect} bindSubmission={bindSubmission} />
      }
    />
  );
}

export default withServices('prospectsService', 'toastrService')(ProspectForm);
