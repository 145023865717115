import React, { useCallback } from 'react';
import { Form, Field } from 'formik';
import { capitalize, upperCase } from 'lodash';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import SwitchButton from '../../core/SwitchButton/SwitchButton';

function DynamicAssetTypeFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue, allowedMLBaseClasses
}) {

  const ToggleButton = ({ isActive, title, fieldName, setFieldValue }) => (
    <div className="d-flex justify-content-between">
      <label>{title}</label>
      <div>
        <SwitchButton
          isActive={isActive}
          onChange={(value) => setFieldValue(fieldName, value)}
        />
      </div>
    </div>
  );

  const {
    dynamicAssetType: {
      canRestrictRentRoll
    }
  } = values;
  bindSubmission(submitForm, isSubmitting);

  const onAssetTypeChange = useCallback((event) => {
    const fieldValue = event.target.value.replace(/ /g, '_');
    setFieldValue("dynamicAssetType.assetType", upperCase(fieldValue))
  }, [])

  return (
    <div>

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <Form>
        <h5>Dynamic Asset Type</h5>
        <hr />
        <div className="form-group">
          <label htmlFor="dynamicAssetType.assetType">Asset Type</label>
          <Field id="dynamicAssetType.assetType" className="form-control text-uppercase" onBlur={onAssetTypeChange} name="dynamicAssetType.assetType" type="text" placeholder="Asset Type" />
          <FieldErrorMessage name="dynamicAssetType.assetType" />
        </div>
        <div className="form-group">
          <label htmlFor="dynamicAssetType.label">Label</label>
          <Field id="dynamicAssetType.email" className="form-control text-capitalize" onBlur={(e) => setFieldValue("dynamicAssetType.label", capitalize(e.target.value))}   name="dynamicAssetType.label" type="text" placeholder="Label" />
          <FieldErrorMessage name="dynamicAssetType.label" />
        </div>

        <div className="form-group">
          <label htmlFor="dynamicAssetType.badgeText">Badge Text</label>
          <Field id="dynamicAssetType.badgeText" className="form-control" name="dynamicAssetType.badgeText" type="text" placeholder="Badge Text" />
          <FieldErrorMessage name="dynamicAssetType.badgeText" />
        </div>

        <div className="form-group">
          <label htmlFor="dynamicAssetType.baseClass">BaseClass</label>
          <Field id="dynamicAssetType.baseClass" className="form-control" name="dynamicAssetType.baseClass" component="select">
            {
                allowedMLBaseClasses.map(key => (
                <option value={key} key={key}>{key}</option>
              ))
            }
          </Field>
          <FieldErrorMessage name="dynamicAssetType.baseClass" />
        </div>
        
        <div className="form-group">
        <ToggleButton
          {...{
            setFieldValue,
            title: 'Restrict Rent Roll',
            isActive: canRestrictRentRoll,
            fieldName: 'dynamicAssetType.canRestrictRentRoll'
          }}
        />
        </div>

      </Form>
    </div>
  );
}

export default DynamicAssetTypeFormComponent;