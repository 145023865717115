import React, {
  useEffect, useState, useCallback
} from 'react';
import {
  Card,
  Button
} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import moment from 'moment';
import {
  faCheckCircle as successfulPaymentIcon
} from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle as failedPaymentIcon } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { companyUsersAsListSelector, loggedInCompanySelector } from '../../../store/selectors';
import UserPopups from '../UserPopups/UserPopups';
import pageStyle from './ManagePayments.module.scss';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import { displayCurrencyFormat } from '../../../utils/utils';

const paymentStatusMapping = {
  succeeded: 'Success',
  failed: 'Failed',
  processing: 'Processing',
};
const PaymentStatus = ({ status }) => paymentStatusMapping[status] || status;

const IsTrial = ({ company }) => {
  const { trialDays, createdAt } = company;
  const trialExpiresOn = moment(createdAt).add(+trialDays, 'days');
  const remianingTrialDays = trialExpiresOn.diff(moment(), 'days');
  const remainingDaysMessage = remianingTrialDays > 0 ? 
    `${remianingTrialDays} days remaining` : 
    `Expired ${Math.abs(remianingTrialDays)} days before`;
  return (
    <>
      <div className="row">
        <div className="col-6">
          <h5>
            Trial
            <small className="text-muted d-block">{remainingDaysMessage}</small>
          </h5>
        </div>
        <div className="col-6 text-right">
          <h6>Trial Ending: <DisplayDate date={trialExpiresOn.toDate()} /></h6>
        </div>
      </div>
    </>
  );
};

const IsNotTrial = ({ subscriptionDetail, setShowDeleteConfirmPopup }) => {
  const { status } = subscriptionDetail;
  return (
    <>
      <div className="row">
        <div className="col-6">
          <h5>
            {subscriptionDetail.subcriptionTitle}
            <small className="text-muted d-block">{moment(subscriptionDetail.endDate).diff(moment(), 'days')} days remaining</small>
          </h5>
        </div>
        <div className="col-6 text-right">
          <h6>{status === 'Trial' ? 'Trial' : 'Plan'} Ending: <DisplayDate date={subscriptionDetail.endDate} /></h6>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-left">
          {
            status === 'Active' || status === 'Trial' ? <Button className="mr-2" variant="outline-danger" onClick={() => setShowDeleteConfirmPopup(true)
            }>Cancel Subscription</Button> : (
              <span>Subscription cancelled on <DisplayDate date={subscriptionDetail.cancelledOnDate} /></span>
            )
          }
        </div>
      </div>
    </>
  );
}


const AccountSettingPageWrapper = ({ style, title, children }) => (
  <div className={`container-fluid ${style.pageOuterSection}`}>
    <div className="row">
      <div className={`col-12 d-flex border-bottom ${style.sectionHeader}`}>
        <h3>{title}</h3>
      </div>
    </div>
    <div className="justify-content-center">
      {children}
    </div>
  </div>
);

const ManagePayments = ({
  style, company, paymentService
}) => {
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [paymentHistory, setPaymentHsitory] = useState([]);

  useEffect(() => {
    if (company.uuid) {
      paymentService.getPaymentHistory().then((paymentHistory) => {
        setPaymentHsitory(paymentHistory);
      });
    }

  }, [company]);
  const { subscriptionDetail, isTrialOffering } = company;

  const handleCancelConfirm = useCallback(() => {
    paymentService.cancelSubscription(subscriptionDetail.subscriptionId);
    setShowDeleteConfirmPopup(false);
  }, [subscriptionDetail, paymentService]);

  

  const SubscptionDetailComponent = !isTrialOffering && subscriptionDetail ? IsNotTrial : IsTrial;

  return (
    <AccountSettingPageWrapper title="Subscription Details" style={style}>
      <div className={pageStyle.bodyWrapper}>
        <div className={pageStyle.innerWrapper}>
          <div className="mt-3">
            <h6 className="font-weight-bold">Current Subscription</h6>
            <Card className="mt-3 shadow-sm">
              <Card.Body style={{ maxHeight: '500px', overflow: 'auto' }}>
                <SubscptionDetailComponent
                  company={company}
                  setShowDeleteConfirmPopup={setShowDeleteConfirmPopup}
                  subscriptionDetail={subscriptionDetail} />
              </Card.Body>
            </Card>
          </div>


          <div className={`${pageStyle.billingHistory} position-relative mt-5`}>
            <h6 className="font-weight-bold mb-3">Billing History</h6>
            <BootstrapTable
              pagination
              options={{
                sizePerPage: 5,
                sizePerPageList: [{
                  text: '5', value: 5
                }, {
                  text: '10', value: 10
                }, {
                  text: 'All', value: paymentHistory.length
                }]
              }}
              data={paymentHistory}
              tableStyle={{ background: '#fafafa' }}
            >
              <TableHeaderColumn
                dataField="uuid"
                isKey
                hidden
              >
                S.N.
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="createdAt"
                dataSort
                dataFormat={(date) => <DisplayDate date={date} />}
                width="60"
              >
                Payment date
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="subcriptionTitle"
                dataSort
                filter={{ type: 'TextFilter', delay: 500 }}
                width="60"

              >
                Details
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="amount"
                dataSort
                filter={{ type: 'TextFilter', delay: 500 }}
                width="60"
                dataFormat={(amount) => `${displayCurrencyFormat(amount)}`}
              >
                Amount
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="status"
                dataSort
                filter={{ type: 'TextFilter', delay: 500 }}
                width="60"
                dataFormat={(status) => (
                    <>
                      <FontAwesomeIcon icon={
                        status === 'succeeded' ? successfulPaymentIcon : failedPaymentIcon
                      }
                        className={status === 'succeeded' ? 'text-success' : 'text-danger'}
                      />
                      &nbsp; <PaymentStatus status={status} />
                    </>
                  )}

              >
                Status
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
          <UserPopups
            showCancelPlan={showDeleteConfirmPopup}
            onDeleteConfirm={handleCancelConfirm}
            onDeleteCancel={() => setShowDeleteConfirmPopup(false)}
          />
        </div>
      </div>
    </AccountSettingPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  company: loggedInCompanySelector(state),
  companyUsers: companyUsersAsListSelector(state)
});

export default connect(mapStateToProps)(withServices('userService', 'paymentService')(ManagePayments));
