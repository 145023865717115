import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  ticketList: {
    tickets: {}
  },
  documentsSummary: {}
};

const [
  {
    setTickets, setTicket
  },
  reducer
] = createDuck('autouw/tickets', {
  _initialState: initialState,

  setTickets: tickets => state => ({
    ...state,
    ticketList: {
      ...state.ticketList,
      tickets: normalize(tickets, 'uuid')
    }
  }),
  setTicket: ticket => state => ({
    ...state,
    ticketList: {
      ...state.ticketList,
      tickets: {
        ...state.ticketList.tickets,
        [ticket.uuid]: ticket
      }
    }
  })
});

export {
  setTickets, setTicket
};

export default reducer;
