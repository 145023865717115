import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { components } from 'reaf';
import * as Yup from 'yup';
import messages from '../../../../locales';
import SignUpFromComponent from './SignUpFromComponent';
import { PASSWORD_SCHEMA, SOCIAL_LOGIN_TYPES } from '../../../constants';
import { setSelfSignupStatus } from '../../../store/auth';

// eslint-disable-next-line max-len
const SignUpFormSchema = Yup.object().shape({
  company: Yup.object().shape({
    name: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required(messages.errors.validationError.companyName),
  }),
  admin: Yup.object().shape({
    firstName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid first name.'),
    lastName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid last name.'),
    email: Yup.string().email('Please provide a valid email.').required('Please provide a valid email'),
    password: PASSWORD_SCHEMA,
    confirmPassword: Yup.string().required(messages.errors.auth.reenterPassword)
    .oneOf([Yup.ref('password'), null], messages.errors.auth.passwordMismatch)
  })
});

function SignUpForm({ authService, store, noVerification, onlyEmailSignup, redirect = true }) {
  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      actions.setSubmitting(true);
      await authService.signup(values, redirect, noVerification);
      store.dispatch(setSelfSignupStatus(true));
    } catch (e) {
      console.log(e);
      actions.setSubmitting(false);
      actions.setStatus({ error: e.message });
    }

    actions.setSubmitting(false);
  }, []);

  const onGoogleLoginSuccess = useCallback(async (googleUser) => {
    await authService.socialLogin({
      idToken: googleUser.getAuthResponse().id_token,
      socialLoginType: SOCIAL_LOGIN_TYPES.GOOGLE
    });
    store.dispatch(setSelfSignupStatus(true));
  });

  const onGoogleLoginFailure = useCallback((error) => {
    console.log(error);
  });

  return (
    <Formik
      initialValues={{
        company: { name: '' },
        admin: { firstName: '', lastName: '', email: '' }
      }}
      validationSchema={SignUpFormSchema}
      onSubmit={onSubmit}
      component={
        formikProps => (
          <SignUpFromComponent
            {...formikProps}
            onGoogleLoginSuccess={onGoogleLoginSuccess}
            onGoogleLoginFailure={onGoogleLoginFailure}
            noVerification={noVerification}
            onlyEmailSignup={onlyEmailSignup}
          />
        )}
    />
  );
}

const SignUpFormSimple = components.withServices('store', 'authService')(SignUpForm);

export const SignUpFormMemo = React.memo(SignUpFormSimple);

export default SignUpFormMemo;
