import React from 'react';
import RDatePicker from 'react-datepicker';
import './DatePicker.scss';

function DatePicker({
  field, form, dateFormat, placeholderText, ...rest
}) {
  const { onChange, name, ...others } = field;

  function onRDPChange(date) {
    onChange({
      target: {
        name,
        value: date
      }
    });
  }

  return (
    <RDatePicker dateFormat={dateFormat} {...rest} {...others} onChange={onRDPChange} autoComplete="off" placeholderText={placeholderText || dateFormat} />
  );
}

export default DatePicker;
