import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome as homeIcon } from '@fortawesome/free-solid-svg-icons/faHome';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import Link from '../../core/Link/Link';
import { userRoleSelector } from '../../../store/selectors';
import { USER_ROLES } from '../../../constants';
import { BreadcrumClicked } from '../../../constants/eventTrackerMessage';
import style from './Breadcrumbs.module.scss'

function Breadcrumbs({
  rootRoute = 'projectsPage',
  items,
  userRole,
  eventTrackerService
}) {
  const logoRoute = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      userRole === USER_ROLES.SUPER_ADMIN.key
        ? 'superAdminHomePage'
        : userRole === USER_ROLES.ACCOUNTS_EXECUTIVE.key
        ? 'accountsExecutivePage'
        : rootRoute,
    [userRole]
  );

  const onHomeButtonClicked = useCallback(() => {
    eventTrackerService.track(BreadcrumClicked, {
      title: 'Home'
    });
  }, []);

  const onBreadcrumClicked = useCallback((item) => {
    eventTrackerService.track(BreadcrumClicked, {
      title: item.content,
      to: item.to,
      route: item.route
    });
  }, []);

  return (
    <div className={style.breadcrumbContainer}>
      <span id="BreadcrumbHome">
        <Link route={logoRoute} onClick={onHomeButtonClicked}>
          <FontAwesomeIcon icon={homeIcon} />
        </Link>
      </span>
      {items.map((item, i) => (
        <div id={`Breadcrumb-${i}`} key={`${i}${item.to}`}>
          {
            item.to !== '#' ? (
              <Link
                className={style.breadcrumbLink}
                route={item.route}
                params={item.params}
                to={item.to}
                onClick={() => onBreadcrumClicked(item)}
              >
                {item.content}
              </Link>
            ) : (
              <div 
                className={`${style.breadcrumbLink} ${style.breadcrumbWithoutLink}`}
                onClick={() => onBreadcrumClicked(item)}
              >
                {item.content}
              </div>
            )
          }
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: userRoleSelector(state)
});

export default connect(mapStateToProps)(
  withServices('eventTrackerService')(Breadcrumbs)
);
