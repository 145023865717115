import BaseCommand from '../../../core/Spreadjs/Commands/BaseCommand';
import { SOURCE_SHEET_NAME, TARGET_SHEET, TARGET_SHEET_NAME } from '../DataSpreadSheet';

class OSUpdateRowColorCommand extends BaseCommand {
  hasUndo = true;

  run(osSS, options) {
    const {
      sheetIndex,
      previousHeadValues,
      previousCategoryValues,
      previousSubCategoryValues,
      range: { row, col, rowCount, colCount },
      headIndex,
      categoryIndex,
      subCategoryIndex,
      isUndo,
    } = options;

    if (sheetIndex === TARGET_SHEET) {
      const { datasheet, spreadsheet } = osSS;
      const [sourceSheetData, targetSheetData] = datasheet.getSheetSpecificData([SOURCE_SHEET_NAME, TARGET_SHEET_NAME]);
      const sourceSheetColCount = datasheet.getColumnCount(sourceSheetData);
      const targetSheetColCount = datasheet.getColumnCount(targetSheetData);

      if (col <= headIndex && headIndex < col + colCount) {
        for (let i = row, count = 0; i < rowCount + row; i++, count++) {
          const rowId = spreadsheet.getCellValue(TARGET_SHEET, i, 0);
          const headValue = isUndo ? previousHeadValues[count] : spreadsheet.getCellValue(TARGET_SHEET, i, headIndex);
          const backColor = osSS.getRowColorByHead(headValue);

          if (col <= subCategoryIndex && subCategoryIndex < col + colCount) {
            // nothing needs to be done, everything should be copied correctly

          } else if (col <= categoryIndex && categoryIndex < col + colCount) {
            // subcategory not copied, category is
            const categoryValue =
              isUndo ? previousCategoryValues[count] : spreadsheet.getCellValue(TARGET_SHEET, i, categoryIndex);
            let subCategoryValue = '';
            if (subCategoryIndex > -1) {
              subCategoryValue = isUndo ? previousSubCategoryValues[count] : '';
            }
            osSS.onTargetHeadValueChange(i, headIndex, headValue, -1, -1, categoryValue, subCategoryValue);
          } else {
            // only head is copied
            const categoryValue = isUndo ? previousCategoryValues[count] : '';
            let subCategoryValue = '';
            if (subCategoryIndex > -1) {
              subCategoryValue = isUndo ? previousSubCategoryValues[count] : '';
            }
            osSS.onTargetHeadValueChange(i, headIndex, headValue, -1, -1, categoryValue, subCategoryValue);
          }


          datasheet.updateRowBackColorForColumnValue(TARGET_SHEET, 0, rowId, backColor, 0, targetSheetColCount);
          datasheet.updateRowBackColorForColumnValue(TARGET_SHEET, 0, rowId, backColor, 0, sourceSheetColCount);
        }
      }
    }
  }

  undo(osSS, options) {
    options.isUndo = true;
    this.run(osSS, options);
    // and undo the previous command i.e. the paste
    setTimeout(() => osSS.workbook.undoManager().undo(), 0);
  }
}

export const osUpdateRowColorCommand = OSUpdateRowColorCommand.get();
