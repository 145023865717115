import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  templates: {},
  currentCompany: '',
  templatesWithModels: {},
  numberOfOSMonthsToHide: 3
};

const [
  { setTemplates, setCurrentCompany, setTemplateModels, setNumberOfOSMonthsToHide },
  reducer
] = createDuck('autouw/company', {
  _initialState: initialState,

  setTemplates: templates => state => ({
    ...state,
    templates
  }),
  setCurrentCompany: companyUUID => state => ({
    ...state,
    currentCompany: companyUUID
  }),
  setTemplateModels: templatesWithModels => state => ({
    ...state,
    templatesWithModels: normalize(templatesWithModels, 'uuid')
  }),
  setNumberOfOSMonthsToHide: numberOfOSMonthsToHide => state => ({
    ...state,
    numberOfOSMonthsToHide
  }),
});

export {
  setTemplates,
  setCurrentCompany,
  setTemplateModels,
  setNumberOfOSMonthsToHide
};

export default reducer;
