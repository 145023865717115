import BaseEvent from './BaseEvent';

export default class CopyEvent extends BaseEvent {
  handle(datasheet) {
    datasheet.workbook.commandManager().execute({
      cmd: 'copyCommand',
    });
    
    if (datasheet.isOSBaseType) {
      datasheet.categoryCopiedValueRange();
      datasheet.subCategoryCopiedValueRange();
    } 
    datasheet.clipboardData.copiedHeaderColumnValues = [];
  }
}
