import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => (
  <div className="d-flex justify-content-center">
    <Spinner animation="grow" />
  </div>
);

const WithLoader = ({ children, isLoading }) => <>{isLoading ? <Loader /> : children}</>;

export default WithLoader;
