import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
// import * as serviceWorker from './serviceWorker';

import { createApplication } from 'reaf';

import GC from '@grapecity/spread-sheets';
import * as ExcelIO from '@grapecity/spread-excelio';
import services from './app/config/services';
import appReducer from './app/store';
import { GCConfig } from './app/config';

const {
  app, container
} = createApplication(services, appReducer);

window['container'] = container;

// TODO: figure out a better way
container.get('authService').registerSideEffects(container.get('sideEffect'));
container.get('apiClient').registerSideEffects(container.get('sideEffect'));
container.get('projectsService').registerSideEffects(container.get('sideEffect'));
container.get('companyService').registerSideEffects(container.get('sideEffect'));


if (GCConfig.licenseKey) {
  GC.Spread.Sheets.LicenseKey = GCConfig.licenseKey;
  ExcelIO.LicenseKey = GCConfig.licenseKey;
}

app.start().catch(error => console.log(error));

export {
  container
};
export default app;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
