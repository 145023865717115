import { setAppConfigurationList } from '../store/appConfiguration';

class FallbackExtractionService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
  }

  async convertDocToTable({document_title, assetType, period_from, period_to, file, from, to, version, documentUuid, projectUuid }) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('document_title', document_title);
    formData.append('assetType', assetType);
    formData.append('period_from', period_from);
    formData.append('period_to', period_to);
    formData.append('from', from);
    formData.append('to', to);
    formData.append('version', version);
    formData.append('documentUuid', documentUuid)
    formData.append('projectUuid', projectUuid);

    const tableData = await this.apiClient.post(
      '/extractionFallback/docToTable',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return tableData.response;
  }

  async extractData(values) {
    const extractedData = await this.apiClient.post(
      '/extractionFallback/extract',
      values
    );
    return extractedData.response;
  }
}

export default FallbackExtractionService;
