import React, { useMemo } from 'react';
import Tour from 'reactour';
import Button from 'react-bootstrap/Button';

const resetPasswordPageSteps = (onClose) => [
  {
    selector: '#password',
    content: () => (
      <>
        <h6 className="border-bottom">Page Guide</h6>
        <p>Enter your password.</p>
      </>
    )
  },
  {
    selector: '#confirmPassword',
    content: () => (
      <>
        <h6 className="border-bottom">Page Guide</h6>
        <p>Re-enter your password to confirm.</p>
      </>
    )
  },
  {
    selector: '#submitButton',
    content: () => (
      <>
        <h6 className="border-bottom">Page Guide</h6>
        <p>Click to send reset password link to your email.</p>
      </>
    )
  },
  {
    selector: '#asdasdasdasdasd',
    content: () => (
      <>
        <h6 className="border-bottom">Page Guide</h6>
        <p>
          {`Once password reset is successfull you will have to click 
          on "Click here to continue to login page" to login in application.`}
        </p>

        <p>
          Or, you may end the guide.
          {' '}
          <br />
          <Button size="sm" variant="warning" onClick={onClose}>End Guide</Button>
        </p>
      </>
    ),
    action: node => node && node.focus()
  }
];

function ResetPasswordPageTour({ show, onClose }) {
  const steps = useMemo(() => resetPasswordPageSteps(onClose), [onClose]);

  return (
    <Tour
      steps={steps}
      isOpen={show}
      onRequestClose={onClose}
      rounded={5}
      accentColor="#5cb7b7"
      startAt={0}
      disableInteraction
    />
  );
}



export default ResetPasswordPageTour;
