import React from 'react';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import style from './DocExtractionFloorPlanConfig.module.scss';
import {
  displayNumberInUSFormat,
  DisplayAmountFormat
} from '../../../utils/utils';
import { rentRollAffixTabViewName } from '../../../constants';

const { CONFIG_VIEW, RENOVATION_VIEW, AFFORDABLE_VIEW } =
  rentRollAffixTabViewName;

const AffixTableView = ({
  getUnitPercentage,
  getAverageTotals,
  setActiveViewName,
  isMenuOpen,
  activeViewName,
  isActive,
  floorPlanConfigData,
  isAcquisition
}) => {
  if (!isActive) {
    return null;
  }

  return (
    <div className={style.floorPlan}>
      <Table>
        <thead className={`configure-table-thead ${style.floorPlanHeader}`}>
          <tr>
            <th>
              Floor Plan <small>Bed/Bath</small>
            </th>
            <th>
              {' '}
              #Occupied/
              <br />
              #Units <small>(Percentage)</small>
            </th>
            <th>
              Avg. Sqft <small>(Min - Max)</small>
            </th>
            <th>
              Avg. <br /> Market Rent<small>(Min - Max)</small>
            </th>
            <th>
              Avg. <br /> Monthly Rent<small>(Min - Max)</small>
            </th>
            {isAcquisition && (
              <>
                <th>
                  #Renovation
                  <small>(Percentage)</small>
                </th>
                <th>
                  #Affordable
                  <small>(Percentage)</small>
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody
          className={`${isMenuOpen ? `overflow-hidden` : 'overflow-auto'} ${
            activeViewName === CONFIG_VIEW ? 'configurefpWrapper' : ''
          }`}
        >
          {Reflect.ownKeys(floorPlanConfigData).map((floorPlan) => (
            <tr key={floorPlan}>
              <td className={style.fPlan}>
                <div className={style.fPlan}>
                  <span
                    title={floorPlan}
                    className="text-with-ellipsis w-100 d-inline-block"
                  >
                    {floorPlan}
                  </span>
                </div>
                <small
                  aria-hidden
                  role="menuitem"
                  onClick={() => setActiveViewName(CONFIG_VIEW)}
                >
                  {/* eslint-disable-next-line max-len */}
                  {`${floorPlanConfigData[floorPlan].BD}/${floorPlanConfigData[floorPlan].BA}`}
                </small>
              </td>
              <td
                title={displayNumberInUSFormat(
                  floorPlanConfigData[floorPlan].count
                )}
              >
                {displayNumberInUSFormat(
                  get(
                    floorPlanConfigData[floorPlan],
                    'occupancyStatus.occupiedUnitCount'
                  )
                )}{' '}
                /{' '}
                {displayNumberInUSFormat(floorPlanConfigData[floorPlan].count)}
                <small
                  title={`(${getUnitPercentage(
                    get(
                      floorPlanConfigData[floorPlan],
                      'occupancyStatus.occupiedUnitCount'
                    ),
                    'occupancyStatus.occupiedUnitCount'
                  )} %)/(${getUnitPercentage(
                    floorPlanConfigData[floorPlan].count
                  )} %)`}
                >
                  {`(${getUnitPercentage(
                    get(
                      floorPlanConfigData[floorPlan],
                      'occupancyStatus.occupiedUnitCount'
                    ),
                    'occupancyStatus.occupiedUnitCount'
                  )} %)`}
                  <br />
                  {`(${getUnitPercentage(
                    floorPlanConfigData[floorPlan].count
                  )} %)/`}
                </small>
              </td>
              <td
                title={getAverageTotals(
                  floorPlan,
                  'squareFtTotal',
                  'count',
                  false
                )}
              >
                {getAverageTotals(floorPlan, 'squareFtTotal', 'count', false)}
                <small>
                  {/* eslint-disable-next-line max-len */}
                  {`(${displayNumberInUSFormat(
                    floorPlanConfigData[floorPlan].squareFtMinValue
                  )} - ${displayNumberInUSFormat(
                    floorPlanConfigData[floorPlan].squareFtMaxValue
                  )})`}
                </small>
              </td>
              <td>
                {getAverageTotals(floorPlan, 'marketRentTotal', 'count')}
                <small>
                  <>
                    (
                    <DisplayAmountFormat
                      number={floorPlanConfigData[floorPlan].marketRentMinValue}
                    />
                    {' - '}
                    <DisplayAmountFormat
                      number={floorPlanConfigData[floorPlan].marketRentMaxValue}
                    />
                    )
                  </>
                </small>
              </td>
              <td>
                {getAverageTotals(floorPlan, 'occupiedMonthlyRentTotal', 'occupiedUnitCount')}
                <small>
                  <>
                    (
                    <DisplayAmountFormat
                      number={
                        floorPlanConfigData[floorPlan].monthlyRentMinValue
                      }
                    />
                    {' - '}
                    <DisplayAmountFormat
                      number={
                        floorPlanConfigData[floorPlan].monthlyRentMaxValue
                      }
                    />
                    )
                  </>
                </small>
              </td>

              {isAcquisition && (
                <>
                  <td>
                    {get(
                      floorPlanConfigData[floorPlan],
                      'renovationConfig.renovated',
                      0
                    )}
                    <small
                      aria-hidden
                      role="menuitem"
                      onClick={() => setActiveViewName(RENOVATION_VIEW)}
                    >
                      {`(${getUnitPercentage(
                        get(
                          floorPlanConfigData[floorPlan],
                          'renovationConfig.renovated'
                        ),
                        'renovationConfig.renovated'
                      )} %)`}
                    </small>
                  </td>
                  <td>
                    {get(
                      floorPlanConfigData[floorPlan],
                      'affordableConfig.affordable',
                      0
                    )}
                    <small
                      aria-hidden
                      role="menuitem"
                      onClick={() => setActiveViewName(AFFORDABLE_VIEW)}
                    >
                      {`(${getUnitPercentage(
                        get(
                          floorPlanConfigData[floorPlan],
                          'affordableConfig.affordable'
                        ),
                        'affordableConfig.affordable'
                      )} %)`}
                    </small>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AffixTableView;
