import React, { useEffect, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import style from './DocExtractionSlidePane.module.scss';
import IconButton from '../../core/Button/IconButton';

function DocExtractionSlidePane({
  heading,
  component,
  onSlidePaneClose,
  contextMenu
}) {
  const [slidePaneWidth, setSlidePaneWidth] = useState(0);
  useEffect(() => {
    setSlidePaneWidth(580);
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500);
    return () => {
      setSlidePaneWidth(0);
      window.dispatchEvent(new Event('resize'));
    };
  }, []);

  return (
    <div className={`${style.slidePanel} ${style.slidePaneWidth}`}>
      <div className={`${style.closeRightArrow}`} onClick={onSlidePaneClose}>
        <IconButton icon={faTimes} title="Close" variant="link" />
      </div>
      {/* {
          contextMenu
        } */}
      <div className="pane-body">
        {component}
      </div>
    </div>
  );
}

export default DocExtractionSlidePane;
