import React from 'react';
import GlobalModels from '../../app/GlobalModels/GlobalModels';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function GlobalModelsPage() {
  return (
    <SuperAdminDashboard>
      <GlobalModels />
    </SuperAdminDashboard>
  );
}
export default GlobalModelsPage;
