import { store } from 'reaf';
import { isEqual } from 'lodash';
import { normalize } from './utils';

const initialState = {
  user: null,
  token: '',
  role: '',
  company: {},
  eventCounts: {
    documentsExtracted: 0,
    documentsUploaded: 0
  },
  userAllowedTemplates: {},
  captchaToken: '',
  isSelfSignup: false,
};

const [
  {
    setAuth,
    resetAuth,
    validateToken,
    setUserAllowedTemplates,
    setCaptchaToken,
    setSelfSignupStatus,
  },
  reducer
] = store.createDuck('autouw/auth', {
  _initialState: initialState,

  setAuth: (user, token, role, company, eventCounts) => state => ({
    ...state,
    user,
    token,
    role,
    company,
    eventCounts
  }),

  setUserAllowedTemplates: userAllowedTemplates => state => ({
    ...state,
    userAllowedTemplates: normalize(userAllowedTemplates, 'uuid')
  }),
  resetAuth: () => () => initialState,
  validateToken: (user, company, eventCounts) => state => {
    if (!isEqual(user, state.user) || !isEqual(company, state.company) || !isEqual(eventCounts, state.eventCounts)) {
      return ({
        ...state,
        user,
        company,
        eventCounts
      });
    } else {
      return state;
    }
  },

  setCaptchaToken: captchaToken => state => ({
    ...state,
    captchaToken
  }),
  
  setSelfSignupStatus: isSelfSignup => state => ({
    ...state,
    isSelfSignup
  })

});


export {
  setAuth,
  resetAuth,
  validateToken,
  setUserAllowedTemplates,
  setCaptchaToken,
  setSelfSignupStatus
};

export default reducer;
