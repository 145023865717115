import React from 'react';
import { Field } from 'formik';
import FieldErrorMessage from '../../app/FieldErrorMessage/FieldErrorMessage';

const validateRequired = (value, showRequiredAstric, label) => {
  if (showRequiredAstric && !value) {
    return `${label} is required`;
  }
};

const FormikTextField = ({ name, label, hidden, showRequiredAstric=false, ...props }) => (
  <div id="ProjectNameField" className={`${hidden ? 'd-none' : 'form-group'}`}>
    <label htmlFor={name}>{label} {showRequiredAstric && (<span className="text-danger">*</span>)}</label>
    <Field
      id={name}
      className="form-control"
      type="text"
      name={name}
      validate={(value) => validateRequired(value, showRequiredAstric, label)} 
      {...props}
    />
    <FieldErrorMessage name={name} />
  </div>
);

export default FormikTextField;
