import React from 'react';
import { withServices } from 'reaf';
import queryString from 'query-string';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';

function TokenValidationPage({ authService }) {
  const parsedQS = queryString.parse(location.search);

  const validateAuthToken = async (token) => {
      await authService.validateCGAuthToken(token);
  }

  React.useEffect(() => {
    validateAuthToken(parsedQS.token)
  }, [parsedQS.token])


  return (
    <LoggedOutUserPage>
      <div className="d-flex align-items-center h-100 justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </LoggedOutUserPage>
  );
}

export default withServices('authService')(TokenValidationPage);
