import React from 'react'

function useClikGatewayUrl(authService) {
  const [clikGatewayUrl, setClikGatewayUrl] = React.useState({
    cgAuthUrl: '',
    cgOriginUrl: ''
  })
  React.useEffect(() => {
    fetchCgUrl();
  }, []);

  const fetchCgUrl = React.useCallback(async () =>{
    const { response } = await authService.getCGAuthUrl()
    const { origin } = new URL(response);
    setClikGatewayUrl({ cgOriginUrl: origin, cgAuthUrl: response });
  }, [])

  return clikGatewayUrl;
}

export default useClikGatewayUrl;
