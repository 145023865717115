export const LoginPageOpened = 'Login Page Opened';
export const LoginSuccess = 'Login Success';
export const LogoutSuccess = 'Logout Success';
export const TwoFactorAuthentication = 'Two Factor Authentication Page Opened';
export const ApplicationBootstraped = 'Application Bootstraped';
export const AppKill = 'App Kill';
export const DocUploaded = 'Document Uploaded';
export const ProjectCreated = 'Project Created';
export const ProjectUpdated = 'Project Updated';
export const ProjectDeleted = 'Project Deleted';
export const ProjectReportOpened = 'Project Report Opened';
export const SupportTicketCreated = 'Support Ticket Created';
export const ProjectListFiltered = 'Project List Filtered';
export const PaginationChanged = 'Pagination Changed';
export const ProjectListSorted = 'Project List Sorted';
export const ShowTourGuides = 'Show Tour Guides';
export const BreadcrumClicked = 'Breadcrum Clicked';
export const ClikLogoClicked = 'Clik Logo Clicked';
export const ProjectOpened = 'Project Opened';
export const DocumentOpened = 'Document Opened';
export const DocumentCSVExported = 'Document CSV Export';
export const DocumentTagged = 'Document Tagged';
export const DocumentDeleted = 'Document Deleted';
export const ClearedChildDocumentTagging = 'Cleared Child Document Tagging';
export const WorkbookSaved = 'Workbook Saved';
export const DocumentViewerOpened = 'Document Viewer Opened';
export const DocumentRenamed = 'Document Renamed';
export const ProjectTaggingCleared = 'Project Tagging Cleared';
export const ReportLinkGenerated = 'Report Link Generated';
export const ProjectMarkedCompleted = 'Project Marked Completed';
export const ShowProjectAssumptionForm = 'Show Project Assumption Form';
export const RefreshDocumentsButtonClicked = 'Refresh Documents Button Clicked';
export const DocTypeFilterChanged = 'Document Type Filter Changed';
export const DocumentSearchedByKeyword = 'Document Searched By Keyword';
export const DocumentTourGuideClicked = 'Document Tour Guide Clicked';
export const DocumentModelDownloaded = 'Document Model Downloaded';
export const ProjectXlsxDownloaded = 'Project Xlsx Downloaded';
export const ProjectNormalisedJsonDownloaded =
  'Project Normalised JSON Downloaded';
  export const ProjectNormalisedJsonSentToFTPServer =
  'Project Normalised JSON Sent to FTP Server';
export const ProjectDownloadedInFormatted = 'Project Downloaded In Format';
export const BrowseOSRRButtonClicked = 'Browse OS RR Button Clicked';
export const DocumentAddedByDrop = 'Document Added By Drop';
export const ReportLinkCopiedToClipboard = 'Report Link Copied To Clipboard';
export const ReportLinkSharedOnSocialMedia =
  'Report Link Shared On Social Media';
export const ReportLinkOpened = 'Report Link Opened';
export const CalendlyLinkClickedOnReportPage =
  'Calendly Link Clicked On Report Page';
export const DownloadWorkbookClickedOnReportPage =
  'Download Workbook Clicked On Report Page';
export const OTPSent = 'OTP Sent';
export const DocumentsDownloadedOnReportPage =
  'Documents Downloaded On Report Page';
export const ExtractionPageTourGuideOpened =
  'Extraction Page Tour Guide Opened';
export const ExtractionPageOpened = 'Extraction Page Opened';
export const ReferenceWindowOpened = 'Reference Window Opened';
export const KeyboardShortcutsOpened = 'Keyboard Shortcuts Opened';
export const AffixSidebarOpened = 'Affix Sidebar Opened';
export const AffixSidebarViewChanged = 'Affix Sidebar View Changed';
export const FloorPlanViewChanged = 'Floor Plan View Changed';
export const AffixConfigSaved = 'Affix Config Saved';
export const AffixConfigViewChanged = 'Affix Config View Changed';

export default {};
