import React from 'react';
import AccountsExecutiveDashboardPage from '../AccountsExecutiveDashboardPage/AccountsExecutiveDashboardPage';
import ProspectPaymentLinksList from '../../app/ProspectPaymentLinksList/ProspectPaymentLinksList';

function AccountsExecutivePaymentLinksPage() {
  return (
    <AccountsExecutiveDashboardPage>
      <ProspectPaymentLinksList />
    </AccountsExecutiveDashboardPage>
  );
}

export default AccountsExecutivePaymentLinksPage;
