import { createDuck } from 'reaf';


const initialState = {
  documentsList: {
    documents: {},
    isFetching: false,
    error: ''
  },
  documentsSummary: {}
};

const [
  { setAllDocuments },
  reducer
] = createDuck('autouw/documents', {
  _initialState: initialState,


  setAllDocuments: documents => state => ({
    ...state,
    documentsList: {
      ...state.documentsList,
      documents,
      isFetching: false,
      error: ''
    }
  })
});

export { setAllDocuments };

export default reducer;
