import React from 'react';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';
import NeedSupportForm from '../NeedSupportForm/NeedSupportForm';

function NeedSupportFormModal({
  show, onHide, project, document
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <FormModal entity="Project" show={show} onHide={onHide} onSubmit={onSubmit} isSubmitting={isSubmitting} title={`Raise support for ${project.name}.`}>
      <NeedSupportForm bindSubmission={bindSubmission} onSubmitSuccess={onHide} project={project} document={document} />
    </FormModal>
  );
}

export default NeedSupportFormModal;
