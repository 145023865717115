import React, { useCallback, useState } from 'react';
import { withServices } from 'reaf';
import { Button, Modal } from "react-bootstrap";
import style from './RecentDocumentsComponent.module.scss';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import WithTooltip from '../../core/WithTooltip/WithTooltip';
import DisplayExtractionJobStatus from '../DisplayExtractionJobStatus/DisplayExtractionJobStatus';
import DocumentTypeBadge from '../DocumentTypeBadge/DocumentTypeBadge';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import { createdAtFilter, SelectDocumentAssetTypeFilter, SelectDocumentTypeFilter, SelectDocumentValidationStatusFilter, SelectExtractionStatusFilter, UpdateAtFilter } from '../../core/ReactTableFilters/ReactTableFilters';
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import { DoucmentValidationStatus } from '../../../constants';
import DisplayDocumentValidationStatus from '../DisplayDocumentValidationStatus/DisplayDocumentValidationStatus';
import IconButton from '../../core/Button/IconButton';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';

const RecentTaggedDocumentsComponent = ({ apiClient, router, queries, projectsService }) => {
  const { data: documents, loading, pageCount, onDownloadCSV, onSendCSV, fetchData: fetchDocuments, refreshData } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    routeName: 'recentTaggedDocuments',
    resourceEndPoint: '/projects/recentDocuments',
    fileName: 'Documents.csv',
    csvSendEndPoint: '/projects/recentDocuments/sendCsvMail',
    csvDownloadEndPoint: '/projects/recentDocuments/downloadCSV',
  });
  const [selectedDocument, setSelectedDocument] = useState();
  const [selectedProject, setSelectedProject] = useState();

  const previewDocument = useCallback((document, project) => {
    setSelectedDocument(document)
    setSelectedProject(project)
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: 'Filename',
      accessor: 'fileName',
      fixedWidth: 340,
      enableClickToCopy: true,
      ignoreTextEllipsis: true
    },
    {
      Header: 'AssetType',
      Filter: SelectDocumentAssetTypeFilter,
      accessor: 'Project.assetType',
      Cell: ({ row: { original: { Project } } }) => Project && Project.assetType ? Project.assetType : ''
    },
    {
      Header: 'Project',
      accessor: 'Project.name',
      fixedWidth: 'auto',
      Cell: ({ row: { original: { Project } } }) => (Project ? `${Project.name}` : 'Deleted'),
      enableClickToCopy: true,
    },
    {
      Header: 'Owner',
      accessor: 'Users.firstName',
      fixedWidth: 100,
      Cell: ({ row: { original: { Project: { Users } } } }) => (Users && Users[0] ? <WithTooltip tooltipText={Users[0].email}><span>{`${Users[0].firstName} ${Users[0].lastName}`}</span></WithTooltip> : 'Deleted')
    },
    {
      Header: 'Type',
      accessor: 'TaggingData.documentType',
      width: 75,
      minWidth: 75,
      Filter: SelectDocumentTypeFilter,
      Cell: ({ row: { original: { TaggingData } } }) => TaggingData && TaggingData.documentType ? <DocumentTypeBadge documentType={TaggingData.documentType} /> : ''
    },
    {
      Header: 'Status',
      Filter: SelectExtractionStatusFilter,
      accessor: 'ExtractionJobs.status',
      Cell: ({ row: { original: { ExtractionJobs } } }) => ExtractionJobs && ExtractionJobs[0] ? <DisplayExtractionJobStatus status={ExtractionJobs[0].status} showBottmSpace={false} /> : ''
    },
    {
      Header: 'Validation',
      Filter: SelectDocumentValidationStatusFilter,
      accessor: 'isValidated',
      Cell: ({ row: { original: { isValidated } } }) => {
        return isValidated ? <DisplayDocumentValidationStatus status={DoucmentValidationStatus.VALIDATED.key} /> :
          <DisplayDocumentValidationStatus status={DoucmentValidationStatus.NOT_VALIDATED.key} />
      }
    },
    {
      Header: 'Created From',
      accessor: 'createdFrom',
      Filter: createdAtFilter,
      Cell: ({ row }) => <DisplayDate date={row.original.createdAt} utcFormat/>
    },
    {
      Header: 'Created To',
      accessor: 'createdTo',
      Filter: createdAtFilter,
      Cell: ({ row }) => <DisplayDate date={row.original.createdAt} utcFormat/>
    },
    {
      Header: 'Action',
      Cell: ({ row: { original: document } }) => {
        return (
          <div className="d-flex justify-content-center flex-row mr-2">
            <IconButton title="Preview File" icon={faFileInvoice} variant="outline-primary" size="sm" onClick={
              () => previewDocument(document, document.Project)
            } />
          </div>
        )
      },
    }
  ], [documents]);

  return (
    <>
      <ReactTable
        columns={columns}
        data={documents}
        title="Recent Tagged Documents"
        onDownloadCSV={onDownloadCSV}
        onSendCSV={onSendCSV}
        queries={queries}
        loading={loading}
        fetchData={fetchDocuments}
        pageCount={pageCount}
        titleClassName="mt-4"
      />
      {
        selectedDocument && selectedProject && (
          <Modal
            show={!!selectedDocument}
            size="xl"
            onHide={() => {
              setSelectedDocument(null);
              setSelectedProject(null);
            }}
            className="error-modal"
            centered
          >
            <Modal.Body>
              <div
                id="DocumentPreviewWrapper"
                className={`vertical-section flex-shrink-0 bg-light ${style.toolbarViewerSpacerRight} ${style.pdfViewerWrapper}`}
                style={{ flex: '3' }}>

                <DocumentViewer
                  document={selectedDocument}
                  project={selectedProject}
                  setPdfPageCount={() => { }}
                  setXLSheetNames={() => { }}
                  small
                />
              </div>
            </Modal.Body>
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {selectedDocument.fileName}
              </Modal.Title>
            </Modal.Header>
          </Modal>
        )
      }
    </>
  );
};

export default withServices('apiClient', 'router', 'projectsService', 'documentsService')(RecentTaggedDocumentsComponent);
