import React from 'react';
import FormModal from '../FormModal/FormModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Prospectform from '../ProspectForm/ProspectForm';
import ProspectInvoiceForm from '../ProspectInvoiceForm/ProspectInvoiceForm';
import useModalForm from '../../../hooks/useModalForm';

function ProspectPopups({
  prospect, invoice, onHide, showCreateProspect, showEditProspect,
  showCreateProspectInvoice, showVoidInvoiceConfirm, onVoidConfirm, onVoidCancel
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showCreateProspect &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showCreateProspect} title="Add Prospect">
            <Prospectform bindSubmission={bindSubmission} onSubmitSuccess={onHide} prospect={prospect} />
          </FormModal>
        )
      }
      {
        showEditProspect &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showEditProspect} title="Edit Prospect">
            <Prospectform bindSubmission={bindSubmission} onSubmitSuccess={onHide} prospect={prospect} />
          </FormModal>
        )
      }
      {
        showCreateProspectInvoice &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showCreateProspectInvoice} title="Send Invoice">
            <ProspectInvoiceForm bindSubmission={bindSubmission} onSubmitSuccess={onHide} prospect={prospect} />
          </FormModal>
        )
      }
      {
        showVoidInvoiceConfirm &&
        (
          <ConfirmationModal
            show={showVoidInvoiceConfirm}
            title={`Cancel Invoice ${invoice.additionalInfo.invoiceNumber}?`}
            message="Are you sure you want to cancel this invoice?"
            onConfirm={onVoidConfirm}
            onCancel={onVoidCancel}
          />
        )
      }
    </div>
  );
}

export default ProspectPopups;
