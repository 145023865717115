import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
import style from './DocExtractionAffixTotalCard.module.scss';

function DocExtractionAffixTotalCard({ cards }) {
  return (
    <>
      <CardDeck className={`${style.summary_totalCol}`}>
        {
          cards ? cards.map(item => (
            <Card 
              key={item.title} 
              className={`${style.card_shadow} ${style.card_space} ${style.amount}`} style={{ background: item.color }}
            >
              <Card.Body className="p-1">
                <Card.Subtitle 
                  className={`mb-1 mt-0 text-muted text-with-ellipsis ${style.subtitle}`}
                >
                  {item.title}
                </Card.Subtitle>
                <Card.Title className={`m-0 ${style.title}`}>
                  {item.content}
                </Card.Title>
              </Card.Body>
            </Card>
          )) : null
        }
      </CardDeck>
    </>
  );
}

export default DocExtractionAffixTotalCard;
