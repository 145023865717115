import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { withServices } from 'reaf';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import * as moment from 'moment'
import ProjectFormComponent from './ProjectFormComponent';
import {
  userAllowedTemplatesAsListSelector,
  authSelector,
  dynamicAssetTypesListSelector,
  dynamicAssetTypesAsObjSelector
} from '../../../store/selectors';
import messages from '../../../../locales';
import { GLOBAL_TEMPLATES_UUID, PROPERTY_TYPES } from '../../../constants';
import config from '../../../config'

const { shouldShowGoogleApiField } = config.featureFlags;
function ProjectForm({
  projectsService,
  onSubmitSuccess,
  bindSubmission,
  templates,
  project = { name: '' },
  toastrService,
  auth,
  dynamicAssetTypes, 
  dynamicAssetTypesObj
}) {
  const ProjectFormSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .transform((value) => (value === '' ? undefined : value))
      .required('Please provide a valid property name.'),
    assetType: Yup.mixed().oneOf(
      dynamicAssetTypes.map((asset)=> asset.assetType),
      'Please select a valid asset type.'
    ).when('templateUuid', (templateUuid, schema) => (
      !!templateUuid && !!templates.length ?
        schema.test('validateTemplate', `This Asset type is not supported by the selected model`, (assetType) => {
          const validAssetTypes = templates.find(template => 
            template.uuid === templateUuid).assetTypes || auth.company.assetTypes;
          return validAssetTypes.includes(assetType);
        }) : schema
    )),
    templateUuid: Yup.string().required('Please select a model.')
  });

  const [projectAddress, setProjectAddress] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(shouldShowGoogleApiField) {
      setIsLoading(true)
      if (project.uuid) {
        projectsService.fetchAddress(project).then(setProjectAddress).finally(() => setIsLoading(false))
      } else {
        setIsLoading(false)
      }
    }
  }, [project.uuid])

  const isSelfSignupAndActiveTrial = useMemo(() => {
    const { company: { subscriptionDetail, createdAt, trialDays, isEnterpriseCompany } } = auth;
    let isActiveTrial = false;
    if (!isEnterpriseCompany && !subscriptionDetail) {
      isActiveTrial = !moment().isAfter(moment(createdAt).add(+trialDays, 'days'));
    }
    return isActiveTrial;
  }, []);

  if (!project.uuid && auth.company.assetTypes) {
    if (auth.company.assetTypes.includes(dynamicAssetTypesObj.MULTIFAMILY.key)) {
      project.assetType =  dynamicAssetTypesObj.MULTIFAMILY.key;
    } else if (isSelfSignupAndActiveTrial) {
        project.assetType = PROPERTY_TYPES.MULTIFAMILY.key;
      } else {
        project.assetType = auth.company.assetTypes[0]
      }
  }
  
  const defaultTemplateUuid = useMemo(() => (isSelfSignupAndActiveTrial ? GLOBAL_TEMPLATES_UUID.CLIK : ''), []);
  
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      const updatedProject = await projectsService.upsertProject(project, values);
      onSubmitSuccess(updatedProject);
      const message =
        project.name === '' ?
          messages.toastMessage.CREATE_PROJECT :
          messages.toastMessage.EDIT_PROJECT;
      toastrService.success(message);
    } catch (error) {
      actions.setStatus({ error: error.message });
    }

    actions.setSubmitting(false);
  }

  const projectTemplates = useMemo(() => {
    if (isSelfSignupAndActiveTrial) {
      return templates.filter(template => (Object.values(GLOBAL_TEMPLATES_UUID).includes(template.uuid)) && template.userTemplateAccess.writeAccess);
    } else {
      return templates.filter(template => template.userTemplateAccess.writeAccess);
    }
  }, [templates, isSelfSignupAndActiveTrial]);

  const getProjectFormComponent = useCallback(
    (formikProps) => (
      <ProjectFormComponent
        {...formikProps}
        bindSubmission={bindSubmission}
        templates={projectTemplates}
        project={project}
      />
    ),
    [projectTemplates, bindSubmission]
  );

  if(isLoading) {
    return false
  }

  return (
    <Formik
      initialValues={{ templateUuid: defaultTemplateUuid, ...project, project, address: { propertyName: '', formattedAddress: '', lat: '', lng: '', zip: '', city: '', state: '', googlePlaceId: '', ...projectAddress } }}
      validationSchema={ProjectFormSchema}
      onSubmit={onSubmit}
      component={getProjectFormComponent}
    />
  );
}

const mapStateToProps = (state) => ({
  templates: userAllowedTemplatesAsListSelector(state),
  auth: authSelector(state),
  dynamicAssetTypes: dynamicAssetTypesListSelector(state),
  dynamicAssetTypesObj: dynamicAssetTypesAsObjSelector(state)
});

export default connect(mapStateToProps)(
  withServices('projectsService', 'toastrService')(ProjectForm)
);
