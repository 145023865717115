import React from 'react';
import { withServices } from 'reaf';
import { Container, Row, Col } from 'react-bootstrap';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import LightButton from '../../core/LightButton/LightButton';

function SuperAdminDashboard({ children, router }) {
  const { pathname } = window.location;

  return (
    <LoggedInUserPage noFooter>
      <Container className="m-0 horizontal-section" fluid>
        <Row className="horizontal-section">
          <Col
            xs="auto"
            className="border-right p-0 bg-info pt-5 shadow naviSidebar"
          >
            <LightButton
              onClick={() => router.setRoute('superAdminPage')}
              active={pathname === router.getUrl('superAdminPage')}
            >
              Company Management
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('superAdminCompanyStatusPage')}
              active={pathname === router.getUrl('superAdminCompanyStatusPage')}
            >
              Company Status
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('usersPage')}
              active={pathname === router.getUrl('usersPage')}
            >
              Users
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('userLogs')}
              active={pathname === router.getUrl('userLogs')}
            >
              User Logs
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('superAdminProjectPage')}
              active={pathname === router.getUrl('superAdminProjectPage')}
            >
              Projects
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('superAdminTemplatesPage')}
              active={pathname === router.getUrl('superAdminTemplatesPage')}
            >
              Templates
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('superAdminDocumentsPage')}
              active={pathname === router.getUrl('superAdminDocumentsPage')}
            >
              Documents
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('VideoTutorialsPage')}
              active={pathname === router.getUrl('VideoTutorialsPage')}
            >
              Video Tutorials
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('TicketsPage')}
              active={pathname === router.getUrl('TicketsPage')}
            >
              Tickets
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('sharedReportsPage')}
              active={pathname === router.getUrl('sharedReportsPage')}
            >
              Report Links
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('FeatureFlagsPage')}
              active={pathname === router.getUrl('FeatureFlagsPage')}
            >
              Feature Flags
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('superAdminDynamicAssetTypesPage')}
              active={pathname === router.getUrl('superAdminDynamicAssetTypesPage')}
            >
              Dynamic Asset Types
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('GlobalTemplateMappingsPage')}
              active={pathname === router.getUrl('GlobalTemplateMappingsPage')}
            >
              Global Template Mappings
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('SubscriptionsListPage')}
              active={pathname === router.getUrl('SubscriptionsListPage')}
            >
              Subscriptions
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('superAdminProspectPage')}
              active={pathname === router.getUrl('superAdminProspectPage')}
            >
              Prospects
            </LightButton>
          </Col>
          <Col className="vertical-section p-0">
            <div className="fill-parent overflow-auto p-3">{children}</div>
          </Col>
        </Row>
      </Container>
    </LoggedInUserPage>
  );
}

export default withServices('router')(SuperAdminDashboard);
