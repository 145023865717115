import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Ussage:
 *  <WithTooltip tooltipText="Display in tooltip">
 *    <span>...Some Text to display</span>
 *  </WithTooltip>
 */
const WithTooltip = ({ children, placement = "bottom", tooltipText }) => (
  <OverlayTrigger
    placement={placement} overlay={<Tooltip bsPrefix={tooltipText ? 'tooltip' : 'hide'}>{tooltipText}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
);

export default WithTooltip;
