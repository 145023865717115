import React from 'react';
import {
  Row, Col, ListGroup, Button, Badge
} from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faFileCode } from '@fortawesome/free-solid-svg-icons/faFileCode';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import Link from '../../core/Link/Link';
import AssetTypeBadge from '../AssetTypeBadge/AssetTypeBadge';
import TemplateTagBadge from '../TemplateTagBadge/TenplateTagBadge';
import IconButton from '../../core/Button/IconButton';

function CompanyTemplateListItem({
  template,
  companyUUID,
  onDeleteTemplate,
  onDownloadTemplate,
  onUpdateTemplate,
  onDownloadDictionary,
  onDownloadCategorySequence,
  isGlobal = false
}) {
  const assetTypes = template.assetTypes &&
    template.assetTypes.map(assetType => (<AssetTypeBadge key={assetType} AssetType={assetType} />));

  const templateTag = (<TemplateTagBadge key={template.tag} TagType={template.tag} />);

  return (
    <ListGroup.Item>
      <Row>
        <Col xs lg="4">
          {template.templateName}
          {assetTypes}
          <Row>
            <Col>
              <div className="d-flex mt-2">
                {template?.isReportEnabled && (
                  <span className='mr-2'>
                    <Badge variant='success'>Report Enabled</Badge>
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs lg="2">
          {
            isGlobal ?
            <Button
                variant="outline-primary"
                className="btn btn-default btn-sm ml-2"
                onClick={() => onUpdateTemplate(template)}
              >
              Edit Template
              </Button>
              : !template.isGlobal && (
              <Button
                variant="outline-primary"
                className="btn btn-default btn-sm ml-2"
                onClick={() => onUpdateTemplate(template)}
              >
              Edit Template
              </Button>
            )}
        </Col>
        <Col xs lg="2">
          <IconButton
            onClick={() => onDownloadTemplate(template)}
            title="Download Template"
            icon={faDownload}
          />
          {
            template.isDictionaryAvailable && (
              <IconButton
                onClick={() => onDownloadDictionary(template)}
                title="Download Dictionary"
                icon={faFileCode}
              />
            )}
          {
            template.isCategorySequenceAvailable && (
              <IconButton
                onClick={() => onDownloadCategorySequence(template)}
                title="Download Category Sequence"
                icon={faFileAlt}
              />
            )}
        </Col>
        <Col xs lg="1">
          {templateTag}
        </Col>
        <Col xs lg="2">
          {
            isGlobal ? (
              <Link
                route="GlobalModelPage"
                params={{ templateUUID: template.uuid }}
                className="btn btn-link btn-sm "
                style={{ marginLeft: '.5em' }}>
                Manage Model
              </Link>
            ) : (

              <Link
                route="superAdminModelManagement"
                params={{ companyUUID, templateUUID: template.uuid }}
                className="btn btn-link btn-sm "
                style={{ marginLeft: '.5em' }}>
                Manage Model
              </Link>
            )
          }
        </Col>
        <Col xs lg="1">
          {
            !template.isGlobal && (
              <Button
                variant="outline-danger"
                className="btn btn-default btn-sm"
                onClick={() => onDeleteTemplate(template)}
              >
              Delete
              </Button>
            )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default CompanyTemplateListItem;
