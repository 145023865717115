import React from 'react';
import * as PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

export default function ColorPicker({ onChangeComplete }) {
  const [current, setCurrent] = React.useState('#333');
  const handleChange = React.useCallback((color) => {
    setCurrent(color);
  }, []);
  return <SketchPicker onChange={handleChange} onChangeComplete={onChangeComplete} color={current} />;
}

ColorPicker.propTypes = {
  onChangeComplete: PropTypes.func.isRequired,
};
