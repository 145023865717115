import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getVideoListSelector } from '../../../store/selectors';
import RelatedTutorialVideos from '../RelatedTutorialVideos/RelatedTutorialVideos';
import TutorialVideo from '../TutorialVideo/TutorialVideo';
import WithLoader from '../WithLoader/WithLoader';

const TutorialPlayer = ({
  videoUuid,
  section,
  router,
  tutorialsListMap,
  tutorials,
}) => {
  const [video, setVideo] = useState();

  useEffect(() => {
    if (videoUuid && tutorialsListMap && tutorialsListMap[videoUuid]) {
      setVideo(tutorialsListMap[videoUuid]);
    }
  }, [tutorialsListMap, videoUuid]);

  return (
    <WithLoader isLoading={!video}>
      <Row>
        <TutorialVideo video={video} />
        <RelatedTutorialVideos {...{ videoUuid, tutorials, router, section }} />
      </Row>
    </WithLoader>
  );
};

const mapStateToProps = (state) => ({
  tutorialsListMap: getVideoListSelector(state),
});

export default connect(mapStateToProps)(TutorialPlayer);
