import React from 'react';
import { Field } from 'formik';
import FieldErrorMessage from '../../app/FieldErrorMessage/FieldErrorMessage';

const validateRequired = (value, showRequiredAstric, label) => {
  if (showRequiredAstric && !value) {
    return `${label} is required`;
  }
};

const FormikSelectField = ({
  name, label, children, showRequiredAstric=false, ...props
}) => (
  <div id="ProjectNameField" className="form-group">
    <label htmlFor={name}>{label} {showRequiredAstric && (<span className="text-danger">*</span>)} </label>
    <Field
      id={name}
      className="form-control"
      component="select"
      name={name}
      validate={(value) => validateRequired(value, showRequiredAstric, label)} 
      {...props}
    >
      {children}
    </Field>
    <FieldErrorMessage name={name} />
  </div>
);

export default FormikSelectField;
