/* eslint-disable no-nested-ternary */
import React, {
  useCallback, useState, useMemo, useEffect
} from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Spinner } from 'react-bootstrap';
import ProjectProductionFormComponent from './ProjectProductionFormComponent';
import ProjectServiceFormComponent from './ProjectServiceFormComponent';
import ProjectAcquisitionFormComponent from './ProjectAcquisitionFormComponent';
import { currentProjectSelector, companyTemplateModelSelector, assumptionSelector, apiConfigIntegrationSelector } from '../../../store/selectors';
import { TEMPLATE_TAG } from '../../../constants';
import { productionValidationSchema, servicingValidationSchema, acquisitionValidationSchema } from './ProjectInfoFormValidationSchema';

function ProjectInfoForm({
  currentProject, assumption, templatesWithModels, onSubmitSuccess, bindSubmission, projectsService, toastrService, apiConfigIntegration
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [initialAssumption, setInitialAssumption] = useState({});
  const [apiConfigIntegrationValues, setApiConfigIntegrationValues] = useState([]);
  useEffect(() => {
    projectsService.fetchAssumption(currentProject)
      .then(() => setIsLoading(false));
  }, []);
  const [loanInfoMainTab, setLoanInfoMainTab] = useState('executeLoan');
  const [loanInfoChildTab, setLoanInfoChildTab] = useState('propertyInformation');
  const [servicingTab, setServicingTab] = useState('general');
  const [acquisitionTab, setAcquisitionTab] = useState('property_information');
  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      await projectsService.updateProjectAssumption(currentProject, values);
      toastrService.success('Assumption updated successfully.');
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }

    actions.setSubmitting(false);
  }, [onSubmitSuccess]);

  const isProduction = useMemo(() => templatesWithModels[currentProject.templateUuid].tag === TEMPLATE_TAG['PRODUCTION'].key, [currentProject, templatesWithModels]);
  const isAcquisition = useMemo(() =>
    templatesWithModels[currentProject.templateUuid].tag === TEMPLATE_TAG.ACQUISITION.key,
    [currentProject, templatesWithModels]);

  const showProductionProjectForm = templatesWithModels[currentProject.templateUuid].tag === TEMPLATE_TAG['PRODUCTION'].key;
  const getProjectInfoFormComponent = useCallback(formikProps => (showProductionProjectForm ?
    <ProjectProductionFormComponent
      {...formikProps}
      loanInfoChildTab={loanInfoChildTab}
      setLoanInfoChildTab={setLoanInfoChildTab}
      mainTab={loanInfoMainTab}
      setMainTab={setLoanInfoMainTab}
      bindSubmission={bindSubmission}
      apiConfigIntegrationValues={apiConfigIntegrationValues}
    /> :
    isAcquisition ? <ProjectAcquisitionFormComponent
      {...formikProps}
      acquisitionTab={acquisitionTab}
      setAcquisitionTab={setAcquisitionTab}
      bindSubmission={bindSubmission}
      apiConfigIntegrationValues={apiConfigIntegrationValues}
    /> :
      <ProjectServiceFormComponent
        {...formikProps}
        servicingTab={servicingTab}
        setServicingTab={setServicingTab}
        bindSubmission={bindSubmission}
        apiConfigIntegrationValues={apiConfigIntegrationValues} />),
    [bindSubmission, loanInfoMainTab, setLoanInfoMainTab, loanInfoChildTab, setLoanInfoChildTab, servicingTab,
      setServicingTab, acquisitionTab, setAcquisitionTab]);
  useEffect(() => {
    let apiConfigValues = [];
    let initialAssumption = assumption;
    if (apiConfigIntegration && apiConfigIntegration.originalConfigFileName) {
      const fileExtension = apiConfigIntegration.originalConfigFileName.split('.').pop();
      fileExtension === 'json'
        ? apiConfigValues = apiConfigIntegration.configFile
        : apiConfigValues = apiConfigIntegration.configFile.mappings;
    }
    if(apiConfigValues) {
      apiConfigValues.forEach(field => {
        if (initialAssumption?.api_integration && !initialAssumption.api_integration.hasOwnProperty(field.name)) {
          initialAssumption.api_integration[field.name] = '';
        }
      });
    }
    setApiConfigIntegrationValues(apiConfigValues);
    setInitialAssumption(initialAssumption)


  }, [assumption, apiConfigIntegration])

  return (
    <>
      {isLoading ? <Spinner animation="grow" style={{ marginLeft: '300px' }} /> : (
        <Formik
          initialValues={initialAssumption}
          enableReinitialize
          onSubmit={onSubmit}
          component={getProjectInfoFormComponent}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentProject: currentProjectSelector(state),
  templatesWithModels: companyTemplateModelSelector(state),
  assumption: assumptionSelector(state),
  apiConfigIntegration: apiConfigIntegrationSelector(state)
});
export default connect(mapStateToProps)(withServices('projectsService', 'toastrService')(ProjectInfoForm));
