import React from 'react';
import { Col } from 'react-bootstrap';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './RelatedTutorialVideos.module.scss';
import { getTimestampToFormatedTime } from '../../../utils/utils';

const RelatedTutorialVideos = ({ tutorials, router, section, videoUuid }) => (
    <Col lg={3} md={2}>
      <h2 className={style.heading}>Related Tutorials</h2>
      {tutorials.map((tutorial) => (
        <div
          className={`${style.relatedVideo} ${style.noselect} ${
            videoUuid === tutorial.uuid ? style.playingVideo : ''
          }`}
          key={tutorial.uuid}
          onClick={(e) => {
            e.preventDefault();
            router.setRoute('tutorialVideos', {
              section,
              videoUuid: tutorial.uuid,
            });
          }}
        >
          <div className={style.icon}>
            <FontAwesomeIcon icon={faPlayCircle} />
          </div>
          <div>
            <div className={style.title}>{tutorial.title}</div>
            <div>
              <small className={`text-muted ${style.duration}`}>
                <FontAwesomeIcon icon={faClock} />{' '}
                {getTimestampToFormatedTime(tutorial.duration)}
              </small>
            </div>
          </div>
        </div>
      ))}
    </Col>
  );

export default RelatedTutorialVideos;
