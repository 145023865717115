import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import RecentProjectsComponent from '../../app/RecentProjectsComponent/RecentProjectsComponent'
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage'

function RecentProjectsPageHeader() {
  return (
    <Container fluid className="bg-light border-bottom sub-header">
      <Row className="align-items-center">
        <Col sm="auto" className="align-middle p2">
          <Breadcrumbs items={[
            { to: '#', content: 'Recent Projects' },
          ]} />
        </Col>
        <Col />
      </Row>
    </Container>
  );
}

export default function RecentProjectsPage({ queries }) {
  return (
    <LoggedInUserPage noFooter>
      <RecentProjectsPageHeader />
      <Container fluid className="m-0 vertical-section">
      <Row className="vertical-section">
        <Col className="vertical-section p-0">
          <div className="fill-parent overflow-auto p-3">
            <RecentProjectsComponent queries={queries} />
          </div>
        </Col>
      </Row>
      </Container>
    </LoggedInUserPage>
  )
}
