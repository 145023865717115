import React from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormModal from '../FormModal/FormModal';
import CompanyTemplateForm from '../CompanyTemplateForm/CompanyTemplateForm';
import CompanyTemplateUpdateForm from '../CompanyTemplateUpdateForm/CompanyTemplateUpdateForm';
import useModalForm from '../../../hooks/useModalForm';

function CompanyTemplatePopups({
  template,
  onHide,
  showUploadTemplate,
  showDeleteConfirm,
  onDeleteConfirm,
  companyUUID,
  showUpdateTemplate,
  isGlobal = false,
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showUploadTemplate &&
        (
          <FormModal onHide={onHide} show={showUploadTemplate} isSubmitting={isSubmitting} onSubmit={onSubmit} title="Upload Template">
            <CompanyTemplateForm
              bindSubmission={bindSubmission}
              onSubmitSuccess={onHide}
              companyUUID={companyUUID}
              isGlobal={isGlobal} />
          </FormModal>
        )
      }
      {
        showUpdateTemplate &&
        (
          <FormModal onHide={onHide} show={showUpdateTemplate} isSubmitting={isSubmitting} onSubmit={onSubmit} title="Update Template">
            <CompanyTemplateUpdateForm
              bindSubmission={bindSubmission}
              onSubmitSuccess={onHide}
              companyUUID={companyUUID}
              template={template}
              isGlobal={isGlobal} />
          </FormModal>
        )
      }
      {
        showDeleteConfirm &&
        (
          <ConfirmationModal
            show={showDeleteConfirm}
            title={`Delete Template ${template.templateName}?`}
            message={`Are you sure you want to delete the template ${template.templateName}`}
            onConfirm={onDeleteConfirm}
            onCancel={onHide}
          />
        )
      }
    </div>
  );
}

export default CompanyTemplatePopups;
