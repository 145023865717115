import React, { useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { setAllFeatureFlags } from '../store/featureFlag';
import { getAllFeatureFlags } from '../store/selectors';

export const FeatureFlagContext = React.createContext({});

const FeatureFlagProvider = ({ children, featureFlags, dispatch, apiClient }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const loader = document.getElementById('landingLoader');

  useEffect(() => {
    if (loader && !isLoading) {
      loader.setAttribute('style', 'display:none');
    }
  }, [isLoading]);

  React.useEffect(() => {
    apiClient
      .get('/shared/featureFlags')
      .then((response) => {
        const featureFlags = response.response;
        const featureObj = featureFlags.reduce(
          (featureFlag, currentFeatureFlag) => {
            featureFlag[currentFeatureFlag.featureName] =
              currentFeatureFlag['isActive'];
            return featureFlag;
          },
          {}
        );
        dispatch(setAllFeatureFlags(featureObj));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null
  } 

  return (
    <FeatureFlagContext.Provider value={{ isLoading, featureFlags }}>
      {children}
    </FeatureFlagContext.Provider>
  ); 
};

export const useFeatureFlag = (featureName, defaultValue) => {
  const { featureFlags, isLoading } = React.useContext(FeatureFlagContext);
  const value = get(featureFlags, featureName, defaultValue);
  return [value, isLoading];
};

const mapStateToProps = (state) => ({
  featureFlags: getAllFeatureFlags(state)
});

export default connect(mapStateToProps)(
  withServices('apiClient')(FeatureFlagProvider)
);
