import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { withServices } from 'reaf';
import {
  faEdit, faLock, faLockOpen, faTrash, faKey, faPuzzlePiece, faCheck, faTimesCircle, faFileExport, faGlobe
} from '@fortawesome/free-solid-svg-icons';
import {
  projectMetaDataSelector
} from '../../../store/selectors';
import CompanyPopups from '../CompanyPopups/CompanyPopups';
import { DocumentFileTypes, EXCLUDE_DOC_FILE_TYPES, PROPERTY_TYPES, } from '../../../constants';
import messages from '../../../../locales';
import IconButton from '../../core/Button/IconButton';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import { ProjectExporterPageLink } from '../../core/adminLinks/links';
import Link from '../../core/Link/Link';
import { usDateFormat } from '../../../utils/utils';

function CompanyList({
  queries,
  projectMetaData,
  companyService,
  toastrService,
  apiClient,
  router,
  projectsService,
}) {
  const documentTypes = useMemo(() => Reflect
    .ownKeys(DocumentFileTypes)
    .filter(docType => !EXCLUDE_DOC_FILE_TYPES.includes(docType)));

  const initialCompany = useMemo(() => ({
    company: {
      name: '',
      assetTypes: [PROPERTY_TYPES.MULTIFAMILY.key],
      documentTypes: documentTypes,
      rentRollRestrictedAssetTypes: [],
      phone: null,
      address: {
        address: '',
        city: '',
        state: '',
        province: '',
        country: '',
        zip: ''
      }
    },
    admin: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    }
  }), [projectMetaData.assetTypes, documentTypes]);

  const companyTypeFilterValues = [
    { value: 'All', label: 'All' },
    { value: 'isTrialOffering', label: 'Trial' },
    { value: 'isEnterpriseCompany', label: 'Enterprise' },
  ];

  const [currentFilterValue, setCurrentFilterValue] = useState(companyTypeFilterValues[0]);
  const [showCreateCompanyFormPopup, setShowCompanyFormPopup] = useState(false);
  const [company, setCompany] = useState(initialCompany);
  const [showEditCompanyForm, setShowEditCompanyForm] = useState(false);
  const [showCompanySSOForm, setShowCompanySSOForm] = useState(false);
  const [showApiIntegrationForm, setShowApiIntegrationForm] = useState(false);
  const [showCompanySAMLForm, setShowCompanySAMLForm] = useState(false);
  const [showDeleteCompanyConfirmation, setShowDeleteCompanyConfirmation] = useState(false);
  const [showGlobalTemplateAccessFrom, setGlobalTemplateAccessFrom] = useState(false);
  const [apiConfigDetails, setApiConfigDetails] = useState({});

  const hidePopups = useCallback(
    () => {
      setShowCompanyFormPopup(false);
      setShowEditCompanyForm(false);
      setShowCompanySSOForm(false);
      setShowCompanySAMLForm(false);
      setShowApiIntegrationForm(false);
      setGlobalTemplateAccessFrom(false);
    },
    [],
  );

  const onDelete = useCallback(async (company) => {
    setCompany({ company });
    setShowDeleteCompanyConfirmation(true);
  }, []);

  const onEditCompany = useCallback(async (company) => {
    setCompany({ company });
    setShowEditCompanyForm(true);
  }, []);

  const onSSOUpdate = useCallback(async (company) => {
    setCompany({ company });
    setShowCompanySSOForm(true);
  }, []);

  const onUpdateApiIntegration = useCallback(async (company) => {
    const apiConfig = await companyService.getApiConfiguraion(company.uuid);
    setApiConfigDetails(apiConfig?.apiIntegrationConfiguration[0]);
    setCompany({ company });
    setShowApiIntegrationForm(true);
  }, [apiConfigDetails, showApiIntegrationForm]);

  const onSAMLUpdate = useCallback(async (company) => {
    setCompany({ company });
    setShowCompanySAMLForm(true);
  }, []);

  const onEditGlobalTemplate = useCallback(async (company) => {
    setCompany({ company });
    setGlobalTemplateAccessFrom(true);
  }, []);

  const onAddCompany = useCallback(() => {
    setCompany(initialCompany);
    setShowCompanyFormPopup(true);
  }, [initialCompany]);

  const { data, loading, pageCount, onDownloadCSV, fetchData, setData, setLoading } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    currentFilterValue,
    routeName: 'superAdminPage',
    resourceEndPoint: '/companies',
    fileName: 'Companies.csv',
    csvDownloadEndPoint: '/companies/downloadCSV'
  });

  const updateCompanyinList = useCallback((updatedCompany) => {
    const index = data.findIndex(comp => comp.uuid === updatedCompany.uuid);
    const updateArray = [...data];
    updateArray[index] = updatedCompany;
    setData(updateArray);
  }, [data]);

  const onToggleCompanyAccess = useCallback(async (company) => {
    setLoading(true);
    const { company: updatedCompany } = await companyService.setCompanyAccess(company, !company.accessEnabled);
    updateCompanyinList(updatedCompany);
    setLoading(false);
  }, [setLoading, updateCompanyinList]);

  const onToggleCompanyAcl = useCallback(async (company) => {
    setLoading(true);
    const { company: updatedCompany } = await companyService.setCompanyAcl(company, !company.isAclEnabled)
    updateCompanyinList(updatedCompany);
    setLoading(false);
  }, [setLoading, updateCompanyinList]);

  const onDeleteConfirm = useCallback(async () => {
    try {
      setLoading(true);
      await companyService.deleteCompany({ company });
      const index = data.findIndex(comp => comp.uuid === company.company.uuid);
      const updateArray = [...data];
      updateArray.splice(index, 1);
      setData(updateArray);
      setLoading(false)
      toastrService.success(messages.toastMessage.COMPANY_DELETED);
    } catch (e) {
      toastrService.error(e.message);
    }
    setShowDeleteCompanyConfirmation(false);
  }, [company, data, setData, setLoading]);

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Active',
      disableFilters: true,
      accessor: 'accessEnabled',
      Cell: ({ row: { original: { accessEnabled } } }) => (
        <IconButton
          className={accessEnabled ? 'btn-outline-success' : 'btn-outline-danger'}
          style={{ width: '100%' }}
          icon={accessEnabled ? faCheck : faTimesCircle}
          title={accessEnabled ? 'Active' : 'Inactive'} />
      )
    },
    {
      Header: 'Payment Due',
      disableFilters: true,
      accessor: 'paymentDueDate',
      Cell: ({ row: { original: { paymentDueDate } } }) => (
        <div style={{ width: '100%', textAlign: 'center', fontWeight: paymentDueDate ? '700' : '500' }}>
          {paymentDueDate === null ? '--' : usDateFormat(paymentDueDate)}
        </div>
      )
    },
    {
      Header: 'Actions',
      disableFilters: true,
      disableSortBy: true,
      accessor: 'actions',
      Cell: ({ row: { original: company } }) => {
        const { accessEnabled, isAclEnabled, isSSOEnabled, ProspectCompany: isProspectCompany } = company;
        return (
          <>
            <IconButton
              onClick={() => onToggleCompanyAccess(company)}
              variant="outline-primary"
              className="smallIconButton"
              title={accessEnabled ? 'Deactivate' : 'Activate'}
              icon={accessEnabled ? faLock : faLockOpen} />
            <IconButton
              onClick={() => onEditCompany(company)}
              variant="outline-primary"
              title="Edit Company"
              className="smallIconButton"
              icon={faEdit} />
            <IconButton
              onClick={() => onEditGlobalTemplate(company)}
              variant="outline-primary"
              title="Global Template Access"
              className="smallIconButton"
              icon={faGlobe} />
            <IconButton
              onClick={() => onUpdateApiIntegration(company)}
              variant="outline-primary"
              title="Api Integration"
              className="smallIconButton"
              icon={faFileExport} />
            <IconButton
              onClick={() => onSSOUpdate(company)}
              variant="outline-primary"
              title="SSO config"
              className="smallIconButton"
              icon={faKey} />
            {/* <IconButton
              variant="outline-danger"
              className="smallIconButton"
              icon={faTrash}
              title="Delete Company"
              disabled={!!isProspectCompany}
              onClick={() => onDelete(company)}
            /> */}
            {
              isSSOEnabled && <IconButton
                onClick={() => onSAMLUpdate(company)}
                variant="outline-primary"
                title="SAML configuration"
                className="smallIconButton"
                icon={faPuzzlePiece} />
            }
            <Button
              variant="outline-primary"
              className="btn btn-default btn-xs ml-2 smallIconButtonHeight"
              onClick={() => onToggleCompanyAcl(company)}
            >
              {isAclEnabled ? 'Deactivate ACL' : 'Activate ACL'}
            </Button>
          </>
        )
      }
    },
    {
      Header: 'Links',
      accessor: 'links',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original: { uuid, name } } }) => (
        <>
          <Link
            route="superAdminUserManagement"
            params={{ companyUUID: uuid }}
            className="btn btn-link btn-xs"
          >
            User
          </Link>
          <Link
            route="superAdminTemplateManagement"
            params={{ companyUUID: uuid }}
            className="btn btn-link btn-xs"
          >
            Template
          </Link>
          <Link
            route="superAdminProjectPage"
            params={{
              _search: { 'Company.name': name }
            }}
            className="btn btn-link btn-xs"
          >
            Projects
          </Link>
          <Link
            route="superAdminDocumentsPage"
            params={{
              _search: { 'Company.name': name }
            }}
            className="btn btn-link btn-xs"
          >
            Documents
          </Link>
          <Link
            route="TicketsPage"
            params={{
              _search: { 'Company.name': name }
            }}
            className="btn btn-link btn-xs"
          >
            Tickets
          </Link>
          <ProjectExporterPageLink
            text="Project Exporters"
            companyUUID={uuid}
            className="btn btn-link btn-xs"
          />
        </>
      )
    },

  ], [data]);

  return (
    <>
      <ReactTable
        currentFilterValue={currentFilterValue}
        setCurrentFilterValue={setCurrentFilterValue}
        companyTypeFilterValues={companyTypeFilterValues}
        columns={columns}
        data={data}
        onDownloadCSV={onDownloadCSV}
        onAdd={onAddCompany}
        queries={queries}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        hideAddButton={true}
      />
      <CompanyPopups
        onHide={hidePopups}
        onEditSuccess={updateCompanyinList}
        onAddSuccess={(updatedCompany) => setData([updatedCompany, ...data])}
        showCompanySAMLForm={showCompanySAMLForm}
        showCreateCompany={showCreateCompanyFormPopup}
        showEditCompanyForm={showEditCompanyForm}
        showCompanySSOForm={showCompanySSOForm}
        showApiIntegrationForm={showApiIntegrationForm}
        showDeleteConfirm={showDeleteCompanyConfirmation}
        showGlobalTemplateAccessFrom={showGlobalTemplateAccessFrom}
        company={company}
        onDeleteConfirm={onDeleteConfirm}
        onDeleteCancel={() => setShowDeleteCompanyConfirmation(false)}
        apiConfigDetails={apiConfigDetails}
        setApiConfigDetails={setApiConfigDetails} />
    </>
  );
}

const mapStateToProps = state => ({
  projectMetaData: projectMetaDataSelector(state)
});

export default connect(mapStateToProps)(withServices('companyService', 'toastrService', 'apiClient', 'router', 'projectsService',)(CompanyList));
