import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  paymentLinks: {}
};

const [
  { setPaymentLinks },
  reducer
] = createDuck('autouw/paymentLinks', {
  _initialState: initialState,

  setPaymentLinks: paymentLinks => state => ({
    ...state,
    paymentLinks: normalize(paymentLinks, 'id')
  }),
});

export {
    setPaymentLinks
};

export default reducer;
