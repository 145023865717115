import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import DocExtractionAffixSidebar from "./DocExtractionAffixSidebar";
import style from './DocExtractionAffixWrapper.module.scss';
import './DocExtractionAffixWrapper.scss';
import { currentProjectSelector } from '../../../store/selectors';

function DocExtractionAffixWrapper({
  component,
  isSlidePaneOpen,
  isAcquisition,
  onConfigOpen,
  onSlidePaneClose,
  projectsService,
  currentProject,
  activeTabKey,
}) {
  const onAffixOpen = useCallback((selectedTab, affixWidth) => {
    onConfigOpen({ selectedTab, affixWidth });
    projectsService.fetchAssumption(currentProject);
  }, [currentProject]);


  return (
    // eslint-disable-next-line no-nested-ternary
    <div className={`${style.affixWrapper} ${isSlidePaneOpen ? 
      isAcquisition ? 
      style.affixWrapperAcquitionOpen : 
      style.affixWrapperOpen : style.affixWrapperClose}`}
    >
      <DocExtractionAffixSidebar
        onAffixOpen={(docType, affixWidth = 600) => onAffixOpen(docType, affixWidth)}
        isAcquisition = {isAcquisition}
        activeTabKey={activeTabKey}
      />  
      <div className={style.slidePanel}>
        {
          isSlidePaneOpen && component
        }
      </div>
      <div className={style.footerWrapper} />
    </div>
  );
}

const maptStateToProps = (state) => ({
  currentProject: currentProjectSelector(state),
})

export default connect(maptStateToProps)(withServices('projectsService')(DocExtractionAffixWrapper));
