import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { startCase } from 'lodash';

function ChargeCodeOptions({
  chargeCodeConfigData, chargeCode, staticChargeCodes, setCurrentChargeCodeCategory, onMenuOpen, onMenuClose
}) {
  const [currentChargeCode, setCurrentChargeCode] = useState({});

  useEffect(() => {
    setCurrentChargeCode({ 
      value: chargeCodeConfigData[chargeCode]?.category, 
      label: chargeCodeConfigData[chargeCode]?.category 
    });
  }, [chargeCodeConfigData, chargeCode]);

  return (
    <>
      <ReactSelect
          placeholder="Select Charge Code Category"
          classNamePrefix="react-select chargeCodeSelect"
          value={currentChargeCode}
          maxMenuHeight={200}
          menuPosition="fixed"
          menuPlacement="auto"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={staticChargeCodes.map(category => ({ value: startCase(category), label: startCase(category) }))}
          onChange={
            (selectedChargeCode) => {
              const { total, colIndex, key } = chargeCodeConfigData[chargeCode];
              setCurrentChargeCode({
                ...selectedChargeCode, total, key, colIndex
              });
              setCurrentChargeCodeCategory({
                total, key, colIndex, category: selectedChargeCode.value
              });
            }
          }
        />
    </>
  );
}

export default ChargeCodeOptions;
