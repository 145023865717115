import React, { useEffect, useState } from 'react';
import { isEmpty  } from 'lodash';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';
import DictionaryTable from '../DictionaryTable/DictionaryTable';
import './UpdateDictionaryModal.scss';
import WithLoader from '../WithLoader/WithLoader';

function UpdateDictionaryModal({ show, onHide, project, data, isLoading, setShowEditDictionary }) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
  const [isProjectDictionaryEmpty, setIsProjectDictionaryEmpty] = useState(true);
  const [projectDictionaryData, setProjectDictionaryData] = useState({});
  const [headDictionaryData, setHeadDictionaryData] = useState({});

  useEffect(() => {
    if(!isEmpty(data?.projectDictionary)) {
      const isFound = Reflect.ownKeys(data?.projectDictionary).find(key => key !== 'notClassified');
      setIsProjectDictionaryEmpty(!isFound)
    }
  }, [data?.projectDictionary]);

  return (
    <FormModal 
      show={show} 
      onHide={onHide} 
      onSubmit={onSubmit} 
      isSubmitting={isSubmitting} 
      className="form-modal-dictionary"
      showFooter={!isProjectDictionaryEmpty}
      title={`Edit Dictionary - ${project?.name}`}
    >
      <WithLoader isLoading={isLoading}>
        {!isProjectDictionaryEmpty ? <DictionaryTable 
        data={data?.projectDictionary}
        headDictionary={data?.headDictionary}
        categoriesList={data?.categoriesList?.categoriesList}
        hasSubCategory={data?.categoriesList?.hasSubCategory}
        bindSubmission={bindSubmission} 
        isSubmitting={isSubmitting}
        onSuccess={onHide}
        project={project}
        setShowEditDictionary={setShowEditDictionary}
        projectDictionaryData={projectDictionaryData}
        setProjectDictionaryData={setProjectDictionaryData}
        headDictionaryData={headDictionaryData}
        setHeadDictionaryData={setHeadDictionaryData}
      /> :
      <div className='no-data-container'>No Data</div>}
      </WithLoader>
    </FormModal>
  );
}

export default UpdateDictionaryModal;