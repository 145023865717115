/* eslint-disable react/require-default-props */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import * as PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { isNil, get as getProperty } from 'lodash';
import moment from 'moment';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import IconButton from '../../core/Button/IconButton';
import { DataSpreadSheet } from './DataSpreadSheet';
import SpreadSheetToolbarButton from '../../core/Spreadjs/Toolbar/SpreadSheetToolbarButton';
import coreStyle from '../../core/Spreadjs/SpreadSheets.module.scss';

import {
  DocumentFileTypes,
  projectReportsConstantsMonthRows,
  reportSheetName,
  USCurrencyFormat,
  projectReportRRWorkingAsOnDate,
  projectReportsConstantsTrailingColRows,
  projectReportsConstantsYTDColRows,
  projectReportsConstantsBudgetedColRows,
  projectReportsConstantsPerformaColRows,
  USDateFormat
} from '../../../constants';

import {
  currentProjectReportSelector,
  currentProjectServicingUnitStatusAssumption,
  currentProjectProductionUnitStatusAssumption
} from '../../../store/selectors';
import { sortPeriodDateByYear } from '../../../utils/utils';
import {
  showBackdropWithProgress,
  setLoadingPercent,
  hideBackdropWithProgress
} from '../../../store/loadingBackdropWithProgress';

const {
  financialSheetName,
  rrWorkingSheetName,
  evaluationVersion,
  constantSheetName,
  rrRentStepsSheetName
} = reportSheetName;
let gcCharts, gcIO;

class ReportSpreadSheet extends React.Component {
  constructor(props) {
    super(props);
    this.refDataSheet = React.createRef();
  }

  get datasheet() {
    return this.refDataSheet.current;
  }

  get spreadsheet() {
    return this.datasheet.spreadsheet;
  }

  get workbook() {
    return this.spreadsheet.workbook;
  }

  handleReady = async () => {
    gcCharts = await import('@grapecity/spread-sheets-charts');
    let { IO } = await import('@grapecity/spread-excelio');
    gcIO = IO;
    this.loadData();
  };

  loadData = () => {
    const { reports } = this.props;
    if (!reports || !this.datasheet || !this.spreadsheet) {
      setTimeout(() => {
        this.loadData();
      }, 100);
      return;
    }

    if (reports && this.spreadsheet && this.datasheet) {
      this.setReportData();
    }
  };

  async setReportData() {
    const {
      store,
      reports,
      companyUuid,
      templateUuid,
      modelUuid,
      apiClient,
      isOpen,
      reportUuid
    } = this.props;
    // eslint-disable-next-line new-cap
    const excelIO = new gcIO();
    this.spreadsheet.suspend();
    store && store.dispatch(showBackdropWithProgress(0));
    if (excelIO && !!Reflect.ownKeys(reports).length) {
      let url;
      if (isOpen) {
        url = `/shared/reports/${reportUuid}/reportTemplateFile`;
      } else {
        url = `/companies/${companyUuid}/templates/${templateUuid}/models/${modelUuid}/reportTemplateFile`;
      }
      apiClient.get(url, { responseType: 'blob' }).then((blob) => {
        excelIO.open(blob, (wbjson) => {
          this.spreadsheet.workbook.fromJSON(wbjson, {
            incrementalLoading: {
              loading: (progress) => {
                progress = (progress * 100).toFixed(1);
                store && store.dispatch(setLoadingPercent(progress));
              },
              loaded: () => {
                if (this.getSheetByName(evaluationVersion)) {
                  const { sheet } = this.getSheetByName(evaluationVersion);
                  if (!isNil(sheet)) {
                    this.spreadsheet.workbook.removeSheet(0); // removing Evaluation version sheet
                  }
                }
                this.setSheetData(reports);
                setTimeout(
                  () => store && store.dispatch(hideBackdropWithProgress()),
                  500
                );
              }
            }
          });
        });
      });
    }
    this.spreadsheet.resume();
  }

  setSheetData(reports) {
    this.spreadsheet.suspend();
    Reflect.ownKeys(reports).forEach((sheetName) => {
      if (reports[sheetName]) {
        this.setSheet(sheetName, reports[sheetName]);
      }
    });
    this.setDefaultConfig();
    this.bindSheetEvents();
    this.spreadsheet.resume();
  }

  getSheetByName(sheetName) {
    const { sheets } = this.spreadsheet.workbook;
    let result = null;
    if (sheets.length) {
      // eslint-disable-next-line prefer-destructuring
      result = sheets
        .map(
          (sheet, sheetIndex) =>
            sheet.name() === sheetName && { sheet, sheetIndex }
        )
        .filter((sheet) => sheet)[0];
    }
    return result;
  }

  setSheet(sheetName, sheetData) {
    this.spreadsheet.suspend();
    const template = this.getSheetByName(sheetName);
    const constantSheet = this.getSheetByName(constantSheetName);
    const isRowExist = sheetData && !!sheetData.rows.length;

    if (template && template.sheet) {
      const { sheet } = template;
      sheet.setRowCount(sheet.getRowCount() + 1000);
      sheet.setColumnCount(sheet.getColumnCount() + 100);
    }
    if (
      (sheetName === financialSheetName ||
        sheetName === rrWorkingSheetName ||
        sheetName === rrRentStepsSheetName) &&
      isRowExist &&
      template
    ) {
      let initialRow;
      initialRow = sheetName === financialSheetName ? 0 : 2;
      const { rows, columns } = sheetData;
      const { sheet, sheetIndex } = template;
      if (!!columns.length && !!rows.length) {
        const headers = columns.map((column) => column.headerName);
        const dataRows = rows.map((row) =>
          columns.map((column) => row[column.name])
        );
        sheet.setArray(initialRow, 0, [headers, ...dataRows]);
        this.setHeaderStyle(sheetIndex, sheetName, initialRow, columns);
        this.setColumnFormat(sheetIndex, columns, rows.length, initialRow);
        this.spreadsheet.freezeRow(sheetIndex, initialRow + 1);
      }

      if (sheetName === financialSheetName && isRowExist && template) {
        const { sheetIndex } = constantSheet;
        this.setConstantsMonthsInfo(sheetIndex, sheetData.periodInfo);
        this.setConstantsBudgetedMonths(sheetIndex, sheetData.meta);
        this.setConstantsPerformaMonths(sheetIndex, sheetData.meta);
        this.setConstantsTrailingMonthsInfo(sheetIndex, sheetData);
        this.updateConstantsUnitNumbers();
        this.setColumnOutlines(sheet, columns);
      }
      if (sheetName === rrWorkingSheetName && isRowExist && template) {
        this.setAsOnDateToRRWorking(
          constantSheet.sheetIndex,
          sheetData.asOnDate
        );
      }
    }
    this.spreadsheet.resume();
  }

  setHeaderStyle(sheetIndex, sheetName, row, columns) {
    this.spreadsheet.suspend();
    const columnCount =
      sheetName === financialSheetName ? columns.length - 4 : columns.length;
    this.spreadsheet.setCellRangeAttr(
      sheetIndex,
      row,
      0,
      1,
      columnCount,
      'backColor',
      '#213a64'
    );
    this.spreadsheet.setCellRangeAttr(
      sheetIndex,
      row,
      0,
      1,
      columns.length,
      'foreColor',
      'white'
    );

    if (sheetName === financialSheetName) {
      this.spreadsheet.setCellRangeAttr(
        sheetIndex,
        row,
        columns.length - 4,
        1,
        4,
        'backColor',
        '#66b6f6'
      ); // setting diffrent color to trailing months.
    }

    this.spreadsheet.resume();
  }

  setColumnFormat(sheetIndex, columns, rowCount, initialRow) {
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(sheetIndex);

    columns &&
      columns.forEach((column, columnIndex) => {
        if (column && column.isAmountValue) {
          this.spreadsheet.setCellRangeAttr(
            sheetIndex,
            1,
            columnIndex,
            rowCount,
            1,
            'formatter',
            USCurrencyFormat
          );
          sheet.autoFitColumn(columnIndex);
        }
        if (column && column.isDateField) {
          this.setDateFormatToCell(sheet, {
            initialRow: 1,
            col: columnIndex,
            rowCount,
            colCount: 1
          });
          sheet.autoFitColumn(columnIndex);
        }
        if (
          column &&
          column?.hasOwnProperty('isSubCategoryAvailable') &&
          !column.isSubCategoryAvailable
        ) {
          sheet.setColumnWidth(columnIndex, 0);
          sheet.setColumnResizable(
            columnIndex,
            false,
            this.spreadsheet.GC.Spread.Sheets.SheetArea.colHeader
          );
        }
        sheet
          .getRange(initialRow, columnIndex, rowCount + 1, 1)
          .borderRight(
            new this.spreadsheet.GC.Spread.Sheets.LineBorder(
              '#00000',
              this.spreadsheet.GC.Spread.Sheets.LineStyle.thin
            )
          );
      });
    this.spreadsheet.resume();
  }

  setDateFormatToCell(sheet, cellRange) {
    this.spreadsheet.suspend();
    const { initialRow, col, rowCount, colCount } = cellRange;
    for (let row = initialRow; row < rowCount; row++) {
      const columnValue = sheet.getCell(row, col).value();
      this.validateAndSetDateToCell(sheet, row, col, columnValue);
    }
    this.spreadsheet.resume();
  }

  validateAndSetDateToCell(sheet, row, col, date) {
    this.spreadsheet.suspend();
    const isValidDate = moment(new Date(date), 'MM/DD/YYYY')._isValid;
    if (!!date && isValidDate) {
      sheet.setValue(row, col, new Date(date));
      sheet.setFormatter(row, col, USDateFormat);
    }
    this.spreadsheet.resume();
  }

  setColumnOutlines(sheet, columns) {
    this.spreadsheet.suspend();
    const monthsColumnIndexes = [];
    columns.forEach((col, index) => {
      if (col && col.isOutlineEnable) {
        monthsColumnIndexes.push(index);
      }
    });
    if (monthsColumnIndexes.length > 1) {
      const startIndex = monthsColumnIndexes[0];
      const endIndex =
        monthsColumnIndexes[monthsColumnIndexes.length - 1] -
        monthsColumnIndexes[0] +
        1;
      sheet.columnOutlines.group(startIndex, endIndex);
      const sheetOutlines = this.getColumnOutlineLevels(
        sheet,
        monthsColumnIndexes.length + 1
      );
      if (sheetOutlines.length) {
        sheet.columnOutlines.expandGroup(sheetOutlines[0], false);
        sheet.invalidateLayout();
      }
    }
    this.spreadsheet.resume();
  }

  getColumnOutlineLevels(sheet, columnCount) {
    this.spreadsheet.suspend();
    const outlineLevels = sheet.columnOutlines.getMaxLevel();
    const outlines = [];
    for (let index = 0, i = 0; index <= outlineLevels; index++) {
      for (let col = 0; col < columnCount; col++) {
        // Fetch group information of Outline
        const groupInfo = sheet.columnOutlines.find(col, index);
        if (!isNil(groupInfo)) {
          outlines[i] = groupInfo;
          i++;
          col = groupInfo.end;
        }
      }
    }
    this.spreadsheet.resume();
    return outlines;
  }

  getMonthlyDataStatus(isMonthlyDataAvailable) {
    return isMonthlyDataAvailable ? 'Monthly' : 'Annually';
  }

  setConstantsMonthsInfo(sheetIndex, periodInfo) {
    this.spreadsheet.suspend();
    if (periodInfo && !!Reflect.ownKeys(periodInfo).length) {
      const { taggedPeriods } = periodInfo;
      const taggedYears = Reflect.ownKeys(taggedPeriods);
      const {
        yearCol,
        monthCol,
        periodFromCol,
        periodToCol,
        monthStatusCol,
        yearStartRow,
        yearEndRow
      } = projectReportsConstantsMonthRows;
      taggedYears.forEach((year, yearRowIndex) => {
        const yearRow = yearRowIndex + yearStartRow;
        const { monthsExtracted, monthlyDataAvailable, periodFrom, periodTo } =
          taggedPeriods[year];
        const monthColumnCaption = `${year} Amount`;
        if (yearRow < yearEndRow) {
          const months = Array.from(new Set(monthsExtracted));
          this.spreadsheet.setCellValue(
            sheetIndex,
            yearRow,
            monthCol,
            months.length
          );
          this.spreadsheet.setCellValue(
            sheetIndex,
            yearRow,
            yearCol,
            monthColumnCaption
          );
          this.spreadsheet.setCellValue(
            sheetIndex,
            yearRow,
            periodFromCol,
            periodFrom
          );
          this.spreadsheet.setCellValue(
            sheetIndex,
            yearRow,
            periodToCol,
            periodTo
          );
          this.spreadsheet.setCellValue(
            sheetIndex,
            yearRow,
            monthStatusCol,
            this.getMonthlyDataStatus(monthlyDataAvailable)
          );
        }
      });
    }
    this.spreadsheet.resume();
  }

  setConstantsBudgetedMonths(sheetIndex, periodMetaInfo) {
    this.spreadsheet.suspend();
    const documents = periodMetaInfo.filter(
      (meta) => meta.documentType === DocumentFileTypes.OS_BUDGETED.key
    );
    const budgetedYearMonthInfo = sortPeriodDateByYear(documents);
    const periodMonths = Object.values(budgetedYearMonthInfo);
    const periodYear = Reflect.ownKeys(budgetedYearMonthInfo);

    projectReportsConstantsBudgetedColRows.forEach((period, index) => {
      const foundPeriod =
        documents.find(
          (date) =>
            moment(date.periodFrom, 'MM/DD/YYYY').format('YYYY') ===
            periodYear[index]
        ) || null;
      this.spreadsheet.setCellValue(
        sheetIndex,
        period.yearRow,
        period.monthCol,
        periodMonths[index] || null
      );
      if (!isNil(foundPeriod) && !!periodMonths[index]) {
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.periodFromCol,
          foundPeriod.periodFrom
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.periodToCol,
          foundPeriod.periodTo
        );
      }
    });
    this.spreadsheet.resume();
  }

  setConstantsPerformaMonths(sheetIndex, periodMetaInfo) {
    this.spreadsheet.suspend();
    const documents = periodMetaInfo.filter(
      (meta) => meta.documentType === DocumentFileTypes.OS_PROFORMA.key
    );
    const performaYearMonthInfo = sortPeriodDateByYear(documents);
    const periodMonths = Object.values(performaYearMonthInfo);
    const periodYear = Reflect.ownKeys(performaYearMonthInfo);

    projectReportsConstantsPerformaColRows.forEach((period, index) => {
      const foundPeriod =
        documents.find(
          (date) =>
            moment(date.periodFrom, 'MM/DD/YYYY').format('YYYY') ===
            periodYear[index]
        ) || null;
      const documentTaggedMonth = periodMonths[index]
        ? periodMonths[index]
        : null;
      this.spreadsheet.setCellValue(
        sheetIndex,
        period.yearRow,
        period.monthCol,
        documentTaggedMonth
      );
      if (!isNil(foundPeriod) && !!periodMonths[index]) {
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.periodFromCol,
          foundPeriod.periodFrom
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.periodToCol,
          foundPeriod.periodTo
        );
      }
    });
    this.spreadsheet.resume();
  }

  setConstantsTrailingMonthsInfo(sheetIndex, sheetData) {
    this.spreadsheet.suspend();
    const trailingColumnName = sheetData.trailingMonthName || 'N/A';
    if (sheetData.periodInfo) {
      const {
        periodInfo: { t12, ytd }
      } = sheetData;
      const { monthsExtracted, periodFrom, periodTo, monthlyDataAvailable } = t12;
      const months = Array.from(new Set(monthsExtracted));
      projectReportsConstantsTrailingColRows.forEach((period) => {
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.monthCol,
          months.length
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.yearCol,
          trailingColumnName
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.periodFromCol,
          periodFrom
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.periodToCol,
          periodTo
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.monthStatusCol,
          this.getMonthlyDataStatus(monthlyDataAvailable)
        );
      });
      this.setYTDTrailingMonthsInfo(sheetIndex, ytd);
    }
    this.spreadsheet.resume();
  }

  setYTDTrailingMonthsInfo(sheetIndex, ytdInfo) {
    this.spreadsheet.suspend();
    if (ytdInfo) {
      const { monthsExtracted, periodFrom, periodTo, monthlyDataAvailable } =
        ytdInfo;
      const months = Array.from(new Set(monthsExtracted));
      const YtdCaption = months.length ? 'YTD' : 'N/A';
      projectReportsConstantsYTDColRows.forEach((period) => {
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.monthCol,
          months.length
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.yearCol,
          YtdCaption
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.periodFromCol,
          periodFrom
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.periodToCol,
          periodTo
        );
        this.spreadsheet.setCellValue(
          sheetIndex,
          period.yearRow,
          period.monthStatusCol,
          this.getMonthlyDataStatus(monthlyDataAvailable)
        );
      });
    }
    this.spreadsheet.resume();
  }

  setDefaultConfig() {
    this.spreadsheet.suspend();
    this.spreadsheet.workbook.options.newTabVisible = false;
    this.spreadsheet.workbook.options.incrementalLoading = true;
    this.spreadsheet.workbook.setActiveSheet(financialSheetName);
    this.spreadsheet.resume();
  }

  bindSheetEvents() {
    const { sheetIndex } = this.getSheetByName(constantSheetName);
    this.spreadsheet.workbook.bind(
      this.spreadsheet.GC.Spread.Sheets.Events.ActiveSheetChanged,
      (sheetName, { newSheet }) => {
        this.spreadsheet.workbook.refresh();
        newSheet.setColumnVisible(0, true);
      }
    );
    // Hide Constant Sheet here
    this.spreadsheet.workbook.sheets[sheetIndex].visible(false);
  }

  updateConstantsUnitNumbers() {
    const { store: { getState } = {} } = this.props;
    const state = getState();
    const currentReport = currentProjectReportSelector(state);
    const currentProjectRRRowsCount = getProperty(
      currentReport,
      "['RR Working'].rows.length",
      0
    );
    const unitNumbersProductionAssumption =
      currentProjectProductionUnitStatusAssumption(state);
    const unitNumbersServicingAssumption =
      currentProjectServicingUnitStatusAssumption(state);
    let unitStatus = 1;

    if (
      !unitNumbersProductionAssumption ||
      unitNumbersProductionAssumption === 1234
    ) {
      if (
        !unitNumbersServicingAssumption ||
        unitNumbersServicingAssumption === 'Annual'
      ) {
        unitStatus = currentProjectRRRowsCount || 1;
      } else {
        unitStatus = unitNumbersServicingAssumption;
      }
    } else {
      unitStatus = unitNumbersProductionAssumption;
    }

    // this.spreadsheet.getSheet(constantSheetIndex).getCell(
    //   CF_UNIT_STATUS_COL_INFO.row, CF_UNIT_STATUS_COL_INFO.column
    // ).value(unitStatus);
  }

  setAsOnDateToRRWorking(sheetIndex, asOnDate = '') {
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    const { row, column } = projectReportRRWorkingAsOnDate;
    this.validateAndSetDateToCell(sheet, row, column, asOnDate);
    this.spreadsheet.resume();
  }

  downloadXLS() {
    const { project } = this.props;
    const data = this.datasheet.getWorkbookDataWithoutSheetProtection();
    const fileName = `${project.name}-report.xlsx`;
    const io = new gcIO();
    io.save(
      data,
      (blob) => {
        saveAs(blob, fileName);
      },
      console.log
    );
  }

  render() {
    const { sheetCount, style, className, canShareReport, canDownloadReportWorkbook, onShowReportLink } = this.props;

    return (
      <div className={`${className} vertical-section`} style={style}>
        <DataSpreadSheet
          toolbarCustomRightChild={
            <>
              <div className={`${coreStyle.spreadsheetSideToolbar} border-0`}>
                <div
                  className={`position-relative d-inline-block ${coreStyle.spreadsheetToolbarSeparator}`}
                  aria-disabled="true"
                  role="separator line"
                >
                  {' '}
                  &nbsp;{' '}
                </div>
                {(canShareReport && canDownloadReportWorkbook) && (
                  <SpreadSheetToolbarButton
                    title="Share Report Link"
                    size="lg"
                    className={coreStyle.shareIcon}
                    onClick={() => onShowReportLink()}
                  />
                )
                }

                )

                {
                  canDownloadReportWorkbook
                  && (
                    <IconButton
                      variant="success"
                      size="sm"
                      text="Download Workbook"
                      id="AddAccountsExecutive"
                      onClick={() => this.downloadXLS()}
                      icon={faDownload} />
                  )

                }
              </div>
            </>
          }
          onReady={this.handleReady}
          ref={this.refDataSheet}
          isProjectReport
          sheetCount={sheetCount}
        />
      </div>
    );
  }
}

ReportSpreadSheet.propTypes = {
  showToolbar: PropTypes.bool.isRequired,
  sheetCount: PropTypes.number.isRequired,
  store: PropTypes.object,
  reports: PropTypes.object.isRequired,
  project: PropTypes.object,
  projectUuid: PropTypes.string.isRequired,
  templateUuid: PropTypes.string.isRequired,
  apiClient: PropTypes.object,
  modelUuid: PropTypes.string.isRequired,
  canShareReport: PropTypes.bool.isRequired,
  companyUuid: PropTypes.string,
  onShowReportLink: PropTypes.func
};

ReportSpreadSheet.defaultProps = {
  customToolbarButtons: null,
  className: '',
  canShareReport: false,
  style: {},
  data: null
};

export default ReportSpreadSheet;
