import { useCallback } from "react";
import { useSelector } from "react-redux";
import { exportCSVFile, usDateFormat } from "../utils/utils";
import {
  currentCompanySelector,
  companiesDetailsSelector
} from '../store/selectors';

export default () => {
  const currentCompanyUuid = useSelector(currentCompanySelector);
  const companiesList = useSelector(companiesDetailsSelector);
  const currentCompanyName = companiesList[currentCompanyUuid].name;

  return useCallback((usersList) => {
    const usersCsvList = usersList.map((user) => ({
      company: currentCompanyName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.UserRole.role,
      updatedAt: usDateFormat(user.updatedAt),
      createdAt: usDateFormat(user.createdAt)
    }));

    const headers = Reflect.ownKeys(usersCsvList[0]);
    const fileName = 'users.csv';
    exportCSVFile(usersCsvList, headers, fileName);
  });
};
