import React from 'react'
import GoogleSignUpButton from './GoogleSignUpButton';
import LinkedInSignUpButton from './LinkedInSignUpButton';

export default function SocialSignUpComponent({ onGoogleLoginSuccess, onGoogleLoginFailure }) {
  return (
    <div className="position-relative">
      <div className="mb-3 google-btn-wrapper">
        <GoogleSignUpButton onSuccess={onGoogleLoginSuccess} onFailure={onGoogleLoginFailure}/>
      </div>
      <div className="mb-3">
        <LinkedInSignUpButton/>
      </div>
    </div>
  )
}
