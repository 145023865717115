import React, { useCallback } from 'react';
import { withServices } from 'reaf';
import ProjectExporterPopups from '../ProjectExporterPopups/ProjectExporterPopups';
import useBool from '../../../hooks/useBool';
import messages from '../../../../locales';

const defaultLabel = (
  <span className="text-primary" style={{ cursor: 'pointer' }}>Delete Property Exporter</span>
);
const DeleteProjectExporter = ({
  label = defaultLabel, projectExporter, companyUUID, projectExporterService, toastrService
}) => {
  const [deleteProjectExporterVisible, showDeleteProjectExporter, hideDeleteProjectExporter] = useBool();

  const onDeleteConfirm = useCallback(async () => {
    try {
      await projectExporterService.deleteProjectExporter(companyUUID, projectExporter.uuid);
      toastrService.success(messages.toastMessage.DELETE_PROJECT_EXPORTER);
    } catch (e) {
      toastrService.error(e.message);
    }
  }, [projectExporter.uuid, companyUUID, projectExporterService]);

  return (
    <>
      <div onClick={showDeleteProjectExporter}>
        {label}
      </div>
      <ProjectExporterPopups
        projectExporter={projectExporter} showDeleteProjectExporter={deleteProjectExporterVisible}
        onDeleteConfirm={onDeleteConfirm} onDeleteCancel={hideDeleteProjectExporter} />
    </>
  );
};

export default (withServices('projectExporterService', 'toastrService')(DeleteProjectExporter));
