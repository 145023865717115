import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { loadingBackdropSelector } from '../../../store/selectors';
import loadingIcon from '../../../assets/images/Gear-4s-60px.svg';

function LoadingBackdrop({ show, message }) {
  return (
    <Modal show={show} backdrop="static" animation={false} centered>
      <Modal.Body className="bg-dark text-light d-flex  justify-content-center">
        <img src={loadingIcon} height={45} alt="Loading Icon" />
        <span className="h4 ml-2 mt-auto mb-auto">{message}</span>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const lbState = loadingBackdropSelector(state);
  return {
    show: lbState.show,
    message: lbState.message
  };
};

export default connect(mapStateToProps)(LoadingBackdrop);
