import React from 'react';
import {
  Container, Row, Col, Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faArrowCircleLeft as prevIcon } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight as nextIcon } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';

function ListHeader({
  totalPages,
  currentPage,
  onPageChange,
  onPageSizeChange,
  pageSize,
  searchText,
  onSearch,
  onAdd,
  addButtonText,
  utilityButtons,
  onExportCSV,
  isGlobal
}) {
  function pageChanged(e) {
    const requestedPage = +e.target.value;
    if (requestedPage < 1 || requestedPage > totalPages) {
      return;
    }
    onPageChange(requestedPage);
  }

  return (
    <Container fluid>
      <Row className="p-0">
        <Col xs="auto" className="pl-0">
          <Button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage <= 1}
            variant="secondary"
            size="sm"
            className="btn-xs mr-1 smallIconButton"
          >
            <FontAwesomeIcon icon={prevIcon} />
          </Button>
          <span className="small">
            Page&nbsp;
            <input
              type="number"
              value={currentPage}
              max={totalPages}
              min={1}
              className="d-inline-block text-center number-input-width input-field-col form-control"
              onChange={pageChanged}
              onFocus={e => e.target.select()}
            />
            &nbsp;
            {`of ${totalPages}`}
          </span>
          <Button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage >= totalPages}
            variant="secondary"
            size="sm"
            className="btn-xs ml-1 smallIconButton"
          >
            <FontAwesomeIcon icon={nextIcon} />
          </Button>
        </Col>
        <Col xs="auto">
          <span className="small">
            Page Size:&nbsp;
            <input
              type="number"
              value={pageSize}
              min={1}
              className="ml-1 d-inline-block text-center number-input-width input-field-col form-control"
              onChange={e => onPageSizeChange(+e.target.value)}
              onFocus={e => e.target.select()}
            />
          </span>
        </Col>
        <Col className="text-right d-flex justify-content-end align-items-center">
          {utilityButtons && utilityButtons.map((item, index) => 
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>{item}</React.Fragment>)}
        </Col>
        <Col xs="auto">
          <span>
            <input className="ml-2 input-field-col form-control" value={searchText} onChange={e => onSearch(e.target.value)} placeholder="Search..." />
          </span>
        </Col>
        {
          addButtonText && !isGlobal &&
          (
            <Col xs="auto" className="button-aligned input-field-col">
              <Button variant="secondary" size="xs" onClick={onAdd}>{addButtonText}</Button>
            </Col>
          )
        }
        {
          onExportCSV &&
          (
            <Col xs="auto" className="button-aligned input-field-col px-0">
              <Button variant="secondary" size="xs" onClick={onExportCSV}>Download CSV</Button>
            </Col>
          )
        }
      </Row>
    </Container>
  );
}

export default ListHeader;
