import React, { useCallback } from 'react';
import {
  Row, Col, Table
} from 'react-bootstrap';
import { sumBy, isNil, isNumber } from 'lodash';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import DocExtractionAffixTableWrapper from '../../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import { validateValue, displayNumberInUSFormat } from '../../../../utils/utils';
import RentRollConfigHeader from '../../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../../DocExtractionSummary/DocExtractionWithoutSummary';

import style from './DocExtractonRetailLeaseExpiration.module.scss';
import { leaseEndRangeSelector } from '../../../../store/selectors';
import DocExtractionAffixBarChart from '../../DocExtractionAffixChart/DocExtractionAffixBarChart';
import './DocExtractonRetailLeaseExpiration.scss';
import messages from '../../../../../locales/en-US';


function DocExtractonRetailLeaseExpiration({ leaseExpiration }) {
  const calculateProRate = useCallback(sqFt => {
    const calculation = !isNil(sqFt) ? (sqFt / sumBy(leaseExpiration, 'SF Rolling')).toFixed(2) : 0;
    return isNumber(Number(calculation)) ? calculation : 0;
  });

  return (
    <>
      <Formik
        key="leaseExpiration"
        onSubmit={() => null}
        initialValues={[]}
        render={() => (
          <Form>
            {
              leaseExpiration.length ? (
                <>
                  <RentRollConfigHeader
                    title="Lease Expiration Schedule"
                    hideChartSwitcher />
                  <div className={style.occupancyRRWrapper}>
                    <DocExtractionAffixTableWrapper>
                      <Row className={style.analysisHeader}>
                        <Col className={`col-md-auto ${style.documentStatusHeader}`}>Year</Col>
                        <Col className={`col-md-auto ${style.sqFtHeader}`}>SF Rolling</Col>
                        <Col className={`col-md-auto ${style.percentHeader}`}>Pro Rata (%)</Col>
                      </Row>
                      <Row className="position-relative">
                        <Col className={style.analysis}>
                          <Table>
                            <tbody>
                              {
                                leaseExpiration.map(leaseInfo => (
                                  <tr key={leaseInfo.name}>
                                    <td 
                                      className={style.documentStatusHeader} 
                                      title= {leaseInfo.name}
                                    >{
                                      leaseInfo.name}
                                    </td>
                                    <td className={style.sqFtHeader} title = {displayNumberInUSFormat(validateValue(leaseInfo['SF Rolling']))}>
                                      {displayNumberInUSFormat(validateValue(leaseInfo['SF Rolling']))}
                                    </td>
                                    <td className={style.percentHeader} title = {`${calculateProRate(leaseInfo['SF Rolling'])}%`}>
                                      {calculateProRate(leaseInfo['SF Rolling'])}
                                      {' '}
%
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </DocExtractionAffixTableWrapper>

                    <DocExtractionAffixTableWrapper>
                      <Row className={`${style.analysischartWrapper} analysisAffixchart`}>
                        <Col>
                          <DocExtractionAffixBarChart
                            data={leaseExpiration}
                            xAxisKeyName="name"
                            width={460}
                            defaultChartColor={['#007bff']}
                          />
                        </Col>
                      </Row>

                    </DocExtractionAffixTableWrapper>
                  </div>
                </>


              ) : <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
            }
          </Form>
        )}
      />

    </>
  );
}

const mapStateToProps = state => ({
  leaseExpiration: leaseEndRangeSelector(state)
});

export default connect(mapStateToProps)(withServices('store', 'toastrService')(DocExtractonRetailLeaseExpiration));
