import BaseCommand from './BaseCommand';

class SetFilterCommand extends BaseCommand {
  run(spreadsheet, options) {
    const { activeSheetIndex } = options;
    const sheet = spreadsheet.getSheet(activeSheetIndex);
    if (sheet.rowFilter()) {
      sheet.rowFilter(null);
    } else {
      const selections = spreadsheet.getSheet().getSelections();
      if (selections.length > 0) {
        const selection = selections[0];
        sheet.rowFilter(
          new spreadsheet.GC.Spread.Sheets.Filter.HideRowFilter(
            new spreadsheet.GC.Spread.Sheets.Range(0, selection.col, sheet.getRowCount(), selection.colCount),
          ),
        );
      }
    }
  }
}

const setFilterCommand = SetFilterCommand.get();

export default setFilterCommand;
