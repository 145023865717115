import React, { useEffect } from 'react';
import { withServices } from 'reaf';
import queryString from 'query-string';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';

function AdminTokenValidationPage({ authService }) {
    const { search } = window.location;
    const parsedQS = queryString.parse(location.search);
    const validateAuthToken = async (token) => {
       await authService.validateAdminCGAuthToken(token);
    }

    useEffect(() => {
      validateAuthToken(parsedQS.token)
    }, [parsedQS.token])


  return (
    <LoggedOutUserPage>
      <div className="d-flex align-items-center h-100 justify-content-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </LoggedOutUserPage>
  );
}

export default withServices('authService')(AdminTokenValidationPage);
