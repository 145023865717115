import React from 'react'
import AddCardDetailForm from '../../app/AddCardDetailForm/AddCardDetailForm'
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage'

export default function AddCardDetailPage() {
  return (
    <LoggedInUserPage hideMenus>
      <div className="vertical-section dotted-bg bg-light">
        <AddCardDetailForm />
      </div>
    </LoggedInUserPage>
  )
}
