/* eslint-disable array-callback-return */
/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-return-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-reflect */
/* eslint-disable max-depth */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  compact,
  difference,
  divide,
  filter,
  findIndex,
  get,
  groupBy,
  invertBy,
  isNil,
  isNumber,
  map,
  max,
  min,
  multiply,
  some,
  sortBy,
  sum,
  sumBy,
  uniq } from 'lodash';
import * as moment from 'moment';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import {
  DataSpreadSheet,
  rowColors,
  SOURCE_SHEET,
  SOURCE_SHEET_NAME,
  TARGET_SHEET,
  TARGET_SHEET_NAME,
  WORKING_SHEET_NAME
} from './DataSpreadSheet';
import { rrCellChangedEvent } from './Events/RRCellChangedEvent';
import {
  affixComponentName,
  affordableRnotationOptions,
  ALLOWED_RETAIL_CC_CATEGORY,
  cellNumberFormat,
  DocumentFileTypes,
  excludedRentRollMFColValue,
  leaseTypeList,
  PROPERTY_TYPES,
  RENT_ROLL_MF_COLUMN_NAME,
  RETAIL_CHARGE_CODE_FORMULA,
  sheetIndex,
  rrSheetIndex,
  spreadColorConfig,
  TEMPLATE_TAGS,
  tenantTypeColumnCalc,
  unitMixNonRevenueUnits,
  USCurrencyFormat,
  USDateFormat
} from '../../../constants';
import DocExtractionTaggingInfo from '../DocExtractionTaggingInfo/DocExtractionTaggingInfo';
import SpreadSheetToolbarButton from '../../core/Spreadjs/Toolbar/SpreadSheetToolbarButton';
import coreStyle from '../../core/Spreadjs/SpreadSheets.module.scss';
import {
  arrayContainsArray,
  checkArrayIncludesValue,
  findValidKey,
  getAverage,
  getFilteredArrayCount,
  getKeyByValue,
  getPercentage,
  getTotalByKey,
  isBracketExists,
  isValidNumber
} from '../../../utils/utils';
import {
  setChargeCodeConfig,
  setFloorPlan,
  setLeaseConfig,
  setRetailLeaseTypeConfig,
  setRetailOccupancy,
  setRetailTenantTypeConfig,
  setUnitMixSummary,
  setUnitStatus
} from '../../../store/currentDocument';
import {
  chargeCodeConfigSelector,
  currentDocumentFloorPlanSelector,
  currentDocumentUnitStatusSelector,
  currentTemplateTagSelector,
  occupancyMappingSelector,
  occupancySelector,
  retailLeaseTypeConfigSelector,
  retailLeaseTypeDropDownSelector,
  staticChargeCodeMappingSelector,
  staticChargeCodeSelector,
  tenantNameUnitStatusSelector,
  unitMixSummaryConfigSelector,
  dynamicAssetTypesAsObjSelector,
  currentDocumentTaggingInfoSelector,
  mfRentRollConfigSelector
} from '../../../store/selectors';
import { KeyboardShortcutsOpened } from '../../../constants/eventTrackerMessage';
import messages from '../../../../locales/en-US';

const { targetSheetIndex, workingSheetIndex } = rrSheetIndex;

const statusMap = {
  "Occupied": "Occupied",
  "Vacant": "Vacant",
};

const tentativeColumnName = 'Tentative Status';

const columnMap = {
  "Status": tentativeColumnName,
  "MarketRent": "Market Rent",
  "MonthlyRent": "Monthly Rent",
  "LeaseEndDate": "End Date",
};

const cutoffPercentage = 30;

const errorMessageMap = {
  MONTHLY_RENT_0: {
    message: 'Monthly rent is 0 for occupied status',
    color: '#E5CB9F'
  },
  MONTHLY_RENT_NON_0: {
    message: 'Monthly rent is not 0 for vacant status',
    color: '#99C4C8'
  },
  CUTTOFF_ERROR: {
    message: `Deviation for monthly rent and market rent by ${cutoffPercentage}%`,
    color: '#EEE4AB',
  },
  ASOFDATE_AFTER_ENDDATE: {
    message: 'As of date is after lease end date',
    color: '#E2DEA9'
  }
};

const rentDiffPercentage = (marketRent, monthlyRent) => Math.abs(monthlyRent - marketRent);

const { invalidCellColor } = spreadColorConfig;
class RRSpreadSheet extends React.Component {
  constructor(props) {
    super(props);
    this.refDataSheet = React.createRef();
    this.state = {
      data: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.workbookData &&
      prevProps.workbookData !== this.props.workbookData
    ) {
      this.workbook.fromJSON(this.props.workbookData);
    } else if (prevProps.data !== this.props.data) {
      this.loadData(this.props.data);
    }
    this.props.onInitManager(this);
  }

  get datasheet() {
    return this.refDataSheet.current;
  }
    
  get spreadsheet() {
    return this.datasheet.spreadsheet;
  }

  get workbook() {
    return this.spreadsheet.workbook;
  }

  handleReady = () => {
    this.loadData();
    this.props.onInitManager(this);
  };

  loadData = () => {
    const { data, workbookData, onlySource } = this.props;
    if (!(data || workbookData) || !this.datasheet || !this.spreadsheet) {
      setTimeout(() => {
        this.loadData();
        if (onlySource) {
          this.removeAllSheetsExceptSourceSheet();
        }
      }, 100);
      return;
    }

    if (workbookData) {
      this.loadDataFromWorkbookData();
    } else {
      this.loadDataFromRawData();
      this.populateTentativeStatusOnFirstRender();
    }
    if (!onlySource) {
      this.registerEvents();
      this.rentRollEvents();
      this.datasheet.handleColumnsFormat();
      this.spreadsheet.workbook.refresh(); // Refreshing sheet to adjust sheet viewport
    } else {
      this.removeAllSheetsExceptSourceSheet();
    }
  };

  loadDataFromWorkbookData = () => {
    const { workbookData } = this.props;
    this.workbook.fromJSON(workbookData);
  };

  getSheetData() {
    const { sheets } = this.spreadsheet.getWorkbookData();
    const targetSheetData = sheets[TARGET_SHEET_NAME];
    const sourceSheetData = sheets[SOURCE_SHEET_NAME];
    return { targetSheetData, sourceSheetData };
  }

  removeAllSheetsExceptSourceSheet() {
    this.spreadsheet.suspend();
    const sheetCount = this.spreadsheet.workbook.sheets.length;
    for (let i = 1; i < sheetCount; i++) {
      this.spreadsheet.workbook.removeSheet(1);
    }
    this.spreadsheet.resume();
  }

  loadDataFromRawData = () => {
    const { data, onlySource } = this.props;
    const sourceRows = data?.source.rows || [];
    const sourceColumns = data?.source.columns || [];
    const extractedRows = data?.target.rows || [];
    const extractedColumns = data?.target.columns || [];

    const extractedHeaderRow = extractedColumns.reduce((header, column) => {
      header[column.name] = column.headerName;
      return header;
    }, {});

    const sourceHeaderRow = sourceColumns.reduce((header, column) => {
      header[column.name] = column.headerName;
      return header;
    }, {});

    this.datasheet.spreadsheet.suspend();
    this.loadSourceData(sourceColumns, [sourceHeaderRow, ...sourceRows]);
    if (!onlySource) {
      this.loadExtractedData(extractedColumns, [
        extractedHeaderRow,
        ...extractedRows
      ]);
      this.datasheet.setCellBorder(data);
      this.datasheet.setSheetData(
        workingSheetIndex,
        [],
        [],
        WORKING_SHEET_NAME,
        false
      );
      this.datasheet.highlightMappedRows(data?.source, data?.target);
      this.setOutlineToGroupedColumns();
    }
    this.datasheet.spreadsheet.resume();
  };

  loadSourceData = (columns, rows) => {
    this.datasheet.setSheetData(
      SOURCE_SHEET,
      columns,
      rows,
      SOURCE_SHEET_NAME,
      true,
      false,
      false
    );
  };

  loadExtractedData = (columns, rows) => {
    this.datasheet.setSheetData(
      TARGET_SHEET,
      columns,
      rows,
      TARGET_SHEET_NAME,
      false,
      true,
      true
    );
    this.datasheet.setDropDownColumns(TARGET_SHEET, { columns, rows });
  };

  onTargetIdValueChange(newValue, oldValue, sourceColCount = -1) {
    // un-match old value
    this.datasheet.updateRowBackColorForColumnValue(
      SOURCE_SHEET,
      0,
      oldValue,
      undefined,
      0,
      sourceColCount
    );
    // match new value
    this.datasheet.updateRowBackColorForColumnValue(
      SOURCE_SHEET,
      0,
      newValue,
      rowColors.mappedRow,
      0,
      sourceColCount
    );
  }

  registerEvents() {
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.CellChanged,
      rrCellChangedEvent,
      this
    );
    this.spreadsheet.workbook.bind(
      this.spreadsheet.GC.Spread.Sheets.Events.CellChanged,
      (_, args) => {
        const { sheetName, newValue, oldValue } = args;
        if (sheetName === TARGET_SHEET_NAME && oldValue !== newValue) {
          this.rentRollEvents();
        }
      }
    );
  }

  rentRollEvents(eventType = undefined) {
    const { dynamicAssetTypes } = this.props;
    const isRetail = !this.validateDocumentAssetType(
      dynamicAssetTypes.MULTIFAMILY.baseClass
    );
    this.setInitialChargeCode(isRetail);
    this.getUniqueUnitStatus();
    this.getUniqueFloorPlan(eventType);
    this.calculateUnitMixSummary(eventType);
    this.retrieveChargeCodes();
    this.setRentRollLeaseTypeData();
    if (isRetail) {
      this.getRetailLeaseExpirationData();
      this.getRetailOccupancyStatus();
      this.getRetailLeaseType();
      this.getRetailTenantTypeData();
    } else {
      setTimeout(() => {
        this.validateMonthlyRent();
      }, 1000);
    }
  }

  onSaveWorkbook() {
    const { onSaveData } = this.props;
    const workbookData = this.spreadsheet.getWorkbookData();
    onSaveData(workbookData);
  }

  validateDocumentAssetType(baseClass) {
    const { dynamicAssetTypes, assetType } = this.props;
    return (
      dynamicAssetTypes[assetType].baseClass === baseClass &&
      !dynamicAssetTypes[assetType].isRentRollRestricted
    );
  }

  getTargetSheetRowCount() {
    this.spreadsheet.suspend();
    let serialNumbers = [];
    const rowCount = this.spreadsheet.getSheet(targetSheetIndex).getRowCount();
    for (let row = 0; row < rowCount; row++) {
      const value = this.spreadsheet.getCellValue(targetSheetIndex, row, 0);
      if (!isNil(value)) {
        serialNumbers.push(value);
      }
    }
    this.spreadsheet.resume();
    return serialNumbers.length;
  }

  getColumnOutlineLevels(sheet, columnCount) {
    this.spreadsheet.suspend();
    const outlineLevels = sheet.columnOutlines.getMaxLevel();
    const outlines = [];
    for (let index = 0, i = 0; index <= outlineLevels; index++) {
      for (let col = 0; col < columnCount; col++) {
        // Fetch group information of Outline
        const groupInfo = sheet.columnOutlines.find(col, index);
        if (!isNil(groupInfo)) {
          outlines[i] = groupInfo;
          i++;
          col = groupInfo.end;
        }
      }
    }
    this.spreadsheet.resume();
    return outlines;
  }

  setOutlineToGroupedColumns() {
    const { dynamicAssetTypes } = this.props;
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    const sheetData = this.datasheet.getSheetSpecificData([TARGET_SHEET_NAME]);
    this.spreadsheet.suspend();
    if (!!sheetData.length && sheetData[0].columns) {
      const rentRollCols = sheetData[0].columns.filter(
        (col) => col.type && col
      );
      if (rentRollCols.length > 0) {
        if (
          this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)
        ) {
          const chargeCodeStartColIndex = findIndex(rentRollCols, [
            'type',
            'CHARGE_CODE'
          ]);
          const staticCCStartColIndex = findIndex(rentRollCols, [
            'type',
            'STATIC_CHARGE_CODE'
          ]);
          if (chargeCodeStartColIndex !== -1 && staticCCStartColIndex !== -1) {
            const endColIndex = rentRollCols.length - chargeCodeStartColIndex;
            const chargeCodeEndColumnIndex =
              chargeCodeStartColIndex - staticCCStartColIndex - 1;
            sheet.columnOutlines.group(
              staticCCStartColIndex,
              chargeCodeEndColumnIndex
            );
            sheet.columnOutlines.group(chargeCodeStartColIndex, endColIndex);
            const sheetOutlines = this.getColumnOutlineLevels(
              sheet,
              rentRollCols.length
            );
            //Expecting two column outlines for Rent Roll (Multifamily Documnet)
            if (sheetOutlines.length === 2) {
              sheet.columnOutlines.expandGroup(sheetOutlines[1], false);
              sheet.invalidateLayout();
            }
          }
        } else if (
          this.validateDocumentAssetType(dynamicAssetTypes.RETAIL.baseClass) ||
          this.validateDocumentAssetType(dynamicAssetTypes.OFFICE.baseClass)
        ) {
          const rentStepStartIndex = findIndex(rentRollCols, [
            'type',
            'RENT_STEP_DATE'
          ]);
          if (rentStepStartIndex !== -1) {
            const endColIndex = rentRollCols.length - rentStepStartIndex;
            sheet.columnOutlines.group(rentStepStartIndex, endColIndex);
            const sheetOutlines = this.getColumnOutlineLevels(
              sheet,
              rentRollCols.length
            );
            if (sheetOutlines.length > 0) {
              sheet.columnOutlines.expandGroup(sheetOutlines[0], false);
              sheet.invalidateLayout();
            }
          }
        }
      }
    }
    this.spreadsheet.resume();
  }

  /*************************************************************************************************/
  // Multifamily Affix Configuration
  /*************************************************************************************************/

  setInitialChargeCode() {
    const { assetType, store, dynamicAssetTypes } = this.props;
    const chargeCodes = chargeCodeConfigSelector(store.getState());
    const rowCount = this.getTargetSheetRowCount();
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    const isRetail = assetType === dynamicAssetTypes.RETAIL.key;
    const retailUniqeChargeCode = [];
    this.spreadsheet.suspend();
    if (Reflect.ownKeys(chargeCodes).length > 0) {
      Reflect.ownKeys(chargeCodes).forEach((colName) => {
        if (isRetail && isBracketExists(colName)) {
          delete chargeCodes[colName];
          return;
        }
        if (isRetail) {
          const chargeCode = chargeCodes[colName];
          let cellValue = '';
          if (chargeCode) {
            cellValue = chargeCode.label
              ? chargeCode.label
              : chargeCode.code
              ? chargeCode.code
              : '';
            if (!chargeCode.label) {
              chargeCode.label = chargeCode.code
                ? !retailUniqeChargeCode.includes(cellValue)
                  ? chargeCode.code
                  : ''
                : '';
            }
          }

          if (
            !!cellValue &&
            (!retailUniqeChargeCode.includes(cellValue) ||
              checkArrayIncludesValue(ALLOWED_RETAIL_CC_CATEGORY, cellValue))
          ) {
            retailUniqeChargeCode.push(cellValue);
          } else {
            chargeCode.label ? (chargeCode.label = '') : (chargeCode.code = '');
            chargeCode.unit = '';
          }
        }
        const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          colName
        );
        if (foundColumnIndex !== -1) {
          const total =
            this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
              sheet,
              `=SUM(${this.datasheet.columnAlphabetIndex(
                foundColumnIndex
              )}2:${this.datasheet.columnAlphabetIndex(
                foundColumnIndex
              )}${rowCount})`
            );
          if (isNil(chargeCodes[colName].category)) {
            const ccConfig = isRetail ? chargeCodes[colName] : {};
            const ccCategory = isRetail
              ? chargeCodes[colName]
              : chargeCodes[colName].code;
            chargeCodes[colName] = {
              total,
              colIndex: foundColumnIndex,
              category: ccCategory,
              key: colName,
              ...ccConfig
            };
          } else {
            chargeCodes[colName] = {
              ...chargeCodes[colName],
              colIndex: foundColumnIndex,
              total,
              key: colName,
              ...chargeCodes[colName]
            };
          }
          chargeCodes[colName]['total'] = total;
        }
      });
    }
    this.initailChargeCodes = { ...this.initailChargeCodes, ...chargeCodes };

    store.dispatch(
      setChargeCodeConfig({
        ...chargeCodes
      })
    );
    this.spreadsheet.resume();
  }

  retrieveChargeCodes() {
    const { store } = this.props;
    const chargeCodes = chargeCodeConfigSelector(store.getState());
    const rowCount = this.getTargetSheetRowCount();
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    this.spreadsheet.suspend();
    if (Reflect.ownKeys(chargeCodes).length > 0) {
      Reflect.ownKeys(chargeCodes).forEach((colName) => {
        const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          colName
        );
        if (foundColumnIndex !== -1) {
          const total =
            this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
              sheet,
              `=SUM(${this.datasheet.columnAlphabetIndex(
                foundColumnIndex
              )}2:${this.datasheet.columnAlphabetIndex(
                foundColumnIndex
              )}${rowCount})`
            );
          chargeCodes[colName] = {
            ...chargeCodes[colName],
            total,
            colIndex: foundColumnIndex,
            category: chargeCodes[colName].category,
            key: colName
          };
        }
      });
    }
    store.dispatch(
      setChargeCodeConfig({
        ...chargeCodes
      })
    );
    this.spreadsheet.resume();
  }

  getRentRollUniqueColumnValues(columnName) {
    const { dynamicAssetTypes } = this.props;
    if (this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      this.spreadsheet.suspend();
      const columnValues = [];
      const rowCount = this.spreadsheet
        .getSheet(targetSheetIndex)
        .getRowCount();
      const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
        1,
        0,
        columnName
      );
      for (let i = 1; i < rowCount; i++) {
        const colValue = this.spreadsheet
          .getSheet(targetSheetIndex)
          .getValue(i, foundColumnIndex);
        !isNil(colValue) && columnValues.push(colValue);
      }
      const result = columnValues.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {});
      this.spreadsheet.resume();
      return result;
    }
  }

  getColumnAlphabetIndex(columnIndex) {
    return this.spreadsheet.toAlphabetColumnName(columnIndex + 1);
  }

  getAlphabetTargetRange(columnName, sheetIndex) {
    const rowCount = this.spreadsheet.workbook
      .getSheet(sheetIndex)
      .getRowCount();
    const columnIndex = this.datasheet.customSearchColumnWithValue(
      1,
      0,
      columnName
    ).foundColumnIndex;
    const targetRange = `${this.getColumnAlphabetIndex(
      columnIndex
    )}1:${this.getColumnAlphabetIndex(columnIndex)}${rowCount}`;
    return { rowCount, targetRange, columnIndex };
  }

  getUniqueFloorPlan(eventType = undefined) {
    const { dynamicAssetTypes } = this.props;
    if (this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      const { store } = this.props;
      this.spreadsheet.suspend();
      const mappedFloorPlan = currentDocumentFloorPlanSelector(
        store.getState()
      );
      const unitStatusConfig = currentDocumentUnitStatusSelector(
        store.getState()
      );
      const isAcquisition =
        currentTemplateTagSelector(store.getState()) ===
        TEMPLATE_TAGS.ACQUISITION.key;
      const unitStatus = Reflect.ownKeys(unitStatusConfig) || [];
      const occupiedUnits = unitStatus.length
        ? unitStatus.filter(
            (unit) => unitStatusConfig[unit].category === 'Occupied'
          )
        : [];
      let result = {};
      let finalFloorPlan = {};
      let validColumnName = '';
      let marketRentTotal = 0;
      let marketRentMinValue = 0;
      let marketRentMaxValue = 0;
      let monthlyRentMinValue = 0;
      let monthlyRentMaxValue = 0;
      let monthlyRentTotal = 0;
      let squareFtMinValue = 0;
      let squareFtMaxValue = 0;
      let squareFtTotal = 0;
      let occupancyStatus = {};
      let columnValues = [];

      for (let col = 0; col < RENT_ROLL_MF_COLUMN_NAME.length; col++) {
        const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          RENT_ROLL_MF_COLUMN_NAME[col]
        );
        if (foundColumnIndex !== -1 && !columnValues.length) {
          const columnData = this.getRentRollUniqueColumnValues(
            RENT_ROLL_MF_COLUMN_NAME[col]
          );
          if (Reflect.ownKeys(columnData).length) {
            const isInvalidCellValues = Reflect.ownKeys(columnData).every(
              (col) => excludedRentRollMFColValue.includes(col)
            );
            // checking if columnData has not contained ignored values
            if (!isInvalidCellValues) {
              validColumnName = RENT_ROLL_MF_COLUMN_NAME[col];
              columnValues.push(columnData);
              break;
            }
          }
        }
      }

      let columnIndex = 0;
      for (let cellValue of columnValues) {
        columnIndex++;
        if (Reflect.ownKeys(cellValue).length > 0) {
          const isColumnContainsInValidValue = arrayContainsArray(
            excludedRentRollMFColValue,
            Reflect.ownKeys(cellValue)
          );
          if (!isColumnContainsInValidValue) {
            result = { ...result, ...cellValue };
          }
        }
      }

      if (Reflect.ownKeys(result).length > 0) {
        Reflect.ownKeys(result).forEach((item) => {
          excludedRentRollMFColValue.includes(item) && delete result[item];
        });
        const { rowCount } = this.getAlphabetTargetRange(
          validColumnName,
          targetSheetIndex
        );
        const floorPlanColumnIndex = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          validColumnName
        ).foundColumnIndex;
        const marketRentAmoutColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Market Rent'
          ).foundColumnIndex;
        const monthlyRentAmoutColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Monthly Rent'
          ).foundColumnIndex;
        const squareFtColumnIndex = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          'Sq Ft'
        ).foundColumnIndex;
        const occupancyStatusColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Status'
          ).foundColumnIndex;
        const affordableColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Affordable'
          ).foundColumnIndex;
        const renovationColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Renovation'
          ).foundColumnIndex;
        const subsidyColumnIndex = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          'Subsidy'
        ).foundColumnIndex;
        const renovationBumpColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Renovation Bump'
          ).foundColumnIndex;
        const renovationCostColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Renovation Cost'
          ).foundColumnIndex;
        const { YES } = affordableRnotationOptions;

        if (isAcquisition && !eventType) {
          // Ignoring this block to execute on manual cell change
          this.calculateAffordableRenovationColumns(
            affordableColumnIndex,
            renovationColumnIndex,
            rowCount
          );
        }

        finalFloorPlan = Reflect.ownKeys(result).reduce((acc, item) => {
          const bed = !isNil(mappedFloorPlan[item])
            ? mappedFloorPlan[item].BD
            : '';
          const bath = !isNil(mappedFloorPlan[item])
            ? mappedFloorPlan[item].BA
            : '';
          const renovationConfig =
            !isNil(mappedFloorPlan[item]) &&
            mappedFloorPlan[item].renovationConfig
              ? mappedFloorPlan[item].renovationConfig
              : {
                  renovated: 0,
                  avgRenovationCost: 0,
                  avgRenovationBump: 0,
                  renovationBump: 0,
                  renovationCost: 0
                };
          const affordableConfig =
            !isNil(mappedFloorPlan[item]) &&
            mappedFloorPlan[item].affordableConfig
              ? mappedFloorPlan[item].affordableConfig
              : { affordable: 0, avgSubsidy: 0 };

          if (marketRentAmoutColumnIndex !== -1) {
            const marketRentColumnValues =
              this.spreadsheet.getMatchedCellValues(
                targetSheetIndex,
                rowCount,
                floorPlanColumnIndex,
                marketRentAmoutColumnIndex,
                item,
                false
              );
            marketRentMinValue = min(compact(marketRentColumnValues)) || 0;
            marketRentMaxValue = max(compact(marketRentColumnValues)) || 0;
            marketRentTotal = sum(compact(marketRentColumnValues)) || 0;
          }
          if (monthlyRentAmoutColumnIndex !== -1) {
            const monthlyRentValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              monthlyRentAmoutColumnIndex,
              item,
              false
            );
            monthlyRentMinValue = min(compact(monthlyRentValues)) || 0;
            monthlyRentMaxValue = max(compact(monthlyRentValues)) || 0;
            monthlyRentTotal = sum(compact(monthlyRentValues)) || 0;
          }
          if (squareFtColumnIndex !== -1 && floorPlanColumnIndex !== -1) {
            const filteredSqFt = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              squareFtColumnIndex,
              item,
              false
            );
            squareFtMinValue = min(compact(filteredSqFt)) || 0;
            squareFtMaxValue = max(compact(filteredSqFt)) || 0;
            squareFtTotal = sum(compact(filteredSqFt)) || 0;
          }
          if (occupancyStatusColumnIndex > 0) {
            const sheetOccupancy = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              occupancyStatusColumnIndex,
              item,
              false
            );
            const occupiedUnitCount = sheetOccupancy.length
              ? sheetOccupancy.filter((unit) => occupiedUnits.includes(unit))
                  .length
              : 0;
            occupancyStatus = { occupiedUnitCount };
          }

          if (affordableColumnIndex > 0 && eventType !== 'onDataBind') {
            const affordableValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              affordableColumnIndex,
              item,
              false
            );
            const subsidyColumnValue = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              subsidyColumnIndex,
              item,
              false
            );
            const isAffordableExists = !!affordableValues.length;
            const affordableCount = isAffordableExists
              ? affordableValues.filter(
                  (affordableValue) => affordableValue === YES
                ).length
              : 0;
            const totalSubsidy = sum(compact(subsidyColumnValue)) || 0;
            affordableConfig.affordable = affordableCount;
            const avgCalculation = totalSubsidy / affordableCount;
            affordableConfig.avgSubsidy = isValidNumber(avgCalculation)
              ? +avgCalculation.toFixed(2)
              : 0;
          }

          if (renovationColumnIndex > 0 && eventType !== 'onDataBind') {
            const renovatedValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              renovationColumnIndex,
              item,
              false
            );
            const renovatedCount = renovatedValues.length
              ? renovatedValues.filter(
                  (renovatedValue) => renovatedValue === YES
                ).length
              : 0;
            renovationConfig.renovated = renovatedCount;
          }

          if (renovationBumpColumnIndex > 0 && eventType !== 'onDataBind') {
            const renovatedBumpValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              renovationBumpColumnIndex,
              item,
              false
            );
            const renovatedBumpTotal = sum(compact(renovatedBumpValues)) || 0;
            const avgCalculation =
              renovatedBumpTotal / renovationConfig.renovated;
            renovationConfig.renovationBump = renovatedBumpTotal;
            renovationConfig.avgRenovationBump = isValidNumber(avgCalculation)
              ? +avgCalculation.toFixed(2)
              : 0;
          }

          if (renovationCostColumnIndex > 0 && eventType !== 'onDataBind') {
            const renovatedCostValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              renovationCostColumnIndex,
              item,
              false
            );
            const renovatedCostTotal = sum(compact(renovatedCostValues)) || 0;
            renovationConfig.renovationCost = renovatedCostTotal;
            const avgCalculation =
              renovatedCostTotal / renovationConfig.renovated;
            renovationConfig.avgRenovationCost = isValidNumber(avgCalculation)
              ? +avgCalculation.toFixed(2)
              : 0;
          }

          acc[item] = {
            BD: Number(bed),
            BA: Number(bath),
            count: result[item],
            marketRentTotal,
            marketRentMinValue,
            marketRentMaxValue,
            monthlyRentMinValue,
            monthlyRentMaxValue,
            monthlyRentTotal,
            squareFtMinValue,
            squareFtMaxValue,
            squareFtTotal,
            occupancyStatus,
            renovationConfig,
            affordableConfig
          };
          return acc;
        }, {});
      }

      finalFloorPlan = Object.keys(finalFloorPlan)
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: finalFloorPlan[key]
          }),
          {}
        );

      store.dispatch(
        setFloorPlan({
          ...finalFloorPlan
        })
      );
      this.spreadsheet.resume();
    }
  }

  calculateLeaseTypeColumns(leaseTypeColumnIndex, rowCount) {
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    const subsidyColumnIndex = this.datasheet.customSearchColumnWithValue(
      1,
      0,
      'Subsidy'
    ).foundColumnIndex;
    for (let row = 1; row < rowCount; row++) {
      if (leaseTypeColumnIndex > 0 && subsidyColumnIndex > 0) {
        const subsidyColumnValue = sheet
          .getCell(row, subsidyColumnIndex)
          .value();
        if (!isNil(subsidyColumnValue)) {
          const cellValue = !!subsidyColumnValue ? 'Affordable' : 'Market Rent';
          sheet.getCell(row, leaseTypeColumnIndex).value(cellValue);
        }
      }
    }
    this.spreadsheet.resume();
  }

  calculateAffordableRenovationColumns(
    affordableColumnIndex,
    renovationColumnIndex,
    rowCount
  ) {
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    const subsidyColumnIndex = this.datasheet.customSearchColumnWithValue(
      1,
      0,
      'Subsidy'
    ).foundColumnIndex;
    const rentPremiumColumnIndex = this.datasheet.customSearchColumnWithValue(
      1,
      0,
      'Rent Premium'
    ).foundColumnIndex;
    const { YES, NO } = affordableRnotationOptions;

    for (let row = 1; row < rowCount; row++) {
      if (affordableColumnIndex > 0 && subsidyColumnIndex > 0) {
        const subsidyColumnValue = sheet
          .getCell(row, subsidyColumnIndex)
          .value();
        if (!isNil(subsidyColumnValue)) {
          const cellValue = !!subsidyColumnValue ? YES : NO;
          sheet.getCell(row, affordableColumnIndex).value(cellValue);
        }
      }
      if (renovationColumnIndex > 0 && rentPremiumColumnIndex > 0) {
        const rentPremiumnColumnValue = sheet
          .getCell(row, rentPremiumColumnIndex)
          .value();
        if (!isNil(rentPremiumnColumnValue)) {
          const cellValue = !!rentPremiumnColumnValue ? YES : NO;
          sheet.getCell(row, renovationColumnIndex).value(cellValue);
        }
      }
    }
    this.spreadsheet.resume();
  }

  calculateUnitMixSummary(eventType = undefined) {
    const { dynamicAssetTypes } = this.props;
    if (this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      this.spreadsheet.suspend();
      const { store } = this.props;
      const sheet = this.spreadsheet.getSheet(targetSheetIndex);
      const mappedUnitMixSummary = unitMixSummaryConfigSelector(
        store.getState()
      );
      const unitStatusConfig = currentDocumentUnitStatusSelector(
        store.getState()
      );
      const isAcquisition =
        currentTemplateTagSelector(store.getState()) ===
        TEMPLATE_TAGS.ACQUISITION.key;
      const unitStatus = Reflect.ownKeys(unitStatusConfig) || [];
      const occupiedUnits = !!unitStatus.length
        ? unitStatus.filter(
            (unit) => unitStatusConfig[unit].category === 'Occupied'
          )
        : [];
      const vacantUnits = !!unitStatus.length
        ? unitStatus.filter(
            (unit) => unitStatusConfig[unit].category === 'Vacant'
          )
        : [];

      let marketRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
      let monthlyRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
      let sqFtConfig = { total: 0, min: 0, max: 0 };
      let occupancyConfig = { occupiedUnitCount: 0, vacantUnitCount: 0 };
      let nonRevenueUnits = 0;
      let affordableConfig = { affordable: 0, avgSubsidy: 0 };
      let renovationConfig = {
        renovated: 0,
        avgRenovationCost: 0,
        avgRenovationBump: 0,
        renovationBump: 0,
        renovationCost: 0
      };
      let unitMix = {};
      let unitMixConfig = {
        marketRentConfig,
        monthlyRentConfig,
        sqFtConfig,
        occupancyConfig,
        nonRevenueUnits,
        affordableConfig,
        renovationConfig
      };
      let unitMixSummary = {};
      let validColumnName = '';
      let columnValues = [];

      for (let col = 0; col < RENT_ROLL_MF_COLUMN_NAME.length; col++) {
        const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          RENT_ROLL_MF_COLUMN_NAME[col]
        );
        if (foundColumnIndex !== -1 && !columnValues.length) {
          const columnData = this.getRentRollUniqueColumnValues(
            RENT_ROLL_MF_COLUMN_NAME[col]
          );
          if (Reflect.ownKeys(columnData).length) {
            const isInvalidCellValues = Reflect.ownKeys(columnData).every(
              (col) => excludedRentRollMFColValue.includes(col)
            );
            // checking if columnData has not contained ignored values
            if (!isInvalidCellValues) {
              validColumnName = RENT_ROLL_MF_COLUMN_NAME[col];
              columnValues.push(columnData);
              break;
            }
          }
        }
      }

      let columnIndex = 0;
      for (let cellValue of columnValues) {
        columnIndex++;
        if (Reflect.ownKeys(cellValue).length > 0) {
          const isColumnContainsInValidValue = arrayContainsArray(
            excludedRentRollMFColValue,
            Reflect.ownKeys(cellValue)
          );
          if (!isColumnContainsInValidValue) {
            unitMix = { ...unitMix, ...cellValue };
          }
        }
      }

      if (Reflect.ownKeys(unitMix).length > 0) {
        Reflect.ownKeys(unitMix).forEach((fpName) => {
          excludedRentRollMFColValue.includes(fpName) && delete unitMix[fpName];
        });

        const { rowCount } = this.getAlphabetTargetRange(
          validColumnName,
          targetSheetIndex
        );
        const floorPlanColumnIndex = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          validColumnName
        ).foundColumnIndex;
        const marketRentAmoutColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Market Rent'
          ).foundColumnIndex;
        const monthlyRentAmoutColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Monthly Rent'
          ).foundColumnIndex;
        const squareFtColumnIndex = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          'Sq Ft'
        ).foundColumnIndex;
        const occupancyStatusColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Status'
          ).foundColumnIndex;
        const affordableColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Affordable'
          ).foundColumnIndex;
        const renovationColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Renovation'
          ).foundColumnIndex;
        const subsidyColumnIndex = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          'Subsidy'
        ).foundColumnIndex;
        const renovationBumpColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Renovation Bump'
          ).foundColumnIndex;
        const renovationCostColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Renovation Cost'
          ).foundColumnIndex;
        const leaseTypeColumnIndex = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          'Lease Type'
        ).foundColumnIndex;

        if (isAcquisition && !eventType) {
          // Ignoring this block to execute on manual cell change
          this.calculateAffordableRenovationColumns(
            affordableColumnIndex,
            renovationColumnIndex,
            rowCount
          );
          this.calculateLeaseTypeColumns(leaseTypeColumnIndex, rowCount);
        }

        unitMixSummary = Reflect.ownKeys(unitMix).reduce((acc, fpName) => {
          const bed = !isNil(mappedUnitMixSummary[fpName])
            ? mappedUnitMixSummary[fpName].BD
            : '';
          const bath = !isNil(mappedUnitMixSummary[fpName])
            ? mappedUnitMixSummary[fpName].BA
            : '';
          let children = !isNil(mappedUnitMixSummary[fpName])
            ? mappedUnitMixSummary[fpName].children
            : [];
          const isModfied =
            (!isNil(mappedUnitMixSummary[fpName]) &&
              mappedUnitMixSummary[fpName].isModfied) ||
            false;
          let leaseTypeColValue = !isNil(mappedUnitMixSummary[fpName])
            ? mappedUnitMixSummary[fpName].leaseType
            : '';
          nonRevenueUnits = !isNil(mappedUnitMixSummary[fpName])
            ? mappedUnitMixSummary[fpName].nonRevenueUnits
            : 0;
          renovationConfig =
            !isNil(mappedUnitMixSummary[fpName]) &&
            mappedUnitMixSummary[fpName].renovationConfig
              ? mappedUnitMixSummary[fpName].renovationConfig
              : renovationConfig;
          affordableConfig =
            !isNil(mappedUnitMixSummary[fpName]) &&
            mappedUnitMixSummary[fpName].affordableConfig
              ? mappedUnitMixSummary[fpName].affordableConfig
              : affordableConfig;

          if (!isModfied) {
            const marketRentColumnValues =
              this.spreadsheet.getMatchedCellValues(
                targetSheetIndex,
                rowCount,
                floorPlanColumnIndex,
                marketRentAmoutColumnIndex,
                fpName,
                false
              );
            const monthlyRentValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              monthlyRentAmoutColumnIndex,
              fpName,
              false
            );
            const sqFtValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              squareFtColumnIndex,
              fpName,
              false
            );
            const occupancyStatusValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              occupancyStatusColumnIndex,
              fpName,
              false
            );
            const affordableValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              affordableColumnIndex,
              fpName,
              false
            );
            const subsidyColumnValue = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              subsidyColumnIndex,
              fpName,
              false
            );
            const renovatedValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              renovationColumnIndex,
              fpName,
              false
            );
            const renovatedBumpValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              renovationBumpColumnIndex,
              fpName,
              false
            );
            const renovatedCostValues = this.spreadsheet.getMatchedCellValues(
              targetSheetIndex,
              rowCount,
              floorPlanColumnIndex,
              renovationCostColumnIndex,
              fpName,
              false
            );

            unitMixConfig = this.calculateUnitMixData(
              {
                marketRentColumnValues,
                monthlyRentValues,
                sqFtValues,
                occupancyStatusValues,
                occupiedUnits,
                vacantUnits,
                affordableValues,
                subsidyColumnValue,
                renovatedValues,
                renovatedBumpValues,
                renovatedCostValues
              },
              unitMix,
              fpName
            );

            if (leaseTypeColumnIndex > 0 && eventType !== 'onDataBind') {
              const leaseTypeValues = this.spreadsheet.getMatchedCellValues(
                targetSheetIndex,
                rowCount,
                floorPlanColumnIndex,
                leaseTypeColumnIndex,
                fpName,
                false
              );
              const childByLeaseType = {};
              const uniqueLeaseTypes = uniq(leaseTypeValues);
              uniqueLeaseTypes.forEach((leaseType, rowIndex) => {
                if (rowIndex === 0) {
                  // considering first row as parent
                  leaseTypeColValue = leaseType;
                }
                if (uniqueLeaseTypes.length > 1) {
                  // Check if child contains lease type value
                  const leaseTypes = this.spreadsheet.getMatchedCellValues(
                    targetSheetIndex,
                    rowCount,
                    floorPlanColumnIndex,
                    leaseTypeColumnIndex,
                    fpName,
                    false
                  );
                  const unitCount =
                    !!leaseType.length &&
                    getFilteredArrayCount(leaseTypes, leaseType);
                  const childConfig = this.calculateChildUnitMixData(
                    sheet,
                    rowCount,
                    floorPlanColumnIndex,
                    {
                      marketRentAmoutColumnIndex,
                      monthlyRentAmoutColumnIndex,
                      squareFtColumnIndex,
                      occupancyStatusColumnIndex,
                      affordableColumnIndex,
                      subsidyColumnIndex,
                      renovationColumnIndex,
                      renovationBumpColumnIndex,
                      renovationCostColumnIndex
                    },
                    leaseTypeColumnIndex,
                    fpName,
                    leaseType
                  );

                  const unitMixDataConfig = this.calculateUnitMixData(
                    {
                      marketRentColumnValues: map(childConfig, 'marketRent'),
                      monthlyRentValues: map(childConfig, 'monthlyRent'),
                      sqFtValues: map(childConfig, 'sqFt'),
                      occupancyStatusValues: map(
                        childConfig,
                        'occupancyStatus'
                      ),
                      occupiedUnits,
                      vacantUnits,
                      affordableValues: map(childConfig, 'affordable'),
                      subsidyColumnValue: map(childConfig, 'subsidy'),
                      renovatedValues: map(childConfig, 'renovation'),
                      renovatedBumpValues: map(childConfig, 'renovationBump'),
                      renovatedCostValues: map(childConfig, 'renovationCost')
                    },
                    unitMix,
                    fpName
                  );

                  if (leaseType !== leaseTypeList.AFFORDABLE) {
                    unitMixDataConfig.affordableConfig = {
                      affordable: 0,
                      avgSubsidy: 0
                    };
                  }

                  childByLeaseType[leaseType] = {
                    floorPlan: fpName,
                    BD: Number(bed),
                    BA: Number(bath),
                    unitCounts: unitCount,
                    leaseType,
                    ...unitMixDataConfig
                  };
                }
              });
              children = Object.values(childByLeaseType);
            }
          }

          if (leaseTypeColValue !== leaseTypeList.AFFORDABLE) {
            unitMixConfig.affordableConfig = { affordable: 0, avgSubsidy: 0 };
          }

          acc[fpName] = {
            BD: Number(bed),
            BA: Number(bath),
            unitCounts: unitMix[fpName],
            leaseType: leaseTypeColValue,
            isModfied,
            children,
            ...unitMixConfig
          };
          return acc;
        }, {});
      }

      unitMixSummary = Object.keys(unitMixSummary)
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: unitMixSummary[key]
          }),
          {}
        );

      store.dispatch(
        setUnitMixSummary({
          ...unitMixSummary
        })
      );
      this.spreadsheet.resume();
    }
  }

  calculateUnitMixData(columnValues, unitMix, fpName) {
    this.spreadsheet.suspend();
    let marketRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
    let monthlyRentConfig = { total: 0, min: 0, max: 0, avg: 0 };
    let sqFtConfig = { total: 0, min: 0, max: 0, avg: 0 };
    let occupancyConfig = { occupiedUnitCount: 0, vacantUnitCount: 0 };
    let nonRevenueUnits = 0;
    let affordableConfig = { affordable: 0, avgSubsidy: 0 };
    let renovationConfig = {
      renovated: 0,
      avgRenovationCost: 0,
      avgRenovationBump: 0,
      renovationBump: 0,
      renovationCost: 0
    };

    const {
      marketRentColumnValues,
      monthlyRentValues,
      sqFtValues,
      occupancyStatusValues,
      occupiedUnits,
      vacantUnits,
      affordableValues,
      subsidyColumnValue,
      renovatedValues,
      renovatedBumpValues,
      renovatedCostValues
    } = columnValues;

    // Market Rent Calculation
    marketRentConfig.min = min(compact(marketRentColumnValues)) || 0;
    marketRentConfig.max = max(compact(marketRentColumnValues)) || 0;
    marketRentConfig.total = sum(compact(marketRentColumnValues)) || 0;
    marketRentConfig.avg =
      getAverage(marketRentConfig.total, unitMix[fpName]) || 0;

    // Monthly Rent Calculation
    monthlyRentConfig.min = min(compact(monthlyRentValues)) || 0;
    monthlyRentConfig.max = max(compact(monthlyRentValues)) || 0;
    monthlyRentConfig.total = sum(compact(monthlyRentValues)) || 0;
    monthlyRentConfig.avg =
      getAverage(monthlyRentConfig.total, unitMix[fpName]) || 0;

    // Sq. Ft Calculation
    sqFtConfig.min = min(compact(sqFtValues)) || 0;
    sqFtConfig.max = max(compact(sqFtValues)) || 0;
    sqFtConfig.total = sum(compact(sqFtValues)) || 0;
    sqFtConfig.avg = getAverage(sqFtConfig.total, unitMix[fpName]) || 0;

    // Occupancy Status calculation
    occupancyConfig.occupiedUnitCount = filter(occupancyStatusValues, (unit) =>
      occupiedUnits.includes(unit)
    ).length;
    occupancyConfig.vacantUnitCount = filter(occupancyStatusValues, (unit) =>
      vacantUnits.includes(unit)
    ).length;
    nonRevenueUnits = filter(occupancyStatusValues, (nonRevenue) =>
      unitMixNonRevenueUnits.includes(nonRevenue)
    ).length;

    // Affordable Calculation
    affordableConfig.affordable =
      (!!affordableValues &&
        affordableValues.filter(
          (affordableValue) =>
            affordableValue === affordableRnotationOptions.YES
        ).length) ||
      0;
    const totalSubsidy = sum(compact(subsidyColumnValue)) || 0;
    const avgCalculation = totalSubsidy / affordableConfig.affordable;
    affordableConfig.avgSubsidy = isValidNumber(avgCalculation)
      ? +avgCalculation.toFixed(2)
      : 0;

    // Renocation Calculation
    renovationConfig.renovated =
      !!renovatedValues &&
      renovatedValues.filter(
        (renovatedValue) => renovatedValue === affordableRnotationOptions.YES
      ).length;
    renovationConfig.renovationBump = sum(compact(renovatedBumpValues)) || 0;
    const avgRenoCalculation =
      renovationConfig.renovationBump / renovationConfig.renovated;
    renovationConfig.avgRenovationBump = isValidNumber(avgRenoCalculation)
      ? +avgRenoCalculation.toFixed(2)
      : 0;
    renovationConfig.renovationCost = sum(compact(renovatedCostValues)) || 0;
    const avgRenoCostCalculation =
      renovationConfig.renovationCost / renovationConfig.renovated;
    renovationConfig.avgRenovationCost = isValidNumber(avgRenoCostCalculation)
      ? +avgRenoCostCalculation.toFixed(2)
      : 0;

    this.spreadsheet.resume();
    return {
      marketRentConfig,
      monthlyRentConfig,
      sqFtConfig,
      occupancyConfig,
      nonRevenueUnits,
      affordableConfig,
      renovationConfig
    };
  }

  calculateChildUnitMixData(
    sheet,
    rowCount,
    sourceColIndex,
    targetColumns,
    leaseTypeColumnIndex,
    fpName,
    leaseType
  ) {
    this.spreadsheet.suspend();
    const filteredValue = [];
    const {
      marketRentAmoutColumnIndex,
      monthlyRentAmoutColumnIndex,
      squareFtColumnIndex,
      occupancyStatusColumnIndex,
      affordableColumnIndex,
      subsidyColumnIndex,
      renovationColumnIndex,
      renovationBumpColumnIndex,
      renovationCostColumnIndex
    } = targetColumns;

    for (let row = 1; row < rowCount; row++) {
      const cellValue = sheet.getCell(row, sourceColIndex).value();
      if (!isNil(cellValue) && cellValue === fpName) {
        const marketRent = sheet
          .getCell(row, marketRentAmoutColumnIndex)
          .value();
        const monthlyRent = sheet
          .getCell(row, monthlyRentAmoutColumnIndex)
          .value();
        const sqFt = sheet.getCell(row, squareFtColumnIndex).value();
        const occupancyStatus = sheet
          .getCell(row, occupancyStatusColumnIndex)
          .value();
        const affordable = sheet.getCell(row, affordableColumnIndex).value();
        const subsidy = sheet.getCell(row, subsidyColumnIndex).value();
        const renovation = sheet.getCell(row, renovationColumnIndex).value();
        const renovationBump = sheet
          .getCell(row, renovationBumpColumnIndex)
          .value();
        const renovationCost = sheet
          .getCell(row, renovationCostColumnIndex)
          .value();
        const leaseTypeColumnValue = sheet
          .getCell(row, leaseTypeColumnIndex)
          .value();
        if (
          !isNil(leaseTypeColumnValue) &&
          leaseTypeColumnValue === leaseType
        ) {
          filteredValue.push({
            marketRent,
            monthlyRent,
            sqFt,
            occupancyStatus,
            affordable,
            subsidy,
            renovation,
            renovationBump,
            renovationCost,
            leaseType,
            fpName
          });
        }
      }
    }
    this.spreadsheet.resume();
    return filteredValue;
  }

  convertTenantNameToStatus(columnName) {
    const { dynamicAssetTypes } = this.props;
    if (this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      const { store } = this.props;
      this.spreadsheet.suspend();
      const columnValues = [];
      let result = {};
      const tenantNameUnitStatusKeys = Reflect.ownKeys(
        tenantNameUnitStatusSelector(store.getState())
      );
      if (tenantNameUnitStatusKeys.length) {
        const rowCount = this.spreadsheet
          .getSheet(targetSheetIndex)
          .getRowCount();
        const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          columnName
        );
        for (let i = 1; i < rowCount; i++) {
          const colValue = this.spreadsheet
            .getSheet(targetSheetIndex)
            .getValue(i, foundColumnIndex);
          if (
            !isNil(colValue) &&
            !isNil(
              tenantNameUnitStatusKeys.find(
                (foundValue) =>
                  colValue.toLowerCase().trim() ===
                  foundValue.toLowerCase().trim()
              )
            )
          ) {
            columnValues.push(colValue);
          } else if (!isNil(colValue)) {
            columnValues.push('Occupied');
          }
        }
        result = columnValues.reduce((acc, val) => {
          acc[val] = (acc[val] || 0) + 1;
          return acc;
        }, {});
      }
      this.spreadsheet.resume();
      return result;
    }
  }

  getUniqueUnitStatus() {
    const { dynamicAssetTypes } = this.props;
    if (this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      this.spreadsheet.suspend();
      const { store } = this.props;
      const unitStatusConfig = currentDocumentUnitStatusSelector(
        store.getState()
      );
      let rowCount;
      let targetRange;
      let isStatusColumnPresent = true;
      if (Reflect.ownKeys(unitStatusConfig).length > 0) {
        let documentUnitStatus = this.getRentRollUniqueColumnValues('Status');
        if (Reflect.ownKeys(documentUnitStatus).length === 0) {
          documentUnitStatus = this.convertTenantNameToStatus('Tenant Name');
          const columnRange = this.getAlphabetTargetRange(
            'Tenant Name',
            targetSheetIndex
          );
          rowCount = columnRange.rowCount;
          targetRange = columnRange.targetRange;
          isStatusColumnPresent = false;
        } else {
          const columnRange = this.datasheet.getAlphabetTargetRange(
            'Status',
            targetSheetIndex
          );
          rowCount = columnRange.rowCount;
          targetRange = columnRange.targetRange;
        }

        const sheet = this.spreadsheet.workbook.getSheet(targetSheetIndex);
        const marketRentAmoutColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Market Rent'
          ).foundColumnIndex;
        const monthlyRentAmoutColumnIndex =
          this.datasheet.customSearchColumnWithValue(
            1,
            0,
            'Monthly Rent'
          ).foundColumnIndex;

        let marketRentTotal = 0;
        let monthlyRentTotal = 0;
        if (
          !!Reflect.ownKeys(documentUnitStatus) &&
          Reflect.ownKeys(documentUnitStatus).length > 0
        ) {
          const unitStatusConfigKeys = Reflect.ownKeys(unitStatusConfig);
          const documentUnitStatusKeys = Reflect.ownKeys(documentUnitStatus);
          if (documentUnitStatusKeys.length) {
            documentUnitStatusKeys.forEach((unitStatus) => {
              const validKeyName = findValidKey(
                unitStatusConfigKeys,
                unitStatus
              );
              if (!isNil(validKeyName)) {
                if (marketRentAmoutColumnIndex !== -1) {
                  const targetSumRange = `${this.datasheet.columnAlphabetIndex(
                    marketRentAmoutColumnIndex
                  )}1:${this.datasheet.columnAlphabetIndex(
                    marketRentAmoutColumnIndex
                  )}${rowCount}`;
                  marketRentTotal =
                    this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
                      sheet,
                      `=ROUND(SUMIF('${TARGET_SHEET_NAME}'!${targetRange},"${validKeyName}",'${TARGET_SHEET_NAME}'!${targetSumRange}),2)`
                    );
                }
                if (monthlyRentAmoutColumnIndex !== -1) {
                  const targetSumRange = `${this.datasheet.columnAlphabetIndex(
                    monthlyRentAmoutColumnIndex
                  )}1:${this.datasheet.columnAlphabetIndex(
                    monthlyRentAmoutColumnIndex
                  )}${rowCount}`;
                  monthlyRentTotal =
                    this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
                      sheet,
                      `=ROUND(SUMIF('${TARGET_SHEET_NAME}'!${targetRange},"${validKeyName}",'${TARGET_SHEET_NAME}'!${targetSumRange}),2)`
                    );
                }
                documentUnitStatus[unitStatus] = {
                  ...unitStatusConfig[validKeyName],
                  marketRentTotal,
                  monthlyRentTotal,
                  count: documentUnitStatus[unitStatus]
                };
              }
            });
          }
        }

        if (!isStatusColumnPresent) {
          if (marketRentAmoutColumnIndex !== -1) {
            const totalMarketRentSumRange = `${this.datasheet.columnAlphabetIndex(
              marketRentAmoutColumnIndex
            )}1:${this.datasheet.columnAlphabetIndex(
              marketRentAmoutColumnIndex
            )}${rowCount}`;
            const totalMarketRent =
              this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
                sheet,
                `=SUM(${totalMarketRentSumRange})`
              );
            const totalOtherMarketRent = Reflect.ownKeys(
              documentUnitStatus
            ).reduce(
              (acc, curr) =>
                (acc = acc + documentUnitStatus[curr].marketRentTotal),
              0
            );
            if (!isNil(documentUnitStatus['Occupied'])) {
              documentUnitStatus['Occupied'].marketRentTotal =
                totalMarketRent - totalOtherMarketRent;
            }
          }
          if (monthlyRentAmoutColumnIndex !== -1) {
            const totalMonthlyRentSumRange = `${this.datasheet.columnAlphabetIndex(
              monthlyRentAmoutColumnIndex
            )}1:${this.datasheet.columnAlphabetIndex(
              monthlyRentAmoutColumnIndex
            )}${rowCount}`;
            const totalMonthlyRent =
              this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
                sheet,
                `=SUM(${totalMonthlyRentSumRange})`
              );
            const totalOtherMonthlyRent = Reflect.ownKeys(
              documentUnitStatus
            ).reduce(
              (acc, curr) =>
                (acc = acc + documentUnitStatus[curr].monthlyRentTotal),
              0
            );
            if (!isNil(documentUnitStatus['Occupied'])) {
              documentUnitStatus['Occupied'].monthlyRentTotal =
                totalMonthlyRent - totalOtherMonthlyRent;
            }
          }
        }
        store.dispatch(
          setUnitStatus({
            ...documentUnitStatus
          })
        );
      }
      this.spreadsheet.resume();
    }
  }

  mergeChargeCodeColumns(chargeCodeColumnRef) {
    const { dynamicAssetTypes } = this.props;
    if (Reflect.ownKeys(chargeCodeColumnRef).length > 0) {
      const { sourceColumnRef, targetcolumnRef } = chargeCodeColumnRef;
      this.spreadsheet.suspend();
      const rowCount = this.getTargetSheetRowCount();
      const sheet = this.spreadsheet.getSheet(targetSheetIndex);
      const sourceColCellIndex = this.datasheet.customSearchColumnWithValue(
        1,
        0,
        sourceColumnRef.key
      ).foundColumnIndex;
      const targetColCellIndex = this.datasheet.customSearchColumnWithValue(
        1,
        0,
        targetcolumnRef.key
      ).foundColumnIndex;
      for (let row = 1; row < rowCount; row++) {
        const sourceColValue = this.spreadsheet.getCellValue(
          targetSheetIndex,
          row,
          sourceColCellIndex
        );
        const targetColValue = this.spreadsheet.getCellValue(
          targetSheetIndex,
          row,
          targetColCellIndex
        );
        const total =
          this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
            sheet,
            `=SUM(${isNil(sourceColValue) ? 0 : sourceColValue}+${
              isNil(targetColValue) ? 0 : targetColValue
            })`
          );
        this.spreadsheet.setCellValue(
          targetSheetIndex,
          row,
          targetColCellIndex,
          total
        );
      }
      sheet.deleteColumns(sourceColCellIndex, 1);
      this.spreadsheet.bindSheetHeader(sheet);
      this.deleteChargeCodeColumnFromStore(sourceColumnRef.key);
      if (
        this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)
      ) {
        this.setChargeCodeAfterColumnMerge();
      } else {
        this.setRetailChargeCode();
      }
    }
    this.spreadsheet.resume();
  }

  deleteChargeCodeColumnFromStore(keyName) {
    const { store } = this.props;
    const chargeCode = chargeCodeConfigSelector(store.getState());
    delete chargeCode[keyName];
    store.dispatch(
      setChargeCodeConfig({
        ...chargeCode
      })
    );
  }

  setChargeCodeAfterColumnMerge(
    isSlidePanelClicked = false,
    eventType = undefined
  ) {
    const { dynamicAssetTypes } = this.props;
    if (this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      const { store } = this.props;
      const userSelectedChargeCode = chargeCodeConfigSelector(store.getState());
      const chargeCodes = invertBy(
        Reflect.ownKeys(userSelectedChargeCode).reduce(
          (chargeCode, chargeCodeKey) => {
            chargeCode[chargeCodeKey] =
              userSelectedChargeCode[chargeCodeKey].category;
            return chargeCode;
          },
          {}
        )
      );
      const rowCount = this.getTargetSheetRowCount();
      const sheet = this.spreadsheet.getSheet(targetSheetIndex);
      const chargeCodeKeys = Reflect.ownKeys(chargeCodes);
      const isAcquisition =
        currentTemplateTagSelector(store.getState()) ===
        TEMPLATE_TAGS.ACQUISITION.key;
      const renovationBumpColumnIndex =
        this.datasheet.customSearchColumnWithValue(
          1,
          0,
          'Renovation Bump'
        ).foundColumnIndex;
      const rentPremiumColumnIndex = this.datasheet.customSearchColumnWithValue(
        1,
        0,
        'Rent Premium'
      ).foundColumnIndex;

      if (chargeCodeKeys.length > 0) {
        this.spreadsheet.suspend();
        this.clearChargeCodeCategory(rowCount, sheet);
        this.clearRenovationBumpColumn(
          sheet,
          rowCount,
          renovationBumpColumnIndex
        );

        for (let targetKey in chargeCodes) {
          const targetFormulaCellIndex =
            this.datasheet.customSearchColumnWithValue(
              targetSheetIndex,
              0,
              targetKey
            ).foundColumnIndex;
          let formulaExpression = '';
          chargeCodes[targetKey].forEach((chargeCode) => {
            const chargeCodeColIndex =
              this.datasheet.customSearchColumnWithValue(
                targetSheetIndex,
                0,
                chargeCode
              ).foundColumnIndex;
            if (chargeCodes[targetKey].length > 1) {
              if (formulaExpression === '') {
                formulaExpression += `${this.datasheet.columnAlphabetIndex(
                  chargeCodeColIndex
                )}&`;
              } else {
                formulaExpression += `+${this.datasheet.columnAlphabetIndex(
                  chargeCodeColIndex
                )}&`;
              }
            } else {
              formulaExpression += `${this.datasheet.columnAlphabetIndex(
                chargeCodeColIndex
              )}&`;
            }
          });
          if (targetFormulaCellIndex !== -1) {
            for (let row = 1; row < rowCount; row++) {
              const finalFormulaExpression = formulaExpression
                .split('&')
                .join(row + 1);
              const cellValue = sheet
                .getCell(row, targetFormulaCellIndex)
                .value();
              if (cellValue === null || !cellValue) {
                sheet.setFormula(
                  row,
                  targetFormulaCellIndex,
                  `=SUM(${finalFormulaExpression})`
                );
                sheet.setFormatter(
                  row,
                  targetFormulaCellIndex,
                  USCurrencyFormat
                );
                if (
                  isAcquisition &&
                  rentPremiumColumnIndex === targetFormulaCellIndex
                ) {
                  const rentPremiumCellValue =
                    this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
                      sheet,
                      `=SUM(${finalFormulaExpression})`
                    );
                  sheet
                    .getCell(row, renovationBumpColumnIndex)
                    .value(rentPremiumCellValue);
                }
              }
            }
          }
        }

        if (!isSlidePanelClicked) {
          this.retrieveChargeCodes();
          this.props.setWorkbookToast({
            type: 'success',
            message: 'Charge code successfully merged.'
          });
        }

        setTimeout(() => {
          this.getUniqueUnitStatus();
          if (!!eventType) {
            // expecting a valid eventType
            this.calculateUnitMixSummary();
            this.getUniqueFloorPlan();
          }
        }, 0);
        this.spreadsheet.resume();
      }
    }
  }

  clearChargeCodeCategory(rowCount, sheet) {
    this.spreadsheet.suspend();
    const { store } = this.props;
    const chargeCodes = staticChargeCodeSelector(store.getState());
    if (chargeCodes.length > 0) {
      for (
        let chargeCodeColumnIndex = 0;
        chargeCodeColumnIndex < chargeCodes.length;
        chargeCodeColumnIndex++
      ) {
        const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          chargeCodes[chargeCodeColumnIndex]
        );
        if (foundColumnIndex !== -1) {
          const range = sheet.getRange(1, foundColumnIndex, rowCount, 1);
          range.formatter(cellNumberFormat);
          for (let row = 1; row < rowCount; row++) {
            const cellValue = sheet.getCell(row, foundColumnIndex).value();
            const isFormulaExist = sheet.getFormula(row, foundColumnIndex);
            if (!isNil(isFormulaExist) && (!!cellValue || cellValue === 0)) {
              sheet.setFormula(row, foundColumnIndex, null);
              sheet.setText(row, foundColumnIndex, null);
            }
          }
        }
      }
    }
    this.spreadsheet.resume();
  }

  clearRenovationBumpColumn(sheet, rowCount, columnIndex) {
    this.spreadsheet.suspend();
    if (columnIndex !== -1) {
      sheet.clear(
        1,
        columnIndex,
        rowCount,
        1,
        this.spreadsheet.GC.Spread.Sheets.SheetArea.viewport,
        this.spreadsheet.GC.Spread.Sheets.StorageType.data
      );
    }
    this.spreadsheet.resume();
  }

  getLeaseInfoFromDocument(columnNames, sheetIndex, rowCount) {
    this.spreadsheet.suspend();
    const columnValues = [];
    for (let headerCol = 0; headerCol < columnNames.length; headerCol++) {
      const columnIndex = this.datasheet.customSearchColumnWithValue(
        sheetIndex,
        0,
        columnNames[headerCol]
      ).foundColumnIndex;
      if (columnIndex !== -1) {
        for (let row = 1; row < rowCount; row++) {
          const cellValue = this.spreadsheet.getCellValue(
            targetSheetIndex,
            row,
            columnIndex
          );
          if (!isNil(cellValue)) {
            headerCol === 0
              ? columnValues.push(cellValue)
              : !!cellValue && (columnValues[row - 1] = cellValue);
          }
        }
      }
    }
    const dates = columnValues.filter(
      (colValue) => moment(colValue, 'MM/DD/YYYY')._isValid && colValue
    );
    const sortedDate = sortBy(dates, (date) =>
      moment(date, 'MM/DD/YYYY').format('X')
    );
    const groupedDate = groupBy(sortedDate, (date) =>
      moment(date, 'MM/DD/YYYY').format('MMM-YY')
    );
    const monthWiseCount = Reflect.ownKeys(groupedDate).map((month) => ({
      'name': month,
      'Lease Count': groupedDate[month].length
    }));

    const yearWiseCount = groupBy(monthWiseCount, (countData) =>
      moment(countData.name, 'MMM-YY').format('YYYY')
    );
    this.spreadsheet.resume();
    return yearWiseCount;
  }

  setRentRollLeaseTypeData() {
    const { dynamicAssetTypes } = this.props;
    if (this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      const { store } = this.props;
      this.spreadsheet.suspend();
      const rowCount = this.spreadsheet
        .getSheet(targetSheetIndex)
        .getRowCount();
      const leaseStartRange = this.getLeaseInfoFromDocument(
        ['Start Date', 'Move In Date'],
        targetSheetIndex,
        rowCount
      );
      const leaseExpirtyRange = this.getLeaseInfoFromDocument(
        ['End Date', 'Move Out Date'],
        targetSheetIndex,
        rowCount
      );
      store.dispatch(setLeaseConfig(leaseStartRange, leaseExpirtyRange));
      this.spreadsheet.resume();
    }
  }

  handleCustomChargeCodeColumn(columnName, columnIndex) {
    const { dynamicAssetTypes } = this.props;
    this.spreadsheet.suspend();
    const { store } = this.props;
    if (!isNil(columnName)) {
      if (columnName.length > 2 && isNaN(columnName)) {
        const sheet = this.spreadsheet.getSheet(targetSheetIndex);
        const trimmedColumnName = columnName.trim();
        const chargeCodePrefix = trimmedColumnName.substring(0, 2);
        if (chargeCodePrefix.toLowerCase() === 'cc') {
          // check if column has cc prefix
          const config = chargeCodeConfigSelector(store.getState());
          let chargeCodeConfig;

          if (
            this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)
          ) {
            // MultiFamily Charge Code object
            chargeCodeConfig = {
              ...config,
              [trimmedColumnName]: {
                total: 0,
                key: trimmedColumnName,
                category: ''
              }
            };
          } else {
            // Retail Charge Code object
            chargeCodeConfig = {
              ...config,
              [trimmedColumnName]: {
                category: { code: '', conversion: 'N/A' },
                total: 0,
                key: trimmedColumnName,
                trimmedColumnName: 0
              }
            };
          }
          sheet.bindColumn(columnIndex, {
            resizable: true,
            size: 180,
            name: columnName,
            headerName: columnName,
            type: 'CHARGE_CODE'
          });
          this.bindSheetColumnsOnChargeCodeAddition(sheet);

          store.dispatch(setChargeCodeConfig(chargeCodeConfig));
        }
      }
    }
    this.spreadsheet.resume();
  }

  bindSheetColumnsOnChargeCodeAddition(sheet) {
    this.spreadsheet.suspend();
    const workbookData = this.spreadsheet.getWorkbookData();
    const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
    columns.forEach((column, i) => {
      const alphabetName = this.spreadsheet.toAlphabetColumnName(i + 1);
      if (!!column && !!column.name) {
        sheet.bindColumn(i, { displayName: alphabetName, ...column });
      }
    });
    this.spreadsheet.resume();
  }

  /*************************************************************************************************/
  // Retail Type Document Code
  /*************************************************************************************************/

  getRetailLeaseExpirationData() {
    const { dynamicAssetTypes } = this.props;
    if (!this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      const { store } = this.props;
      this.spreadsheet.suspend();
      const columnValues = this.datasheet.fetchValueFromColumns(
        targetSheetIndex,
        ['End Date', 'Sq Ft'],
        ['endDate', 'sqFt']
      );
      const groupedDates = groupBy(columnValues, 'endDate');
      const monthWiseCount = Reflect.ownKeys(groupedDates).map((date) => ({
        name: date,
        total: sumBy(groupedDates[date], 'sqFt')
      }));
      const yearWiseCount = groupBy(
        monthWiseCount,
        (countData) =>
          moment(countData.name, 'MM/DD/YYYY')._isValid &&
          moment(countData.name, 'MM-DD-YYYY').format('YYYY')
      );
      const finalLeaseExpiration = Reflect.ownKeys(yearWiseCount)
        .map((year) => ({
          'name': year,
          'SF Rolling': sumBy(yearWiseCount[year], 'total')
        }))
        .filter((item) => item.name !== 'false' && item);
      store.dispatch(setLeaseConfig(null, finalLeaseExpiration));
      this.spreadsheet.resume();
    }
  }

  getRetailOccupancyStatus() {
    const { dynamicAssetTypes } = this.props;
    if (!this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      const { store } = this.props;
      this.spreadsheet.suspend();
      const projectOccupancy = occupancySelector(store.getState());
      const projectOccupancyMappings = occupancyMappingSelector(
        store.getState()
      );
      const columnValues = this.datasheet
        .fetchValueFromColumns(
          targetSheetIndex,
          ['Status', 'Sq Ft'],
          ['status', 'sqFt']
        )
        .filter((key) => key.status);
      const groupedStatus = groupBy(columnValues, 'status');
      const filterprojectOccupancy = Array.isArray(projectOccupancy)
        ? groupBy(projectOccupancy, 'name')
        : [];
      const finalOccupancyData = Reflect.ownKeys(groupedStatus).map(
        (status) => {
          const category = !isNil(filterprojectOccupancy[status])
            ? filterprojectOccupancy[status][0].category
            : this.getOccupancyCategory(status, projectOccupancyMappings);
          return {
            name: status,
            units: groupedStatus[status].length,
            category,
            value: sumBy(groupedStatus[status], 'sqFt')
          };
        }
      );
      store.dispatch(setRetailOccupancy(finalOccupancyData));
      this.spreadsheet.resume();
    }
  }

  getOccupancyCategory(status, projectOccupancyMappings) {
    return projectOccupancyMappings.includes(status) ? status : '';
  }

  getRetailLeaseType() {
    const { dynamicAssetTypes } = this.props;
    if (!this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      const { store } = this.props;
      this.spreadsheet.suspend();
      const rowCount = this.spreadsheet
        .getSheet(targetSheetIndex)
        .getRowCount();
      const leaseTypeMapping = retailLeaseTypeDropDownSelector(
        store.getState()
      );
      const leaseTypeConfig = retailLeaseTypeConfigSelector(store.getState());
      const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
        targetSheetIndex,
        0,
        'Lease Type'
      );
      if (foundColumnIndex !== -1) {
        const leaseTypeColumnValues = uniq(
          this.spreadsheet
            .getColumnValues(targetSheetIndex, 1, rowCount, foundColumnIndex)
            .filter((leaseType) => leaseType !== null && leaseType)
        );
        const uniqueColumnValue = difference(
          leaseTypeColumnValues,
          leaseTypeMapping
        );
        const finalLeaseType = uniqueColumnValue.length
          ? Object.assign(
              ...uniqueColumnValue.map((item) => ({
                [item]: !isNil(leaseTypeConfig[item])
                  ? leaseTypeConfig[item]
                  : ''
              }))
            )
          : {};
        store.dispatch(setRetailLeaseTypeConfig(finalLeaseType));
      }
      this.spreadsheet.resume();
    }
  }

  getRetailTenantTypeData() {
    const { dynamicAssetTypes } = this.props;
    if (!this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      this.spreadsheet.suspend();
      const { store } = this.props;
      const columnValues = this.datasheet
        .fetchValueFromColumns(
          targetSheetIndex,
          Object.values(tenantTypeColumnCalc),
          Reflect.ownKeys(tenantTypeColumnCalc)
        )
        .filter((tenant) => tenant.TENANT_TYPE);
      const totalSquareFt = sumBy(
        columnValues,
        getKeyByValue(tenantTypeColumnCalc, tenantTypeColumnCalc.SQFT)
      );
      const groupedByTenantName = groupBy(
        columnValues,
        getKeyByValue(tenantTypeColumnCalc, tenantTypeColumnCalc.TENANT_TYPE)
      );
      const finalTenantTypeData = Reflect.ownKeys(groupedByTenantName).map(
        (tenant) => {
          if (!isNil(tenant)) {
            const sqFtTotal = sumBy(groupedByTenantName[tenant], 'SQFT');
            const totalRent = some(groupedByTenantName[tenant], 'BASE_RENT')
              ? getTotalByKey(groupedByTenantName[tenant], 'BASE_RENT')
              : sumBy(groupedByTenantName[tenant], (key) =>
                  key.MONTLY_RENT_CALC ? Number(key.MONTLY_RENT_CALC) : 0
                );

            const MONTLY_CAM = some(groupedByTenantName[tenant], 'CAM_RE')
              ? getTotalByKey(groupedByTenantName[tenant], 'CAM_RE')
              : sumBy(groupedByTenantName[tenant], (key) =>
                  key.MONTLY_CAM_CALC ? Number(key.MONTLY_CAM_CALC) : 0
                );

            const MONTLY_TAX = some(groupedByTenantName[tenant], 'TAX_RE')
              ? getTotalByKey(groupedByTenantName[tenant], 'TAX_RE')
              : sumBy(groupedByTenantName[tenant], (key) =>
                  key.MONTLY_TAX_CALC ? Number(key.MONTLY_TAX_CALC) : 0
                );

            const MONTLY_INC = some(groupedByTenantName[tenant], 'INS_RE')
              ? getTotalByKey(groupedByTenantName[tenant], 'INS_RE')
              : sumBy(groupedByTenantName[tenant], (key) =>
                  key.MONTLY_INC_CALC ? Number(key.MONTLY_INC_CALC) : 0
                );

            const totalSqFtPercent = getPercentage(sqFtTotal, totalSquareFt);
            const totalRecovery = Number(MONTLY_CAM + MONTLY_TAX + MONTLY_INC);
            return {
              name: tenant,
              Recoveries: totalRecovery,
              sqFtTotal,
              Rent: isNaN(totalRent) ? 0 : totalRent,
              totalSqFtPercent
            };
          }
        }
      );

      store.dispatch(setRetailTenantTypeConfig(finalTenantTypeData));

      this.spreadsheet.resume();
    }
  }

  calculateTenantType(calcultedPercent) {
    this.spreadsheet.suspend();
    const columnValues = this.datasheet.fetchValueFromColumns(
      targetSheetIndex,
      [tenantTypeColumnCalc.TENANT_TYPE, tenantTypeColumnCalc.SQFT],
      ['TENANT_TYPE', 'SQFT']
    );
    const totalSquareFt = sumBy(columnValues, 'SQFT');
    const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Tenant Type'
    );
    if (!!columnValues.length && foundColumnIndex !== -1) {
      if (foundColumnIndex) {
        for (let row = 0; row < columnValues.length; row++) {
          const tenantType = this.getTenantType(
            columnValues[row].SQFT,
            totalSquareFt,
            calcultedPercent
          );
          this.spreadsheet.setCellValue(
            targetSheetIndex,
            columnValues[row].TENANT_TYPE_ROW_INDEX,
            foundColumnIndex,
            tenantType
          );
        }
      }
    }
    this.getRetailTenantTypeData();
    this.spreadsheet.resume();
  }

  getTenantType(sqft, totalSquareFt, calcultedPercent) {
    const { assetType, dynamicAssetTypes } = this.props;
    const inlineType =
      assetType === dynamicAssetTypes.OFFICE.key ? 'Minor' : 'Inline';
    const tenantType =
      getPercentage(sqft, totalSquareFt) >= calcultedPercent
        ? 'Major'
        : inlineType;

    return tenantType;
  }

  setRetailChargeCode() {
    const { dynamicAssetTypes } = this.props;
    this.spreadsheet.suspend();
    if (!this.validateDocumentAssetType(dynamicAssetTypes.MULTIFAMILY.baseClass)) {
      const { store } = this.props;
      const userSelectedChargeCode = chargeCodeConfigSelector(store.getState());
      const rowCount = this.getTargetSheetRowCount();
      const sheet = this.spreadsheet.workbook.getSheet(targetSheetIndex);
      const squareFtColumnIndex = this.datasheet.customSearchColumnWithValue(
        targetSheetIndex,
        0,
        'Sq Ft'
      ).foundColumnIndex;
      const chargeCodesColumns =
        staticChargeCodeSelector(store.getState()) || [];
      const staticChargeCodeMappings = staticChargeCodeMappingSelector(
        store.getState()
      );
      const annualCalcChargeCodeColumnMappings = staticChargeCodeMappings
        .map((chargeCode) => chargeCode.mappedCalculatedColumn)
        .filter((cc) => cc);
      const chargeCodeColumnsToClear = [
        ...chargeCodesColumns,
        ...annualCalcChargeCodeColumnMappings
      ].filter((cc) => !!cc);
      this.clearRetailChargeCodeColumns(
        sheet,
        chargeCodeColumnsToClear,
        rowCount
      );
      const otherRecoveries = [];
      for (let targetKey in userSelectedChargeCode) {
        const { label, unit } = userSelectedChargeCode[targetKey];
        const sourceColCellIndex = this.datasheet.customSearchColumnWithValue(
          targetSheetIndex,
          0,
          targetKey
        ).foundColumnIndex;
        const targetColCellIndex = this.datasheet.customSearchColumnWithValue(
          targetSheetIndex,
          0,
          label
        ).foundColumnIndex;
        let mappedCalcColIndex = -1;
        const findMappedColumnName =
          !!staticChargeCodeMappings &&
          staticChargeCodeMappings.find(
            (mappedCol) => mappedCol.columnName === label
          );
        if (!isNil(findMappedColumnName)) {
          mappedCalcColIndex = this.datasheet.customSearchColumnWithValue(
            targetSheetIndex,
            0,
            findMappedColumnName.mappedCalculatedColumn
          ).foundColumnIndex;
        }
        if (userSelectedChargeCode[targetKey] && !!label) {
          if (targetColCellIndex !== -1 && sourceColCellIndex !== -1) {
            for (let row = 1; row < rowCount; row++) {
              const sourceColValue = this.spreadsheet.getCellValue(
                targetSheetIndex,
                row,
                sourceColCellIndex
              );
              const sqFtColumnValue =
                squareFtColumnIndex !== -1
                  ? this.spreadsheet.getCellValue(
                      targetSheetIndex,
                      row,
                      squareFtColumnIndex
                    )
                  : 1;
              let calculatedCellValue =
                this.calculateRetailChargeCodeTotal(
                  sourceColValue,
                  unit,
                  sqFtColumnValue
                ) || 0;
              if (label === ALLOWED_RETAIL_CC_CATEGORY[0]) {
                // Expecting Other Recoveries here
                calculatedCellValue = sum([
                  otherRecoveries[row],
                  calculatedCellValue
                ]);
                if (!isNil(otherRecoveries[row])) {
                  otherRecoveries.push(calculatedCellValue);
                } else {
                  otherRecoveries[row] = calculatedCellValue;
                }
              }

              this.spreadsheet.setCellValue(
                targetSheetIndex,
                row,
                targetColCellIndex,
                calculatedCellValue
              );
              sheet.setFormatter(row, targetColCellIndex, USCurrencyFormat);
              mappedCalcColIndex !== -1 &&
                this.spreadsheet.setCellFormula(
                  sheet,
                  row,
                  mappedCalcColIndex,
                  `=PRODUCT(${this.datasheet.columnAlphabetIndex(
                    targetColCellIndex
                  )}${row + 1}, 12)`
                ); // (chargeCode*12) To get annual total
            }
            const { foundColumnIndex } =
              this.datasheet.customSearchColumnWithValue(1, 0, targetKey);
            if (foundColumnIndex !== -1) {
              const total =
                this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
                  sheet,
                  `=SUM(${this.datasheet.columnAlphabetIndex(
                    foundColumnIndex
                  )}2:${this.datasheet.columnAlphabetIndex(
                    foundColumnIndex
                  )}${rowCount})`
                );
              userSelectedChargeCode[targetKey]['total'] = total;
            }
          }
        }
      }
    }
    this.spreadsheet.resume();
  }

  calculateRetailChargeCodeTotal(chargeCodeTotal, unit, sqFtColumnValue) {
    this.spreadsheet.suspend();
    const { EMPTY, MONTHLY, ANNUAL, ANNUAL_PER_SQFT, MONTHLY_PER_SQFT } =
      RETAIL_CHARGE_CODE_FORMULA;
    let calculatedCellValue = 0;
    if (chargeCodeTotal !== null) {
      if (unit === EMPTY || unit === MONTHLY || isNil(unit)) {
        calculatedCellValue = chargeCodeTotal; // Show Charge Code Value as it is
      }
      if (unit === ANNUAL) {
        calculatedCellValue = divide(chargeCodeTotal, 12); // [chargeCode/12]
      }
      if (unit === ANNUAL_PER_SQFT) {
        calculatedCellValue = multiply(
          divide(chargeCodeTotal, 12),
          sqFtColumnValue
        ); // [chargeCode/12*SqFt]
      }
      if (unit === MONTHLY_PER_SQFT) {
        calculatedCellValue = multiply(chargeCodeTotal, sqFtColumnValue); // [chargeCode*SqFt]
      }
    }
    this.spreadsheet.resume();
    return calculatedCellValue;
  }

  clearRetailChargeCodeColumns(sheet, chargeCodesColumns, rowCount) {
    this.spreadsheet.suspend();
    if (chargeCodesColumns.length > 0) {
      for (
        let chargeCodeColumnIndex = 0;
        chargeCodeColumnIndex < chargeCodesColumns.length;
        chargeCodeColumnIndex++
      ) {
        const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
          1,
          0,
          chargeCodesColumns[chargeCodeColumnIndex]
        );
        if (foundColumnIndex !== -1) {
          const range = sheet.getRange(1, foundColumnIndex, rowCount, 1);
          range.formatter(cellNumberFormat);
          for (let row = 1; row < rowCount; row++) {
            sheet.setFormula(row, foundColumnIndex, null);
            sheet.setText(row, foundColumnIndex, null);
          }
        }
      }
    }
    this.spreadsheet.resume();
  }

  /******************************/
  /* Flag errorgenous data on MF RR */
  /********************************/

  populateTentativeStatusOnFirstRender() {
    if (this.validateDocumentAssetType(PROPERTY_TYPES.MULTIFAMILY.baseClass)) {
      const targetSheetColumnCount = this.spreadsheet.getSheet(TARGET_SHEET).getColumnCount();
      const columnIndex = targetSheetColumnCount - 3;
      const statusIndex = this.datasheet.getColIndex(TARGET_SHEET, "Status");
      const [ targetSheetData ] = this.datasheet.getSheetSpecificData([TARGET_SHEET_NAME]);
      const { data: { dataTable } } = targetSheetData;
      this.spreadsheet.setCellValue(targetSheetIndex, 0, columnIndex, tentativeColumnName);
      const sheet = this.spreadsheet.getSheet(targetSheetIndex);
      sheet.setColumnWidth(columnIndex, 0);
      sheet.setColumnResizable(columnIndex, false, this.spreadsheet.GC.Spread.Sheets.SheetArea.colHeader);
      for (let i = 1; i < Reflect.ownKeys(dataTable).length; i++) {
        const rowIndex = i;
        const status = this.spreadsheet.getCellValue(targetSheetIndex, rowIndex, statusIndex);
        this.spreadsheet.setCellValue(targetSheetIndex, rowIndex, columnIndex, status);
      }
    }
  } 

  validateMonthlyRent() {
    // Validate only if tentative status exists,
    const tentativeStatusIndex = this.datasheet.getColIndex(TARGET_SHEET, columnMap["Status"]);
    if (tentativeStatusIndex < 0) {
      return false;
    }
    this.spreadsheet.suspend();
    const taggingInfo = currentDocumentTaggingInfoSelector(this.props.store.getState());
    const asOnDate = get(taggingInfo, 'asOnDate');

    const rowCount = this.getTargetSheetRowCount();
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);

    const statusIndex = this.datasheet.getColIndex(TARGET_SHEET, columnMap["Status"]);
    const marketRentIndex = this.datasheet.getColIndex(TARGET_SHEET, columnMap['MarketRent']);
    const monthlyRentIndex = this.datasheet.getColIndex(TARGET_SHEET, columnMap['MonthlyRent']);
    const leaseEndDateIndex = this.datasheet.getColIndex(TARGET_SHEET, columnMap['LeaseEndDate']);

    this.statusIndex = statusIndex;
    this.marketRentIndex = marketRentIndex;
    this.monthlyRentIndex = monthlyRentIndex;
    this.leaseEndDateIndex = leaseEndDateIndex;

    Array(rowCount).fill(0).map((_, i) => {
      const index = i + 1;
      const row = index;
      const status = this.spreadsheet.getCellValue(targetSheetIndex, row, statusIndex);
      const marketRent = this.spreadsheet.getCellValue(targetSheetIndex, row, marketRentIndex);
      const monthlyRent = this.spreadsheet.getCellValue(targetSheetIndex, row, monthlyRentIndex);
      const leaseEndDate = this.spreadsheet.getCellValue(targetSheetIndex, row, leaseEndDateIndex);

      let shouldHighlightCell = false;
      let errorMessageMapObj = errorMessageMap['MONTHLY_RENT_0'];
      if (status === statusMap["Occupied"] && monthlyRent <= 1) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['MONTHLY_RENT_0'];
      }

      if (status === statusMap["Vacant"] && monthlyRent > 0) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['MONTHLY_RENT_NON_0'];
      }


      if (monthlyRent > 0 && marketRent > 0) {
        if (status === statusMap["Occupied"] && rentDiffPercentage(monthlyRent, marketRent) > cutoffPercentage) {
          shouldHighlightCell = true;
          errorMessageMapObj = errorMessageMap['CUTTOFF_ERROR'];
        }
      }

      if (moment(leaseEndDate).isBefore(asOnDate)) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['ASOFDATE_AFTER_ENDDATE'];
      }

      this.highlightRow(sheet, row, errorMessageMapObj, shouldHighlightCell);
    });

    this.spreadsheet.resume();
  }

  reCalculateTentativeStatus(rrOccupancyConfig) {
    // Validate only if tentative status column exits.
    if (!this.statusIndex) {
      return false;
    }
    if (this.validateDocumentAssetType(PROPERTY_TYPES.MULTIFAMILY.baseClass)) {
      const { unitStatus: { config } } = rrOccupancyConfig;
      const { statusIndex: tentativeStatusIndex } = this;
      const [ targetSheetData ] = this.datasheet.getSheetSpecificData([TARGET_SHEET_NAME]);
      const { data: { dataTable } } = targetSheetData;
      const statusIndex = this.datasheet.getColIndex(TARGET_SHEET, "Status");
      this.spreadsheet.suspend();
      for (let i = 1; i < Reflect.ownKeys(dataTable).length; i++) {
        const rowIndex = i;
        const status = this.spreadsheet.getCellValue(targetSheetIndex, rowIndex, statusIndex);
        const tentativeStatus = get(config[status], 'category', status);
        this.spreadsheet.setCellValue(targetSheetIndex, rowIndex, tentativeStatusIndex, tentativeStatus);
      }
      this.spreadsheet.resume();
      this.validateMonthlyRent();
    }
  }

  validateMonthlyRentOnCellChanged({
    sheet, row, col
  }) {
    // Validate only if tentative status column exits.
    if (!this.statusIndex) {
      return false;
    }
    if (this.validateDocumentAssetType(PROPERTY_TYPES.MULTIFAMILY.baseClass)) {
      const statusIndex = this.datasheet.getColIndex(TARGET_SHEET, "Status");
      /**
       * Validate only if changed column is Status, MonthlyRent, MarketRent, Tentative Status, Move out date
       */
      const { statusIndex: tentativeStatusIndex, marketRentIndex, monthlyRentIndex, leaseEndDateIndex } = this;
      if ([statusIndex, tentativeStatusIndex, marketRentIndex, monthlyRentIndex, leaseEndDateIndex].indexOf(col) < 0) {
        return;
      }

      if (statusIndex === col) {
        const { unitStatus: { config } } = mfRentRollConfigSelector(this.props.store.getState());
        const status = this.spreadsheet.getCellValue(targetSheetIndex, row, statusIndex);
        const tentativeStatus = get(config[status], 'category', status);
        this.spreadsheet.setCellValue(targetSheetIndex, row, tentativeStatusIndex, tentativeStatus);
      }
      const taggingInfo = currentDocumentTaggingInfoSelector(this.props.store.getState());
      const asOnDate = get(taggingInfo, 'asOnDate');

      const status = this.spreadsheet.getCellValue(targetSheetIndex, row, tentativeStatusIndex);
      const marketRent = this.spreadsheet.getCellValue(targetSheetIndex, row, marketRentIndex);
      const monthlyRent = this.spreadsheet.getCellValue(targetSheetIndex, row, monthlyRentIndex);
      const leaseEndDate = this.spreadsheet.getCellValue(targetSheetIndex, row, leaseEndDateIndex);

      let shouldHighlightCell = false;
      let errorMessageMapObj = errorMessageMap['MONTHLY_RENT_0'];

      if (status === statusMap["Occupied"] && monthlyRent <= 1) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['MONTHLY_RENT_0'];
      }

      if (status === statusMap["Vacant"] && monthlyRent > 0) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['MONTHLY_RENT_NON_0'];
      }

      if (monthlyRent > 0 && marketRent > 0) {
        if (status === statusMap["Occupied"] && rentDiffPercentage(monthlyRent, marketRent) > cutoffPercentage) {
          shouldHighlightCell = true;
          errorMessageMapObj = errorMessageMap['CUTTOFF_ERROR'];
        }
      }

      if (moment(leaseEndDate).isBefore(asOnDate)) {
        shouldHighlightCell = true;
        errorMessageMapObj = errorMessageMap['ASOFDATE_AFTER_ENDDATE'];
      }

      this.highlightRow(sheet, row, errorMessageMapObj, shouldHighlightCell);
    }

  }

  highlightCell(sheet, row, col, shouldHighlightCell) {
    this.spreadsheet.suspend();
    sheet.getRange(row, col, 1, 1)['backColor'](shouldHighlightCell ? '#FF6F61' : undefined);
    sheet.getRange(row, col, 1, 1)['foreColor'](shouldHighlightCell ? 'black' : undefined);
    this.spreadsheet.resume();
  }

  highlightRow(sheet, row, errorMessageMap, shouldHighlightCell) {
    this.spreadsheet.suspend();
    const { color, message } = errorMessageMap;
    sheet.getRange(row, -1, 1, -1).backColor(shouldHighlightCell ? color : undefined);
    sheet.comments.add(row, 1, shouldHighlightCell ? message : undefined);
    this.spreadsheet.resume();
  }

  render() {
    const {
      className,
      style,
      taggingInfo,
      onSlidePaneOpen,
      onRefereceWindowOpen,
      document,
      project,
      sheetCount,
      showToolbar
    } = this.props;
    return (
      <>
        <div className={`${className} vertical-section`} style={style}>
          <DataSpreadSheet
            toolbarCustomRightChild={
              <>
                <div className={`${coreStyle.spreadsheetSideToolbar} border-0`}>
                  <DocExtractionTaggingInfo
                    docType={DocumentFileTypes.RENT_ROLL.key}
                    taggingInfo={taggingInfo}
                    project={project}
                    document={document}
                  />
                  <div
                    className={`position-relative d-inline-block ${coreStyle.spreadsheetToolbarSeparator}`}
                    aria-disabled="true"
                    role="separator line"
                  >
                    {' '}
                    &nbsp;{' '}
                  </div>
                  <SpreadSheetToolbarButton
                    id="DownloadXLSXFile"
                    title="Download Workbook"
                    size="lg"
                    className={coreStyle.downloadFileIcon}
                    onClick={() => this.datasheet.downloadXLS(document)}
                  />
                  <SpreadSheetToolbarButton
                    id="SaveXLSXFile"
                    title="Save Workbook"
                    size="lg"
                    className={coreStyle.saveFileIcon}
                    onClick={() => this.onSaveWorkbook()}
                  />
                  <SpreadSheetToolbarButton
                    id="refWindow"
                    title="Open Reference Window"
                    size="lg"
                    className={coreStyle.referenceWindowIcon}
                    onClick={onRefereceWindowOpen}
                  />
                  <SpreadSheetToolbarButton
                    id="keyboardShortcuts"
                    title="Keyboard Shortcuts"
                    size="lg"
                    className={coreStyle.keyboardShortcutsIcon}
                    onClick={() => {
                      onSlidePaneOpen(
                        affixComponentName.SHORTCUT_KEYS,
                        affixComponentName.SHORTCUT_KEYS
                      );
                      this.props.eventTrackerService.track(
                        KeyboardShortcutsOpened
                      );
                    }}
                  />
                </div>
              </>
            }
            documentRef={this}
            documentType={DocumentFileTypes.RENT_ROLL.key}
            onToast={this.props.setWorkbookToast}
            onReady={this.loadData}
            onDocumentEvents={() => this.rentRollEvents()}
            ref={this.refDataSheet}
            showToolbar={showToolbar}
            sheetCount={sheetCount}
          />
        </div>
      </>
    );
  }
}

RRSpreadSheet.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  assetType: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  toolbarCustomRightChild: PropTypes.node,
  workbookData: PropTypes.object,
  taggingInfo: PropTypes.object,
  onSlidePaneOpen: PropTypes.func,
  onRefereceWindowOpen: PropTypes.func,
  data: PropTypes.shape({
    meta: PropTypes.object.isRequired,
    source: PropTypes.object.isRequired,
    target: PropTypes.shape({
      rows: PropTypes.array.isRequired,
      columns: PropTypes.array.isRequired
    })
  }),
  sheetCount: PropTypes.number,
  showToolbar: PropTypes.bool
};

const maptStateToProps = (state) => ({
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
});
export default connect(maptStateToProps)(
  withServices('store', 'eventTrackerService')(RRSpreadSheet)
);
