import React, { useState } from 'react';
import { Form, Field, Formik } from 'formik';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { faArrowCircleLeft as prevIcon } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';

import {
  loggedInCompanySelector,
  dynamicAssetTypesListSelector,
} from '../../../store/selectors';
import UploadProgress from '../../app/UploadProgress/UploadProgress';
import { Button, Card } from 'react-bootstrap';
import autouwAiImg from '../../../assets/images/technology-ai.png';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import style from './FallbackApproachPage.module.scss';
import ReactTable from '../../core/ReactTable/ReactTable';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import UserHeader from '../../app/UserHeader/UserHeader';
import IconButton from '../../core/Button/IconButton';
import FallbackApproachFormComponent from '../../app/FallbackApproach/FallbackApproachFormComponent';
import FallbackSpreadSheet from '../../app/DocExtractionComponent/FallbackSpreadSheet';


function FallbackApproachPage({
  fallbackExtractionService,
  toastrService
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  async function onSubmit(values, actions) {
    if (values?.file === null) {
      toastrService.error('Please select a Rent Roll or Operating Statement document');
      return
    }
    try {
      setIsLoading(true);
      setResult(null);
      const response = await fallbackExtractionService.convertDocToTable(values);
      setIsLoading(false);
      setResult(response?.data);
    } catch (error) {
      console.log(error)
      // actions.setStatus({ error: error.message });
    }
  }

  const onCloseExtractionPreview = () => {
    setResult(null);
  }

  return (
    <LoggedInUserPage>
      {
        !result ?
          <FallbackApproachFormComponent
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setResult={setResult}
            onSubmit={onSubmit}
          /> :( 
            <FallbackSpreadSheet data={result} onCloseExtractionPreview={onCloseExtractionPreview} />
         )
      }
    </LoggedInUserPage>
  );
}

const mapStateToProps = (state) => {
  const currentCompany = loggedInCompanySelector(state);
  return {
    company: currentCompany,
    assetTypes: dynamicAssetTypesListSelector(state),
  };
};

export default connect(mapStateToProps)(
  withServices('fallbackExtractionService', 'toastrService')(FallbackApproachPage)
);
