import React from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/selectors';
import { LoginFormMemo } from '../../app/LoginForm/LoginForm';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import { clikConnectConfig } from '../../../config';


function useRedirectOnUserLoggedIn(applicationSlug, timeout) {
  const authDetails = useSelector(authSelector);
  const authString = JSON.stringify(authDetails);
  const encodedAuthString = btoa(authString);

  const redirect = React.useCallback(() => {
    window.location = `${clikConnectConfig.appBaseUrl}/connect/auth/success/${applicationSlug}?t=${encodedAuthString}`;
  }, [applicationSlug, encodedAuthString]);

  React.useEffect(() => {
    if (authDetails.user) {
      setTimeout(redirect, timeout);
    }
  }, [redirect, timeout]);
}

function AlreadyLoggedIn({ applicationSlug }) {
  useRedirectOnUserLoggedIn(applicationSlug, 1000);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <strong>You are already logged in, redirecting you back...</strong>
    </div>
  );
}

function LoginPage({ applicationSlug }) {
  useRedirectOnUserLoggedIn(applicationSlug, 0);

  return (
    <LoggedOutUserPage>
      <div className="vertical-section dotted-bg">
        <LoginFormMemo redirectOnSuccess={false} />
      </div>
    </LoggedOutUserPage>
  );
}

function AuthProviderLoginPage({ applicationSlug, loggedIn }) {
  if (loggedIn) {
    return <AlreadyLoggedIn applicationSlug={applicationSlug} />;
  }

  return (
    <LoginPage applicationSlug={applicationSlug} />
  );
}

// eslint-disable-next-line import/prefer-default-export
export { AuthProviderLoginPage };
