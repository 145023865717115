import React from 'react';
import style from './StaticPageWithNav.module.scss';

export default function StaticPageWithNav({ content, navigation, footerNavigation = null, isLoggedIn = true }) {
  return (
    <>
      <div className="row">
        <div className={`col-md-9 ${isLoggedIn ? style.pageWrapper : style.pageWrapperWithoutLogin}`}>
          {content}
        </div>
        <div className={`col-3 ${style.contentNav}`}>
          <h5>CONTENTS</h5>
          <ul>
            {
              !!navigation.length && navigation.map(nav => (
                <li key={nav.id}>
                  <a href={`#${nav.id}`} title={nav.name}>{nav.name}</a>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      {footerNavigation}
    </>
  )
}
