import React, {
  useState, useEffect, useCallback, useMemo
} from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  companyTemplatesAsListSelector, getGlobalTemplateMappingsSelector
} from '../../../store/selectors';
import { getTemplateDropdownMap } from '../../../utils/utils';
import _ from 'lodash';

function UserTemplateAccessForm({
  onSubmitSuccess, bindSubmission, user, companyUUID, userService, toastrService, companyTemplates
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [readAccessAll, setReadAccessAll] = useState(false);
  const [initialWriteTemplateAccess, setInitialWriteTemplateAccess] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState(initialWriteTemplateAccess);
  const [initialReadTemplateAccess, setInitialReadTemplateAccess] = useState([]);
  const [selectedReadAccessTemplates, setSelectedReadAccessTemplates] = useState(initialReadTemplateAccess);
  const companyTemplatesMap = useMemo(() => getTemplateDropdownMap(companyTemplates),
    [companyTemplates]);

  useEffect(() => {
    const fetchTemplates = async () => {
      const fetchedTemplates = await userService.getTemplateAccess(user.user.uuid);
      let initialWriteTemplateAccess = [];
      let initialReadTemplateAccess = [];
      if (fetchedTemplates.length) {
        initialWriteTemplateAccess = getTemplateDropdownMap(fetchedTemplates.filter(template => template?.UserTemplateAccess?.writeAccess));
        initialReadTemplateAccess = getTemplateDropdownMap(fetchedTemplates.filter(template => template?.UserTemplateAccess?.writeAccess || template?.UserTemplateAccess?.readAccess));
      } else {
        initialWriteTemplateAccess = companyTemplatesMap;
        initialReadTemplateAccess = companyTemplatesMap;
        setSelectAll(true);
        setReadAccessAll(true);
      }
      setInitialWriteTemplateAccess(initialWriteTemplateAccess);
      setSelectedTemplates(initialWriteTemplateAccess);
      setInitialReadTemplateAccess(initialReadTemplateAccess);
      setSelectedReadAccessTemplates(initialReadTemplateAccess);
    };
    fetchTemplates();
  }, [user, companyTemplatesMap]);

  const onSubmit = useCallback(async () => {
    if(selectedTemplates && selectedTemplates.length || selectedReadAccessTemplates && selectedReadAccessTemplates.length) {
      try {
        const writeTemplateAccessUuids = selectedTemplates.map(({ value }) => value);
        const readTemplateAccessUuids = selectedReadAccessTemplates.map(({ value }) => value);
        const response = await userService.updateTemplateAccess(
          readTemplateAccessUuids,
          writeTemplateAccessUuids,
          user.user.uuid
        );
        toastrService.success(response.message);
        onSubmitSuccess();
      } catch (error) {
        console.error(error);
      }
    }
  }, [selectedTemplates, selectedReadAccessTemplates, user.user]);

  const getTemplateAccesss = useCallback((templateName) => {
    const { user: { UserTemplateAccesses } } = user; 
    const foundTemplateName = companyTemplates.find(template => template.templateName === templateName);
    const template = UserTemplateAccesses ? UserTemplateAccesses.find(template => template.templateId === foundTemplateName.id) : {};
    return template;
  }, [user, companyTemplates])

  useEffect(() => {
    bindSubmission(onSubmit, false);
  }, [onSubmit]);

  return (
    <>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox" checked={selectAll} onChange={e => {
            setSelectAll(e.target.checked);
            setSelectedTemplates(companyTemplatesMap);
          }} label="Allow write access to all templates" />
      </Form.Group>
      <ReactSelect
        isMulti
        isDisabled={selectAll}
        value={selectedTemplates}
        onChange={selectedTempates => {
          setSelectedTemplates(selectedTempates);
        }}
        options={companyTemplatesMap} />
      {isEmpty(selectedTemplates) && <div className="text-danger">
          <small>Please select atleast one template</small>
      </div>}
      <Form.Group controlId="formBasicCheckbox" style={{marginTop: "20px"}}>
        <Form.Check
          type="checkbox" checked={readAccessAll} onChange={e => {
            setReadAccessAll(e.target.checked);
            setSelectedReadAccessTemplates(companyTemplatesMap);
          }} label="Allow read access to all templates." />
      </Form.Group>
      <ReactSelect
        isMulti
        isDisabled={readAccessAll}
        value={selectedReadAccessTemplates}
        onChange={selectedTempates => {
          setSelectedReadAccessTemplates(selectedTempates);
        }}
        options={companyTemplatesMap} />
    </>
  );
}
const mapStateToProps = state => ({
  companyTemplates: [
    ...companyTemplatesAsListSelector(state)
  ]
});
export default connect(mapStateToProps)(withServices('userService', 'toastrService')(UserTemplateAccessForm));
