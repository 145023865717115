import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { withServices } from 'reaf';
import textDocumentIcon from '../../../assets/images/text-documents.svg';
import { DocumentAddedByDrop } from '../../../constants/eventTrackerMessage';
import style from './DocumentFileDrop.module.scss';
import './DocumentFileDrop.scss';

function DocumentFileDrop({ project, documentsService, eventTrackerService }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      documentsService
        .createDocuments(project, acceptedFiles)
        .catch(console.log);
      eventTrackerService.track(DocumentAddedByDrop);
    },
    [project, documentsService]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="uploadDocumentFileDrop">
      <div
        {...getRootProps({
          className:
            'vertical-section justify-content-center bg-light dropzone-file-uploads-container'
        })}
      >
        <input {...getInputProps()} />
        <div className={`text-center ${style.documentFileDragdrop}`}>
          <div className={`${style.documentFileIcon}`}>
            <img src={textDocumentIcon} alt="text document icon" />
          </div>
          <h1>Drop your Rent Roll and Operating Statement files here</h1>
          <p>
            Or use the&nbsp;
            <strong className="text-primary cursor-pointer">
              Browse Files
            </strong>{' '}
            &nbsp;button{' '}
          </p>
          <small>
            Please upload&nbsp;
            <span className="text-primary">.pdf, .xlsx</span> &nbsp;file
            formats.
          </small>
        </div>
      </div>
    </div>
  );
}

export default withServices(
  'documentsService',
  'eventTrackerService'
)(DocumentFileDrop);
