import BaseCommand from './BaseCommand';

class SelectRowCommand extends BaseCommand {
  run(spreadsheet, options) {
    const sheet = spreadsheet.getSheet();
    sheet.setSelection(sheet.getActiveRowIndex(), 0, 1, sheet.getColumnCount());
  }
}

const selectRowCommand = SelectRowCommand.get();

export default selectRowCommand;
