import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Col, Row, Container } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import ProjectExporterPopups from '../ProjectExporterPopups/ProjectExporterPopups';
import DeleteProjectExporter from '../DeleteProjectExporter/DeleteProjectExporter';
import { projectExportersAsListSelector, currentCompanySelector } from '../../../store/selectors';
import { displayDateFormat } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';
import useBool from '../../../hooks/useBool';

const initialValues = {
  projectExporter: {
    name: '',
    outputFormat: 'XML'
  }
};

const regexFilter = {
  type: 'RegexFilter',
  placeholder: 'Filter',
  delay: 1000
};


function ProjectExporterList({ projectExporters, companyUUID, projectExporterService }) {
  const [createProjectExporterVisible, showCreateProjectExporter, hideCreateProjectExporter] = useBool();

  const downloadFile = useCallback((row, fileType) => {
    projectExporterService.getProjectExporterFile(companyUUID, row.uuid, fileType);
  }, [companyUUID]);


  const ActionFieldComponent = useCallback(({ projectExporter }) => (
    <div className="d-flex flex-row">
      <IconButton icon={faDownload} className="smallIconButton" placement="left" title="Download Mapper File" onClick={() => downloadFile(projectExporter, 'mapper')} />
      <IconButton icon={faDownload} className="smallIconButton" placement="left" title="Download Mapping Function File" onClick={() => downloadFile(projectExporter, 'mappingFunction')} />
      <DeleteProjectExporter companyUUID={companyUUID} projectExporter={projectExporter} label={<IconButton className="smallIconButton" placement="left" title="Delete Property Exporter" icon={faTrash} />} />
    </div>
  ), [companyUUID]);

  const actionFieldFormat = useCallback((_, projectExporter) => (
    <ActionFieldComponent projectExporter={projectExporter} />
  ), []);


  return (
    <>
      <ProjectExporterPopups
        onHide={hideCreateProjectExporter}
        showCreateProjectExporter={createProjectExporterVisible}
        projectExporter={initialValues}
      />
      <Container fluid className="p-2 pb-4">
        <Row className="align-items-center">
          <Col sm="auto">
            <h5 className="mb-0">Project Exporters</h5>
          </Col>
          <Col sm="auto" className="pl-0 tourIconBtn">
            <IconButton
              title="Add Project Exporters" className="btn-circle btn-shadow"
              variant="outline-secondary" size="sm" icon={faPlus}
              onClick={showCreateProjectExporter}
              id="AddProjectExporter"
            />
          </Col>
          <Col />
        </Row>
      </Container>

      <BootstrapTable
        data={projectExporters}
        tableStyle={{ background: '#fafafa' }}
        condensed
        hover
      >
        <TableHeaderColumn
          dataField="uuid"
          isKey
          hidden
          dataSort
          width="60"
          dataAlign="center"
        >
              S.N.
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="name"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
              Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="outputFormat"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
        >
               Output Format
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="createdAt"
          dataFormat={displayDateFormat}
          dataSort
        >
              Created On
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="Actions"
          dataFormat={actionFieldFormat}
          width="20%"
        >
              Actions
        </TableHeaderColumn>
      </BootstrapTable>

    </>
  );
}

const mapStateToProps = state => ({
  projectExporters: projectExportersAsListSelector(state),
  companyUUID: currentCompanySelector(state)
});


export default connect(mapStateToProps)(withServices('projectExporterService')(ProjectExporterList));
