import { createSelector } from 'reselect';
import { get as getProperty, isEmpty } from 'lodash';

export const routerStateSelector = (state) => state.router;
export const authSelector = (state) => state.app.auth;
export const loggedInCompanySelector = (state) => state.app.auth.company;
export const loggedInUserSelector = (state) => state.app.auth.user;
export const selfSignupStatusSelector = (state) => state.app.auth.isSelfSignup;
export const projectMetaDataSelector = (state) =>
  state.app.metaData.appMetaData.projectMetaData;
export const prospectMetaDataSelector = (state) =>
  state.app.metaData.appMetaData.prospectMetaData;
export const allProjectsSelector = (state) =>
  state.app.projects.projectList.projects;
export const allProjectsAsListSelector = createSelector(
  allProjectsSelector,
  (projectsMap) => Reflect.ownKeys(projectsMap).map((uuid) => projectsMap[uuid])
);
export const documentsSummarySelector = (state) =>
  state.app.projects.documentsSummary;
export const superAdminDocumentsSelector = (state) =>
  state.app.documents.documentsList.documents;
export const currentCompanySelector = (state) =>
  state.app.company.currentCompany;
export const numberOfOSMonthsToHideSelector = (state) =>
  state.app.company.numberOfOSMonthsToHide;

export const currentProjectSelector = (state) =>
  state.app.currentProject.project;
export const companiesDetailsSelector = (state) =>
  state.app.companyList.companiesDetails;
export const companiesDetailsAsListSelector = createSelector(
  companiesDetailsSelector,
  (companyDetailsMap) =>
    Reflect.ownKeys(companyDetailsMap).map((uuid) => companyDetailsMap[uuid])
);
export const accountsExecutivesListSelector = (state) =>
  state.app.accountsExecutive.accountsExecutiveList;
export const prospectsListSelector = (state) =>
  state.app.prospects.prospectsList;
export const prospectInvoicesListSelector = (state) =>
  state.app.prospects.invoiceList;
export const currentProspectSelector = (state) =>
  state.app.prospects.prospectsList.filter(
    (prospect) => prospect.uuid === state.app.prospects.currentProspectUuid
  );
export const dynamicAssetTypesListSelector = (state) =>
  state.app.dynamicAssetType.dynamicAssetTypesList;
export const dynamicAssetTypesAsObjSelector = createSelector(
  dynamicAssetTypesListSelector,
  (dynamicAssetTypes) => dynamicAssetTypes.reduce((assetTypeObj, assetType) => {
    assetTypeObj[assetType.assetType] = assetType
    return assetTypeObj;
  }, {})
);
export const activeSubscriptionsListSelector = (state) => {
  return state.app.subscriptionsList.activeSubscriptions;
};
export const inActiveSubscriptionsListSelector = (state) => {
  return state.app.subscriptionsList.inActiveSubscriptions;
};
export const companyUsersList = (state) =>
  state.app.companyUsers.companyUsersList;
export const companyUsersAsListSelector = createSelector(
  companyUsersList,
  (usersMap) => Reflect.ownKeys(usersMap).map((uuid) => usersMap[uuid])
);
export const allowUserToUpdateTemplate = (state) =>
  state.app.companyUsers.allowUserToUpdateTemplate;
export const currentTemplateSelector = (state) =>
  state.app.companyTemplates.currentTemplateUuid;
export const companyTemplatesList = (state) =>
  state.app.companyTemplates.companyTemplates;
export const companyTemplatesAsListSelector = createSelector(
  companyTemplatesList,
  (templateMap) => Reflect.ownKeys(templateMap).map((uuid) => templateMap[uuid])
);
export const companyProjectExpotersList = (state) =>
  state.app.companyProjectExporters.companyProjectExporters;
export const projectExportersAsListSelector = createSelector(
  companyProjectExpotersList,
  (projectExportersMap) =>
    Reflect.ownKeys(projectExportersMap).map(
      (uuid) => projectExportersMap[uuid]
    )
);
export const templateKeysToMaskSelector = (state) =>
  state.app.metaData.appMetaData.additionalMetaData.keysToMask;
export const ticketMetaDataSelector = (state) =>
  state.app.metaData.appMetaData.ticketMetaData;
export const excludedCategoriesSelector = (state) =>
  state.app.metaData.appMetaData.additionalMetaData.excludedCategories;
export const templateModelList = (state) =>
  state.app.templateModel.templateModels;
export const templateModelAsListSelector = createSelector(
  templateModelList,
  (templateModelMap) =>
    Reflect.ownKeys(templateModelMap).map((uuid) => templateModelMap[uuid])
);
export const currentProjectTemplateHeadCategoriesSelector = (state) =>
  state.app.currentProject.templateHeadCategories;
export const currentProjectCategorySequenceSelector = (state) =>
  state.app.currentProject.categorySequence;
export const currentProjectSummarySheetTotalConfigSelector = (state) =>
  state.app.currentProject.summarySheetTotalConfig;
export const isDocumentTaggingActiveSelector = (state) =>
  state.app.currentProject.isDocumentTaggingActive;
export const setIsDocumentValidatedSelector = (state) =>
  state.app.currentProject.isDocumentValidated;
export const currentProjectTemplateMappingsSelector = (state) =>
  state.app.currentProject.templateMappings;
export const currentProjectViewerActiveSheetSelector = (state) =>
  state.app.currentProject.currentViewerActiveSheet;
export const currentProjectIsReportEnabledSelector = (state) =>
  state.app.currentProject.isReportEnabled;
export const currentProjectisReportShareLinkEnabledSelector = (state) =>
  state.app.currentProject.isReportShareLinkEnabled;
export const currentProjectCanDownloadConsolidatedModeldSelector = (state) =>
  state.app.currentProject.canDownloadConsolidatedModel;
export const currentProjectReportSelector = (state) =>
  state.app.currentProject.reports;
export const assumptionSelector = (state) =>
  state.app.currentProject.assumption;
export const projectSpecificAssumptionSelector = (state) =>
  state.app.currentProject.projectSpecificAssumption;
export const currentProjectDocumentsSelector = (state) =>
  state.app.currentProject.documents;
export const currentProjectAssetTypeSelector = (state) =>
  state.app.currentProject.project.assetType;
export const currentPojectModelNameSelector = (state) =>
  getProperty(
    state,
    'app.currentProject.assumption.servicing.general.model',
    ''
  );
export const currentProjectServicingUnitStatusAssumption = (state) =>
  getProperty(
    state,
    'app.currentProject.assumption.servicing.lender_information["SF/Units/Pads/Beds"]'
  );
export const currentProjectProductionUnitStatusAssumption = (state) =>
  getProperty(
    state,
    'app.currentProject.assumption.production.execute_loan_information.property_information.no_of_units'
  );
export const currentProjectAcquisitionUnitStatusAssumption = (state) =>
  getProperty(
    state,
    'app.currentProject.assumption.acquisition.property_information.no_of_units'
  );
export const currentProjectDocumentsAsListSelector = createSelector(
  currentProjectDocumentsSelector,
  (documentsMap) =>
    Reflect.ownKeys(documentsMap).map((uuid) => documentsMap[uuid])
);
export const uploadsStatusSelector = (state) => state.app.fileUploadStatus;
export const currentDocumentSelector = (state) =>
  state.app.currentDocument.document;
export const rentRolllConfigSelector = (state) =>
  state.app.currentDocument.rentRolllConfig;
export const floorPlanSummarySelector = (state) =>
  state.app.currentDocument.floorPlanSummary;
export const chargeCodeConfigSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.chargeCode.config;
export const mfRentRollConfigSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig;
export const commercialRentRollConfigSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.commercialRentRollConfig;
export const rowReverseMappingSelector = (state) =>
  state.app.currentDocument.rowReverseMapping;
export const isMonthlyDataUsedSelector = (state) =>
  state.app.currentDocument.useMonthlyData;

export const staticChargeCodeSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.chargeCode.chargeCodeDropdown;
export const staticChargeCodeMappingSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.chargeCode
    .chargeCodeDropDownColMappings;
export const unitChargeCodeSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.chargeCode.chargeCodeUnitTypes;
export const extractedDataSelector = (state) =>
  state.app.currentDocument.extractedData.data;
export const cashflowDropdownsSelector = (state) =>  
state.app.currentDocument.extractedData.data.meta.columnDropdowns;
export const workbookDataSelector = (state) =>
  state.app.currentDocument.workbookData;
export const workbookDataCopySelector = (state) =>
  state.app.currentDocument.workbookDataCopy;
export const isDocumentValidatedSelector = (state) =>
  state.app.currentDocument.document.isValidated;
export const columnHeadersSelector = (state) =>
  state.app.currentDocument.columnHeadersList;
export const summaryDataSelector = (state) =>
  state.app.currentDocument.summaryData;
  export const cashflowPeriodIdentifierSelector = (state) =>
  state.app.currentDocument.cashFlowPeriodIdentifier;
export const netRentalIncomeSelector = (state) =>
  state.app.currentDocument.netRentalIncome;
export const apiConfigIntegrationSelector = (state) =>
  state.app.currentDocument.apiConfigIntegration;
export const isTaggedPeriodEqualsMLReturnedPeriod = (state) =>
  state.app.currentDocument.isTaggedPeriodEqualsMLReturnedPeriod;
export const currentDocumentTypeSelector = (state) =>
  state.app.currentDocument.document.taggingData.documentType;
export const currentDocumentTaggingInfoSelector = (state) =>
  state.app.currentDocument.document.taggingData;

export const currentDocumentUnitStatusSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.unitStatus.config;
export const currentDocumentFloorPlanSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.floorPlan.config;
export const currentDocumentFloorPlanBedSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.floorPlan
    .bedMapping;
export const currentDocumentFloorPlanBathSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.floorPlan
    .bathMapping;
export const currentDocumentStaticUnitStatusSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.unitStatus
    .unitStatusDropdown;
export const tenantNameUnitStatusSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.unitStatus
    .tenantNameConfig;
export const leaseStartRangeSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.leaseConfig
    .leaseStartRange;
export const leaseEndRangeSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.leaseConfig
    .leaseExpirtyRange;

export const leaseTypeListSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.unitMixSummary
    .leaseTypeDropDown;

export const unitMixSummaryConfigSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.mfRentRollConfig.unitMixSummary
    .summary;

export const projectDictionaryExistsSelector = (state) =>
  state.app.currentDocument.projectDictionaryExists;
export const currentTemplateTagSelector = (state) =>
  state.app.currentDocument.templateTag;
export const currentProjectTemplateSelector = (state) => {
  const currentTemplateUuid = state.app.currentProject.project.templateUuid;
  return state.app.company.templates.filter(t => t.uuid === currentTemplateUuid)[0];
}
export const occupancySelector = (state) =>
  state.app.currentDocument.rentRolllConfig.commercialRentRollConfig
    .occupancyStatus.config;
export const occupancyMappingSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.commercialRentRollConfig
    .occupancyStatus.occupancyMapping;

export const retailLeaseTypeConfigSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.commercialRentRollConfig.leaseType
    .config;
export const retailLeaseTypeDropDownSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.commercialRentRollConfig.leaseType
    .leaseTypes;

export const retailTenantTypeConfigSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.commercialRentRollConfig.tenantType
    .config;
export const retailTenantTypeCalculationPercentSelector = (state) =>
  state.app.currentDocument.rentRolllConfig.commercialRentRollConfig.tenantType
    .commercialMajorTenantTypePercent;

export const userRoleSelector = (state) => authSelector(state).role;
export const userAllowedTemplatesSelector = (state) =>
  state.app.auth.userAllowedTemplates;
export const userAllowedTemplatesAsListSelector = createSelector(
  userAllowedTemplatesSelector,
  (templates) => Reflect.ownKeys(templates).map((uuid) => templates[uuid])
);

export const companyTemplatesSelector = (state) => state.app.company.templates;
export const clikTemplateSelector = createSelector(
  userAllowedTemplatesSelector,
  (templates) => {
    const clickTemplate = templates.find(
      (template) => template.templateName.toLowerCase().indexOf('clik') !== -1
    );
    return clickTemplate;
  }
);

export const companyTemplateModelSelector = (state) =>
  state.app.company.templatesWithModels;

export const loadingBackdropSelector = (state) => state.app.loadingBackdrop;
export const loadingBackdropWithProgressSelector = (state) =>
  state.app.loadingBackdropWithProgress;

export const isFetchingList = (state) =>
  state.app.loadingBackdrop.isFetchingList;

export const toastrSelector = (state) => state.app.notificationToastr;

export const productTourSelector = (state) => state.app.productTour;

export const uiStateSelector = (state) => state.app.uiState;

export const eventCountsSelector = (state) => state.app.auth.eventCounts;

export const getTicketsSelector = (state) =>
  state.app.tickets.ticketList.tickets;
export const getAppConfigurationsSelector = (state) =>
  getProperty(state, 'app.appConfiguration.appConfigurationList');
export const getTicketsAsListSelector = createSelector(
  getTicketsSelector,
  (ticketsMap) => Reflect.ownKeys(ticketsMap).map((uuid) => ticketsMap[uuid])
);

export const getVideoTutorialsCategorySelector = (state) =>
  state.app.videoTutorial.categories;
export const getVideoListSelector = (state) =>
  state.app.videoTutorial.videoList;
export const getVideoTutorialsListSelector = createSelector(
  getVideoListSelector,
  (tutorialListMap) =>
    Reflect.ownKeys(tutorialListMap).map((uuid) => tutorialListMap[uuid])
);
export const getAllFeatureFlags = (state) => state.app.featureFlag.featureFlags;
export const getGlobalTemplateMappingsSelector = (state) =>
  state.app.globalTemplates.globalTemplates;
export const getGlobalTemplateMappingsAsListSelector = createSelector(
  getGlobalTemplateMappingsSelector,
  (globalTemplates) =>
    Reflect.ownKeys(globalTemplates).map((uuid) => globalTemplates[uuid])
);

export const readOnlyAccessSelector = (state) => state.app.currentProject.readOnlyAccess;

export const getAllPaymentLinks = (state) =>
  Reflect.ownKeys(state.app.paymentLinks.paymentLinks).map((id) => state.app.paymentLinks.paymentLinks[id])

export const isConsolidatedModelDownloadingSelector = (state) => 
  state.app.currentProject.isConsolidatedModelDownloading;

export const isAPIIntegrationFormConfigured = (state) => 
  state.app.currentDocument?.apiConfigIntegration?.configFile?.mappings?.length;

export const isAPIIntegrationFormEmpty = (state) => 
  isEmpty(state.app.currentProject?.assumption?.api_integration);

export const downloadFileNameSelector = (state) => 
  state.app.currentProject.downloadFileName;