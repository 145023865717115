import React from 'react';
import {
  Modal
} from 'react-bootstrap';
import TicketCard from '../Tickets/TicketCard';

const TicketPopups = ({
  ticket, showTicketPopoup, onTicketUpdate, setHideTicketPopup
}) => (
  showTicketPopoup && (
    <Modal show={showTicketPopoup} onHide={() => setHideTicketPopup()} backdrop="static" size="md">
      <Modal.Header closeButton>
        <Modal.Title>Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          ticket && <TicketCard ticket={ticket} onTicketUpdate={onTicketUpdate} />
        }
      </Modal.Body>
    </Modal>
  )
);

export default TicketPopups;
