import React, { useCallback, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import style from './DocumentList.module.scss';
import { doSorting } from '../../../utils/utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import messages from '../../../../locales/en-US';

const getClass = (({ headerClassName }) => {
  if (headerClassName) {
    return typeof headerClassName === 'string' ? headerClassName : headerClassName();
  }

  return '';
});
const getColClass = (({ columnClassName }, row) => {
  if (columnClassName) {
    return typeof columnClassName === 'string' ? columnClassName : columnClassName(null, row);
  }

  return '';
});

const DocumentList = ({
  columns,
  data,
  onSelect,
  selectedItem,
  isExpanded,
  toggleCollapse,
  selectedDocumentsForModel = [],
  setSelectedDocumentsForModel
}) => {
  const [sortState, setSortState] = useState({ column: { dataField: '' }, order: '' });
  const [visibleRows, setVisibleRows] = useState(data);
  const [checkedRowsMap, setCheckedRowsMap] = useState({});

  useEffect(() => {
    if(Array.isArray(selectedDocumentsForModel)) {
      if(!selectedDocumentsForModel.length) {
        setCheckedRowsMap({});
      }
      const childrenMap = {};
      const map = selectedDocumentsForModel.reduce((acc, cv) => {
        if(cv.children && cv.children.length >= 1) {
          cv.children.map(child => {
            childrenMap[child.uuid] = child.isValidated;
          });
          //if original row's valid tagged children is same as selectedDcouments children then check the parent as well else uncheck
          const originalRowsChildren = data.find(dataEl => dataEl.uuid === cv.uuid)
            .children
            .filter(child => child.isValidated);
          const selectedRowsChildren = cv.children.filter(child => child.isValidated);
          acc[cv.uuid] = !isEmpty(selectedRowsChildren) && selectedRowsChildren.length === originalRowsChildren.length;
        }
        return acc;
      },{});
      setCheckedRowsMap({...map, ...childrenMap});
    }
  },[selectedDocumentsForModel]);


  useEffect(() => {
    setVisibleRows(data);
  }, [data, selectedDocumentsForModel])

  const setSorting = useCallback((col) => {
    if (col.dataSort) {
      let { column, order } = sortState;
      if (column.dataField !== col.dataField) {
        column = col;
        order = 'desc';
      } else {
        order = order === 'desc' ? 'asc' : 'desc';
      }

      const sortFunc = col.sortFunc || doSorting;
      const visibleRows = data.sort((rowA, rowB) => {
        if (col.sortFunc) {
          return sortFunc(rowA, rowB, order);
        } else {
          return sortFunc(rowA[column.dataField], rowB[column.dataField], order);
        }
      });

      setSortState({
        column: col,
        order
      });
      setVisibleRows(visibleRows);
    }
  }, [sortState, data]);

  const getSortIndicator = useCallback((col) => {
    if (col.dataSort) {
      if (sortState.column.dataField === col.dataField) {
        return sortState.order === 'desc' ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon style={{ color: '#cccccc' }} icon={faSort} />;
      }
    }
  }, [sortState]);

  const getRowElement = useCallback((row, rowIndex) => (col, index) => (
    <div
      key={index}
      className={`${getColClass(col, row)} ${style[`widthGrid${index}`]}`}>
      {col.dataFormat ? col.dataFormat(row[col.dataField], row, rowIndex) : row[col.dataField]}
    </div>
  ), []);

  const getSelectedClass = useCallback((row) => (selectedItem && row.uuid === selectedItem.uuid ? style.selectItem : ''), [selectedItem]);

  useEffect(() => {
    if (sortState.column.dataField) {
      const { column, order } = sortState;
      const sortFunc = column.sortFunc || doSorting;
      const visibleRows = data.sort((rowA, rowB) => {
        if (column.sortFunc) {
          return sortFunc(rowA, rowB, order);
        } else {
          return sortFunc(rowA[column.dataField], rowB[column.dataField], order);
        }
      });

      setVisibleRows(visibleRows);
    } else {
      setVisibleRows(data);
    }
  }, [data, sortState]);

  const handleChildrenCheckboxClick = useCallback((event, row, child) => {
    event.persist();
    let tempSelectedDocs = [];
    if(event.target.checked) {
      tempSelectedDocs = selectedDocumentsForModel.filter(parentDocument => !(parentDocument.uuid === row.uuid));
      let tempRow = selectedDocumentsForModel.find(parentDocument => parentDocument.uuid === row.uuid);
      if(tempRow) {
        tempRow = {
          ...tempRow,
          children: [...tempRow.children, child]
        }
      } else {
        tempRow = {
          ...row,
          children: [{...child}]
        }
      }
      tempSelectedDocs = [
        ...tempSelectedDocs,
        tempRow
      ]
    } else {
      let targetRow = selectedDocumentsForModel.find(parentDocument => parentDocument.uuid === row.uuid);
      tempSelectedDocs = selectedDocumentsForModel.filter(parentDocument => !(parentDocument.uuid === row.uuid));
      targetRow = {
        ...targetRow,
        children: targetRow.children.filter(targetRowChild => !(targetRowChild.uuid === child.uuid))
      }
      tempSelectedDocs = [
        ...tempSelectedDocs,
        targetRow
      ]
    }
    setSelectedDocumentsForModel([...tempSelectedDocs])
  }, [selectedDocumentsForModel, setSelectedDocumentsForModel]);

  const handleCheckboxClick = useCallback((event, row) => {
    event.persist();
    let tempSelectedDocumentsForModel = selectedDocumentsForModel.filter(document => !(document.uuid === row.uuid));
    if(event.target.checked) {
      tempSelectedDocumentsForModel = [
        ...tempSelectedDocumentsForModel,
        row
      ];
    } else {
      tempSelectedDocumentsForModel = selectedDocumentsForModel.filter(document => !(document.uuid === row.uuid))
    }
    setSelectedDocumentsForModel([...tempSelectedDocumentsForModel]);
  }, [selectedDocumentsForModel, setSelectedDocumentsForModel, checkedRowsMap]);

  const getCheckboxEl = (row, child=null) => {
    let isDisabled = false;
    if(child) {
      isDisabled = !(child.isValidated);
    }else {
      if(!row.children) {
        isDisabled = !row.children;
      } else if( row.children.length == 1 ) {
        isDisabled = !(row.children[0].isValidated);
      } else if(row.children.length > 1) {
        isDisabled = !row.children.find(child => child.isValidated);
      }
    }
    const checkboxEl = (
      <label className={`docCheckbox ${isDisabled ? 'disableSelectCheckbox': ''}`}>
      <input
        disabled={isDisabled}
        key={Math.random()}
        type="checkbox"
        id={child ? `checkbox-${child.uuid}` : `{checkbox-${row.uuid}}`}
        checked={child ? checkedRowsMap[child.uuid] : checkedRowsMap[row.uuid]}
        onChange={(e) => child ? handleChildrenCheckboxClick(e, row, child) : handleCheckboxClick(e, row)}
      />
      <span></span>
    </label>
    )
    return isDisabled ? (<OverlayTrigger placement='bottom' overlay={<Tooltip bsPrefix={'tooltip'}>{messages.DOCUMENT_SELECT_DISABLE_MSG}</Tooltip>}>
      {checkboxEl}
    </OverlayTrigger>) : 
    checkboxEl
  }

  return (
    <div className={`clearfix ${style.tablelistpro}`}>
      <div className={`clearfix ${style.fixed}`}>
        <div className={`clearfix ${style.tableHeader}`}>
          {columns.map((col, index) => (
            <div key={index} onClick={() => setSorting(col)} className={`${getClass(col)} ${col.dataSort ? style.sort : ''} ${style[`widthGrid${index}`]}`}>
              {col.label}
              {' '}
              {getSortIndicator(col)}
            </div>
          ))}
        </div>
      </div>
      <div className="clearfix">
        <ul>
          {
            visibleRows.map((row, index) => (
              <>
                <li className={`clearfix d-flex ${getSelectedClass(row)} ${style.parent} ${style.visibleRow}`} key={row.uuid}>
                {!(!!row.children && row.children.length > 1) &&
                <div className={`docuemntListCheckbox ${style.documentCheckbox}`}>
                  {getCheckboxEl(row)}
                </div>}
                  <li
                    id={`Document-${index}`}
                    className='border-0'
                    onClick={() => onSelect(row)}>
                    {
                      !!row.children && row.children.length > 1 && (
                        <ul className="clearfix">
                          <li className={`clearfix hasChildDocument ${getSelectedClass(row)} ${style.documentRow}`} onClick={() => {
                            toggleCollapse(row)
                            }}>
                            <div className={`checkbox ${style.documentCheckbox}`}>
                            {getCheckboxEl(row)}
                          </div>
                            {columns.map(getRowElement(row, index))}
                          </li>
                          {isExpanded(row) && row.children.map((child, index) => (
                            <>
                              <li key={index} className='childDocument' style={{ cursor: 'auto', borderBottom: 'unset', position: 'relative' }}>
                                <div className={`checkbox ${style.documentCheckbox}`}>
                                  {getCheckboxEl(row, child)}
                                </div>
                                {columns.map(getRowElement(child, index))}
                              </li>
                            </>
                          ))}
                        </ul>
                      )
                    }
                    {(!row.children || row.children.length === 1) && columns.map(getRowElement(row, index))}
                  </li>
                </li>
              </>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default DocumentList;
