import React from 'react';
import FormModal from '../FormModal/FormModal';
import AccountsExecutiveForm from '../AccountsExecutiveForm/AccountsExecutiveForm';
import useModalForm from '../../../hooks/useModalForm';

function AccountsExecutivePopups({
  accountsExecutive, onHide, showCreateAccountsExecutive
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showCreateAccountsExecutive &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showCreateAccountsExecutive} title="Add Accounts Executive">
            <AccountsExecutiveForm 
              bindSubmission={bindSubmission} 
              onSubmitSuccess={onHide} 
              accountsExecutive={accountsExecutive} 
            />
          </FormModal>
        )
      }
    </div>
  );
}

export default AccountsExecutivePopups;
