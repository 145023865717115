import React from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import { connect } from 'react-redux';
import { dynamicAssetTypesAsObjSelector } from '../../../store/selectors';
import { CLIK_OS_CATEGORY_MAPPING } from '../../../constants';
import { displayCurrencyFormat, fetchBaseTypeByAssetType } from '../../../utils/utils';
import './DocExtractionRentalIncomeChart.scss';

function DocExtractionRentalIncomeChart({ data, assetType, dynamicAssetTypes }) {
  return (
    <div className="netRentalIncomeChart rentalIncomePieChartListing">
      <LineChart
        width={460}
        height={400}
        data={data}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" angle={-45} textAnchor="end" interval={0} height={50} />
        <YAxis />
        <Tooltip formatter={(value) => displayCurrencyFormat(value)} />
        <Legend />
        <Line type="monotone" stroke="#3F66F1" dataKey={CLIK_OS_CATEGORY_MAPPING[fetchBaseTypeByAssetType(assetType, dynamicAssetTypes)].RENTAL_INCOME} />
        <Line type="monotone" stroke="#D63423" dataKey={CLIK_OS_CATEGORY_MAPPING[fetchBaseTypeByAssetType(assetType, dynamicAssetTypes)].VACANCY_LOSS} />
        <Line type="monotone" stroke="#008F7A" dataKey="Net Rental Income" />
      </LineChart>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
})
export default connect(mapStateToProps)(DocExtractionRentalIncomeChart);
