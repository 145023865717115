

import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DocumentViewer from '../../app/DocumentViewer/DocumentViewer';
import DocExtractionComponent from '../../app/DocExtractionComponent/DocExtractionComponent';
import useWindowSize from '../../../hooks/useWindowSize';
import style from "./ExtractionPopupView.module.scss";

function ExtractionPopupView({ document, project }) {
  const [view, setView] = useState('original');
  const { screenHeight } = useWindowSize();

  function changeView(view) {
    setView(view);
  }

  return (
    <div className="vertical-section">
      <Container fluid className="vh-100 position-relative">
        <Row className="justify-content-center p-2 border-bottom mb-2 position-fixed w-100 bg-white" style={{ zIndex: 1 }}>
          <Col xs="auto" className="pr-0">
            <Button size="sm" className="btn-xs" variant={view === 'original' ? 'primary' : 'secondary'} onClick={() => changeView('original')}>
  Original
                Document
            </Button>
          </Col>
          <Col xs="auto">
            <Button size="sm" className="btn-xs" variant={view === 'converted' ? 'primary' : 'secondary'} onClick={() => changeView('converted')}>
  Converted
                Sheet
            </Button>
          </Col>
        </Row>
        <Row className={style.extractionPopupView_section}>
          <div className="splitter-layout">
            <div className="layout-pane layout-pane-primary">
              <div className="position-relative">
                <div className="vertical-section position-absolute w-100" style={{ visibility: view === 'converted' ? 'visible' : 'hidden' }}>
                  <DocExtractionComponent 
                    showAffix={false} showToolbar={false} onlySource sheetHeight={{ height: `${screenHeight - 30}px` }} 
                  />
                </div>
                <div className="vertical-section position-absolute w-100" style={{ visibility: view === 'original' ? 'visible' : 'hidden' }}>
                  <DocumentViewer document={document} project={project} className="w-100" viewerHeight="100vh" sheetHeight={{ height: `${screenHeight - 30}px` }} />
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}


export default ExtractionPopupView;
