import React, { useState, useCallback } from 'react';
import { Field } from 'formik';
import { faEye as eyeIcon } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash as eyeSlashIcon } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { InputGroup } from 'react-bootstrap';
import IconButton from '../../core/Button/IconButton';
import style from './PasswordViewer.module.scss';
import { SpriteLockIcon } from '../../core/SpriteIcon/SpriteIcon';

function PasswordViewer({
  id, name, placeholder = "", showIcon = true, ...args
}) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  return (
    <>
      <InputGroup className="position-relative d-flex justify-content-end">
        <div className="loginFormIcon passwordIcon">
          {showIcon && <SpriteLockIcon />}
        </div>
        <Field {...args} id={id} className={`form-control ${style.formControl}`} name={name} type={showPassword ? 'text' : 'password'} placeholder={name === 'newPassword' ? 'New Password' : placeholder} />
        <InputGroup.Append className={`form-control showPasswordIcon ${style.inputGroupAlign}`}>
          <IconButton
            onClick={toggleShowPassword}
            variant="link"
            icon={showPassword ? eyeSlashIcon : eyeIcon}
          />
        </InputGroup.Append>
      </InputGroup>
    </>
  );
}

export default PasswordViewer;
