import React, { useEffect } from 'react';
import { withServices } from 'reaf';
import FormModal from '../FormModal/FormModal';
import ProjectFormComponentHook from '../ProjectForm/ProjectFormComponentHook';
import useModalForm from '../../../hooks/useModalForm';

const UpdateProjectFormModal = ({
  show, onHide, project, company, templateService, onEditSuccess
}) => {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  useEffect(() => {
    templateService.loadCompanyTemplatesList(company.uuid);
  }, [company.uuid]);
  return (
    <FormModal 
      show={show} 
      onSubmit={onSubmit} 
      isSubmitting={isSubmitting} 
      onHide={onHide} 
      title={`Edit Project - ${project?.name}`}
    >
      <ProjectFormComponentHook 
        project={project} 
        company={company} 
        onSubmitSuccess={(project) => {onHide(); onEditSuccess && onEditSuccess(project)}} 
        bindSubmission={bindSubmission} 
      />
    </FormModal>
  );
};

export default withServices('templateService')(UpdateProjectFormModal);
