import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  templateModels: {}
};

const [
  { setTemplateModels, addTemplateModel, removeModelFromTemplate },
  reducer
] = createDuck('autouw/templateModels', {
  _initialState: initialState,
  setTemplateModels: templateModels => state => ({
    ...state,
    templateModels: normalize(templateModels, 'uuid')
  }),
  addTemplateModel: templateModel => state => ({
    ...state,
    templateModels: {
      ...state.templateModels,
      [templateModel.uuid]: {
        ...state.templateModels[templateModel.uuid],
        ...templateModel
      }
    }
  }),
  removeModelFromTemplate: templateModelUuid => state => {
    const templateModelList = { ...state.templateModels };
    Reflect.deleteProperty(templateModelList, templateModelUuid);
    return ({
      ...state,
      templateModels: templateModelList
    });
  }
});

export {
  setTemplateModels,
  addTemplateModel,
  removeModelFromTemplate
};

export default reducer;
