import { createAction, handleActions } from 'reaf';

const INCREMENT = 'raf/counter/INCREMENT';
const DECREMENT = 'raf/counter/DECREMENT';

const initialState = {
  count: 0
};

export const increment = createAction(INCREMENT);
export const decrement = createAction(DECREMENT);

const reducer = handleActions(initialState, {
  [INCREMENT]: state => ({ count: state.count + 1 }),
  [DECREMENT]: state => ({ count: state.count - 1 })
});

export default reducer;
