import { createDuck } from 'reaf';

const initialState = {
  show: false,
  type: '',
  message: '',
  position: 'bottom',
  delay: 3000,
  autohide: true
};

const [
  { showToastr, hideToastr },
  reducer
] = createDuck('autouw/notificationToastr', {
  _initialState: initialState,

  showToastr: (type, message, position, delay, autohide) => state => ({
    ...state,
    show: true,
    type,
    message,
    position,
    delay,
    autohide
  }),

  hideToastr: () => state => ({
    ...state,
    ...initialState
  })
});

export {
  showToastr,
  hideToastr
};

export default reducer;
