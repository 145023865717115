import React from 'react';
import AccountsExecutiveDashboardPage from '../AccountsExecutiveDashboardPage/AccountsExecutiveDashboardPage';

function AccountsExecutivePage() {
  return (
    <>
      <AccountsExecutiveDashboardPage>
        <h1 className="clikai-title my-3 border-bottom pb-2">
       Welcome Accounts Executive!
        </h1>
      </AccountsExecutiveDashboardPage>
    </>
  );
}

export default AccountsExecutivePage;
