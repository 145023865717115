import { sheetIndex } from '../../../../constants';
import BaseEvent from '../../../core/Spreadjs/Events/BaseEvent';
import { SOURCE_SHEET_NAME, TARGET_SHEET_NAME } from '../DataSpreadSheet';

const { targetSheetIndex } = sheetIndex;

export class OSDragFillBlockEvent extends BaseEvent {
  handle(OSSpreadSheet, _, args) {
    const { spreadsheet, datasheet } = OSSpreadSheet;
    const {
      fillRange: {
        row, rowCount, col: selectedCol, colCount: selectedColCount
      }
    } = args;
    const activeSheetIndex = spreadsheet.getActiveSheetIndex();
    const headIndex = datasheet.customSearchColumnWithValue(targetSheetIndex, 0, 'Head').foundColumnIndex;
    const categoriesColIndex = datasheet.customSearchColumnWithValue(targetSheetIndex, 0, 'Category').foundColumnIndex;
    const subCategoriesColIndex = datasheet.customSearchColumnWithValue(targetSheetIndex, 0, 'Sub Category').foundColumnIndex;
    const previousCategoryValues = spreadsheet.getColumnValues(targetSheetIndex, row, rowCount, categoriesColIndex);
    const previousSubCategoryValues = spreadsheet.getColumnValues(targetSheetIndex, row, rowCount, subCategoriesColIndex);
    const isCopyCategory = selectedColCount > 1;
    const isCopySubCategory = selectedColCount > 2;
    const copyCategoryValue = spreadsheet.getColumnValues(targetSheetIndex, row - 1, 1, categoriesColIndex)[0];
    const copySubCategoryValue = spreadsheet.getColumnValues(targetSheetIndex, row - 1, 1, subCategoriesColIndex)[0];
    const previousHeadValues = spreadsheet.getColumnValues(targetSheetIndex, row, rowCount, headIndex);
    const [sourceSheetData, targetSheetData] = datasheet.getSheetSpecificData([SOURCE_SHEET_NAME, TARGET_SHEET_NAME]);
    const targetSheetColCount = datasheet.getColumnCount(targetSheetData);
    const sourceSheetColCount = datasheet.getColumnCount(sourceSheetData);
    const colCount = {
      targetSheetColCount,
      sourceSheetColCount
    };
    const undoState = {
      row,
      rowCount,
      selectedCol,
      isCopyCategory,
      selectedColCount,
      copyCategoryValue,
      isCopySubCategory,
      previousHeadValues,
      categoriesColIndex,
      copySubCategoryValue,
      previousCategoryValues,
      previousSubCategoryValues,
      categoryCol: categoriesColIndex,
      subCategoryCol: subCategoriesColIndex
    };

    setTimeout(() => OSSpreadSheet.workbook.commandManager().execute({
      cmd: 'osDragFillCommand', isUndo: false, activeSheetIndex, undoState, colCount, headIndex
    }), 0);

  }
}

export const osDragFillBlockEvent = OSDragFillBlockEvent.get();

