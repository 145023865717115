import React, { useEffect } from 'react';
import './GoogleSignUpButton.scss';

const GoogleSignUpButton = ({ onSuccess, onFailure }) => {
  useEffect(() => {
    window.gapi.load('auth2', () => {
      window.gapi.signin2.render('google-signin', {
        scope: 'profile email',
        width: 240,
        height: 50,
        longtitle: true,
        theme: 'light',
        onsuccess: onSuccess,
        onfailure: onFailure
      });
      setTimeout(() => {
        try {
          document.querySelector('.google-btn-wrapper .abcRioButtonContents > span').innerText = 'Sign up with Google';
        } catch (error) {
          console.log(error);
        }
      }, 0);
    });
  }, []);

  return (
    <div className="g-signin2" id="google-signin" data-onsuccess="onSignIn" data-theme="dark" data-text="Signup" />
  );
};

export default GoogleSignUpButton;