import React from 'react';
import ProjectList from '../../app/ProjectList/ProjectList';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function SuperAdminProjectPage({ queries }) {
  return (
    <SuperAdminDashboard>
      <ProjectList queries={queries} />
    </SuperAdminDashboard>
  );
}

export default SuperAdminProjectPage;
