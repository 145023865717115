// eslint-disable-next-line import/no-cycle
import { TARGET_SHEET, TARGET_SHEET_NAME } from "../../../app/DocExtractionComponent/DataSpreadSheet";
import BaseEvent from "./BaseEvent";

export default class ColumnChangedEvent extends BaseEvent {
	handle(datasheet, _, args) {
		const {
			sheetName, col, propertyName
		} = args;
		const amountColIndex = datasheet.customSearchColumnWithValue(TARGET_SHEET, 0, 'Amount').foundColumnIndex;
		const adjustmentColIndex = datasheet.customSearchColumnWithValue(TARGET_SHEET, 0, 'Adjustment').foundColumnIndex;
		const columnIndex = adjustmentColIndex !== -1 ? adjustmentColIndex : amountColIndex;
		if ((sheetName === TARGET_SHEET_NAME && datasheet.isRentRollBaseType) && propertyName === 'deleteColumns') {
			setTimeout(() => datasheet.props.documentRef.rentRollEvents(), 0);
		}
		if (datasheet.isOSBaseType) {
			if (sheetName === TARGET_SHEET_NAME && propertyName === 'addColumns') {
				datasheet.props.documentRef.setCellFormatInUSNumber();
				datasheet.props.documentRef.operatingStatementEvents();
			} if (sheetName === TARGET_SHEET_NAME && col >= columnIndex) {
				datasheet.props.documentRef.operatingStatementEvents();
			}
		}
	}
}