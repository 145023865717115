import React, { useCallback } from 'react'
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { validateRequiredField } from '../../../utils/utils';

export default function DocExtractionFindModule({ onSearchQuery, onClose }) {
  
  const onSubmit = useCallback((...args) => {
    const [formValue] = args;
    onSearchQuery(formValue);
  }, [onSearchQuery]);

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        keyword: '',
      }}
      render={({
        isValid,
      }) => (
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Field
                  name="keyword"
                  placeholder="Find in sheet"
                  validate={validateRequiredField('keyword')}
                />
                <button
                  type="submit"
                  disabled={!isValid}
                  className="btn btn-light ml-1">
                    <FontAwesomeIcon icon={faAngleDown}/>
                </button>
                <button type="button" className="btn btn-light ml-1" onClick={onClose}>                
                  <FontAwesomeIcon icon={faTimes}/>
                </button>
              </div>
            </div>
          </Form>
        )}
    />
  )
}
