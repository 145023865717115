import React from 'react';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import DocumentsList from '../../app/DocumentsList/DocumentsList';

function SuperAdminDocumentsPage({ queries }) {
  return (
    <SuperAdminDashboard>
      <DocumentsList queries={queries} />
    </SuperAdminDashboard>
  );
}

export default SuperAdminDocumentsPage;
