import React from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import IconButton from '../../core/Button/IconButton';
import ItemWithContextMenu from '../ItemTile/ItemWithContextMenu';
import ChargeCodeConfigContextMenuComponent from './ChargeCodeConfigContextMenuComponent';
import style from './ChargeCodeConfigContextMenu.module.scss';

const ChargeCodeConfigContextMenu = ({}) => (
  <div className={`${style.downloadButton}`}>
    <ItemWithContextMenu
      openOnClick
      TileComponent={() => (
        <IconButton
          variant="outline-secondary"
          style={{
            width: '26px',
            height: '26px'
          }}
          icon={faEllipsisV}
        />
      )}
      ContextMenuComponent={({ hideContextMenu }) => (
        <ChargeCodeConfigContextMenuComponent
          hideContextMenu={hideContextMenu}
        />
      )}
    />
  </div>
);

export default ChargeCodeConfigContextMenu;
