import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

function BulkProjectJsonComponent({
  status,
  isSubmitting,
  bindSubmission,
  submitForm,
  projectOptions,
  values,
  selectedCompany,
  setSelectedCompany,
  companiesOptions,
  ...form
}) {
  const [selectedProjects, setSelectedProjects] = useState(values.selectedProjects || []);

  bindSubmission(submitForm, isSubmitting);

  return (
    <div>
      {status && status.error && (
        <div className="alert alert-danger">
          <span>{status.error}</span>
        </div>
      )}
      <Form>
        <div className="form-group">
          <label htmlFor="companies">Select Company</label>
            <ReactSelect
              value={selectedCompany}
              name="companies"
              onChange={(selectedOptions) => {
                setSelectedCompany(selectedOptions);
                form.setFieldValue('companies', selectedOptions);
              }}
              options={companiesOptions}
            />
          <FieldErrorMessage name="companies" />
        </div>
        <div className="form-group">
          <label htmlFor="selectedProjects">Projects</label>
            <ReactSelect
              isMulti
              value={selectedProjects}
              name="selectedProjects"
              onChange={(selectedOptions) => {
                setSelectedProjects(selectedOptions);
                form.setFieldValue('selectedProjects', selectedOptions);
              }}
              options={projectOptions}
            />
          <FieldErrorMessage name="selectedProjects" />
        </div>
      </Form>
    </div>
  );
}

export default BulkProjectJsonComponent;