import React from 'react';
import { connect } from 'react-redux';
import { currentDocumentSelector, currentProjectSelector, routerStateSelector } from '../../../store/selectors';
import ExtractionPageView from './ExtractionPageView';
import ExtractionPopupView from './ExtractionPopupView';

function ExtractionPage({
  document, project, isPopup
}) {
  if (isPopup) {
    return <ExtractionPopupView project={project} document={document} />;
  }
  return <ExtractionPageView project={project} document={document} />;
}

const mapStateToProps = state => ({
  project: currentProjectSelector(state),
  document: currentDocumentSelector(state),
  isPopup: routerStateSelector(state).queries.popup === 'true'
});

export default connect(mapStateToProps)(ExtractionPage);
