import React from 'react';
import {
  Form
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { ticketMetaDataSelector } from '../../../store/selectors';

const TicketStatus = ({
  ticket, ticketService, onTicketUpdate, ticketMetaData
}) => {
  const statusMetaData = ticketMetaData.status;
  return (
    <Form.Control
      value={ticket.status} as="select" size="sm" onChange={(e) => {
        ticketService.updateTicketStatus(ticket.uuid, e.target.value);
        onTicketUpdate && onTicketUpdate({ ...ticket, status: e.target.value });
      }} onClick={(e) => {
        e.stopPropagation();
      }}>
        <option value="" disabled selected></option>
      {
        Reflect
          .ownKeys(statusMetaData)
          .map(statusKey => <option value={statusKey} key={statusKey}>{statusKey}</option>)
      }
    </Form.Control>
  );
};

const mapStateToProps = (state) => ({
  ticketMetaData: ticketMetaDataSelector(state)
});

export default connect(mapStateToProps)(withServices('ticketService')(TicketStatus));
