import { useState } from 'react';

function useModalForm() {
  let submitForm;

  const [isSubmitting, setIsSubmitting] = useState(false);

  function bindSubmission(sf, submitting) {
    submitForm = sf;
    setIsSubmitting(submitting);
  }

  function onSubmit() {
    submitForm && submitForm();
  }

  return [isSubmitting, bindSubmission, onSubmit];
}

export default useModalForm;
