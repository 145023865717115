import React, { useCallback, useState } from 'react';
import ProspectPopups from '../ProspectPopups/ProspectPopups';

const defaultLabel = (
  <span className="text-primary" style={{ cursor: 'pointer' }}>Send Invoice</span>
);
const ProspectInvoice = ({ label = defaultLabel, prospect }) => {
  const [showProspectInvoiceForm, setShowProspectInvoiceForm] = useState(false);
  const onEdit = useCallback(() => {
    setShowProspectInvoiceForm(true);
  }, []);

  return (
    <>
      <div onClick={() => onEdit()}>
        {label}
      </div>
      <ProspectPopups 
        showCreateProspectInvoice={showProspectInvoiceForm} 
        prospect={prospect} onHide={() => setShowProspectInvoiceForm(false)} 
      />
    </>
  );
};

export default ProspectInvoice;
