import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  projectList: {
    projects: {},
    isFetching: false,
    error: ''
  },
  documentsSummary: {}
};

const [
  {
    setProjects, addProject, updateProject, deleteProject, setDocumentsSummary
  },
  reducer
] = createDuck('autouw/projects', {
  _initialState: initialState,

  setDocumentsSummary: (project, summary) => state => ({
    ...state,
    documentsSummary: {
      ...state.documentsSummary,
      [project.uuid]: summary
    }
  }),

  setProjects: projects => state => ({
    ...state,
    projectList: {
      ...state.projectList,
      projects: normalize(projects, 'uuid'),
      isFetching: false,
      error: ''
    }
  }),

  addProject: project => state => ({
    ...state,
    projectList: {
      ...state.projectList,
      projects: {
        [project.uuid]: project,
        ...state.projectList.projects
      }
    }
  }),

  updateProject: project => state => ({
    ...state,
    projectList: {
      ...state.projectList,
      projects: {
        ...state.projectList.projects,
        [project.uuid]: project
      }
    }
  }),

  deleteProject: uuid => (state) => {
    const projects = {
      ...state.projectList.projects
    };
    Reflect.deleteProperty(projects, uuid);
    return {
      ...state,
      projectList: {
        ...state.projectList,
        projects
      }
    };
  }
});

export {
  setProjects, addProject, updateProject, deleteProject, setDocumentsSummary
};

export default reducer;
