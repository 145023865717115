import React from 'react';
import {
  Card
} from 'react-bootstrap';
import { connect } from 'react-redux';
import SsoConfigForm from '../SsoConfigForm/SsoConfigForm';
import { loggedInCompanySelector } from '../../../store/selectors';
import pageStyle from './SsoConfig.module.scss';

const SsoConfig = ({ style, company }) => (
  <>
    <div className={`container-fluid ${style.pageOuterSection}`}>
    <div className="row">
        <div className={`col-12 d-flex border-bottom ${style.sectionHeader}`}>
          <h3>Single Sign On Configuration</h3>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Card className={pageStyle.cardWrapper} >
          <Card.Body>
            <SsoConfigForm company={company} />
          </Card.Body>
        </Card>
      </div>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  company: loggedInCompanySelector(state)
});

export default connect(mapStateToProps)(SsoConfig);
