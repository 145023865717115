import React, { useState, useEffect, useCallback } from 'react';
import ReactSelect from 'react-select';
import { startCase } from 'lodash';
import './ChargeCodeOptions.scss';
import messages from '../../../../../locales/en-US';
import { checkArrayIncludesValue, checkChildKeyHasValue } from '../../../../utils/utils';
import { ALLOWED_RETAIL_CC_CATEGORY } from '../../../../constants';

function ChargeCodeOptions({
  chargeCodeConfigData, chargeCode, staticChargeCodes, chargeCodeUnits, setCurrentChargeCodeCategory,
  ccConfig, setCcConfig, onMenuOpen, onMenuClose, toastrService
}) {
  const [currentChargeCode, setCurrentChargeCode] = useState({});
  const [currentChargeCodeUnit, setCurrentChargeCodeUnit] = useState({ 
    label: chargeCodeConfigData[chargeCode]?.unit, 
    value: chargeCodeConfigData[chargeCode]?.unit 
  })


  useEffect(() => {
    const retailChargeCode = chargeCodeConfigData[chargeCode];
    // eslint-disable-next-line no-nested-ternary
    const currentChargeCodeValue = retailChargeCode?.label ? retailChargeCode?.label : retailChargeCode?.code ? retailChargeCode?.code : '';
    if (currentChargeCodeValue) { 
      setCurrentChargeCode({ value: currentChargeCodeValue, label: currentChargeCodeValue });
    } else {
      setCurrentChargeCodeUnit({ value: '', label: '' });
    }
  }, [chargeCodeConfigData, chargeCode]);

  const isCategoryNotExist = useCallback((category, chargeCode, ccConfig) => {
    let isCategoryAvailable = false;
    if (!!category && !checkArrayIncludesValue(ALLOWED_RETAIL_CC_CATEGORY, category)) {
      isCategoryAvailable = checkChildKeyHasValue(ccConfig, 'label', category);
    }
    if (!isCategoryAvailable) {
      const retailChargeCode = { 
        ...ccConfig,  
        [chargeCode] : { ...ccConfig[chargeCode], label: category, code: category } 
      };
      setCcConfig(retailChargeCode)
    }
    return isCategoryAvailable;
  },[])

  const onChargeCodeChange = useCallback((label) => {
    const {
      total, colIndex, key, category: { conversion, originalName }
    } = chargeCodeConfigData[chargeCode];
    if (!isCategoryNotExist(label, chargeCode, ccConfig)) {
      const unit = label ? currentChargeCodeUnit.label : ''
      setCurrentChargeCode({
        label, total, unit, key, colIndex, category: { code: label, conversion, originalName }
      });
      setCurrentChargeCodeCategory({
        label, unit, total, key, colIndex, category: { code: label, conversion, originalName }
      });     
      setCurrentChargeCodeUnit({ label: unit, value: unit });
    } else {
      toastrService.error(messages.toastMessage.CHARGE_CODE_ALREADY_EXIST, 'top')
    }
  }, [currentChargeCode, setCurrentChargeCodeCategory, currentChargeCodeUnit]);

  const onChargeCodeUnitChange = useCallback((unit) => {
    const { label } = currentChargeCode;
    if (label) {
      setCurrentChargeCode({
        ...chargeCodeConfigData[chargeCode], ...{ unit: unit.label, label }
      });
      setCurrentChargeCodeCategory({
        ...chargeCodeConfigData[chargeCode], ...{ unit: unit.label, label }
      });
      setCurrentChargeCodeUnit(unit);
    } else {
      !!unit.label &&  toastrService.error(messages.toastMessage.CHARGE_CODE_SELECT_ERROR, 'top');
      setCurrentChargeCodeUnit('');
    }
  }, [currentChargeCode, setCurrentChargeCodeCategory, currentChargeCodeUnit])
  
  return (
    <table>
      <tbody>
      <tr className="selectChargeCodeWidthOptionDrop">
        <td>
          <ReactSelect
            placeholder="Select Charge Code Category"
            classNamePrefix="react-select chargeCodeSelect"
            value={currentChargeCode}
            maxMenuHeight={200}
            menuPosition="fixed"
            menuPlacement="auto"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            options={staticChargeCodes.map(category => ({ value: startCase(category), label: startCase(category) }))}
            onChange={({ label }) => onChargeCodeChange(label)}
          />
        </td>
        <td>
        <ReactSelect
            classNamePrefix="react-select chargeCodeSelect"
            value={currentChargeCodeUnit}
            maxMenuHeight={200}
            menuPosition="fixed"
            placeholder=""
            menuPlacement="auto"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            options={chargeCodeUnits.map(unit => ({ value: startCase(unit), label: startCase(unit) }))}
            onChange={(unit) => onChargeCodeUnitChange(unit)}
          />
        </td>
      </tr>
      </tbody>
    </table>
  );
}

export default ChargeCodeOptions;