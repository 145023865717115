import Button from 'react-bootstrap/Button';
import React from 'react';
import style from './LightButton.module.scss';

function LightButton({ children, active, customClassName = '', ...rest }) {
  return (
    <Button
      disabled={active}
      {...rest}
      variant="info"
      className={`d-block w-100 rounded-0 ${!customClassName ? style.btnInfo : customClassName}`}
    >
      {children}
    </Button>
  );
}

export default LightButton;
