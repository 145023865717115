import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import SwitchButton from '../../core/SwitchButton/SwitchButton';

function FeatureFlagFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
 
      <Form>
        <div className="form-group">
          <label htmlFor="featureName">Feature Flag Name</label>
          <Field id="featureName" className="form-control" name="featureName" placeholder="" type="text" />
          <FieldErrorMessage name="featureName" />
        </div>
        <div className="form-group">
          <label htmlFor="isActive">Is Active</label>
          <SwitchButton
            isActive={values.isActive}
            onChange={() => setFieldValue('isActive', !values.isActive)}
          />
        </div>
      </Form>
    </div>
  );
}

export default FeatureFlagFormComponent;
