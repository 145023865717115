import { createDuck } from 'reaf';

const initialState = {
  appConfigurationList: []
};

const [
  { setAppConfigurationList },
  reducer
] = createDuck('autouw/AppConfigurationList', {
  _initialState: initialState,

  setAppConfigurationList: appConfigurationList => state => ({
    ...state,
    appConfigurationList
  })
});

export { setAppConfigurationList };

export default reducer;
