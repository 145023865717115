/* eslint-disable no-prototype-builtins */
/* eslint-disable max-depth */
/* eslint-disable import/no-cycle */
import { isNil } from "lodash";
import { spreadColorConfig, spreadRangeChangedActionCode } from "../../../../constants";
import { TARGET_SHEET_NAME } from "../../../app/DocExtractionComponent/DataSpreadSheet";
import BaseEvent from "./BaseEvent";

export default class RangeChangedEvent extends BaseEvent {
  handle(datasheet, _, args) {
    const { sheet, sheetName, action, changedCells, row, col: startColumnIndex, colCount } = args;
    const { spreadsheet } = datasheet;

    if (sheetName === TARGET_SHEET_NAME && (!!changedCells.length && row > 0)) {
      if (action === spreadRangeChangedActionCode.clear) { // This code block will only execute on clear event
        spreadsheet.suspend();
        const workbookData = spreadsheet.getWorkbookData();
        const { columns } = workbookData.sheets[sheetName];
        for (let column = 0; column < colCount; column++) {
          const columnIndex = startColumnIndex + column;
          const columnHeaderName = sheet.getCell(0, columnIndex).value();
          if (columnHeaderName) {
            const foundColumnName = columns
            .filter(validCol => validCol)
            .find(column => column.headerName === columnHeaderName);
            if (foundColumnName && foundColumnName?.hasOwnProperty('dataType')) {
              if (foundColumnName.dataType !== 'string') { // ignoring valdation check on string type datatype
                changedCells.forEach(({ row, col }) => {
                  if (columnIndex === col) {
                    const cellValue = sheet.getCell(row, col).value();
                    const cellStyle = sheet.getStyle(row, col) || {};
                    if (isNil(cellValue) && cellStyle?.hasOwnProperty('foreColor')) {
                      if (cellStyle.foreColor === spreadColorConfig.invalidCellColor) {
                        datasheet.highlightCellValue(sheet, row, col, undefined);
                        datasheet.props.onToast({ message: '', autohide: true });
                      }
                    }
                  }
                });
              }
            }
          }
        }
        if (datasheet.isOSBaseType) {
          datasheet.props.documentRef.onHighlightAmountCell(startColumnIndex); // Always call this method at last to highlight the diffrence value between amount & total     
        }
        spreadsheet.resume();
      }
    }
  }

}