import React from 'react';
import { Card } from 'react-bootstrap';
import style from './ChangePasswordFormComponent.module.scss';

function ChangePasswordFormComponent({ cgOriginUrl }) {
  return (
    <div className={`${style.changePasswordOuter} passwordGroupRow`}>
      <div className="row">
        <div className={`col-12 d-flex border-bottom ${style.sectionHeader}`}>
          <h3>Change Password</h3>
        </div>
      </div>
      <Card className={`${style.formWrapper}`}>
        <Card.Body className="py-0 lh-lg">
          <p className="mb-2">You can change your password by signing into <b>Clik Gateway</b> using the button below:</p>
          <a href={`${cgOriginUrl}/user/settings/change-password`} target="_blank" className="py-1 btn btn-primary btn-sm">Change Password</a>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ChangePasswordFormComponent;
