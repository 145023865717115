import React, { useCallback } from 'react';
import { faTable } from '@fortawesome/free-solid-svg-icons/faTable';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { withServices } from 'reaf';
import IconButton from '../../core/Button/IconButton';
import style from './RentRollConfigHeader.module.scss';
import { rentRollAffixTabViewName } from '../../../constants';
import { AffixConfigViewChanged } from '../../../constants/eventTrackerMessage';

const { TABLE_VIEW, CHART_VIEW, CONFIG_VIEW, RENOVATION_VIEW, AFFORDABLE_VIEW } =
  rentRollAffixTabViewName;

function RentRollConfigHeader({
  title,
  summaryTitle,
  customTitle,
  activeViewName,
  downloadTitle = 'Download',
  isAcquisition,
  isCustomOptionVisible = false,
  hideChartSwitcher = false,
  onTabViewChange,
  onDownload,
  eventTrackerService
}) {
  const onViewChange = useCallback(
    (activeViewName) => {
      onTabViewChange(activeViewName);
      eventTrackerService.track(AffixConfigViewChanged, {
        view: activeViewName
      });
    },
    [activeViewName]
  );

  return (
    <>
      <div className={`row align-items-center d-flex ${style.sectionHeader}`}>
        <div className={`pl-2 flex-grow-1 ${style.panelHeader}`}>
          <h3 className="small-heading mb-0">{title}</h3>
        </div>
        {!hideChartSwitcher && (
          <div className={`ml-auto ${style.viewIcons}`}>
            <IconButton
              icon={faTable}
              title={summaryTitle || "Unit Mix Summary"}
              onClick={() => onViewChange(TABLE_VIEW)}
              className={`${activeViewName === TABLE_VIEW ? style.acitve : ''}`}
            />
            {isCustomOptionVisible && (
              <IconButton
                icon={faCogs}
                title={customTitle}
                onClick={() => onViewChange(CONFIG_VIEW)}
                className={`${
                  activeViewName === CONFIG_VIEW ? style.acitve : ''
                }`}
              />
            )}
            {isAcquisition && (
              <IconButton
                icon={faCogs}
                onClick={() => onViewChange(RENOVATION_VIEW)}
                className={`${
                  activeViewName === RENOVATION_VIEW ? style.acitve : ''
                }`}
                title="Renovations"
              />
            )}
            {isAcquisition && (
              <IconButton
                icon={faCogs}
                onClick={() => onViewChange(AFFORDABLE_VIEW)}
                className={`${
                  activeViewName === AFFORDABLE_VIEW ? style.acitve : ''
                }`}
                title="Lease Type"
              />
            )}
            <IconButton
              icon={faChartLine}
              onClick={() => onViewChange(CHART_VIEW)}
              className={`${activeViewName === CHART_VIEW ? style.acitve : ''}`}
              title="Analysis"
            />
          </div>
        )}
        {onDownload && (
          <div className={`ml-auto ${style.viewIcons}`}>
            <IconButton
              icon={faFileDownload}
              title={downloadTitle}
              onClick={() => onDownload()}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default withServices('eventTrackerService')(RentRollConfigHeader);
