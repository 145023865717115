/* eslint-disable prefer-reflect */
/* eslint-disable no-useless-call */
import React from 'react';

export function useBindCallback(func, ...params) {
  return React.useCallback(() => func.call(null, ...params), [func, ...params]);
}

export function useBindCallbackWithEvent(func, ...params) {
  return React.useCallback((e) => func.call(null, e, ...params), [func, ...params]);
}
