import React, { useMemo } from 'react';
import { Form } from 'formik';
import { toLower } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { Button, Col, Row } from 'react-bootstrap';
import FormikSelectField from '../../core/FormikSelectField/FormikSelectField';
import FormikTextArea from '../../core/FormikTextArea/FormikTextArea';
import { SUPPORT_TYPES } from '../../../constants';
import style from './NeedSupportFormComponent.module.scss';
import { appendClassName, validateDocumentFile } from '../../../utils/utils';

const validExtensions = ['pdf', 'xls', 'xlsx', 'png', 'jpeg', 'jpg'];

const DropArea = () => <div className={style.dropArea}>Drop files here</div>;

const SelectedFiles = ({ files, onRemoveFile }) => files.length === 0
    ? null
    : files.map((selectedFile, index) => (
        <div className="form-group file-badge" key={selectedFile.name ? selectedFile.name : ''}>
          <Row style={{ lineHeight: '36px', marginLeft: '0' }}>
            <label xs={1}>File:</label>
            <Col className="file-name">
              <span>{selectedFile.name}</span>
              <Button
                className="file-button"
                variant="link"
                onClick={() => onRemoveFile(selectedFile, index)}
              >
                X
              </Button>
            </Col>
          </Row>
        </div>
      ));

const MultipleFileUploader = ({
  getRootProps,
  getInputProps,
  title,
  isDragActive,
  validExtensions,
  allowedFileSize,
}) => {
  const cachedValidExtentions = useMemo(
    () => (validExtensions ? validExtensions.map(toLower) : undefined),
    [validExtensions]
  );
  return (
    <div
      {...getRootProps({
        className: appendClassName(
          'form-group text-center p-2 margin-zero-bottom bg-light rounded',
          style.isDragging
        ),
      })}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <DropArea />
      ) : (
        <>
          <h1>Drop {title} here</h1>
          <p className={style.actionButtonInfo}>
            Or use{' '}
            <strong className="text-primary cursor-pointer">
              Browse Files
            </strong>
          </p>

          {cachedValidExtentions ? (
            <small>
              Please upload{' '}
              <span className="text-primary">
                {cachedValidExtentions.join(', ')}
              </span>{' '}
              file formats.
            </small>
          ) : (
            ''
          )}
          {allowedFileSize ? (
            <small>
              {' '}
              Allowed File Size{' '}
              <span className="text-primary">{allowedFileSize}</span> MB
            </small>
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default function NeedSupportFormComponent({
  status,
  isSubmitting,
  bindSubmission,
  submitForm,
  onAttachmentChange,
  selectedAttachments,
}) {
  const onFileChange = React.useCallback((files) => {
    try {
      files.forEach((file) => validateDocumentFile(file, 10, validExtensions));
      onAttachmentChange(files);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(error.message);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => onFileChange([...selectedAttachments, ...files]),
    noClick: false,
    noKeyboard: true,
  });

  bindSubmission(submitForm, isSubmitting);
  return (
    <>
      <div>
        <div>
          {status && status.error && (
            <div className="alert alert-danger">
              <p>{status.error}</p>
            </div>
          )}

          <Form>
            <FormikSelectField name="type" label="Type">
              {Reflect.ownKeys(SUPPORT_TYPES).map((issueType) => (
                <option key={issueType} value={issueType}>
                  {SUPPORT_TYPES[issueType].label}
                </option>
              ))}
            </FormikSelectField>
            <FormikTextArea name="description" label="Description" />
          </Form>
        </div>
      </div>

      <>
        <SelectedFiles
          files={selectedAttachments}
          onRemoveFile={(file, index) => {
            selectedAttachments.splice(index, 1);
            onAttachmentChange([...selectedAttachments]);
          }}
        />
        <MultipleFileUploader
          getRootProps={getRootProps}
          isDragActive={isDragActive}
          getInputProps={getInputProps}
          onFilesChange={(files) => {
            onFileChange([...selectedAttachments, ...Array.from(files)]);
          }}
          title={selectedAttachments.length > 0 ? 'more files' : 'files'}
          validExtensions={validExtensions}
          allowedFileSize="10"
        />
      </>
    </>
  );
}
