import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

function ProspectInvoiceFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <Form>
        <h3>Confirm Invoice Details</h3>
        <hr />

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="prospect.numberOfLicenses">Number of Licenses</label>
            <Field id="prospect.numberOfLicenses" className="form-control" name="prospect.numberOfLicenses" placeholder="1" type="number" required />
            <FieldErrorMessage name="prospect.numberOfLicenses" />
          </div>
          <br />

          <div className="form-group col-md-4">
            <label htmlFor="prospect.amountPerLicense">Amount Per License (USD)</label>
            <Field id="prospect.amountPerLicense" className="form-control" name="prospect.amountPerLicense" placeholder="500" type="text" required />
            <FieldErrorMessage name="prospect.amountPerLicense" />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="prospect.totalAmount">Total Amount (USD)</label>
            <Field
              id="prospect.totalAmount" className="form-control"
              name="prospect.totalAmount"
              value={values.prospect.numberOfLicenses * values.prospect.amountPerLicense} readOnly />
            <FieldErrorMessage name="prospect.totalAmount" />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ProspectInvoiceFormComponent;
