import React, { useState, useCallback } from 'react';
import { withServices } from 'reaf';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import DocumentTaggingForm from '../../app/DocumentTaggingForm/DocumentTaggingForm';
import {
  authSelector,
  currentDocumentSelector,
  currentProjectSelector, productTourSelector
} from '../../../store/selectors';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import DocumentViewer from '../../app/DocumentViewer/DocumentViewer';
import DocumentPageTour from './DocumentPageTour';
import DocumentsService from '../../../services/DocumentsService';
import { FILE_EXT_TYPES } from '../../../constants';
import TourIconButton from '../../app/IconButtons/TourIconButton';
import { markFirstTourDone } from '../../../store/productTour';
import style from './DocumentPage.module.scss';

function DocumentPageHeader({ project, document, onHelpClick }) {
  return (
    <Container
      fluid 
      className={`p-2 light-blue border-bottom-light-blue ${style.documentsHeader}`} style={{ zIndex: 1 }}
    >
      <Row className="align-items-center">
        <Col xs="auto">
          <Breadcrumbs items={[
            { route: 'projectPage', params: { projectUuid: project.uuid }, content: project.name },
            { to: '#', content: document.fileName }
          ]}
          />
        </Col>
        <Col />
        <Col sm="auto" className="pl-0 tourIconBtn">
          <TourIconButton id="ShowTourButton" onClick={onHelpClick} />
        </Col>
      </Row>
    </Container>
  );
}

function DocumentPage({
  project, document, startTour, store, router
}) {
  function onDocumentTaggingSuccess() {
    router.setRoute('projectPage', { projectUuid: project.uuid });
  }

  const [pageCount, setPageCount] = useState(0);
  const [sheetNames, setSheetNames] = useState([]);
  const [showTour, setShowTour] = useState(startTour);
  const onCloseTour = useCallback(() => {
    setShowTour(false);
    store.dispatch(markFirstTourDone('documentPage'));
  }, []);

  const docType = DocumentsService.getDocumentType(document);

  return (
    <LoggedInUserPage>
      <div className="vertical-section">
        <DocumentPageHeader document={document} project={project} onHelpClick={() => setShowTour(true)} />
        <div className="horizontal-section">
          <div className="vertical-section" style={{ width: '75%', flexBasis: '75%' }}>
            <DocumentViewer
              project={project}
              document={document}
              setPdfPageCount={setPageCount}
              setXLSheetNames={setSheetNames}
            />
          </div>

          <div style={{ flexGrow: 0, flexBasis: '25%', width: '25%' }} className="shadow">
            <DocumentTaggingForm
              project={project}
              document={document}
              onSuccess={onDocumentTaggingSuccess}
              pageCount={pageCount}
              sheetNames={sheetNames}
            />
          </div>
        </div>
      </div>
      <DocumentPageTour show={showTour} onClose={onCloseTour} showSplitting={FILE_EXT_TYPES.PDF === docType} />
    </LoggedInUserPage>
  );
}

const mapStateToProps = (state) => {
  const authState = authSelector(state);
  const tourState = productTourSelector(state);

  return {
    project: currentProjectSelector(state),
    document: currentDocumentSelector(state),
    startTour: authState.user && !authState.user.lastLogin && !tourState.documentPage.meta.firstTourDone
  };
};

export default connect(mapStateToProps)(withServices('documentsService', 'router', 'store')(DocumentPage));
