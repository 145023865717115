import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
import style from './DocExtractionSummaryTotal.module.scss';
import { DisplayAmountFormat } from '../../../utils/utils';

function DocExtractionSummaryTotal({ summaryTotal }) {
  const { SUMMARY_TOTAL, SUMMARY_NOI, SUMMARY_NCF } = summaryTotal;
  return (
    <div className={`${style.totalSummary} ${style.cardsFixBottom}`}>
      <CardDeck className={`${style.summary_totalCol}`}>
        {
          SUMMARY_TOTAL.map((item) => (
            <Card 
              key={item.head} 
              className={`rounded ${style.card_shadow} ${style.card_space}`} 
              style={{ background: item.color }}
            >
              <Card.Body className="p-1">
                <Card.Subtitle className={`mb-1 mt-0 text-muted text-with-ellipsis ${style.subtitle}`}>
                  Total
                  {' '}
                  {item.head}
                </Card.Subtitle>
                <Card.Title className={`m-0 ${style.title}`}>
                  <DisplayAmountFormat number={item.total} />
                </Card.Title>
              </Card.Body>
            </Card>
          ))
        }
      </CardDeck>
      <CardDeck className={`${style.summary_totalCol}`}>
        <Card className={`${style.card_shadow} ${style.card_space} ${style.amount}`}>
          <Card.Body className="p-1">
            <Card.Subtitle className={`mb-1 mt-0 text-muted text-with-ellipsis ${style.subtitle}`}>NOI</Card.Subtitle>
            <Card.Title className={`m-0 ${style.title}`}>
              <DisplayAmountFormat number={SUMMARY_NOI} />
            </Card.Title>
          </Card.Body>
        </Card>
        <Card className={`${style.card_shadow} ${style.card_space} ${style.amount}`}>
          <Card.Body className="p-1">
            <Card.Subtitle className={`mb-1 mt-0 text-muted text-with-ellipsis ${style.subtitle}`}>NCF</Card.Subtitle>
            <Card.Title className={`m-0 ${style.title}`}>
              <DisplayAmountFormat number={SUMMARY_NCF} />
            </Card.Title>
          </Card.Body>
        </Card>
      </CardDeck>
    </div>
  );
}

export default DocExtractionSummaryTotal;
