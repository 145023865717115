import React, { useMemo, useCallback } from 'react';
import { withServices } from 'reaf';
import { groupBy, get } from 'lodash';
import { connect } from 'react-redux';
import Board from '@lourenci/react-kanban';
import { ticketMetaDataSelector } from '../../../store/selectors';
import '@lourenci/react-kanban/dist/styles.css';

function TicketsKanbanBoard({
  ticketsList, ticketMetaData, ticketService, onTicketSelect
}) {
  const statusColumns = useMemo(() => {
    const { status } = ticketMetaData;
    const ticketsWithGroupedStatus = groupBy(ticketsList, (o) => (!o.status ? 'BACKLOG' : o.status));
    return Reflect.ownKeys(status)
      .filter(statusKey => status[statusKey].isVisibleInKanban)
      .map((statusKey) => {
        const ticketList = get(ticketsWithGroupedStatus, statusKey, []);
        return ({
          id: statusKey,
          title: `${status[statusKey].label} (${ticketList.length})`,
          cards: ticketList.map(ticket => ({
            id: ticket.uuid,
            title: `[${ticket.Company.name}] ${ticket.User.firstName} ${ticket.User.lastName}`,
            description: ticket.description
          }))
        });
      });
  }, [ticketMetaData.status, ticketsList]);

  const board = useMemo(() => {
    const board = {
      columns: statusColumns
    };
    return board;
  }, [ticketsList, statusColumns]);

  const onCardDragEnd = useCallback((board, card, source, destination) => {
    ticketService.updateTicketStatus(card.id, destination.toColumnId);
  }, []);

  return <Board onCardDragEnd={onCardDragEnd} initialBoard={board} />;
}

const mapStateToProps = (state) => ({
  ticketMetaData: ticketMetaDataSelector(state)
});

export default connect(mapStateToProps)(withServices('ticketService')(TicketsKanbanBoard));
