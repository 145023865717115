import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';

function LeaseTypeOptions({
  leaseTypeConfigData, leaseType, staticLeaseType, setCurrentLeaseTypeCategory, onMenuOpen, onMenuClose
}) {
  const [currentLeaseType, setCurrentLeaseType] = useState({});
  useEffect(() => {
    setCurrentLeaseType({ value: leaseTypeConfigData[leaseType], label: leaseTypeConfigData[leaseType] });
  }, [leaseTypeConfigData, leaseType]);

  return (
    <>
      <ReactSelect
          placeholder="Select Lease Type Category"
          classNamePrefix="react-select chargeCodeSelect"
          value={currentLeaseType}
          maxMenuHeight={200}
          menuPosition="fixed"
          menuPlacement="auto"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={staticLeaseType.map(category => ({ value: category, label: category }))}
          onChange={
            (selectedLeaseType) => {
              setCurrentLeaseType({ ...selectedLeaseType });
              setCurrentLeaseTypeCategory({ ...selectedLeaseType });
            }
          }
        />
    </>
  );
}

export default LeaseTypeOptions;
