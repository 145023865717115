import React from 'react';
import * as Yup from 'yup';
import { Form, Field, Formik } from 'formik';
import { withServices } from 'reaf';
import slugify from '../../../utils/slugify';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

const CompanySSOFormSchema = Yup.object().shape({
  isSSOEnabled: Yup.boolean(),
  slug: Yup.string().
    trim().
    transform(value => (value === '' ? undefined : value)).
    required('Please provide a valid Slug')
});

const SSOForm = function ({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue
}) {
  const setSlug = function (e) {
    setFieldValue('slug', slugify(e.target.value, { lower: true }));
  };
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>
      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <Form>
        <div
          className="form-group">
          <label
            htmlFor="isSSOEnabled"
            style={{ display: 'block', fontWeight: 500 }}>
            Allow SSO integration:
            <Field
              type="checkbox" name="isSSOEnabled"
              checked={values.isSSOEnabled} />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="slug">Slug : </label>
          <Field
            type="input"
            className="form-control"
            name="slug"
            onChange={setSlug} />
          <FieldErrorMessage name="slug" />
        </div>
      </Form>
    </div>
  );
};

function CompanySSOForm({
  onSubmitSuccess,
  bindSubmission,
  companyDetails,
  companyService
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      const { company: updatedCompany } = await companyService.updateCompanySSO(companyDetails.company, values);
      onSubmitSuccess(updatedCompany);
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{
        isSSOEnabled: companyDetails.company.isSSOEnabled,
        slug: companyDetails.company.slug || slugify(companyDetails.company.name, { lower: true })
      }}
      validationSchema={CompanySSOFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <SSOForm {...formikProps} bindSubmission={bindSubmission} />}
    />
  );
}

export default withServices('companyService')(CompanySSOForm);
