/* eslint-disable import/no-cycle */
import { chargeCodeConfigSelector } from '../../../../store/selectors';
import { checkChildKeyHasValue } from '../../../../utils/utils';
import { TARGET_SHEET_NAME } from '../../../app/DocExtractionComponent/DataSpreadSheet';
import BaseEvent from './BaseEvent';

export default class CellChangedEvent extends BaseEvent {
  handle(datasheet, { type }, args) {
    const { sheet, sheetName, col, row, newValue, oldValue } = args;
    const { spreadsheet } = datasheet;

    if (row !== 0 && sheetName === TARGET_SHEET_NAME) {
        const workbookData = spreadsheet.getWorkbookData();
        const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
        datasheet.onCellValueChanged({ columns, ...args });
    }

    if (sheetName === TARGET_SHEET_NAME && oldValue !== newValue) {
      const workbookData = spreadsheet.getWorkbookData();
      const { columns } = workbookData.sheets[TARGET_SHEET_NAME];

      if (datasheet.isOSBaseType) {
        datasheet.props.documentRef.operatingStatementEvents();
      }

      if (datasheet.isRentRollBaseType) {
        const chargeCodeConfig = chargeCodeConfigSelector(datasheet.props.documentRef.props.store.getState());
        if (checkChildKeyHasValue(chargeCodeConfig, 'colIndex', col)) {
          datasheet.props.documentRef.retrieveChargeCodes();
        }
        datasheet.props.documentRef.rentRollEvents(type);
      }

      if (row !== 0) { // It runs on every cell change event
        datasheet.validateCellOnValueChanged(sheet, row, col, columns, newValue);
        if (datasheet.isOSBaseType) {
          datasheet.props.documentRef.onHighlightAmountCell(col); // Always call this method at last to highlight the diffrence value between amount & total     
        }
      }
    }
  };
}
  

