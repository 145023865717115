import React, { useMemo } from 'react';
import { withServices } from 'reaf';
import IconButton from '../../core/Button/IconButton';
import moment from 'moment';
import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { SelectCompanyType } from '../../core/ReactTableFilters/ReactTableFilters';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';

function CompanyStatus ({
  queries,
  apiClient,
  router,
  projectsService,
}) {
    const getDays = (subscriptionDetail, trialDays) => {
      const remainingDays = subscriptionDetail ? moment(subscriptionDetail.endDate).diff(moment(), 'days') : trialDays;
      return remainingDays;
    }
    const { data, loading, pageCount, fetchData } = useReactTable({
      queries,
      apiClient,
      router,
      projectsService,
      routeName: 'superAdminCompanyStatusPage',
      resourceEndPoint: '/companies',
      });
      const columns = useMemo(() => [
        {
          minWidth: '120px',
          Header: 'Name',
          accessor: 'name'
        },
        {
          Header: 'Active',
          disableFilters: true,
          accessor: 'accessEnabled',
          Cell: ({ row: { original: { accessEnabled } } }) => (
            <IconButton
              className={accessEnabled ? 'btn-outline-success' : 'btn-outline-danger'}
              style={{ width: '100%' }}
              icon={accessEnabled ? faCheck : faTimesCircle}
              title={accessEnabled ? 'Active' : 'Inactive'} />
          )
        },
        {
          Header: 'CompanyType',
          accessor: 'isEnterpriseCompany',
          Filter: SelectCompanyType,
          Cell: ({ row: { original: { isEnterpriseCompany } } }) => (
            <div>
              {isEnterpriseCompany ? 'Enterprise' : 'Trial'}
            </div>
          )
        },
        {
          Header: 'Trial Start Date',
          disableFilters: true,
          accessor: 'createdAt',
          Cell: ({ row: { original: { createdAt, isEnterpriseCompany } } }) => (
            <div>
              {isEnterpriseCompany ? '_' : moment(createdAt).format('MM/DD/YYYY')}
            </div>
          )
        },
        {
          Header: 'Trial End Date',
          disableFilters: true,
          accessor: 'trialEndDate',
          Cell: ({ row: { original: { subscriptionDetail, trialDays, isEnterpriseCompany } } }) => (
            <div>
              {!isEnterpriseCompany ?  moment().add(getDays(subscriptionDetail, trialDays), 'days').format('MM/DD/YYYY') : '_' }
            </div>
          )
        }
      ], [data]);
    return (
      <ReactTable
        columns={columns}
        data={data}
        queries={queries}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        tableStatus={true}
      />
    )
}

export default (withServices('companyService', 'toastrService', 'apiClient', 'router', 'projectsService',)(CompanyStatus));