import React from 'react';
import ForgotPasswordForm from '../../app/ForgotPasswordForm/ForgotPasswordForm';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';

function ForgotPasswordPage() {
  return (
    <LoggedOutUserPage>
      <div className="vertical-section dotted-bg bg-light">
        <ForgotPasswordForm />
      </div>
    </LoggedOutUserPage>
  );
}

export default ForgotPasswordPage;
