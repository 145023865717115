/* eslint-disable import/no-cycle */
import React from 'react';
import * as PropTypes from 'prop-types';
import GC from '@grapecity/spread-sheets';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { isNil, isNumber } from 'lodash';
import SpreadSheets from '../../core/Spreadjs/SpreadSheets';
import { removeSpecialCharacter } from '../../../utils/string';
import { FORMATS } from '../../core/Spreadjs/Toolbar/FormatMenu';
import { SheetTabClickedEvent } from './Events/SheetTabClickedEvent';
import { noopFunc } from '../../../lib/utils/noop';
import { columnDataTypeFormatters, sheetIndex, rrSheetIndex, sheetNames, USDateFormat, USCurrencyFormat, validDateFormats, spreadColorConfig, DocumentFileTypes } from '../../../constants';
import copyCommand from '../../core/Spreadjs/Commands/CopyCommand';
import pasteCommand from '../../core/Spreadjs/Commands/PasteCommand';
import CopyEvent from '../../core/Spreadjs/Events/CopyEvent';
import ClipboardPastedEvent from '../../core/Spreadjs/Events/ClipboardPastedEvent';
import ClipboardPastingEvent from '../../core/Spreadjs/Events/ClipboardPastingEvent';
import ClipboardData from './Misc/ClipboardData';
import { checkValidNumber } from '../../../utils/utils';
import messages from '../../../../locales/en-US';
import CellChangedEvent from '../../core/Spreadjs/Events/CellChangedEvent';
import RangeChangedEvent from '../../core/Spreadjs/Events/RangeChangedEvent';
import RowChangedEvent from '../../core/Spreadjs/Events/RowChangedEvent';
import ColumnChangedEvent from '../../core/Spreadjs/Events/ColumnChangedEvent';
import CellClickEvent from '../../core/Spreadjs/Events/CellClickEvent';
import EditEndingEvent from '../../core/Spreadjs/Events/EditEndingEvent';

const disabledSheetContextMenuItems = [
  'gc.spread.insertSheet', 'gc.spread.deleteSheet', 'gc.spread.hideSheet', 'gc.spread.unhideSheet'
];

function convertDataListDropDown(dropdownValue) {
  const listDropDown = [];
  !!dropdownValue.length && dropdownValue.forEach((value) => listDropDown.push({ item: value, value }));
  return listDropDown;
}

export const rowColors = {
  mappedRow: '#eee',
  matchedRow: '#dddddd'
};


export const HeaderStyleMap = {
  STATIC: 'staticHeader',
  DATA: 'dataHeader',
  CHARGE_CODE: 'chargeCodeHeader',
  STATIC_CHARGE_CODE: 'staticChargeCodeHeader',
  SUMMARY_TOTAL: 'summaryTotalStyle',
  SUMMARY_HEADER: 'summaryHeaderStyle',
  SUMMARY_STATIC_HEADER: 'staticSummaryHeader',
  RENT_STEP_DATE: 'rentStepDate',
  RENT_STEP_DATE_DATA: 'rentStepDateData',
  RENT_STEP_MONTHLY: 'rentStepMonthly',
  RENT_STEP_MONTHLY_DATA: 'rentStepMonthlyData',
  RENT_STEP_PSF: 'rentStepPsf',
  RENT_STEP_PSF_DATA: 'rentStepPsfData',
  BOLD_CELL_STYLE: 'boldCellStyle'
};

export const SOURCE_SHEET = sheetIndex.sourceSheetIndex;
export const TARGET_SHEET = sheetIndex.targetSheetIndex;
export const SOURCE_SHEET_NAME = sheetNames.sourceSheetName;
export const TARGET_SHEET_NAME = sheetNames.targetSheetName;
export const WORKING_SHEET_NAME = sheetNames.workingSheetName;
export const SUMMARY_SHEET_NAME = sheetNames.summarySheetName;

export class DataSpreadSheet extends React.Component {
  constructor(props) {
    super(props);
    this.refSpreadsheet = React.createRef();
    this.clipboardData = new ClipboardData();
  }

  get spreadsheet() {
    return this.refSpreadsheet.current;
  }

  get workbook() {
    return this.spreadsheet.workbook;
  }

  get toAlphabetColumnName() {
    return this.spreadsheet.toAlphabetColumnName;
  }

  get isActiveSheetProtected() {
    return this.workbook.sheets[this.workbook.getActiveSheetIndex()].options.isProtected;
  }

  get isOSBaseType() {
    return this.props.documentType === DocumentFileTypes.OPERATING_STATEMENT.baseType;
  }

  get getWorkingSheetIndex() {
    return this.isOSBaseType() ? sheetIndex.workingSheetIndex : rrSheetIndex.workingSheetIndex;
  }

  get isRentRollBaseType() {
    return this.props.documentType === DocumentFileTypes.RENT_ROLL.baseType;
  }

  /*************************************************************************************************/
  // Spread core features that are used for basic events, commands, core functions etc
  /*************************************************************************************************/

  registerCommands() {
    this.spreadsheet.registerCustomCommand('copyCommand', copyCommand, this);
    this.spreadsheet.registerCustomCommand('pasteCommand', pasteCommand, this);
  }

  bindEvents() {
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.SheetTabClick, SheetTabClickedEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.ClipboardChanged, CopyEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.ClipboardPasted, ClipboardPastedEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.ClipboardPasting, ClipboardPastingEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.CellChanged, CellChangedEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.RangeChanged, RangeChangedEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.RowChanged, RowChangedEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.ColumnChanged, ColumnChangedEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.CellClick, CellClickEvent.get(), this,
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.EditEnding, EditEndingEvent.get(), this,
    );
  }

  disableSheetTabContextMenu() {
    const contextMenuData = this.workbook.contextMenu.menuData;
    contextMenuData.forEach((e) => {
      e.disable = disabledSheetContextMenuItems.includes(e.name);
    });
  }

  highlightMappedRows(source, extracted, getRowColor) {
    let sourceIndex = 0;
    let targetIndex = 0;

    while (targetIndex < extracted.rows.length && sourceIndex <= source.rows.length - 1) {
      if (extracted.rows[targetIndex].id === source.rows[sourceIndex].id) {
        let color = rowColors.mappedRow;
        if (getRowColor) {
          const targetRowColor = getRowColor(extracted.rows[targetIndex]);
          this.spreadsheet.setCellRangeAttr(
            TARGET_SHEET, targetIndex + 1, 0, 1, extracted.columns.length, 'backColor', targetRowColor,
          );
          color = targetRowColor || color;
        }
        this.spreadsheet.setCellRangeAttr(
          SOURCE_SHEET, sourceIndex + 1, 0, 1, source.columns.length, 'backColor', color,
        );
        targetIndex++;
      }
      sourceIndex++;
    }
  }

  handleReady = () => {
    this.props.onReady();
    if (!this.props.isProjectReport) {
      this.addStyles();
      this.registerCommands();
      this.bindEvents();
      this.disableSheetTabContextMenu();
      this.setSheetZoomRatio();
    }
  };

  setSheetData = (sheetIndex, columns, rows, sheetName, readonly = false, hasHeader = true, autofitColumns = false) => {
    this.spreadsheet.setSheetName(sheetIndex, sheetName);
    const columnCount = columns.length;
    this.spreadsheet.setSheetData(sheetIndex, rows, readonly);
    this.spreadsheet.setSheetColumnCount(sheetIndex, columnCount + 10);
    this.bindSheetColumns(sheetIndex, columns, 180);
    hasHeader && this.styleHeaderRow(sheetIndex, columns);
    autofitColumns && this.spreadsheet.autoFitColumns(sheetIndex);
    readonly && this.spreadsheet.setSheetReadOnly(sheetIndex, readonly);
    this.setCurrentSheetContextMenu(readonly);
    this.onFreezeRowColumn();
  };

  setCurrentSheetContextMenu(isWorkbookLoaded = false) {
    if (isWorkbookLoaded) {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => this.workbook.options.allowContextMenu = !this.isActiveSheetProtected, 0);
    } else {
      this.workbook.options.allowContextMenu = this.isActiveSheetProtected;
    }
  }

  onFreezeRowColumn() {
    const sheet = this.spreadsheet.getSheet(TARGET_SHEET);
    this.spreadsheet.getSheet(SOURCE_SHEET).frozenColumnCount(1)
    sheet.frozenRowCount(1)
    if (this.isRentRollBaseType) {
      sheet.frozenColumnCount(3); // Freezing column till floor plan column.
    } else {
      sheet.frozenColumnCount(1);
    }
  }

  setSheetZoomRatio() {
    this.spreadsheet.suspend();
    const sheets = this.spreadsheet.getSheets();
    if (window.screen && window.screen.width && !!sheets.length) {
      const zoomFactor = (window.screen.width >= 1600) ? 1 : (window.screen.width >= 1440) ? 0.8 : 0.7;
      for (let i = 0; i < sheets.length; i++) {
        sheets[i].zoom(zoomFactor);
      }
    }
    this.spreadsheet.resume();
  }

  bindSheetColumns = (sheetIndex, columns, defaultColumnSize) => {
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    columns.forEach(
      (column, i) => {
        sheet.bindColumn(i, { resizable: true, size: defaultColumnSize, ...column });
        this.spreadsheet.setColumnHeader(sheetIndex, i, this.toAlphabetColumnName(i));
      },
    );
  };

  setDropDownColumns(sheetIndex, { rows, columns }) {
    columns.forEach((column, c) => {
      if (column.options) {
        rows.forEach((row, r) => {
          if (Reflect.ownKeys(row).length > 0 && r > 0) {
            this.setDropDownList(sheetIndex, r, c, column.options);
          }
        });
      }
    });
  }

  setDropDownList(sheetIndex, row, col, dropDownOptions) {
    this.spreadsheet.suspend();
    const dropDownListCellType = new this.spreadsheet.GC.Spread.Sheets.Style();
    dropDownListCellType.cellButtons = [{
      imageType: this.spreadsheet.GC.Spread.Sheets.ButtonImageType.dropdown,
      command: 'openList',
      useButtonStyle: false
    }];
    dropDownListCellType.dropDowns = [{
      type: this.spreadsheet.GC.Spread.Sheets.DropDownType.list,
      option: {
        items: convertDataListDropDown(dropDownOptions)
      }
    }];
    this.spreadsheet.getSheet(sheetIndex).setStyle(row, col, dropDownListCellType);
    this.spreadsheet.resume();
  }

  styleHeaderRow = (sheetIndex, columns) => {
    columns.forEach((column, i) => {
      this.spreadsheet.setCellStyle(sheetIndex, 0, i, HeaderStyleMap[column.type || 'DATA']);
    });
  };

  addStyles() {
    this.spreadsheet.addStyle(null, 'cellWarning', {
      backColor: 'rgb(255,128,0)',
      foreColor: '#000'
    });

    this.spreadsheet.addStyle(null, 'dataHeader', {
      backColor: 'rgb(43, 142, 255)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.spreadsheet.addStyle(null, 'staticHeader', {
      backColor: 'rgb(23, 102, 155)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });
    this.spreadsheet.addStyle(null, HeaderStyleMap.STATIC_CHARGE_CODE, {
      backColor: 'rgb(51, 51, 51)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin',
      },
    });

    this.spreadsheet.addStyle(null, HeaderStyleMap.CHARGE_CODE, {
      backColor: 'rgb(51, 96, 51)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin',
      },
    });

    this.spreadsheet.addStyle(null, HeaderStyleMap.RENT_STEP_DATE, {
      backColor: '#446',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      },
      borderLeft: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.spreadsheet.addStyle(null, HeaderStyleMap.RENT_STEP_DATE_DATA, {
      backColor: '#aae',
      foreColor: '#000',
      borderLeft: {
        color: 'black',
        lineStyle: 'medium'
      },
      borderBottom: {
        color: 'grey',
        lineStyle: 'thin'
      }
    });

    this.spreadsheet.addStyle(null, HeaderStyleMap.RENT_STEP_MONTHLY, {
      backColor: '#557',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.spreadsheet.addStyle(null, HeaderStyleMap.RENT_STEP_MONTHLY_DATA, {
      backColor: '#ccf',
      foreColor: '#000',
      borderLeft: {
        color: 'grey',
        lineStyle: 'thin'
      },
      borderBottom: {
        color: 'grey',
        lineStyle: 'thin'
      }
    });

    this.spreadsheet.addStyle(null, HeaderStyleMap.RENT_STEP_PSF, {
      backColor: '#557',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      },
      borderRight: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.spreadsheet.addStyle(null, HeaderStyleMap.RENT_STEP_PSF_DATA, {
      backColor: '#ccf',
      foreColor: '#000',
      borderBottom: {
        color: 'grey',
        lineStyle: 'thin'
      },
      borderLeft: {
        color: 'grey',
        lineStyle: 'thin'
      },
      borderRight: {
        color: 'black',
        lineStyle: 'medium'
      }
    });

    this.spreadsheet.addStyle(null, HeaderStyleMap.SUMMARY_STATIC_HEADER, {
      backColor: '#3e3d39',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });
  }

  setCellFormatInUSNumber(sheetIndex, row, col, rowCount, colCount) {
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    this.spreadsheet.suspend();
    const range = sheet.getRange(1, col, rowCount, colCount);
    range.formatter(FORMATS.NUMBER);
    this.spreadsheet.resume();
  }

  getSheetSpecificData(sheetNames) {
    const { sheets } = this.spreadsheet.getWorkbookData();
    return sheetNames.map((sheetName) => sheets[sheetName]);
  }

  getColIndex(sheetIndex, columnName) {
    return this.customSearchColumnWithValue(sheetIndex, 0, columnName).foundColumnIndex;
  }

  getColumnCount(sheetData) {
    return Object.keys(sheetData.colHeaderData.dataTable[0]).length;
  }

  setWarningStyleRule(sheet, formula, ranges) {
    const warningStyle = this.workbook.getNamedStyle('cellWarning');
    sheet.conditionalFormats.addFormulaRule(formula, warningStyle, ranges);
  }

  updateRowBackColorForColumnValue(sheetIndex, columnIndex, cellValue, backColor, col = 0, colCount = -1) {
    const { foundRowIndex } = this.customSearchRowWithValue(sheetIndex, columnIndex, cellValue);
    if (foundRowIndex !== -1) {
      this.spreadsheet.setCellRangeAttr(
        sheetIndex, foundRowIndex, 0, 1, colCount, 'backColor', backColor,
      );
    }
  }

  searchColumnIndexes(sheetIndex, columnHeaders) {
    return columnHeaders.map((headerName) => this.customSearchColumnWithValue(
      sheetIndex, 0, headerName,
    ).foundColumnIndex);
  }

  setValue(sheetIndex, row, col, value) {
    if (typeof value === 'number') {
      this.spreadsheet.setCellValue(sheetIndex, row, col, value);
    } else {
      const cellValue = removeSpecialCharacter(value);

      if (parseFloat(cellValue).toString() === 'NaN') {
        this.spreadsheet.setCellValue(sheetIndex, row, col, cellValue);
      } else {
        this.spreadsheet.setCellText(sheetIndex, row, col, cellValue.split(',').join(''));
      }
    }
  }

  customSearchRowWithValue(sheetIndex, columnIndex, cellValue, options = {}) {
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    const rowCount = sheet.getRowCount();
    for (let row = options.startRow || 0; row < rowCount; row++) {
      const val = sheet.getCell(row, columnIndex).value();
      if (val === cellValue) {
        return { foundRowIndex: row };
      }
    }
    return { foundRowIndex: -1 };
  }

  customSearchColumnWithValue(sheetIndex, rowIndex, cellValue, options = {}) {
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    const cellCount = sheet.getColumnCount();
    for (let col = options.startCol || 0; col < cellCount; col++) {
      const val = sheet.getCell(rowIndex, col).value();
      if (val === cellValue) {
        return { foundColumnIndex: col };
      }
    }
    return { foundColumnIndex: -1 };
  }

  customSearchWithColumnValue(sheetIndex, columnIndex, cellValue, options = {}) {
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    const rowCount = sheet.getRowCount();
    for (let row = options.startRow || 0; row < rowCount; row++) {
      const val = sheet.getCell(row, columnIndex).value();
      if (val === cellValue) {
        return { foundRowIndex: row };
      }
    }
    return { foundRowIndex: -1 };
  }

  customSearchColumnWithIgnoreCaseValue(sheetIndex, rowIndex, cellValue, options = {}) {
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    const cellCount = sheet.getColumnCount();
    if (cellValue !== null) {
      for (let col = options?.startCol || 0; col < cellCount; col++) {
        const val = sheet.getCell(rowIndex, col).value();
        const updatedCellValue = !isNil(val) ? val.toLowerCase() : val;
        if (updatedCellValue === cellValue.toLowerCase()) {
          return { foundColumnIndex: col };
        }
      }
    }
    return { foundColumnIndex: -1 };
  }

  isColumnNotEmpty(sheetIndex, colIndex) {
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    for (let i = 0; i < sheet.getRowCount(); i++) {
      if (sheet.getValue(i, colIndex) != null) {
        return true;
      }
    }
    return false;
  }

  getWorkbookDataWithoutSheetProtection() {
    const workbookData = this.spreadsheet.getWorkbookData();
    const { sheets } = workbookData;
    // eslint-disable-next-line no-return-assign
    Reflect.ownKeys(sheets).forEach((item) => sheets[item].isProtected = false);
    return workbookData;
  }

  downloadXLS = (document) => new Promise((resolve) => {
    const data = this.getWorkbookDataWithoutSheetProtection();
    const fileName = [...document.fileName.split('.').slice(0, -1), 'xlsx'].join('.');
    const io = new this.spreadsheet.GCExcel.IO();
    io.save(data, (blob) => {
      resolve(blob);
      saveAs(blob, fileName);
    }, console.error);
  });

  setColumnReadOnly(sheetIndex, row, col, rowCount, columnCount) {
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    const summarySheetColCount = this.spreadsheet.getSheet(sheetIndex).getColumnCount();
    this.spreadsheet.suspend();
    this.spreadsheet.setCellRangeAttr(sheetIndex, row, col, rowCount, columnCount, 'locked', true);
    this.spreadsheet.setCellRangeAttr(sheetIndex, -1, 0, -1, summarySheetColCount, 'locked', false);
    sheet.options.isProtected = true;
    sheet.options.protectionOptions.allowDeleteRows = true;
    sheet.options.protectionOptions.allowDeleteColumns = true;
    sheet.options.protectionOptions.allowInsertRows = true;
    sheet.options.protectionOptions.allowInsertColumns = true;
    sheet.options.protectionOptions.allowDargInsertRows = true;
    sheet.options.protectionOptions.allowDragInsertColumns = true;
    this.spreadsheet.resume();
  }

  setCellBorder(data) {
    this.spreadsheet.suspend();
    if (data) {
      const dataKey = ['source', 'target'];
      for (let sheetIndex = 0; sheetIndex < 2; sheetIndex++) {
        const sheet = this.spreadsheet.getSheet(sheetIndex);
        const { columns, rows } = data[dataKey[sheetIndex]];
        const rowCount = rows.filter(item => Reflect.ownKeys(item).length > 0).length;
        sheet.getRange(0, 0, rowCount, columns.length, GC.Spread.Sheets.SheetArea.viewport)
          .setBorder(new GC.Spread.Sheets.LineBorder("#ababab", GC.Spread.Sheets.LineStyle.thin), { all: true }, 3);
      }
    }
    this.spreadsheet.resume();
  }

  columnAlphabetIndex(colIndex) {
    return this.spreadsheet.toAlphabetColumnName(colIndex + 1);
  }

  getAlphabetTargetRange(columnName, sheetIndex) {
    const rowCount = this.spreadsheet.getSheet(sheetIndex).getRowCount();
    const columnIndex = this.customSearchColumnWithValue(1, 0, columnName).foundColumnIndex;
    const targetRange = `${this.columnAlphabetIndex(columnIndex)}1:${this.columnAlphabetIndex(columnIndex)}${rowCount}`;
    return { rowCount, targetRange, columnIndex };
  }

  fetchValueFromColumns(sheetIndex, columnNames, outputKeys, showRowIndex = true) {
    this.spreadsheet.suspend();
    const result = [];
    const rowCount = this.spreadsheet.getSheet(sheetIndex).getRowCount();
    for (let column = 0; column < columnNames.length; column++) {
      const columnIndex = this.customSearchColumnWithValue(sheetIndex, 0, columnNames[column]).foundColumnIndex;
      if (columnIndex !== -1) {
        for (let row = 1; row < rowCount; row++) {
          const columnValues = this.spreadsheet.getCellValue(sheetIndex, row, columnIndex);
          if (!isNil(columnValues)) {
            const rowIndex = showRowIndex ? { [`${outputKeys[column]}_ROW_INDEX`]: row } : {};
            result[row] = { ...result[row], ...{ [outputKeys[column]]: columnValues, ...rowIndex } };
          }
        }
      }
    }
    this.spreadsheet.resume();
    return result.filter(output => output);
  }

  getMatchedCellValues(sheetIndex, rowCount, sourceColumnIndex, targetColumnIndex, cellValue) {
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(sheetIndex);
    const foundRowIndex = [];
    for (let row = 1; row < rowCount; row++) {
      const columnValue = sheet.getCell(row, sourceColumnIndex).value();
      if (columnValue === cellValue) {
        foundRowIndex.push(sheet.getCell(row, targetColumnIndex).value());
      }
    }
    this.spreadsheet.resume();
    return foundRowIndex;
  }

  getTargetSheetRowCount() {
    this.spreadsheet.suspend();
    let serialNumbers = [];
    const rowCount = this.spreadsheet.getSheet(TARGET_SHEET).getRowCount();
    for (let row = 0; row < rowCount; row++) {
      const value = this.spreadsheet.getCellValue(TARGET_SHEET, row, 0);
      if (!isNil(value)) {
        serialNumbers.push(value);
      }
    }
    this.spreadsheet.resume();
    return serialNumbers.length;
  }

  removeSpecialCharacter(value) {
    const isNumber = /\(([^)]+)\)/g;
    if (isNumber.test(value)) {
      const extractedValue = value.substr(1).slice(0, -1);
      if (parseFloat(extractedValue).toString() !== 'NaN') {
        return `-${extractedValue}`;
      }
    } else {
      return this.removeCurrencySymbol(value);
    }
  }

  removeCurrencySymbol(value) {
    const isCurrency = /\$/g;
    return isCurrency.test(value) ? value.substr(1) : value;
  }

  setValue(sheetIndex, row, col, value) {
    if (typeof value === 'number') {
      this.spreadsheet.setCellValue(sheetIndex, row, col, value);
    } else {
      const cellValue = this.removeSpecialCharacter(value);

      if (parseFloat(cellValue).toString() === 'NaN') {
        this.spreadsheet.setCellValue(sheetIndex, row, col, cellValue);
      } else {
        this.spreadsheet.setCellText(sheetIndex, row, col, cellValue.split(',').join(''));
      }
    }
  }

  validateCurrencyColumn(value, colDataType = '') {
    const isNumber = /\(([^)]+)\)/g;
    const validNumberRegex = /[^-.,a-zA-Z0-9]/g;
    if (isNumber.test(value)) {
      let extractedValue = value.substr(1).slice(0, -1);
      if (colDataType === 'currency') {
        extractedValue = extractedValue.replace(validNumberRegex, '');
      }
      if (parseFloat(extractedValue).toString() !== 'NaN') {
        return `-${extractedValue}`;
      }
    } else {
      let cellValue = value;
      if (isNaN(value) && colDataType === 'currency') {
        const removedSpecialCharValue = cellValue.replace(validNumberRegex, '');
        if (isNaN(Number(removedSpecialCharValue)) && removedSpecialCharValue.toString().length === 1) {
          cellValue = '';
        } else {
          cellValue = removedSpecialCharValue;
        }
      }
      return cellValue;
    }
  }

  getSourceTargetData() {
    const { sheets } = this.spreadsheet.getWorkbookData();
    const targetSheetData = sheets[TARGET_SHEET_NAME];
    const sourceSheetData = sheets[SOURCE_SHEET_NAME];
    return { targetSheetData, sourceSheetData };
  }

  /*************************************************************************************************/
  // Handling Row column delete & insert operation
  /*************************************************************************************************/

  onRowColDeletion = (isUndo, sheet, row, col, rowCount) => {
    if (sheet === this.spreadsheet.getSheet(TARGET_SHEET)) {
      this.spreadsheet.suspend();
      if (col < 1) {
        const [sourceSheetData] = this.getSheetSpecificData([SOURCE_SHEET_NAME, TARGET_SHEET_NAME]);
        const sourceSheetColCount = this.getColumnCount(sourceSheetData);
        for (let i = row; i < rowCount + row; i++) {
          const rowId = this.spreadsheet.getCellValue(TARGET_SHEET, i, 0);
          const backColor = isUndo ? this.props.getRowColor(i) : undefined;
          this.updateRowBackColorForColumnValue(SOURCE_SHEET, 0, rowId, backColor, 0, sourceSheetColCount);
        }
      }
      this.props.onDocumentEvents();
      this.spreadsheet.resume();
    }
  };

  onRowColInsertion = (isUndo, sheet, row, col, rowCount, colCount) => {
    if (!isUndo) {
      if (sheet === this.spreadsheet.getSheet(TARGET_SHEET)) {
        if (col < 1) {
          this.spreadsheet.suspend();
          this.props.onRowsInsert(sheet, row, rowCount);
          this.spreadsheet.resume();
        } else if (row < 1) {
          for (let i = 0; i < colCount; i++) {
            sheet.setStyleName(0, col + i, HeaderStyleMap.DATA);
          }
          sheet.getCell(row, col).locked(false);
        }
      }
    }
    this.props.onDocumentEvents();
  };

  /*************************************************************************************************/
  // Handling Row column copy-paste operation
  /*************************************************************************************************/

  copyColumnsFromPreviousRow(sheet, columnIndexes = [], row, rowCount) {
    if (columnIndexes.length > 0) {
      for (let colIndex = 0; colIndex < columnIndexes.length; colIndex++) {
        for (let i = row; i < rowCount + row; i++) {
          const fromRange = [new this.spreadsheet.GC.Spread.Sheets.Range(i - 1, columnIndexes[colIndex], 1, 1)];
          const toRanges = [new this.spreadsheet.GC.Spread.Sheets.Range(i, columnIndexes[colIndex], 1, 1)];
          this.workbook.commandManager().execute({
            cmd: 'clipboardPaste',
            sheetName: sheet.name(),
            fromSheet: sheet,
            fromRanges: fromRange,
            pastedRanges: toRanges,
            isCutting: false,
            clipboardText: '',
            pasteOption: this.spreadsheet.GC.Spread.Sheets.ClipboardPasteOptions.formatting
          });
        }
      }
    }
  }

  copySourceColumnsToTarget(activeSheetIndex, headerNames, sourceColumnRange, targetColumnRange) {
    const { col: sourceColumn, rowCount: sourceRowCount, colCount: sourceColumnCount } = sourceColumnRange;
    const { col: targetColumn } = targetColumnRange;
    const sheet = this.spreadsheet.getSheet(activeSheetIndex);
    const workbookData = this.spreadsheet.getWorkbookData();
    const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
    this.spreadsheet.suspend();
    let lastIndex = 0;
    let columnDataTypes = [];
    let columnDataTypeRecord = [];
    let foundColumnDataType = [];

    for (let i = 0; i < sourceRowCount; i++) {
      for (let j = 0; j < sourceColumnCount; j++) {
        i && this.spreadsheet.setCellValue(activeSheetIndex, i, targetColumn + j, '');
        if (!!headerNames[j] && i === 0) {
          const { columnValue } = headerNames[j];
          const foundColumnConfig = columns.length ? columns.filter(col => col.headerName === columnValue) : [];
          foundColumnDataType.push(...foundColumnConfig);
          if (!!foundColumnDataType.length && foundColumnDataType[j]?.hasOwnProperty('dataType')) {
            columnDataTypes.push({ column: targetColumn + j, dataType: foundColumnDataType[j].dataType })
          }
          this.spreadsheet.setCellStyle(activeSheetIndex, i, targetColumn + j, headerNames[j].columnStyle);
        } else {
          const cellStyle = new GC.Spread.Sheets.Style();
          const defaultCellStyle = sheet.getStyle(i, 1);
          if (defaultCellStyle) {
            cellStyle.backColor = defaultCellStyle.backColor || undefined
            cellStyle.borderBottom = defaultCellStyle.borderBottom || undefined;
            cellStyle.borderLeft = defaultCellStyle.borderLeft || undefined;
            cellStyle.borderRight = defaultCellStyle.borderRight || undefined;
            cellStyle.borderTop = defaultCellStyle.borderTop || undefined;
          }
          this.spreadsheet.setCellStyle(activeSheetIndex, i, targetColumn + j, cellStyle);
        }
        const sourceId = this.spreadsheet.getCellValue(SOURCE_SHEET, i, 0);
        const { foundRowIndex } = this.customSearchWithColumnValue(TARGET_SHEET, 0, sourceId, { startRow: lastIndex });
        if (foundRowIndex !== -1 && i > 0) {
          lastIndex = foundRowIndex;
          const columnValue = this.spreadsheet.getCellValue(SOURCE_SHEET, i, sourceColumn + j);
          if (!!columnDataTypes.length && columnDataTypes[j].dataType === 'date') {
            this.validateAndSetDateToCell(sheet, foundRowIndex, targetColumn + j, columnValue)
          } else {
            const colDataType = columnDataTypes.length ? columnDataTypes[j].dataType : 'string';
            this.setValue(activeSheetIndex, foundRowIndex, targetColumn + j, columnValue, colDataType);
            !!columnDataTypes.length && this.setExistingCellFormat(sheet, foundRowIndex, targetColumn + j, columnDataTypes[j].dataType);
          }

          if (!!columnDataTypes.length && columnValue) {
            let isValidDataType = this.validateColumnDataType(columnDataTypes[j].dataType, columnValue);
            if (!isValidDataType) {
              const { dataType } = columnDataTypes[j];
              columnDataTypeRecord.push({ row: foundRowIndex, sourceId, isValidDataType: spreadColorConfig.invalidCellColor, col: targetColumn + j, dataType });
            }
          }
        }
      }
    }

    this.spreadsheet.resume();
    if (columnDataTypes.length) {
      setTimeout(() => {
        sheet.clearSelection();
        this.spreadsheet.suspend();
        if (columnDataTypeRecord.length) {
          columnDataTypeRecord.forEach(({ row, col }) => {
            this.highlightCellValue(sheet, row, col, spreadColorConfig.invalidCellColor)
          });
          this.props.onToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
        }
        columnDataTypes.forEach(({ column }) => {
          this.props.documentRef.onHighlightAmountCell(column);
        });
        this.spreadsheet.resume();
      }, 0);
    }
  }

  copySourceRangeToTarget(activeSheetIndex, sourceRange, targetRow, targetColumn) {
    this.spreadsheet.suspend();
    const {
      row, col, rowCount, colCount,
    } = sourceRange;

    const sheet = this.spreadsheet.getSheet(activeSheetIndex);
    const workbookData = this.spreadsheet.getWorkbookData();
    const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
    const cellValidationStates = [];

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < colCount; j++) {
        const sourceValue = this.spreadsheet.getCellValue(SOURCE_SHEET, row + i, col + j);
        this.setValue(activeSheetIndex, targetRow + i, targetColumn + j, sourceValue);
        const cellState = this.onCellValueChanged({ sheet, columns, row: targetRow + i, col: targetColumn + j });
        cellValidationStates.push(cellState)
      }
    }
    if (cellValidationStates.includes(false)) { // checking if any dataType validation failed in collection
      this.props.onToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
    }
    this.spreadsheet.resume();
  }

  onRangePaste(activeSheet, options) {
    if (this.spreadsheet.getActiveSheetIndex() === activeSheet && this.copiedSourceSelectedRange) {
      const pastedRange = this.workbookManager.getSelections(activeSheet)[0];
      const targetColumn = pastedRange.col;
      const targetRow = pastedRange.row;
      this.copyRangeSourceToTarget(activeSheet, this.copiedSourceSelectedRange, targetRow, targetColumn);
    } else if (this.workbookManager.getActiveSheetIndex() === activeSheet && !this.copiedSourceSelectedRange) {
      this.onRangePasteToTargetSheet(activeSheet, options);
    }
    this.clipboardData.reset();
  }

  onRangePasteToTargetSheet(activeSheetIndex, options) {
    if (this.spreadsheet.getActiveSheetIndex() === activeSheetIndex) {
      this.spreadsheet.suspend();
      const sheet = this.spreadsheet.getSheet(activeSheetIndex);
      const workbookData = this.spreadsheet.getWorkbookData();
      const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
      const { cellRange } = options;
      this.copyFromSameSheet(sheet, columns, { ...cellRange })
      this.spreadsheet.resume();
    }
  }

  copyRangeSourceToTarget(activeSheetIndex, sourceRange, targetRow, targetColumn) {
    this.spreadsheet.suspend();
    const {
      row, col, rowCount, colCount
    } = sourceRange;
    const sheet = this.spreadsheet.getSheet(activeSheetIndex);
    const workbookData = this.spreadsheet.getWorkbookData();
    const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
    const cellValidationStates = [];

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < colCount; j++) {
        const sourceValue = this.spreadsheet.getCellValue(SOURCE_SHEET, row + i, col + j);
        this.setValue(activeSheetIndex, targetRow + i, targetColumn + j, sourceValue);
        const cellState = this.onCellValueChanged({ sheet, columns, row: targetRow + i, col: targetColumn + j });
        cellValidationStates.push(cellState)
      }
    }
    if (cellValidationStates.includes(false)) { // checking if any dataType validation failed in collection
      this.props.onToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
    }
    this.spreadsheet.resume();
  }

  copyFromSameSheet(sheet, columns, options) {
    this.spreadsheet.suspend();
    const { row, col, rowCount, colCount } = options;
    const cellValidationStates = [];
    const startRow = row < 0 ? 1 : 0;
    if (row < 0) {
      this.validateHeaderIndentity(sheet, options);
    }
    for (let i = startRow; i < rowCount; i++) {
      for (let j = 0; j < colCount; j++) {
        const rowIndex = row < 0 ? i : row + i;
        const cellValue = sheet.getCell(rowIndex, col + j).value();
        const cellState = this.onCellValueChanged({ sheet, columns, row: rowIndex, col: col + j });
        !isNil(cellValue) && cellValidationStates.push(cellState);
      }
    }
    if (cellValidationStates.includes(false)) { // checking if any dataType validation failed in collection
      this.props.onToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
    }
    this.spreadsheet.resume();
  }

  categoryCopiedValueRange() {
    const {
      row, rowCount, col, colCount
    } = this.spreadsheet.workbook.getActiveSheet().getSelections()[0];
    const { foundColumnIndex } = this.customSearchColumnWithValue(TARGET_SHEET, 0, 'Category', { startCol: col });
    if (foundColumnIndex !== -1 && colCount > 1) {
      this.clipboardData.copiedCategoryValues = this.spreadsheet.getColumnValues(TARGET_SHEET, row, rowCount, foundColumnIndex);
    } else {
      this.clipboardData.copiedCategoryValues = null;
    }
  }

  subCategoryCopiedValueRange() {
    const {
      row, rowCount, col, colCount
    } = this.spreadsheet.workbook.getActiveSheet().getSelections()[0];
    const { foundColumnIndex } = this.customSearchColumnWithValue(TARGET_SHEET, 0, 'Sub Category', { startCol: col });
    if (foundColumnIndex !== -1 && colCount > 1) {
      this.clipboardData.copiedSubCategoryValue = this.spreadsheet.getColumnValues(TARGET_SHEET, row, rowCount, foundColumnIndex);
    } else {
      this.clipboardData.copiedSubCategoryValue = null;
    }
  }

  /*************************************************************************************************/
  // Handling datatype validation here on target sheet columns
  /*************************************************************************************************/

  validateHeaderIndentity(sheet, options) {
    this.spreadsheet.suspend();
    const headerConfig = this.copiedHeaderColumnValues;
    if (headerConfig.length) {
      const { col, colCount } = options;
      for (let i = 0; i < colCount; i++) {
        const columnIndex = col + i;
        const defaultCellStyle = this.spreadsheet.getCellStyle(TARGET_SHEET, 0, 1);
        const cellStyle = headerConfig[i] ? headerConfig[i].columnStyle : defaultCellStyle;
        sheet.setStyle(0, columnIndex, cellStyle);
      }
    }
    this.spreadsheet.resume();
  }

  setExistingCellFormat(sheet, row, col, dataType, cellValue = null) {
    this.spreadsheet.suspend();
    if (dataType === 'currency') {
      const validFormat = (!!cellValue && typeof cellValue === 'object') ? USDateFormat : USCurrencyFormat;
      sheet.setFormatter(row, col, validFormat)
    } else if (dataType === 'date') {
      const validFormat = (!!cellValue && typeof cellValue === 'object') ? USDateFormat : 0;
      sheet.setFormatter(row, col, validFormat)
    } else if (dataType === 'number') {
      const validFormat = (!!cellValue && typeof cellValue === 'object') ? USDateFormat : 0;
      sheet.setFormatter(row, col, validFormat)
    } else if (dataType === 'string') {
      const validFormat = (!!cellValue && typeof cellValue === 'object') ? USDateFormat : '';
      sheet.setFormatter(row, col, validFormat)
    }
    this.spreadsheet.resume();
  }

  highlightCellValue(sheet, row, col, foreColor, isAlignRight = false) {
    this.spreadsheet.suspend();
    const cellStyle = sheet.getStyle(row, col);
    const style = new this.spreadsheet.GC.Spread.Sheets.Style();
    style.foreColor = foreColor || undefined;

    if (cellStyle) {
      style.backColor = cellStyle.backColor || undefined;
      style.borderBottom = cellStyle.borderBottom || undefined;
      style.borderLeft = cellStyle.borderLeft || undefined;
      style.borderRight = cellStyle.borderRight || undefined;
      style.borderTop = cellStyle.borderTop || undefined;
      style.cellButtons = cellStyle.cellButtons || undefined;
      style.dropDowns = cellStyle.dropDowns || undefined;
      style.parentName = cellStyle.parentName || undefined;
      style.name = cellStyle.name || undefined;
    }

    if (isAlignRight) {
      style.hAlign = GC.Spread.Sheets.HorizontalAlign.right;
    }
    sheet.setStyle(row, col, style);
    sheet.getCell(row, col).tag(foreColor ? 'invalid' : 'valid'); // attaching the tag to the cell.
    this.spreadsheet.resume();
  }

  validateColumnDataType(dataType, value) {
    let isValidDataType = true;
    const date = moment(value, validDateFormats, true);
    const cellValue = (dataType === 'date' && date.isValid()) ? moment(new Date(value)).format("MM/DD/YYYY") : this.validateCurrencyColumn(value, dataType);
    if (!!dataType && (!isNil(cellValue) && (!!cellValue || checkValidNumber(cellValue)))) {
      if (dataType === 'number' || dataType === 'currency') {
        isValidDataType = checkValidNumber(cellValue);
      } else if (dataType === 'string') {
        isValidDataType = true; // Allowing any thpe value in string data type 
      } else if (dataType === 'date') {
        if (!checkValidNumber(cellValue) && !Number.isFinite(cellValue)) {
          isValidDataType = (typeof date === 'object' || typeof date === 'string') && date.isValid();
        } else {
          isValidDataType = false;
        }
      }
    }
    return isValidDataType;
  }

  validateAndSetDateToCell(sheet, row, col, date) {
    this.spreadsheet.suspend();
    const isValidDate = this.validateColumnDataType('date', date);
    if (!!date && isValidDate) {
      sheet.setValue(row, col, new Date(date));
      sheet.setFormatter(row, col, USDateFormat);
    } else {
      sheet.setValue(row, col, date);
    }
    this.spreadsheet.resume();
  }

  onCellValueChanged({ sheet, columns, row, col }) {
    this.spreadsheet.suspend();
    const cellValue = sheet.getCell(row, col).value();
    const headerName = sheet.getCell(0, col).value();
    let isValidValue = true;
    if (headerName) {
      const foundColumnConfig = columns.length ? columns.filter(col => col.headerName === headerName) : [];
      if (!!foundColumnConfig.length && foundColumnConfig[0]?.hasOwnProperty('dataType')) {
        const { dataType } = foundColumnConfig[0];
        const validCellValue = typeof cellValue === 'object' ? moment(new Date(cellValue), 'MM/DD/YYYY') : cellValue;
        isValidValue = dataType === 'string' ? true : this.validateColumnDataType(dataType, validCellValue);
        if (isValidValue) {
          this.highlightCellValue(sheet, row, col, undefined);
        } else {
          this.highlightCellValue(sheet, row, col, spreadColorConfig.invalidCellColor);
        }
        this.setExistingCellFormat(sheet, row, col, dataType, cellValue);
      }
    }
    this.spreadsheet.resume();
    return isValidValue;
  }

  handleColumnsFormat() {
    this.spreadsheet.suspend();
    const workbookData = this.spreadsheet.getWorkbookData();
    if (workbookData) {
      const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
      if (columns.length) {
        const sheet = this.spreadsheet.getSheet(TARGET_SHEET);
        const rowCount = this.getTargetSheetRowCount();
        columns.forEach((column) => {
          if (column?.hasOwnProperty('dataType') && column.dataType !== 'string') {
            const { dataType } = column;
            const { foundColumnIndex } = this.customSearchColumnWithValue(1, 0, column.headerName);
            if (foundColumnIndex !== -1 && columnDataTypeFormatters?.hasOwnProperty(dataType)) {
              const range = sheet.getRange(1, foundColumnIndex, rowCount, 1);
              for (let row = 1; row < rowCount; row++) {
                const columnValue = sheet.getCell(row, foundColumnIndex).value();
                const isValid = this.validateColumnDataType(dataType, columnValue);
                const isDateDataType = dataType === 'date' && (isValid && !!columnValue)
                this.highlightCellValue(sheet, row, foundColumnIndex, isValid ? undefined : spreadColorConfig.invalidCellColor, isDateDataType);
                if (isValid) {
                  this.validateAndSetDateToCell(sheet, row, foundColumnIndex, columnValue);
                }
              }
              if (dataType !== 'string' && dataType !== 'date') {
                range.formatter(columnDataTypeFormatters[dataType]); // applying formattar to the column
              }
            }
          }
        })
      }
    }
    this.spreadsheet.resume();
  }

  validateAndSetColumnValue(options) {
    this.spreadsheet.suspend();
    const rowCount = this.getTargetSheetRowCount();
    const workbookData = this.spreadsheet.getWorkbookData();
    const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
    const { sheet, cellRange } = options;
    const { row, col, colCount } = cellRange;
    const foundColumnDataType = [];
    if (sheet && row < 0) {
      for (let i = 0; i < rowCount; i++) {
        for (let j = 0; j < colCount; j++) {
          const columnValue = sheet.getCell(i, col + j).value();
          if (i === 0) {
            const foundColumnConfig = columns.length ? columns.filter(col => col.headerName === columnValue) : [];
            foundColumnDataType.push(...foundColumnConfig);
          } else if (!!foundColumnDataType.length && foundColumnDataType[j]?.hasOwnProperty('dataType')) {
            const { dataType } = foundColumnDataType[j];
            let isValidDataType = this.validateColumnDataType(dataType, columnValue);
            if (!isValidDataType) {
              sheet.getCell(i, col + j).value(null);
            }
          }
        }
      }
    }
    this.spreadsheet.resume();
  }

  validateTargetSheet() {
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(TARGET_SHEET);
    const rowCount = sheet.getRowCount();
    const workbookData = this.spreadsheet.getWorkbookData();
    const { columns } = workbookData.sheets[TARGET_SHEET_NAME];
    const invalidCoulumns = [];
    if (columns.length) {
      const columnsWithValidDataType = columns.filter(column => column?.hasOwnProperty('dataType') && column.dataType !== 'string'); // ignoring string data type columns
      for (let col = 0; col < columnsWithValidDataType.length; col++) {
        const column = columnsWithValidDataType[col];
        const { foundColumnIndex } = this.customSearchColumnWithValue(TARGET_SHEET, 0, column.headerName);
        if (foundColumnIndex !== -1) {
          const isInvalid = this.searchInvalidColumnValues(sheet, foundColumnIndex, rowCount, 'invalid');
          if (isInvalid) {
            invalidCoulumns.push(column.headerName);
          }
        }
      }
    }

    if (invalidCoulumns.length) {
      this.spreadsheet.setActiveSheet(TARGET_SHEET_NAME);
      const sentance = invalidCoulumns.length === 1 ? `column has` : `columns have`;
      this.props.onToast({
        type: 'invalid-on-save-datatype',
        message: `${invalidCoulumns.join(', ')} ${sentance} ${messages.toastMessage.INVALID_DATA_TYPE_WITH_CELL_REF}`,
        autohide: false,
        wrapperClass: 'spread-validation-error',
        headerMessage: 'Data Validation Error'
      });
    }

    this.spreadsheet.resume();
    return !!invalidCoulumns.length
  }

  searchInvalidColumnValues(sheet, colIndex, rowCount, cellTag) {
    let isInvalid = false;
    const invalidRows = []
    for (let row = 1; row < rowCount; row++) {
      const tag = sheet.getTag(row, colIndex);
      const hightlightedColor = sheet.getCell(row, colIndex).foreColor();
      if (tag === cellTag && hightlightedColor === spreadColorConfig.invalidCellColor) {
        isInvalid = true;
        invalidRows.push(isInvalid);
      } else {
        isInvalid = false;
        invalidRows.push(isInvalid);
      }
    }
    return invalidRows.includes(true); // checking columns has any invalid value
  }

  validateCellOnValueChanged(sheet, row, col, columns, cellValue) {
    if (!isNil(cellValue)) {
      if (typeof cellValue === 'object' && Reflect.ownKeys(cellValue).length > 0) {
        return
      }
    }

    try {
      const formulaValue = typeof cellValue === 'string' && GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, cellValue, row, col);
      if (formulaValue && typeof formulaValue !== 'object') {
        cellValue = formulaValue
      }
    } catch (e) { }

    this.spreadsheet.suspend();
    const columnHeaderName = sheet.getCell(0, col).value();
    if (columnHeaderName) {
      const foundColumnName = columns.filter(validCol => validCol).find(column => column.headerName === columnHeaderName);
      if (foundColumnName && foundColumnName?.hasOwnProperty('dataType')) {
        const { dataType } = foundColumnName;
        if (dataType !== 'string') { // ignoring valdation check on string type datatype
          if (!!cellValue || isNumber(cellValue)) {
            const validCellValue = typeof cellValue === 'object' ? moment(new Date(cellValue), 'MM/DD/YYYY') : cellValue;
            const isValidValue = this.validateColumnDataType(dataType, validCellValue);
            if (isValidValue) {
              this.highlightCellValue(sheet, row, col, undefined);
              this.props.onToast({ message: '', autohide: true });
            } else {
              this.highlightCellValue(sheet, row, col, spreadColorConfig.invalidCellColor);
              this.props.onToast({ type: 'invalid-datatype', message: messages.toastMessage.INVALID_DATA_TYPE });
            }
          } else {
            this.highlightCellValue(sheet, row, col, undefined);
            this.props.onToast({ message: '', autohide: true });
          }
          this.setExistingCellFormat(sheet, row, col, dataType, cellValue);
        }
      }
    }
    this.spreadsheet.resume();
  }


  render() {
    const { sheetCount, toolbarCustomButtons, toolbarCustomRightChild, isProjectReport, showToolbar } = this.props;
    return (
      <SpreadSheets
        onReady={this.handleReady}
        ref={this.refSpreadsheet}
        sheetCount={sheetCount}
        showToolbar={showToolbar}
        isProjectReport={isProjectReport}
        toolbarCustomButtons={toolbarCustomButtons}
        toolbarCustomRightChild={toolbarCustomRightChild}
        onRowColDeletion={this.onRowColDeletion}
        onRowColInsertion={this.onRowColInsertion}
      />
    );
  }
}

DataSpreadSheet.propTypes = {
  onReady: PropTypes.func.isRequired,
  documentRef: PropTypes.object,
  documentType: PropTypes.string,
  sheetCount: PropTypes.number.isRequired,
  showToolbar: PropTypes.bool,
  toolbarCustomButtons: PropTypes.node,
  toolbarCustomRightChild: PropTypes.node,
  getRowColor: PropTypes.func,
  onDocumentEvents: PropTypes.func,
  onRowsInsert: PropTypes.func,
  onToast: PropTypes.func,
  isProjectReport: PropTypes.bool
};

DataSpreadSheet.defaultProps = {
  toolbarCustomButtons: null,
  toolbarCustomRightChild: null,
  getRowColor: () => rowColors.mappedRow,
  onDocumentEvents: noopFunc,
  onRowsInsert: noopFunc,
  isProjectReport: false,
};
