import React from 'react';
import Downshift from 'downshift';

const Autocomplete = ({
  value,
  items,
  renderItem,
  onChange,
  onSelect,
  itemToString,
  shouldItemRender = () => true,
  renderInput = (props) => <input {...props} />,
  wrapperStyle = {},
  menuStyle = {},
  inputProps = {},
  wrapperProps = {},
}) => (
  <Downshift
    onSelect={onSelect}
    inputValue={value}
    itemToString={itemToString}
  >
    {({
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem,
      getRootProps,
      clearSelection
    }) => (
      <div {...wrapperProps} style={wrapperStyle}>
        <div
          {...getRootProps({}, { suppressRefError: true })}
        >
          {renderInput(getInputProps({ ...inputProps, onChange: (e) => onChange(e.target.value, { clearSelection }) }))}
        </div>
        <div {...getMenuProps({ style: menuStyle })}>
          {isOpen
            ? items
                .filter(item => shouldItemRender(item, inputValue))
                .map((item, index) => (
                  <div
                    {...getItemProps({
                      key: index,
                      index,
                      item,
                    })}
                  >
                    {renderItem(item, highlightedIndex === index)}
                  </div>
                ))
            : null}
        </div>
      </div>
    )}
  </Downshift>
);

export default Autocomplete;
