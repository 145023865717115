import React, { useEffect, useState } from 'react';
import style from './ResetPasswordFormComponent.module.scss';

function ResetPasswordConfirmationPopUp({ redirectToLogin }) {
  const [timeLeft, setTimeLeft] = useState(5);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft === 1) {
        redirectToLogin();
      }
      setTimeLeft(timeLeft - 1);
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <div>
      <div className={style.autoRedirect}>
           Redirecting to login in {timeLeft} seconds
        </div>
    </div>
  );
}

export default ResetPasswordConfirmationPopUp;
