import React from 'react';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';
import FeatureFlagForm from '../FeatureFlagForm/FeatureFlagForm';

function FeatureFlagPopups({
  onHide, showFeatureFlagPopup
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showFeatureFlagPopup &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showFeatureFlagPopup} title="Add Feature Flag">
            <FeatureFlagForm
              bindSubmission={bindSubmission}
              onSubmitSuccess={onHide}
            />
          </FormModal>
        )
      }
    </div>
  );
}

export default FeatureFlagPopups;
