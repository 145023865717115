import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  companyUsersList: {},
  allowUserToUpdateTemplate: false
};

const [
  {
    setCompanyUsersList,
    addUserInCompanyUsersList,
    removeUserFromCompanyUsersList,
    allowUserToUpdateTemplate
  },
  reducer
] = createDuck('autouw/user', {
  _initialState: initialState,

  setCompanyUsersList: companyUsersList => state => ({
    ...state,
    companyUsersList: normalize(companyUsersList, 'uuid')
  }),

  addUserInCompanyUsersList: companyUser => state => ({
    ...state,
    companyUsersList: {
      ...state.companyUsersList,
      [companyUser.uuid]: companyUser
    }
  }),

  removeUserFromCompanyUsersList: companyUser => (state) => {
    const userList = { ...state.companyUsersList };
    Reflect.deleteProperty(userList, companyUser.uuid);
    return {
      ...state,
      companyUsersList: userList
    };
  },
  allowUserToUpdateTemplate: userWithTemplateAccess => state => ({
    ...state,
    allowUserToUpdateTemplate: userWithTemplateAccess
  })
});

export {
  setCompanyUsersList,
  addUserInCompanyUsersList,
  removeUserFromCompanyUsersList,
  allowUserToUpdateTemplate
};

export default reducer;
