import React, { useState, useMemo, useCallback } from 'react';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import ListGroup from 'react-bootstrap/ListGroup';
import { uploadsStatusSelector } from '../../../store/selectors';
import { FILE_TYPES } from '../../../constants';
import style from './UploadProgress.module.scss';
import arrowIcon from '../../../assets/images/down-arrow-white.svg';

function MinimisedUploadProgress({
  uploads, uploadIds, onMinimiseClick, onCloseClick
}) {
  const counts = useMemo(() => {
    let active = 0;
    let success = 0;
    let failed = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const id of uploadIds) {
      const { status } = uploads[id];
      if (status === 'active') {
        active++;
      } else if (status === 'success') {
        success++;
      } else {
        failed++;
      }
    }

    return {
      active, success, failed
    };
  }, [uploads, uploadIds]);

  return (
    <div className={`mb-2 ${style.uploadStatusDialog} ${style.uploadProgressPopup}`}>
      <div className={`modal-header text-white ${style.modalHeader}`}>
        <Container fluid>
          <Row>
            <Col className="p-0 mr-1" xs="auto">
              <Badge variant="primary" className={`${style.badge}`}>
                Active&nbsp;
                {counts.active}
              </Badge>
            </Col>

            <Col className="p-0 mr-1" xs="auto">
              <Badge variant="success" className={`${style.badge}`}>
                Success&nbsp;
                {counts.success}
              </Badge>
            </Col>

            <Col className="p-0" xs="auto">
              <Badge variant="danger" className={`${style.badge}`}>
                Failed&nbsp;
                {counts.failed}
              </Badge>
            </Col>

            <Col className="p-0" />

            <Col xs="auto" className={`${style.modalClose}`}>
              <button type="button" className={`close ${style.close}`} onClick={onCloseClick}>
                <span aria-hidden="true">×</span>
              </button>
              <button type="button" className={`close ${style.modalButtonClose}`} onClick={onMinimiseClick}>
                <span className={style.arrowUpIcon}>
                  <img src={arrowIcon} alt="arrow up" />
                </span>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

function MaximisedUploadProgress({
  uploads, uploadIds, onCloseClick, onMinimiseClick
}) {
  return (
    <div className={`mb-2 ${style.uploadStatusDialog} ${style.uploadProgressPopup}`} id="MaximisedUploadProgress">
      <div className={`modal-header text-white ${style.modalHeader}`}>
        <Container fluid>
          <Row>
            <Col xs="auto" className={`${style.modalTitle}`}>
              <span>
                Uploading&nbsp;
                {uploadIds.length}
                {' '}
items
              </span>
            </Col>
            <Col />
            <Col xs="auto" className={`${style.modalClose}`}>
              <button type="button" className={`close ${style.close}`} onClick={onCloseClick}>
                <span aria-hidden="true">×</span>
              </button>
              <button type="button" className={`close ${style.modalButtonClose}`} onClick={onMinimiseClick}>
                <span className={style.arrowDownIcon}>
                  <img src={arrowIcon} alt="arrow down" />
                </span>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      <ListGroup className={`${style.uploadListGroup}`}>
        {
          uploadIds.map(id => (
            <ListGroup.Item key={id} className={`${style.listGroupItem}`}>
              <Container fluid>
                <Row>
                  <Col xs="auto" className="p-1">
                    <img src={FILE_TYPES[uploads[id].fileType].icon} width={22} alt="pdf file icon" />
                  </Col>
                  <Col xs="auto" className="p-1 d-flex align-items-center">
                    <small 
                      className={`${style.uploadFileName} text-with-ellipsis d-inline-block`}
                    >
                      {uploads[id].name}
                    </small>
                  </Col>
                  <Col className="p-1" />
                  <Col xs="auto" className="p-1">
                    {
                      // eslint-disable-next-line no-nested-ternary
                      uploads[id].status === 'success' ?
                        <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> :
                        uploads[id].status === 'failed' ?
                          <FontAwesomeIcon icon={faTimesCircle} className="text-danger" /> :
                          <FontAwesomeIcon icon={faSpinner} className="text-primary" />
                    }
                  </Col>
                </Row>
              </Container>
            </ListGroup.Item>
          ))
        }
      </ListGroup>

    </div>
  );
}


function UploadProgress({ uploads, documentsService }) {
  const [showMinView, setShowMinView] = useState(false);
  const uploadIds = useMemo(() => Reflect.ownKeys(uploads), [uploads]);

  const onMinimiseClick = useCallback(() => setShowMinView(!showMinView), [showMinView]);
  const onCloseClick = useCallback(() => documentsService.clearUploadQueue(), [documentsService]);

  if (uploadIds.length === 0) {
    return (<></>);
  }

  if (showMinView) {
    return <MinimisedUploadProgress 
      onCloseClick={onCloseClick} 
      onMinimiseClick={onMinimiseClick} 
      uploadIds={uploadIds} uploads={uploads} 
    />;
  }

  return <MaximisedUploadProgress 
    uploads={uploads} uploadIds={uploadIds} 
    onMinimiseClick={onMinimiseClick} 
    onCloseClick={onCloseClick} 
  />;
}

const mapStateToProps = state => ({
  uploads: uploadsStatusSelector(state)
});

export default connect(mapStateToProps)(withServices('documentsService')(UploadProgress));
