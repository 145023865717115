import React from 'react';
import { withServices } from 'reaf';

function Link({
  route, params = {}, to, onClick, children, router, ...others
}) {
  const url = route === undefined ? to : router.getUrl(route, params);

  function onLinkClick(event) {
    if (onClick) {
      onClick(event);
    }

    if (event.isDefaultPrevented()) {
      return;
    }

    event.preventDefault();
    router.setLocation(url);
  }

  return (
    <a href={url} onClick={onLinkClick} {...others}>
      {children}
    </a>
  );
}

export default withServices('router')(Link);
