import React from 'react';
import {
  Card, ListGroup
} from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { withServices } from 'reaf';
import { displayDateFormat } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';

const DocumentListItem = ({ document, ticket, documentsService }) => (
  <>
    <Card>
      <Card.Body>
        <Card.Title>{document.fileName}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Document</Card.Subtitle>
        {document.TaggingData && (
          <Card.Text>
            <ListGroup>
              <ListGroup.Item>
                Document Type:
                {' '}
                {document.TaggingData.documentType}
              </ListGroup.Item>
              {document.TaggingData.asOnDate && (
                <ListGroup.Item>
                  As on Date:
                  {' '}
                  {displayDateFormat(document.TaggingData.asOnDate)}
                </ListGroup.Item>
              )
              }
              {document.TaggingData.periodFrom && (
                <ListGroup.Item>
                  Period From:
                  {' '}
                  {displayDateFormat(document.TaggingData.periodFrom)}
                </ListGroup.Item>
              )}
              {document.TaggingData.periodTo && (
                <ListGroup.Item>
                  Period To:
                  {' '}
                  {displayDateFormat(document.TaggingData.periodTo)}
                </ListGroup.Item>
              )}
              {document.TaggingData.splitFrom && (
                <ListGroup.Item>
                  Split From:
                  {' '}
                  {document.TaggingData.splitFrom}
                </ListGroup.Item>
              )}
              {document.TaggingData.splitTo && (
                <ListGroup.Item>
                  Split To:
                  {' '}
                  {document.TaggingData.splitTo}
                </ListGroup.Item>
              )}
              {document.TaggingData.sheet && (
                <ListGroup.Item>
                  Sheet:
                  {' '}
                  {document.TaggingData.sheet}
                </ListGroup.Item>
              )}
            </ListGroup>
          </Card.Text>
        )
        }
        <IconButton
          className="ml-2"
          onClick={(e) => {
            e.stopPropagation();
            documentsService.getAdminDocumentFile(ticket.Company, ticket.Project, document);
          }}
          variant="outline-primary"
          title="Download Attachment"
          icon={faDownload}
        />
      </Card.Body>
    </Card>
  </>
);

export default withServices('documentsService')(DocumentListItem);
