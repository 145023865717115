import React from 'react';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import TermsAndConditions from '../../app/TermsAndConditions/TermsAndConditions';

function TermsAndConditionsPage() {
  return (
    <LoggedOutUserPage>
      <TermsAndConditions style={{}} />
    </LoggedOutUserPage>
  );
}

export default TermsAndConditionsPage;
