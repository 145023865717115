/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-depth */
/* eslint-disable max-len */
/* eslint-disable max-params */
/* eslint-disable array-callback-return */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import * as PropTypes from 'prop-types';
import { withServices } from 'reaf';
import {
  find,
  groupBy,
  isArray,
  isEmpty,
  isNaN,
  isNil,
  map,
  mergeWith,
  pick,
  round,
  sortBy,
  sum,
  sumBy,
  uniqBy
} from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  DataSpreadSheet,
  SOURCE_SHEET,
  SOURCE_SHEET_NAME,
  SUMMARY_SHEET_NAME,
  TARGET_SHEET,
  TARGET_SHEET_NAME,
  WORKING_SHEET_NAME
} from './DataSpreadSheet';
import SpreadSheetToolbarButton from '../../core/Spreadjs/Toolbar/SpreadSheetToolbarButton';
import { reverseRowSignCommand } from './Commands/ReverseRowSignCommand';
import { osCellChangedEvent } from './Events/OSCellChangedEvent';
import { osClipboardPastingEvent } from './Events/OSClipboardPastingEvent';
import { osUpdateRowColorCommand } from './Commands/OSUpdateRowColorCommand';
import { osDragFillCommand } from './Commands/OSDragFillCommand';
import { osDragFillBlockEvent } from './Events/OSDragFillBlockEvent';
import {
  currentProjectCategorySequenceSelector,
  currentProjectTemplateHeadCategoriesSelector,
  currentProjectTemplateMappingsSelector,
  excludedCategoriesSelector,
  workbookDataSelector,
  currentProjectAssetTypeSelector,
  currentDocumentTaggingInfoSelector,
  projectDictionaryExistsSelector,
  dynamicAssetTypesAsObjSelector
} from '../../../store/selectors';
import {
  affixComponentName,
  cellNumberFormat,
  CLIK_OS_CATEGORY_MAPPING,
  DocumentFileTypes,
  RED_BACKGROUND_BASE64_IMG,
  sheetIndex,
  sheetNames,
  summaryConstant,
  summarySheetHeaders,
  summarySheetHeadSequence
} from '../../../constants';
import {
  setNetRentalIncome,
  setRowReverseMapping,
  setSummaryData
} from '../../../store/currentDocument';
import {
  capitalizeFirstLetter,
  compareTwoStrings,
  fetchBaseTypeByAssetType,
  isNotEmptyOrNaNValue,
  isNumberBetweenRange,
  isStringType
} from '../../../utils/utils';
import coreStyle from '../../core/Spreadjs/SpreadSheets.module.scss';
import DocExtractionTaggingInfo from '../DocExtractionTaggingInfo/DocExtractionTaggingInfo';
import { noopFunc } from '../../../lib/utils/noop';
import { KeyboardShortcutsOpened } from '../../../constants/eventTrackerMessage';
import messages from '../../../../locales/en-US';

export const WORKING_SHEET = 2;

const rowColors = {
  'Income': '#daeeff',
  'Expense': '#fff1f1',
  'Capital Expense': '#feffb6',
  'mappedRow': '#eee',
  'matchedRow': '#dddddd',
  'NOI': '#d9f8fd',
  'Noi': '#d9f8fd'
};

const {
  sourceSheetIndex,
  targetSheetIndex,
  workingSheetIndex,
  summarySheetIndex
} = sheetIndex;

const { summarySheetName } = sheetNames;

class OSSpreadSheet extends React.Component {
  constructor(props) {
    super(props);
    this.refDataSheet = React.createRef();
    this.rowReverseMapping = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.workbookData &&
      prevProps.workbookData !== this.props.workbookData
    ) {
      this.workbook.fromJSON(this.props.workbookData);
    } else if (prevProps.data !== this.props.data) {
      this.loadData(this.props.data);
    }
    this.props.onInitManager(this);
  }

  get datasheet() {
    return this.refDataSheet.current;
  }

  get spreadsheet() {
    return this.datasheet.spreadsheet;
  }

  get workbook() {
    return this.spreadsheet.workbook;
  }

  /*************************************************************************************************/
  // Spread core features that are used for basic OS events, commands, core functions etc
  /*************************************************************************************************/

  getCategories() {
    const { projectTemplateHeadCategories } = this.props;
    if (projectTemplateHeadCategories) {
      return projectTemplateHeadCategories || [];
    }
  }

  handleReady = () => {
    this.loadData();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onInitManager(this);
  };

  loadData = () => {
    const { data, workbookData, onlySource } = this.props;
    if (!(data || workbookData) || !this.datasheet || !this.spreadsheet) {
      setTimeout(() => {
        this.loadData();
        if (onlySource) {
          this.removeAllSheetsExceptSourceSheet();
        }
      }, 100);
      return;
    }

    if (workbookData) {
      this.loadDataFromWorkbookData();
    } else {
      this.loadDataFromRawData();
    }

    if (!onlySource) {
      this.registerCommands();
      this.registerEvents();
      this.setAffixSummaryData();
      this.setShowHideMonthColumnsOnLoad();
      this.getRentalIncomeInfo();
      this.datasheet.handleColumnsFormat();
      this.setMonthTotalAmount();
      this.spreadsheet.workbook.refresh(); // Refreshing sheet to adjust sheet viewport
    } else {
      this.removeAllSheetsExceptSourceSheet();
    }
  };

  loadDataFromWorkbookData = () => {
    const { workbookData } = this.props;
    this.workbook.fromJSON(workbookData);
    this.addStyleToSummarySheet();
    this.setSummarySheetData();
  };

  loadDataFromRawData = () => {
    const { data, projectTemplateHeadCategories, onlySource } = this.props;
    const sourceRows = data?.source.rows || [];
    const sourceColumns = data?.source.columns || [];
    const extractedRows = data?.target.rows || [];
    const extractedColumns = data?.target.columns || [];
    const categories = projectTemplateHeadCategories;

    const extractedHeaderRow = extractedColumns.reduce((header, column) => {
      header[column.name] = column.headerName;
      return header;
    }, {});

    const sourceHeaderRow = sourceColumns.reduce((header, column) => {
      header[column.name] = column.headerName;
      return header;
    }, {});

    this.spreadsheet.suspend();
    this.loadSourceData(sourceColumns, [sourceHeaderRow, ...sourceRows]);
    if (!onlySource) {
      this.loadExtractedData(
        extractedColumns,
        [extractedHeaderRow, ...extractedRows],
        categories
      );
      this.setSummarySheetData();
      this.highlightLineItems(data.target);
      this.datasheet.setSheetData(
        workingSheetIndex,
        [],
        [],
        WORKING_SHEET_NAME,
        false
      );
      this.spreadsheet.setSheetName(summarySheetIndex, SUMMARY_SHEET_NAME);
      this.datasheet.highlightMappedRows(
        data.source,
        data.target,
        (targetRow) => (targetRow.head ? rowColors[targetRow.head] : undefined)
      );
      this.datasheet.setCellBorder(data);
      this.datasheet.handleColumnsFormat();
    }
    this.spreadsheet.resume();
  };

  loadSourceData = (columns, rows) => {
    this.datasheet.setSheetData(
      SOURCE_SHEET,
      columns,
      rows,
      SOURCE_SHEET_NAME,
      true,
      false,
      false
    );
    this.spreadsheet.getSheet(SOURCE_SHEET).frozenColumnCount(1);
  };

  loadExtractedData = (columns, rows, categories) => {
    this.datasheet.setSheetData(
      TARGET_SHEET,
      columns,
      rows,
      TARGET_SHEET_NAME,
      false,
      true,
      true
    );
    this.datasheet.setDropDownColumns(TARGET_SHEET, { columns, rows });
    this.addStyleToSummarySheet();
    this.setCellFormatInUSNumber();
    this.setupCategoryDropdown(columns, rows, categories);
    this.spreadsheet.getSheet(TARGET_SHEET).frozenRowCount(1);
    this.spreadsheet.getSheet(TARGET_SHEET).frozenColumnCount(1);
    this.allColumnsList = columns;
  };

  removeAllSheetsExceptSourceSheet() {
    this.spreadsheet.suspend();
    const sheetCount = this.spreadsheet.workbook.sheets.length;
    for (let i = 1; i < sheetCount; i++) {
      this.spreadsheet.workbook.removeSheet(1);
    }
    this.spreadsheet.resume();
  }

  operatingStatementEvents() {
    this.setAffixSummaryData();
    this.getRentalIncomeInfo();
    // this.highlightVacancyLossAmountCell();
  }

  addStyleToSummarySheet() {
    this.spreadsheet.addStyle(TARGET_SHEET, 'boldCellStyle', {
      font: 'bold 11pt Calibri'
    });

    this.spreadsheet.addStyle(summarySheetIndex, 'summaryTotalStyle', {
      hAlign: true,
      vAlign: true
    });

    this.spreadsheet.addStyle(summarySheetIndex, 'summaryHeaderStyle', {
      hAlign: true,
      vAlign: true,
      font: 'bold 11pt Calibri'
    });

    this.spreadsheet.addStyle(summarySheetIndex, 'staticSummaryHeader', {
      backColor: 'rgb(23, 102, 155)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });

    this.summaryStyle = {
      SUMMARY_TOTAL: 'summaryTotalStyle',
      SUMMARY_HEADER: 'summaryHeaderStyle',
      SUMMARY_STATIC_HEADER: 'staticSummaryHeader'
    };

    this.headerStyleMap = {
      BOLD_CELL_STYLE: 'boldCellStyle'
    };
  }

  setupCategoryDropdown(columns, rows, categories) {
    const categoryColumnIndex = columns.findIndex(
      (column) => column.name === 'category'
    );
    const subCategoryColumnIndex = columns.findIndex(
      (column) => column.name === 'sub_category'
    );
    if (categoryColumnIndex !== -1) {
      rows.forEach((rowObj, row) => {
        if (Reflect.ownKeys(rowObj).length > 0) {
          row &&
            this.setCategoryDropdown(
              row,
              categoryColumnIndex,
              categories,
              rowObj.head
            );
          row &&
            subCategoryColumnIndex !== -1 &&
            this.setSubCategoryDropdown(
              row,
              subCategoryColumnIndex,
              categories,
              rowObj.head,
              rowObj.category
            );
        }
      });
    }
  }

  setCategoryDropdown(row, categoryColumnIndex, categories, head) {
    const headCategories = categories[head] || [];
    if (!Array.isArray(headCategories) && typeof headCategories === 'object') {
      categories = Reflect.ownKeys(headCategories).sort();
    } else {
      categories = headCategories.sort();
    }
    this.datasheet.setDropDownList(
      TARGET_SHEET,
      row,
      categoryColumnIndex,
      categories
    );
    return categories.length ? categories[0] : 'Select Category';
  }

  setSubCategoryDropdown(
    row,
    subCategoryColumnIndex,
    categories,
    head = null,
    category = null
  ) {
    let subCategories = [];
    if (subCategoryColumnIndex !== -1) {
      if (head !== null) {
        const headCategories = categories[head];
        if (
          !Array.isArray(headCategories) &&
          typeof headCategories === 'object'
        ) {
          subCategories = headCategories[category]
            ? headCategories[category].sort()
            : [];
        }
      }

      this.datasheet.setDropDownList(
        TARGET_SHEET,
        row,
        subCategoryColumnIndex,
        subCategories
      );
      return subCategories.length ? subCategories[0] : 'Select Sub Category';
    }
  }

  updateCategoryCombo(row, head) {
    const categoryColumnIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Category'
    ).foundColumnIndex;
    return this.setCategoryDropdown(
      row,
      categoryColumnIndex,
      this.getCategories(),
      head
    );
  }

  updateSubCategoryCombo(row, head, category) {
    const subCategoryColumnIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Sub Category'
    ).foundColumnIndex;
    const defaultSubCategoryValue = this.setSubCategoryDropdown(
      row,
      subCategoryColumnIndex,
      this.getCategories(),
      head,
      category
    );
    setTimeout(
      () =>
        this.spreadsheet.setCellRangeAttr(
          TARGET_SHEET,
          row,
          subCategoryColumnIndex,
          1,
          1,
          'backColor',
          rowColors[head]
        ),
      0
    );
    return defaultSubCategoryValue;
  }

  onTargetHeadValueChange(
    row,
    col,
    editingText,
    sourceColCount = -1,
    targetSheetColCount = -1,
    categoryValue = '',
    subCategoryValue = ''
  ) {
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(TARGET_SHEET);
    const id = sheet.getCell(row, 0).value();
    const defaultCategoryValue = this.updateCategoryCombo(row, editingText);
    const defaultSubCategoryValue = this.updateSubCategoryCombo(
      row,
      editingText,
      categoryValue
    );
    const categoryColumnIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Category'
    ).foundColumnIndex;
    const subCategoryColumnIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Sub Category'
    ).foundColumnIndex;
    this.datasheet.updateRowBackColorForColumnValue(
      SOURCE_SHEET,
      0,
      id,
      rowColors[editingText],
      0,
      sourceColCount
    );
    this.spreadsheet.setCellRangeAttr(
      TARGET_SHEET,
      row,
      0,
      1,
      targetSheetColCount,
      'backColor',
      rowColors[editingText]
    );

    this.spreadsheet.setCellValue(
      TARGET_SHEET,
      row,
      categoryColumnIndex,
      editingText === '' ? '' : categoryValue || defaultCategoryValue
    );
    this.spreadsheet.setCellValue(
      TARGET_SHEET,
      row,
      subCategoryColumnIndex,
      editingText === '' ? '' : subCategoryValue || defaultSubCategoryValue
    );
    const updatedCategoryValue = categoryValue || defaultCategoryValue;
    this.setSubCategoryDropdown(
      row,
      subCategoryColumnIndex,
      this.getCategories(),
      editingText,
      updatedCategoryValue
    );
    this.spreadsheet.resume();
  }

  onTargetCategoryValueChange(row, col, head, category) {
    const defaultSubCategoryValue = this.updateSubCategoryCombo(
      row,
      head,
      category
    );
    const subCategoryColumnIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Sub Category'
    ).foundColumnIndex;
    this.spreadsheet.setCellValue(
      TARGET_SHEET,
      row,
      subCategoryColumnIndex,
      defaultSubCategoryValue
    );
  }

  onTargetIdValueChange(row, newValue, oldValue, sourceColCount = -1) {
    // un-match old value
    this.datasheet.updateRowBackColorForColumnValue(
      SOURCE_SHEET,
      0,
      oldValue,
      undefined,
      0,
      sourceColCount
    );
    // match new value
    this.datasheet.updateRowBackColorForColumnValue(
      SOURCE_SHEET,
      0,
      newValue,
      this.getRowColor(row),
      0,
      sourceColCount
    );
  }

  registerCommands() {
    this.spreadsheet.registerCustomCommand(
      'reverseRowSign',
      reverseRowSignCommand,
      this
    );
    this.spreadsheet.registerCustomCommand(
      'osUpdateRowColorCommand',
      osUpdateRowColorCommand,
      this
    );
    this.spreadsheet.registerCustomCommand(
      'osDragFillCommand',
      osDragFillCommand,
      this
    );
  }

  registerEvents() {
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.CellChanged,
      osCellChangedEvent,
      this
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.ClipboardPasting,
      osClipboardPastingEvent,
      this
    );
    this.spreadsheet.registerEvent(
      this.spreadsheet.GC.Spread.Sheets.Events.DragFillBlock,
      osDragFillBlockEvent,
      this
    );
  }

  setShowHideMonthColumnsOnLoad() {
    this.spreadsheet.suspend();
    const { data } = this.props;
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    if (sheet) {
      const months = map(
        data?.target.columns.filter(
          (month) =>
            !isNil(month) &&
            moment(month.headerName, 'MMMM YYYY')._isValid &&
            month
        ),
        'headerName'
      );
      const filteredMonths =
        months.length >= 3 ? months.concat('Total') : months;
      if (months.length < 3) {
        filteredMonths.forEach((month) => {
          const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
            targetSheetIndex,
            0,
            month
          );
          sheet.setColumnWidth(foundColumnIndex, 0);
          sheet.setColumnResizable(
            foundColumnIndex,
            false,
            this.spreadsheet.GC.Spread.Sheets.SheetArea.colHeader
          );
        });
      } else {
        filteredMonths.forEach((month) => {
          const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
            targetSheetIndex,
            0,
            month
          );
          sheet.setColumnWidth(foundColumnIndex, 130);
          sheet.setColumnResizable(
            foundColumnIndex,
            true,
            this.spreadsheet.GC.Spread.Sheets.SheetArea.colHeader
          );
        });
      }
    }
    this.spreadsheet.resume();
  }

  toggleMonths = () => {
    this.spreadsheet.suspend();
    const { data } = this.props;
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    if (sheet) {
      const months = map(
        data?.target.columns.filter(
          (month) =>
            !isNil(month) &&
            moment(month.headerName, 'MMMM YYYY')._isValid &&
            month
        ),
        'headerName'
      );
      const filteredMonths =
        months.length >= 3 ? months.concat('Total') : months;
      filteredMonths.forEach((month) => {
        const { foundColumnIndex } = this.datasheet.customSearchColumnWithValue(
          targetSheetIndex,
          0,
          month
        );
        if (foundColumnIndex !== -1) {
          const columnWidth = sheet.getColumnWidth(
            foundColumnIndex,
            this.spreadsheet.GC.Spread.Sheets.SheetArea.viewport
          );
          if (columnWidth > 0) {
            sheet.setColumnWidth(foundColumnIndex, 0);
            sheet.setColumnResizable(
              foundColumnIndex,
              false,
              this.spreadsheet.GC.Spread.Sheets.SheetArea.colHeader
            );
          } else {
            sheet.setColumnWidth(foundColumnIndex, 130);
            sheet.setColumnResizable(
              foundColumnIndex,
              true,
              this.spreadsheet.GC.Spread.Sheets.SheetArea.colHeader
            );
          }
        }
      });
    }
    this.spreadsheet.resume();
  };

  getRowColor = (rowIndex) => {
    const headIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Head'
    ).foundColumnIndex;
    return rowColors[
      this.spreadsheet.getCellValue(TARGET_SHEET, rowIndex, headIndex)
    ];
  };

  getRowColorByHead(head) {
    return rowColors[head];
  }

  onRowsInsert = (sheet, rowIndex, rowCount) => {
    const headIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Head'
    ).foundColumnIndex;
    const categoryIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Category'
    ).foundColumnIndex;
    this.datasheet.copyColumnsFromPreviousRow(
      sheet,
      [headIndex, categoryIndex],
      rowIndex,
      rowCount
    );
  };

  fetchMontlyDataFromDoc(months, sourceValue, sourceColumnIndex) {
    const columnValues = [];
    const rowCount = this.spreadsheet.getSheet(targetSheetIndex).getRowCount();
    this.spreadsheet.suspend();
    for (let i = 0; i < months.length; i++) {
      const monthColumnIndex = this.datasheet.customSearchColumnWithValue(
        targetSheetIndex,
        0,
        months[i]
      ).foundColumnIndex;
      if (monthColumnIndex !== -1) {
        const monthTotal = this.datasheet.getMatchedCellValues(
          targetSheetIndex,
          rowCount,
          sourceColumnIndex,
          monthColumnIndex,
          sourceValue
        );
        columnValues.push({
          name: moment(months[i], 'MMMM YYYY').format('MMM YY'),
          [sourceValue]: sum(monthTotal)
        });
      }
    }
    this.spreadsheet.resume();
    return columnValues;
  }

  getMappings(targetMonthColumns, sourceColumnIndex) {
    const { store, assetType, dynamicAssetTypes } = this.props;
    const templateMapping = currentProjectTemplateMappingsSelector(
      store.getState()
    ).filter((headValue) => headValue.head === 'Income' && headValue);
    if (!!templateMapping.length && !!targetMonthColumns.length) {
      const mappedColumnValues = [];
      let finalNetRentalIncomeData = null;
      const baseClass = fetchBaseTypeByAssetType(assetType, dynamicAssetTypes);
      const clikCategoryMappings = CLIK_OS_CATEGORY_MAPPING[baseClass];
      if (!isNil(clikCategoryMappings)) {
        Reflect.ownKeys(clikCategoryMappings).forEach((category) => {
          const foundMappedCategoryObj = find(templateMapping, {
            clikCategory: clikCategoryMappings[category]
          });
          const foundMappedCategory = !isNil(foundMappedCategoryObj)
            ? foundMappedCategoryObj.category
            : false;
          if (foundMappedCategory) {
            const monthsData = this.fetchMontlyDataFromDoc(
              targetMonthColumns,
              foundMappedCategory,
              sourceColumnIndex
            );
            mappedColumnValues.push(monthsData);
          }
        });

        if (mappedColumnValues.length) {
          const groupedByMonth = groupBy(mappedColumnValues.flat(), 'name');
          const filterNetRentalIncomeData = Reflect.ownKeys(groupedByMonth).map(
            (month) =>
              mergeWith({}, ...groupedByMonth[month], (obj, src) =>
                isArray(obj) ? obj.concat(src) : undefined
              )
          );
          finalNetRentalIncomeData = filterNetRentalIncomeData.map(
            (netRentalIncome) =>
              Object.assign(netRentalIncome, {
                'Net Rental Income':
                  netRentalIncome[clikCategoryMappings.RENTAL_INCOME] -
                  netRentalIncome[clikCategoryMappings.VACANCY_LOSS]
              })
          );
        }
      }

      return finalNetRentalIncomeData;
    }
  }

  /*************************************************************************************************/
  // Amout column validation & Highlight amount cell value on mismatch from total
  /*************************************************************************************************/

  setCellFormatInUSNumber() {
    const sheet = this.spreadsheet.getSheet(TARGET_SHEET);
    const columnCount = sheet.getColumnCount();
    const rowCount = sheet.getRowCount();
    const amountIndex = this.datasheet.customSearchColumnWithValue(
      TARGET_SHEET,
      0,
      'Amount'
    ).foundColumnIndex;
    const dataColumnCount = columnCount - amountIndex;

    this.datasheet.setCellFormatInUSNumber(
      TARGET_SHEET,
      1,
      amountIndex,
      rowCount,
      dataColumnCount
    );
  }

  searchColumnWithHeaderValue(value) {
    return this.datasheet.customSearchColumnWithValue(TARGET_SHEET, 0, value)
      .foundColumnIndex;
  }

  setAmountAndTotalCompareFormula(
    sheet,
    rowIndex,
    amountColIndex,
    totalColIndex
  ) {
    const rowId = rowIndex + 1;
    const ranges = [this.spreadsheet.getRange(rowIndex, amountColIndex, 1, 1)];

    const totalCell = `${this.datasheet.toAlphabetColumnName(
      totalColIndex
    )}${rowId}`;
    const amountCell = `${this.datasheet.toAlphabetColumnName(
      amountColIndex
    )}${rowId}`;
    const formula = `=ABS(${amountCell} - ${totalCell}) > 1`;

    this.datasheet.setWarningStyleRule(sheet, formula, ranges);
  }

  /*************************************************************************************************/
  // Row Reverse Sign Feature
  /*************************************************************************************************/

  reverseRowSign = () => {
    const activeSheetIndex = this.spreadsheet.getActiveSheetIndex();
    if (activeSheetIndex === TARGET_SHEET) {
      const selections = this.spreadsheet.getSelections();

      const [
        serialNumberIndex,
        headIndex,
        categoriesColIndex,
        subCategoriesColIndex
      ] = this.datasheet.searchColumnIndexes(TARGET_SHEET, [
        'S.N.',
        'Head',
        'Category',
        'Sub Category'
      ]);

      const [targetSheetData, sourceSheetData] = this.datasheet.getSheetSpecificData([
        TARGET_SHEET_NAME,
        SOURCE_SHEET_NAME
      ]);

      selections.forEach((selection) => {
        const { row, rowCount, col } = selection;

        if (col !== -1) {
          return;
        }

        const previousSerialNumberValues = this.spreadsheet.getColumnValues(
          TARGET_SHEET,
          row,
          rowCount,
          serialNumberIndex
        );
        const previousHeadValues = this.spreadsheet.getColumnValues(
          TARGET_SHEET,
          row,
          rowCount,
          headIndex
        );
        const previousCategoryValues = this.spreadsheet.getColumnValues(
          TARGET_SHEET,
          row,
          rowCount,
          categoriesColIndex
        );
        const previousSubCategoryValues = this.spreadsheet.getColumnValues(
          TARGET_SHEET,
          row,
          rowCount,
          subCategoriesColIndex
        );

        const undoState = {
          previousSerialNumberValues,
          previousHeadValues,
          previousCategoryValues,
          previousSubCategoryValues
        };

        const targetSheetColCount =
          this.datasheet.getColumnCount(targetSheetData);
        const sourceSheetColCount =
          this.datasheet.getColumnCount(sourceSheetData);
        const columnCount = { targetSheetColCount, sourceSheetColCount };

        const columnIndex = {
          serialNumberIndex,
          headIndex,
          categoriesColIndex,
          subCategoriesColIndex
        };

        this.workbook.commandManager().execute({
          cmd: 'reverseRowSign',
          isUndo: false,
          activeSheetIndex,
          undoState,
          selection,
          columnCount,
          columnIndex
        });
      });
    }
  };

  setRowReverseMappingsToStore() {
    this.spreadsheet.suspend();
    const { store } = this.props;
    const mappedRowReverseValues = [];
    const mappedRowReverse = Reflect.ownKeys(this.rowReverseMapping);
    const filteredRowReverseMapping = mappedRowReverse.length
      ? mappedRowReverse.filter((row) => this.rowReverseMapping[row])
      : [];
    const serialNumberIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'S.N.'
    ).foundColumnIndex;
    const lineItemIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Line Item'
    ).foundColumnIndex;
    const headIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Head'
    ).foundColumnIndex;
    const categoriesColIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Category'
    ).foundColumnIndex;
    const subCategoriesColIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Sub Category'
    ).foundColumnIndex;
    if (filteredRowReverseMapping.length) {
      for (let row = 0; row < filteredRowReverseMapping.length; row++) {
        const { foundRowIndex } = this.datasheet.customSearchWithColumnValue(
          targetSheetIndex,
          serialNumberIndex,
          Number(filteredRowReverseMapping[row])
        );
        if (foundRowIndex !== -1) {
          const lineItem = this.spreadsheet.getCellValue(
            targetSheetIndex,
            foundRowIndex,
            lineItemIndex
          );
          const head = this.spreadsheet.getCellValue(
            targetSheetIndex,
            foundRowIndex,
            headIndex
          );
          const category = this.spreadsheet.getCellValue(
            targetSheetIndex,
            foundRowIndex,
            categoriesColIndex
          );
          const subCategory = this.spreadsheet.getCellValue(
            targetSheetIndex,
            foundRowIndex,
            subCategoriesColIndex
          );
          mappedRowReverseValues.push({
            lineItem,
            head,
            category,
            subCategory
          });
        }
      }

      store.dispatch(setRowReverseMapping(mappedRowReverseValues));
    }
    this.spreadsheet.resume();
  }

  /*************************************************************************************************/
  // Summary Sheet Data binding
  /*************************************************************************************************/

  getSavedSummarySheetData() {
    this.spreadsheet.suspend();
    const savedSummaryData = [];
    const { store } = this.props;
    const workbookData = workbookDataSelector(store.getState());
    if (!isNil(workbookData)) {
      const {
        sheets: {
          Summary: {
            data: { dataTable }
          }
        }
      } = workbookData;
      for (let i = 1; i < Reflect.ownKeys(dataTable).length; i++) {
        if (!isNil(dataTable[i])) {
          savedSummaryData.push({
            head:
              !isNil(dataTable[i][0]) && dataTable[i][0]?.hasOwnProperty('value')
                ? dataTable[i][0].value
                : '',
            category:
              !isNil(dataTable[i][1]) && dataTable[i][1]?.hasOwnProperty('value')
                ? dataTable[i][1].value
                : '',
            isCustomAdjustment: !(
              !isNil(dataTable[i][3]) &&
              dataTable[i][3]?.hasOwnProperty('formula')
            ),
            isCommentAvailable: !!(
              !isNil(dataTable[i][4]) && dataTable[i][4]?.hasOwnProperty('value')
            )
          });
        } else {
          break;
        }
      }
    }
    this.spreadsheet.resume();
    return savedSummaryData;
  }

  getAllHeadCategoryList() {
    const { projectTemplateHeadCategories, projectHeadCategorySequence } =
      this.props;
    const headCategoryList = isNil(projectHeadCategorySequence)
      ? projectTemplateHeadCategories
      : projectHeadCategorySequence;
    const summaryHeadCategoryList = [];
    if (isNil(projectHeadCategorySequence)) {
      Reflect.ownKeys(headCategoryList).map((head) => {
        if (Array.isArray(headCategoryList[head])) {
          return headCategoryList[head].map((category) => {
            const categoryItem =
              category.trim().length === 0 ? false : category;
            summaryHeadCategoryList.push({ head, category: categoryItem });
          });
        } else {
          return Reflect.ownKeys(headCategoryList[head]).map((category) =>
            headCategoryList[head][category].map((subCategory) => {
              const categoryItem =
                category.trim().length === 0 ? false : category;
              const subCategoryItem =
                subCategory.trim().length === 0 ? false : subCategory;
              summaryHeadCategoryList.push({
                head,
                category: categoryItem,
                subCategory: subCategoryItem
              });
            })
          );
        }
      });
      return summaryHeadCategoryList.flat();
    } else {
      return projectHeadCategorySequence;
    }
  }

  getUniqueCategories(headIndex, categoryIndex, subCategoryIndex, rowCount) {
    const { projectHeadCategorySequence } = this.props;
    const isNotCategorySequence = isNil(projectHeadCategorySequence);
    const headCategoryList = this.getAllHeadCategoryList();
    const categories = Array(rowCount)
      .fill(0)
      .map((_, i) => {
        const index = i + 1;
        const category = this.spreadsheet.getCellValue(
          targetSheetIndex,
          index,
          categoryIndex
        );
        const subCategory = this.spreadsheet.getCellValue(
          targetSheetIndex,
          index,
          subCategoryIndex
        );
        const head = this.spreadsheet.getCellValue(
          targetSheetIndex,
          index,
          headIndex
        );
        // eslint-disable-next-line no-nested-ternary
        return category
          ? subCategory
            ? { head, category, subCategory }
            : { head, category }
          : null;
      })
      .filter((x) => x);
    const updatedCategoryList = [...categories, ...headCategoryList];
    const keyToPick =
      subCategoryIndex > 0 ? ['head', 'subCategory'] : ['head', 'category'];
    let uniqByCategorySubCategory = uniqBy(updatedCategoryList, (elem) =>
      JSON.stringify(pick(elem, keyToPick))
    );
    if (subCategoryIndex > -1) {
      const finalCategorySubCategory = [];
      const groupByCategory = groupBy(uniqByCategorySubCategory, 'category');
      const categoryWithSubCategories = Reflect.ownKeys(groupByCategory).reduce(
        (acc, curr) => {
          acc[curr] = uniqBy(groupByCategory[curr], 'subCategory').map(
            (data) => data.subCategory
          );
          return acc;
        },
        {}
      );

      Reflect.ownKeys(categoryWithSubCategories).forEach((category) => {
        const foundObject = updatedCategoryList.find(
          (item) => item.category === category
        );
        if (foundObject) {
          const { head, category } = foundObject;
          finalCategorySubCategory.push({ head, category, isSubCategory: false });
          const subCategories = categoryWithSubCategories[category];
          if (subCategories.length) {
            subCategories.forEach((subCategory) => {
              finalCategorySubCategory.push({
                head,
                category: subCategory,
                isSubCategory: true
              });
            });
          }
        }
      });
      uniqByCategorySubCategory = finalCategorySubCategory;
    }

    uniqByCategorySubCategory = uniqByCategorySubCategory.sort(
      (a, b) =>
        summarySheetHeadSequence.indexOf(a.head) -
        summarySheetHeadSequence.indexOf(b.head)
    );

    return isNotCategorySequence ? uniqByCategorySubCategory : headCategoryList;
  }

  setSummarySheetData() {
    /* eslint-disable */
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(summarySheetIndex);
    this.spreadsheet.addAdditionalRowsColumns(summarySheetIndex, 500, 100);
    sheet
      .getRange(
        -1,
        -1,
        1,
        -1,
        this.spreadsheet.GC.Spread.Sheets.SheetArea.viewport
      )
      .clear(this.spreadsheet.GC.Spread.Sheets.StorageType.style);
    const headIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Head'
    ).foundColumnIndex;
    const categoryIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Category'
    ).foundColumnIndex;
    let subCategoryIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Sub Category'
    ).foundColumnIndex;
    const amountIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Amount'
    ).foundColumnIndex;
    const adjustmentsIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Adjustments'
    ).foundColumnIndex;
    const notesColIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Notes'
    ).foundColumnIndex;

    const rowCount = sheet.getRowCount();
    const amountSumRangeByCategory = [];
    const colAlphabetIndex = (colIndex) =>
      this.datasheet.toAlphabetColumnName(colIndex);
    const targetRange = `${colAlphabetIndex(categoryIndex)}1:${colAlphabetIndex(
      categoryIndex
    )}${rowCount}`;
    const targetSubCategoryRange = `${colAlphabetIndex(
      subCategoryIndex
    )}1:${colAlphabetIndex(subCategoryIndex)}${rowCount}`;
    const targetConditionRange = `${colAlphabetIndex(
      headIndex
    )}1:${colAlphabetIndex(headIndex)}${rowCount}`;
    const targetSumRange = `${colAlphabetIndex(
      amountIndex
    )}1:${colAlphabetIndex(amountIndex)}${rowCount}`;
    const targetAdjustmentsSumRange = `${colAlphabetIndex(
      adjustmentsIndex
    )}1:${colAlphabetIndex(adjustmentsIndex)}${rowCount}`;
    const targetSheetNotesRange = `${colAlphabetIndex(
      notesColIndex
    )}1:${colAlphabetIndex(notesColIndex)}${rowCount}`;

    // To hide sub-category column, we had kept column width to 0
    const subCategoryColWidth = this.spreadsheet
      .getSheet(targetSheetIndex)
      .getColumnWidth(subCategoryIndex);
    subCategoryIndex = subCategoryColWidth ? subCategoryIndex : -1;

    const categories = this.getUniqueCategories(
      headIndex,
      categoryIndex,
      subCategoryIndex,
      rowCount
    );
    const storedSummaryData = this.getSavedSummarySheetData();
    // this.operatingStatementEvents();
    const isAdjustmentColumnAbsent = adjustmentsIndex === -1;
    let updatedSummarySheetHeaders = isAdjustmentColumnAbsent
      ? summarySheetHeaders.filter((header) => header !== 'Adjustments')
      : summarySheetHeaders;

    if (notesColIndex == -1) {
      updatedSummarySheetHeaders = updatedSummarySheetHeaders.filter(
        (header) => header !== 'Notes'
      );
    }

    Reflect.ownKeys(categories[0]).length !== 0 && categories.unshift({});

    // Resetting the summary sheet when template is switched or category changed from category sequence.
    if (!!storedSummaryData.length && !!categories.length) {
      if (storedSummaryData.length + 1 !== categories.length) {
        sheet.clear(
          -1,
          -1,
          -1,
          -1,
          this.spreadsheet.GC.Spread.Sheets.SheetArea.viewport,
          this.spreadsheet.GC.Spread.Sheets.StorageType.data
        );
      }
    }

    updatedSummarySheetHeaders.forEach((head, columnIndex) => {
      sheet.setValue(sourceSheetIndex, columnIndex, head);
      this.spreadsheet.setCellStyle(
        summarySheetIndex,
        0,
        columnIndex,
        'staticSummaryHeader'
      );
      sheet.setColumnWidth(columnIndex, 200);
    });

    const formulaExpr = (colTargetRange, head, category, targetSumRange) =>
      `ROUND(SUMIFS('${TARGET_SHEET_NAME}'!${targetSumRange},'${TARGET_SHEET_NAME}'!${colTargetRange},"${category}", '${TARGET_SHEET_NAME}'!${targetConditionRange}, "${head}"),2)`;

    const noteFormulaExpr = (colTargetRange, targetSumRange, row) =>
      `TEXTJOIN(";",TRUE,IF($B${row + 1
      }='${TARGET_SHEET_NAME}'!${colTargetRange},IF('${TARGET_SHEET_NAME}'!${targetSumRange}<>"", '${TARGET_SHEET_NAME}'!${targetSumRange},""),""))`;

    if (subCategoryIndex > -1) {
      this.spreadsheet.getSheet(summarySheetIndex).setColumnWidth(1, 350);
    }

    for (let categoryRow = 1; categoryRow < categories.length; categoryRow++) {
      if (isStringType(categories[categoryRow].category)) {
        if (categories[categoryRow].category.trim().length === 0) {
          sheet.setValue(categoryRow, 0, '');
          sheet.setValue(categoryRow, 1, '');
          this.spreadsheet.setCellRangeAttr(
            summarySheetIndex,
            categoryRow,
            0,
            1,
            3,
            'backColor',
            undefined
          );
        } else {
          const { head, category } = categories[categoryRow];
          sheet.setValue(categoryRow, 0, head);
          sheet.setValue(categoryRow, 1, category);

          if (subCategoryIndex > -1) {
            //SubCategories binding here
            const { isSubCategory } = categories[categoryRow];
            if (isSubCategory) {
              sheet.setFormula(
                categoryRow,
                2,
                formulaExpr(
                  targetSubCategoryRange,
                  head,
                  category,
                  targetSumRange
                )
              );
              const subCategory = sheet.getCell(categoryRow, 1);
              subCategory.textIndent(2);
            } else {
              sheet.setFormula(
                categoryRow,
                2,
                formulaExpr(targetRange, head, category, targetSumRange)
              );
              this.spreadsheet.setColumnsFont(
                sheet,
                categoryRow,
                [1, 2, 3],
                'bold 11pt Calibri'
              );
              const categoryRowIndex = categoryRow + 1;
              const cellIndex = `${colAlphabetIndex(2)}${categoryRowIndex}`;
              amountSumRangeByCategory.push({
                head,
                cellIndex,
                categoryRowIndex
              });
            }
          } else {
            sheet.setFormula(
              categoryRow,
              2,
              formulaExpr(targetRange, head, category, targetSumRange)
            );
          }

          if (adjustmentsIndex !== -1) {
            let adjustmentFormulaExpression;
            const findSummaryByHeadCategory = storedSummaryData.length
              ? storedSummaryData.find(
                (summary) =>
                  summary.head === categories[categoryRow].head &&
                  summary.category === categories[categoryRow].category
              )
              : null;

            if (subCategoryIndex > -1) {
              const { isSubCategory } = categories[categoryRow];
              if (isSubCategory) {
                adjustmentFormulaExpression = formulaExpr(
                  targetSubCategoryRange,
                  head,
                  category,
                  targetAdjustmentsSumRange
                );
              } else {
                adjustmentFormulaExpression = formulaExpr(
                  targetRange,
                  head,
                  category,
                  targetAdjustmentsSumRange
                );
              }
            } else {
              adjustmentFormulaExpression = formulaExpr(
                targetRange,
                head,
                category,
                targetAdjustmentsSumRange
              );
            }

            if (
              !isNil(findSummaryByHeadCategory) &&
              !!Reflect.ownKeys(findSummaryByHeadCategory).length
            ) {
              const { isCommentAvailable, commentValue, isCustomAdjustment } = findSummaryByHeadCategory;
              if (!isCommentAvailable) {
                sheet.setFormula(categoryRow, 3, adjustmentFormulaExpression);
                sheet.setValue(categoryRow, 4, commentValue);
              }
              !isCustomAdjustment && sheet.setFormula(categoryRow, 3, adjustmentFormulaExpression);
            }
            if (
              storedSummaryData.length === 0 ||
              storedSummaryData.length + 1 !== categories.length ||
              isNil(findSummaryByHeadCategory)
            ) {
              sheet.setFormula(categoryRow, 3, adjustmentFormulaExpression);
            }
          }

          if (notesColIndex > 0) {
            let notesFormulaExpression = '';
            if (subCategoryIndex > -1) {
              const { isSubCategory } = categories[categoryRow];
              if (isSubCategory) {
                notesFormulaExpression = noteFormulaExpr(
                  targetSubCategoryRange,
                  targetSheetNotesRange,
                  categoryRow
                );
              } else {
                notesFormulaExpression = noteFormulaExpr(
                  targetRange,
                  targetSheetNotesRange,
                  categoryRow
                );
              }
            } else {
              notesFormulaExpression = noteFormulaExpr(
                targetRange,
                targetSheetNotesRange,
                categoryRow
              );
            }
            sheet.setArrayFormula(categoryRow, 5, 1, 1, notesFormulaExpression);
            this.datasheet.setColumnReadOnly(
              summarySheetIndex,
              categoryRow,
              5,
              1,
              1
            );
          }
          let formattedColumnCount = notesColIndex > 0 ? 6 : 5;

          this.spreadsheet.setCellFormat(
            summarySheetIndex,
            categoryRow,
            2,
            cellNumberFormat
          );
          this.spreadsheet.setCellFormat(
            summarySheetIndex,
            categoryRow,
            3,
            cellNumberFormat
          );
          this.spreadsheet.setCellRangeAttr(
            summarySheetIndex,
            categoryRow,
            0,
            1,
            isAdjustmentColumnAbsent ? 4 : 5,
            'backColor',
            rowColors[capitalizeFirstLetter(categories[categoryRow].head)]
          );
          this.spreadsheet.setCellRangeAttr(
            summarySheetIndex,
            categoryRow,
            0,
            1,
            isAdjustmentColumnAbsent
              ? formattedColumnCount - 1
              : formattedColumnCount,
            'backColor',
            rowColors[capitalizeFirstLetter(categories[categoryRow].head)]
          );
        }
      }
    }

    if (subCategoryIndex > 0 && sheet.rowOutlines.getMaxLevel() === -1) {
      this.setSummaryRowOutline(
        sheet,
        amountSumRangeByCategory,
        categories.length
      );
    }

    if (subCategoryIndex === -1) {
      sheet.rowOutlines.ungroup(1, categories.length - 1);
    }

    sheet
      .getRange(
        0,
        0,
        categories.length,
        updatedSummarySheetHeaders.length,
        this.spreadsheet.GC.Spread.Sheets.SheetArea.viewport
      )
      .setBorder(
        new this.spreadsheet.GC.Spread.Sheets.LineBorder(
          '#ababab',
          this.spreadsheet.GC.Spread.Sheets.LineStyle.thin
        ),
        { all: true },
        4
      );
    this.setSummaryTotal(
      sheet,
      categories.length,
      categories,
      amountSumRangeByCategory
    );
    this.spreadsheet.resume();
    /* eslint-enable */
  }

  setSummaryRowOutline(sheet, range, totalCategories) {
    this.spreadsheet.suspend();
    const rowRanges = range.map((r) => r.categoryRowIndex);
    !!rowRanges.length &&
      rowRanges.forEach((row, i) => {
        const rowCount = rowRanges[i + 1] - row;
        if (!isNaN(rowCount)) {
          sheet.rowOutlines.group(row, rowCount - 1);
        } else {
          sheet.rowOutlines.group(row, totalCategories - row);
        }
        sheet.rowOutlines.expand(row, false);
      });
    sheet.invalidateLayout();
    this.spreadsheet.resume();
  }

  setSummaryTotal(
    sheet,
    summaryRowCount,
    categories,
    amountSumRangeByCategory
  ) {
    this.spreadsheet.suspend();
    const { OS_HEADS } = summaryConstant;
    const tableRowCount = summaryRowCount + 1;
    const style = this.summaryStyle.SUMMARY_TOTAL;
    for (let i = 0; i < OS_HEADS.length; i++) {
      sheet.setValue(tableRowCount + i, 1, OS_HEADS[i].text);
      const filteredByHead =
        !!amountSumRangeByCategory.length &&
        amountSumRangeByCategory
          .filter((item) => item.head === OS_HEADS[i].key)
          .map((col) => col.cellIndex);
      const formulaExpression = amountSumRangeByCategory.length
        ? `ROUND(SUM(${filteredByHead.join()}),2)`
        : `ROUND(SUMIF('${summarySheetName}'!A1:C${tableRowCount},"${OS_HEADS[i].key}",'${summarySheetName}'!C1:C${tableRowCount}),2)`;
      setTimeout(
        () => sheet.setFormula(tableRowCount + i, 2, formulaExpression),
        0
      );
      for (let j = 0; j < 3; j++) {
        sheet.setStyle(tableRowCount + i, j, 'summaryTotalStyle');
        this.spreadsheet.setCellFormat(
          summarySheetIndex,
          tableRowCount + i,
          j,
          cellNumberFormat
        );
      }
    }
    this.spreadsheet.resume();
    this.setSummaryNOI(
      sheet,
      style,
      tableRowCount + OS_HEADS.length,
      categories
    );
  }

  setSummaryNOI(sheet, style, rowIndex, categories) {
    this.spreadsheet.suspend();
    const { OS_NOI_NCF } = summaryConstant;
    const totalIncome = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Income'
    ).foundRowIndex;
    const totalExpense = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Expense'
    ).foundRowIndex;
    const totalCapex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Capital Expense'
    ).foundRowIndex;
    for (let i = 0; i < OS_NOI_NCF.length; i++) {
      sheet.setValue(rowIndex + i, 1, OS_NOI_NCF[i].text);
      if (OS_NOI_NCF[i].key === 'NOI') {
        sheet.setFormula(
          rowIndex + i,
          2,
          `=C${totalIncome + 1}-C${totalExpense + 1}`
        );
      } else if (OS_NOI_NCF[i].key === 'NCF') {
        sheet.setFormula(
          rowIndex + i,
          2,
          `=C${rowIndex + 1}-C${totalCapex + 1}`
        );
      } /* else if (OS_NOI_NCF[i].key === 'NOI_DIFF') {
        if (documentNOIIndex !== -1) {
          sheet.setFormula(rowIndex + i, 2, `=C${rowIndex + 1}-C${documentNOIIndex + 1}`);
        } else {
          sheet.setValue(rowIndex + i, 1, '');
        }
      }*/

      for (let j = 0; j < 3; j++) {
        sheet.setStyle(rowIndex + i, j, style);
        this.spreadsheet.setCellFormat(
          summarySheetIndex,
          rowIndex + i,
          j,
          cellNumberFormat
        );
      }
    }
    this.spreadsheet.resume();

    this.setSummaryExcludedItems(sheet, style, categories);
  }

  filterTemplateByAssetType(templateMapping, projectAssetType) {
    return templateMapping.length
      ? templateMapping.filter(
        (template) => template.assetType === projectAssetType
      )
      : [];
  }

  setSummaryExcludedItems(sheet, style, categories) {
    this.spreadsheet.suspend();
    const { projectTemplateMappings, store, currentAssetType } = this.props;
    const { WITHOUT_EXCLUDED_ITEMS } = summaryConstant;
    let excludedIncomeIndex = -1;
    let excludedExpenseIndex = -1;
    let excludedCapexIndex = -1;
    const excludedCategories = excludedCategoriesSelector(store.getState());
    const excludedMappingList = [];

    try {
      if (!isNil(projectTemplateMappings)) {
        const templateMapping = this.filterTemplateByAssetType(
          projectTemplateMappings,
          currentAssetType
        );
        const excludedClikTemplateMapping = this.filterTemplateByAssetType(
          excludedCategories,
          currentAssetType
        );

        if (templateMapping.length) {
          excludedClikTemplateMapping.forEach((template) => {
            const foundMapping = templateMapping.find((projectMapping) =>
              compareTwoStrings(
                projectMapping.clikCategory,
                template.clikCategory
              )
            );
            excludedMappingList.push(foundMapping);
          });

          categories.forEach((documentData, rowIndex) => {
            if (!isNil(documentData)) {
              const foundCategory = excludedMappingList.find(
                (mapppingData) =>
                  compareTwoStrings(mapppingData.head, documentData.head) &&
                  compareTwoStrings(mapppingData.category, documentData.category)
              );
              if (!isNil(foundCategory) && !!Reflect.ownKeys(foundCategory).length) {
                const { head } = foundCategory;
                if (compareTwoStrings(head, 'Income')) {
                  excludedIncomeIndex = rowIndex;
                }
                if (compareTwoStrings(head, 'Expense')) {
                  excludedExpenseIndex = rowIndex;
                }
              }
            }
          });
        }
      }
    } catch (e) {
      this.props.setWorkbookToast({
        message: messages.toastMessage.TEMPLATE_MAPPING_ERROR,
        autohide: false,
        type: 'invalid-on-save-datatype',
        wrapperClass: 'spread-validation-error',
        headerMessage: 'Template Mapping Error'
      });
      console.error(e)
    }


    const rowIndex =
      this.datasheet.customSearchWithColumnValue(
        summarySheetIndex,
        1,
        'Net Cash Flow'
      ).foundRowIndex + 2;
    const totalIncomeIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Income'
    ).foundRowIndex;
    const totalExpenseIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Expense'
    ).foundRowIndex;
    const totalCapexIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Capital Expense'
    ).foundRowIndex;

    for (let i = 0; i < WITHOUT_EXCLUDED_ITEMS.length; i++) {
      sheet.setValue(rowIndex + i, 1, WITHOUT_EXCLUDED_ITEMS[i].text);
      sheet.setStyle(rowIndex + i, 1, style);
      this.spreadsheet.setCellFormat(
        summarySheetIndex,
        rowIndex + i,
        2,
        cellNumberFormat
      );
      if (WITHOUT_EXCLUDED_ITEMS[i].key === 'header') {
        this.spreadsheet.setCellStyle(
          summarySheetIndex,
          rowIndex + i,
          1,
          'summaryHeaderStyle'
        );
      } else if (WITHOUT_EXCLUDED_ITEMS[i].key === 'Income') {
        const isExcludedOmittedIncome =
          excludedIncomeIndex !== -1
            ? `=C${totalIncomeIndex + 1}-C${excludedIncomeIndex + 1}`
            : `=C${totalIncomeIndex + 1}`;
        sheet.setFormula(rowIndex + i, 2, isExcludedOmittedIncome);
      } else if (WITHOUT_EXCLUDED_ITEMS[i].key === 'Expense') {
        const isExcludedOmittedExpense =
          excludedExpenseIndex !== -1
            ? `=C${totalExpenseIndex + 1}-C${excludedExpenseIndex + 1}`
            : `=C${totalExpenseIndex + 1}`;
        sheet.setFormula(rowIndex + i, 2, isExcludedOmittedExpense);
      } else if (WITHOUT_EXCLUDED_ITEMS[i].key === 'Capital Expense') {
        const isExcludedOmittedCapex =
          excludedCapexIndex !== -1
            ? `=C${totalCapexIndex + 1}-C${excludedCapexIndex + 1}`
            : `=C${totalCapexIndex + 1}`;
        sheet.setFormula(rowIndex + i, 2, isExcludedOmittedCapex);
      }
    }
    this.spreadsheet.resume();
    this.setSummaryExcludedItemsNOI(sheet, style);
  }

  setSummaryExcludedItemsNOI(sheet) {
    this.spreadsheet.suspend();
    const totalIncomeIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Income:'
    ).foundRowIndex;
    const totalExpenseIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Expense:'
    ).foundRowIndex;
    const excludedItemNoiIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Net Operating Income:'
    ).foundRowIndex;
    sheet.setFormula(
      excludedItemNoiIndex,
      2,
      `=C${totalIncomeIndex + 1}-C${totalExpenseIndex + 1}`
    );
    this.spreadsheet.resume();
    this.setSummaryExcludedItemsNCF(sheet);
  }

  setSummaryExcludedItemsNCF(sheet) {
    this.spreadsheet.suspend();
    const totalCapexIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Total Capital Expense:'
    ).foundRowIndex;
    const excludedItemNoiIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Net Operating Income:'
    ).foundRowIndex;
    const excludedItemNcfIndex = this.datasheet.customSearchWithColumnValue(
      summarySheetIndex,
      1,
      'Net Cash Flow:'
    ).foundRowIndex;
    sheet.setFormula(
      excludedItemNcfIndex,
      2,
      `=C${excludedItemNoiIndex + 1}-C${totalCapexIndex + 1}`
    );
    this.spreadsheet.resume();
  }

  setAffixSummaryData() {
    this.spreadsheet.suspend();
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    const rowCount = sheet.getRowCount();
    const headIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Head'
    ).foundColumnIndex;
    const categoryIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Category'
    ).foundColumnIndex;
    const lineItemIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Line Item'
    ).foundColumnIndex;
    const amountIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Amount'
    ).foundColumnIndex;
    const targetSheetData = Array(rowCount)
      .fill(0)
      .map((_, i) => {
        const index = i + 1;
        const category = this.spreadsheet.getCellValue(
          targetSheetIndex,
          index,
          categoryIndex
        );
        const head = this.spreadsheet.getCellValue(
          targetSheetIndex,
          index,
          headIndex
        );
        const lineItem = this.spreadsheet.getCellValue(
          targetSheetIndex,
          index,
          lineItemIndex
        );
        const serialNumber = this.spreadsheet.getCellValue(
          targetSheetIndex,
          index,
          0
        );
        const amount = this.spreadsheet.getCellValue(
          targetSheetIndex,
          index,
          amountIndex
        );
        return (
          category && {
            category,
            head,
            lineItem,
            serialNumber,
            amount
          }
        );
      })
      .filter((x) => x);

    const sortByHead = sortBy(targetSheetData, 'head').reverse();
    const groupedHeads = groupBy(sortByHead, 'head');

    const totalAmount = (key) =>
      round(
        sumBy(key, (obj) => isNotEmptyOrNaNValue(obj, 'amount') && obj.amount),
        2
      );
    const checkNullValue = (value) => (value !== null ? value : '');

    const summaryDetail = Reflect.ownKeys(groupedHeads).map((head) => {
      const heads = groupedHeads[head];
      const incomeTotal = sumBy(
        groupedHeads['Income'],
        (obj) => isNotEmptyOrNaNValue(obj, 'amount') && obj.amount
      );
      const groupedCategory = groupBy(heads, 'category');
      const categoryAmountTotal = Object.values(groupedCategory).map((item) =>
        sumBy(item, (obj) => isNotEmptyOrNaNValue(obj, 'amount') && obj.amount)
      );
      this.spreadsheet.resume();
      return {
        title: head,
        color: rowColors[head],
        total: round(totalAmount(heads), 2),
        displayTotal: round(totalAmount(heads), 2),
        showEGI: true,
        expanded: true,
        children: Reflect.ownKeys(groupedCategory).map((category, index) => ({
          title: category,
          color: rowColors[head],
          total: categoryAmountTotal[index],
          egi: round((categoryAmountTotal[index] / incomeTotal) * 100, 2),
          displayTotal: round(categoryAmountTotal[index], 2),
          children: groupedCategory[category].map((item) => ({
            title: `${checkNullValue(item.lineItem)}`,
            displayTotal: !isNaN(item.amount) ? round(item.amount, 2) : 0,
            color: rowColors[head]
          }))
        }))
      };
    });

    const summaryTotal = this.getSummaryTotal(summaryDetail);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.store.dispatch(setSummaryData({ summaryDetail, summaryTotal }));
  }

  getSummaryTotal(summaryData) {
    if (summaryData.length > 0) {
      this.spreadsheet.suspend();
      const SUMMARY_TOTAL = summaryData.map((item) => ({
        head: item.title.replace(/ /g, ''),
        color: item.color,
        total: round(item.total, 2)
      }));
      const { Expense, Income, CapitalExpense } = groupBy(SUMMARY_TOTAL, 'head');
      const IncomeTotal = isEmpty(Income) ? 0 : Income[0].total;
      const ExpenseTotal = isEmpty(Expense) ? 0 : Expense[0].total;
      const SUMMARY_NOI = round(IncomeTotal - ExpenseTotal, 2);
      const SUMMARY_NCF = round(
        SUMMARY_NOI - (isEmpty(CapitalExpense) ? 0 : CapitalExpense[0].total),
        2
      );
      this.spreadsheet.resume();
      return { SUMMARY_TOTAL, SUMMARY_NOI, SUMMARY_NCF };
    }
  }

  /*************************************************************************************************/
  // Handling Line Items on dictionary prediction
  /*************************************************************************************************/

  highlightLineItems(targetData) {
    this.spreadsheet.suspend();
    const { store } = this.props;
    const lineItemColumnIndex = targetData.columns.findIndex(
      (column) => column.name === 'line_item'
    );
    const isProjectDictionaryExists = projectDictionaryExistsSelector(
      store.getState()
    );
    if (lineItemColumnIndex !== -1) {
      targetData.rows.forEach((rowObj, row) => {
        if (!!Reflect.ownKeys(rowObj).length && row > 0 && rowObj.options) {
          if (isProjectDictionaryExists) {
            const {
              options: { lineItemMatch }
            } = rowObj;
            if (!isNil(lineItemMatch) && !lineItemMatch) {
              const rowIndex = row + 1;
              this.spreadsheet.setCellStyle(
                targetSheetIndex,
                rowIndex,
                lineItemColumnIndex,
                this.headerStyleMap.BOLD_CELL_STYLE
              );
            }
          }
        }
      });
    }
    this.spreadsheet.resume();
  }

  /*************************************************************************************************/
  // Handling Amount column
  /*************************************************************************************************/

  onHighlightAmountCell(columnIndex = null) {
    if (columnIndex) {
      const headIndex = this.datasheet.customSearchColumnWithValue(
        targetSheetIndex,
        0,
        'Head'
      ).foundColumnIndex;
      const categoryIndex = this.datasheet.customSearchColumnWithValue(
        targetSheetIndex,
        0,
        'Category'
      ).foundColumnIndex;
      const subCategoryIndex = this.datasheet.customSearchColumnWithValue(
        targetSheetIndex,
        0,
        'Sub Category'
      ).foundColumnIndex;
      if (
        columnIndex !== headIndex &&
        columnIndex !== categoryIndex &&
        columnIndex !== subCategoryIndex
      ) {
        this.setMonthTotalAmount(columnIndex);
      }
    }
  }

  setMonthTotalAmount(columnIndex = null) {
    const serialNumberColIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'S.N.'
    ).foundColumnIndex;
    const amountColumnIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Amount'
    ).foundColumnIndex;
    const adjustmentColumnIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Adjustments'
    ).foundColumnIndex;
    const totalColumnIndex = this.datasheet.customSearchColumnWithValue(
      targetSheetIndex,
      0,
      'Total'
    ).foundColumnIndex;
    const {
      targetSheetData: {
        data: { dataTable }
      }
    } = this.datasheet.getSourceTargetData();
    const startColumnIndex =
      adjustmentColumnIndex === -1 ? amountColumnIndex : adjustmentColumnIndex;
    const sheet = this.spreadsheet.getSheet(targetSheetIndex);
    const colAlphabetIndex = (colIndex) =>
      this.spreadsheet.toAlphabetColumnName(colIndex);

    this.spreadsheet.suspend();
    if (startColumnIndex !== -1 && totalColumnIndex !== -1) {
      const colCount = Reflect.ownKeys(dataTable[0]).length - 1; // dataTable consists column info at zero index
      const monthColCount = colCount - startColumnIndex - 1;
      if (monthColCount > 0) {
        monthColCount > 2
          ? sheet.setColumnWidth(totalColumnIndex, 150)
          : sheet.deleteColumns(totalColumnIndex, 1);
        for (let i = 1; i < Reflect.ownKeys(dataTable).length; i++) {
          const rowIndex = i + 1;
          const amountColValue =
            this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
              sheet,
              `=VALUE(${colAlphabetIndex(amountColumnIndex)}${rowIndex})`
            );
          const totalColValue =
            this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
              sheet,
              `=VALUE(${colAlphabetIndex(totalColumnIndex)}${rowIndex})`
            );

          const serialNumber = this.spreadsheet.getCellValue(
            targetSheetIndex,
            i,
            serialNumberColIndex
          );
          if (!isNil(amountColValue) && !isNil(serialNumber)) {
            const monthsColValue =
              this.spreadsheet.GC.Spread.Sheets.CalcEngine.evaluateFormula(
                sheet,
                `=SUM(${colAlphabetIndex(
                  startColumnIndex + 1
                )}${rowIndex}:${colAlphabetIndex(colCount - 1)}${rowIndex})`
              );
            let totalAmountValue = 0;
            if (!!columnIndex && columnIndex < colCount) {
              // checking if edited column is month column
              totalAmountValue = monthsColValue;
              //syncing up the months sum with total column total.
              this.spreadsheet.setCellValue(
                targetSheetIndex,
                i,
                totalColumnIndex,
                totalAmountValue
              );
            } else {
              totalAmountValue = totalColValue;
            }
            if (monthColCount > 2 && totalColumnIndex !== -1) {
              this.compareAmountWithTotals(
                sheet,
                i,
                amountColumnIndex,
                amountColValue,
                totalAmountValue
              );
            }
          }
        }
      }
    }
    this.spreadsheet.resume();
  }

  compareAmountWithTotals(
    sheet,
    rowIndex,
    amountColumnIndex,
    amountColValue,
    totalColValue
  ) {
    if (this.props.isEqualTaggedPeriodToMLPeriod) {
      if (!isNil(amountColValue) && !isNil(totalColValue)) {
        const maxNumber = Math.floor(totalColValue) + 1;
        const minNumber = Math.floor(totalColValue) - 1;
        const amountValue = Math.floor(amountColValue);
        if (!isNumberBetweenRange(amountValue, minNumber, maxNumber)) {
          sheet
            .getCell(rowIndex, amountColumnIndex)
            .backgroundImage(RED_BACKGROUND_BASE64_IMG);
        } else {
          sheet.getCell(rowIndex, amountColumnIndex).backgroundImage(undefined);
        }
      }
    }
  }

  /*************************************************************************************************/
  // Getting Rental Income Features
  /*************************************************************************************************/

  getRentalIncomeInfo() {
    const { store, data } = this.props;
    this.spreadsheet.suspend();
    const filteredMonths = map(
      data?.target.columns.filter(
        (month) =>
          !isNil(month) &&
          moment(month.headerName, 'MMMM YYYY')._isValid &&
          month
      ),
      'headerName'
    );
    if (filteredMonths.length > 0) {
      const categoryColumnIndex = this.datasheet.customSearchColumnWithValue(
        targetSheetIndex,
        0,
        'Category'
      ).foundColumnIndex;
      const netRentalIncome = this.getMappings(
        filteredMonths,
        categoryColumnIndex
      );
      store.dispatch(setNetRentalIncome(netRentalIncome));
    }
    this.spreadsheet.resume();
  }

  highlightVacancyLossAmountCell() {
    /* eslint-disable */
    const { store, assetType } = this.props;
    this.spreadsheet.suspend();
    const rowCount = this.spreadsheet.getSheet(targetSheetIndex).getRowCount();
    const templateMapping = currentProjectTemplateMappingsSelector(
      store.getState()
    ).filter((headValue) => headValue.head === 'Income' && headValue);
    const baseClass = fetchBaseTypeByAssetType(assetType, dynamicAssetTypes);
    const clikCategoryMappings = CLIK_OS_CATEGORY_MAPPING[baseClass];

    if (!!templateMapping.length && !isNil(clikCategoryMappings)) {
      const vacancyLossCategory = templateMapping.find(
        (category) =>
          category.clikCategory === clikCategoryMappings.VACANCY_LOSS
      );
      if (!isNil(vacancyLossCategory)) {
        const { category } = vacancyLossCategory;
        const categoryColumnIndex = this.datasheet.customSearchColumnWithValue(
          targetSheetIndex,
          0,
          'Category'
        ).foundColumnIndex;
        const amountColumnIndex = this.datasheet.customSearchColumnWithValue(
          targetSheetIndex,
          0,
          'Amount'
        ).foundColumnIndex;
        const foundRows = this.datasheet.customSearchWithColumnValues(
          targetSheetIndex,
          categoryColumnIndex,
          category,
          { startRow: 1 }
        );
        const sheet = this.datasheet.getSheet(targetSheetIndex);
        const dataValidator =
          this.spreadsheet.GC.Spread.Sheets.DataValidation.createNumberValidator(
            this.spreadsheet.GC.Spread.Sheets.ConditionalFormatting
              .ComparisonOperators.lessThan,
            0
          );

        if (foundRows.length) {
          for (let row = 1; row < rowCount; row++) {
            if (foundRows.includes(row)) {
              const amountValue = this.spreadsheet.getCellValue(
                targetSheetIndex,
                row,
                amountColumnIndex
              );
              this.spreadsheet.workbook.options.highlightInvalidData = true;
              if (
                !isNaN(amountValue) &&
                amountValue !== '' &&
                Math.sign(amountValue) === 1
              ) {
                dataValidator.showInputMessage(true);
                dataValidator.ignoreBlank(true);
                dataValidator.inputMessage(
                  `${category} must be a negative value.`
                );
                dataValidator.inputTitle('Note');
                dataValidator.highlightStyle({
                  type: this.spreadsheet.GC.Spread.Sheets.DataValidation
                    .HighlightType.circle,
                  color: 'red'
                });
                sheet.setDataValidator(row, amountColumnIndex, dataValidator);
              }
            } else {
              const isValidatorExist = sheet.getDataValidator(
                row,
                amountColumnIndex
              );
              !isNil(isValidatorExist) &&
                sheet.setDataValidator(row, amountColumnIndex, null);
            }
          }
        }
      }
    }
    this.spreadsheet.resume();
    /* eslint-enable */
  }

  onSaveWorkbook() {
    const { onSaveData } = this.props;
    const workbookData = this.spreadsheet.getWorkbookData();
    onSaveData(workbookData);
  }

  render() {
    const {
      className,
      style,
      taggingInfo,
      onSlidePaneOpen,
      onRefereceWindowOpen,
      document,
      project,
      sheetCount,
      showToolbar
    } = this.props;
    return (
      <div className={`${className} vertical-section`} style={style}>
        <DataSpreadSheet
          toolbarCustomButtons={
            <>
              <SpreadSheetToolbarButton
                title="Reverse Row Sign"
                id="reverseRowSign"
                className={coreStyle.reverseSignIcon}
                onClick={this.reverseRowSign}
              />
              <div
                className={`position-relative d-inline-block ${coreStyle.spreadsheetToolbarSeparator}`}
                aria-disabled="true"
                role="separator line"
              >
                {' '}
                &nbsp;{' '}
              </div>
              <SpreadSheetToolbarButton
                title="Show/Hide Months"
                id="toggleMonths"
                className={coreStyle.calendarMonthsIcon}
                onClick={this.toggleMonths}
              />
            </>
          }
          toolbarCustomRightChild={
            <>
              <div className={`${coreStyle.spreadsheetSideToolbar} border-0`}>
                <DocExtractionTaggingInfo
                  docType={DocumentFileTypes.OPERATING_STATEMENT.key}
                  taggingInfo={taggingInfo}
                  project={project}
                  document={document}
                />

                <div
                  className={`position-relative d-inline-block ${coreStyle.spreadsheetToolbarSeparator}`}
                  aria-disabled="true"
                  role="separator line"
                >
                  {' '}
                  &nbsp;{' '}
                </div>
                <SpreadSheetToolbarButton
                  id="DownloadXLSXFile"
                  title="Download Workbook"
                  size="lg"
                  className={coreStyle.downloadFileIcon}
                  onClick={() => this.datasheet.downloadXLS(document)}
                />
                <SpreadSheetToolbarButton
                  id="SaveXLSXFile"
                  title="Save Workbook"
                  size="lg"
                  className={coreStyle.saveFileIcon}
                  onClick={() => this.onSaveWorkbook()}
                />
                <SpreadSheetToolbarButton
                  id="refWindow"
                  title="Open Reference Window"
                  size="lg"
                  className={coreStyle.referenceWindowIcon}
                  onClick={onRefereceWindowOpen}
                />
                <SpreadSheetToolbarButton
                  id="keyboardShortcuts"
                  title="Keyboard Shortcuts"
                  size="lg"
                  className={coreStyle.keyboardShortcutsIcon}
                  onClick={() => {
                    onSlidePaneOpen(
                      affixComponentName.SHORTCUT_KEYS,
                      affixComponentName.SHORTCUT_KEYS
                    );
                    this.props.eventTrackerService.track(
                      KeyboardShortcutsOpened
                    );
                  }}
                />
              </div>
            </>
          }
          showToolbar={showToolbar}
          documentRef={this}
          documentType={DocumentFileTypes.OPERATING_STATEMENT.key}
          getRowColor={this.getRowColor}
          onDocumentEvents={() => this.operatingStatementEvents()}
          onRowsInsert={this.onRowsInsert}
          onToast={this.props.setWorkbookToast}
          onReady={this.loadData}
          ref={this.refDataSheet}
          sheetCount={sheetCount}
        />
      </div>
    );
  }
}

OSSpreadSheet.propTypes = {
  customToolbarButtons: PropTypes.node,
  toolbarCustomRightChild: PropTypes.node,
  className: PropTypes.string,
  workbookData: PropTypes.object,
  isEqualTaggedPeriodToMLPeriod: PropTypes.bool,
  data: PropTypes.shape({
    data: PropTypes.shape({
      meta: PropTypes.object.isRequired,
      source: PropTypes.object.isRequired,
      extracted: PropTypes.shape({
        rows: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired
      })
    }),
    categoryMapping: PropTypes.object.isRequired
  }),
  setWorkbookToast: PropTypes.func,
  style: PropTypes.object,
  sheetCount: PropTypes.number,
  showToolbar: PropTypes.bool
};

OSSpreadSheet.defaultProps = {
  customToolbarButtons: null,
  toolbarCustomRightChild: null,
  workbookData: {},
  setWorkbookToast: noopFunc,
  className: '',
  sheetCount: 0,
  style: {},
  data: null,
  showToolbar: true
};

const mapStateToProps = (state) => ({
  projectTemplateHeadCategories:
    currentProjectTemplateHeadCategoriesSelector(state),
  projectHeadCategorySequence: currentProjectCategorySequenceSelector(state),
  projectTemplateMappings: currentProjectTemplateMappingsSelector(state),
  currentAssetType: currentProjectAssetTypeSelector(state),
  taggingInfo: currentDocumentTaggingInfoSelector(state),
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
});

export default connect(mapStateToProps)(
  withServices('store', 'eventTrackerService')(OSSpreadSheet)
);
