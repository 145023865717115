import React from 'react';
import { withServices } from 'reaf';
import { Container, Row, Col } from 'react-bootstrap';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import LightButton from '../../core/LightButton/LightButton';
import './AccountsExecutiveDashboardPage.scss';

function AccountsExecutiveDashboardPage({ children, router }) {
  const { pathname } = window.location;

  return (
    <LoggedInUserPage noFooter>
      <Container className="m-0 horizontal-section" fluid>
        <Row className="horizontal-section">
          <Col xs="auto" className="border-right p-0 bg-info pt-5 shadow naviSidebar">
            <LightButton
              onClick={() => router.setRoute('accountsExecutiveProspectPage')}
              active={pathname === router.getUrl('accountsExecutiveProspectPage')}>
                Prospects
            </LightButton>
            <LightButton
              onClick={() => router.setRoute('prospectPaymentLinksPage')}
              active={pathname === router.getUrl('prospectPaymentLinksPage')}>
                Payment Links
            </LightButton>
          </Col>
          <Col className="vertical-section p-0">
            <div className="fill-parent overflow-auto p-3">
              {children}
            </div>
          </Col>
        </Row>
      </Container>
    </LoggedInUserPage>
  );
}

export default withServices('router')(AccountsExecutiveDashboardPage);
