import React from 'react';
import ProjectForm from '../ProjectForm/ProjectForm';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';

function ProjectFormModal({ show, onHide, project }) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <FormModal entity="Project" show={show} onHide={onHide} onSubmit={onSubmit} isSubmitting={isSubmitting} title={project && project.uuid ? 'Edit Property' : 'Add Property'}>
      <ProjectForm bindSubmission={bindSubmission} onSubmitSuccess={onHide} project={project} />
    </FormModal>
  );
}

export default ProjectFormModal;
