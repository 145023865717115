import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { sumBy, keyBy, groupBy } from 'lodash';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import DocExtractionAffixTableWrapper from '../../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import DocExtractionAffixTotalCard from '../../DocExtractionAffixTotalCard/DocExtractionAffixTotalCard';
import { affixTotalColors } from '../../../../constants';
import RentRollConfigHeader from '../../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../../DocExtractionSummary/DocExtractionWithoutSummary';
import {
  occupancySelector,
  occupancyMappingSelector,
  readOnlyAccessSelector
} from '../../../../store/selectors';
import DocExtractionAffixPieChart from '../../DocExtractionAffixChart/DocExtractionAffixPieChart';
import DocExtractionRetailOccupancyOptions from './DocExtractionRetailOccupancyOptions';
import { setRetailOccupancy } from '../../../../store/currentDocument';
import style from './DocExtractionRetailOccupancy.module.scss';
import messages from '../../../../../locales/en-US';
import { displayNumberInUSFormat } from '../../../../utils/utils';
import FormSubmitButton from '../../../core/Button/FormSubmitButton';
import { AffixConfigSaved } from '../../../../constants/eventTrackerMessage';

const getChartData = (data) => {
  const categoryWiseData = groupBy(
    data,
    (config) => config.category || config.name
  );
  return Reflect.ownKeys(categoryWiseData).map((categoryKey) => {
    const configs = categoryWiseData[categoryKey];
    const config = { ...configs[0] };
    config.name = config.category || config.name;
    for (let i = 1; i < configs.length; i++) {
      const otherConfig = configs[i];
      config.value += otherConfig.value;
      config.units += otherConfig.units;
    }
    return config;
  });
};

function DocExtractionRetailOccupancy({
  occupancyStatus,
  staticOccupancyStatus,
  store,
  onConfigSubmit,
  onSlidePaneClose,
  eventTrackerService,
  readOnlyAccess
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [occupancyConfig, setOccupancyConfig] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setOccupancyConfig(keyBy(occupancyStatus, 'name'));
  }, [occupancyStatus]);

  const {
    RENT_ROLL: {
      MULTIFAMILY: { TOTAL_UNITS, TOTAL_MARKET_RENT }
    }
  } = affixTotalColors;

  const getSqFtPercentage = useCallback(
    (totalSqft) =>
      `${((totalSqft / sumBy(occupancyStatus, 'value')) * 100).toFixed(2)} %`,
    []
  );

  const onSubmit = useCallback(
    async (...args) => {
      const [formValue] = args;
      setIsSubmitting(true);
      store.dispatch(setRetailOccupancy(Object.values(formValue)));
      await onConfigSubmit();
      setIsSubmitting(false);
      eventTrackerService.track(AffixConfigSaved);
    },
    [onConfigSubmit, store]
  );

  return (
    <>
      {Reflect.ownKeys(occupancyConfig).length ? (
        <>
          <RentRollConfigHeader
            title="Configure Occupancy Code/Unit Status"
            hideChartSwitcher
          />
          <Formik
            enableReinitialize
            key="Occupancy"
            onSubmit={onSubmit}
            initialValues={occupancyConfig}
            render={({ setFieldValue, isValid }) => (
              <Form>
                <div className={style.occupancyRRWrapper}>
                  <DocExtractionAffixTableWrapper>
                    <Row className={style.occupancyHeader}>
                      <Col
                        className={`col-md-auto ${style.documentStatusHeader}`}
                      >
                        Occupancy Code
                      </Col>
                      <Col className={`col-md-auto ${style.unitHeader}`}>
                        #Units
                      </Col>
                      <Col className={`col-md-auto ${style.sqFtHeader}`}>
                        Square Feet
                        <small>(Percentage)</small>
                      </Col>
                      <Col className={`col-md-auto ${style.categoryHeader}`}>
                        Category{' '}
                      </Col>
                    </Row>
                    <Row className="position-relative">
                      <Col className={`${style.occupancyWrapper}`}>
                        <Table>
                          <tbody>
                            {Reflect.ownKeys(occupancyConfig).map((status) => (
                              <tr key={status}>
                                <td className={style.documentStatus}>
                                  <div
                                    title={occupancyConfig[status]['name']}
                                    className={style.columnNowrapCode}
                                  >
                                    <span className="text-with-ellipsis w-100 d-inline-block">
                                      {occupancyConfig[status]['name']}
                                    </span>
                                  </div>
                                </td>
                                <td className={style.units}>
                                  <div
                                    title={displayNumberInUSFormat(
                                      occupancyConfig[status]['units']
                                    )}
                                    className={style.columnNowrapUnits}
                                  >
                                    <span className="text-with-ellipsis w-100 d-inline-block">
                                      {displayNumberInUSFormat(
                                        occupancyConfig[status]['units']
                                      )}
                                    </span>
                                  </div>
                                </td>
                                <td className={style.sqFt}>
                                  <div
                                    className={`${style.columnNowrapAvgsqft}`}
                                  >
                                    <span
                                      title={displayNumberInUSFormat(
                                        occupancyConfig[status]['value']
                                      )}
                                      className="text-with-ellipsis w-100 d-inline-block"
                                    >
                                      {displayNumberInUSFormat(
                                        occupancyConfig[status]['value']
                                      )}
                                    </span>
                                    <small
                                      title={`(${getSqFtPercentage(
                                        occupancyConfig[status]['value']
                                      )})`}
                                      className="text-with-ellipsis w-100 d-inline-block"
                                    >
                                      {`(${getSqFtPercentage(
                                        occupancyConfig[status]['value']
                                      )})`}
                                    </small>
                                  </div>
                                </td>
                                <td className={style.categroy}>
                                  <DocExtractionRetailOccupancyOptions
                                    occupancyConfigData={occupancyConfig}
                                    occupancyStatus={status}
                                    staticOccupancyStatus={
                                      staticOccupancyStatus
                                    }
                                    setCurrentChargeOccupancyStatus={(
                                      dropdownValue
                                    ) => {
                                      setFieldValue(status, dropdownValue);
                                    }}
                                    onMenuClose={() => setIsMenuOpen(false)}
                                    onMenuOpen={() => setIsMenuOpen(true)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </DocExtractionAffixTableWrapper>
                  <DocExtractionAffixTableWrapper>
                    <Row
                      className={`${style.occupancyStatusChart} occupancyAffixchart`}
                    >
                      <Col>
                        <DocExtractionAffixPieChart
                          data={getChartData(occupancyStatus)}
                          basicChart
                          showCurrency={false}
                        />
                      </Col>
                    </Row>
                  </DocExtractionAffixTableWrapper>
                </div>
                <DocExtractionAffixTableWrapper>
                  <div className={style.cardsFixBottom}>
                    <DocExtractionAffixTotalCard
                      cards={[
                        {
                          title: 'Total Square Feet (%)',
                          color: TOTAL_UNITS,
                          content: `${displayNumberInUSFormat(
                            sumBy(occupancyStatus, 'value')
                          )} (100.00%)`
                        },
                        {
                          title: 'Total Units',
                          color: TOTAL_MARKET_RENT,
                          content: displayNumberInUSFormat(
                            sumBy(occupancyStatus, 'units')
                          )
                        }
                      ]}
                    />
                  </div>
                </DocExtractionAffixTableWrapper>
                <Row className={style.footerFixedBottom}>
                  <Button
                    variant="outline-secondary btn-sm"
                    onClick={onSlidePaneClose}
                  >
                    Cancel
                  </Button>
                  <FormSubmitButton
                    isValid={isValid && !readOnlyAccess}
                    isSubmitting={isSubmitting}
                    id="occupancySubmit"
                  />
                </Row>
              </Form>
            )}
          />
        </>
      ) : (
        <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  occupancyStatus: occupancySelector(state),
  staticOccupancyStatus: occupancyMappingSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state)
});

export default connect(mapStateToProps)(
  withServices('store', 'eventTrackerService')(DocExtractionRetailOccupancy)
);
