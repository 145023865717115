import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Col, Row, Container, Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import DynamicAssetTypesPopups from '../DynamicAssetTypesPopups/DynamicAssetTypesPopups';
import { dynamicAssetTypesListSelector } from '../../../store/selectors';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { displayDateFormat } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

function DynamicAssetTypesList({ dynamicAssetTypes, dynamicAssetTypesService, toastrService }) {
  const regexFilter = useMemo(() => ({
    type: 'RegexFilter',
    placeholder: 'Filter',
    delay: 1000
  }), []);

  const initialValues = {
    dynamicAssetType: {
      assetType: '',
      key: '',
      label: '',
      badgeText: '',
      baseClass: 'MULTIFAMILY',
      canRestrictRentRoll: false
    }
  };

  const [showCreateDynamicAssetTypePopup, setShowCreateDynamicAssetTypePopup] = useState(false);
  const [showEditDynamicAssetTypePopup, setEditCreateDynamicAssetTypePopup] = useState(false);
  const [dynamicAssetType, setDynamicAssetType] = useState(initialValues);
  const [selectedAssetType, setSelectedAssetType] = useState();
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);

  const hidePopups = useCallback(
    () => {
      setShowCreateDynamicAssetTypePopup(false);
      setEditCreateDynamicAssetTypePopup(false);
    },
    [],
  );

  const onAddDynamicAssetType = useCallback(() => {
    setDynamicAssetType(initialValues);
    setShowCreateDynamicAssetTypePopup(true);
  }, [initialValues]);

  const onEditDynamicAssetType = useCallback((row) => {
    const selectRow = {dynamicAssetType: row};
    setDynamicAssetType(selectRow);
    setEditCreateDynamicAssetTypePopup(true);
  }, [initialValues]);

  const onCancelDelete = useCallback(() => {
    setShowDeleteConfirmPopup(false);
  }, [selectedAssetType]);

  const onDeleteConfirm = useCallback(() => {
    dynamicAssetTypesService.deleteDynamicAssetType(selectedAssetType).catch(e => {
      toastrService.error(e.message, 'top');
    });
    setShowDeleteConfirmPopup(false);
  }, [selectedAssetType]);

  return (
    <>
      <DynamicAssetTypesPopups
        onHide={hidePopups}
        showCreateDynamicAssetType={showCreateDynamicAssetTypePopup}
        showEditDynamicAssetType={showEditDynamicAssetTypePopup}
        dynamicAssetType={dynamicAssetType}
      />
      <Container fluid className="p-2 pb-4">
        <Row className="align-items-center">
          <Col sm="auto">
            <h5 className="mb-0">Dynamic Asset Types</h5>
          </Col>
          <Col sm="auto" className="pl-0 tourIconBtn">
            <IconButton
              title="Add Dynamic Asset Type" className="btn-circle btn-shadow"
              variant="outline-secondary" size="sm" icon={faPlus}
              onClick={e => {
                onAddDynamicAssetType();
              }
              }
              id="AddDynamicAssetType"
            />
          </Col>
          <Col />
        </Row>
      </Container>

      <BootstrapTable
        data={dynamicAssetTypes}
        tableStyle={{ background: '#fafafa' }}
        condensed
        hover
      >
        <TableHeaderColumn
          dataField="assetType"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          isKey
          filter={regexFilter}
        >
          Asset Type
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="label"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Label
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="badgeText"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Badge Text
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="baseClass"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Base Class
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="canRestrictRentRoll"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Can Restrict RR
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="createdAt"
          dataFormat={displayDateFormat}
          dataSort
        >
          Created On
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="action"
          dataSort
          width="100px"
          dataFormat={(cell, row) => (
            <div >
              <IconButton
                onClick={() => onEditDynamicAssetType(row)}
                variant="outline-primary"
                title="Edit dynamic asset type"
                className="smallIconButton"
                icon={faEdit}
                style={{ marginLeft: '10px' }}  />
              <IconButton
                variant="outline-danger"
                className="smallIconButton"
                icon={faTrash}
                title="Delete"
                onClick={() => {
                  setSelectedAssetType(row);
                  setShowDeleteConfirmPopup(true);
                }}
              />
            </div>

          )}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable >

      {
        showDeleteConfirmPopup && selectedAssetType &&
        (
          <ConfirmationModal
            isConfirm
            show={showDeleteConfirmPopup}
            message={`Do you want to delete ${selectedAssetType.label} asset type?`}
            title="Delete Asset Type"
            onConfirm={onDeleteConfirm}
            onCancel={onCancelDelete}
          />
        )
      }

    </>
  );
}

const mapStateToProps = state => ({
  dynamicAssetTypes: dynamicAssetTypesListSelector(state)
});


export default connect(mapStateToProps)(withServices('dynamicAssetTypesService', 'toastrService')(DynamicAssetTypesList));
