import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withServices } from 'reaf';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormModal from '../FormModal/FormModal';
import BulkProjectJsonComponent from './BulkProjectJsonComponent';
import useModalForm from '../../../hooks/useModalForm';
import messages from '../../../../locales/en-US';

function BulkProjectJson({ onHide, showModal, companyService, projects, projectsService, refreshData, toastrService }) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  const [projectOptions, setProjectOptions] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();

  const loadAllCompanies = useCallback(async () => {
    const companies = await companyService.getAllCompanies();
    const companiesOptions = companies.rows.map(company => ({
      value: company.name,
      label: company.name
    }))
    setCompaniesOptions(companiesOptions);
  }, [])

  React.useEffect(() => {
    loadAllCompanies();
  }, [])

  useEffect(() => {
    if (selectedCompany && projects?.length > 0) {
      const projectOptions = projects.filter(project => project.Company.name === selectedCompany.value)
      .map(project => ({
        value: project.uuid,
        label: project.name
      }));
      setProjectOptions(projectOptions);
    }
  }, [selectedCompany, projects]);

  const initialValues = {
    selectedProjects: [],
    companies: ''
  };

  const bulkProjectJsonValidationSchema = useMemo(() => Yup.object().shape({
    companies: Yup.object().required('Please select company'),
    selectedProjects: Yup.array().min(1, 'Please select at least one project'),
  }), []);

  async function onSubmitForm(values, actions) {
    actions.setSubmitting(true);
    try {
      const payload = {
        selectedProjects: values.selectedProjects.map(project => project.value)
      };
      await projectsService.bulkProjectsJson(payload);
      refreshData();
      toastrService.success(messages.toastMessage.BULK_PROJECT_JSON_SUCCESS, null, 5000);
      onHide();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  return (
    <FormModal
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      onHide={onHide}
      show={showModal}
      size="lg"
      title="Bulk Project Json"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={bulkProjectJsonValidationSchema}
        component={formikProps => (
          <BulkProjectJsonComponent
            {...formikProps}
            bindSubmission={bindSubmission}
            projectOptions={projectOptions}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            companiesOptions={companiesOptions}
            onHide={onHide}
          />
        )}
      />
    </FormModal>
  );
}

export default withServices('companyService', 'projectsService', 'toastrService')(BulkProjectJson);