import React from 'react';
import { ExtractionJobStatus as JobStatus } from '../../../constants';

function DisplayExtractionJobStatus({ status }) {
  const displayJobStatus = JobStatus[status];
  return (
    <span>
      {displayJobStatus && (
        <span>
          {displayJobStatus.icon}
          {' '}
          {displayJobStatus.label}
          {' '}
        </span>
      )}
    </span>
  );
}

export default DisplayExtractionJobStatus;
