import React from 'react';
import { Field } from 'formik';

function Checkbox(props) {
  const { name, label, value: propsValue, onChange } = props;
  return (
    <>
      <Field name={name}>
        {({ field, form }) => (
          <label>
            <input
              type="checkbox"
              {...props}
              checked={field.value.includes(propsValue)}
              onChange={() => {
                let v;
                if (field.value.includes(propsValue)) {
                  const nextValue = field.value.filter(
                    value => value !== propsValue,
                  );
                  form.setFieldValue(name, nextValue);
                  v = nextValue;
                } else {
                  const nextValue = field.value.concat(propsValue);
                  form.setFieldValue(name, nextValue);
                  v = nextValue;
                }

                if (onChange) {
                  onChange(v);
                }
              }}
            />
            {label}
          </label>
        )}
      </Field>
    </>
  );
}

export default Checkbox;
