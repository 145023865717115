import React from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormModal from '../FormModal/FormModal';
import UserForm from '../UserForm/UserForm';
import useModalForm from '../../../hooks/useModalForm';
import ResetUserPasswordForm from '../ResetUserPasswordForm/ResetUserPasswordForm';
import EditUserForm from '../EditUserForm/EditUserForm';
import UserTemplateAccessForm from '../UserTemplateAccessForm/UserTemplateAccessForm';
import { USER_ROLES } from '../../../constants';

function UserPopups({
  user, onHide, showCreateUser, showEditTemplateAccess, showDeleteUser, showCancelPlan,
  showResetPassword, companyUUID, onDeleteConfirm, onDeleteCancel, showEditUserForm
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
  const [isTemplateAccessSubmitting, bindTemplateAccessSubmission, onTemplateAccessSubmit] = useModalForm();

  return (
    <div>
      {
        showCreateUser &&
        (
          <FormModal onHide={onHide} show={showCreateUser} onSubmit={onSubmit} isSubmitting={isSubmitting} title={user && user.uuid ? 'Edit User' : 'Add User'}>
            <UserForm
              bindSubmission={bindSubmission} 
              onSubmitSuccess={onHide} user={{ user, role: USER_ROLES.COMPANY_USER.key }} 
              companyUUID={companyUUID} 
            />
          </FormModal>
        )
      }
      {
        showResetPassword &&
        (
          <FormModal onHide={onHide} show={showResetPassword} onSubmit={onSubmit} isSubmitting={isSubmitting} title="Change Password">
            <ResetUserPasswordForm bindSubmission={bindSubmission} onSubmitSuccess={onHide} user={user} />
          </FormModal>
        )
      }
      {
        showEditUserForm &&
        (
          <FormModal onHide={onHide} show={showEditUserForm} onSubmit={onSubmit} isSubmitting={isSubmitting} title="Update User">
            <EditUserForm 
              bindSubmission={bindSubmission} 
              onSubmitSuccess={onHide} 
              user={{ user, role: user.UserRole.role }} 
            />
          </FormModal>
        )
      }
      {
        showEditTemplateAccess && (
          <FormModal onHide={onHide} show={showEditTemplateAccess} onSubmit={onTemplateAccessSubmit} isSubmitting={isTemplateAccessSubmitting} title="Update Template Access">
            <UserTemplateAccessForm 
              bindSubmission={bindTemplateAccessSubmission} 
              onSubmitSuccess={onHide} 
              user={{ user, role: user.UserRole.role }} 
            />
          </FormModal>
        )}
      {
        showDeleteUser &&
        (
          <ConfirmationModal
            isConfirm
            show={showDeleteUser}
            message={`Are you sure you want to delete the user ${user.firstName}`}
            title={`Delete User ${user.firstName}?`}
            onConfirm={onDeleteConfirm}
            onCancel={onDeleteCancel}
          />
        )
      }
      {
        showCancelPlan &&
        (
          <ConfirmationModal
            isConfirm
            show={showCancelPlan}
            message="Do you want to cancel the subscription?"
            title="Cancel Subscription"
            onConfirm={onDeleteConfirm}
            onCancel={onDeleteCancel}
          />
        )
      }
    </div>
  );
}

export default UserPopups;
