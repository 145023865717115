import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { withServices } from 'reaf';
import { get } from 'lodash';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import { Modal } from "react-bootstrap";
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons/faFileInvoice';
import { usDateFormat } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';
import { USER_ACTION_TYPES } from '../../../constants';

function UsersLog({
  queries,
  apiClient,
  router, 
  projectsService
}) {

  const [metaData, setMetaData] = useState();
  const [metaDataTitle, setMetaDataTitle] = useState();
  const [showDownloadButton, setShowDownloadButton] = useState();

  const downloadMetaData = React.useCallback((metaData, fileName) => {
    const blob = new Blob([metaData], { type: 'application/json' });
    saveAs(blob, fileName);
  }, []);

  const previewDocument = useCallback((metaDataDocument, companyName, action) => {
    setMetaDataTitle(`${action} MetaData Preview on ${companyName}`);
    if (metaDataDocument === null) {
      setShowDownloadButton(false);
      setMetaData("No Meta Data Available");
    } else {
      setShowDownloadButton(true);
      setMetaData(JSON.stringify(metaDataDocument, null, 2));
    }
  }, []);

  const { data, loading, pageCount, onDownloadUserlogCSV, fetchData, showUserlogQueryPopup, csvDownloadEndPoint, hidePopups } = useReactTable({
    queries,
    apiClient,
    projectsService,
    router,
    routeName: 'userLogs',
    resourceEndPoint: '/admin/userLogs',
    fileName: 'Userlogs.csv',
    csvDownloadEndPoint: '/admin/userLogs/downloadCSV'
  });

  const columns = useMemo(() => [
    {
      Header: 'First Name',
      accessor: 'User.firstName',
      cell: ({ row: { original } }) => get(original, 'User.firstName')
    },
    {
      Header: 'Last Name',
      accessor: 'User.lastName',
      cell: ({ row: { original } }) => get(original, 'User.lastName')
    },
    {
      Header: 'Email',
      accessor: 'User.email',
      cell: ({ row: { original } }) => get(original, 'User.email')
    },
    {
      Header: 'Company',
      accessor: 'User.Companies.name',
      Cell: ({ row: { original } }) => {
        return get(original, 'User.Companies[0].name', 'Super Admin')
      }
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row: { original: { action } } }) => USER_ACTION_TYPES[action]
    },
    {
      Header: 'Date',
      disableFilters: true,
      accessor: 'createdAt',
      Cell: ({ row: { original: { createdAt } } }) => (
        <div style={{ width: '100%', textAlign: 'center', fontWeight: createdAt ? '700' : '500' }}>
          {createdAt === null ? '--' : usDateFormat(createdAt)}
        </div>
      )
    },
    {
      Header: 'Meta Data',
      Cell: ({ row: { original } }) => (

        <div className="d-flex justify-content-center flex-row">
          <IconButton title="Preview MetaData" icon={faFileInvoice} variant="outline-primary" size="sm" onClick={
            () => previewDocument(get(original, 'metaData'), get(original, 'User.Companies[0].name', 'Super Admin'), USER_ACTION_TYPES[original.action])
          } />
        </div>
      )
    },
  ], [data]);

  return (
    <>
      {metaData && (
        <Modal
          show={!!metaData}
          size="xl"
          onHide={() => {
            setMetaData(null);
            setMetaDataTitle(null)
          }}
          className="error-modal"
          centered
        >
          <Modal.Header closeButton>

            <div className="d-flex custom-margin-class justify-content-around flex-row align-items-center">
              <Modal.Title className="mr-3">{metaDataTitle}</Modal.Title>
              {showDownloadButton && (<IconButton
                title="Download MetaData"
                icon={faDownload}
                variant="outline-primary"
                size="sm"
                onClick={() => downloadMetaData(metaData, metaDataTitle)}
              />)}
            </div>
          </Modal.Header>
          <Modal.Body>
            <pre>{metaData}</pre>
          </Modal.Body>

        </Modal>
      )}
      <ReactTable
        columns={columns}
        data={data}
        onDownloadUserlogCSV={onDownloadUserlogCSV}
        queries={queries}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        showUserlogQueryPopup={showUserlogQueryPopup}
        csvDownloadEndPoint={csvDownloadEndPoint}
        hidePopups={hidePopups}
      />
    </>
  );
}


export default withServices('apiClient', 'router', 'projectsService')(UsersLog);
