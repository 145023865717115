import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { isNil, isEmpty } from 'lodash';
import * as moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DocumentsService from '../../../services/DocumentsService';
import {
  ExtractionJobStatus,
  DocumentFileTypes as DOC_TYPES,
  FILE_TYPES,
} from '../../../constants';
import style from './DocumentsListView.module.scss';
import DocumentContextMenu from '../DocumentContextMenu/DocumentContextMenu';
import DocumentList from './DocumentList';
import { doSorting, isOSBaseType, isOSFullBaseType } from '../../../utils/utils';
import {
  currentProjectSelector,
  userAllowedTemplatesSelector,
} from '../../../store/selectors';
import './DocumentsListView.scss';
import rightArrow from '../../../assets/images/right-arrow.svg';
import useExpandRow from '../../../hooks/useExpandRow';

const extractionStatusMap = {
  Default: 0,
  Successful: 1,
  Active: 2,
  Canceled: 3,
  Pending: 4,
  Converted: 5,
  Failed: 6,
};

const getDocument = (document) =>
  document.children ? document.children[0] : document;

const getDocumentType = (document) => {
  if (document.children) {
    return [
      ...new Set(
        document.children.map((document) => getDocumentType(document))
      ),
    ].join('/');
  }
  const documentType =
    document.taggingData && document.taggingData.documentType
      ? document.taggingData.documentType
      : DOC_TYPES.NONE.key;
  const assetTypeBadge =
    documentType !== DOC_TYPES.NONE.key
      ? DOC_TYPES[documentType]?.badgeText
      : '';
  return assetTypeBadge;
};

const getDate = (document) => {
  if (document.taggingData) {
    const { documentType } = document.taggingData;
    if (!isNil(documentType) && isOSBaseType(documentType)) {
      return moment(new Date(document.taggingData.periodFrom));
    } else if (documentType === DOC_TYPES.RENT_ROLL.key) {
      return moment(new Date(document.taggingData.asOnDate));
    } else {
      return null;
    }
  }
};

const fileTypeIcon = (document) => {
  const docType = DocumentsService.getDocumentType(document);
  const icon = FILE_TYPES[docType] ? FILE_TYPES[docType].icon : '';
  return <img src={icon} width={30} height={30} alt="file icon" />;
};

const fileTypeFormatter = (_, document) => {
  if (!document.groupId) {
    return fileTypeIcon(document);
  }
};

const documentTypeSorter = (document1, document2, order) => {
  const assetType1 = getDocumentType(getDocument(document1));
  const assetType2 = getDocumentType(getDocument(document2));
  return doSorting(assetType1, assetType2, order);
};

const taggedDateSorter = (document1, document2, order) => {
  const date1 = getDate(getDocument(document1));
  const date2 = getDate(getDocument(document2));
  return doSorting(date2, date1, order);
};

const getDocumentExtractionStatus = (document) => {
  let statusStyle = ExtractionJobStatus['DEFAULT'];
  if (document.extractionJob && document.extractionJob.status) {
    statusStyle = ExtractionJobStatus[document.extractionJob.status];
  }
  return statusStyle;
};

const getDocumentsChildExtractionStatus = ({ children }) => {
  const statusStyleMap = { key: 0, value: ExtractionJobStatus['DEFAULT'] };
  // eslint-disable-next-line no-restricted-syntax
  for (const document of children) {
    const statusStyle = getDocumentExtractionStatus(document);
    if (statusStyleMap.key < extractionStatusMap[statusStyle.label]) {
      statusStyleMap['key'] = extractionStatusMap[statusStyle.label];
      statusStyleMap['value'] = statusStyle;
    }
  }

  return statusStyleMap['value'];
};

const getDocumentDocumentExtractionStatus = (document) => {
  if (document.children) {
    return getDocumentsChildExtractionStatus(document).icon;
  }
  let extractionIcon;
  if (document.extractionJob && document.extractionJob.status) {
    extractionIcon = ExtractionJobStatus[document.extractionJob.status].icon;
  }
  return extractionIcon;
};

const documentTypeFormatter = (_, document) => (
  <div title={document.extractionJob && document.extractionJob.status}>
    {getDocumentDocumentExtractionStatus(document)} {getDocumentType(document)}
  </div>
);

const getDocumentStatusStyle = (document) => {
  if (document.children) {
    return style[getDocumentsChildExtractionStatus(document).label];
  }
  const statusStyle = getDocumentExtractionStatus(document).label;
  return style[statusStyle];
};

const getValidationStatusElement = (status) => {
  if (!status) {
    return (
      <OverlayTrigger placement="bottom" overlay={
        <Tooltip >Please complete the review for not validated documents
          before generating property report/model
        </Tooltip>}>
        <div className="status-report pl-1">
          {ExtractionJobStatus['PENDING'].icon} Not Validated
        </div>
      </OverlayTrigger>
    )
  }
  return (
    <div className="status-report pl-1">
      {ExtractionJobStatus['SUCCESSFUL'].icon} Validated
    </div>
  )
}

const documentValidation = (_, document) => {
  if (document.children && document.children.length === 1) {
    return documentValidation(null, document.children[0]);
  } if (document.children && document.children.length > 1) {
    return null
  }
  if (document.isValidated) {
    return getValidationStatusElement(document.isValidated)
  } else {
    if (!isEmpty(document.taggingData)) {
      return getValidationStatusElement(document.isValidated)
    }
    return null
  }
}

const taggedDateFormatter = (_, document) => {
  if (document.children && document.children.length === 1) {
    return taggedDateFormatter(null, document.children[0]);
  }
  const rrDateFormat = 'MMM DD, YYYY';
  const osDateFormat = 'MMM, YYYY';
  const { taggingData } = document;
  if (taggingData) {
    const { documentType } = taggingData;
    if (!isNil(documentType) && (isOSBaseType(documentType) || isOSFullBaseType(documentType))) {
      const period =  taggingData.periodFrom  && taggingData.periodTo ? 
      `${moment(new Date(taggingData.periodFrom))
        .utc()
        .format(osDateFormat)} - ${moment(
          new Date(taggingData.periodTo)
        )
          .utc()
          .format(osDateFormat)}` : <div className="docuemntTaggingLoader"></div>;
      return period;
    } else if (documentType === DOC_TYPES.RENT_ROLL.key) {
      const { asOnDate } = taggingData;
      return asOnDate && moment(new Date(asOnDate), 'MM/DD/YYYY')._isValid ? moment(new Date(asOnDate))
        .utc()
        .format(rrDateFormat) : <div className="docuemntTaggingLoader"></div>;
    } else {
      return null;
    }
  }
  return null;
};

const DocumentsListView = ({
  documents,
  project,
  templates,
  onNeedSupport,
  onClearTagging,
  onDelete,
  onAddTagging,
  onDownloadCSV,
  onOpen,
  onReExtract,
  onRename,
  handleSelectAllButton,
  selectDocument,
  selectedDocument,
  goToAddTaggingStep,
  hasTrialExhausted,
  selectAllModels = [],
  selectedDocumentsForModel = [],
  setSelectedDocumentsForModel,
  succesfulTaggedDocuments
}) => {
  const { isExpanded, expandDocument, collapseDocument, toggleCollapse } =
    useExpandRow();


  const expandIconFormatter = useCallback(
    (_, document) => {
      if (!!document.children && document.children.length > 1) {
        return isExpanded(document) ? (
          <img
            src={rightArrow}
            className={`${style.arrowDown} ${style.arrowWidth}`}
            alt="arrow down"
          />
        ) : (
          <img
            src={rightArrow}
            className={`${style.arrowLeft} ${style.arrowWidth}`}
            alt="arrow left"
          />
        );
      }
      return false;
    },
    [isExpanded, expandDocument, collapseDocument]
  );

  const documentActionFormatter = useCallback(
    (_, document) => (
      <div className="d-flex align-items-center justify-content-between">
        {
          (!!document.children && document.children.length > 1) ? 
            isExpanded(document) ? 
              <img
              src={rightArrow}
              className={`arrow ${style.arrowDown} ${style.arrowWidth}`}
              alt="arrow down" /> : (
              <img
                src={rightArrow}
                className={`arrow ${style.arrowLeft} ${style.arrowWidth}`}
                alt="arrow left"
              />
            ) : <div>&nbsp;</div>
        }
        <DocumentContextMenu
          isChildDocument={!!document.groupId}
          document={document}
          template={templates[project.templateUuid]}
          onReExtract={onReExtract}
          onDownloadCSV={() => onDownloadCSV(document)}
          onAddTagging={() => onAddTagging(document)}
          onDelete={() => onDelete(document)}
          onNeedSupport={() => onNeedSupport(document)}
          hasTrialExhausted={hasTrialExhausted}
          onClearTagging={() => onClearTagging(document)}
          onOpen={onOpen}
          onRename={() => onRename(document)}
        />
      </div>

    ),
    [templates, isExpanded]
  );  

 const getHeaderCheckbox = useCallback(
      <label className='docCheckbox'>
      <input
        key={Math.random()}
        type="checkbox"
        checked={selectAllModels}
        onChange={handleSelectAllButton}
        disabled={succesfulTaggedDocuments && !succesfulTaggedDocuments.length}
      />
        <span></span>
      </label>
  , [selectAllModels, handleSelectAllButton, succesfulTaggedDocuments]);


  const columns = useMemo(() => [
    {
      dataField: '',
      label: getHeaderCheckbox,
      width: 'auto',
      headerClassName: 'documentCheckbox documents-header-1',
      dataFormat: null,
    },
    {
      dataField: 'documentType',
      label: '',
      width: '10%',
      columnClassName: `fileIcon ${style.folderIcon}`,
      headerClassName: '',
      dataFormat: fileTypeFormatter,
    },
    {
      dataField: 'fileName',
      label: 'Name',
      columnClassName: 'text-with-ellipsis',
      headerClassName: 'documents-header-2 pl-0',
      width: '25%',
      dataFormat: (_, document) =>
        !document.groupId && (
          <span title={document['fileName']}>{document['fileName']}</span>
        ),
      dataSort: true,
    },
    {
      dataField: 'documentType',
      label: 'Type',
      width: '10%',
      headerClassName: 'documents-header-3 pl-0',
      columnClassName: (_, document) =>
        `docTypeBedge ${style.badgeStatus} ${getDocumentStatusStyle(document)}`,
      dataFormat: documentTypeFormatter,
      dataSort: true,
      sortFunc: documentTypeSorter,
    },
    {
      dataField: 'documentTypes',
      label: 'Validation',
      headerClassName: 'status-type documents-header-4 pl-0',
      columnClassName: 'status-type',
      width: '15%',
      dataFormat: documentValidation,
      dataSort: true,
      sortFunc: documentTypeSorter,
    },
    {
      dataField: 'taggedDate',
      label: 'Period',
      width: '15%',
      headerClassName: 'ml-auto documents-header-5 pl-0',
      columnClassName: 'ml-auto',
      dataFormat: taggedDateFormatter,
      dataSort: true,
      sortFunc: taggedDateSorter,
    },
    {
      dataField: 'actions',
      label: 'Actions',
      width: '15%',
      headerClassName: 'ml-auto',
      columnClassName: 'ml-auto',
      dataFormat: documentActionFormatter,
    },
  ]);

  return (
    <DocumentList
      columns={columns}
      data={documents}
      selectedItem={selectedDocument}
      onSelect={selectDocument}
      isExpanded={isExpanded}
      toggleCollapse={toggleCollapse}
      selectedDocumentsForModel={selectedDocumentsForModel}
      setSelectedDocumentsForModel={setSelectedDocumentsForModel}
    />
  );
};
const mapStateToProps = (state) => ({
  project: currentProjectSelector(state),
  templates: userAllowedTemplatesSelector(state),
});

export default connect(mapStateToProps)(DocumentsListView);
