const config = {
  app: {
    isFreebieApp: process.env.REACT_APP_IS_FREEBIE_APP === 'true',
    environment: process.env.REACT_APP_ENVIRONMENT || 'dev',
    hostUrl: process.env.REACT_APP_APP_HOST_URL || 'http://localhost:3001',
    completeUrl: process.env.REACT_APP_APP_COMPLETE_URL,
    recentProjectsAndDocsCompanyUuid: process.env.REACT_APP_RECENT_PROJECTS_DOCS_COMPANY_UUID || '',
    sendLogEmail: process.env.REACT_APP_SEND_LOG_EMAIL || '',
    clikGatewayUrl: process.env.REACT_APP_CLIK_GATEWAY_URL || '',
  },

  stripe: {
    publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  },

  api: {
    baseUrl: process.env.REACT_APP_BASEURL
  },

  calendlyLink: process.env.REACT_APP_CALENDLY_LINK,

  appBaseUrl: process.env.REACT_APP_APPBASEURL,
  reExtractBeforeDate: process.env.REACT_APP_RE_EXTRACT_BEFORE,
  invoiceExpiryInMonths: process.env.REACT_APP_INVOICE_EXPIRY_IN_MONTHS || 12,

  auth: {
    timeout: 30
  },

  GC: {
    licenseKey: process.env.REACT_APP_GC_LICENSE
  },
  captcha: {
    siteKey:
      process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
      '6LfQp64ZAAAAAKfWHi4nInRVTVSYbVGb6Oe3MZf0'
  },

  socialLogin: {
    googleClientId:
      process.env.REACT_APP_GOOGLE_CLIENT_ID ||
      '1095180418444-duvhgk3qpi34pvufpns3kmvfn922uvnj.apps.googleusercontent.com',
    linkedInClientId:
      process.env.REACT_APP_LINKEDIN_CLIENT_ID || '78cmmsm8urm2me',
    linkedInRedirectUri:
      `${window.location.origin}${process.env.REACT_APP_LINKEDIN_REDIRECT_URI}` ||
      'http://localhost:3000/auth/linkedin/callback',
    microsoftClientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '4d005f78-62ec-4c58-9d99-e7b7ec503520',
    microsoftRedirectUri: `${window.location.origin}${process.env.REACT_APP_MICROSOFT_REDIRECT_URI}` || 'http://localhost:3000/auth/microsoft/callback',
  },

  clikConnect: {
    appBaseUrl: process.env.REACT_APP_CLIK_CONNECT_APP_BASE_URL
  },

  hubspot: {
    portalId: process.env.REACT_APP_HB_PORTAL_ID,
    formId: process.env.REACT_APP_HB_FORM_ID
  },

  calendly: {
    url:
      process.env.REACT_APP_CALENDLY_LINK ||
      'https://calendly.com/parag-clik/autouw?submissionGuid=a1d0bb40-7864-41cf-a898-b95e3236c720&month=2021-08'
  },

  website: {
    url: process.env.REACT_APP_WEBSITE_URL || 'https://clik.ai'
  },

  featureFlags: {
    shouldShowGoogleApiField:
      process.env.REACT_APP_SHOW_GOOGLEAPI_FIELDS === 'true'
  },

  jotoForm: {
    apiKey: process.env.REACT_APP_JOTOFORM_APIKEY,
    formId: process.env.REACT_APP_JOTOFORM_FORMID,
    emailFieldId: process.env.REACT_APP_JOTOFORM_EMAIL_FIELD_ID
  },
  eventTracking: {
    isEventTrackingEnabled:
      process.env.REACT_APP_IS_EVENT_TRACKING_ENABLED === 'true',
    mixPanelProjectToken: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN
  },
  usersWithTemplateAccess: process.env.REACT_APP_USER_WITH_TEMPLATE_ACCESS || [],
  companyWithCustomApiConfigFeature: process.env.REACT_APP_CUSTOM_API_CONFIG_COMPANIES_UUID || [],
  KKR_COMPANY_UUID: process.env.REACT_APP_KKR_COMPANY_UUID || null
};

export const appConfig = config.app;
export const apiConfig = config.api;
export const authConfig = config.auth;
export const GCConfig = config.GC;
export const { captcha, socialLogin, hubspot, appBaseUrl, eventTracking, usersWithTemplateAccess, companyWithCustomApiConfigFeature, KKR_COMPANY_UUID } =
  config;
export const clikConnectConfig = config.clikConnect;
export const { jotoForm } = config;
export default config;
