import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  companiesDetails: {}
};

const [
  { setCompaniesDetails, updateCompany },
  reducer
] = createDuck('autouw/companyList', {
  _initialState: initialState,

  setCompaniesDetails: companiesDetails => state => ({
    ...state,
    companiesDetails: normalize(companiesDetails, 'uuid')
  }),

  updateCompany: updatedCompanyDetails => state => ({
    ...state,
    companiesDetails: {
      ...state.companiesDetails,
      [updatedCompanyDetails.uuid]: updatedCompanyDetails
    }
  })
});

export {
  setCompaniesDetails,
  updateCompany
};

export default reducer;
