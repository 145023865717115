import React from 'react';
import { VIDEO_TUT_URL } from '../../../constants';
import StaticPageFooterNavigation from '../../core/StaticPageWithNav/StaticPageFooterNavigation';
import style from './VideoTutorial.module.scss';

function VideoTutorial() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className={`col-12 d-flex border-bottom ${style.sectionHeader}`}>
          <h3>AutoUW Walkthrough</h3>
        </div>
      </div>
      <div className={`d-flex ${style.videoWrapper}`}>
        {
          VIDEO_TUT_URL.map((url) => (
            <iframe
              width="100%"
              height="600"
              title="Clik walkthrough"
              key={url}
              src={url} frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />
          ))
        }
      </div>
      <StaticPageFooterNavigation 
          leftMenuInfo = {{ route: 'release', displayName: 'Release Notes' }} 
          rightMenuInfo = {{ route: 'privacy', displayName: 'Privacy Policy' }} 
      />
    </div>
  );
}

export default VideoTutorial;
