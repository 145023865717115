import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  globalTemplates: {},
};

const [
  {
    setGlobalTemplates
  },
  reducer
] = createDuck('autouw/globalTemplates', {
  _initialState: initialState,
  setGlobalTemplates: globalTemplates => state => ({
    ...state,
    globalTemplates: normalize(globalTemplates, 'uuid')
  }),
});

export {
  setGlobalTemplates,
};

export default reducer;
