import React, { useState, useCallback, useEffect } from 'react';
import ReactSelect from 'react-select';
import { isFinite } from 'lodash';

function FloorPlanDropDownOptions({
  fpConfigData = {}, floorPlan, floorPlanBedMapping, floorPlanBathMapping, setCurrentFloorPlan, style, 
  activeViewName, CONFIG_VIEW, onMenuOpen, onMenuClose
}) {

  const [floorPlanConfigData, setFloorPlanConfigData] = useState(fpConfigData);
  useEffect(() => {
    setFloorPlanConfigData(fpConfigData)
    setSelectedBedOption(fpConfigData[floorPlan]?.BD)
    setSelectedBathOption(fpConfigData[floorPlan]?.BA)
  }, [fpConfigData]);

  const [selectedBedOption, setSelectedBedOption] = useState(floorPlanConfigData[floorPlan].BD);
  const [selectedBathOption, setSelectedBathOption] = useState(floorPlanConfigData[floorPlan].BA);
  const [disable, setDisable] = useState(false)
  const [isStudio, setStudio] = useState(
    floorPlanConfigData[floorPlan].BD === 0 && floorPlanConfigData[floorPlan].BA === 0.5
  )
  const activeViewClassName = useCallback((activeClassName) => (activeViewName === activeClassName ? '' : 'd-none'));
  return (
    <>
      <td className={`col-md-auto ${style.beds} ${activeViewClassName(CONFIG_VIEW)}`}>
        <ReactSelect
          classNamePrefix="react-select chargeCodeSelect"
          value={{ label: isStudio ? 'Studio' : isFinite(Number(selectedBedOption)) ? selectedBedOption : 0 , value: isStudio ? 'Studio' : selectedBedOption }}
          placeholder="Bed"
          maxMenuHeight={200}
          menuPosition="fixed"
          menuPlacement="auto"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={floorPlanBedMapping && floorPlanBedMapping.map(category => ({ value: category, label: category }))}
          onChange={
            (selectedBed) => {
              const { BA, BD, ...floorPlanConfig } = floorPlanConfigData[floorPlan];
              setSelectedBedOption(selectedBed.value);
              setDisable(selectedBed.value === 'Studio')
              if(selectedBed.value === 'Studio') {
                setStudio(true);
                setCurrentFloorPlan({ BD: 0, BA: 0.5, ...floorPlanConfig });
                setSelectedBathOption('0.5');
              } else {
                setStudio(false);
                setCurrentFloorPlan({ 
                  BD: Number(selectedBed.value), 
                  BA: Number(selectedBathOption), 
                  ...floorPlanConfig 
                });
              }
            }
          }
        />
      </td>
      <td className={`col-md-auto ${style.bath} ${activeViewClassName(CONFIG_VIEW)}`}>
        <ReactSelect
          classNamePrefix={`react-select ${disable || isStudio ? 'react-select__disabled' : ''} chargeCodeSelect`}
          value={{ value: selectedBathOption, label: isFinite(Number(selectedBathOption)) ? selectedBathOption : 0 }}
          maxMenuHeight={200}
          menuPlacement="auto"
          menuPosition="fixed"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={floorPlanBathMapping && floorPlanBathMapping.map(category => ({ value: category, label: category }))}
          onChange={
            (selectedBath) => {
              const { BA, BD, ...floorPlanConfig } = floorPlanConfigData[floorPlan];
              setCurrentFloorPlan({ 
                BD: Number(selectedBedOption), 
                BA: Number(selectedBath.value), 
                ...floorPlanConfig 
              });
              setSelectedBathOption(selectedBath.value);
            }
          }
        />
      </td>
    </>
  );
}

export default FloorPlanDropDownOptions;
