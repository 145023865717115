import React, { useMemo } from "react";
import { Card, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withServices } from "reaf";
import { uploadsStatusSelector } from "../../../store/selectors";
import uploadIcon from "../../../assets/images/uploadDocument.svg";
import style from "./DocumentUploadErrorPopup.module.scss";

const ModalWrapper = ({ show, size = "lg", onHide, failedDocuments }) => (
  <Modal
    show={show}
    size={size}
    onHide={onHide}
    className="error-modal"
    centered
  >
    <Modal.Body>
      <Row>
        <Col className={style.desciptionWrapper}>
          <div
            className="d-flex justify-content-center align-items-center flex-column"
            style={{ height: "100%" }}
          >
            <img src={uploadIcon} alt="Upload document" />
            <div className={style.title}>
              Document{failedDocuments.length > 1 ? "s" : ""}
            </div>
            <div className={style.subTitle}>Failed to Upload</div>

            <div className={style.description}>
              Please <span style={{ color: "#007bff" }}>Try Again</span> with
              the documents with Required Extentions (
              <span style={{ color: "#007bff" }}>.pdf, .xls</span>)
            </div>
          </div>
        </Col>
        <Col className={style.errorBox}>
          <DocumentUploadErrorMessage documentsError={failedDocuments} />
        </Col>
      </Row>
    </Modal.Body>
  </Modal>
);

const DocumentUploadErrorMessage = ({ documentsError }) => (
    <>
      {documentsError.map((docError) => (
        <Card key={docError.key} className={style.errorWrapper}>
          <Card.Header title={docError.name}>{docError.name}</Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item title={docError.message}>
              {docError.message}
            </ListGroup.Item>
          </ListGroup>
        </Card>
      ))}
    </>
  );

const DocumentUploadErrorPopup = ({ uploads = {}, documentsService }) => {
  const failedDocuments = useMemo(() => Reflect.ownKeys(uploads)
      .map((key) => ({ ...uploads[key], key }))
      .filter(({ status }) => status === "failed"), [uploads]);

  return (
    <ModalWrapper
      show={!!failedDocuments.length}
      onHide={() => documentsService.clearUploadQueue()}
      failedDocuments={failedDocuments}
    />
  );
};

const mapStateToProps = (state) => ({
  uploads: uploadsStatusSelector(state),
});
export default connect(mapStateToProps)(
  withServices("documentsService")(DocumentUploadErrorPopup)
);
