import React from 'react';
import GlobalTemplateMappings from '../../app/GlobalTemplateMappings/GlobalTemplateMappings';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function GlobalTemplatesPage() {
  return (
    <SuperAdminDashboard>
      <GlobalTemplateMappings />
    </SuperAdminDashboard>
  );
}
export default GlobalTemplatesPage;
