import React from 'react'
import styles from './ErrorPage.module.scss';
import autoUnderWritingImage from '../../../assets/images/auto-underwriting.svg';
import AnonymousHeader from '../../app/AnonymousHeader/AnonymousHeader';
import Footer from '../../app/Footer/Footer';
import config from '../../../config';

export default function ErrorPage({ title, errorMessage }) {
  return (
    <div className="page">
      <AnonymousHeader logoUrl={config.website.url}/>
      <div className={styles.wrapper}>
        <div className={styles.message}>
          {title && <h1 className="my-3 border-bottom pb-2">{title}</h1>}
          <h5 className="my-3 border-bottom pb-2">{errorMessage}</h5>
        </div>
        <div className={styles.imageWrapper}>
          <img alt="Auto Underwriting" src={autoUnderWritingImage} height="400px"/>
        </div>
      </div>
      <Footer isTrial/>
    </div>
  )
}
