import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Row, Button } from 'react-bootstrap';
import { sumBy, isNil, uniqBy, pick, get, subtract } from 'lodash';
import { setFloorPlan } from '../../../store/currentDocument';
import {
  currentDocumentFloorPlanSelector,
  currentDocumentFloorPlanBedSelector,
  currentDocumentFloorPlanBathSelector,
  currentDocumentSelector,
  readOnlyAccessSelector
} from '../../../store/selectors';
import DocExtractionAffixTableWrapper from '../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import DocExtractionAffixTotalCard from '../DocExtractionAffixTotalCard/DocExtractionAffixTotalCard';
import style from './DocExtractionFloorPlanConfig.module.scss';
import {
  displayNumberInUSFormat,
  getAverage,
  DisplayAmountFormat,
  displayAmountWithCurrency,
  getCurrencyAverage,
  getFpFloorPlan
} from '../../../utils/utils';
import { rentRollAffixTabViewName, affixTotalColors } from '../../../constants';
import RentRollConfigHeader from '../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../DocExtractionSummary/DocExtractionWithoutSummary';
import DocExtractionFloorPlanChart from './DocExtractionFloorPlanChart';
import './DocExtractionFloorPlanConfig.scss';
import messages from '../../../../locales/en-US';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import AffixTableView from './AffixTableView';
import AffixConfigView from './AffixConfigView';
import RenovationConfigView from './RenovationConfigView';
import AffordableConfigView from './AffordableConfigView';
import {
  AffixConfigSaved,
  FloorPlanViewChanged
} from '../../../constants/eventTrackerMessage';

const { TABLE_VIEW, CONFIG_VIEW, CHART_VIEW, RENOVATION_VIEW, AFFORDABLE_VIEW } =
  rentRollAffixTabViewName;
const {
  RENT_ROLL: {
    MULTIFAMILY: {
      AVG_SQFT,
      AVG_MARKET_RENT,
      AVG_MONTHLY_RENT,
      TOTAL_MONTHLY_RENT,
      TOTAL_UNITS,
      BEDS_BATHS
    }
  }
} = affixTotalColors;

const getUnitMixRow = (floorPlan, floorPlanConfigData) => [{
  'Floor Plan': floorPlan,
  'Bed': +floorPlanConfigData.BD ? +floorPlanConfigData.BD : 0,
  'Bath': +floorPlanConfigData.BA ? +floorPlanConfigData.BA : 0,
  'Avg. Sqft': getAverage(floorPlanConfigData.squareFtTotal, floorPlanConfigData.count) || 0,
  '#Unit': floorPlanConfigData.count,
  '#Occupied Units': get(floorPlanConfigData, 'occupancyStatus.occupiedUnitCount'),
  '#Vacant Units': subtract(floorPlanConfigData?.count, floorPlanConfigData?.occupancyStatus?.occupiedUnitCount) || 0,
  'Avg. Market Rent': `${getCurrencyAverage(floorPlanConfigData?.marketRentTotal, floorPlanConfigData?.count) || 0}`,
  'Avg. Monthly Rent': `${floorPlanConfigData?.occupiedUnitCount ? (getCurrencyAverage(floorPlanConfigData?.occupiedMonthlyRentTotal, floorPlanConfigData?.occupiedUnitCount) || 0) : 0}`,
  '#Renov + New': get(floorPlanConfigData, 'renovationConfig.renovated', 0),
  'Avg. Renov Cost': get(floorPlanConfigData, 'renovationConfig.avgRenovationCost', 0),
  'Avg. Renov Bump': get(floorPlanConfigData, 'renovationConfig.avgRenovationBump', 0),
  'Avg. Subsidy': get(floorPlanConfigData, 'affordableConfig.avgSubsidy', 0)
}];

function DocExtractionFloorPlanConfig({
  fpConfigData,
  floorPlanBedMapping,
  floorPlanBathMapping,
  isAcquisition,
  currentDocument,
  store,
  onConfigSubmit,
  onSlidePaneClose,
  eventTrackerService,
  utilsService,
  readOnlyAccess
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeViewName, setActiveViewName] = useState(TABLE_VIEW);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [floorPlanConfigData, setFloorPlanConfigData] = useState({});

  useEffect(() => {
    fpConfigData && setFloorPlanConfigData(fpConfigData)
  }, [fpConfigData]);

  const downloadXLS = useCallback((sheetData, sheetName) => {
    utilsService.downloadExcel({ 'Floor Plan Summary': sheetData }, sheetName)
  }, [utilsService])


  const onSubmit = useCallback(
    async (...args) => {
      const [formValue] = args;
      setIsSubmitting(true);
      store.dispatch(setFloorPlan(formValue));
      await onConfigSubmit();
      setIsSubmitting(false);
      eventTrackerService.track(AffixConfigSaved);
    },
    [onConfigSubmit, store]
  );

  const getChartData = useCallback(() => {
    const chartConfig = [];
    Reflect.ownKeys(floorPlanConfigData).forEach((floorPlan) => {
      const { count, marketRentTotal, monthlyRentTotal, squareFtTotal } =
        floorPlanConfigData[floorPlan];

      const avgMarketRent = getAverage(marketRentTotal, count);
      const avgMonthlyRent = getAverage(monthlyRentTotal, count);
      const avgsqft = getAverage(squareFtTotal, count);

      chartConfig.push({
        'name': floorPlan,
        'Market Rent': marketRentTotal,
        'Monthly Rent': monthlyRentTotal,
        'Vacancy Loss': marketRentTotal - monthlyRentTotal,
        'Avg. Market Rent': avgMarketRent,
        'Avg. Monthly Rent': avgMonthlyRent,
        'Avg. Loss': avgMarketRent - avgMonthlyRent,
        'Avg. SqFt': avgsqft
      });
    });
    return chartConfig;
  }, [floorPlanConfigData]);

  const activeViewClassName = useCallback((activeClassName) =>
    activeViewName === activeClassName ? '' : 'd-none'
  );

  const getBedBathTotals = useCallback((bedBathKey) =>
    Reflect.ownKeys(floorPlanConfigData).reduce((total, item) => {
      total =
        total +
        floorPlanConfigData[item][bedBathKey] * floorPlanConfigData[item].count;
      return total;
    }, 0)
  );

  const getCardTotals = useCallback((keyName, displayAmount = false) => {
    if (!isNil(sumBy(Object.values(floorPlanConfigData), keyName))) {
      const total = sumBy(Object.values(floorPlanConfigData), keyName);
      return displayAmount ? (
        <DisplayAmountFormat number={total} />
      ) : (
        displayNumberInUSFormat(total)
      );
    } else {
      return 0;
    }
  });

  const getTotalCardAvg = useCallback(
    (keyName, unitCountKeyName, displayAmount = true) => {
      const totalAverage = Reflect.ownKeys(floorPlanConfigData).reduce(
        (total, floorPlan) => {
          const average =
            floorPlanConfigData[floorPlan][keyName] /
            floorPlanConfigData[floorPlan][unitCountKeyName];
          total =
            total + average * floorPlanConfigData[floorPlan][unitCountKeyName];
          return total;
        },
        0
      );

      if (!isNil(totalAverage)) {
        const total = Math.round(
          totalAverage /
            sumBy(Object.values(floorPlanConfigData), unitCountKeyName)
        );
        return displayAmount ? (
          <DisplayAmountFormat number={total} />
        ) : (
          displayNumberInUSFormat(total)
        );
      } else {
        return 0;
      }
    }
  );

  const getUnitPercentage = useCallback(
    (unit = 0, countKey = 'count') => {
      const unitCount = sumBy(Object.values(floorPlanConfigData), countKey);
      if (unitCount === 0) {
        return 0;
      } else {
        return Number((unit / unitCount) * 100).toFixed(2);
      }
    },
    [floorPlanConfigData]
  );

  const getAverageTotals = useCallback(
    (ObjKey, totalAmount, totalCount, displayAmount = true) => {
      if (floorPlanConfigData[ObjKey] && floorPlanConfigData[ObjKey][totalCount]) {
        const total = Math.round(
          floorPlanConfigData[ObjKey][totalAmount] /
            floorPlanConfigData[ObjKey][totalCount]
        );
        return displayAmount ? (
          <DisplayAmountFormat number={total} />
        ) : (
          displayNumberInUSFormat(total)
        );
      } else {
        return <DisplayAmountFormat number={0} />;
      }
    },
    [floorPlanConfigData]
  );

  const affixTotalCardTop = useMemo(() => isAcquisition
      ? [
          {
            title: '#Units',
            color: TOTAL_UNITS,
            content: `${getCardTotals('count')} (100.00%)`
          },
          {
            title: '#Renovated units',
            color: BEDS_BATHS,
            content: getCardTotals('renovationConfig.renovated', false)
          },
          {
            title: '#Affordable units',
            color: AVG_MARKET_RENT,
            content: getCardTotals('affordableConfig.affordable', false)
          },
          {
            title: 'Total Renov Cost',
            color: AVG_SQFT,
            content: getCardTotals('renovationConfig.renovationCost', true)
          }
        ]
      : [
          {
            title: '#Units',
            color: TOTAL_UNITS,
            content: `${getCardTotals('count')} (100.00%)`
          },
          {
            title: 'Avg. Sqft',
            content: getTotalCardAvg('squareFtTotal', 'count', false),
            color: AVG_SQFT
          },
          {
            title: 'Avg. Market Rent',
            color: AVG_MARKET_RENT,
            content: getTotalCardAvg('marketRentTotal', 'count')
          }
        ], [
    getBedBathTotals,
    getBedBathTotals,
    getTotalCardAvg,
    getCardTotals,
    isAcquisition
  ]);

  const affixTotalCardBottom = useMemo(() => isAcquisition
      ? [
          {
            title: 'Avg. Sqft',
            content: getTotalCardAvg('squareFtTotal', 'count', false),
            color: AVG_SQFT
          },
          {
            title: 'Avg. Market Rent',
            color: AVG_MARKET_RENT,
            content: getTotalCardAvg('marketRentTotal', 'count')
          },
          {
            title: 'Avg. Monthly Rent',
            color: AVG_MONTHLY_RENT,
            content: getTotalCardAvg('monthlyRentTotal', 'count')
          },
          {
            title: 'Total Monthly Rent',
            color: TOTAL_MONTHLY_RENT,
            content: getCardTotals('monthlyRentTotal', true)
          }
        ]
      : [
          {
            title: 'Avg. Monthly Rent',
            color: AVG_MONTHLY_RENT,
            content: getTotalCardAvg('monthlyRentTotal', 'count')
          },
          {
            title: 'Total Monthly Rent',
            color: TOTAL_MONTHLY_RENT,
            content: getCardTotals('monthlyRentTotal', true)
          }
        ], [
    getBedBathTotals,
    getBedBathTotals,
    getTotalCardAvg,
    getCardTotals,
    isAcquisition
  ]);

  const onPopulateBedBathFromRegex = React.useCallback(() => {
    const updatedFpConfig = {...floorPlanConfigData}
    Reflect.ownKeys(updatedFpConfig).forEach(fp => {
      const config = getFpFloorPlan(fp);
      updatedFpConfig[fp] = {
        ...updatedFpConfig[fp],
        ...config
      }
    })
    setFloorPlanConfigData(updatedFpConfig);
  }, [floorPlanConfigData, getFpFloorPlan, setFloorPlanConfigData])

  return (
    <>
      {Reflect.ownKeys(floorPlanConfigData).length > 0 ? (
        <>
          <RentRollConfigHeader
            title="Configure Floor Plan"
            customTitle="Configure Floor Plan"
            isCustomOptionVisible
            isAcquisition={isAcquisition}
            activeViewName={activeViewName}
            onTabViewChange={(activeView) => {
              setActiveViewName(activeView);
              eventTrackerService.track(FloorPlanViewChanged, {
                view: activeView
              });
            }}
            onDownload={() => {
              const data = Reflect.ownKeys(floorPlanConfigData).map(
                (floorPlan) => {
                  const row = [
                    getUnitMixRow(
                      floorPlan,
                      floorPlanConfigData[floorPlan]
                    )
                  ];
                  return row;
                }
              ).flat();
              const uniqueByFloorPlan = uniqBy(data.flat(), (elem) => JSON.stringify(pick(elem, ['Floor Plan'])));
              downloadXLS(uniqueByFloorPlan, `${`${currentDocument.fileName}-floor-plan-summary`}.xlsx`);
             }}
          />

          <Formik
            onSubmit={onSubmit}
            key="floorPlanKey"
            enableReinitialize
            initialValues={floorPlanConfigData}
            render={({ setFieldValue, values, isValid }) => (
                <Form>
                  <DocExtractionAffixTableWrapper>
                    <AffixTableView
                      {...{
                        getUnitPercentage,
                        getAverageTotals,
                        setActiveViewName,
                        activeViewClassName,
                        isMenuOpen,
                        activeViewName,
                        isActive: activeViewName === TABLE_VIEW,
                        floorPlanConfigData,
                        setFieldValue,
                        setIsMenuOpen,
                        floorPlanBedMapping,
                        floorPlanBathMapping,
                        isAcquisition
                      }}
                    />

                    <AffixConfigView
                      {...{
                        getUnitPercentage,
                        getAverageTotals,
                        setActiveViewName,
                        activeViewClassName,
                        isMenuOpen,
                        activeViewName,
                        isActive: activeViewName === CONFIG_VIEW,
                        floorPlanConfigData,
                        setFieldValue,
                        setIsMenuOpen,
                        floorPlanBedMapping,
                        floorPlanBathMapping,
                        isAcquisition
                      }}
                    />

                    <RenovationConfigView
                      {...{
                        getUnitPercentage,
                        getAverageTotals,
                        setActiveViewName,
                        activeViewClassName,
                        isMenuOpen,
                        activeViewName,
                        isActive: activeViewName === RENOVATION_VIEW,
                        floorPlanConfigData,
                        setFieldValue,
                        setIsMenuOpen,
                        floorPlanBedMapping,
                        floorPlanBathMapping,
                        isAcquisition
                      }}
                    />

                    <AffordableConfigView
                      {...{
                        getUnitPercentage,
                        getAverageTotals,
                        setActiveViewName,
                        activeViewClassName,
                        isMenuOpen,
                        activeViewName,
                        isActive: activeViewName === AFFORDABLE_VIEW,
                        floorPlanConfigData,
                        setFieldValue,
                        setIsMenuOpen,
                        floorPlanBedMapping,
                        floorPlanBathMapping,
                        isAcquisition
                      }}
                    />

                    <DocExtractionFloorPlanChart
                      style={style}
                      isActive={activeViewName === CHART_VIEW}
                      data={getChartData()}
                      xAxisKeyName="name"
                    />

                    <DocExtractionAffixTotalCard cards={affixTotalCardTop} />
                    <DocExtractionAffixTotalCard cards={affixTotalCardBottom} />
                  </DocExtractionAffixTableWrapper>
                  <Row
                    className={`configure-table-tfooter footerFixedBottom ${style.floorPlanFooter}`}
                  >
                    {activeViewName === CONFIG_VIEW && 
                    <Button
                      variant="outline-secondary btn-sm"
                      onClick={onPopulateBedBathFromRegex}
                    >
                      Pick bed and bath
                    </Button>}
                    <Button
                      variant="outline-secondary btn-sm"
                      onClick={onSlidePaneClose}
                    >
                      Cancel
                    </Button>
                    <FormSubmitButton
                      isValid={isValid && !readOnlyAccess}
                      isSubmitting={isSubmitting}
                      id="floorPlanConfigSubmit"
                    />
                  </Row>
                </Form>
              )}
          />
        </>
      ) : (
        <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  fpConfigData: currentDocumentFloorPlanSelector(state),
  floorPlanBedMapping: currentDocumentFloorPlanBedSelector(state),
  floorPlanBathMapping: currentDocumentFloorPlanBathSelector(state),
  currentDocument: currentDocumentSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state),
});

export default connect(mapStateToProps)(
  withServices('store', 'eventTrackerService', 'utilsService')(DocExtractionFloorPlanConfig)
);
