/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import ReactSelect from 'react-select';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Table as BTable, Button, Dropdown,  Form as BSForm } from 'react-bootstrap';
import { withServices } from 'reaf';
import { useTable, usePagination, useSortBy, useFilters, useAsyncDebounce } from 'react-table';
import { faAngleDoubleLeft as firstIcon } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight as lastIcon } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faAngleLeft as prevIcon } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight as nextIcon } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faSort as sortIcon } from '@fortawesome/free-solid-svg-icons/faSort';
import { faCaretUp as sortUpIcon } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCaretDown as sortDownIcon } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faPlus as addIcon } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faUpload as uploadIcon } from '@fortawesome/free-solid-svg-icons/faUpload';

import { isEmpty, isObjectLike, noop } from 'lodash';
import { PAGINATION_DEFAULT, CompanyTypes, CompanyStatusRowColor } from '../../../constants';
import IconButton from "../Button/IconButton";
import style from './ReactTable.module.scss';
import { DefaultColumnFilter } from '../ReactTableFilters/ReactTableFilters';
import FormModal from '../../app/FormModal/FormModal';
import UserlogQueryPopup from '../../app/UserlogQueryPopup/UserlogQueryPopup';
import SwitchButton from '../../core/SwitchButton/SwitchButton';

export function getDefaultState(queries) {
  let state = { pageIndex: 0, pageSize: PAGINATION_DEFAULT.pageSize };

  const {
    _limit,
    _offset,
    _search,
    _order,

    ..._exactMatch
  } = queries;

  if (_limit) {
    state.pageIndex = (+_offset || 0) / +_limit;
    state.pageSize = _limit;
  } else {
    state.pageIndex = 0;
    state.pageSize = 50;
  }

  if (_search) {
    state.filters = Reflect.ownKeys(_search).map(id => ({ id, value: _search[id] }))
  }

  if (_order) {
    state.sortBy = Reflect.ownKeys(_order).map(id => ({
      id,
      desc: _order[id] === 'DESC'
    }))
  }

  if (!isEmpty(_exactMatch)) {
    state.filters = [
      ...(state.filters || []),
      ...Reflect.ownKeys(_exactMatch).map(id => ({ id, value: { _exactMatch: _exactMatch[id] } }))
    ]
  }

  if (!state.filters) {
    state.filters = [];
  }

  if (!state.sortBy) {
    state.sortBy = [];
  }

  if (!state.hiddenColumns) {
    state.hiddenColumns = [];
  }



  return state;
}

export const ReactTablePagination = ({
  loading, setPageSize, pageSize, pageOptions, canPreviousPage, gotoPage,
  previousPage, nextPage, pageCount, canNextPage, pageIndex
}) => (
  <div className="d-flex">
    {loading && <div className={style.linePreloader} />}
    <div className={style.appPagination}>
      <IconButton icon={firstIcon} onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
      <IconButton icon={prevIcon} onClick={() => previousPage()} disabled={!canPreviousPage} />
      <div>
        <span>Page: </span>
        <input
          type="text"
          value={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
        <span>of</span>
        <strong>{pageOptions.length}</strong>
      </div>
      <IconButton icon={nextIcon} onClick={() => nextPage()} disabled={!canNextPage} />
      <IconButton icon={lastIcon} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
    </div>

    <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '15px' }}>
    <div className={style.paginationDropdown}>
      <span>Show </span>
      <ReactSelect
        defaultValue={{ label: pageSize, value: pageSize }}
        maxMenuHeight={230}
        menuPlacement="auto"
        menuPosition="fixed"
        options={PAGINATION_DEFAULT.pageSizes.map(pageSize => ({ value: pageSize, label: pageSize }))}
        onChange={
          (e) => {
            setPageSize(Number(e.value));
          }
        }
      />
    </div>
    </span>
  </div>
);

const ReactTableCell = ({ cell, isEnableClickToCopy, ignoreTextEllipsis, onCopyToClipboard }) => {
  return (
    <>
      {
        isEnableClickToCopy ? (
          <CopyToClipboard text={cell.value} onCopy={onCopyToClipboard}>
            <div
              style={{ width: cell.column.fixedWidth }} title={cell.value ? cell?.value?.toString() : ''}
              className={!ignoreTextEllipsis ? "text-with-ellipsis" : ''}
            >
              {cell.render('Cell')}
            </div>
          </CopyToClipboard>  
        ) : (
          <div 
          style={{ width: cell.column.fixedWidth }} title={cell.value ? cell?.value?.toString() : ''}
          className={!ignoreTextEllipsis ? "text-with-ellipsis" : ''}
        > 
          {cell.render('Cell')}
          </div>
        )
      }
    </>
  )
}

export const ReactTableUI = ({ getTableProps, headerGroups, getTableBodyProps,
  rows, prepareRow, onCopyToClipboard, tableStatus, onRowClick, enableRowClik }) => (
    <div className="react-table">
      <BTable bordered hover size="sm" {...getTableProps()}>
        <thead>
          {
            headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps({ width: column.fixedWidth })}>
                      <div className="d-flex">
                        <span>
                        {
                          column.render('Header')
                        }
                        </span>

                          {column.canSort && <span {...column.getSortByToggleProps()}>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? <IconButton variant="light" icon={sortDownIcon}/>
                                : <IconButton variant="light" icon={sortUpIcon}/>
                              : <IconButton variant="light" icon={sortIcon}/>}
                          </span>

                        }
                      </div>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
              </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
          rows.map(row => {
          prepareRow(row);
          let rowStyle = {}
          if(tableStatus) {
            const { original: { isEnterpriseCompany, subscriptionDetail, trialDays } } = row;
            const remainingDays = subscriptionDetail ? moment(subscriptionDetail.endDate).diff(moment(), 'days') : trialDays;
            rowStyle = {
              borderRadius: '5px'
            };
          if (!isEnterpriseCompany) {
            if (remainingDays < 0) {
              rowStyle.background = CompanyStatusRowColor.EXPIRED
            } else if(remainingDays <= 7) {
              rowStyle.background = CompanyStatusRowColor.EXPIRING
            }
          }
          }
          return (
            <tr {...row.getRowProps()} style={rowStyle}   onClick={enableRowClik ? () => onRowClick(row.original) : undefined}>
              {
                row.cells.map(cell =>
                (
                  <td {...cell.getCellProps()}>
                    <ReactTableCell 
                      cell={cell}
                      isEnableClickToCopy={cell?.column?.enableClickToCopy || false}
                      ignoreTextEllipsis={cell?.column?.ignoreTextEllipsis || false}
                      onCopyToClipboard={onCopyToClipboard}
                    />
                  </td>
                ))}
            </tr>
          );
          })}
        </tbody>   
      </BTable>

      {rows.length < 1 && (
        <div className={style.noRecord}>There is no record to display</div>  
      )}

    </div>
  )

export const useReactTable = ({
  apiClient,
  projectsService,
  fileName,
  routeName,
  router,
  queries = {},
  resourceEndPoint,
  csvDownloadEndPoint,
  currentFilterValue,
  csvSendEndPoint,
}) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [showUserlogQueryPopup, setShowUserlogQueryPopup] = useState(false);
  const changeRoute = useAsyncDebounce((routeName, options) => {
    router.setRoute(routeName, options);
  }, 200);

  const fetchData = React.useCallback(({ pageSize, pageIndex, sortBy, filters , currentFilterValue, isExactSearch }) => {
    let _order = {};
    if (sortBy && sortBy[0]) {
      const sorting = sortBy[0];
      _order[sorting.id] = sorting.desc ? 'DESC' : 'ASC';
    } else {
      _order = null;
    }

    let _search = {};
    let _exactMatch = {};
    if (filters && filters.length) {
      filters.forEach(filter => {
        if (filter && typeof filter.value === 'string') {
          _search[filter.id] = encodeURIComponent(filter.value);
        } else if (filter && typeof filter.value === 'object') {
          if (filter.value._exactMatch) {
            _exactMatch[filter.id] = encodeURIComponent(filter.value._exactMatch);
          }
        }
      })
    }

    const _offset = pageSize * (pageIndex || 0);
    const _limit = pageSize;
    const params = {
      _limit,
      _offset,
    };
    if(currentFilterValue) {
      if(currentFilterValue.value === 'isEnterpriseCompany' ) {
        params.isEnterpriseCompany = true;
      }
      if(currentFilterValue.value === 'isTrialOffering') {
        params.isTrialOffering = true;
      }
    }
    if(isExactSearch) {
      params.isExactSearch = true;
    }
    if (_order) {
      params['_order'] = _order;
    }

    if (_search && !isEmpty(_search)) {
      params['_search'] = _search;
    }

    changeRoute(routeName, {
      ...params,
      ..._exactMatch
    });

  }, [router, currentFilterValue]);

  const requestServer = (endPoint, options) => {
    const { params: { _search } } = options;
    let _updatedSearch;
    if (_search) {
      _updatedSearch = Reflect.ownKeys(_search)
        .map(key => ({ [key]: encodeURIComponent((_search[key])) }))
        .reduce((obj, val) => {
          const key = Reflect.ownKeys(val);
          obj[key] = val[key]   
          return obj  
        }, {});
    }
    const apiOptions = isObjectLike(_search) ? { params:{ ...options.params, _search: _updatedSearch } } : options
    return apiClient.get(endPoint, apiOptions)
  };

  const refreshData = React.useCallback(() => {
    const endPoint = resourceEndPoint;
    const params = {
      ...queries
    };

    if (params._limit) {
      setLoading(true);
      requestServer(endPoint, { params }).then(result => {
        setData(result.response.rows);
        setPageCount(Math.ceil(result.response.count / params._limit));
        setLoading(false);
      });
    }
  }, [queries]);

  React.useEffect(() => {
    refreshData();
  }, [refreshData]);

  const onDownloadCSV = React.useCallback(async () => {
    const endPoint = csvDownloadEndPoint;
    const params = {
      ...queries
    };

    setLoading(true);
    await projectsService.downloadProjectCSV(endPoint, { params }, fileName);
    setLoading(false);
  }, [queries]);

  const onDownloadUserlogCSV = React.useCallback(async () => {
    setShowUserlogQueryPopup(!showUserlogQueryPopup);
  }, [])

  const hidePopups = () => {
    setShowUserlogQueryPopup(!showUserlogQueryPopup);
  }

  const onSendCSV = React.useCallback(async () => {
    const endPoint = csvSendEndPoint;
    const params = {
      ...queries
    };

    setLoading(true);
    await projectsService.sendProjectCSV(endPoint, {params});
    setLoading(false);
  }, [queries]);

  return {
    data, setData,
    loading, setLoading,
    pageCount, setPageCount,
    fetchData, onDownloadCSV,
    onSendCSV, refreshData,
    showUserlogQueryPopup,
    csvDownloadEndPoint,
    onDownloadUserlogCSV,
    hidePopups
  }

};

const ReactTable = ({
  fetchData = noop,
  onAdd,
  onSelectProjectAction,
  projectActionValues,
  currentProjectAction,
  title = "",
  onDownloadCSV,
  onSendCSV,
  data,
  columns,
  queries = {},
  loading,
  pageCount: controlledPageCount,
  currentFilterValue,
  setCurrentFilterValue,
  companyTypeFilterValues,
  titleClassName= '',
  toastrService,
  tableStatus,
  showUserlogQueryPopup,
  csvDownloadEndPoint,
  onDownloadUserlogCSV,
  hidePopups,
  onRowClick,
  enableRowClik = false,
  hideAddButton = false,
}) => {

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: getDefaultState(queries),
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      disableMultiSort: true,
      pageCount: controlledPageCount
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const [isExactSearch, setIsExactSearch] = useState(false);

  const { pageIndex, pageSize, sortBy, filters } = state;

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters, currentFilterValue, isExactSearch });
  }, [fetchData, pageIndex, pageSize, sortBy, filters, isExactSearch]);

  const onChangeFilter = useCallback((filterValue) => {
    setCurrentFilterValue({ ...filterValue });
  }, [rows]);

  const getCount = useCallback((data, type) =>{
    const count = data.filter(company => company.isEnterpriseCompany).length;
    return type === CompanyTypes.ENTERPRISE ? count : data.length - count;
  }, [data])

 const onCopyToClipboard = useCallback(() => {
  toastrService.success('Copied to clipboard', 'bottom');
 }, [toastrService])

  return (
    <>
      <div className="header mt-2 mb-4 pb-1">
        <h3 className={titleClassName}>{title}</h3>
      </div>

      <div className="pb-3 position-relative" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div className="d-flex align-items-center col-7 pl-0">
          { onDownloadCSV && <Button onClick={onDownloadCSV} variant="primary" size="sm">Export to CSV</Button>}
          { onDownloadUserlogCSV && 
            <>
              <Button onClick={onDownloadUserlogCSV} variant="primary" size="sm">Export to CSV</Button>
              <UserlogQueryPopup onHide={hidePopups} showUserlogQueryPopup={showUserlogQueryPopup} csvEndEndPoint={csvDownloadEndPoint} />
            </>
          }
          { onSendCSV && <Button onClick={onSendCSV} variantvariant="primary" className='ml-2' size="sm">Send CSV </Button>}
          {
            onSelectProjectAction &&
              <ReactSelect
                classNamePrefix="react-select"
                className={style.companyTypeFilter}
                defaultValue={currentProjectAction}
                maxMenuHeight={230}
                menuPlacement="auto"
                menuPosition="fixed"
                options={projectActionValues}
                onChange={onSelectProjectAction}
              />
          }
          {
            (onAdd && !hideAddButton) &&
              <IconButton defaultClass="btn btn-sm ml-2" variant="primary" icon={addIcon} text="Create" onClick={onAdd} />
          }
          { onAdd &&
            <ReactSelect
              classNamePrefix="react-select"
              className={style.companyTypeFilter}
              defaultValue={currentFilterValue}
              maxMenuHeight={230}
              menuPlacement="auto"
              menuPosition="fixed"
              options={companyTypeFilterValues}
              onChange={onChangeFilter}
            />
          }
          { tableStatus && 
            <div style={{display: 'flex'}}>
              <BTable bordered hover size="sm">
                <thead>
                  <tr>
                    <th>{CompanyTypes.ENTERPRISE}</th>
                    <th>{CompanyTypes.TRIAL}</th>
                    <th>{CompanyTypes.ALL}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{getCount(data, CompanyTypes.ENTERPRISE)}</td>
                    <td>{getCount(data, CompanyTypes.TRIAL)}</td>
                    <td>{data && data.length}</td>
                  </tr>
                </tbody>
              </BTable>
            </div>
          }
          <div class="fade alert alert-info show m-0 py-1 px-2 ml-2 shadow-sm">
            <BSForm.Check
              checked={isExactSearch}
              id='exactSearch'
              onChange={() => setIsExactSearch(!isExactSearch)}
              type="checkbox"
              label="Allow exact search for filters"
              className="text-sm"
            />
          </div>
         
        </div>

        <ReactTablePagination
          loading={loading}
          page={page}
          setPageSize={setPageSize}
          pageSize={pageSize}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          pageCount={pageCount}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
        />
      </div>

      <ReactTableUI
        rows={rows}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        onCopyToClipboard={onCopyToClipboard}
        tableStatus={tableStatus}
        enableRowClik={enableRowClik}
        onRowClick={onRowClick}
      />

    </>
  );
};

export default withServices('toastrService')(ReactTable);
