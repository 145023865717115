import React, { useMemo } from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import { Badge } from 'react-bootstrap';

function DisplayRowData({ label, value = '', listData = [] }) {
  return (
    <tr>
      <td className="col-3">
        <Badge>{label}</Badge>
      </td>
      <td>
        {
          !!value ? <Badge variant="light">{value}</Badge> : listData
        }
      </td>
    </tr>
  )
}

function TemplateCloneFormComponent({
  template, companies, status, isSubmitting, bindSubmission, submitForm, getFieldProps
}) {

  bindSubmission(submitForm, isSubmitting);

  const selectedCompanyUuid = getFieldProps('cloneCompanyUuid');

  const selectedCompany = useMemo(() => {
    const { value } = selectedCompanyUuid;
    let result = null
    if (!!value) {
      result = companies.find(company => company.uuid === value)
    }
    return result;
  }, [selectedCompanyUuid, companies]);

  return (
    <div>
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <span>{status.error}</span>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <label htmlFor="templateName">Template Name</label>
          <Field id="templateName" className="form-control" name="templateName" type="text" />
          <FieldErrorMessage name="templateName" />
        </div>
        <div className="form-group">
          <table className='table table-bordered'>
            <tbody>
              <DisplayRowData label="Company Name" value={template.Company.name}/>
              <DisplayRowData label="Template Type" value={template.tag}/>
              <DisplayRowData label="Asset Types" listData={
                template.assetTypes.map(
                  assetType =>
                    <Badge variant='primary' key={assetType} className='mr-2'>{assetType}</Badge>
                )
              } />
            </tbody>
          </table>
        </div>
        <div className="form-group">
          <label htmlFor="cloneCompanyUuid">Clone to company</label>
          <Field id="cloneCompanyUuid" className="form-control" name="cloneCompanyUuid" component="select">
            <option value="">Select company</option>
            {
              !!companies.length && companies.map(company => (
                <option value={company.uuid} key={company.uuid}>{company.name}</option>
              ))
            }
          </Field>
          <FieldErrorMessage name="cloneCompanyUuid" />
        </div>
        {
          selectedCompany && (
            <div className="form-group">
              <table className='table table-bordered'>
                <tbody>
                  <DisplayRowData label="Allowed Asset Types" listData={
                    selectedCompany.assetTypes.map(
                      assetType =>
                        <Badge variant='primary' key={assetType} className='mr-2'>{assetType}</Badge>
                    )
                  } />
                </tbody>
              </table>
            </div>
          )
        }
      </Form>
    </div>
  );
}

export default TemplateCloneFormComponent;
