import React from 'react';
import { withServices } from 'reaf';
import style from './StaticPageWithNav.module.scss';

function StaticPageFooterNavigation({ router, leftMenuInfo, rightMenuInfo }) {
    return (
        <div className="row mx-3">
            <div className={`col-9 ${style.footerNav}`}>
                <div className="text-left">
                    {!!leftMenuInfo && <a  onClick={() => router.setRoute('accountSettingsPage', { section: leftMenuInfo.route })}>{`<< ${leftMenuInfo.displayName}`}</a>}
                </div>
                <div className="text-right">
                    {!!rightMenuInfo && <a onClick={() => router.setRoute('accountSettingsPage', { section: rightMenuInfo.route })}>{`${rightMenuInfo.displayName} >>`}</a>}
                </div>
            </div>
            <div className="col-3" />
        </div>
    )
}

export default withServices('router')(StaticPageFooterNavigation);