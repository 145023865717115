import React from 'react';
import AccountsExecutiveDashboardPage from '../AccountsExecutiveDashboardPage/AccountsExecutiveDashboardPage';
import ProspectsList from '../../app/ProspectsList/ProspectsList';

function AccountsExecutiveProspectPage() {
  return (
    <AccountsExecutiveDashboardPage>
      <ProspectsList />
    </AccountsExecutiveDashboardPage>
  );
}

export default AccountsExecutiveProspectPage;
