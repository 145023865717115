import React, { useCallback } from 'react';
import { withServices } from 'reaf';
import Badge from 'react-bootstrap/Badge';
import { faCheck, faCopy, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import IconButton from '../../core/Button/IconButton';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import { PROPERTY_TYPES } from '../../../constants';
import TemplateCloneForm from '../TemplateCloneForm/TemplateCloneForm';
import { useState } from 'react';


const DisplayAvailableUnavailableState = ({ isAvailable }) => {
  return (
    <div className="d-flex justify-content-center">
    <IconButton
      className={`border-0 bg-transparent text ${isAvailable ? 'text-success' : 'text-danger'}`}
      style={{fontSize: '15px'}}
      icon={isAvailable ? faCheck : faTimesCircle}
      title={isAvailable ? 'Enabled' : 'Disabled'} />
    </div>
  )
}

const TemplateList = ({ queries, apiClient, router, companyService, projectsService, templateService, templateModelService, toastrService }) => {
  const {
    data: templates,
    loading,
    pageCount,
    onDownloadCSV,
    fetchData: fetchTemplates,
  } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    routeName: "superAdminTemplatesPage",
    resourceEndPoint: "/admin/templates",
    fileName: 'Templates.csv',
    csvDownloadEndPoint: "/admin/templates/downloadCSV",
  });

  const [companies, setCompanies] = useState([]);
  const loadAllCompanies = useCallback(async () => {
    const companies = await companyService.getAllCompanies();
    setCompanies(companies.rows); 
  }, [])

  React.useEffect(() => {
    loadAllCompanies();
  }, [])  

  const columns = React.useMemo(() => [
    {
      Header: 'Template Name',
      accessor: 'templateName',
      fixedWidth: 200
    },
    {
      Header: 'Company',
      accessor: 'Company.name',
      fixedWidth: 120,
      Cell: ({ row: { original: { Company } } }) => (Company ? `${Company.name}` : 'Deleted')
    },
    {
      Header: 'Asset Types',
      accessor: 'assetTypes',
      disableSortBy: true,
      disableFilters: true,
      fixedWidth: 180,
      Cell: ({ value: templateAssetTypes }) => (templateAssetTypes && templateAssetTypes.length > 0) && templateAssetTypes.map(assetType => {
        return (
          <Badge className='mr-1' variant="primary" key={assetType}>
            {PROPERTY_TYPES[assetType]?.badgeText}
          </Badge>
        )
     })
    },
    {
      Header: 'Dictionary',
      accessor: 'isDictionaryAvailable',
      fixedWidth: 100,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { original: { isDictionaryAvailable } } }) => <DisplayAvailableUnavailableState isAvailable={isDictionaryAvailable}/>
    },
    {
      Header: 'Category Seq',
      accessor: 'isCategorySequenceAvailable',
      fixedWidth: 130,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { original: { isCategorySequenceAvailable } } }) => <DisplayAvailableUnavailableState isAvailable={isCategorySequenceAvailable}/>
    },

    {
      Header: 'Report',
      accessor: 'isReportEnabled',
      fixedWidth: 100,
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { original: { isReportEnabled } } }) => <DisplayAvailableUnavailableState isAvailable={isReportEnabled}/>
    },
    {
      Header: 'Created On',
      accessor: 'createdAt',
      fixedWidth: 130,
      disableFilters: true,
      Cell: ({ value }) => <DisplayDate date={value} />
    },
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: true,
      disableFilters: true,
      fixedWidth: 70,
      Cell: ({ row: { original: template } }) => (
        (
          <div className="d-flex flex-row">
            <TemplateCloneForm
              templateService={templateService}
              templateModelService={templateModelService}
              toastrService={toastrService}
              template={template}
              companies={companies}
              label={
                <IconButton className="smallIconButton" placement="left" title="Clone Template" icon={faCopy} />
              }
            />
          </div>
        )
      )
    }
  ], [templates]);

  return <ReactTable
    columns={columns}
    data={templates}
    title="Templates"
    onDownloadCSV={onDownloadCSV}
    queries={queries}
    loading={loading}
    fetchData={fetchTemplates}
    pageCount={pageCount}
  />
};

export default withServices('apiClient', 'router', 'companyService', 'projectsService', 'templateService', 'toastrService', 'templateModelService')(TemplateList);
