import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { get, unset } from 'lodash';
import { withServices } from 'reaf';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './CustomTableFilters.module.scss';
import { ProjectListFiltered } from '../../../constants/eventTrackerMessage';
import Autocomplete from '../../core/Autocomplete/Autocomplete';

const autocompleteMenuStyle = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  maxHeight: '50%',
  margin: 0
};

export const ProjectNameFilter = withServices('eventTrackerService')(
  ({ queries, setFilters, accessor, eventTrackerService }) => {
    const filterValue = get(queries, `_search.${accessor}`, '');
    const [localValue, setLocalValue] = useState(filterValue);

    useEffect(() => {
      setLocalValue(decodeURIComponent(filterValue));
    }, [filterValue]);

    const onSubmit = useCallback(
      (e) => {
        e.stopPropagation();
        e.preventDefault();
        setFilters(localValue, `_search.${accessor}`);
        eventTrackerService.track(ProjectListFiltered, {
          columnName: 'Project Name',
          value: localValue
        });
      },
      [localValue, accessor, setFilters]
    );

    const onPropertyNameChange = useCallback(
      (e) => {
        setLocalValue(e.target.value || '');
      },
      [setLocalValue]
    );

    const onClearPropertyName = useCallback(() => {
      setLocalValue('');
      setFilters('', `_search.${accessor}`);
    }, [setLocalValue, setFilters, accessor]);

    return (
      <form className="d-flex projectNameFilter" onSubmit={onSubmit}>
        <input
          type="text"
          value={localValue}
          className="form-control"
          onChange={onPropertyNameChange}
          placeholder="Search property name ..."
        />
        <Button type="submit" variant="light" size="sm" className="btn-xs ml-1">
          <FontAwesomeIcon icon={faSearch} />
        </Button>
        {localValue && (
          <Button
            type="button"
            variant="light"
            size="sm"
            onClick={onClearPropertyName}
            className="btn-xs ml-1"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </form>
    );
  }
);

export const AutoCompleteModelUsedFilter = withServices('eventTrackerService')(
  ({
    queries,
    fetchProjects,
    accessor,
    modelUsedOptions,
    className,
    eventTrackerService
  }) => {
    const modelUsed = get(queries, accessor, '');
    const [autoCompleteValue, setAutoCompleteValue] = useState(modelUsed);
    const items = useMemo(
      () =>
        Reflect.ownKeys(modelUsedOptions).map((modelUuid) => ({
          uuid: modelUuid,
          label: modelUsedOptions[modelUuid]
        })),
      [modelUsedOptions]
    );
    const inputProps = useMemo(
      () => ({
        className: 'filter text-filter form-control'
      }),
      []
    );

    useEffect(() => {
      setAutoCompleteValue(modelUsed);
    }, [modelUsed]);

    const setFilters = useCallback(
      (localValue) => {
        let newQuery = { ...queries, _offset: 0, [accessor]: localValue };
        if (!localValue) {
          unset(newQuery, accessor);
        }
        fetchProjects(newQuery);
        eventTrackerService.track(ProjectListFiltered, {
          columName: 'Model Used Column',
          value: localValue
        });
      },
      [queries, fetchProjects]
    );

    const onChange = useCallback(
      (value, { clearSelection }) => {
        if (!value) {
          setFilters('');
          clearSelection();
        }
        setAutoCompleteValue(value);
      },
      [setFilters, setAutoCompleteValue]
    );

    const onSelect = useCallback(
      (item) => {
        if(item) {
          const { label } = item;
          setAutoCompleteValue(label);
          setFilters(label);
        }
      },
      [setFilters, setAutoCompleteValue]
    );

    const renderItem = useCallback(
      (item, isHighlighted) => (
        <div
          className={isHighlighted ? style.highlight : style.deEmphasize}
          key={item.uuid}
        >
          {item.label}
        </div>
      ),
      []
    );

    const renderInput = useCallback(
      (props) => <input placeholder="Select Model Used" {...props} />,
      []
    );

    const getItemValue = useCallback((x) => x ? x.label : '', []);
  
    const shouldItemRender = useCallback(
      (item, value) =>
        item.label.toLowerCase().indexOf(value.toLowerCase()) !== -1,
      []
    );

    return (
      <Autocomplete
        value={autoCompleteValue}
        inputProps={inputProps}
        itemToString={getItemValue}
        renderInput={renderInput}
        menuStyle={autocompleteMenuStyle}
        shouldItemRender={shouldItemRender}
        items={items}
        renderItem={renderItem}
        onSelect={onSelect}
        onChange={onChange}
        wrapperProps={{ className }}
      />
    );
  }
);

export const SelectAssetTypeFilter = withServices('eventTrackerService')(
  ({
    queries,
    setFilters,
    accessor,
    assetTypesOptions,
    setPropertyTypeFilter,
    eventTrackerService
  }) => {
    const assetType = get(queries, accessor, '');
    const [localValue, setLocalValue] = useState(assetType);

    useEffect(() => {
      setLocalValue(assetType);
    }, [assetType]);

    const onChange = useCallback(
      (e) => {
        const v = e.target.value;
        setLocalValue(v);
        setFilters(v || undefined, accessor);
        setPropertyTypeFilter(v);
        eventTrackerService.track(ProjectListFiltered, {
          columnName: 'Asset Class Column',
          value: v
        });
      },
      [setLocalValue, setFilters, setPropertyTypeFilter]
    );

    return (
      <select
        id="AssetTypeFilter"
        className="form-control ml-1"
        value={localValue}
        onChange={onChange}
      >
        <option value="">All Asset Classes</option>
        {Reflect.ownKeys(assetTypesOptions).map((uuid) => (
          <option value={uuid} key={uuid}>
            {assetTypesOptions[uuid]}
          </option>
        ))}
      </select>
    );
  }
);
