/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useCallback } from 'react';
import { isString, isNumber } from 'lodash';
import {
  PieChart, Pie, Sector, Cell, Tooltip, Legend
} from 'recharts';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { rentRollConfigTabNames, affixChartColors } from '../../../constants';
import style from './DocExtractionAffixChart.module.scss';
import './DocExtractionAffixPieChart.scss';
import { displayCurrencyFormat, displayNumberInUSFormat } from '../../../utils/utils';
import useSvgToImageDownload from '../../../hooks/useSvgToImageDownload';
import IconButton from '../../core/Button/IconButton';


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {
          payload.tabName === rentRollConfigTabNames.FP && `FP - ${payload.name}`
        }
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

      {
        payload.tabName === rentRollConfigTabNames.FP && (
          <>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Count- ${value} (${(percent * 100).toFixed(2)}%)`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={28} textAnchor={textAnchor} fill="#2b8eff">
              {`Beds- ${payload.Beds}, Baths- ${payload.Baths}`}
            </text>

          </>
        )
      }

      {
        payload.tabName === rentRollConfigTabNames.UNIT_STATUS && (
          <>
            <text x={ex + (cos >= 0 ? 1 : -1) * 24} y={ey} textAnchor={textAnchor} fill="#333">{`${value} (${(percent * 100).toFixed(2)}%)`}</text>

            <text x={ex + (cos >= 0 ? 1 : -1) * 24} y={ey} dy={28} textAnchor={textAnchor} fill="#2b8eff">
              {`${payload.name}`}
            </text>
          </>
        )
      }

    </g>
  );
};


const CustomTooltip = props => {
  // we don't need to check payload[0] as there's a better prop for this purpose
  if (!props.active) {
    // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
    return null;
  }

  const { payload } = props.payload[0].payload;

  return (
    <div className="card p-2">
      {
        Reflect.ownKeys(payload).map(tooltip => (
          <div className="row" key={tooltip}>
            <div className="col-12">
              {
                `${(tooltip === 'name' || tooltip === 'value') ? '' : (isString(payload[tooltip]) && !!payload[tooltip].length) ? `${tooltip} :` : isNumber(payload[tooltip]) ? `${tooltip} :` : ''}  ${tooltip === 'value' ? props.showCurrency ? displayCurrencyFormat(payload[tooltip]) : displayNumberInUSFormat(payload[tooltip]) : displayNumberInUSFormat(payload[tooltip])}`
              }
            </div>
          </div>
        ))
      }

    </div>
  );
};


function DocExtractionAffixPieChart({ data, basicChart = false, showCurrency = true }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [getPng, { ref, handleDownload }] = useSvgToImageDownload({ height: 600 });

  const onPieEnter = useCallback((index) => {
    setActiveIndex(index);
  });

  const onDownloadClick = useCallback(async () => {
    const png = await getPng();
    handleDownload(png);
  }, [handleDownload, getPng]);

  return (
    <>
      <div className={style.chartWrapper}>
        <div className={style.chartContainer}>
          {
            !basicChart ? (
              <PieChart width={500} height={300} ref={ref}>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx={240}
                  cy={120}
                  innerRadius={50}
                  outerRadius={70}
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={(_, index) => onPieEnter(index)}
                >
                  {
                    data.map((entry, index) => 
                      <Cell key={index} fill={affixChartColors[index % affixChartColors.length]} />
                    )
                  }
                </Pie>
              </PieChart>
            ) : (
              <div className="documentSummaryPieChartBottom">
                <PieChart width={500} height={250}  ref={ref} onMouseEnter={(_, index) => onPieEnter(index)}>
                  <Pie
                    data={data}
                    cx={250}
                    cy={120}
                    labelLine={false}
                    dataKey="value"
                    outerRadius={120}
                    fill="#8884d8"
                  >
                    {
                      data.map((entry, index) => 
                        <Cell key={index} fill={affixChartColors[index % affixChartColors.length]} />
                      )
                    }
                  </Pie>
                  <Legend verticalAlign="bottom" height={48} />
                  <Tooltip content={<CustomTooltip showCurrency={showCurrency} />} />
                </PieChart>
              </div>
            )
          }
          <div className={`${style.downloadBtnContainer} ${style.downloadPie}`}>
            <IconButton icon={faDownload} onClick={onDownloadClick} className={style.downloadImageBtn} text="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default DocExtractionAffixPieChart;
