import React from 'react';
import { connect } from 'react-redux';
import DocExtractionRentRollMFConfig from './DocExtractionRentRollMFConfig';
import DocExtractionRentRollRetailConfig from './DocExtractionRentRolRetailConfig';
import { isMFBaseType, isRetailBaseType } from '../../../utils/utils';
import { dynamicAssetTypesAsObjSelector, readOnlyAccessSelector } from '../../../store/selectors';

function DocExtractionRentRollConfig({
  assetType, isAcquisition, onRentRollConfigSubmit, onSlidePaneClose,
  onMergeChargeCodeColumn, onTenantTypeCalcChange, activeTabKey = '', setActiveTabKey,
  dynamicAssetTypes, readOnlyAccess
}) {

  return (
    <>
      {
        (
          isMFBaseType(assetType, dynamicAssetTypes) &&
          !dynamicAssetTypes[assetType].isRentRollRestricted
        ) && (
          <DocExtractionRentRollMFConfig
            onRentRollConfigSubmit={onRentRollConfigSubmit}
            onSlidePaneClose={onSlidePaneClose}
            onMergeChargeCodeColumn={onMergeChargeCodeColumn}
            activeTabKey={activeTabKey}
            isAcquisition={isAcquisition}
            setActiveTabKey={setActiveTabKey}
            readOnlyAccess={readOnlyAccess}
          />
        )
      }

      {
        (
          isRetailBaseType(assetType, dynamicAssetTypes) &&
          !dynamicAssetTypes[assetType].isRentRollRestricted
        ) && (
          <DocExtractionRentRollRetailConfig
            onRentRollConfigSubmit={onRentRollConfigSubmit}
            onSlidePaneClose={onSlidePaneClose}
            onTenantTypeCalcChange={(value) => onTenantTypeCalcChange(value)}
            onMergeChargeCodeColumn={onMergeChargeCodeColumn}
            activeTabKey={activeTabKey}
            setActiveTabKey={setActiveTabKey}
            readOnlyAccess={readOnlyAccess}
          />
        )
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state)
})

export default connect(mapStateToProps)(DocExtractionRentRollConfig);