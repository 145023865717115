import React from 'react';
import {
  Row, Col, ListGroup, Button
} from 'react-bootstrap';
import { faInfo as infoIcon } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import AssetTypeBadge from '../AssetTypeBadge/AssetTypeBadge';
import IconButton from '../../core/Button/IconButton';
import SwitchButton from '../../core/SwitchButton/SwitchButton';

function ToggleButton({ isActive, title, onChange }) {
  const [isActiveToggle, setIsActiveToggle] = React.useState(isActive)

  const onToggle = React.useCallback(() => {
    setIsActiveToggle(!isActiveToggle);
    onChange(!isActiveToggle)
  }, [isActiveToggle, onChange])

  return (
    <div className="d-flex justify-content-around align-items-center">
      <label>{title}</label>
      <div>
        <SwitchButton
          isActive={isActiveToggle}
          onChange={onToggle}
        />
      </div>
    </div>
  );
} 

function TemplateModelListItem({
  model, template, onDeleteTemplateModel, onEditTemplateModel, 
  onDownloadModel, onDownloadReportTemplate, onEditModel, onModelInformation, onToggleConsolidatedModel
}) {
const assetType = model.assetType &&
    (<AssetTypeBadge key={model.assetType} AssetType={model.assetType} />);

  return (
    <ListGroup.Item>
      <Row>
        <Col>
          {model.modelName}
          {assetType}
        </Col>
        <Col xs lg="1" style={{ display: 'none' }}>
          <Button
              variant="outline-primary"
              className="btn btn-default btn-sm"
              onClick={() => onEditTemplateModel(model)}
            >
              Edit
            </Button>
        </Col>
        <Col xs lg="3" pt={5}>
          <ToggleButton 
            isActive={model.canDownloadConsolidatedModel} 
            title="Allow Download consolidated model"
            onChange={(isActive) => onToggleConsolidatedModel(model, isActive)}
          />
        </Col>
        <Col xs lg="4">
          {!template.isGlobal && (
            <>
              <IconButton
                className="btn btn-default btn-sm"
                variant="outline-primary"
                onClick={() => onDownloadModel(model)}
                title="Download Model"
                text="Model Template"
                icon={faDownload}
              />

              {
                !!model.hasReportTemplateFile && <IconButton
                  className="ml-2 btn btn-default btn-md"
                  variant="outline-primary"
                  onClick={() => onDownloadReportTemplate(model)}
                  title="Download Report Template"
                  text="Report Template"
                  icon={faDownload}
                />
              }
            </>
          )}
        </Col>
        <Col xs lg="2">
          {!template.isGlobal && (
            <>
              <IconButton
                className="btn btn-default btn-sm"
                title="Delete Report Template"
                variant="outline-danger"
                onClick={() => onDeleteTemplateModel(model)}
                icon={faTrash}
                text="Delete"
              />
              <IconButton
                className="btn btn-default btn-sm"
                title="Update Report Template"
                variant="outline-primary"
                onClick={() => onEditModel(model)}
                icon={faPencilAlt}
                text="Update"
              />
              <IconButton
                className="btn btn-default btn-sm"
                title="Model Information"
                variant="outline-primary"
                onClick={() => onModelInformation(model)}
                icon={infoIcon}
              />
            </>
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default TemplateModelListItem;
