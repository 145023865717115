import React from 'react';
import { appConfig } from '../config';
import LoginPage from '../components/pages/LoginPage/LoginPage';
import ProjectsPage from '../components/pages/ProjectsPage/ProjectsPage';
import ProjectPage from '../components/pages/ProjectPage/ProjectPage';
import DocumentPage from '../components/pages/DocumentPage/DocumentPage';
import ExtractionPage from '../components/pages/ExtractionPage/ExtractionPage';
import AccountSettingsPage from '../components/pages/AccountSettingsPage/AccountSettingsPage';
import { ExtractionJobStatus } from '../constants';
import ForgotPasswordPage from '../components/pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '../components/pages/ResetPasswordPage/ResetPasswordPage';
import ReportsPage from '../components/pages/ReportsPage/ReportsPage';
import { showBackdrop, hideBackdrop } from '../store/loadingBackdrop';
import { loaderManager } from '../utils/utils';
import CompanyListPage from '../components/pages/CompanyListPage/CompanyListPage';
import CompaniesUsersListPage from '../components/pages/CompaniesUsersListPage/CompaniesUsersListPage';
import TermsAndConditionsPage from '../components/pages/TermsAndConditionsPage/TermsAndConditionsPage';
import ReleaseNotesPage from '../components/pages/ReleaseNotesPage/ReleaseNotesPage';
import PrivacyPage from '../components/pages/PrivacyPage/PrivacyPage';
import TemplateListPage from '../components/pages/TemplateListPage/TemplateListPage';
import TemplateModelListPage from '../components/pages/TemplateModelListPage/TemplateModelListPage';
import SuperAdminProjectPage from '../components/pages/SuperAdminProjectPage/SuperAdminProjectPage';
import SuperAdminDocumentsPage from '../components/pages/SuperAdminDocumentsPage/SuperAdminDocumentsPage';
import SuperAdminHomePage from '../components/pages/SuperAdminHomePage/SuperAdminHomePage';
import SuperAdminAccountsExecutivePage from '../components/pages/SuperAdminAccountsExecutivePage/SuperAdminAccountsExecutivePage';
import AccountsExecutiveProspectPage from '../components/pages/AccountsExecutiveProspectPage/AccountsExecutiveProspectPage';
import ProspectInvoicePage from '../components/pages/ProspectInvoicePage/ProspectInvoicePage';
import AccountsExecutivePage from '../components/pages/AccountsExecutivePage/AccountsExecutivePage';
import SuperAdminProjectExporterPage from '../components/pages/SuperAdminCompanyProjectExporterPage/SuperAdminCompanyProjectExporterPage';
import { AuthProviderLoginPage } from '../components/pages/AuthProvider/AuthProviderLoginPage';
import AcceptTermsAndConditionsPage from '../components/pages/AcceptTermsAndConditionsPage/AcceptTermsAndConditionsPage';
import TrialLoginPage from '../components/pages/TrialLoginPage/TrialLoginPage';
import TrialUserRegistrationPage from '../components/pages/TrialUserRegistrationPage/TrialUserRegistrationPage';
import LinkedInCallbackPage from '../components/pages/LinkedInCallbackPage/LinkedInCallbackPage';
import MicrosoftCallbackPage from '../components/pages/MicrosoftCallbackPage/MicrosoftCallbackPage';
import RouteNotFound from '../components/pages/RouteNotFound/404';
import TicketsPage from '../components/pages/TicketsPage/TicketsPage';
import SharedReportLinksPage from '../components/pages/SharedReportLinksPage/SharedReportLinksPage';
import SamlLoginPage from '../components/pages/SamlLoginPage/SamlLoginPage';
import ErrorPage from '../components/pages/ErrorPage/ErrorPage';
import GlobalReportWrapper from '../components/pages/GlobalReport/GlobalReportWrapper';
import SuperAdminAppConfigurationPage from '../components/pages/SuperAdminAppConfigurationPage/SuperAdminAppConfigurationPage';
import VideoTutorialsPage from '../components/pages/VideoTutorialsPage/VideoTutorialsPage';
import TutorialsPage from '../components/pages/TutorialsPage/TutorialsPage';
import SuperAdminDynamicAssetTypesPage from '../components/pages/SuperAdminDynamicAssetTypesPage/SuperAdminDynamicAssetTypesPage';
import {
  ExtractionPageOpened,
  ReportLinkOpened
} from '../constants/eventTrackerMessage';
import FeatureFlagsPage from '../components/pages/FeatureFlagsPage/FeatureFlagsPage';
import UsersListPage from '../components/pages/UsersListPage/UsersListPage';
import SignupPage, { SignupPageWithoutVerification } from '../components/pages/SignupPage/SignupPage';

import AddCardDetailPage from '../components/pages/AddCardDetailPage/AddCardDetailPage'
import FallbackPage from '../components/pages/FallbackPage/FallbackApproachPage';
import VerifyEmailPage from '../components/pages/VerifyEmailPage/VerifyEmailPage';
import GlobalTemplatesPage from '../components/pages/GlobalTemplatesPage/GlobalTemplatesPage';
import SubscriptionsListPage from '../components/pages/SubscriptionsListPage/SubscriptionsListPage'
import SuperAdminProspectList from '../components/pages/SuperAdminProspectsList/SuperAdminProspectsList'
import GlobalModelsPage from '../components/pages/GlobalModelsPage/GlobalModelsPage';
import AccountsExecutivePaymentLinksPage from '../components/pages/AccountsExecutivePaymentLinksPage/AccountsExecutivePaymentLinksPage';
import RecentProjectsPage from '../components/pages/RecentProjectsPage/RecentProjectsPage';
import RecentTaggedDocumentsPage from '../components/pages/RecentDocumentsPage/RecentTaggedDocumentsPage';
import RecentUnTaggedDocumentsPage from '../components/pages/RecentDocumentsPage/RecentUnTaggedDocumentsPage';
import AddUpdateCompanyTemplateList from '../components/pages/AddUpdateTemplatesPage/AddUpdateTemplatesPage';
import CompanyStatusPage from '../components/pages/CompanyStatusPage/CompanyStatusPage';
import UserLogPage from '../components/pages/UsersLogPage/UsersLogPage';
import SuperAdminTemplatesPage from '../components/pages/SuperAdminTemplatesPage/SuperAdminTemplatesPage';
import TokenValidationPage from '../components/pages/TokenValidationPage/TokenValidationPage';
import AdminLoginPage from '../components/pages/LoginPage/AdminLoginPage';
import AdminTokenValidationPage from '../components/pages/TokenValidationPage/AdminTokenValidationPage';

class Routes {
  // eslint-disable-next-line max-params
  constructor(
    authService,
    projectsService,
    documentsService,
    companyService,
    companyListService,
    store,
    baseUrl,
    userService,
    templateService,
    paymentService,
    templateModelService,
    accountsExecutiveService,
    prospectsService,
    projectExporterService,
    ticketService,
    appConfigurationService,
    videoTutorialService,
    reportLinkService,
    eventTrackerService,
    dynamicAssetTypesService,
    toastrService,
    paymentLinksService
  ) {
    this.authService = authService;
    this.projectsService = projectsService;
    this.documentsService = documentsService;
    this.companyService = companyService;
    this.companyListService = companyListService;
    this.userService = userService;
    this.templateService = templateService;
    this.paymentService = paymentService;
    this.templateModelService = templateModelService;
    this.accountsExecutiveService = accountsExecutiveService;
    this.prospectsService = prospectsService;
    this.paymentLinksService = paymentLinksService;
    this.projectExporterService = projectExporterService;
    this.ticketService = ticketService;
    this.appConfigurationService = appConfigurationService;
    this.store = store;
    this.baseUrl = baseUrl || '';
    this.pID = '1ae82a29-0856-48a4-a27f-befa04dfd8c6';
    this.videoTutorialService = videoTutorialService;
    this.reportLinkService = reportLinkService;
    this.eventTrackerService = eventTrackerService;
    this.dynamicAssetTypesService = dynamicAssetTypesService;
    this.toastrService = toastrService;
  }


  loggedIn = (context, params) => {
    if(!this.authService.isLoggedIn()) {
      const { pathname } = context;
      this.authService.getLoginUrl(pathname).then(response => {
        window.location = response.response;
      })
    }
    return null
  }
  hasAgreedTerms = () => {
    let page = null;
    if (!this.authService.hasAgreedTerms) {
      if (this.authService.isEnterpriseCompany || this.authService.isEnterpriseTrialUser) {
        page = 'acceptTermsPage';
      } else if (!this.authService.isSelfSignedAndSubscribed) {
        page = 'completeTrialUserRegistrationPage'
      }
      return { redirect: page }
    }
    return null
  }

  isSelfSignedAndSubscribed = () => {
    let page = null;
    if (!this.authService.isEnterpriseCompany) {
      if (!this.authService.isSelfSignedAndSubscribed && this.authService.isTrialDaysExpired) {
        page = 'addCardDetails';
        return { redirect: page }
      } else {
        return null;
      }
    }
    return null;
  }

  redirectExpiredTrialUser = () => {
    let page = null;
    const { isEnterpriseCompany, isEnterpriseTrialUser, isTrialDaysExpired } = this.authService;
    if (!isEnterpriseCompany && isEnterpriseTrialUser && isTrialDaysExpired) {
      page = 'addCardDetails';
      return { redirect: page }
    } else {
      return null;
    }
  }

  loggedOut = () =>
    this.authService.isLoggedIn()
      ? { redirect: this.authService.resolveRoute() }
      : null;

  isSuperAdmin = () =>
    !this.authService.isSuperAdmin() ? { redirect: 'roleAccessErrorPage' } : null;

  isSupportUser = () =>
    !this.authService.isSupportUser() ? { redirect: 'roleAccessErrorPage' } : null;

  isAccountsExecutive = () =>
    !this.authService.isAccountsExecutive()
      ? { redirect: 'roleAccessErrorPage' }
      : null;

  isEmailVerified = () =>
    !this.authService.isEmailVerified
      ? { redirect: 'verifyEmail' }
      : null;

  hasTrialExpired = () => this.authService.checkHasTrialExpired()

  getRoutes() {
    return [
      {
        path: '',
        name: 'loginPage',
        guards: [this.loggedOut],
        render: {
          component: <LoginPage />,
          title: 'Login'
        }
      },
      {
        path: '/token',
        name: 'tokenValidationPage',
        guards: [this.loggedOut],
        render: {
          component: <TokenValidationPage />,
          title: 'Login'
        }
      },
      {
        path: '/admin/token',
        name: 'adminTokenValidationPage',
        guards: [this.loggedOut],
        render: {
          component: <AdminTokenValidationPage />,
          title: 'Login'
        }
      },
      {
        path: '/verifyEmail',
        name: 'verifyEmail',
        guards: [],
        controller: async ({ queries }) => {
          const { email, token } = queries;
          return {
            component: <VerifyEmailPage {...{ email, token }} />,
            title: 'Verify Account'
          };
        },
      },
      {
        path: '/payment/callback',
        name: 'paymentCallback',
        guards: [this.loggedIn],
        controller: async ({ queries }) => {
          const { session_id: sessionId, redirectBackTo } = queries;
          await this.authService.validatePayment(sessionId);
          this.toastrService.success('Payment Successful! Thank you for your payment.');
          if (redirectBackTo) {
            window.location.href = redirectBackTo;
          }
          return ({
            component: <h1>{sessionId} Validating payment...</h1>,
            title: 'Payment Callback'
          })
        }
      },
      {
        path: '/global',
        name: 'global',
        children: [
          {
            name: 'ReportsPage',
            path: '/report/:reportUuid',
            controller: async (_, { reportUuid }) => {
              try {
                const {
                  companyUuid,
                  projectUuid,
                  templateUuid,
                  modelUuid,
                  canDownloadDocument,
                  canDownloadReportWorkbook
                }
                  = await this.projectsService.getReportLinkDetails(reportUuid);

                const project =
                  await this.projectsService.getPublicProjectDetails(
                    reportUuid,
                    projectUuid
                  );
                this.eventTrackerService.track(ReportLinkOpened, {
                  ...project,
                  canDownloadDocument
                });
                return {
                  component: (
                    <GlobalReportWrapper
                      companyUuid={companyUuid}
                      projectUuid={projectUuid}
                      templateUuid={templateUuid}
                      modelUuid={modelUuid}
                      reportUuid={reportUuid}
                      project={project}
                      canDownloadDocument={canDownloadDocument}
                      canDownloadReportWorkbook={canDownloadReportWorkbook}
                    />
                  ),
                  title: 'Project Report'
                };
              } catch (error) {
                return {
                  component: (
                    <ErrorPage
                      title="Link is not valid anymore"
                      errorMessage="Oops! This invite URL is not valid anymore"
                    />
                  )
                };
              }
            }
          }
        ]
      },
      {
        path: '/auth',
        name: 'auth',
        children: [
          {
            path: '/linkedin/callback',
            name: 'linkedinAuthCallback',
            guards: [this.loggedOut],
            controller: async ({ queries }) => ({
              component: <LinkedInCallbackPage code={queries.code} />,
              title: 'LinkedIn callback'
            })
          },
          {
            path: '/microsoft/callback',
            name: 'microsoftAuthCallback',
            guards: [this.loggedOut],
            controller: async ({ queries }) => ({
              component: <MicrosoftCallbackPage code={queries.code} />,
              title: 'Microsoft callback'
            })
          },
          {
            path: '/saml/callback',
            name: 'samlAuthCallback',
            controller: async ({ queries }) => {
              await this.authService.fetchUsersAuthState(queries);
              return {
                component: <SamlLoginPage />,
                title: 'Loging In...'
              };
            }
          },
          {
            path: '/saml/:companySlug',
            name: 'samlAuthLoginPage',
            controller: async ({ params: { companySlug }, queries }) => ({
              component: (
                <SamlLoginPage
                  companySlug={companySlug}
                  errorMessage={queries.message}
                />
              ),
              title: 'Login Page'
            })
          }
        ]
      },
      {
        path: '/admin/login',
        name: 'adminLoginPage',
        guards: [this.loggedOut],
        render: {
          component: <AdminLoginPage />,
          title: 'Admin Login'
        }
      },
      {
        name: 'termsPage',
        path: '/terms',
        render: {
          component: <TermsAndConditionsPage />,
          title: 'Terms and Conditions'
        }
      },
      {
        name: 'acceptTermsPage',
        path: '/accept-terms',
        render: {
          component: <AcceptTermsAndConditionsPage />,
          title: 'Accept Terms and Conditions'
        }
      },
      {
        name: 'completeTrialUserRegistrationPage',
        path: '/completeRegistration',
        render: {
          component: <TrialUserRegistrationPage />,
          title: 'Complete Registration'
        }
      },
      {
        name: 'releasePage',
        path: '/release',
        render: {
          component: <ReleaseNotesPage />,
          title: 'Release Notes'
        }
      },
      {
        name: 'privacyPolicyPage',
        path: '/privacy',
        render: {
          component: <PrivacyPage />,
          title: 'Privacy Policy'
        }
      },
      {
        name: 'roleAccessErrorPage',
        path: '/roleAccessError',
        guards: [this.loggedIn, this.hasAgreedTerms],
        render: {
          component: (
            <div>
              <h1>You don&apos;t have sufficient role to visit this page.</h1>
            </div>
          ),
          title: 'Role Access Error'
        }
      },
      {
        path: '/admin',
        guards: [this.loggedIn, this.hasAgreedTerms, this.isSuperAdmin],
        children: [
          {
            path: '',
            name: 'superAdminHomePage',
            controller: async () => {
              await this.templateService.loadGlobalTemplate();
              return {
                component: <SuperAdminHomePage />,
                title: 'Super Admin'
              }
            }
          },
          {
            path: '/tickets',
            name: 'TicketsPage',
            controller: async ({queries}) => {
              return {
                component: <TicketsPage queries={queries} />,
                title: 'Tickets'
              };
            }
          },
          {
            path: '/videos',
            name: 'VideoTutorialsPage',
            controller: async () => {
              await this.videoTutorialService.loadVideoTutorials();
              await this.videoTutorialService.listVideoCategories();
              return {
                component: <VideoTutorialsPage />,
                title: 'Tutorials'
              };
            }
          },
          {
            path: '/globalTemplateMappings',
            name: 'GlobalTemplateMappingsPage',
            controller: async () => {
              await this.templateService.loadGlobalTemplate();
              return {
                title: 'Global Templates',
                component: <GlobalTemplatesPage />
              }
            }
          },
          {
            path: '/subscriptionsList',
            name: 'SubscriptionsListPage',
            controller: async () => {
            await this.paymentService.getSubscriptionList();
              return {
                title: 'Subscriptions List',
                component: <SubscriptionsListPage />
              }
            }
          },
          {
            path: '/prospects',
            name: 'superAdminProspectPage',
            controller: async () => {
              await this.prospectsService.loadProspects();
              return {
                component: <SuperAdminProspectList />,
                title: 'Super Admin Prospect Page'
              };
            }
          },
          {
            path: '/prospects/:prospectUuid/invoices',
            name: 'superAdminProspectInvoicePage',
            controller: async ({ params: { prospectUuid } }) => {
              await this.prospectsService.loadProspectInvoices(prospectUuid);
              return {
                component: <ProspectInvoicePage isSuperAdmin/>,
                title: 'Super Admin Prospect Invoice Page'
              };
            }
          },
          {
            path: '/:templateUUID/globalModels',
            name: 'GlobalModelPage',
            controller: async ({ params: { templateUUID } }) => {
              await this.templateService.loadGlobalTemplate();
              await this.templateModelService.loadGlobalTemplateModelsList(
                templateUUID
              )
              return {
                title: 'Global Templates',
                component: <GlobalModelsPage />
              }
            }
          },
          {
            path: '/companiesWithStatus',
            name: 'superAdminCompanyStatusPage',
            controller: async({ queries }) => ({
              component: <CompanyStatusPage queries={queries} />,
              title: 'Companies with status'
            })
          },
          {
            path: '/companies',
            children: [
              {
                path: '',
                name: 'superAdminPage',
                controller: async ({ queries }) => {
                await this.templateService.loadGlobalTemplate();
                  return {
                    component: <CompanyListPage queries={queries} />,
                    title: 'Companies List'
                  }
                }
              },
              {
                path: '/:companyUUID/users',
                name: 'superAdminUserManagement',
                controller: async ({ params: { companyUUID } }) => {
                  await Promise.all([
                    await this.templateService.loadGlobalTemplate(),
                    this.companyListService.loadCompanyList(),
                    this.userService.loadCompanyUsersList(companyUUID),
                    this.templateService.loadCompanyTemplatesList(companyUUID)
                  ]);
                  return {
                    component: <CompaniesUsersListPage />,
                    title: 'User List'
                  };
                }
              },
              {
                path: '/:companyUUID/templates',
                name: 'superAdminTemplateManagement',
                controller: async ({ params: { companyUUID } }) => {
                  await Promise.all([
                    this.companyListService.loadCompanyList(),
                    this.templateService.loadCompanyTemplatesList(companyUUID)
                  ]);
                  return {
                    component: <TemplateListPage />,
                    title: 'Company Templates'
                  };
                }
              },
              {
                path: '/:companyUUID/templates/:templateUUID/models',
                name: 'superAdminModelManagement',
                controller: async ({ params: { companyUUID, templateUUID } }) => {
                  await Promise.all([
                    this.companyListService.loadCompanyList(),
                    this.templateService.loadCompanyTemplatesList(companyUUID),
                    this.templateModelService.loadTemplateModelsList(
                      companyUUID,
                      templateUUID
                    )
                  ]);
                  return {
                    component: <TemplateModelListPage isSuperAdmin={true} />,
                    title: 'Templates Models'
                  };
                }
              },
              {
                path: '/:companyUUID/projectExporters',
                name: 'superAdminCompanyProjectExporters',
                controller: async ({ params: { companyUUID } }) => {
                  await Promise.all([
                    this.companyListService.loadCompanyList(),
                    this.projectExporterService.getCompanyProjectExporters(
                      companyUUID
                    )
                  ]);
                  return {
                    component: <SuperAdminProjectExporterPage />,
                    title: 'Super Admin Company Project Exporters List'
                  };
                }
              }
            ]
          },
          {
            path: '/users',
            name: 'usersPage',
            controller: async ({ queries }) => ({
              title: 'Users',
              component: <UsersListPage queries={queries} />
            }),
          },
          {
            path: '/userLogs',
            name: 'userLogs',
            controller: async ({ queries }) => ({
              title: 'User Logs',
              component: <UserLogPage queries={queries} />
            }),
          },
          {
            path: '/configurations',
            name: 'superAdminConfigurationPage',
            controller: async () => {
              await Promise.all([
                this.appConfigurationService.loadAppConfigurations()
              ]);
              return {
                component: <SuperAdminAppConfigurationPage />,
                title: 'Super admin app configuration list'
              };
            }
          },
          {
            path: '/projects',
            name: 'superAdminProjectPage',
            controller: async ({ queries }) => ({
              component: <SuperAdminProjectPage queries={queries} />,
              title: 'Super Admin Projects List'
            })
          },
          {
            path: '/templates',
            name: 'superAdminTemplatesPage',
            controller: async ({ queries }) => ({
              component: <SuperAdminTemplatesPage queries={queries} />,
              title: 'Super Admin Templates List'
            })
          },
          {
            path: '/documents',
            name: 'superAdminDocumentsPage',
            controller: async ({ queries }) => ({
              component: <SuperAdminDocumentsPage queries={queries} />,
              title: 'Super Admin Documents List'
            })
          },
          {
            path: '/featureFlags',
            name: 'FeatureFlagsPage',
            controller: async () => ({
              component: <FeatureFlagsPage />,
              title: 'Feature Flags'
            })
          },
          {
            path: '/sharedReports',
            name: 'sharedReportsPage',
            controller: async () => {
              const reportLinks =
                await this.reportLinkService.getSharedReportLinks();
              return {
                component: <SharedReportLinksPage reportLinks={reportLinks} />,
                title: 'Super Admin Shared Report Links Page'
              };
            }
          },
          {
            path: '/accountsExecutive',
            name: 'superAdminAccountsExecutivePage',
            controller: async () => {
              await this.accountsExecutiveService.loadAccountsExecutives();
              return {
                component: <SuperAdminAccountsExecutivePage />,
                title: 'Super Admin Accounts Executive List'
              };
            }
          },
          {
            path: '/dynamicAssetTypes',
            name: 'superAdminDynamicAssetTypesPage',
            controller: async () => {
              await this.dynamicAssetTypesService.loadDynamicAssetTypes();
              return {
                component: <SuperAdminDynamicAssetTypesPage />,
                title: 'Super Admin Dynamic Asset Types List'
              };
            }
          }
        ]
      },
      {
        path: '/accountsExecutive',
        guards: [this.loggedIn, this.hasAgreedTerms, this.isAccountsExecutive],
        children: [
          {
            path: '',
            name: 'accountsExecutivePage',
            controller: async () => ({
              component: <AccountsExecutivePage />,
              title: 'Accounts Executive'
            })
          },
          {
            path: '/prospects',
            name: 'accountsExecutiveProspectPage',
            controller: async () => {
              await this.prospectsService.loadProspects();
              return {
                component: <AccountsExecutiveProspectPage />,
                title: 'Account Executive Prospect Page'
              };
            }
          },
          {
            path: '/prospects/paymentLinks',
            name: 'prospectPaymentLinksPage',
            controller: async () => {
              await this.paymentLinksService.listPaymentLinks();
              return {
                component: <AccountsExecutivePaymentLinksPage />,
                title: 'Account Executive Payment Links Page'
              };
            }
          },
          {
            path: '/prospects/:prospectUuid/invoices',
            name: 'accountsExecutiveProspectInvoicePage',
            controller: async ({ params: { prospectUuid } }) => {
              await this.prospectsService.loadProspectInvoices(prospectUuid);
              return {
                component: <ProspectInvoicePage />,
                title: 'Account Executive Prospect Invoice Page'
              };
            }
          }
        ]
      },
      {
        name: "addCardDetails",
        path: "/addCardDetails",
        guards: [this.loggedIn, this.isEmailVerified, this.hasAgreedTerms],
        render: {
          component: <AddCardDetailPage />,
          title: 'Choose your subscription plan'
        }
      },
      // {
      //   name: "fallbackApproach",
      //   path: "/parserbeta",
      //   guards: [this.loggedIn, this.isSupportUser],
      //   render: {
      //     component: <FallbackPage />,
      //     title: 'Parser Beta'
      //   }
      // },
      {
        name: 'projectsPage',
        path: '/projects',
        guards: [this.loggedIn, this.hasAgreedTerms, this.isEmailVerified, this.isSelfSignedAndSubscribed, this.redirectExpiredTrialUser],
        children: [
          {
            path: '',
            controller: async ({ queries }) => ({
              component: <ProjectsPage queries={queries} />,
              title: 'Projects'
            })
          },

          {
            path: '/:projectUuid',
            controller: async (_, { projectUuid }) => {
              await Promise.all([
                this.companyService.loadCompanyTemplates(),
                this.projectsService.loadCurrentProject(projectUuid),
                this.userService.loadUserAllowedTemplates()
              ]);
            },

            children: [
              {
                name: 'projectPage',
                path: '',
                controller: async (_, { projectUuid }) => {
                  this.documentsService.clearUploadQueue();
                  await this.projectsService.loadCurrentProjectDocuments(
                    projectUuid
                  );
                  await this.projectsService.getProjectDocumentSummary({
                    uuid: projectUuid
                  });
                  return {
                    component: <ProjectPage />,
                    title: 'Property Page'
                  };
                }
              },
              {
                path: '/documents/:documentUuid',
                controller: async ({ pathname }, { projectUuid, documentUuid }) => {
                  const document =
                    await this.documentsService.loadCurrentDocument(
                      projectUuid,
                      documentUuid
                    );
                  if (
                    document.extractionJob &&
                    document.extractionJob.status ===
                    ExtractionJobStatus.SUCCESSFUL.key &&
                    !pathname.endsWith('extraction') &&
                    !pathname.endsWith('extraction/')
                  ) {
                    return {
                      redirect: {
                        to: 'docExtractionPage',
                        params: {
                          projectUuid,
                          documentUuid
                        }
                      }
                    };
                  }
                },
                children: [
                  {
                    path: '',
                    name: 'documentPage',
                    render: {
                      component: <DocumentPage />,
                      title: 'Document Page'
                    }
                  },
                  {
                    path: '/extraction',
                    name: 'docExtractionPage',
                    controller: async (_, { projectUuid, documentUuid }) => {
                      const promise = Promise.all([
                        this.projectsService.loadProjectTemplateHeadCategories({
                          uuid: projectUuid
                        }),
                        this.documentsService.loadExtractedData(
                          projectUuid,
                          documentUuid
                        ),
                        this.documentsService.loadWorkbookData(
                          { uuid: projectUuid },
                          { uuid: documentUuid }
                        ),
                        this.documentsService.loadWorkbookHeadersList(
                          { uuid: projectUuid },
                          { uuid: documentUuid }
                        )
                      ]);

                      this.eventTrackerService.track(ExtractionPageOpened, {
                        projectUuid,
                        documentUuid
                      });

                      await loaderManager(
                        promise,
                        () =>
                          this.store.dispatch(
                            showBackdrop('Loading Extracted Data...')
                          ),
                        () => this.store.dispatch(hideBackdrop()),
                        50,
                        300
                      );

                      return {
                        component: <ExtractionPage />,
                        title: 'Document Extraction'
                      };
                    }
                  }
                ]
              },
              {
                name: 'projectReportsPage',
                path: '/:templateUuid/:modelUuid/reports',
                controller: async (
                  queryParams,
                  { projectUuid, templateUuid, modelUuid }
                ) => {
                  const { queries: { documentsUuidsForModel } = {} } = queryParams;
                  return ({
                    component: (
                      <ReportsPage
                        projectUuid={projectUuid}
                        templateUuid={templateUuid}
                        modelUuid={modelUuid}
                        selectedDocuments={documentsUuidsForModel}
                      />
                    ),
                    title: 'Project Report'
                  })
                }
              }
            ]
          }
        ]
      },
      {
        path: '/profile/:section',
        name: 'accountSettingsPage',
        guards: [this.loggedIn, this.hasAgreedTerms],
        controller: (_, { section }) => ({
          component: <AccountSettingsPage section={section} />,
          title: 'Account Settings - Profile'
        })
      },
      {
        name: 'forgotPasswordPage',
        path: '/forgotPassword',
        guards: [this.loggedOut],
        render: {
          component: <ForgotPasswordPage />,
          title: 'Forgot Password'
        }
      },
      {
        name: 'signUpPageWithoutVerification',
        path: '/signup/rk6syd(jqnwh=*hit30asnajh',
        guards: [this.loggedOut],
        render: {
          component: <SignupPageWithoutVerification />,
          title: 'Signup'
        }
      },
      {
        name: 'resetPasswordPage',
        path: '/resetPassword',
        guards: [this.loggedOut],
        render: {
          component: <ResetPasswordPage />,
          title: 'Reset Password'
        }
      },
      {
        name: 'addUpdateCompanyTemplateList',
        path: '/addUpdateCompanyTemplateList/:companyUuid',
        guards: [this.loggedIn],
        controller: async ({ params: { companyUuid }}) => {
          await Promise.all([
            this.templateService.loadCompanyTemplatesList(companyUuid),
            this.companyListService.loadCompanyList()
          ]);
          return {
            component: <AddUpdateCompanyTemplateList />,
            title: 'Add Update Template'
          };
        }
      },
      {
        path: '/:companyUUID/templates/:templateUUID/listTemplateModels',
        name: 'templateAccessModelManagement',
        controller: async ({ params: { companyUUID, templateUUID } }) => {
          await Promise.all([
            this.companyListService.loadCompanyList(),
            this.templateService.loadCompanyTemplatesList(companyUUID),
            this.templateModelService.loadTemplateModelsList(
              companyUUID,
              templateUUID
            )
          ]);
          return {
            component: <TemplateModelListPage isSuperAdmin={false} />,
            title: 'Templates Models'
          };
        }
      },
      {
        path: '/auth-provider',
        children: [
          {
            path: '/auth',
            children: [
              {
                path: '/login',
                name: 'authProviderLoginPage',
                controller: async (context) => {
                  const { a } = context.queries;
                  const applicationSlug = atob(a);
                  const loggedIn = this.authService.isLoggedIn();

                  return {
                    component: (
                      <AuthProviderLoginPage
                        applicationSlug={applicationSlug}
                        loggedIn={loggedIn}
                      />
                    ),
                    title: 'Clik Login'
                  };
                }
              }
            ]
          }
        ]
      },
      {
        name: 'tutorialVideos',
        path: '/tutorials/:section/:videoUuid',
        guards: [this.loggedIn],
        controller: (_, { section, videoUuid }) => ({
          component: <TutorialsPage section={section} videoUuid={videoUuid} />,
          title: 'Tutorials'
        })
      },
      {
        name: 'tutorials',
        path: '/tutorials/:section',
        guards: [this.loggedIn],
        controller: (_, { section }) => ({
          component: <TutorialsPage section={section} />,
          title: 'Tutorials'
        })
      },
      {
        name: 'recentProjects',
        path: '/recent-projects',
        guards: [this.loggedIn, this.hasAgreedTerms],
        controller: async ({ queries }) => ({
          component: <RecentProjectsPage queries={queries} />,
          title: 'Recent Projects'
        })
      },
      {
        name: 'recentTaggedDocuments',
        path: '/recent-tagged-documents',
        guards: [this.loggedIn, this.hasAgreedTerms],
        controller: async ({ queries }) => ({
          component: <RecentTaggedDocumentsPage queries={queries} />,
          title: 'Recent Documents'
        })
      },
      {
        name: 'recentUntaggedDocuments',
        path: '/recent-untagged-documents',
        guards: [this.loggedIn, this.hasAgreedTerms],
        controller: async ({ queries }) => ({
          component: <RecentUnTaggedDocumentsPage queries={queries} />,
          title: 'Recent Documents'
        })
      },
      {
        path: '(.*)',
        controller: () => ({
          component: <RouteNotFound />,
          title: 'Not Found'
        })
      }
    ];
  }
}

export default Routes;
