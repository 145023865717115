import { createDuck } from 'reaf';

const initialState = {
  accountsExecutiveList: []
};

const [
  { setAccountsExecutiveList },
  reducer
] = createDuck('autouw/accountsExecutiveList', {
  _initialState: initialState,

  setAccountsExecutiveList: accountsExecutiveList => state => ({
    ...state,
    accountsExecutiveList
  })
});

export { setAccountsExecutiveList };

export default reducer;
