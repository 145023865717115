import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import {
  companyUsersAsListSelector,
  currentCompanySelector
} from '../../../store/selectors';
import UserPopups from '../UserPopups/UserPopups';
import List from '../../core/List/List';
import UserListItem from './UserListItem';
import useExportUsers from '../../../hooks/useExportUsers';
import { PAGINATION_DEFAULT } from '../../../constants';

const initialUser = {
  firstName: '',
  lastName: '',
  email: '',
  password: ''
};
function UserList({ companyUsers, companyUUID, userService }) {
  const [showAddUserFormPopup, setShowAddUserFormPopup] = useState(false);
  const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [showEditTemplateAccess, setShowEditTemplateAccess] = useState(false);
  const [user, setUser] = useState(initialUser);

  const onDelete = useCallback((user) => {
    setUser(user);
    setShowDeleteUserConfirmation(true);
  }, [user]);

  const onResetPassword = useCallback((user) => {
    setUser(user);
    setShowResetPasswordModal(true);
  }, [user]);

  const addButtonText = 'Add User';
  const title = 'Users';
  const onAdd = useCallback(() => {
    setUser(initialUser);
    setShowAddUserFormPopup(true);
  }, []);

  const onDeleteConfirm = useCallback(async () => {
    await userService.deleteCompanyUser(user, companyUUID);
    setShowDeleteUserConfirmation(false);
  }, [user, companyUUID]);

  const onToggleAccess = useCallback(async (user) => {
    await userService.toggleAccess(user, companyUUID);
  }, [user, companyUUID]);

  const onToggleApiConfigAccess = useCallback(async (user) => {
    await userService.toggleApiConfigAccess(user, companyUUID);
  }, [user, companyUUID])

  const filterListItem = useCallback((companyUser, searchText) => (
    companyUser.firstName.toLowerCase() +
      companyUser.lastName.toLowerCase() +
      companyUser.email.toLowerCase()
  ).indexOf(searchText.toLowerCase()) !== -1,
  [],);

  const hidePopups = useCallback(() => {
    setShowAddUserFormPopup(false);
    setShowResetPasswordModal(false);
    setShowEditUserForm(false);
    setShowEditTemplateAccess(false);
  }, []);

  const onEdit = useCallback((user) => {
    setUser(user);
    setShowEditUserForm(true);
  }, []);

  const onEditTemplateAccess = useCallback((user) => {
    hidePopups();
    setUser(user);
    setShowEditTemplateAccess(true);
  }, []);

  const onExportCSV = useExportUsers();

  return (
    <>
      <List
        onAdd={onAdd}
        onExportCSV={onExportCSV}
        listItems={companyUsers}
        filterListItem={filterListItem}
        title={title}
        getKey={user => user.uuid}
        recordsPerPage={PAGINATION_DEFAULT.pageSize}
        ListItem={({ item: user }) => (
          <UserListItem
            user={user}
            onDelete={onDelete}
            onEdit={onEdit}
            onResetPassword={onResetPassword}
            onToggleAccess={onToggleAccess}
            onToggleApiConfigAccess={onToggleApiConfigAccess}
            onEditTemplateAccess={onEditTemplateAccess} />
        )}
      />
      <UserPopups
        onHide={hidePopups}
        showDeleteUser={showDeleteUserConfirmation}
        showCreateUser={showAddUserFormPopup}
        showResetPassword={showResetPasswordModal}
        user={user}
        companyUUID={companyUUID}
        showEditUserForm={showEditUserForm}
        onDeleteConfirm={onDeleteConfirm}
        showEditTemplateAccess={showEditTemplateAccess}
        onDeleteCancel={() => setShowDeleteUserConfirmation(false)}
      />
    </>
  );
}

const mapStateToProps = state => ({
  companyUsers: companyUsersAsListSelector(state),
  companyUUID: currentCompanySelector(state)
});

export default connect(mapStateToProps)(withServices('userService')(UserList));
