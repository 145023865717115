import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { components } from 'reaf';
import * as Yup from 'yup';
import messages from '../../../../locales';
import ResetPasswordFormComponent from './ResetPasswordFormComponent';
import { PASSWORD_SCHEMA } from '../../../constants';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email(messages.errors.auth.invalidEmail)
    .required(messages.errors.auth.invalidEmail),
  password: PASSWORD_SCHEMA,
  confirmPassword: Yup.string()
    .required(messages.errors.auth.requiredPassword)
    .oneOf([Yup.ref('password'), null], 'Your passwords don\'t match'),
  token: Yup.string().required()
});


function ResetPasswordForm({
  email, token, errorMessage, authService, router
}) {
  const [showResetPasswordConfirmation, setShowResetPasswordConfirmation] = useState(false);
  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      await authService.resetPassword(values);
      actions.setStatus({ success: 'You password is successfully changed.' });
      setShowResetPasswordConfirmation(true);
    } catch (e) {
      console.log(e);
      actions.setStatus({ error: e.message });
    }

    actions.setSubmitting(false);
  }, [authService]);

  const redirectToLogin = () => {
    router.setRoute('loginPage');
  };

  return (
    <Formik
      initialStatus={{ error: errorMessage }}
      initialValues={{
        email, token, password: '', confirmPassword: ''
      }}
      validationSchema={formSchema}
      onSubmit={onSubmit}
      showResetConfirmation={showResetPasswordConfirmation}
      onRedirectToLogin={redirectToLogin}
      component={formikProps => (
        <ResetPasswordFormComponent
          {...formikProps}
          showResetConfirmation={showResetPasswordConfirmation}
          onRedirectToLogin={redirectToLogin}
        />
      )}
    />
  );
}

export default components.withServices('authService', 'router')(ResetPasswordForm);
