import React, {
  useEffect, useMemo, useState, useCallback
} from 'react';
import {
  Card,
  Button
} from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { USER_ROLES } from '../../../constants';
import { companyUsersAsListSelector, loggedInCompanySelector } from '../../../store/selectors';
import UserPopups from '../UserPopups/UserPopups';
import pageStyle from './ManageUsers.module.scss';
import DisplayDate from '../../core/DisplayDate/DisplayDate';

const AccountSettingPageWrapper = ({ style, title, children }) => (
  <div className={`container-fluid ${style.pageOuterSection}`}>
    <div className="row">
      <div className={`col-12 d-flex border-bottom ${style.sectionHeader}`}>
        <h3>{title}</h3>
      </div>
    </div>
    <div className="d-flex justify-content-center">
      {children}
    </div>
  </div>
);

const ManageUsers = ({
  style, company, companyUsers = [], userService
}) => {
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const deleteSelectedUser = useCallback(
    async () => {
      await userService.deleteCompanyUser(selectedUser, company.uuid);
      setShowDeleteConfirmPopup(false);
    },
    [selectedUser, company]
  );

  const users = useMemo(() => (
    companyUsers.filter(user => user.UserRole.role === USER_ROLES.COMPANY_USER.key)
  ), [companyUsers]);

  useEffect(() => {
    if (company.uuid) {
      userService.loadCompanyUsersList(company.uuid);
    }
  }, [company]);

  return (
    <AccountSettingPageWrapper title="Manage Users" style={style}>
      <Card className={pageStyle.cardWrapper}>
        <Card.Body style={{ maxHeight: '500px', overflow: 'auto' }}>
          <BootstrapTable
            pagination
            options={{
              sizePerPage: 5,
              sizePerPageList: [{
                text: '5', value: 5
              }, {
                text: '10', value: 10
              }, {
                text: 'All', value: users.length
              }]
            }}
            data={users}
            tableStyle={{ background: '#fafafa' }}
          >
            <TableHeaderColumn
              dataField="uuid"
              isKey
              hidden
            >
              S.N.
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="firstName"
              dataSort
              filter={{ type: 'TextFilter', delay: 500 }}
              width="60"

            >
              First Name
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="lastName"
              dataSort
              filter={{ type: 'TextFilter', delay: 500 }}
              width="60"

            >
              Last Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="email"
              dataSort
              filter={{ type: 'TextFilter', delay: 500 }}
              width="60"

            >
              Email
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="createdAt"
              dataSort
              dataFormat={(date) => <DisplayDate date={date} />}
              width="60"
            >
              Created At
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="action"
              dataSort
              width="30"
              dataFormat={(cell, row) => (
                <Button
                  variant="danger" onClick={() => {
                    setSelectedUser(row);
                    setShowDeleteConfirmPopup(true);
                  }}>
                  Delete
                </Button>
              )}
            >
              Action
            </TableHeaderColumn>
          </BootstrapTable>
        </Card.Body>
      </Card>
      <UserPopups
        showDeleteUser={showDeleteConfirmPopup}
        user={selectedUser}
        onDeleteConfirm={() => deleteSelectedUser()}
        onDeleteCancel={() => setShowDeleteConfirmPopup(false)}
      />
    </AccountSettingPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  company: loggedInCompanySelector(state),
  companyUsers: companyUsersAsListSelector(state)
});

export default connect(mapStateToProps)(withServices('userService')(ManageUsers));
