import React, { useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { withServices } from 'reaf';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import clikaiBannerImg from '../../../assets/images/clikai-hero.svg';
import style from './SamlLoginPage.module.scss';
import useClikGatewayUrl from '../../../hooks/useClikGatewayUrl';

function SamlLoginPage({ errorMessage, companySlug, authService }) {
  const { cgAuthUrl } = useClikGatewayUrl(authService);

  useEffect(() => {
    if (!!cgAuthUrl) {
      redirectTOCG(cgAuthUrl, companySlug)
    }
  }, [cgAuthUrl])

  const redirectTOCG = (url, companySlug) => {
    const cgUrl = new URL(url);
    const redirectUrl = `${cgUrl.origin}/${companySlug}${cgUrl.search}`;
    setTimeout(() => {
      window.location.replace(redirectUrl)
    }, 1000)
  }
  return (
    <LoggedOutUserPage>
      <div className="vertical-section bg-light">
        <div className="container mt-5">
          <div className="row mt-5">
            <div className="col-xs-12 col-md-8 col-sm-12">
              <div className={`${style.clikaiBanner}`}>
                <img src={clikaiBannerImg} alt="Clik Banner" />
              </div>
            </div>
            <div className="col-xs-12 col-md-4 col-sm-12 ">
              <div className="d-flex align-items-center">
               <h3 className="clik-title">Redirecting to Clik Gateway...</h3>
              </div>
            </div>
          </div>
        </div>

      </div>
    </LoggedOutUserPage>
  );
}

export default withServices('authService')(SamlLoginPage);
