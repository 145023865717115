import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ClipBoardInput from '../ClipBoardInput/ClipBoardInput';
import style from './ConfirmationModal.module.scss';

function ConfirmationModal({
  title, message, onConfirm, onCancel, show, isConfirm = true, isClipboard = false,
  isDetele = false, showConfirmButton = true, size
}) {
  const buttonText = (() => {
    if (isDetele) {
      return 'Delete';
    } else if (isConfirm) {
      return 'Confirm';
    } else {
      return 'OK';
    }
  })();
  const [doubleClick, setDoubleClick] = useState(false);

  return (
    <Modal show={show} onHide={onCancel} backdrop="static" size={size}>
      <Modal.Header closeButton>
        <Modal.Title title={title}>
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={style.confirmationModal}>
        {isClipboard ?
          <ClipBoardInput text={message} /> : <p>{message}</p>}
      </Modal.Body>

      <Modal.Footer>
        {
          isConfirm &&
          <Button variant="outline-danger" size="sm" type="button" className="deleteCancel"
            onClick={onCancel}>Cancel</Button>
        }
        {showConfirmButton && <Button
          variant="primary" type="button" disabled={doubleClick} className="deleteConfirm"
          onClick={() => {
            onConfirm();
            setDoubleClick(true);
          }}>
          {buttonText}
        </Button>}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
