import React from 'react';
import { Form as FormikForm, Field } from 'formik';
import { Form, InputGroup } from 'react-bootstrap';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

function DocumentFormComponent({
  status, isSubmitting, bindSubmission, submitForm, extension
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div className="input-group-content-center-end">

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <FormikForm>
        <Form.Group controlId="exampleForm">
          <Form.Label>File Name</Form.Label>
          <InputGroup className="mb-4 position-relative d-flex justify-content-end">
            <Field
              className="form-control"
              type="text"
              name="fileName"
              style={{ padding: '1.3em' }}
            />
            <InputGroup.Append>
              <InputGroup.Text id="inputGroupPrepend">
.
                {extension}
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <FieldErrorMessage name="fileName" />
      </FormikForm>
    </div>
  );
}

export default DocumentFormComponent;
