import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { spreadShortcutKeys } from '../../../constants';
import style from './DocExtractionShortcutKeys.module.scss';

function DocExtractionShortcutKeys() {
  return (
    <Row>
      <Col className={`${style.shortcutKeysInfo}`}>
        <Table>
          <thead>
            <tr>
              <th>Key</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              spreadShortcutKeys.map(item => (
                <tr key={item.key}>
                  <td>{item.key.split(' ').map(value => <kbd key={value}>{value}</kbd>)}</td>
                  <td>{item.description}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default DocExtractionShortcutKeys;
