import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import styles from './FormSubmitButton.module.scss';

const FormSubmitButton = ({
  isValid,
  isSubmitting,
  title = 'Save',
  className = '',
  processingText = 'Saving...',
  ...othrProps
}) => (
  <Button
    type="submit"
    size="sm"
    disabled={!isValid || isSubmitting}
    {...othrProps}
    className={`${className} ${isValid ? styles['valid'] : styles['invalid']}`}
  >
    {isSubmitting ? (
      <>
        {' '}
        <span>{processingText}</span>{' '}
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </>
    ) : (
      title
    )}
  </Button>
);

export default FormSubmitButton;
