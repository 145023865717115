import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import { currentProjectSelector } from '../../../store/selectors';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import ProjectReport from '../../app/ProjectReport/ProjectReport';
import { ProjectReportOpened } from '../../../constants/eventTrackerMessage';

function ReportsPageHeader({ project }) {
  return (
    <Container fluid className="shadow p-0 pl-2 z-index-10">
      <Row>
        <Col className="p-2 pl-4" xs="auto">
          <Breadcrumbs items={[
            { route: 'projectPage', params: { projectUuid: project.uuid }, content: project.name },
            { to: '#', content: 'Reports' }
          ]}
          />
        </Col>
      </Row>
    </Container>
  );
}

function ReportsPage({ project, projectUuid, templateUuid, modelUuid, eventTrackerService, selectedDocuments }) {
  useEffect(() => eventTrackerService.track(ProjectReportOpened, project))
  return (
    <LoggedInUserPage noFooter>
      <div className="vertical-section">
        <ReportsPageHeader project={project} />
        <ProjectReport projectUuid={projectUuid} templateUuid={templateUuid} modelUuid={modelUuid} selectedDocuments={selectedDocuments} />
      </div>
    </LoggedInUserPage>
  );
}

const mapStateToProps = state => ({
  project: currentProjectSelector(state)
});

export default connect(mapStateToProps)(withServices('eventTrackerService')(ReportsPage));
