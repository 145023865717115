import React, { useCallback, useMemo } from 'react';
import { Field } from 'formik';
import { Col, Row, Button } from 'react-bootstrap';
import { toLower } from 'lodash';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import './FileUploaderStyle.scss';

const getFileExtension = (filename) => ((/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined);

function FileUploader({
  title, selectedFile, onFileChange, onRemoveFile, validExtensions, allowedFileSize, buttonTitlePrefix ='Choose'
}) {
  const cachedValidExtentions = useMemo(() => 
    (validExtensions ? validExtensions.map(toLower) : undefined),
  [validExtensions]);
  const fileChanged = useCallback((event) => {
    const file = event.target.files[0];
    const fileExtension = getFileExtension(file.name);
    const fileSize = file.size;
    let invalidFile = false;
    let errorMessage = '';
    if (allowedFileSize) {
      if (fileSize / (1024 * 1024) > allowedFileSize) {
        invalidFile = true;
        errorMessage = `Max File Size: ${allowedFileSize} MB`;
      }
    }
    if (cachedValidExtentions && !cachedValidExtentions.includes(toLower(fileExtension))) {
      invalidFile = true;
      errorMessage = `Allowed File Extensions:  ${cachedValidExtentions}`;
    }
    if (invalidFile) {
      // eslint-disable-next-line no-alert
      alert(`Invalid File, ${errorMessage}`);
    } else {
      onFileChange(event);
    }
  }, [validExtensions, allowedFileSize]);

  return (
    <>
      {(selectedFile && selectedFile.name) ? (
        <div className="form-group file-badge">
          <Row style={{ lineHeight: "36px", marginLeft: "0" }}>
            <label xs={1}>File:</label>
            <Col className="file-name">
              <span>{selectedFile.name}</span>
              <Button className="file-button" variant="link" onClick={onRemoveFile}>X</Button>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="form-group margin-zero-bottom">
          <label className="file-label" htmlFor={title}>{buttonTitlePrefix}&nbsp;{title}
          </label>
          <Field id={title} onChange={fileChanged} className="form-control file-input" type="file" name={title} />
          <FieldErrorMessage name={title} />
          {cachedValidExtentions ? (
            <span className="info-allowed-files">
              {' '}
Allowed extensions:
              {cachedValidExtentions.join(', ')}
              {' '}

            </span>
          ) : ''}
          {allowedFileSize ? (
            <span className="info-allowed-files">
              {' '}
Allowed File Size:
              {allowedFileSize}
              {' '}
MB
              {' '}
            </span>
          ) : ''}
        </div>
      )}
    </>
  );
}

export default FileUploader;
