import { Field } from 'formik'
import React from 'react'
import { Form } from 'react-bootstrap'
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage'
import FileUploader from '../FileUploader/FileUploader';
import { TEMPLATE_TAGS, VALID_PROJECT_FILE_EXTENSION, VALID_TEMPLATE_FILE_EXTENSION } from '../../../constants';

function BulkProjectUploadComponent({
  companies,
  status,
  isSubmitting,
  selectedProjectFile,
  projectFileName,
  bindSubmission,
  submitForm,
  onProjectFileChange,
  onRemoveProjectFile,
}) {

  bindSubmission(submitForm, isSubmitting);

  return (
    <div>
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <span>{status.error}</span>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <label htmlFor="companyUuid">Company</label>
          <Field id="companyUuid" className="form-control" name="companyUuid" component="select">
            <option value="">Select company</option>
            {
              !!companies.length && companies.map(company => (
                <option value={company.uuid} key={company.uuid}>{company.name}</option>
              ))
            }
          </Field>
          <FieldErrorMessage name="companyUuid" />
        </div>
        <div className="form-group">
          <FileUploader
            onFileChange={onProjectFileChange}
            validExtensions={VALID_PROJECT_FILE_EXTENSION}
            onRemoveFile={onRemoveProjectFile}
            title="Project File"
            selectedFile={selectedProjectFile}
            fileName={projectFileName} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="sendLogTo">Send Log to</label>
          <Field id="sendLogTo" className="form-control" name="sendLogTo" type="text" />
          <FieldErrorMessage name="sendLogTo" />
        </div>
      </Form>
    </div>
  )
}

export default BulkProjectUploadComponent
