import React from 'react';
import { Form, Field } from 'formik';
import { withServices } from 'reaf';
import { Form as BSForm, Card, Alert } from 'react-bootstrap';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import clikLogoImg from '../../../assets/images/clikai-logo.svg';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import useBool from '../../../hooks/useBool';
import Link from '../../core/Link/Link';
import { PasswordStrengthInfo } from '../PasswordStrengthInfo/PasswordStrengthInfo';
import PasswordViewer from '../PasswordViewer/PasswordViewer';
import { SpriteCompanyIcon, SpriteEmailIcon, SpriteLockIcon, SpriteUserIcon } from '../../core/SpriteIcon/SpriteIcon';
import IconButton from '../../core/Button/IconButton';
import SocialSignUpComponent from './SocialSignUpComponent/SocialSignUpComponent';
import style from './SignUpFromComponent.module.scss';

function SignUpFromComponent({
  status, isSubmitting, onGoogleLoginSuccess, onGoogleLoginFailure, errors, noVerification, onlyEmailSignup = false
}) {
  const [agreedToTerms, , , toggleAgreement] = useBool(false);
  const [showEmailOption, setShowEmailOption] = React.useState(false);

  React.useEffect(() => {
    if(onlyEmailSignup) {
      setShowEmailOption(true);
    }
  },[onlyEmailSignup]);


  return (
    <Card className="form-card">
      <Card.Body className={style.formCardBody}>
        <div className="loginFormLogo">
          <img src={clikLogoImg} alt="Clik Logo" />
        </div>

        <div className="row">
          <div className="col-md-12">
            {
              status && status.error &&
              (
                <Alert variant="danger">
                  <p>{status.error}</p>
                </Alert>
              )
            }
          </div>
        </div>
        <div className="row align-items-center">
          {
            !showEmailOption ? (
              <div className="col-12">
                <div className="mb-3">
                  <h4><strong>Start for Free Today</strong></h4>
                  <p>Get started with Clik tools, and upgrade as you grow.</p>
                </div>
                <SocialSignUpComponent 
                  onGoogleLoginSuccess={onGoogleLoginSuccess} 
                  onGoogleLoginFailure={onGoogleLoginFailure}
                />
                
                <div className={style.formSeparator}>
                  <small>&nbsp;</small>
                  <span>Or</span>
                </div>

                <div className="mb-1 btnEmail">
                  <button type="button" onClick={() => setShowEmailOption(true)} className={`btn btn-primary btn-block ${style.btnEmail}`}>Sign up with Email</button>
                </div>

                <div className="mb-3">
                  <small className="text-secondary">Already signed up? </small>
                  <Link className="text-primary text-decoration mt-3 d-inline-block" route="loginPage">
                    <b>Log in</b>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="col-12">
                {!onlyEmailSignup && <h5 className="mb-3">
                  <IconButton
                    title="" className="text-left mr-3"
                    variant="link" size="sm" icon={faArrowLeft}
                    onClick={() => setShowEmailOption(false)}
                  />
                  Sign up with email
                </h5>}
                <Form autoComplete="off">
                  <BSForm.Group controlId="company.name" className="position-relative">
                    <BSForm.Label>Company Name *</BSForm.Label>
                    <div className="loginFormIcon">
                      <SpriteCompanyIcon />
                    </div>
                    <Field id="company.name" className={`form-control ${style.formControl}`} name="company.name" type="text" />
                    <FieldErrorMessage name="company.name" />
                  </BSForm.Group>
                  <BSForm.Group controlId="admin.firstName" className="position-relative">
                    <BSForm.Label>First Name</BSForm.Label>
                    <div className="loginFormIcon">
                      <SpriteUserIcon />
                    </div>
                    <Field id="admin.firstName" className={`form-control ${style.formControl}`} name="admin.firstName" type="text" />
                    <FieldErrorMessage name="admin.firstName" />
                  </BSForm.Group>

                  <BSForm.Group controlId="admin.lastName" className="position-relative">
                    <BSForm.Label>Last Name</BSForm.Label>
                    <div className="loginFormIcon">
                      <SpriteUserIcon />
                    </div>
                    <Field id="admin.lastName" className={`form-control ${style.formControl}`} name="admin.lastName" type="text" />
                    <FieldErrorMessage name="admin.lastName" />
                  </BSForm.Group>
                  <BSForm.Group controlId="admin.email" className="position-relative">
                    <BSForm.Label>Email</BSForm.Label>
                    <div className="loginFormIcon">
                      <SpriteEmailIcon />
                    </div>
                    <Field id="admin.email" className={`form-control ${style.formControl}`} name="admin.email" type="email" />
                    <FieldErrorMessage name="admin.email" />
                  </BSForm.Group>
                  <BSForm.Group controlId="admin.password" className="position-relative passwordGroupRow">
                    <BSForm.Label>Password <PasswordStrengthInfo /></BSForm.Label>
                    <PasswordViewer showIcon id="password" name="admin.password" placeholder="Enter your password" />
                    <FieldErrorMessage name="admin.password" />
                  </BSForm.Group>

                  <BSForm.Group controlId="admin.confirmPassword" className="position-relative">
                    <BSForm.Label>Confirm Password</BSForm.Label>
                    <div className="loginFormIcon">
                      <SpriteLockIcon />
                    </div>
                    <Field
                      id="confirmPassword"
                      name="admin.confirmPassword"
                      type="password"
                      className={`form-control ${style.formControl}`}
                      placeholder="Re-enter your new password"
                    />
                    <FieldErrorMessage name="admin.confirmPassword" />
                  </BSForm.Group>

                  <BSForm.Group controlId="agree">
                    <BSForm.Check
                      checked={agreedToTerms}
                      onChange={toggleAgreement}
                      type="checkbox"
                      label={(
                        <span>
                          <small>
                            By clicking Create Account you agree to our&nbsp;
                            <br />
                            <Link route="termsPage">Terms of Service</Link>
                            &nbsp;and&nbsp;
                            <Link route="privacyPolicyPage">Privacy Policy</Link>
                            .
                          </small>
                        </span>
                      )}
                    />
                  </BSForm.Group>
                  <FormSubmitButton
                    className={`btn-block ${style.btnPrimary}`}
                    isSubmitting={isSubmitting}
                    isValid={agreedToTerms}
                    title="Create Account"
                    processingText="Please wait..."
                  />
                </Form>
              </div>
            )
          }
        </div>
      </Card.Body>
    </Card>
  );
}

export default withServices('store')(SignUpFromComponent);
