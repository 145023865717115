import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import IconButton from '../../core/Button/IconButton';
import style from './ProjectsPageSubHeader.module.scss';

export default function ProjectsPageSubHeader({ onAdd, isReadOnly }) {
  return (
    <Container fluid className="bg-white subHeader-ms">
      <Row className="align-items-center">
        <Col sm="auto">
          <h5 className={`mb-0 position-relative ${style.headingAligment}`}>Property</h5>
        </Col>
        <Col sm="auto pl-0">
          <IconButton
            title="Add Property"
            className="btn-circle btn-shadow"
            variant="outline-secondary"
            size="sm"
            icon={faPlus}
            onClick={onAdd}
            disabled={isReadOnly}
            id="AddProjectButton"
          />
        </Col>
        <Col />
      </Row>
    </Container>
  );
}
