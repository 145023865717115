import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withServices } from 'reaf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { Button, Spinner } from 'react-bootstrap';
import { faTimesCircle as closeIcon } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faCheck as tickIcon } from '@fortawesome/free-solid-svg-icons/faCheck';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import TourIconButton from '../../app/IconButtons/TourIconButton';
import WithTooltip from '../../core/WithTooltip/WithTooltip';
import messages from '../../../../locales/en-US';
import { DocumentFileTypes, PROPERTY_TYPES } from '../../../constants';

function ValidateButtonText({ buttonText, buttonIcon }) {
  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon={buttonIcon} />
      <p className='ml-2 mb-0'>{buttonText}</p>
    </div>
  )
}

function ExtractionPageHeader({
  document, project, isDocumentValidated, isDocumentValidating, isBetaParserEnabled, 
  isBetaParserLoading, readOnlyAccess, onValidateAndSave, onHelpClick, onBetaParserClick
}) {

  const commericalBaseClassAssetTypes = React.useMemo(() => {
    return Reflect.ownKeys(PROPERTY_TYPES)
      .filter(propertyType => PROPERTY_TYPES[propertyType].baseClass === 'COMMERCIAL')
  }, [PROPERTY_TYPES])

  return (
    <Container fluid className="z-index-10 bg-light sub-header">
      <Row className="align-items-center">
        <Col xs="auto" className="extractionBreadcrumb">
          <Breadcrumbs items={[
            { route: 'projectPage', params: { projectUuid: project.uuid }, content: project.name },
            { to: '#', content: document.fileName },
            { to: '#'
            
            , content: 'Extraction' }
          ]}
          />
        </Col>
        <Col />
        {
          (isBetaParserEnabled &&
            document?.taggingData?.documentType === DocumentFileTypes.RENT_ROLL.key) &&
          commericalBaseClassAssetTypes.includes(project?.baseClass) && (
            <Col xs="auto" className="pl-0 tourIconBtn" style={{ right: '45px' }}>
              <Button
                variant='info' className='py-1'
                disabled={isBetaParserLoading}
                onClick={onBetaParserClick}
              >
                {isBetaParserLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {' '}
                    <span>Please wait...</span>{' '}
                  </>
                ) : 'Beta Parser'}
              </Button>
            </Col>
          )
        }
     
        <Col xs="auto" className="pl-0 tourIconBtn" style={{ right: '51px' }}>
          <WithTooltip tooltipText={
              isDocumentValidated ? 
              messages.toastMessage.DOC_INVALIDATE_TOOLTIP:  messages.toastMessage.DOC_VALIDATE_TOOLTIP}
            >
            <Button
              variant={isDocumentValidated ? 'danger' : 'success'}
              size='sm'
              id='mark-validate'
              className='mr-2 py-1'
              disabled={isDocumentValidating || readOnlyAccess}
              onClick={onValidateAndSave}>
              {
                isDocumentValidated ? 
                  <ValidateButtonText buttonText="Mark Invalidated" buttonIcon={closeIcon}/> : 
                  <ValidateButtonText buttonText="Mark Validated" buttonIcon={tickIcon}/>
              }
            </Button>
          </WithTooltip>
          <TourIconButton id="ShowTourButton" onClick={onHelpClick} />
        </Col>
      </Row>
    </Container>
  );
}

export default ExtractionPageHeader;
