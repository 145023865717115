import React, { useState } from 'react';
import { Field, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form as BSForm, Card, Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import clikLogoImg from '../../../assets/images/clikai-logo.svg';
import Link from '../../core/Link/Link';
import { captcha } from '../../../config/index';
import './ForgotPasswordFormComponent.scss';
import { SpriteEmailIcon } from '../../core/SpriteIcon/SpriteIcon';

function ForgotPasswordFormComponent({ status, isSubmitting, recaptchaRef }) {
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  return (
    <Card className="form-card">
      <Card.Body className="form-card-body">
      <div className="loginFormLogo">
          <img src={clikLogoImg} alt="Clik Logo" />
        </div>
        {
          status && status.error &&
          (
            <Alert variant="danger">
              <div>{status.error}</div>
            </Alert>
          )
        }
        {
          status && status.success &&
          (
            <Alert variant="success">
              <div>{status.success}</div>
            </Alert>
          )
        }

        <p>Please provide your email address to reset your password</p>

        <Form>
          <BSForm.Group controlId="email" className="position-relative">
            <BSForm.Label>Email Address</BSForm.Label>
            <div className="loginFormIcon emailIcon">
              <SpriteEmailIcon />
            </div>
            <Field
              id="email"
              className="form-control formControl"
              type="email"
              name="email"
              autoComplete="off"
              placeholder="Enter your email address"
            />
            <FieldErrorMessage name="email" />
          </BSForm.Group>

          <BSForm.Group>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={captcha.siteKey}
              onChange={(t) => setCaptchaVerified(t)}
            />
          </BSForm.Group>

          <Button className="btn btn-primary btn-block" type="submit" disabled={isSubmitting || !isCaptchaVerified}>Continue</Button>
        </Form>

        <small><Link className="text-secondary text-decoration-none mt-3 d-inline-block" route="loginPage">Continue to login screen</Link></small>
      </Card.Body>
    </Card>
  );
}

export default ForgotPasswordFormComponent;
