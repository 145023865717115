import React, { useRef } from 'react';
import { Formik } from 'formik';
import { components } from 'reaf';
import * as Yup from 'yup';
import messages from '../../../../locales';
import ForgotPasswordFormComponent from './ForgotPasswordFormComponent';


const formSchema = Yup.object().shape({
  email: Yup.string()
    .email(messages.errors.auth.invalidEmail)
    .required(messages.errors.auth.invalidEmail)
});


function ForgotPasswordForm({ authService }) {
  const recaptchaRef = useRef(null);
  async function onSubmit(values, actions) {
    actions.setStatus({});
    values.captchaToken = recaptchaRef.current.getValue();
    try {
      await authService.requestResetPassword(values);
      actions.setStatus({ success: 'We have sent you a mail with instructions to reset your password. Please follow the mail instructions to reset your password' });
    } catch (error) {
      let { message } = error;
      recaptchaRef.current.reset();
      actions.setStatus({ error: message });
    }

    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={formSchema}
      onSubmit={onSubmit}
      component={
        formikProps => (
          <ForgotPasswordFormComponent
            {...formikProps}
            recaptchaRef={recaptchaRef}
          />
        )}
    />
  );
}

export default components.withServices('authService')(ForgotPasswordForm);
