import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV as dottedIcon } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import ItemWithContextMenu from '../ItemTile/ItemWithContextMenu';
import style from './DocExtractionChargeCodeMenu.module.scss';

const ContextMenu = ({ hideContextMenu, onMenuSelect }) => (
  <div>
    <Dropdown.Menu show>
      <Dropdown.Item onClick={useCallback(() => hideContextMenu() & onMenuSelect())}>Merge Charge Code</Dropdown.Item>
    </Dropdown.Menu>
  </div>
);

export const DocExtractionChargeCodeMenu = ({ onMenuSelect }) => (
  <div className={`width-dottedCol ${style.appContextMenu} ${style.widthDotted}`}>
    <ItemWithContextMenu
      openOnClick

      TileComponent={() => (
        <FontAwesomeIcon icon={dottedIcon} />
      )}
      ContextMenuComponent={({ hideContextMenu }) => (
        <ContextMenu
          hideContextMenu={hideContextMenu}
          onMenuSelect={onMenuSelect}
        />
      )}
    />
  </div>
);
