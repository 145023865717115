import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import {
  Spinner
} from 'react-bootstrap';
import SsoConfigComponent from './SsoConfigComponent';

const SsoConfigSchema = Yup.object().shape({
  nameId: Yup
    .string()
    .trim()
    .required('Please provide a valid name id.'),
  userAttribute: Yup
    .string()
    .trim()
    .required('Please provide a valid user attribute.'),
  signinPageUrl: Yup
    .string()
    .url('Please provide a valid page URL.')
    .required('Please provide a valid page URL.'),
  idpCertificate: Yup
    .string()
    .trim()
    .required('Please provide a valid page Certificate.'),

  issuer: Yup
    .string()
    .trim(),

  binding: Yup
    .mixed()
    .oneOf([
      "HTTP-POST",
      "HTTP-Redirect"
    ], 'Please select a valid binding.'),
});

const initialValues = {
  nameId: "EMAIL",
  userAttribute: "EMAIL",
  signinPageUrl: "",
  idpCertificate: "",
  issuer: "",
  binding: "HTTP-POST"
};
function SsoConfigForm({ ssoConfigurationService, toastrService, company }) {
  const [initialSsoConfiguration, setInitialSsoConfiguration] = useState();

  useEffect(() => {
    async function fetchInitialSsoConfiguration() {
      const ssoConfig = await ssoConfigurationService.fetchCompanySsoconfiguration(company);
      setInitialSsoConfiguration({ ...initialValues, ...ssoConfig });
    }
    if (company && company.uuid) {
      fetchInitialSsoConfiguration();
    }
  }, [company]);
  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      await ssoConfigurationService.createOrUpdateSsoConfiguration(company, values);
      toastrService.success('SSO configuraton saved!');
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }, [company]);

  return (
    <>
      {initialSsoConfiguration ?
        (
          <Formik
            initialValues={initialSsoConfiguration}
            validationSchema={SsoConfigSchema}
            isInitialValid
            component={(formikProps) => <SsoConfigComponent company={company} {...formikProps} />}
            onSubmit={onSubmit}
          />
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </div>
        )}
    </>
  );
}

export default withServices('ssoConfigurationService', 'toastrService')(SsoConfigForm);
