import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { SketchPicker } from 'react-color';

function ColorPicker({ children, onSetColor }) {
  return (
    <OverlayTrigger
      placement="auto"
      delay={{ show: 10, hide: 10 }}
      trigger="click"
      rootClose
      overlay={(
        <div>
          <SketchPicker onChangeComplete={onSetColor} />
        </div>
      )}
    >
      <div className="d-inline-block">
        {children}
      </div>
    </OverlayTrigger>
  );
}

export default ColorPicker;
