import React from 'react';
import { appConfig } from '../../../config';

export default function ProTag({ className }) {
  return (
    appConfig.isFreebieApp && (
      <span className={`badge badge-pill badge-warning ${className}`}>Pro</span>
    )
  );
}
