import { saveAs } from 'file-saver';
import { showBackdrop, hideBackdrop, isFetchingList } from '../store/loadingBackdrop';

const getResponseRow = response => response.row;

class Service {
  constructor(apiClient, store) {
    this.store = store;
    this.apiClient = apiClient;
  }

  /**
   * @param {*} endPoint
   * @param {*} maxRowCountPerPage
   * @param {*} action
   * @param {*} processResponse
   * @param {*} timeoutTime Required to let React render the first batch quickly.
   */
  // eslint-disable-next-line max-params
  async fetchList(
    endPoint,
    maxRowCountPerPage,
    action,
    processResponse = getResponseRow,
    timeoutTime = 100,
    options = {}
  ) {
    this.store.dispatch(showBackdrop('Please Wait...'));
    this.store.dispatch(isFetchingList(true));
    const [totalCount, list] = await this.fetchFirstBatch(
      endPoint, maxRowCountPerPage, action, processResponse, options
    );
    if (totalCount > maxRowCountPerPage) {
      if (timeoutTime) {
        setTimeout(async () => {
          await this.fetchOtherBatches(
            endPoint, maxRowCountPerPage, action, processResponse, totalCount, list, options
          );
          this.store.dispatch(isFetchingList(false));
        }, timeoutTime);
      } else {
        await this.fetchOtherBatches(endPoint, maxRowCountPerPage, action, processResponse, totalCount, list, options);
        this.store.dispatch(isFetchingList(false));
      }
    } else {
      this.store.dispatch(isFetchingList(false));
    }
    this.store.dispatch(hideBackdrop());
  }

  async fetchOtherBatches(endPoint, maxRowCountPerPage, action, processResponse, totalCount, list, options) {
    const noOfApiCalls = Math.ceil(totalCount / maxRowCountPerPage) - 1;
    const queryString = options.queryParams ? this.getQueryString(options.queryParams) : '';

    const nextResponseDocuments = await Promise.all(
      Array(noOfApiCalls)
        .fill(0)
        .map(async (_, index) => {
          const response = await this.apiClient.get(
            `${endPoint}?pageLimit=${maxRowCountPerPage}&pageNumber=${index + 2}&${queryString}`
          );
          return processResponse(response.response);
        }),
    );

    const allItems = list.concat(...nextResponseDocuments);
    this.store.dispatch(action(allItems));
    return allItems;
  }

  async fetchFirstBatch(endPoint, maxRowCountPerPage, action, processResponse, options) {
    const queryString = options.queryParams ? this.getQueryString(options.queryParams) : '';
    const endPointWithPagination = `${endPoint}?pageLimit=${maxRowCountPerPage}&${queryString}`;
    const result = await this.apiClient.get(endPointWithPagination);
    const totalCount = result.response.count;
    const list = processResponse(result.response);
    this.store.dispatch(action(list));
    return [
      totalCount,
      list
    ];
  }

  async downloadFile(url, fileName) {
    const response = await this.apiClient.get(
      url, { responseType: 'blob' },
    );
    saveAs(response, fileName);
  }

  getQueryString(queryParams) {
    return Reflect.ownKeys(queryParams)
      .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join('&');
  }

  getUrlWithQueryParams(url, queryParams) {
    return `${url}?${this.getQueryString(queryParams)}`;
  }
}

export default Service;
