import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import withServices from 'reaf/lib/components/withServices';
import {
  currentDocumentTypeSelector,
  chargeCodeConfigSelector,
  currentDocumentUnitStatusSelector,
  currentDocumentFloorPlanSelector
} from '../../../store/selectors';
import styles from './ChargeCodeConfigContextMenuComponent.module.scss';

const ChargeCodeConfigContextMenuComponent = ({
  hideContextMenu,
  chargeCodeConfigData,
  floorPlanConfigData,
  unitStatusConfigData,
  utilsService
}) => {
  const onChargeConfigDownload = useCallback(() => {
    utilsService.downloadChargeCodeExcel(chargeCodeConfigData, floorPlanConfigData, unitStatusConfigData);
  }, [chargeCodeConfigData, floorPlanConfigData, unitStatusConfigData]);

  return (
    <div>
      <Dropdown.Menu className={styles.chargeCodeDropDownMenu} show>
        <Dropdown.Item onClick={() => hideContextMenu() & onChargeConfigDownload()}>Download</Dropdown.Item>
      </Dropdown.Menu>
    </div>
  );
};

const mapStateToProps = state => ({
  documentType: currentDocumentTypeSelector(state),
  chargeCodeConfigData: chargeCodeConfigSelector(state),
  floorPlanConfigData: currentDocumentFloorPlanSelector(state),
  unitStatusConfigData: currentDocumentUnitStatusSelector(state)
});
export default connect(mapStateToProps)(withServices('utilsService')(ChargeCodeConfigContextMenuComponent));
