import React, { useCallback, useEffect, useMemo } from 'react';
import { withServices } from 'reaf';
import DocumentsService from '../../../services/DocumentsService';
import { FILE_TYPES } from '../../../constants';
import PdfViewer from '../PdfViewer/PdfViewer';
import XLViewer from '../XLViewer/XLViewer';
import { noopFunc } from '../../../lib/utils/noop';
import { DocumentViewerOpened } from '../../../constants/eventTrackerMessage';

function DocumentViewer({
  project,
  document,
  setPdfPageCount,
  setXLSheetNames = noopFunc,
  documentsService,
  small = false,
  viewerHeight,
  sheetHeight = {},
  eventTrackerService
}) {
  const cache = useMemo(() => ({}), []);

  if (!cache[document.uuid]) {
    cache[document.uuid] = documentsService.getDocumentFileUrlObject(
      project,
      document
    );
  }

  const fileObj = cache[document.uuid];

  // resetting sheets name on load
  useEffect(() => {
    setXLSheetNames([]);
  }, []);

  useEffect(() => {
    eventTrackerService.track(DocumentViewerOpened, { document, project });
  }, [document.uuid]);

  const onDataLoad = useCallback(
    ({ sheetNames }) => {
      if (setXLSheetNames) {
        setXLSheetNames(sheetNames);
      }
    },
    [setXLSheetNames]
  );

  const onPdfPageCountSet = useCallback(
    (pageCount) => {
      if (setPdfPageCount) {
        setPdfPageCount(pageCount);
      }
    },
    [setPdfPageCount]
  );

  const documentType = DocumentsService.getDocumentType(document);
  if (documentType === FILE_TYPES.PDF.key) {
    return (
      <PdfViewer
        key={document.uuid}
        fileObj={fileObj}
        onSetPageCount={onPdfPageCountSet}
        small={small}
        viewerHeight={viewerHeight}
      />
    );
  }
  if (documentType === FILE_TYPES.XL.key) {
    return (
      <XLViewer
        key={document.uuid}
        document={document}
        project={project}
        onDataLoad={onDataLoad}
        sheetHeight={sheetHeight}
      />
    );
  }
}

export default withServices(
  'documentsService',
  'eventTrackerService'
)(DocumentViewer);
