import React, { useEffect } from 'react';
import { withServices } from 'reaf';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import AdminLoginForm from '../../app/LoginForm/AdminLoginForm';
import homeLeftBg from '../../../assets/images/clik-home-bg.svg';
import homeRightBg from '../../../assets/images/clik-home-bg2.svg';

function AdminLoginPage({ eventTrackerService }) {
  return (
    <LoggedOutUserPage  noFooter noHeader>
    <div className="flex flex-row">
        <div className="home-bg home-right-bg">
          <span><img src={homeRightBg} /></span>
        </div>
        <AdminLoginForm />
        <div className="home-bg home-left-bg">
          <span>
            <img src={homeLeftBg} />
          </span>
        </div>
      </div>
    </LoggedOutUserPage>
  );
}

export default withServices('eventTrackerService')(AdminLoginPage);
