import React from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import useModalForm from '../../../hooks/useModalForm';
import FormModal from '../FormModal/FormModal';
import DocumentForm from '../DocumentForm/DocumentForm';
import NeedSupportFormModal from '../NeedSupportFormModal/NeedSupportFormModal';

function DocumentPopups({
  document,
  project,
  onHide,
  showRenameForm,
  showDeleteConfirm,
  onDeleteConfirm,
  showClearTaggingConfirm,
  onClearTaggingConfirm,
  showExtractionPendingPopup,
  needSupport
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showRenameForm && (
          <FormModal size="" isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showRenameForm} title="Rename document">
            <DocumentForm 
              bindSubmission={bindSubmission} 
              onSubmitSuccess={onHide} 
              document={document} 
              project={project} 
            />
          </FormModal>
        )
      }

      {
        needSupport &&
        <NeedSupportFormModal project={project} document={document} onHide={onHide} show={needSupport} />
      }

      {
        showDeleteConfirm &&
        (
          <ConfirmationModal
            show={showDeleteConfirm}
            title={`Delete Document ${document.fileName}?`}
            message={`Are you sure you want to delete document ${document.fileName}`}
            onConfirm={onDeleteConfirm}
            onCancel={onHide}
          />
        )
      }

      {
        showClearTaggingConfirm &&
        (
          <ConfirmationModal
            show={showClearTaggingConfirm}
            title={`Clear Tagging for Document ${document.fileName}?`}
            message={`Are you sure you want to clear tagging for the document ${document.fileName}`}
            onConfirm={onClearTaggingConfirm}
            onCancel={onHide}
          />
        )
      }

      {
        showExtractionPendingPopup &&
         (
           <ConfirmationModal
             show={showExtractionPendingPopup}
             title="Extraction in progress"
             message="Document extraction is still in progress. Please wait a while till the extraction completes."
             onConfirm={onHide}
             onCancel={onHide}
             isConfirm={false}
           />
         )
      }

    </div>
  );
}

export default DocumentPopups;
