import React from 'react';
import ProjectReport from '../../app/ProjectReport/ProjectReport';
import AnonymousHeader from '../../app/AnonymousHeader/AnonymousHeader';
import config from '../../../config';

function GlobalReportWrapper({
  companyUuid,
  projectUuid,
  templateUuid,
  modelUuid,
  reportUuid,
  canDownloadDocument,
  canDownloadReportWorkbook,
  project
}) {
  return (
    <div className="page">
      <AnonymousHeader logoUrl={config.calendlyLink} />
      <div className="vertical-section">
        <ProjectReport
          companyUuid={companyUuid}
          projectUuid={projectUuid}
          templateUuid={templateUuid}
          isGlobalReport
          modelUuid={modelUuid}
          reportUuid={reportUuid}
          isOpen
          canDownloadDocument={canDownloadDocument}
          publicProject={project}
          canDownloadReportWorkbook={canDownloadReportWorkbook}
        />
      </div>
    </div>
  );
}

export default GlobalReportWrapper;
