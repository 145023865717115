import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import TemplateCloneFormComponent from './TemplateCloneFormComponent';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';
import messages from '../../../../locales/en-US';
import { blobToFile } from '../../../utils/utils';

const defaultLabel = (
  <span className="text-primary" style={{ cursor: 'pointer' }}>Clone Template</span>
);
const TemplateCloneForm = ({
  label = defaultLabel, template, companies, templateService, templateModelService, toastrService
}) => {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  const [showCloneTemplate, setShowCloneTemplate] = useState(false);

  const TemplateCloneFormValidationSchema = useMemo(() => Yup.object().shape({
    templateName: Yup.string().required(messages.errors.templateName).test(
      'slashes',
      "Please remove slashes",
      (value) => (value || '').indexOf('/') < 0,
    ),
    cloneCompanyUuid: Yup.string().required('Please select company')
  }), []);

  const initialValues = {
    templateName: template.templateName,
    currentCompany: template.Company.name,
    currentCompanyUuid: template.Company.uuid,
    templateTag: template.tag,
    assetTypes: template.assetTypes,
    cloneCompanyUuid: ''
  }

  async function onSubmitForm(values, actions) {
    actions.setStatus({});
    const { templateName, cloneCompanyUuid, currentCompanyUuid } = values;
    const {
      assetTypes,
      keysToMask,
      tag,
      isReportEnabled,
      TemplateModels: templateModels,
      isDictionaryAvailable,
      isCategorySequenceAvailable,
      isAllowedCustomSummarySheet
    } = template;

    const cloneCompanyTemplates = await templateService.loadCompanyTemplatesList(cloneCompanyUuid)
    const isTemplateExist = cloneCompanyTemplates.some(template => template.templateName === templateName) || false;

    if (!isTemplateExist) {
      const templateFile = blobToFile(
        await templateService.getTemplateFile(currentCompanyUuid, template.uuid),
        `${templateName}.json`
      );

      let dictionaryFile = null;
      let categorySequenceFile = null;
      let summarySheetTotalConfig = null;
      if (isDictionaryAvailable) {
        dictionaryFile = blobToFile(await templateService.getDictionaryFile(template.uuid), `${templateName}-dictionary.json`)
      }

      if (isCategorySequenceAvailable) {
        categorySequenceFile = blobToFile(await templateService.getCategorySequenceFile(template.uuid), `${templateName}-category-sequence.json`)
      }

      if (isAllowedCustomSummarySheet) {
        summarySheetTotalConfig = blobToFile(await templateService.getSummaryTotalConfigFile(template.uuid), `${templateName}-summary-total-config.json`)
      }

      try {
        const payload = {
          templateName,
          templateFile,
          tag,
          isReportEnabled,
          assetTypes,
          keysToMask,
          dictionaryFile,
          categorySequenceFile,
          summarySheetTotalConfig
        }
        const uploadedTemplate = await templateService.cloneTemplate(cloneCompanyUuid, payload);
        await uploadModels(currentCompanyUuid, cloneCompanyUuid, template, uploadedTemplate, templateModels)
        setShowCloneTemplate(false);
        toastrService.success(`${uploadedTemplate.templateName} cloned successfully`)
      } catch (error) {
        actions.setStatus({ error: error.message });
      }
    } else {
      actions.setStatus({ error: `Template with ${templateName} name is already exist` });
    }
    actions.setSubmitting(false);
  }

  async function uploadModels(currentCompanyUuid, cloneCompanyUuid, sourceTemplate, targetTemplate, templateModels) {
    const models = templateModels.map(async (model) => {
      let modelFile = null;
      let reportTemplateFile = null;
      let file = null

      const modelFileName = model.modelFilePath
      const reportFileName = `${model.modelName}-${model.assetType}-report`

      modelFile = await templateModelService.getModelFileFromAdmin(currentCompanyUuid, sourceTemplate.uuid, model.uuid, modelFileName) || null
      reportTemplateFile = await templateModelService.getReportFileFromAdmin(currentCompanyUuid, sourceTemplate.uuid, model.uuid, reportFileName) || null

      const payload = {
        modelFile,
        reportTemplateFile,
        assetType: model.assetType,
        modelName: model.modelName,
        file,
        allowConsolidatedModel: model.allowConsolidatedModel,
      }

      await templateModelService.uploadCloneTemplateModel(cloneCompanyUuid, targetTemplate.uuid, payload)
    })
    await Promise.all(models);
  }


  return (
    <>
      <div onClick={() => setShowCloneTemplate(true)}>
        {label}
      </div>
      <FormModal
        show={showCloneTemplate}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        onHide={() => setShowCloneTemplate(false)}
        title="Clone Template" size="md"
        submitButtonText="Clone Template"
        className="modal-sm">

        <Formik
          initialValues={initialValues}
          validationSchema={TemplateCloneFormValidationSchema}
          onSubmit={onSubmitForm}
          component={formikProps => (
            <TemplateCloneFormComponent
              {...formikProps}
              bindSubmission={bindSubmission}
              showTemplateCloneModel={showCloneTemplate}
              template={template}
              companies={companies}
              onHide={() => setShowCloneTemplate(false)}
            />
          )
          }
        />
      </FormModal>
    </>
  );
};

TemplateCloneForm.propTypes = {
  label: PropTypes.node,
  template: PropTypes.object.isRequired
};

export default TemplateCloneForm;
