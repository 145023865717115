import React, { useCallback, useState } from 'react';
import { withServices } from 'reaf';
import ProspectPopups from '../ProspectPopups/ProspectPopups';
import messages from '../../../../locales';

const defaultLabel = (
  <span className="text-primary" style={{ cursor: 'pointer' }}>Cancel Invoice</span>
);
const CancelProspectInvoice = ({
  label = defaultLabel, prospect, invoice, prospectsService, toastrService
}) => {
  const [showVoidInvoiceConfirm, setShowVoidInvoiceConfirm] = useState(false);

  const onVoidConfirm = useCallback(async () => {
    try {
      await prospectsService.voidInvoice(prospect, invoice);
      toastrService.success(messages.toastMessage.CANCEL_INVOICE);
      setShowVoidInvoiceConfirm(false);
    } catch (e) {
      toastrService.error(e.message);
    }
  }, [prospect, invoice, prospectsService]);

  const onVoid = useCallback(() => {
    setShowVoidInvoiceConfirm(true);
  }, []);

  return (
    <>
      <div onClick={() => onVoid()}>
        {label}
      </div>
      <ProspectPopups
        prospect={prospect} invoice={invoice} showVoidInvoiceConfirm={showVoidInvoiceConfirm}
        onVoidConfirm={() => onVoidConfirm()} onVoidCancel={() => setShowVoidInvoiceConfirm(false)} />
    </>
  );
};

export default (withServices('prospectsService', 'toastrService')(CancelProspectInvoice));
