import React from 'react';
import FormModal from '../FormModal/FormModal';
import DynamicAssetTypesForm from '../DynamicAssetTypesForm/DynamicAssetTypesForm';
import useModalForm from '../../../hooks/useModalForm';

function DynamicAssetTypesPopups({
  dynamicAssetType, onHide, showCreateDynamicAssetType, showEditDynamicAssetType
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showCreateDynamicAssetType &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showCreateDynamicAssetType} title="Add Dynamic Asset Type">
            <DynamicAssetTypesForm
              bindSubmission={bindSubmission}
              onSubmitSuccess={onHide}
              dynamicAssetType={dynamicAssetType}
              showCreateDynamicAssetType
            />
          </FormModal>
        )
      },
      {
        showEditDynamicAssetType &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showEditDynamicAssetType} title="Edit Dynamic Asset Type">
            <DynamicAssetTypesForm
              bindSubmission={bindSubmission}
              onSubmitSuccess={onHide}
              dynamicAssetType={dynamicAssetType}
            />
          </FormModal>
        )
      }
    </div>
  );
}

export default DynamicAssetTypesPopups;