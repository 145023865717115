import React, { useCallback, useState } from "react";
import cryptoRandomString from 'crypto-random-string';
import { Form, Field, Formik } from 'formik';
import { withServices } from 'reaf';
import CreatableSelect from 'react-select/creatable';
import { Button } from 'react-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { isEmpty, isString } from 'lodash';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import SwitchButton from '../../core/SwitchButton/SwitchButton';
import IconButton from '../../core/Button/IconButton';
import { companyApiIntegrationTypes, companyApiIntegrationApiMethods } from '../../../constants';
import PasswordViewer from "../PasswordViewer/PasswordViewer";
import FileUploader from '../FileUploader/FileUploader';
import { VALID_INTEGRATION_CONFIG_FILE_EXTENSION } from '../../../constants';
import messages from '../../../../locales/en-US';

const ToggleButton = ({ isActive, title, fieldName, setFieldValue }) => (
  <div className="d-flex">
    <label>{title}</label>
    <div>
      <SwitchButton
        isActive={isActive}
        onChange={(value) => setFieldValue(fieldName, value)}
      />
    </div>
  </div>
);

const GenerateAPIKeys = ({ keyIndex, defaultApiKeys, totalApiCount, apiKey, onGenerateAPIKey, onDeleteAPIKey, onCopyAPIKey }) => {
 
  const isExistingApiKey = React.useMemo(() => {
    let isExistingApi = false
    if (!!apiKey && apiKey.hasOwnProperty('key')) {
      const { key } = apiKey;
      isExistingApi = defaultApiKeys.some(apiConfig => apiConfig.key === key)
    } 
    return isExistingApi;
  }, [apiKey])

  const isValidApiKeyForCopy = React.useMemo(() => {
    let isValidApiKey = false
    if (!!apiKey && apiKey.hasOwnProperty('key')) {
      const { key, hint } = apiKey;
      isValidApiKey = (key.substring(0,7) === hint.substring(0,7))
    } 
    return isValidApiKey;
  }, [apiKey]);

  return (
    <div className="form-group">
      <label htmlFor="apiConfigDetails.config.apiKey">API Key {keyIndex + 1}</label>
      <div className="d-flex justify-content-between">
        <div className={`w-${!isExistingApiKey ? '75' : '100'}`}>
          <p className="form-control w-100 text-muted">
           {apiKey?.hint}
          </p>
        </div>
        <div className='d-flex'>
          {
            !isExistingApiKey && (
              <>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="text-center btn-sm apiConfigurationGenerateKeyButton mr-2"
                  onClick={() => onGenerateAPIKey(keyIndex)}
                >
                  Generate API Key
                </Button>
              </>
            )
          }

          {
            isValidApiKeyForCopy && (
              <IconButton
              defaultClass="btn-xs mr-2"
              className="smallIconButton btn btn-outline-info btn-sm ml-0"
              icon={faCopy}
              title="Copy API Key"
              onClick={() => onCopyAPIKey(keyIndex)}
            />
            )
          }
          {
            totalApiCount > 1 && (
              <IconButton
                defaultClass="btn-xs"
                className={`smallIconButton btn btn-outline-danger btn-sm ${isExistingApiKey ? 'ml-2' : 'ml-0'}`}
                icon={faTrash}
                title="Delete API Key"
                onClick={() => onDeleteAPIKey(keyIndex)}
              />
            )
          }
          
        </div>
      </div>
    </div>
  )
}

const APIIntegrationForm = function ({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue, configTypes, onIntegrationConfigFileChange, onRemoveIntegrationConfigFile, selectedConfigFile,
  onDelete, apiConfigDetails, formProps,  showDeleteConfigConfirmation, onDeleteCancel, onDeleteConfirm, onConfigDownload, setApiConfigDetails, apiMethods, 
  defaultApiKeys, apiKeysConfig, setApiKeysConfig, ...form
}) {
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [validEmailEnterd, setValidEmailEnterd] = useState(true);

  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const handleEmailChange = (selectedOptions) => {
    const isValid = selectedOptions.every((option) => isValidEmail(option.value));
    if (isValid) {
      setValidEmailEnterd(true);
      const emails = selectedOptions.map((option) => option.value);
      formProps.setFieldValue('apiConfigDetails.emails', emails);
      setSelectedEmails(selectedOptions);
    } else {
      setValidEmailEnterd(false);
    }
  };

  const onGenerateAPIKey = useCallback((keyIndex) => {
    const key = cryptoRandomString({length: 32});
    const hint = `${key.substring(0,7)}${Array(25).fill('X').join('')}`;
    apiKeysConfig[keyIndex] = {key, hint};
    formProps.setFieldValue(`apiConfigDetails.apiKeys`, apiKeysConfig);
  }, [apiKeysConfig]);

  const onAddAPIKey = useCallback(() => {
    if (apiKeysConfig.length < 5) {
      setApiKeysConfig([...apiKeysConfig, '']);
    } 
  }, [apiKeysConfig])

  const onDeleteAPIKey = useCallback((keyIndex) => {
    if (apiKeysConfig.length > 1) {
     const updatedApiKeys = apiKeysConfig.filter((_, idx) => idx !== keyIndex);
     setApiKeysConfig(updatedApiKeys)
    }
  }, [apiKeysConfig]);

  const onCopyAPIKey = useCallback((keyIndex) => {
    const apiKey = apiKeysConfig[keyIndex];
    if (apiKey) {
      navigator.clipboard.writeText(apiKey.key);
    }
  }, [apiKeysConfig])

  bindSubmission(submitForm, isSubmitting);
  return (
    <div>
      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <ToggleButton
            {...{
              setFieldValue,
              title: 'Enable/Disable Api Push Button',
              isActive: values.apiConfigDetails.isConfigEnabled,
              fieldName: 'apiConfigDetails.isConfigEnabled'
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="apiConfigDetails.name">Name : </label>
          <Field
            id="apiConfigDetails.name"
            className="form-control"
            name="apiConfigDetails.name"
            type="text"
          />
          <FieldErrorMessage name="apiConfigDetails.name" />
        </div>
        <div className="form-group">
          <label htmlFor="apiConfigDetails.config.apiMethod">API Method : </label>
          <Field
            id="apiConfigDetails.config.apiMethod"
            className="form-control"
            name="apiConfigDetails.config.apiMethod"
            component="select"
          >
            {Reflect.ownKeys(apiMethods).map(type => (
              <option key={apiMethods[type]} value={apiMethods[type]}>{apiMethods[type]}</option>
            ))}
          </Field>
        </div>
        <div className="form-group">
          <label htmlFor="apiConfigDetails.config.url">URL : </label>
          <Field
            id="apiConfigDetails.config.url"
            className="form-control"
            name="apiConfigDetails.config.url"
            type="url"
          />
          <FieldErrorMessage name="apiConfigDetails.config.url" />
        </div>
        <div className="form-group">
          <label htmlFor="apiConfigDetails.typeOfConfig">Type of Config</label>
          <Field
            id="apiConfigDetails.typeOfConfig"
            className="form-control"
            name="apiConfigDetails.typeOfConfig"
            component="select"
          >
            {Reflect.ownKeys(configTypes).map(config => (
              <option key={configTypes[config]} value={configTypes[config]}>{configTypes[config]}</option>
            ))}
          </Field>
        </div>
        {values.apiConfigDetails.typeOfConfig === companyApiIntegrationTypes.BASIC_AUTH &&
          <>
            <div className="form-group">
              <label htmlFor="apiConfigDetails.config.username">Username : </label>
              <Field
                id="apiConfigDetails.config.username"
                className="form-control"
                name="apiConfigDetails.config.username"
                type="text"
              />
              <FieldErrorMessage name="apiConfigDetails.config.username" />
            </div>
            <div className="form-group passwordGroupRow" controlId="password">
              <label htmlFor="apiConfigDetails.config.password">Password : </label>
              <PasswordViewer autoComplete="new-password" id="apiConfigDetails.config.password" name="apiConfigDetails.config.password" placeholder="Enter your password" />
              <FieldErrorMessage name="apiConfigDetails.config.password" />
            </div>
          </>
        }
        {values.apiConfigDetails.typeOfConfig === companyApiIntegrationTypes.BEARER_TOKEN &&
          <div className="form-group">
            <label htmlFor="apiConfigDetails.config.bearerToken">Token : </label>
            <Field
              id="apiConfigDetails.config.bearerToken"
              className="form-control"
              name="apiConfigDetails.config.bearerToken"
              type="text"
            />
            <FieldErrorMessage name="apiConfigDetails.config.bearerToken" />
          </div>
        }
        {values.apiConfigDetails.typeOfConfig === companyApiIntegrationTypes.API_KEY &&
          <div className="form-group">
            <label htmlFor="apiConfigDetails.config.apiKey">Api Key For Push: </label>
            <Field
              id="apiConfigDetails.config.apiKey"
              className="form-control"
              name="apiConfigDetails.config.apiKey"
              type="text"
            />
            <FieldErrorMessage name="apiConfigDetails.config.apiKey" />
          </div>
        }
        <div className="form-group">
          <label htmlFor="apiConfigDetails.integrationConfigFile" className="mb-2">
            {!isEmpty(apiConfigDetails?.originalConfigFileName) ? 'Update' : 'Upload'} Integration Form Config
          </label>
          <div className="mb-3">
            {isEmpty(apiConfigDetails?.originalConfigFileName) && <FileUploader
              onFileChange={onIntegrationConfigFileChange}
              validExtensions={VALID_INTEGRATION_CONFIG_FILE_EXTENSION}
              onRemoveFile={onRemoveIntegrationConfigFile}
              title="Config"
              selectedFile={selectedConfigFile}
              fileName={apiConfigDetails?.configFileName}
            />}
            {!isEmpty(apiConfigDetails?.originalConfigFileName) && (
              <>
                <div className="d-flex justify-content-between rounded-lg border p-0 mb-2" >
                  <span className="mx-3 ">{apiConfigDetails?.originalConfigFileName} </span>
                  <span>
                    <IconButton
                      defaultClass="btn-xs mr-2 ml-2"
                      className="smallIconButton"
                      icon={faDownload}
                      title="Download config"
                      onClick={() => onConfigDownload()}
                    />
                    <IconButton
                      variant="outline-danger"
                      defaultClass="btn-xs mr-0"
                      className="smallIconButton"
                      icon={faTrash}
                      title="Delete config"
                      onClick={() => onDelete()}
                    />
                  </span>
                </div>
                {
                  showDeleteConfigConfirmation &&
                  (
                    <div style={{ marginBottom: '8px' }}>
                      <span style={{ marginRight: '10px', marginLeft: '8px', color: '#dc3545', fontSize: '16px' }}>Are you sure you want to delete the config?</span>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        style={{ padding: '0px' }}
                        className="smallIconButton text-center"
                        onClick={() => onDeleteCancel()}
                      >
                        No
                      </Button>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="smallIconButton text-center"
                        style={{ padding: '0px', marginLeft: '8px' }}
                        onClick={() => onDeleteConfirm()}
                      >
                        Yes
                      </Button>
                    </div>
                  )
                }
                <FileUploader
                  onFileChange={onIntegrationConfigFileChange}
                  validExtensions={VALID_INTEGRATION_CONFIG_FILE_EXTENSION}
                  onRemoveFile={onRemoveIntegrationConfigFile}
                  buttonTitlePrefix="Update"
                  title="Config"
                  selectedFile={selectedConfigFile}
                  fileName={apiConfigDetails?.configFileName}
                />
              </>
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="apiConfigDetails.emails">Emails : </label>
          <CreatableSelect
            isMulti
            value={values.apiConfigDetails.emails.map((email) => ({
              value: email,
              label: email,
            }))}
            onChange={(values) => {
              handleEmailChange(values)
            }
            }
            options={[]}
            placeholder="Enter Emails"
            required={true}
          />
          {(isEmpty(values.apiConfigDetails?.emails) || (!validEmailEnterd)) && <div className="text-danger">
            <small>Please Enter a valid Email</small>
          </div>}
          {/* <FieldErrorMessage name="emails" /> */}
        </div>
        <div className="form-group">
          <label htmlFor="apiConfigDetails.emailSubject">Email Subject : </label>
          <Field
            id="apiConfigDetails.emailSubject"
            className="form-control"
            name="apiConfigDetails.emailSubject"
            type="text"
          />
          <FieldErrorMessage name="apiConfigDetails.emailSubject" />
        </div>
        <hr/>
        <div className='form-group'>
          <div className="d-flex align-items-center">
          <h6>Api Keys for Data Pull</h6>
          <IconButton
              title="Add Api Key" className="ml-3 btn-circle btn-shadow"
              variant="outline-secondary" size="sm" icon={faPlus}
              onClick={e => onAddAPIKey()}
              id="addAPIKey"
            />
          </div>
        </div>
        {
          Array(apiKeysConfig.length).fill(0).map((apiKey, idx) => {
            return (
              <GenerateAPIKeys
                key={`${apiKey}_${idx}`}
                keyIndex={idx}
                apiKey={apiKeysConfig[idx]}
                defaultApiKeys={defaultApiKeys}
                totalApiCount={apiKeysConfig.length}
                fieldName={`apiConfigDetails.apiKeys[${idx}].hint`}
                onGenerateAPIKey={onGenerateAPIKey}
                onDeleteAPIKey={onDeleteAPIKey}
                onCopyAPIKey={onCopyAPIKey}
              />
            )
          })
        }
      </Form>
    </div>
  );
};

function ApiIntegratonForm({
  onSubmitSuccess,
  bindSubmission,
  companyDetails,
  companyService,
  apiConfigDetails,
  companyUuid,
  toastrService,
  interationConfigFileName,
  setApiConfigDetails
}) {
  const [integrationConfigFile, setIntegrationConfigFile] = useState(null);
  const [showDeleteConfigConfirmation, setShowDeleteConfigConfirmation] = useState(false);

  const defaultApiKeys = React.useMemo(() => {
    return apiConfigDetails?.apiKeys?.length > 0 ? 
           apiConfigDetails.apiKeys.map(key => isString(key) ? JSON.parse(key) : key) : [''];
  }, [apiConfigDetails?.apiKeys]);

  const [apiKeysConfig, setApiKeysConfig] = useState(defaultApiKeys);

  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      companyService.updateApiConfiguration({ ...values.apiConfigDetails, apiKeys: apiKeysConfig, integrationConfigFile, companyUuid });
      onSubmitSuccess();
      const sucessMessage = messages.toastMessage.API_INTEGRATION_CONFIG_UPDATE_SUCCESS;
      toastrService.success(sucessMessage);
    } catch (error) {
      const errorMessage = messages.toastMessage.API_INTEGRATION_CONFIG_UPDATE_ERROR;
      toastrService.error(errorMessage);
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  function onDelete() {
    setShowDeleteConfigConfirmation(true);
  }

  function onDeleteCancel() {
    setShowDeleteConfigConfirmation(false)
  }

  async function onConfigDownload() {
    try {
      await companyService.downloadCompanyApiConfig(companyUuid);
    } catch (error) {
      toastrService.error(messages.toastMessage.API_CONFIG_DOWNLOAD_FAIL);
    }
  }

  const onDeleteConfirm = useCallback(async () => {
    try {
      const response = await companyService.updateConfigOfApiConfiguration(companyUuid);
      setApiConfigDetails({
        ...apiConfigDetails,
        originalConfigFileName: null
      })
      setShowDeleteConfigConfirmation(false)
    } catch (error) {
      toastrService.error(messages.toastMessage.API_CONFIG_DELETE_FAIL);
    }
  }, [setApiConfigDetails, companyUuid, apiConfigDetails])

  const {
    config: { username, password, url, bearerToken, apiKey, apiMethod } = {},
    name,
    isConfigEnabled,
    typeOfConfig,
    emails,
    emailSubject,
  } = apiConfigDetails || {};

  return (
    <Formik
      initialValues={{
        apiConfigDetails: {
          ...apiConfigDetails,
          config: {
            username: username || '',
            password: password || '',
            url: url || '',
            bearerToken: bearerToken || '',
            apiKey: apiKey || [],
            apiMethod: apiMethod || 'POST'
          },
          name: name || '',
          isConfigEnabled: isConfigEnabled || false,
          typeOfConfig: typeOfConfig || '',
          emails: emails || [],
          configFileName: interationConfigFileName || '',
          integrationConfigFile: integrationConfigFile || null,
          emailSubject: emailSubject || '',
          apiKeys: defaultApiKeys,
        }
      }}
      onSubmit={onSubmit}
      component={formikProps =>
        <APIIntegrationForm {...formikProps}
          formProps={formikProps}
          onIntegrationConfigFileChange={e => setIntegrationConfigFile(e.target.files[0])}
          onRemoveIntegrationConfigFile={() => setIntegrationConfigFile(null)}
          bindSubmission={bindSubmission}
          selectedConfigFile={integrationConfigFile}
          configTypes={companyApiIntegrationTypes}
          apiMethods={companyApiIntegrationApiMethods}
          onDelete={onDelete}
          apiConfigDetails={apiConfigDetails}
          showDeleteConfigConfirmation={showDeleteConfigConfirmation}
          onDeleteCancel={onDeleteCancel}
          onDeleteConfirm={onDeleteConfirm}
          onConfigDownload={onConfigDownload}
          setApiConfigDetails={setApiConfigDetails} 
          apiKeysConfig={apiKeysConfig}
          defaultApiKeys={defaultApiKeys}
          setApiKeysConfig={setApiKeysConfig}
          />
       }
    />
  );
}

export default withServices('companyService', 'toastrService')(ApiIntegratonForm);