import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { isNil } from 'lodash';

function DocExtractionRetailOccupancyOptions({
  occupancyConfigData, occupancyStatus, staticOccupancyStatus, setCurrentChargeOccupancyStatus, onMenuOpen, onMenuClose
}) {
  const [currentOccupancyCode, setCurrentOccupancyCode] = useState({});

  useEffect(() => {
    const { category } = occupancyConfigData[occupancyStatus];
    const occupancyStatusConfig = !isNil(category) ? category : '';
    setCurrentOccupancyCode({ value: occupancyStatusConfig, label: occupancyStatusConfig });
  }, [occupancyConfigData]);

  return (
    <>
      <ReactSelect
          placeholder="Select Occupancy"
          classNamePrefix="react-select chargeCodeSelect"
          value={currentOccupancyCode}
          maxMenuHeight={200}
          menuPosition="fixed"
          menuPlacement="auto"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={staticOccupancyStatus.map(category => ({ value: category, label: category }))}
          onChange={
            ({ value, label }) => {
              const { category, ...configData } = occupancyConfigData[occupancyStatus];
              setCurrentOccupancyCode({ value, label });
              setCurrentChargeOccupancyStatus({ ...configData, category: value });
            }
          }
        />
    </>
  );
}

export default DocExtractionRetailOccupancyOptions;
