import BaseEvent from './BaseEvent';
import { sheetIndex } from '../../../../constants';

export default class ClipboardPastingEvent extends BaseEvent {
  handle(datasheet) {
    const { spreadsheet } = datasheet;
    const { targetSheetIndex } = sheetIndex;
    const activeSheetIndex = spreadsheet.getActiveSheetIndex();
    const pastedRange = spreadsheet.getSelections(activeSheetIndex)[0];
    if (pastedRange && 
      (activeSheetIndex === targetSheetIndex || activeSheetIndex === spreadsheet.getWorkingSheetIndex())
    ) {
      const { row, col } = pastedRange;
      const defaultHeaderCellStyle = spreadsheet.getCellStyle(targetSheetIndex, 0, 1);
      spreadsheet.suspend();
      if (row < 0) {
        for (let i = 0; i < datasheet.clipboardData.copiedSourceColumnRange.colCount; i++) {
          const columnIndex = col + i;
          const columnValue = spreadsheet.getCellValue(activeSheetIndex, 0, columnIndex);
          const columnStyle = spreadsheet.getCellStyle(activeSheetIndex, 0, columnIndex);
          const headerCellStyle = columnStyle || defaultHeaderCellStyle;
          datasheet.clipboardData.copiedHeaderColumnValues.push({ columnValue, columnStyle: headerCellStyle });
        }
      }
      spreadsheet.resume();
    }
  }
}
