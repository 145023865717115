import React, { useState, useCallback, useMemo } from 'react';
import RentRollConfigHeader from '../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionAffixLeaseChartFilter from './DocExtractionAffixLeaseChartFilter';
import DocExtractionWithoutSummary from '../DocExtractionSummary/DocExtractionWithoutSummary';
import DocExtractionAffixBarChart from '../DocExtractionAffixChart/DocExtractionAffixBarChart';
import { findMaximumLengthOfArrayInObj } from '../../../utils/utils';
import style from './DocExtractionAffixLeaseChart.module.scss';
import './DocExtractionAffixLeaseChart.scss';


function DocExtractionAffixLeaseChart({ title, defaultChartColor, tanentInfo }) {
  const selectedYear = useMemo(() => findMaximumLengthOfArrayInObj(tanentInfo));
  const [chartData, setChartData] = useState(tanentInfo[selectedYear]);

  const onFilterChange = useCallback((selectedYear) => {
    setChartData(tanentInfo[selectedYear]);
  }, [chartData]);

  return (
    <>

      {
        Reflect.ownKeys(tanentInfo).length > 0 ? (
          <>
            <div className={style.leaseWrapper}>
              <div className={`${style.affixLeaseChartHeader}`}>
                <div className="row align-items-center">
                  <div className="col-9"><RentRollConfigHeader title={title} hideChartSwitcher /></div>
                  <div className="col-3">
                    <DocExtractionAffixLeaseChartFilter
                      yearRange={Reflect.ownKeys(tanentInfo)}
                      selectedYear={selectedYear}
                      onFilterChange={(leaseChartType, selectedYear) => onFilterChange(leaseChartType, selectedYear)} />
                  </div>
                </div>
              </div>
              <div className="analysisAffixChart">
                <DocExtractionAffixBarChart
                  data={chartData}
                  defaultChartColor={defaultChartColor}
                  width={460}
                  height={280}
                  yAxisLabelName="Lease Count"
                  xAxisKeyName="name" />
              </div>
            </div>
          </>
        ) : <DocExtractionWithoutSummary message="No Data available" />
      }
    </>
  );
}

export default DocExtractionAffixLeaseChart;
