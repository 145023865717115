import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import {
  Row, Col, Button
} from 'react-bootstrap';
import DocExtractionAffixTableWrapper from '../../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import { setRetailLeaseTypeConfig } from '../../../../store/currentDocument';
import { readOnlyAccessSelector, retailLeaseTypeConfigSelector, retailLeaseTypeDropDownSelector } from '../../../../store/selectors';
import LeaseTypeOptions from './LeaseTypeOptions';
import RentRollConfigHeader from '../../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../../DocExtractionSummary/DocExtractionWithoutSummary';
import style from './DocExtractionRetailLeaseType.module.scss';
import messages from '../../../../../locales/en-US';
import FormSubmitButton from '../../../core/Button/FormSubmitButton';


function DocExtractionRetailLeaseType({
  leaseTypeConfigData, staticLeaseType, store, onConfigSubmit, onSlidePaneClose, readOnlyAccess
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(async (...args) => {
    const [formValue] = args;
    setIsSubmitting(true);
    store.dispatch(setRetailLeaseTypeConfig(formValue));
    await onConfigSubmit();
    setIsSubmitting(false);
  }, [onConfigSubmit, store]);

  return (
    <>
      {
        Reflect.ownKeys(leaseTypeConfigData).length > 0 ? (
          <>
            <RentRollConfigHeader title="Configure Lease Type" hideChartSwitcher />

            <Formik
              enableReinitialize
              key="leaseTypeConfig"
              onSubmit={onSubmit}
              initialValues={leaseTypeConfigData}
              render={({
                setFieldValue,
                isValid
              }) => (
                <Form>
                  <DocExtractionAffixTableWrapper>
                    <>
                        <Row className={style.leaseTypeHeader}>
                          <Col className={`col-md-auto ${style.serialNumber}`}>#</Col>
                          <Col className={`col-md-auto ${style.leaseType}`}>Lease Type</Col>
                          <Col className={`col-md-auto ${style.category}`}>Category</Col>
                        </Row>
                        <Row className="position-relative">
                          <Col className={`${style.leaseTypeConfig} ${isMenuOpen ? `overflow-hidden` : 'overflow-auto'}`}>

                            {
                              Reflect.ownKeys(leaseTypeConfigData).map((leaseType, rowIndex) => (
                                <ul key={leaseType}>
                                  <li className={`col-md-auto ${style.serialNumber}`}>
                                   <div title={rowIndex + 1} className={style.documentStatusHeader}><span className="text-with-ellipsis w-100 d-inline-block">{rowIndex + 1}</span></div>
                                  </li>
                                  <li className={`col-md-auto ${style.leaseType}`}>
                                    <div title={leaseType} className={style.leaseType}>
                                      <span className="text-with-ellipsis w-100 d-inline-block">
                                        {leaseType}
                                      </span>
                                    </div>
                                  </li>
                                  <li className={style.category}>
                                    <LeaseTypeOptions
                                      leaseTypeConfigData={leaseTypeConfigData}
                                      leaseType={leaseType}
                                      staticLeaseType={staticLeaseType}
                                      setCurrentLeaseTypeCategory={(category) => {
                                        setFieldValue(leaseType, category.value);
                                      }}
                                      onMenuClose={() => setIsMenuOpen(false)}
                                      onMenuOpen={() => setIsMenuOpen(true)}
                                    />
                                  </li>
                                </ul>
                              ))
                            }
                          </Col>
                        </Row>
                      </>

                  </DocExtractionAffixTableWrapper>
                  <Row className={`${style.leaseTypeFooter} ${style.footerFixedBottom}`}>
                    <Button variant="outline-secondary btn-sm" onClick={onSlidePaneClose}>Cancel</Button>
                    <FormSubmitButton
                      isValid={isValid && !readOnlyAccess}
                      isSubmitting={isSubmitting}
                      id="leaseTypeSubmit"
                    />
                  </Row>
                </Form>
              )}
            />
          </>
        ) : <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
      }


    </>
  );
}

const mapStateToProps = state => ({
  leaseTypeConfigData: retailLeaseTypeConfigSelector(state),
  staticLeaseType: retailLeaseTypeDropDownSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state)
});

export default connect(mapStateToProps)(withServices('store')(DocExtractionRetailLeaseType));
