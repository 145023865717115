import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import style from './TutorialVideo.module.scss';
import VimeoPlayer from '../VimeoPlayer/VimeoPlayer';
import {
  getTimestampToFormatedMinuteString,
  getTimestampToFormatedSecondString,
} from '../../../utils/utils';

const TutorialVideo = ({ video }) => (
  <Col>
    <Card className={style.playerCard}>
      <div className={style.videoWrapper}>
        {video && <VimeoPlayer video={video} />}
      </div>

      <Card.Body>
        <Row>
          <Col>
            <div className={`${style.videoTitle} card-title`}>
              {video?.title}
            </div>
            <p className={style.videoDescription}>{video?.description}</p>
          </Col>
          <Col lg={3} md={3}>
            <div className={`${style.videoTitle} card-title`}>Duration</div>
            <p>
              <span className={style.durationNumber}>
                {getTimestampToFormatedMinuteString(video?.duration || '00')}
              </span>
              <span className={style.durationText}> Mins </span>
              <span className={style.durationNumber}>
                {getTimestampToFormatedSecondString(video?.duration || '00')}
              </span>
              <span className={style.durationText}> Secs </span>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
);

export default TutorialVideo;
