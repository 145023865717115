import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';

function UnitStatusOptions({
  unitStatusConfigData, unitStatus, staticUnitStatus, setCurrentUnitStatusCategory, onMenuOpen, onMenuClose
}) {
  const [currentUnitStatus, setCurrentUnitStatus] = useState({});
  useEffect(() => {
    setCurrentUnitStatus({
      value: unitStatusConfigData[unitStatus].category, 
      label: unitStatusConfigData[unitStatus].category
    });
  }, [unitStatusConfigData, unitStatus]);

  return (
    <>
      <ReactSelect
          placeholder="Select Unit Status"
          classNamePrefix="react-select chargeCodeSelect"
          value={currentUnitStatus}
          menuPosition="fixed"
          menuPlacement="auto"
          options={staticUnitStatus.map(unitStatus => ({ value: unitStatus, label: unitStatus }))}
          onChange={
            (selectedUnitStatus) => {
              const { marketRentTotal, monthlyRentTotal, count } = unitStatusConfigData[unitStatus];
              setCurrentUnitStatus({
                ...selectedUnitStatus, category: selectedUnitStatus.value, marketRentTotal, monthlyRentTotal, count
              });
              setCurrentUnitStatusCategory({
                category: selectedUnitStatus.value, marketRentTotal, monthlyRentTotal, count
              });
            }
          }
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
        />
    </>
  );
}

export default UnitStatusOptions;
