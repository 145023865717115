import Service from './Service';

class SsoConfigurationService extends Service {
  constructor(apiClient, store) {
    super(apiClient, store);
    this.apiClient = apiClient;
    this.store = store;
  }

  async fetchCompanySsoconfiguration({ uuid: companyUuid }) {
    const result = await this.apiClient.get(`/companies/${companyUuid}/ssoConfiguration`);
    return result.response.ssoConfiguration;
  }

  async createOrUpdateSsoConfiguration({ uuid: companyUuid }, ssoConfig) {
    const result = await this.apiClient.post(`/companies/${companyUuid}/ssoConfiguration/createOrUpdate`, ssoConfig);
    return result.response.ssoConfiguration;
  }
}

export default SsoConfigurationService;
