import React from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormModal from '../FormModal/FormModal';
import TemplateModelForm from '../TemplateModelForm/TemplateModelForm';
import useModalForm from '../../../hooks/useModalForm';
import UpdateTemplateModelForm from '../UpdateTemplateModelForm/UpdateTemplateModelForm';

function TemplateModelPopups({
  model, onHide, showUploadModel, showDeleteConfirm, onDeleteConfirm, companyUUID, templateUUID, showUpdateModel, showModalInformation
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
  function ModelInformationTable({ model }) {
    return (
      <>
        <table style={{ border: '1px solid #ddd', borderCollapse: 'collapse', width: '100%', marginTop: '10px', marginBottom: '20px' }}>
          <caption style={{ captionSide: 'top', textAlign: 'left', fontWeight: 'bold', marginBottom: '8px' }}>Model Information</caption>
          <tbody>
            <tr>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}><strong>Model Name</strong></td>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>{model?.modelName}</td>
            </tr>
            <tr>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}><strong>File Path</strong></td>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>{model?.modelFilePath}</td>
            </tr>
            <tr>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}><strong>Updated At</strong></td>
              <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>{model?.updatedAt}</td>
            </tr>
          </tbody>
        </table>
        {model.hasReportTemplateFile && (
          <table style={{ border: '1px solid #ddd', borderCollapse: 'collapse', width: '100%' }}>
            <caption style={{ captionSide: 'top', textAlign: 'left', fontWeight: 'bold', marginBottom: '8px' }}>Report Information</caption>
            <tbody>
              <tr>
                <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}><strong>File Path</strong></td>
                <td style={{ padding: '8px', textAlign: 'left', border: '1px solid #ddd' }}>{model.reportFilePath}</td>
              </tr>
            </tbody>
          </table>)}
      </>
    );
  }


  return (
    <div>
      {showUploadModel && (
        <FormModal
          onHide={onHide}
          show={showUploadModel}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          title="Upload Model"
        >
          <TemplateModelForm
            initialValues={model}
            bindSubmission={bindSubmission}
            onSubmitSuccess={onHide}
            companyUUID={companyUUID}
            templateUUID={templateUUID}
          />
        </FormModal>
      )}
      {showUpdateModel && (
        <FormModal
          onHide={onHide}
          show={showUpdateModel}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          title="Update Model"
        >
          <UpdateTemplateModelForm
            initialValues={model}
            bindSubmission={bindSubmission}
            onSubmitSuccess={onHide}
            companyUUID={companyUUID}
            templateUUID={templateUUID}
          />
        </FormModal>
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          show={showDeleteConfirm}
          title={`Delete Model ${model.modelName}?`}
          message={`Are you sure you want to delete the Model ${model.modelName}`}
          onConfirm={onDeleteConfirm}
          onCancel={onHide}
        />
      )}
      {showModalInformation && (
        <ConfirmationModal
          show={showModalInformation}
          title={model.hasReportTemplateFile ? 'Model and Report Information' : 'Model Information'}
          size={'lg'}
          message={(
            <ModelInformationTable model={model} />
          )}
          showConfirmButton={false}
          onCancel={onHide}
        />
      )}

    </div>
  );
}

export default TemplateModelPopups;
