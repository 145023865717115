import React from 'react';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import CompanyTemplateList from '../../../components/app/CompanyTemplateList/CompanyTemplateList';
import { Container, Row, Col } from 'react-bootstrap';

function AddUpdateCompanyTemplateList() {
	return (
	<LoggedInUserPage noFooter>
		<Container fluid className="m-0 vertical-section">
			<Row className="vertical-section">
				<Col className="vertical-section p-0">
					<div className="fill-parent overflow-auto p-3">
							<CompanyTemplateList />
					</div>
				</Col>
			</Row>
		</Container>
	</LoggedInUserPage>
	)
}

export default AddUpdateCompanyTemplateList;