import React from 'react';
import { Table } from 'react-bootstrap';
import { Field } from 'formik';
import { displayNumberInUSFormat } from '../../../utils/utils';
import style from './DocExtractionFloorPlanConfig.module.scss';


const AffordableConfigView = ({
  isActive, floorPlanConfigData, getAverageTotals
}) => {

  if (!isActive) {
    return null;
  }

  return (
    <div className={style.floorPlan}>
      <Table>
        <thead>
          <tr>
            <th>Floor Plan</th>
            <th>#Units</th>
            <th> Avg. Sqft <small>(Min - Max)</small></th>
            <th>#Affordable units</th>
            <th>Avg. Subsidy</th>
          </tr>
        </thead>
        <tbody>
          {
            Reflect.ownKeys(floorPlanConfigData).map((floorPlan) => (
              <tr key={floorPlan}>
                <td>
                  <div className={style.fPlan}><span title={floorPlan} className="text-with-ellipsis w-100 d-inline-block">{floorPlan}</span></div>
                </td>
                <td
                  title={displayNumberInUSFormat(floorPlanConfigData[floorPlan].count)}>
                  {displayNumberInUSFormat(floorPlanConfigData[floorPlan].count)}
                </td>
                <td
                  title={getAverageTotals(floorPlan, 'squareFtTotal', 'count', false)}>
                  {getAverageTotals(floorPlan, 'squareFtTotal', 'count', false)}
                  <small>
                    {/* eslint-disable-next-line max-len */}
                    {`(${displayNumberInUSFormat(floorPlanConfigData[floorPlan].squareFtMinValue)} - ${displayNumberInUSFormat(floorPlanConfigData[floorPlan].squareFtMaxValue)})`}
                  </small>
                </td>
                <td
                  title="Affordable">
                  <Field name={`['${floorPlan}'].affordableConfig.affordable`} className={`form-control ${style.textField}`} placeholder="0" type="number" />
                </td>
                <td
                  title="Ave. Subsidy">
                  <Field name={`['${floorPlan}'].affordableConfig.avgSubsidy`} className={`form-control ${style.textField}`} placeholder="0" type="number" />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  )
}

export default AffordableConfigView;