import React, { useCallback, useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { withServices } from 'reaf';
import { faAngleDoubleLeft as firstIcon } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight as lastIcon } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faAngleLeft as prevIcon } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight as nextIcon } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { PAGINATION_DEFAULT } from '../../../constants';
import IconButton from '../../core/Button/IconButton';
import style from './CustomTablePagination.module.scss';
import { PaginationChanged } from '../../../constants/eventTrackerMessage';

const CustomTablePagination = ({
  loading,
  fetchProjects,
  queries,
  totalPageCount = 1,
  eventTrackerService
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(
    queries._limit || PAGINATION_DEFAULT.pageSize
  );
  const [canPreviousPage, setCanPreviousPage] = useState(true);
  const [canNextPage, setCanNextPage] = useState(true);

  const getPageNumber = useCallback(
    (queries) =>
      +(queries._offset || 0) /
        +(queries._limit || PAGINATION_DEFAULT.pageSize) +
      1,
    []
  );

  useEffect(() => {
    setPageSize(queries._limit || PAGINATION_DEFAULT.pageSize);
  }, [queries._limit]);

  useEffect(() => {
    setPageNumber(getPageNumber(queries));
  }, [queries._limit, queries._offset]);

  useEffect(() => {
    const pageNumber = getPageNumber(queries);
    pageNumber >= totalPageCount ? setCanNextPage(false) : setCanNextPage(true);
    pageNumber <= 1 ? setCanPreviousPage(false) : setCanPreviousPage(true);
  }, [queries._limit, queries._offset, totalPageCount]);

  const gotoPage = useCallback(
    (pageNumber) => {
      const pageLimit = +queries._limit;
      if (
        pageNumber !== NaN &&
        pageLimit !== NaN &&
        pageNumber > 0 &&
        pageNumber <= totalPageCount
      ) {
        setPageNumber(pageNumber);
        const _offset = (pageNumber - 1) * pageLimit;
        if (_offset >= 0) {
          fetchProjects({ ...queries, _offset });
        }
      }
    },
    [queries, totalPageCount]
  );

  const gotoFirstPage = useCallback(() => {
    gotoPage(1);
    eventTrackerService.track(PaginationChanged, {
      buttonName: 'Go to first page'
    });
  }, [gotoPage]);
  const gotoPreviousPage = useCallback(() => {
    gotoPage(pageNumber - 1);
    eventTrackerService.track(PaginationChanged, {
      buttonName: 'Go to previous page'
    });
  }, [pageNumber, gotoPage]);
  const gotoNextPage = useCallback(() => {
    gotoPage(pageNumber + 1);
    eventTrackerService.track(PaginationChanged, {
      buttonName: 'Go to next page'
    });
  }, [pageNumber, gotoPage]);
  const gotoLastPage = useCallback(() => {
    gotoPage(totalPageCount);
    eventTrackerService.track(PaginationChanged, {
      buttonName: 'Go to last page'
    });
  }, [totalPageCount, gotoPage]);

  const onChange = useCallback(
    (e) => {
      setPageNumber(e.target.value);
    },
    [setPageNumber]
  );
  const onBlur = useCallback(
    (e) => {
      gotoPage(+e.target.value);
      eventTrackerService.track(PaginationChanged, {
        buttonName: 'Go to page',
        pageNumber: +e.target.value
      });
    },
    [gotoPage]
  );

  const onChangePageSize = useCallback(
    (e) => {
      setPageSize(Number(e.value));
      fetchProjects({ ...queries, _limit: Number(e.value), _offset: 0 });
      eventTrackerService.track(PaginationChanged, {
        buttonName: 'Change Page Size',
        pageNumber: +e.value
      });
    },
    [setPageSize, fetchProjects]
  );

  return (
    <div className="d-flex">
      {loading && <div className={style.linePreloader} />}
      <div className={style.appPagination}>
        <IconButton
          id="GoToFirstPage"
          icon={firstIcon}
          onClick={gotoFirstPage}
          disabled={!canPreviousPage}
        />
        <IconButton
          id="GoToPrevPage"
          icon={prevIcon}
          onClick={gotoPreviousPage}
          disabled={!canPreviousPage}
        />
        <div>
          <span>Page: </span>
          <input
            id="GoToPage"
            type="text"
            value={pageNumber}
            onChange={onChange}
            onBlur={onBlur}
          />
          <span>of</span>
          <strong>{totalPageCount || 1}</strong>
        </div>
        <IconButton
          id="GoToNextPage"
          icon={nextIcon}
          onClick={gotoNextPage}
          disabled={!canNextPage}
        />
        <IconButton
          id="GoToLastPage"
          icon={lastIcon}
          onClick={gotoLastPage}
          disabled={!canNextPage}
        />
      </div>

      <span className="d-inline-flex align-items-center ml-3">
        <div className={style.paginationDropdown}>
          <span>Show </span>
          <ReactSelect
            className="page-select"
            defaultValue={{ label: pageSize, value: pageSize }}
            value={{ label: pageSize, value: pageSize }}
            maxMenuHeight={230}
            menuPlacement="auto"
            menuPosition="fixed"
            options={PAGINATION_DEFAULT.pageSizes.map((pageSize) => ({
              value: pageSize,
              label: pageSize
            }))}
            onChange={onChangePageSize}
          />
        </div>
      </span>
    </div>
  );
};
export default withServices('eventTrackerService')(CustomTablePagination);
