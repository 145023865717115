export default class ClipboardData {
  copiedSourceColumnRange = null;

  copiedSourceSelectedRange = null;

  copiedHeaderColumnValues = [];

  copiedCategoryValues = null;

  copiedSubCategoryValue = null;

  lastCellValue = '';

  reset() {
    this.copiedSourceColumnRange = null;
    this.copiedSourceSelectedRange = null;
    this.copiedHeaderColumnValues = [];
    this.copiedCategoryValues = null;
    this.copiedSubCategoryValue = null;
  }

  hasData() {
    return this.copiedSourceColumnRange || this.copiedSourceSelectedRange;
  }

  setCopiedRange(copiedRange) {
    if (copiedRange.row < 0) {
      this.copiedSourceColumnRange = copiedRange;
      this.copiedSourceSelectedRange = null;
    } else {
      this.copiedSourceSelectedRange = copiedRange;
      this.copiedSourceColumnRange = null;
    }
  }
}