import React from 'react';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { dynamicAssetTypesAsObjSelector, projectMetaDataSelector } from '../../../store/selectors';

function AssetTypeBadge({ AssetType, dynamicAssetTypes }) {
  const displayType = dynamicAssetTypes[AssetType];
  return (
    <span>{displayType && <Badge variant="primary" className="ml-2">{displayType.badgeText}</Badge>}</span>
  );
}

const mapStateToProps = state => ({
  projectsMetaData: projectMetaDataSelector(state),
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
});

export default connect(mapStateToProps)(AssetTypeBadge);
