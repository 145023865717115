import React, { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Tour from '../../core/Tour/Tour';

const addProjectSteps = onClose => [
  {
    selector: '#sdadadasd',
    content: ({ goTo }) => (
      <>
        <h6 className="border-bottom">Add Property Tutorial</h6>
        <p>
          This tutorial will guide you through creating a new property.
          Please click the next button after completing each instruction to continue.
        </p>
        <p>
          You can click the close button on any instruction to get out of the tutorial.
        </p>
        <p>
          <Button size="sm" variant="info" onClick={() => goTo(1)}>Start</Button>
        </p>
      </>
    )
  },
  {
    selector: '#AddProjectButton',
    content: () => (
      <>
        <h6 className="border-bottom">Add Property Tutorial</h6>
        <p>
          Click &nbsp;
          {' '}
          <i>Add Property</i>
          {' '}
&nbsp; button to add a new property/project.
        </p>
      </>
    )
  },
  {
    selector: '#ProjectNameField',
    content: ({ goTo, inDOM }) => {
      if (!inDOM) {
        goTo(1);
        return null;
      }

      return (
        <>
          <h6 className="border-bottom">Add Property Tutorial</h6>
          <p>
            Enter project/property name here.
          </p>
        </>
      );
    },
    action: node => node && node.focus()
  },
  {
    selector: '#AssetTypeField',
    content: () => (
      <>
        <h6 className="border-bottom">Add Property Tutorial</h6>
        <p>
          Specify asset type for the project/property.
        </p>
      </>
    ),
    action: node => node && node.focus()
  },
  {
    selector: '#ProjectTemplateField',
    content: () => (
      <>
        <h6 className="border-bottom">Add Property Tutorial</h6>
        <p>
          Specify the template you want to use for this project/property.
        </p>
      </>
    ),
    action: node => node && node.focus()
  },
  {
    selector: '#ProjectFormSaveButton',
    content: () => (
      <>
        <h6 className="border-bottom">Add Property Tutorial</h6>
        <p>
          Click the &nbsp;
          {' '}
          <i>Save</i>
          {' '}
&nbsp; button to create this property.
        </p>
      </>
    ),
    action: node => node && node.focus()
  },
  {
    selector: '#asdasdasdasdasd',
    content: () => (
      <>
        <h6 className="border-bottom">Add Property Tutorial</h6>
        <p>
          If you see any errors on the form, click the back button to go back and fix any incorrect fields.
        </p>
        <p>
          Otherwise you may end this tutorial now.
        </p>
        <p>
          <Button size="sm" variant="warning" onClick={onClose}>End Tutorial</Button>
        </p>
      </>
    ),
    action: node => node && node.focus()
  }
];

function AddProjectsTour({ show, onClose, step }) {
  const steps = useMemo(() => addProjectSteps(onClose), [onClose]);

  return (
    <Tour
      steps={steps}
      isOpen={show}
      onRequestClose={onClose}
      rounded={5}
      accentColor="#5cb7b7"
      startAt={0}
      updateDelay={300}
      update={Math.random().toString()}
      closeWithMask={false}
      disableInteraction={false}
      showNavigation={false}
      disableKeyboardNavigation={false}
      showButtons
      goToStep={step}
    />
  );
}


export default AddProjectsTour;
