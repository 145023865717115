import React, { useState } from 'react';
import { withServices } from 'reaf';
import TicketsKanbanBoard from '../TicketsKanbanBoard/TicketsKanbanBoard';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import useBool from '../../../hooks/useBool';
import { getTicketsAsListSelector } from '../../../store/selectors';
import TicketListHeader from '../TicketListHeader/TicketListHeader';
import { createdAtFilter } from '../../core/ReactTableFilters/ReactTableFilters';
import TicketPopups from '../TicketPopups/TicketPopups';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import TicketStatus from '../TicketStatus/TicketStatus';
import { SelectTicketStatusFilter } from '../../core/ReactTableFilters/ReactTableFilters';

const Tickets = ({ ticketsList, ticketService, queries, apiClient, router }) => {
  const { data, loading, pageCount, fetchData, setData, refreshData } = useReactTable({
    queries,
    apiClient,
    router,
    ticketService,
    routeName: 'TicketsPage',
    resourceEndPoint: '/admin/tickets',
    fileName: 'Tickets.csv',
    csvDownloadEndPoint: '/admin/tickets/downloadCSV'
  });

  const [showTicketPopoup, setShowTicketPopup, setHideTicketPopup] = useBool(false);
  const [selectedTicket, setSelectedTicket] = useState();

  const columns = React.useMemo(() => [
    {
      Header: '#',
      accessor: 'uuid',
      fixedWidth: 30,
      disableFilters: true,
      ignoreTextEllipsis: true,
      Cell: ({ row: { original : { uuid } } }) => uuid.substr(uuid.length - 5, uuid.length)
    },
    {
      Header: 'Ticket',
      accessor: 'description',
      ignoreTextEllipsis: true,
      fixedWidth: 260,
    },
    {
      Header: 'Type',
      accessor: 'type',
      fixedWidth: 60,
    },
    {
      Header: 'Status',
      accessor: 'status',
      fixedWidth: 130,
      Filter: SelectTicketStatusFilter,
      Cell: ({ row: { original } }) => <TicketStatus ticket={original} onTicketUpdate={onTicketUpdate} />
    },
    {
      Header: 'Company',
      accessor: 'Company.name',
      fixedWidth: 80,
      Cell: ({ row: { original: { Company }} }) => (Company ? `${Company.name}` : 'Deleted')
    },
    {
      Header: 'User',
      accessor: 'User.firstName',
      fixedWidth: 50,
      Cell: ({ row: { original: { User }} }) => (User ? `${User.firstName}` : 'Deleted')
    },
    {
      Header: 'Updated From',
      accessor: 'updatedFrom',
      fixedWidth: 50,
      ignoreTextEllipsis: true,
      Filter: createdAtFilter,
      Cell: ({ row }) => <DisplayDate date={row.original.updatedAt} />
    },
    {
      Header: 'Updated To',
      accessor: 'updatedTo',
      fixedWidth: 50,
      ignoreTextEllipsis: true,
      Filter: createdAtFilter,
      Cell: ({ row }) => <DisplayDate date={row.original.updatedAt} />
    },
    {
      Header: 'Has attachment',
      accessor: 'TicketAttachments',
      fixedWidth: 50,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => (
        row.original.TicketAttachments && row.original.TicketAttachments.length ? `Yes` : 'No'
      )
    }
  ], [data, setData]);

  const onTicketUpdate = React.useCallback(async(ticket) => {
    const index = data.findIndex(t => t.uuid === ticket.uuid);
    const updateArray = [...data];
    updateArray[index] = ticket;
    setData(updateArray);
  }, [data, setData])

  const onDownloadCSV = React.useCallback(async () => {
    await ticketService.downloadCSV(queries);
  }, [queries]);

  const onRowClick = React.useCallback(async (ticket) => {
    setSelectedTicket(ticket);
    setShowTicketPopup();
  }, [data]);

  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        title="Tickets"
        onDownloadCSV={onDownloadCSV}
        queries={queries}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
        onRowClick={onRowClick}
        enableRowClik={true}
      />
      <TicketPopups
        showTicketPopoup={showTicketPopoup}
        ticket={selectedTicket}
        onTicketUpdate={(ticket) => {
          setSelectedTicket(ticket);
          onTicketUpdate(ticket)
        }}
        setHideTicketPopup={setHideTicketPopup} 
      />
    </>
  );
};

export default withServices('ticketService', 'apiClient', 'router')(Tickets);