import FileSaver from 'file-saver';
import { useCallback } from 'react';
import { useCurrentPng } from 'recharts-to-png';

function useSvgToImageDownload(options = {}) {
  const handleDownload = useCallback(async (png, name = "chart.png") => {
    if (png) {
      FileSaver.saveAs(png, name);
    }
  }, [FileSaver]);

  const [getPng, { ref, isLoading }] = useCurrentPng({ ...options, y: -20 });
  return [getPng, { ref, isLoading, handleDownload }];
}
export default useSvgToImageDownload;