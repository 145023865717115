
const messages = {
  errors: {
    serverError: 'Oops! Something went wrong. Please try again later.',

    auth: {
      passwordMismatch: 'Your passwords don\'t match',
      currentPassword: 'Please enter your current password',
      requiredPassword: 'Please provide a valid password',
      invalidEmail: 'Please provide a valid email address',
      passwordMaxLength: 'Your password can be at most 20 characters long',
      passwordMinLength: 'Your password should be at least 8 characters long',
      invalidCredentials: 'Your email and password do not match. Please try again.',
      passwordUpperCase: 'You must have at least one capital letter in your password',
      passwordNumericCase: 'You must have at least one numeric letter in your password',
      passwordSpecialCharacter: 'You must have at least one special character in your password',
      passwordSmallCase: 'You must have at least one small case letter in your password',
      multipleSessionsNotAllowed: 'You are already logged in from another device. Click the following button to log out from other devices and continue.',
      multipleSessions: 'User is already signed in.',
      resetPassword: 'Reset Password',
      emailNotRegistered: 'This email is not registered with us.',
      companyName: 'You need to provide your company name',
      name: 'Please provide your name',
      reenterPassword: 'Please enter confirm password',
      emailOTPSuccess: 'We have sent you an Otp on your registerd email address.'
    },

    validationError: {
      assetTypes: 'Please select a valid asset type.',
      documentTypes: 'Please select a valid document type.',
      companyName: 'Please provide a valid company name.',
      firstName: 'Please provide a valid first name.',
      lastName: 'Please provide a valid last name.',
      invalidEmail: 'Please provide a valid email address',
      requiredEmail: 'Email address is mendatory',
      role: 'Please provide a role.',
      date: 'Please enter a valid date'
    },

    fileUpload: {
      required: "Please select a file",
      format: "Please select a valid file",
      size: "Please select a file of size less than"
    },

    templateName: 'Please provide a valid template name'
  },
  toastMessage: {
    CREATE_PROJECT: 'Property has been successfully created',
    EDIT_PROJECT: 'Property has been successfully edited',
    DELETE_PROJECT: 'Property has been successfully deleted',
    CLEAR_PROJECT_TAG: 'Property tag is successfully cleared',
    DOCUMENT_TAG: 'Document is successfully tagged',
    AS_ON_DATE_UPDATE: 'As on Date updated successfully',
    CLEAR_DOCUMENT_TAG: 'Document tag is successfully cleared',
    DELETE_DOCUMENT: 'Document is deleted successfully',
    TEMPLATE_MODEL_ERROR: 'Template Model(s) is not available.',
    NORMALIZED_JSON_DOWNLOAD_ERROR: 'Error in downloading normalized json for this project.',
    NORMALIZED_JSON_SEND_ERROR: 'Error in sending json for this project.',
    API_INTEGRATION_PUSH_IN_PROCESS: 'Sending the data to Data Hub',
    API_INTEGRATION_PUSH_ERROR: 'Apologies, but something went wrong. A support case has been logged for the Digital Technology team to investigate.',
    API_INTEGRATION_PUSH_SUCCESS: 'Data successfully sent to Data Hub',
    PROJECT_COMPLETION_ERROR: 'Error in marking property complete/incomplete. Please try again later!',
    CREATE_ACCOUNTS_EXECUTIVE: 'Accounts Executive has been successfully created',
    CREATE_PROSPECT: 'Prospect has been successfully created',
    CREATE_PAYMENT_LINK: 'Link has been successfully created',
    UPDATE_PROSPECT: 'Prospect updated successfully',
    SEND_INVOICE: 'Invoice has been successfully sent',
    CANCEL_INVOICE: 'Invoice has been canceled',
    COMPANY_DELETED: 'Company deletion process initiated. You will receive confirmation/error mail once it is complete',
    CREATE_PROJECT_EXPORTER: 'Property exporter has been successfully created',
    DELETE_PROJECT_EXPORTER: 'Property exporter has been successfully deleted',
    CHARGE_CODE_SELECT_ERROR: 'Please select any category first',
    CHARGE_CODE_ALREADY_EXIST: 'Charge code category config already exist',
    INVALID_DATA_TYPE: 'Highlighted cell has invalid data type and can impact model/report integration',
    INVALID_DATA_TYPE_WITH_CELL_REF: 'an invalid data type and can impact model/report integration',
    CREATE_VIDEO_TUTORIAL: 'Video Tutorial has been successfully created',
    EDIT_VIDEO_TUTORIAL: 'Video Tutorial has been successfully edited',
    DELETE_VIDEO_TUTORIAL: 'Video Tutorial has been successfully deleted',
    TEMPLATE_MAPPING_ERROR: 'Excluded Income/Expense is not mapped with the clik category in the template mapping. Please verfiy the uploaded template mapping.',
    CREATE_DYNAMIC_ASSET_TYPE: 'Dynamic Asset Type has been successfully created',
    EDIT_DYNAMIC_ASSET_TYPE: 'Dynamic Asset Type has been successfully edited',
    DICTIONARY_UPDATED: 'Dictionary Updated Successfully',
    DICTIONARY_UPDATE_ERROR: 'Error in updating the Dictionary',
    DOC_VALIDATION_SUCCESS: 'Document has been marked as validated',
    DOC_VALIDATION_ERROR: 'Document has been marked as invalidated',
    DOC_VALIDATE_TOOLTIP: 'Click to mark document as validated',
    DOC_INVALIDATE_TOOLTIP: 'Click to mark document as invalidated',
    API_CONFIG_DOWNLOAD_FAIL: 'Unable to download config please try again !',
    API_CONFIG_DELETE_FAIL: 'Unable to delete config please try again !',
    API_INTEGRATION_CONFIG_UPDATE_SUCCESS: 'Api Integration Form has been successfully updated',
    API_INTEGRATION_CONFIG_UPDATE_ERROR: 'Error in updating Api Integration Form',
    MIGRATE_PROJECT_SUCCESS: 'Project has been successfully migrated',
    BULK_PROJECT_JSON_SUCCESS: 'Bulk project json downloaded successfully',
    BETA_PARSER_SUCCESS: 'Beta Parser extracted the data successfully!',
    BETA_PARSER_ERROR: 'Sorry, Beta Parser couldn`t extract the data.'
  },
  affixMessage: {
    notFound: 'No Relevant Data Found'
  },
  projectReport: {
    invalidTemplates: 'Project Report has invalid template file'
  },
  DOCUMENT_SELECT_DISABLE_MSG: "Only succesfully tagged and validated documents can be selected"
};

export default messages;
