import React, { useState, useEffect, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import { withServices } from 'reaf';
import { saveAs } from 'file-saver';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik, Form, Field } from 'formik';
import { Spinner } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import {
  currentProjectReportSelector,
  currentProjectSelector,
  loggedInCompanySelector
} from '../../../store/selectors';
import { appendClassName, showPaymentPopup } from '../../../utils/utils';

import SpreadSheet from '../../core/SpreadSheet/SpreadSheet';
import { jotoForm } from '../../../config';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import ShareReportLinkModal from '../ShareReportLinkModal/ShareReportLinkModal';
import ThankYouIcon from '../../../assets/images/email-verify-icon.png';
// import styles from './ProjectReport.module.scss';
import {
  DocumentsDownloadedOnReportPage,
  DownloadWorkbookClickedOnReportPage,
  OTPSent
} from '../../../constants/eventTrackerMessage';
import { useFeatureFlag } from '../../../providers/FeatureFlagProvider';
import ProjectReportSkeletonLoader from '../ProjectReport/ProjectReportSkeletonLoader';
import FallbackWorkbookManager from '../../pages/FallbackPage/FallbackWorkbookManager';

const { formId, apiKey, emailFieldId } = jotoForm;



function FallbackSpreadSheet({
  store,
  toastrService,
  apiClient,
  currentCompany,
  companyUuid,
  projectUuid,
  templateUuid,
  modelUuid,
  selectedDocuments,
  isOpen,
  reportUuid,
  canDownloadDocument = false,
  data,
  onCloseExtractionPreview
}) {
  const [manager, setManager] = useState(null);
  const [showDownloadDocumentPopup, setShowDownloadDocumentPopop] =
    useState(false);

  const createManager = useCallback(async (wbManager) => {
    setManager(
      new FallbackWorkbookManager(wbManager, {
        projectUuid,
        templateUuid,
        apiClient,
        modelUuid,
        selectedDocuments,
        companyUuid: companyUuid,
        reportUuid,
        isOpen
      })
    );
  }, []);

  useEffect(() => {
    manager && manager.updateOptions({ store, toastrService });
  }, [manager, store, toastrService]);

  useEffect(() => {
    if (manager && data) {
      manager.setData(data, false);
    }
  }, [data, manager]);

  const downloadXLS = useCallback(
    (blob) => {
      const fileName = `fallback-document.xlsx`;
      saveAs(blob, fileName);
    },
    []);

  return (
    <div className="vertical-section position-relative h-100">
        <SpreadSheet
          showToolbar
          sheetCount={1}
          isDocFallbackApproach
          canDownloadDocument={canDownloadDocument}
          canShareReport={currentCompany.canShareReport}
          canDownloadReportWorkbook={true}
          onInitManager={createManager}
          onDownloadXLS={downloadXLS}
          company={currentCompany}
          setShowDownloadDocumentPopop={setShowDownloadDocumentPopop}
          onCloseExtractionPreview={onCloseExtractionPreview}
          data={data}
        />
      {/* <ProjectReportSkeletonLoader/> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  project: currentProjectSelector(state),
  reports: currentProjectReportSelector(state),
  currentCompany: loggedInCompanySelector(state)
});

export default connect(mapStateToProps)(
  withServices(
    'store',
    'apiClient',
    'toastrService',
    'projectsService',
    'documentsService',
    'authService',
    'captureEmailService',
    'eventTrackerService',
    'projectReportService',
  )(FallbackSpreadSheet)
);
