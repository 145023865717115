/* eslint-disable max-len */
import React from 'react';
import { Spinner } from 'react-bootstrap';
import StaticPageFooterNavigation from '../../core/StaticPageWithNav/StaticPageFooterNavigation';
import StaticPageWithNav from '../../core/StaticPageWithNav/StaticPageWithNav';
import styles from './ReleaseNotes.module.scss';

function BulletSection({ title, heading, bullets }) {
  return (
    <div className="clearfix mt-4 mb-3">
      <h5 className="clearfix mb-3">{title}</h5>
      {heading && <p className="clearfix font-weight-bold">{heading}</p>}
      {
        bullets.map((bullet) => {
          const { section, header, bullets } = bullet;
          if (!bullets) {
            return <>
              {
                header && (
                  <div className="clearfix pl-3 mb-3">
                    <h5 className="clearfix mb-2 small-heading">{header}</h5>
                    <ul>
                      {
                        section.map(({ content }) => (<li>{content}</li>))
                      }
                    </ul>
                  </div>
                )
              }
              {
                !header && (
                  <ul>
                    {
                      section.map(({ content }) => (<li>{content}</li>))
                    }
                  </ul>
                ) 
              }
            </>
          }
          if (bullets) {
            return (
              <>
                <div className="clearfix pl-3 mb-3">
                  <div className="clearfix mb-2 small-heading">{header}</div>
                  {
                    bullets.map((bullet) => {
                      const { section, header } = bullet;
                      return (
                      <div key={header} className="clearfix pl-3 mb-3">
                        <div className="clearfix mb- small-heading">{header}</div>
                        <ul>
                          {
                            section.map(({ content }) => (
                              <li key={content}>{content}</li>
                            ))
                          }
                        </ul>
                      </div>
                    )})
                  }
                </div>
              </>
            )
          }
          return null;
        })
      }
      
    </div>
  );
}

function ReleaseNotes({ style }) {
  const [releaseNotesJson, setReleaseNotesJson] = React.useState();
  React.useEffect(() => {
    fetch('/releaseNotes.json').then((response) => response.json()).then((releaseNotes) => setReleaseNotesJson(releaseNotes))
  }, []);

  if (!releaseNotesJson) {
    return <div className={styles.loader}>
      <Spinner animation="grow" />
    </div>;
  }
  return (
    <div className={`container-fluid ${style.pageOuterSection}`}>
      <div className="row">
        <div className={`col-12 d-flex border-bottom ${style.sectionHeader}`}>
          <h3>AutoUW Release Notes</h3>
          <span className="text-primary">{releaseNotesJson.title}</span>
        </div>
      </div>
      <StaticPageWithNav
        content = {
          <section>
           <div className="clearfix"  style={{ display: 'none' }}>
            
            <div className="alert alert-primary" role="alert">
              <span className={`${style.admonitionIcon}`}>
                {' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path d="M0 9c0-5 4-9 9-9s9 4 9 9-4 9-9 9-9-4-9-9zm8-1v5c0 .6.4 1 1 1s1-.4 1-1V8c0-.6-.4-1-1-1s-1 .4-1 1zm1-2c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1z" fill="#0052CC" fillRule="evenodd"> </path>
                </svg>
                {' '}

              </span>
              <p><strong>Stay up to date!</strong></p>
              <p>Log in and watch this page to get notified about new Clik releases.</p>
              <p>
                If you can&rsquo;t see the
                <strong>Watch</strong>
                {' '}
button, try clearing app.clik-related cookies in your browser.
              </p>
            </div>
          </div>

          <div id="changesLog" className="border-bottom pb-4">
            <h2 className="mt-0">Change logs</h2>
            <p>Too many release notes? Take a look at our Upgrade matrix to get a quick roll-up of the most important changes in the latest versions.</p>
            <ul>
              {
                releaseNotesJson.content.map(({ title, date, href }, index) => (
                  <li key={title}>
                    {date} —
                    <a href={`#${href}`}>{title}</a>
                    {' '}
                    {index === 0 && <span className="badge badge-pill badge-warning">New</span>}
                  </li>
                ))
              }
            </ul>
          </div>
          {
            releaseNotesJson.content.map(({ href, title, content }) => (
              <div key={title} id={href} className="border-bottom pb-4">
                <h2>{title}</h2>
                {content.map(({ subHeading, bullets, heading }) => (
                  <BulletSection key={subHeading} title={subHeading} bullets={bullets} heading={heading} />
                ))}
              </div>
            ))
          }
        </section>
        }
        navigation = {[
          { id: 'changesLog', name: 'Change logs' },
          ...releaseNotesJson.content.map(({ href, title }) => ({
            id: href,
            name: title
          })),
        ]}

        footerNavigation = {
          <StaticPageFooterNavigation
            leftMenuInfo = {{ route: 'terms', displayName: 'Terms & Conditions' }} 
            rightMenuInfo = {{ route: 'tips', displayName: 'Tutorial' }} 
          />
        }
      
      />
    </div>
  );
}
export default ReleaseNotes;
