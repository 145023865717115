import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  project: '',
  documents: {},
  isDocumentTaggingActive: false,
  isDocumentValidated: false,
  assumption: null,
  projectSpecificAssumption: null,
  reports: {},
  templateHeadCategories: {},
  categorySequence: null,
  templateMappings: null,
  summarySheetTotalConfig: null,
  currentViewerActiveSheet: null,
  isReportEnabled: false,
  isReportShareLinkEnabled: false,
  canDownloadConsolidatedModel: false,
  isConsolidatedModelDownloading: false,
  downloadFileName: '',
  setReadOnlyAccess: false
};

const [
  {
    setCurrentProject,
    setProjectDocuments,
    setIsDocumentValidated,
    setIsDocumentTaggingActive,
    reset,
    setDocument,
    updateDocument,
    deleteDocument,
    setReports,
    setTemplateHeadCategories,
    setSummarySheetTotalConfig,
    setCategorySequence,
    updateProjectAssumption,
    setTemplateMappings,
    setAssumption,
    setProjectSpecificAssumption,
    setCurrentActiveViewerSheet,
    setIsReportEnabled,
    setIsReportShareLinkEnabled,
    setCanDownloadConsolidatedModel,
    setIsConsolidatedModelDownloading,
    setDownloadFileName,
    setReadOnlyAccess
  },
  reducer
] = createDuck('autouw/currentProject', {
  _initialState: initialState,

  setCurrentProject: project => state => ({
    ...state,
    project
  }),

  setReadOnlyAccess: readOnlyAccess => state => ({
    ...state,
    readOnlyAccess
  }),

  setProjectDocuments: documents => state => ({
    ...state,
    documents: normalize(documents, 'uuid')
  }),

  setIsDocumentValidated: isDocumentValidated => state => ({
    ...state,
    isDocumentValidated
  }),
  
  setIsDocumentTaggingActive: isDocumentTaggingActive => state => ({
    ...state,
    isDocumentTaggingActive
  }),

  setDocument: document => state => ({
    ...state,
    documents: {
      [document.uuid]: document,
      ...state.documents
    }
  }),

  updateDocument: document => state => ({
    ...state,
    documents: {
      ...state.documents,
      [document.uuid]: document
    }
  }),

  setTemplateHeadCategories: templateHeadCategories => state => ({
    ...state,
    templateHeadCategories
  }),

  setCategorySequence: categorySequence => state => ({
    ...state,
    categorySequence
  }),

  setTemplateMappings: templateMappings => state => ({
    ...state,
    templateMappings
  }),

  setSummarySheetTotalConfig: summarySheetTotalConfig => state => ({
    ...state,
    summarySheetTotalConfig
  }),

  deleteDocument: document => (state) => {
    const documents = { ...state.documents };
    Reflect.deleteProperty(documents, document.uuid);
    return {
      ...state,
      documents
    };
  },

  setReports: reports => state => ({
    ...state,
    reports
  }),

  setAssumption: assumption => state => ({
    ...state,
    assumption
  }),

  updateProjectAssumption: assumption => state => ({
    ...state,
    project: {
      ...state.project,
      assumption
    }
  }),

  setProjectSpecificAssumption: projectSpecificAssumption => state => ({
    ...state,
    projectSpecificAssumption
  }),

  setCurrentActiveViewerSheet: currentViewerActiveSheet => state => ({
    ...state,
    currentViewerActiveSheet
  }),
  setIsReportEnabled: isReportEnabled => state => ({
    ...state,
    isReportEnabled
  }),
  setIsReportShareLinkEnabled: isReportShareLinkEnabled => state => ({
    ...state,
    isReportShareLinkEnabled
  }),
  setCanDownloadConsolidatedModel: canDownloadConsolidatedModel => state => ({
    ...state,
    canDownloadConsolidatedModel
  }),
  setIsConsolidatedModelDownloading: isConsolidatedModelDownloading => state => ({
    ...state,
    isConsolidatedModelDownloading
  }),
  setDownloadFileName: downloadFileName => state => ({
    ...state,
    downloadFileName
  }),
  reset: () => () => initialState
});

export {
  setCurrentProject,
  setProjectDocuments,
  setIsDocumentValidated,
  setIsDocumentTaggingActive,
  reset as resetCurrentProject,
  setDocument,
  updateDocument,
  deleteDocument,
  setReports,
  setTemplateHeadCategories,
  setCategorySequence,
  setSummarySheetTotalConfig,
  updateProjectAssumption,
  setTemplateMappings,
  setAssumption,
  setProjectSpecificAssumption,
  setCurrentActiveViewerSheet,
  setIsReportEnabled,
  setIsReportShareLinkEnabled,
  setCanDownloadConsolidatedModel,
  setIsConsolidatedModelDownloading,
  setDownloadFileName,
  setReadOnlyAccess
};

export default reducer;
