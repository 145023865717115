import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import CompanyStatus from '../../app/Company/CompanyStatus';

function CompaniesPageHeader() {
  return (
    <Container fluid className="p-2 light border-bottom-light-blue sa-breadcrumb">
      <Row className="align-items-center">
        <Col>
          <Breadcrumbs rootRoute="superAdminHomePage" items={[{ to: '#', content: 'Companies with status' }]} />
        </Col>
      </Row>
    </Container>
  );
}

function CompanyStatusPage({ queries }) {
  return (
    <SuperAdminDashboard>
      <CompaniesPageHeader />
      <CompanyStatus queries={queries} />
    </SuperAdminDashboard>
  );
}
export default CompanyStatusPage;
