import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import ResetUserPasswordFormComponent from './ResetUserPasswordFormComponent';
import messages from '../../../../locales';
import { PASSWORD_SCHEMA } from '../../../constants';

const UserFormSchema = Yup.object().shape({
  newPassword: PASSWORD_SCHEMA,
  confirmPassword: Yup.string()
    .required(messages.errors.auth.requiredPassword)
    .oneOf([Yup.ref('newPassword'), null], messages.errors.auth.passwordMismatch)
});

function ResetUserPasswordForm({
  onSubmitSuccess, bindSubmission, user, userService
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      await userService.userResetPassword(user, values.newPassword);
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ newPassword: '', confirmPassword: '' }}
      validationSchema={UserFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <ResetUserPasswordFormComponent {...formikProps} bindSubmission={bindSubmission} />
      }
    />
  );
}

export default withServices('userService')(ResetUserPasswordForm);
