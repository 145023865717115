/* eslint-disable no-prototype-builtins */
/* eslint-disable max-nested-callbacks */
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { withServices } from 'reaf';
import { Table, Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import messages from '../../../../locales';
import { DICTIONARY_KEYS } from '../../../constants';

const DictionaryTable = ({ 
  data, categoriesList, hasSubCategory, headDictionary, onSuccess, isSubmitting, bindSubmission,
  dictionaryService, project, toastrService, setShowEditDictionary, setProjectDictionaryData, headDictionaryData,
  setHeadDictionaryData, projectDictionaryData
}) => {
  const [dictionaryData, setDictionaryData] = useState([]);
  const [headItems, setHeadItems] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState({});
  const [subCategoryOptions, setSubCategoryOptions] = useState({});

  const onSubmit = useCallback(async() =>{
    onSuccess();
    dictionaryService.updateProjectDictionary(project, projectDictionaryData, headDictionaryData)
  }, [project, projectDictionaryData, headDictionaryData]);

  useEffect(() => {
    setProjectDictionaryData(data);
    setHeadDictionaryData(headDictionary);
  }, [data, headDictionary])

  bindSubmission(onSubmit, isSubmitting)

  const setTableDictionaryData = useCallback(({ 
    head='', 
    category='', 
    subCategory = '', 
    isHeadDictionary = false , 
    isProjectDictionary = false, 
    headItem = '', lineItem = '' 
  }) => {
      setDictionaryData({
          ...dictionaryData, 
          [lineItem]: {
            ...dictionaryData[lineItem],
            headItem: head,
            category,
            subCategory
          } 
        })
      if (isHeadDictionary) {
        setHeadDictionaryData({
          ...headDictionaryData,
          [lineItem]: {
            ...headDictionaryData[lineItem],
            head,
            category,
            subCategory
          }
        })
      }
      if(isProjectDictionary) {
        setProjectDictionaryData({
          ...projectDictionaryData,
          [headItem]: {
            ...projectDictionaryData[headItem],
            [lineItem]: {
              ...projectDictionaryData[headItem][lineItem],
              category,
              subCategory
            }
          }
        })
      }
  }, [projectDictionaryData, headDictionaryData, dictionaryData])

  const onHeadChange = useCallback((targetValue, type, row) => {
    if(type === DICTIONARY_KEYS.HEAD) {
      setTableDictionaryData({
        head: targetValue.value,
        isHeadDictionary: true,
        isProjectDictionary: false,
        lineItem: row.lineItem
      })
    } else if (type === DICTIONARY_KEYS.CATEGORY) {
      if(headDictionaryData[row.lineItem]) {
        setTableDictionaryData({
          head: headDictionaryData[row.lineItem].head,
          isHeadDictionary: true,
          isProjectDictionary: false,
          lineItem: row.lineItem,
          category: targetValue.value
        })
      } else {
        setTableDictionaryData({
          head: row.headItem,
          isHeadDictionary: false,
          isProjectDictionary: true,
          headItem: row.headItem,
          lineItem: row.lineItem,
          category: targetValue.value
        })
      }
    } else if (type === DICTIONARY_KEYS.SUBCATEGORY) {
      if(headDictionaryData[row.lineItem]) {
        setTableDictionaryData({
          head: headDictionaryData[row.lineItem].head,
          isHeadDictionary: true,
          isProjectDictionary: false,
          category: dictionaryData[row.lineItem].category,
          subCategory: targetValue.value,
          lineItem: row.lineItem,
        })
      } else {
        setTableDictionaryData({
          head: row.headItem,
          isHeadDictionary: false,
          isProjectDictionary: true,
          headItem: row.headItem,
          lineItem: row.lineItem,
          category: projectDictionaryData[row.headItem][row.lineItem].category,
          subCategory: targetValue.value
        })
      }
    }
  }, [projectDictionaryData, headDictionaryData, dictionaryData]);

  const initDictionaryTableData = () => {
    let tempDictionaryData = {};
    let headOptions = [];
    let tempCategoryOptions = {};
    let tempSubCategoryOptions = {};
    let count = 0;
    try {
      Reflect.ownKeys(data).map(key => {
        if(key === DICTIONARY_KEYS.NOT_CLASSIFIED) return
        if(!headOptions.includes(key)) {
          headOptions.push({ label: key, value: key })
        }
        if(!tempCategoryOptions.hasOwnProperty(key) && !isEmpty(categoriesList)) {
          tempCategoryOptions = {
            ...tempCategoryOptions,
            [key]: Array.isArray(categoriesList[key]) ? 
            categoriesList[key].map(category => ({
              value: category,
              label: category
            })) : 
            Reflect.ownKeys(categoriesList[key]).map(category => {
              tempSubCategoryOptions = {
                ...tempSubCategoryOptions,
                [category]: categoriesList[key][category].map(subCategory => ({
                  value: subCategory,
                  label: subCategory
                }))
              }
              return ({
                value: category,
                label: category
              })
            }),
          }
        }
        setCategoryOptions(tempCategoryOptions);
        setSubCategoryOptions(tempSubCategoryOptions);
        Reflect.ownKeys(data[key]).map(dataItem => {
          const useHeadDictionary = headDictionary.hasOwnProperty(dataItem);
          const headDictionaryDataItem = headDictionary[dataItem];
          tempDictionaryData = {
            ...tempDictionaryData,
            [dataItem]: {
              key: ++count,
              headItem: useHeadDictionary ? headDictionary[dataItem].head : key,
              lineItem: dataItem,
              category: useHeadDictionary ? headDictionaryDataItem.category : data[key][dataItem]?.category,
              subCategory: hasSubCategory && (
                useHeadDictionary ? headDictionaryDataItem.subCategory : data[key][dataItem]?.subCategory
              )
            }
          }
          return null
        })
        return null;
      })
    } catch(error) {
      setShowEditDictionary(false);
      toastrService.error(messages.errors.serverError);
    }
    setHeadItems(headOptions);
    setDictionaryData(tempDictionaryData);
  }

  useEffect(() => {
    initDictionaryTableData();
  }, [data]);

  return (
    <Form>
      <Table responsive="lg">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Line Item</th>
            <th>Head Item</th>
            {!isEmpty(categoryOptions) && <th>Categories</th>}
            {hasSubCategory && <th>Sub Categories</th>}
          </tr>
        </thead>
        <tbody>
        {!isEmpty(dictionaryData) && Reflect.ownKeys(dictionaryData).map(item => (
          <tr key={dictionaryData[item].key}>
            <td>{dictionaryData[item].key}</td>
            <td>{dictionaryData[item].lineItem}</td>
            <td>
              <ReactSelect
                classNamePrefix="react-select"
                value={{ label: dictionaryData[item].headItem, value: dictionaryData[item].headItem }}
                maxMenuHeight={230}
                menuPlacement="auto"
                menuPosition="fixed"
                options={headItems}
                onChange={(value) => onHeadChange(value, DICTIONARY_KEYS.HEAD, dictionaryData[item])}
              />
            </td>
            {!isEmpty(categoryOptions) && <td>
              <ReactSelect
                placeholder="Select"
                classNamePrefix="react-select"
                value={{ label: dictionaryData[item].category, value: dictionaryData[item].category || '' }}
                maxMenuHeight={230}
                width={300}
                menuPlacement="auto"
                menuPosition="fixed"
                options={categoryOptions[dictionaryData[item].headItem]}
                onChange={(value) => onHeadChange(value, DICTIONARY_KEYS.CATEGORY, dictionaryData[item])}
              />
            </td>}
            {hasSubCategory && <td>
              <ReactSelect
                  classNamePrefix="react-select"
                  value={{ label: dictionaryData[item].subCategory, value: dictionaryData[item].subCategory }}
                  maxMenuHeight={230}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  options={subCategoryOptions[dictionaryData[item].category]}
                  onChange={(value) => onHeadChange(value, DICTIONARY_KEYS.SUBCATEGORY, dictionaryData[item])}
              />
            </td>}
          </tr>
        ))}
        </tbody>
      </Table>
    </Form>
  )
}

export default withServices(
  'dictionaryService',
  'toastrService',
  )(DictionaryTable);