import { saveAs } from 'file-saver';
import Service from './Service';
import { setTickets, setTicket } from '../store/tickets';

class TicketService extends Service {
  constructor(apiClient, store) {
    super(apiClient, store);
    this.apiClient = apiClient;
    this.store = store;
  }

  async fetchTicketListForAdmin() {
    const url = '/admin/tickets';
    const ticketResponse = await this.apiClient.get(url);
    this.store.dispatch(setTickets(ticketResponse['response']));
  }

  async updateTicketStatus(ticketUuid, status) {
    const url = `/admin/tickets/${ticketUuid}`;
    const response = await this.apiClient.put(url, { status });
    const { ticket } = response['response'];

    this.store.dispatch(setTicket(ticket));
  }

  async downloadTicketAtttachment(ticket, ticketAttachment) {
    const result = await this.apiClient.get(
      `/tickets/${ticket.uuid}/ticketAttachments/${ticketAttachment.uuid}/attachmentFile`,
      {
        responseType: 'blob'
      }
    );
    const blob = new Blob([result], { type: '' });
    const { fileName } = ticketAttachment;

    saveAs(blob, fileName);
  }

  async fetchTicketWithProjectDetails({ uuid }) {
    const url = `/admin/tickets/${uuid}`;
    const response = await this.apiClient.get(url);
    const { ticket } = response['response'];

    this.store.dispatch(setTicket(ticket));

    return ticket;
  }

  async downloadCSV(searchText) {
    const result = await this.apiClient.get(
      `/admin/tickets/downloadCSV`, { params: { searchText } }
    );
    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    const timestamp = new Date();
    const fileName = `Tickets_${timestamp}.csv`;

    saveAs(blob, fileName);
  }
}

export default TicketService;
