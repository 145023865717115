import React from 'react';
import SubscriptionList from '../../app/SubscriptionsList/SubscriptionList';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function dummy() {
  return (
    <SuperAdminDashboard>
      <SubscriptionList />
    </SuperAdminDashboard>
  );
}
export default dummy;
