import React from 'react';
import AccountsExecutivePageList from '../../app/AccountsExecutiveList/AccountsExecutiveList';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function SuperAdminAccountsExecutivePage() {
  return (
    <SuperAdminDashboard>
      <AccountsExecutivePageList />
    </SuperAdminDashboard>
  );
}

export default SuperAdminAccountsExecutivePage;
