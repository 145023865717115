import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import DocumentFormComponent from './DocumentFormComponent';

const DocumentFormSchema = Yup.object().shape({
  fileName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid file name.')
});

function DocumentForm({
  onSubmitSuccess, bindSubmission, project, document, documentsService
}) {
  const [initialFileName, _] = useState(document.fileName);
  const extension = useMemo(() => initialFileName.split('.').pop());

  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      const fileName = `${values.fileName}.${extension}`;
      await documentsService.updateDocument(project, document, { fileName });
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ fileName: initialFileName.split('.').slice(0, -1).join('.') }}
      validationSchema={DocumentFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <DocumentFormComponent 
        extension={extension} 
        {...formikProps} 
        bindSubmission={bindSubmission} 
      />
      }
    />
  );
}

export default withServices('documentsService')(DocumentForm);
