import * as Yup from 'yup';
import messages from '../../../../locales';

// eslint-disable-next-line max-len
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const getDynamicAssetTypeKeys = (assetTypes) => assetTypes.map(assetType => assetType.assetType)

const companyFormSchema = (dynamicAssetTypes)=> Yup.object().shape({
  name: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required(messages.errors.validationError.companyName),
  assetTypes: Yup
  .array()
  .of(Yup.mixed().oneOf(getDynamicAssetTypeKeys(dynamicAssetTypes), messages.errors.validationError.assetTypes), 'Please select a valid asset type.').required(),
  // documentTypes: Yup.
  // array()
  // .of(Yup.mixed().oneOf(documentTypes, 'Please select a valid asset type.')).required('Please Select one Document type'),
  isTrialOffering: Yup.boolean(),
  isEnterpriseCompany: Yup.boolean()
  .test(
    'oneOfRequired',
    'Either one of isTrialOffering, isEnterpriseCompany must be entered',
    function(isEnterpriseCompany) {
      const isTrialOffering = !!this.parent?.isTrialOffering;
      return (isTrialOffering !== isEnterpriseCompany)
    }
  ),
  address: Yup.object().shape({
    address: Yup.string(),
    country: Yup.string()
      .when('address', {
        is: (address) => address && address.length > 0,
        then: Yup.string().test('alphabets', 'Country must only contain alphabets', (value) => /^[A-Za-z]+$/.test(value)).required('Country is required with address.')
      }),
    zip: Yup.string()
      .when('address', {
        is: (address) => address && address.length > 0,
        then: Yup.string().required('Postal Code is required with address.')
      })
  }).nullable().notRequired(),
  phone: Yup.string().transform(value => (!value ? null : value)).nullable().notRequired()
    .matches(phoneRegExp, 'Please provide a valid phone number.')
});

export default companyFormSchema;
