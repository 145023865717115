import React, { useState, useCallback } from 'react';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import ExtractionPageHeader from './ExtractionPageHeader';
import DocExtractionComponent from '../../app/DocExtractionComponent/DocExtractionComponent';
import ExtractionPageTour from './ExtractionPageTour';
import { markFirstTourDone } from '../../../store/productTour';
import { ExtractionPageTourGuideOpened } from '../../../constants/eventTrackerMessage';
import ExtractionPageValidationTour from './ExtractionPageValidationTour';
import { isDocumentValidatedSelector, readOnlyAccessSelector } from '../../../store/selectors';

function ExtractionPageView({
  document,
  project,
  startTour,
  store,
  router,
  isDocumentValidated,
  eventTrackerService,
  readOnlyAccess
}) {
  const [showSubHeader, setShowSubHeader] = useState(true);
  const [showTour, setShowTour] = useState(startTour);
  const [showDocumentValidationTour, setShowDocumentValidationTour] = useState(!isDocumentValidated)

  const closeTour = useCallback(() => {
    setShowTour(false);
    store.dispatch(markFirstTourDone('extractionPage'));
  }, []);

  const closeDocumentValidationTour = useCallback(() => {
    setShowDocumentValidationTour(false);
  }, []);

  const onToggleSubHeader = useCallback(() => {
    setShowSubHeader(!showSubHeader);
  }, [showSubHeader]);

  return (
    <LoggedInUserPage noFooter>
      <div className="vertical-section">
        <DocExtractionComponent
          document={document}
          router={router}
          project={project}
          showSubHeader={showSubHeader}
          setShowTour={setShowTour}
          onToggleSubHeader={onToggleSubHeader}
          readOnlyAccess={readOnlyAccess}
        />
      </div>
      <ExtractionPageTour
        show={showTour}
        onClose={closeTour}
        document={document}
        project={project}
      />
    </LoggedInUserPage>
  );
}

const mapStateToProps = (state) => ({
  startTour: false,
  isDocumentValidated: isDocumentValidatedSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state)
});

export default connect(mapStateToProps)(
  withServices('store', 'router', 'eventTrackerService')(ExtractionPageView)
);
