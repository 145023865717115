import React from 'react';
import { get } from 'lodash';
import { appendClassName } from '../../../utils/utils';
import style from './ProjectFormComponent.module.scss';

const placeSearchResult = (item, isHighlighted) => (
  <div 
    className={appendClassName(isHighlighted ? style.autocompleteHighlight: style.autocompleteBg, style.autocomplete)} 
    key={item.place_id}
  >
    <div>
      {get(item, 'structured_formatting.main_text')}
    </div>
    <div className={style.helpText}>
      {get(item, 'structured_formatting.secondary_text')}
    </div>
  </div>
)

export default placeSearchResult;
