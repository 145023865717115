import { setCategories, setVideoList } from '../store/videoTutorial';

class VideoTutorialsService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  async listVideoCategories() {
    const videoCategories = await this.apiClient.get(
      '/videoTutorial/categories'
    );
    this.store.dispatch(setCategories(videoCategories.response));
    return videoCategories;
  }

  async createVideoTutorial(values) {
    const videoTutorial = await this.apiClient.post(
      '/admin/videoTutorial',
      { videoTutorial: values.videoTutorial },
    );

    await this.loadVideoTutorials();
    return videoTutorial;
  }

  async deleteVideoTutorial(values) {
    const videoTutorial = await this.apiClient.delete(
      '/admin/videoTutorial',
      { id: values.uuid },
    );

    await this.loadVideoTutorials();
    return videoTutorial;
  }

  async updateVideoTutorial(values) {
    const videoTutorial = await this.apiClient.put(
      '/admin/videoTutorial',
      { videoTutorial: values.videoTutorial },
    );

    await this.loadVideoTutorials();
    return videoTutorial;
  }

  async loadVideoTutorials() {
    const result = await this.apiClient.get('/videoTutorial');
    const videoTutorialsList = result.response;
    this.store.dispatch(setVideoList(videoTutorialsList));
    return videoTutorialsList;
  }
}

export default VideoTutorialsService;
