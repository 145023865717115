import { createDuck } from 'reaf';

const initialState = {
  isProjectListView: true
};

const [
  { updateProjectView },
  reducer
] = createDuck('autouw/ui', {
  _initialState: initialState,

  updateProjectView: (isProjectListView) => state => ({
    ...state,
    isProjectListView
  })
});

export {
  updateProjectView
};

export default reducer;
