import React from 'react';
import { appConfig } from '../../../config';
import style from './RouteNotFound.module.scss';

export default function NotFound() {
  return (
    <div className={style.notFoundWrapper}>
      <div className={style.notfound}>
        <div className={`${style.notFoundInner}`}>
          <h1>Oops!</h1>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <h2>404 - The Page can't be found</h2>
        </div>
        <a href={appConfig.completeUrl}>Go TO Homepage</a>
      </div>
    </div>
  );
}
