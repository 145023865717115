import React from 'react';
import Card from 'react-bootstrap/Card';
import ItemWithContextMenu from '../ItemTile/ItemWithContextMenu';
import LargeTile from '../ItemTile/LargeTile';
import DocumentContextMenu from '../DocumentContextMenu/DocumentContextMenu';
import DocumentsService from '../../../services/DocumentsService';
import { ExtractionJobStatus, DocumentFileTypes as DOC_TYPES, FILE_TYPES } from '../../../constants';
import style from './DocumentTile.module.scss';

function formatDate(str) {
  const date = new Date(str);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

function DocumentTile({
  document,
  onDownloadCSV,
  onDelete,
  onClearTagging,
  onOpen,
  onRename,
  onClick,
  selected,
  onNeedSupport,
  ...restProps
}) {
  const docType = DocumentsService.getDocumentType(document);
  const icon = FILE_TYPES[docType] ? FILE_TYPES[docType].icon : '';
  const documentType = document.taggingData && document.taggingData.documentType ? 
    document.taggingData.documentType : 
    DOC_TYPES.NONE.key;
  const tlBadgeText = documentType !== DOC_TYPES.NONE.key ? DOC_TYPES[documentType].badgeText : '';
  const { taggingData: { periodFrom, periodTo, asOnDate } } = document.taggingData ? document : { taggingData: {} };

  let trIcon;
  if (document.extractionJob && document.extractionJob.status) {
    trIcon = ExtractionJobStatus[document.extractionJob.status].icon;
  }
  return (
    <ItemWithContextMenu
      TileComponent={() => (
        <LargeTile
          {...restProps}
          name={document.fileName}
          icon={icon}
          onDoubleClick={onOpen}
          onClick={onClick}
          selected={selected}
          tlBadgeText={tlBadgeText}
          trIcon={trIcon}
        />
      )}
      ContextMenuComponent={({ hideContextMenu }) => (
        <DocumentContextMenu
          document={document}
          hideContextMenu={hideContextMenu}
          onDownloadCSV={onDownloadCSV}
          onDelete={onDelete}
          onNeedSupport={onNeedSupport}
          onClearTagging={onClearTagging}
          onOpen={onOpen}
          onRename={onRename}
        />
      )}
      InfoComponent={
        () => (
          <Card className={`bg-secondary text-light ${style.tileTooltip}`}>
            <Card.Body className={`p-1 small ${style.tileInfo} ${style.cardBody}`}>
              <div 
                className={`font-weight-bold border-bottom mb-2 word-wrap-normal ${style.heading}`}
              >
                {document.fileName}
              </div>

              <div>
                <strong>Created: </strong>
                <span>{formatDate(document.createdAt)}</span>
              </div>
              <div className="mb-2">
                <strong>Updated: </strong>
                <span>{formatDate(document.updatedAt)}</span>
              </div>
              {
                (documentType === DOC_TYPES.OPERATING_STATEMENT.key ||
                  documentType === DOC_TYPES.OS_BUDGETED.key) && (
                  <>
                    <div>
                      <strong>Period From: </strong>
                      <span>{formatDate(periodFrom)}</span>
                    </div>
                    <div className="mb-2">
                      <strong>Period To: </strong>
                      <span>{formatDate(periodTo)}</span>
                    </div>
                  </>
                )
              }
              {
                documentType === DOC_TYPES.RENT_ROLL.key && (
                <>
                  <div className="mb-2">
                    <strong>As On Date: </strong>
                    <span>{formatDate(asOnDate)}</span>
                  </div>
                </>
                )
              }

              {
                document.extractionJob &&
                (
                  <>
                    {
                      document.extractionJob.createdAt && (
                        <div>
                          <strong>Last Conversion: </strong>
                          <span>{formatDate(document.extractionJob.createdAt)}</span>
                        </div>
                      )
                    }
                    {
                      document.extractionJob.status && (
                        <div>
                          <strong>Conversion Result: </strong>
                          <span>{document.extractionJob.status}</span>
                        </div>
                      )
                    }
                  </>
                )
              }

            </Card.Body>
          </Card>
        )
      }
    />
  );
}

export default DocumentTile;
