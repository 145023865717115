import { createDuck } from 'reaf';

const initialState = {
  show: false,
  loadingPercent: 0,
};

const [
  { showBackdropWithProgress, hideBackdropWithProgress, setLoadingPercent },
  reducer
] = createDuck('autouw/loadingbackdropWithProgress', {
  _initialState: initialState,

  showBackdropWithProgress: (loadingPercent) => state => ({
    ...state,
    show: true,
    loadingPercent,
  }),

  hideBackdropWithProgress: () => state => ({
    ...state,
    show: false,
    loadingPercent: 0,
  }),

  setLoadingPercent: (loadingPercent) => state => ({
    ...state,
    loadingPercent
  })
});

export {
  showBackdropWithProgress,
  hideBackdropWithProgress,
  setLoadingPercent
};

export default reducer;
