import React from 'react';
import ReactTour from 'reactour';
// import ReactJoyride from 'react-joyride';

function Tour(props) {
  return (
    <ReactTour {...props} />
  );
}


// function Joyride({
//   steps, isOpen, onRequestClose, rounded, accentColor, startAt, ...rest
// }) {
//   const s = steps.map(s => ({target: s.selector, content: s.content}));

//   return (
//     <ReactJoyride steps={s} {...rest} disableOverlay />
//   );
// }

export default Tour;
