import React, { useMemo } from 'react';
import style from './VimeoPlayer.module.scss';

const VimeoPlayer = ({ video }) => {
  const src = useMemo(
    () =>
      `https://player.vimeo.com/video/${video.videoId}?autoplay=1&title=0&byline=0&portrait=0`,
    [video.videoId]
  );
  return (
    <>
      <div className={style.videoWrapper}>
        <iframe
          title="Vimeo Player"
          src={src}
          className={style.iframe}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
         />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  );
};

export default VimeoPlayer;
