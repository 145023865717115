import React, { useMemo, useState, useCallback } from 'react';
import { Form, Field } from 'formik';
import { connect } from 'react-redux';
import { pick, get } from 'lodash';
import ReactSelect from 'react-select';
import {
  Col, Form as BSForm
} from 'react-bootstrap';
import { withServices } from 'reaf';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import FormikTextField from '../../core/FormikTextField/FormikTextField';
import { EXCLUDED_TEMPLATES as excludedTemplates, TEMPLATE_TAG } from '../../../constants';
import { authSelector, companyTemplateModelSelector, dynamicAssetTypesListSelector } from '../../../store/selectors';
import { getLocationDetails, isFreeBieApp, validateRequiredField } from '../../../utils/utils';
import style from './ProjectFormComponent.module.scss';
import placeSearchResult from './placeSearchResult';
import config from '../../../config'
import Autocomplete from '../../core/Autocomplete/Autocomplete';

const menuStyle = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  zIndex: '999',
  maxHeight: '50%',
};

const addressTextKey = 'name';

const { shouldShowGoogleApiField } = config.featureFlags;
const ProjectAddresDetailFormComponents = () => {
  if(!shouldShowGoogleApiField) {
    return null;
  }
  return (
  <div className={style.addressWrapper}>
    <fieldset>
      <FormikTextField
        label="Property Address"
        placeholder="Enter Property Address"
        name="address.formattedAddress"
      />
      <FormikTextField
        hidden
        label="Google Place Id"
        placeholder="Enter Google Place Id"
        name="address.googlePlaceId"
      />
      <FormikTextField
        hidden
        label="Lattitude"
        placeholder="Enter Lattitude"
        name="address.lat"
      />
      <FormikTextField
        hidden
        label="Longitude"
        placeholder="Enter Longitude"
        name="address.lng"
      />

      <FormikTextField
        label="City"
        placeholder="Enter City"
        name="address.city"
      />

      <BSForm.Row className="form-group">
        <Col>
          <FormikTextField
            label="State"
            placeholder="Enter State"
            name="address.state"
          />
        </Col>
        <Col>
          <FormikTextField
            label="Zip"
            type="text"
            placeholder="Enter Zip Code"
            name="address.zip"
          />
        </Col>
      </BSForm.Row>
    </fieldset>
  </div>)
};

function ProjectFormComponent({
  status, isSubmitting, bindSubmission, project, submitForm, templates = [], values, auth, 
  placesService, templateWithModels, dynamicAssetTypes, ...form
}) {
  const defaultSelectedTemplate = values.templateUuid ? { value: values.templateUuid, label: templateWithModels[values.templateUuid].templateName } : '';
  const [selectedTemplate, setSelectedTemplate] = useState(defaultSelectedTemplate);
  const [places, setPlaces] = useState([]);
  const onChangeInput = useCallback((searchText) => {
    form.setFieldValue(addressTextKey, searchText);
    placesService.autocomplete(searchText).then(setPlaces)
  }, [placesService, form.setFieldValue]);
  
  const onSelectPlace = useCallback(async (placeId) => {
    const placeDetails =  await placesService.getDetailsByPlaceId(placeId);
    form.setFieldValue(addressTextKey, placeDetails.name);
    const details = getLocationDetails(placeDetails);
    Reflect.ownKeys(details).forEach(key => form.setFieldValue(`address.${key}`, details[key]))
  }, [placesService, form.setFieldValue]);

  bindSubmission(submitForm, isSubmitting);
  const allowedAssetTypes = useMemo(() => pick(dynamicAssetTypes.reduce((assetTypesObj, assetType)=>{
    assetTypesObj[assetType.assetType]= {
      key: assetType.assetType,
      label: assetType.label,
      badgeText: assetType.badgeText
    };
    return assetTypesObj
  }, {}), auth.company.assetTypes), [dynamicAssetTypes, auth]);

  const defaultSelectedAssetType = values.assetType ? { value: values.assetType, label: allowedAssetTypes[values.assetType].label } : '';

  const [selectedAssetTypes, setSelectedAssetTypes] = useState(defaultSelectedAssetType);
  const filteredTemplates = useMemo(() => templates
    .filter(template => !Reflect.ownKeys(excludedTemplates)
    .includes(template.uuid) && (!template.assetTypes || template.assetTypes.indexOf(values.assetType) !== -1))
    .filter((template) => 
      (values.project.templateUuid ? templateWithModels[values.project.templateUuid].tag === template.tag : true))
    .filter((template) => 
      ((values.project.templateUuid && 
        templateWithModels[values.project.templateUuid].keysToMask.length === 0) ? 
        template.keysToMask.length === 0 : true)),
  [templates, values]);

  return (
    <div id="ProjectFormComponent">

      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <Form>

        {
          !shouldShowGoogleApiField
            ? (
              <div id="ProjectNameField" className="form-group">
                <label htmlFor="name">Property Name</label>
                <Field id="name" className="form-control" name="name" type="text" />
                <FieldErrorMessage name="name" />
              </div>
            )
          : (
            <div id="ProjectNameField" className="form-group">
              <label htmlFor="name">Property Name</label>
              <Autocomplete
                value={get(values, addressTextKey, '')}
                itemToString={(item) => item ? get(item, 'structured_formatting.main_text') : get(values, addressTextKey, '')}
                inputProps={{ className: 'filter text-filter form-control modelUsedFilter', placeholder: "Property name" }}
                renderInput={(props) => (<input placeholder="Property name" {...props} />)}
                items={places}
                menuStyle={menuStyle}
                wrapperStyle={{ display: 'unset' }}
                renderItem={(item, isHighlighted) => placeSearchResult(item, isHighlighted)}
                onChange={(value) => onChangeInput(value)}
                onSelect={(item, { clearSelection }) => {
                  if (item) {
                    const { place_id: placeId } = item;
                    onSelectPlace(placeId);
                    clearSelection();
                  }
                }}
              />
              <FieldErrorMessage name="name" />
            </div>
          )
        }
        <div id="AssetTypeField" className="form-group">
          <label htmlFor="assetType">Asset Type</label>
          <ReactSelect
            placeholder="Select Asset Type..." value={selectedAssetTypes} name="assetType" options={Reflect.ownKeys(allowedAssetTypes).map(key => ({ value: key, label: allowedAssetTypes[key].label }))}
            isDisabled={(values.project.templateUuid && 
              templateWithModels[values.project.templateUuid].keysToMask.length > 0) || (
                project && project.id
              )
            }
            onChange={
              (selectedAssetTypes) => {
                setSelectedAssetTypes(selectedAssetTypes);
                form.setFieldValue('assetType', selectedAssetTypes.value);
              }
            }
          />
          <FieldErrorMessage name="assetType" />
        </div>

        {
          !isFreeBieApp && (
            <div id="ProjectTemplateField" className="form-group">
              <label htmlFor="templateUuid">Model</label>
              <ReactSelect
                placeholder="Select Model..." value={selectedTemplate} name="templateUuid" options={filteredTemplates.map(temp => ({ value: temp.uuid, label: temp.templateName }))}
                isDisabled={values.project.templateUuid &&
                  templateWithModels[values.project.templateUuid].keysToMask.length > 0}
                onChange={
                  (selectedTemplate) => {
                    setSelectedTemplate(selectedTemplate);
                    form.setFieldValue('templateUuid', selectedTemplate.value);
                  }
                }
              />
              <FieldErrorMessage name="templateUuid" />
            </div>
          )
        }
        <ProjectAddresDetailFormComponents values={values} form={form} templateWithModels={templateWithModels} />
        <div id="projectNotes" className="form-group mt-3">
          <label htmlFor="name">Property Note</label>
          <Field id="notes" className="form-control" as="textarea" name="notes" type="text" placeholder="Add property note" />
          <FieldErrorMessage name="notes" />
        </div>
      </Form>
    </div>
  );
}

const mapStateToProps = state => ({
  auth: authSelector(state),
  templateWithModels: companyTemplateModelSelector(state),
  dynamicAssetTypes: dynamicAssetTypesListSelector(state)
});
export default withServices('placesService')(connect(mapStateToProps)(ProjectFormComponent));
