import React from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Container, Row, Col } from 'react-bootstrap';
import {
  currentCompanySelector,
  currentTemplateSelector,
  companyTemplatesList,
  companiesDetailsSelector
} from '../../../store/selectors';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import TemplateModelList from '../../app/TemplateModelList/TemplateModelList';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';

function TemplateModelListPageHeader({
  companyUUID, templateUUID, companiesDetails, companyTemplates
}) {
  return (
    <Container fluid className="p-2 light border-bottom-light-blue sa-breadcrumb">
      <Row className="align-items-center">
        <Col>
          <Breadcrumbs
            rootRoute="superAdminHomePage"
            items={[
              { route: 'superAdminPage', content: 'Companies' },
              { route: 'superAdminPage', content: companiesDetails[companyUUID].name, params: {
                '_search[name]': companiesDetails[companyUUID].name
              } },
              { route: 'superAdminTemplateManagement', params: { companyUUID }, content: companyTemplates[templateUUID].templateName },
              { to: '#', content: 'Models' }]} />
        </Col>
      </Row>
    </Container>
  );
}

function TemplateModelListPage({
  companyUUID, templateUUID, companiesDetails, companyTemplates, isSuperAdmin
}) {
  return (
    <>
    {isSuperAdmin ? (
      <SuperAdminDashboard>
      <TemplateModelListPageHeader
        companyUUID={companyUUID} templateUUID={templateUUID}
        companiesDetails={companiesDetails} companyTemplates={companyTemplates} />
      <TemplateModelList />
    </SuperAdminDashboard>
    ) :
    <LoggedInUserPage noFooter>
      <Container fluid className="m-0 vertical-section">
        <Row className="vertical-section">
          <Col className="vertical-section p-0">
            <div className="fill-parent overflow-auto p-3">
              <TemplateModelList />
            </div>
          </Col>
        </Row>
      </Container>
    </LoggedInUserPage>
    }
    </>
  );
}
const mapStateToProps = state => ({
  companyUUID: currentCompanySelector(state),
  templateUUID: currentTemplateSelector(state),
  companyTemplates: companyTemplatesList(state),
  companiesDetails: companiesDetailsSelector(state)
});
export default connect(mapStateToProps)(withServices('templateModelService')(TemplateModelListPage));
