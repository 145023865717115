import React, { useCallback } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { faFileAlt, faFolder, faFolderOpen, faVideo } from '@fortawesome/free-solid-svg-icons';
import { authSelector, userRoleSelector } from '../../../store/selectors';
import { allowUserToUpdateTemplate } from '../../../store/companyUsers';
import avatarFile from '../../../assets/images/avatar.svg';
import clikLogoFile from '../../../assets/images/logo-symbol.png';
import Link from '../../core/Link/Link';
import style from './UserHeader.module.scss';
import { USER_ROLES } from '../../../constants';
import { usersWithTemplateAccess } from '../../../config';
import {
  SpriteSettingsIcon,
  SpriteLogoutIcon
} from '../../core/SpriteIcon/SpriteIcon';
import { ClikLogoClicked } from '../../../constants/eventTrackerMessage';
import config from '../../../config';

export class UDWrapper extends React.Component {
  render() {
    return (
      <div onClick={this.props.onClick} className="clearfix cursor-pointer">
        {this.props.children}
      </div>
    );
  }
}

function UserHeader({
  userName,
  userRole,
  companyName,
  companyUuid,
  authService,
  router,
  store,
  eventTrackerService,
  hideMenus = false,
  userEmail
}) {
  const logout = useCallback(() => authService.logout(), []);
  const logoRoute = authService.resolveRoute();
  const onClickLogoClick = useCallback(() => {
    eventTrackerService.track(ClikLogoClicked);
  }, []);

  const allowRecentProjectDocs = React.useMemo(() => 
      userRole === USER_ROLES.SUPPORT_USER.key && companyUuid === config.app.recentProjectsAndDocsCompanyUuid, 
  [userRole, companyUuid])

  const allowUpdateTemplate = React.useMemo(() => {
    if(usersWithTemplateAccess.includes(userEmail)) {
      store.dispatch(allowUserToUpdateTemplate(true))
      return true;
    }
    return false;
  }, [userEmail, companyUuid]);

  return (
    <header
      className={
        `navbar navbar-expand navbar-dark justify-content-between bd-navbar bg-dark text-white ${style.header}`
      }
    >
      <Link
        route={logoRoute}
        className={`text-white ${style.navbarBrand}`}
        onClick={onClickLogoClick}
      >
        <img src={clikLogoFile} alt="Clik Logo" />
        <span className="ml-2">
          <small>
            <strong>{companyName}</strong>
          </small>
        </span>
      </Link>
      <ul
        className={`navbar-nav align-items-center ml-md-auto ${style.accountHeader}`}
      >
        <li className="nav-item pl-2">
          <Dropdown className={style.avatar}>
            <Dropdown.Toggle as={UDWrapper}>
              <div className={`clearfix ${style.userBdrHover}`}>
                <span className={style.avatarIcon}>
                  <img src={avatarFile} alt="User avatar" />
                </span>
                <span>
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={style.dropdownMenuRight}>
              <div className={`mt-n1 ${style.clikUserName}`}>
                <span className={style.userNameText}>{userName}</span>
                <small className="text-secondary">
                  {userRole ? USER_ROLES[userRole].label : ''}
                </small>
              </div>
              <div className="dropdown-divider" role="separator" />
              {
                allowRecentProjectDocs && (
                  <>
                    <Dropdown.Item
                      onClick={() =>
                        router.setRoute('recentProjects')
                      }
                    >
                      <span className={`${style.videoIcon}`}>
                        <FontAwesomeIcon icon={faFolderOpen} />
                      </span>
                      Recent Projects
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        router.setRoute('recentTaggedDocuments')
                      }
                    >
                      <span className={`${style.videoIcon} mr-1`}>
                        <FontAwesomeIcon icon={faFileAlt} />
                      </span>
                      Recent Documents
                    </Dropdown.Item>
                  </>
                )
              }
              {
                allowUpdateTemplate && (
                  <Dropdown.Item
                    onClick={() =>
                      router.setRoute('addUpdateCompanyTemplateList', { companyUuid })
                    }
                  >
                    <span className={`${style.videoIcon}`}>
                      <FontAwesomeIcon icon={faFolderOpen} />
                    </span>
                    Add/Update template
                  </Dropdown.Item>
                )
              }
              {
                !hideMenus && (
                  <>
                    <Dropdown.Item
                      onClick={() =>
                        router.setRoute('tutorials', { section: 'GETTING_STARTED' })
                      }
                    >
                      <span className={style.videoIcon}>
                        <FontAwesomeIcon icon={faVideo} />
                      </span>
                      Tutorials
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        router.setRoute('accountSettingsPage', { section: 'profile' })
                      }
                    >
                      <SpriteSettingsIcon />
                      Account Settings
                    </Dropdown.Item>
                </>
                )
              }

              <Dropdown.Item onClick={logout}>
                <SpriteLogoutIcon />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </header>
  );
}

const mapStateToProps = (state) => {
  const auth = authSelector(state);

  return auth.user
    ? {
        userName: [auth.user.firstName, auth.user.lastName].join(' '),
        userRole: userRoleSelector(state),
        companyName: auth.company.name,
        companyUuid: auth.company.uuid,
        userEmail: auth.user.email
      }
    : {};
};

export default connect(mapStateToProps)(
  withServices('authService', 'router', 'store', 'eventTrackerService')(UserHeader)
);
