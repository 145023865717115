import React from 'react';
import TrialUserRegistrationForm from '../../app/TrialUserRegistrationForm/TrialUserRegistrationForm';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';

const TrialUserRegistrationPage = () => (
  <LoggedOutUserPage canLogout>
    <div className="vertical-section dotted-bg bg-light">
      <TrialUserRegistrationForm />
    </div>
  </LoggedOutUserPage>
);

export default TrialUserRegistrationPage;
