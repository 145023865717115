import React, { useEffect, useCallback, useState, useMemo } from 'react';
import {
  Card, ListGroup, ListGroupItem, Spinner
} from 'react-bootstrap';
import { withServices } from 'reaf';
import { get, filter } from 'lodash';
import { displayDateFormat } from '../../../utils/utils';
import DownloadTicketAttachment from '../DownloadTicketAttachment/DownloadTicketAttachment';
import TicketStatus from '../TicketStatus/TicketStatus';
import TicketType from '../TicketType/TicketType';
import DisplayAssetType from '../DisplayAssetType/DisplayAssetType';
import DocumentListItem from './DocumentListItem';

const TicketCard = ({ ticket, onTicketUpdate, ticketService }) => {

  const [ticketWithProject, setTicketWithProject] = useState();

  const fetchTicketWithProject = useCallback(async (ticket) => {
    const ticketWithProject = await ticketService.fetchTicketWithProjectDetails(ticket);
    setTicketWithProject(ticketWithProject);
  }, []);

  useEffect(() => {
    fetchTicketWithProject(ticket);
  }, [ticket]);

  const childDocuments = useMemo(() => ticketWithProject
      && ticketWithProject.Document
      && ticketWithProject.Project
      && ticketWithProject.Project.Documents
      && filter(ticketWithProject.Project.Documents, { groupId: ticketWithProject.Document.uuid }),
  [ticketWithProject]);

  return <> {ticketWithProject ? (
    <Card>
      <Card.Body>
        <Card.Text>
          {ticketWithProject.description}
        </Card.Text>

        <ListGroup className="list-group-flush text-muted">
          {ticketWithProject.TicketAttachments && ticketWithProject.TicketAttachments.length > 0 && <ListGroup.Item>
            Attachments:
            {" "}
            {ticketWithProject.TicketAttachments.map(ticketAttachment => 
              <DownloadTicketAttachment  ticketAttachment={ticketAttachment} ticket={ticketWithProject} />)
            } 
          </ListGroup.Item>}
          <ListGroupItem>
            Type:
          {' '}
            <TicketType ticket={ticketWithProject} />
          </ListGroupItem>
          <ListGroupItem>
            Status:
          {' '}
            <TicketStatus ticket={ticketWithProject} onTicketUpdate={onTicketUpdate} />
          </ListGroupItem>
          <ListGroupItem>
            Company:
          {' '}
            {ticket?.Company?.name}
          </ListGroupItem>
          <ListGroupItem>
            Author:
          {' '}

            {}
            {ticketWithProject.User ? `${get(ticketWithProject, 'User.firstName', '')} ${get(ticketWithProject, 'User.lastName', '')} ${get(ticketWithProject, 'User.email', '')}` : 'User Deleted'}
          </ListGroupItem>
          <ListGroupItem>
            Project:
          {' '}
            {get(ticketWithProject, 'Project.name', 'Deleted')}
            {' '}
          </ListGroupItem>
          <ListGroupItem>
            Asset Type:
          {' '}
            <DisplayAssetType AssetType={get(ticketWithProject, 'Project.assetType', 'Project Deleted')} />
            {' '}
          </ListGroupItem>
          <ListGroupItem>
            Template Name:
          {' '}
            {get(ticketWithProject, 'Project.TemplateMapping.templateName', 'Project Deleted')}
            {' '}
          </ListGroupItem>
          <ListGroupItem>
            Last update on:
          {' '}
            {displayDateFormat(ticketWithProject.updatedAt)}
          </ListGroupItem>

          {
            ticketWithProject.documentId && ticketWithProject.documentId !== 'null' && !ticketWithProject.Document && <ListGroupItem><span>Document Deleted!</span></ListGroupItem>
          }

          {ticketWithProject.Document && (
            <ListGroupItem>
              <DocumentListItem document={ticketWithProject.Document} ticket={ticketWithProject} />
            </ListGroupItem>
          )}

          {childDocuments && childDocuments.length > 0 && childDocuments.map(document => (
            <ListGroupItem>
              <DocumentListItem document={document} ticket={ticketWithProject} />
            </ListGroupItem>
          ))}

          {!ticketWithProject.Document && ticketWithProject.Project && ticketWithProject.Project.Documents && 
          ticketWithProject.Project.Documents.map(document => (
            <ListGroupItem>
              <DocumentListItem document={document} ticket={ticketWithProject} />
            </ListGroupItem>
          ))}

        </ListGroup>
      </Card.Body>
    </Card>
  ) : <Spinner />

  }</>
};

export default withServices('ticketService')(TicketCard);
