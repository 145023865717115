import React from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { withServices } from 'reaf';
import CompanyFormComponent from './CompanyFormComponent';
import { PASSWORD_SCHEMA } from '../../../constants';
import companyFormSchema from './companyFormSchema';
import { dynamicAssetTypesListSelector } from '../../../store/selectors';

const CompanyFormSchema = (dynamicAssetTypes) => Yup.object().shape({
  company: companyFormSchema(dynamicAssetTypes),
  admin: Yup.object().shape({
    firstName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid first name.'),
    lastName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid last name.'),
    email: Yup.string().email('Please provide a valid email.').required('Please provide a valid email'),
    password: PASSWORD_SCHEMA
  })
});

function CompanyForm({
  onSubmitSuccess, bindSubmission, company, companyService, dynamicAssetTypes
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      const { company: createdCompany } = await companyService.upsertCompany(company, values);
      onSubmitSuccess(createdCompany);
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }
  return (
    <Formik
      initialValues={{ 
        company: { 
          ...company.company, 
          paymentDueDate: '', 
          trialDays: 7, 
          isEnterpriseCompany: true, 
          isTrialOffering:false, 
          allowProjectJson: false,
          canDownloadReportWorkbook: true,
          canDownloadModel: true,
          canShareReport: true,
          OSMonthsToHide: 3,
          isMultifactorAuthEnabled: false,
          canEditProjectDetails: true,
          isBetaParserEnabled: false
        }, 
        admin: company.admin 
      }}
      validationSchema={CompanyFormSchema(dynamicAssetTypes)}
      onSubmit={onSubmit}
      component={formikProps => <CompanyFormComponent {...formikProps} bindSubmission={bindSubmission} />
      }
    />
  );
}

const mapStateToProps = state => ({
  dynamicAssetTypes: dynamicAssetTypesListSelector(state) || []
});

export default connect(mapStateToProps)(withServices('companyService')(CompanyForm));
