import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import { currentCompanySelector } from '../../../store/selectors';
import ProjectExporterFormComponent from './ProjectExporterFormComponent';
import messages from '../../../../locales';

const ProjectExporterFormSchema = Yup.object().shape({
  projectExporter: Yup.object().shape({
    name: Yup.string().trim().transform(value => (value === '' ? undefined : value))
      .required('Please enter a valid property exporter name.')
      .min(3, 'Name has to be at least 3 characters long.')
  })
});

function ProjectExporterForm({
  onSubmitSuccess, bindSubmission, projectExporter, projectExporterService, companyUUID, toastrService
}) {
  const [mapperFile, setMapperFile] = useState(null);
  const [mappingFunctionFile, setMappingFunctionFile] = useState(null);
  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      if (!mapperFile || !mappingFunctionFile) {
        throw new Error('Mapper and Mapping Function files must be attached.');
      }
      await projectExporterService.createProjectExporter(companyUUID, {
        ...values, mapperFile, mappingFunctionFile
      });
      onSubmitSuccess();
      toastrService.success(messages.toastMessage.CREATE_PROJECT_EXPORTER);
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }, [companyUUID, onSubmitSuccess, mapperFile, mappingFunctionFile, projectExporterService]);

  return (
    <Formik
      initialValues={projectExporter}
      validationSchema={ProjectExporterFormSchema}
      onSubmit={onSubmit}
      component={formikProps => (
        <ProjectExporterFormComponent
          {...formikProps}
          projectExporter={projectExporter}
          bindSubmission={bindSubmission}
          mapperFileName="mapperFile"
          selectedMapperFile={mapperFile}
          onMapperFileChange={e => setMapperFile(e.target.files[0])}
          onRemoveMapperFile={() => setMapperFile(null)}
          mappingFunctionFileName="mappingFunctionFile"
          selectedMappingFunctionFile={mappingFunctionFile}
          onMappingFunctionFileChange={e => setMappingFunctionFile(e.target.files[0])}
          onRemoveMappingFunctionFile={() => setMappingFunctionFile(null)}
        />
      )}
    />
  );
}

const mapStateToProps = state => ({
  companyUUID: currentCompanySelector(state)
});

export default connect(mapStateToProps)(withServices('projectExporterService', 'toastrService')(ProjectExporterForm));
