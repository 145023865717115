import React, { useCallback, useState, useMemo } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../core/Button/IconButton';
import style from './ProspectPaymentLinksList.module.scss';
import './ProspectPaymentLinksList.scss';
import PaymentLinkPopup from '../PaymentLinkPopup/PaymentLinkPopup';
import { getAllPaymentLinks } from '../../../store/selectors';

function ProspectPaymentLinksList({ links }) {
  const rowStatusBackgroundColors = {
    default: '#DCDCDC',
    open: '#FFFFE0',
    paid: '#90EE90',
    void: '#DCDCDC'
  };

  const regexFilter = useMemo(() => ({
    type: 'RegexFilter',
    placeholder: 'Filter',
    delay: 1000
  }), []);

  function rowStyleFormat(row) {
    return { 
      backgroundColor: row && rowStatusBackgroundColors[row.status] ? 
      rowStatusBackgroundColors[row.status] : 
      rowStatusBackgroundColors.default 
    };
  }

  const initialValues = {
    name: "",
    amount: "",
    quantity: ""
  };

  const [paymentLink, setPaymentLink] = useState(initialValues);
  const [showCreatePaymentLinkPopup, setShowCreatePaymentLinkPopup] = useState(false);

  const hidePopup = useCallback(
    () => {
      setShowCreatePaymentLinkPopup(false);
    },
    [],
  );

  const onAddPaymentLink = useCallback(() => {
    setPaymentLink(initialValues);
    setShowCreatePaymentLinkPopup(true);
  }, [initialValues]);

  return (
    <>
      <PaymentLinkPopup
        onHide={hidePopup}
        showCreatePaymentLinkPopup={showCreatePaymentLinkPopup}
        paymentLink={paymentLink}
      />
      <Container fluid className={`p-2 pb-3 ${style.projectsSubHeader}`}>
        <Row className="align-items-center">
          <Col sm="auto">
            <h5 className={`${style.headingAligment}`}>
              Payment links
            </h5>
          </Col>
          <Col sm="auto" className="pl-0 tourIconBtn">
            <IconButton
              title="Add Payment Link" className="btn-circle btn-shadow" variant="outline-secondary" size="sm" icon={faPlus} onClick={e => {
                onAddPaymentLink();
              }} id="AddPaymentLinkButton" />
          </Col>
        </Row>
      </Container>
      <div className="prospectInvoicesTable">
        <BootstrapTable
          containerClass="bootstrapTableCustom"
          data={links}
          tableStyle={{ background: '#fafafa' }}
          trStyle={rowStyleFormat}
          condensed
          hover
          pagination
          version="4"
        >
          <TableHeaderColumn
            dataField="id"
            isKey
            dataSort
            dataAlign="center"
          >
        ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="metadata"
            dataSort
            dataAlign="center"
            dataFormat={(metadata) => metadata.price}
          >
        AMOUNT
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="url"
            // dataFormat={displayInvoiceNumber}
            filter={regexFilter}
          >
        URL
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="active"
            // dataFormat={displayCurrencyFormat}
            dataSort
            width="100"
          >
        ACTIVE
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  links: getAllPaymentLinks(state)
});

export default connect(mapStateToProps)(
  ProspectPaymentLinksList
);

