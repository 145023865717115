import React from 'react';
import Tour from 'reactour';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { DocumentFileTypes, sheetMappings } from '../../../constants';
import style from './ExtractionPageTour.module.scss';
import { dynamicAssetTypesAsObjSelector } from '../../../store/selectors';

const [SOURCE_SHEET, TARGET_SHEET, WORKING_SHEET, SUMMARY_SHEET] = sheetMappings;

// TODO: Need to update the logic becuase code will break if new assetTypes are added.
const extractionPageOSIntroTour = [
  {
    selector: '#sdadadasd',
    content: ({ goTo }) => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>
          This guide will walk you through various elements on the page.
          Please click the start button or use arrow keys to see next item.
        </p>
        <p>
          You can click the close button at any step to get out of this guide.
        </p>
        <p>
          <Button size="sm" variant="info" onClick={() => goTo(1)}>Start</Button>
        </p>
      </>
    )
  },
  {
    selector: 'canvas#null_tabStrip',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>
          <strong>{SOURCE_SHEET.displayName}: </strong>
Contains the table data just as extracted from the source document.
        </p>
        <p>
          <strong>{TARGET_SHEET.displayName}: </strong>
Contains the cleaned extracted data, each row representing a line item information.
        </p>
        <p>
          <strong>{WORKING_SHEET.displayName}: </strong>
A temporary sheet for any intermediate calculations.
        </p>
        <p>
          <strong>{SUMMARY_SHEET.displayName}: </strong>
Shows a quick summary of operating statements for validation.
        </p>
      </>
    )
  },
]

const extractionPageRentRollIntroTour = [
  {
    selector: '#sdadadasd',
    content: ({ goTo }) => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>
          This guide will walk you through various elements on the page.
          Please click the start button or use arrow keys to see next item.
        </p>
        <p>
          You can click the close button at any step to get out of this guide.
        </p>
        <p>
          <Button size="sm" variant="info" onClick={() => goTo(1)}>Start</Button>
        </p>
      </>
    )
  },
  {
    selector: 'canvas#null_tabStrip',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>
          <strong>{SOURCE_SHEET.displayName}: </strong>
Contains the table data just as extracted from the source document.
        </p>
        <p>
          <strong>{TARGET_SHEET.displayName}: </strong>
Contains the cleaned extracted data, each row representing a tenant information.
        </p>
        <p>
          <strong>{WORKING_SHEET.displayName}: </strong>
A temporary sheet for any intermediate calculations.
        </p>
      </>
    )
  },
]


const extractionPageCommonTour = [
  {
    selector: '.extractionBreadcrumb',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click here anytime to go back to the documents view.</p>
      </>
    )
  },
  {
    selector: '#DownloadXLSXFile',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to download workbook with the extracted data</p>
      </>
    )
  },
  {
    selector: '#SaveXLSXFile',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to save the workbook</p>
      </>
    )
  },
  {
    selector: '#refWindow',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Use this button to toggle the document reference window.</p>
      </>
    )
  },
  {
    selector: '#keyboardShortcuts',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to see the shortcut keys for extraction view</p>
      </>
    )
  },
  {
    selector: '#SSCellBox',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Shows current selection in spread sheet</p>
      </>
    )
  },
  {
    selector: '#SSFormulaBox',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Use this area to edit cell values and Excel like functions</p>
      </>
    )
  }
];

const rentRollTour = onClose => [
  ...extractionPageCommonTour,
  {
    selector: '#asdasdasdasdasd',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>
          You may now end the guide.
        </p>
        <Button size="sm" variant="warning" onClick={onClose}>End Guide</Button>

      </>
    ),
    action: node => node && node.focus()
  }
];

const rentRollMFTour = onClose => [
  ...extractionPageRentRollIntroTour,
  ...extractionPageCommonTour,
  {
    selector: '#configCC',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to configure charge codes</p>
      </>
    )
  },
  {
    selector: '#unitStatusTabButton',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to configure unit status</p>
      </>
    )
  },
  {
    selector: '#floorPlanTabButton',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to configure floor plan</p>
      </>
    )
  },
  {
    selector: '#analysisTabButton',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to view lease trend analysis</p>
      </>
    )
  },
  {
    selector: '#asdasdasdasdasd',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>
          You may now end the guide.
        </p>
        <Button size="sm" variant="warning" onClick={onClose}>End Guide</Button>

      </>
    ),
    action: node => node && node.focus()
  }
];

const retailRentRollTour = onClose => [
  ...extractionPageRentRollIntroTour,
  ...extractionPageCommonTour,
  {
    selector: '#configCC',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to configure charge codes</p>
      </>
    )
  },
  {
    selector: '#occupancyTabButton',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to configure units status</p>
      </>
    )
  },
  {
    selector: '#leaseTypeTabButton',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to configure lease type</p>
      </>
    )
  },
  {
    selector: '#tenantTypeTabButton',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to configure lease tenant type</p>
      </>
    )
  },
  {
    selector: '#analysisTabButton',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to view lease expiration schedule</p>
      </>
    )
  },
  {
    selector: '#asdasdasdasdasd',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>
          You may now end the guide.
        </p>
        <Button size="sm" variant="warning" onClick={onClose}>End Guide</Button>

      </>
    ),
    action: node => node && node.focus()
  }
];

const operatingStatementTour = onClose => [
  ...extractionPageOSIntroTour,
  ...extractionPageCommonTour,
  {
    selector: '#osAffixButton',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>Click this button to view summary and analysis</p>
      </>
    )
  },
  {
    selector: '#asdasdasdasdasd',
    content: () => (
      <>
        <h6 className="border-bottom">Extraction Page Guide</h6>
        <p>
          You may now end the guide.
        </p>
        <Button size="sm" variant="warning" onClick={onClose}>End Guide</Button>

      </>
    ),
    action: node => node && node.focus()
  }
];

function ExtractionPageTour({
  show, onClose, document, project, dynamicAssetTypes
}) {
  const { taggingData: { documentType } } = document;
  const { assetType } = project;

  const extractionPageSteps = (onClose) => {
    if (
      documentType === DocumentFileTypes.RENT_ROLL.key && assetType === dynamicAssetTypes.MULTIFAMILY.key
    ) {
      return rentRollMFTour(onClose);
    } else if (documentType === DocumentFileTypes.RENT_ROLL.key && 
        (assetType === dynamicAssetTypes.RETAIL.key || 
          assetType === dynamicAssetTypes.OFFICE.key || 
          assetType === dynamicAssetTypes.INDUSTRIAL.key || 
          assetType === dynamicAssetTypes.WAREHOUSE.key)
      ) {
      return retailRentRollTour(onClose);
    } else if (documentType === DocumentFileTypes.RENT_ROLL.key) {
      return rentRollTour(onClose);
    } else {
      return operatingStatementTour(onClose);
    }
  };

  return (
    <Tour
      steps={extractionPageSteps(onClose)}
      isOpen={show}
      onRequestClose={onClose}
      rounded={5}
      accentColor="#2196f2"
      startAt={0}
      className={style.extractionTour}
      closeWithMask={false}
      disableInteraction={false}
    />
  );
}

const mapStateToProps = (state) => ({
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
});
export default connect(mapStateToProps)(ExtractionPageTour);
