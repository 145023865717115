import React, { useCallback, useEffect, useMemo } from 'react';
import { withServices } from 'reaf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft as backButton } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft';
import { connect } from 'react-redux';
import Link from '../../core/Link/Link';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import LightButton from '../../core/LightButton/LightButton';
import style from './TutorialsPage.module.scss';
import profileIcon from '../../../assets/images/profile.svg';
import GettingStarted from '../../app/GettingStarted/GettingStarted';
import { getVideoTutorialsCategorySelector, getVideoTutorialsListSelector } from '../../../store/selectors';

function getSection(section, router, tutorials, videoUuid) {
  if (section === 'GETTING_STARTED') {
    return (
      <GettingStarted {...{ style, tutorials, section, router, videoUuid }} />
    );
  }

  return <h1>Tutorials Page</h1>;
}

function TutorialsPage({
  section,
  router,
  videoUuid,
  videoTutorialService,
  authService,
  tutorials,
  categories,
}) {
  useEffect(() => {
    videoTutorialService.loadVideoTutorials();
    videoTutorialService.listVideoCategories();
  }, []);

  const changeSection = useCallback(
    (nextSection) => {
      router.setRoute('tutorials', { section: nextSection });
    },
    [router]
  );

  const params = useMemo(
    () => (videoUuid ? { section } : {}),
    [section, videoUuid]
  );

  const routeName = useMemo(() => authService.resolveRoute(), []);

  return (
    <LoggedInUserPage>
      <div className="horizontal-section">
        <div
          className={`vertical-section border-right p-0  flex-grow-0 ${style.sidebarLeftCol}`}
        >
          <Link
            route={videoUuid ? 'tutorials' : routeName}
            params={params}
            className={style.backButton}
          >
            <FontAwesomeIcon icon={backButton} className={style.backButtonIcon} /> {videoUuid ? 'Back' : 'Back to Application'}
          </Link>

          {
            Reflect.ownKeys(categories).map(key => (
              <LightButton
                key={key}
                customClassName={style.settingNavLinkBtn}
                variant="link"
                onClick={() => changeSection(key)}
                active={section === key}
              >
                <span className={style.navIcons}>
                  <img src={profileIcon} alt="profile" />
                </span>
                {categories[key].label}
              </LightButton>
            ))
          }
          
        </div>
        <div className="vertical-section position-relative">
          <div className={`fill-parent ${style.wrapperScroll}`}>
            {getSection(section, router, tutorials, videoUuid)}
          </div>
        </div>
      </div>
    </LoggedInUserPage>
  );
}

const mapStateToProps = (state) => ({
  tutorials: getVideoTutorialsListSelector(state),
  categories: getVideoTutorialsCategorySelector(state),
});

export default connect(mapStateToProps)(
  withServices('router', 'apiClient', 'videoTutorialService', 'authService')(TutorialsPage)
);
