import React, { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Tour from '../../core/Tour/Tour';

const addFileSteps = (onClose) => [
  {
    selector: '#sdadadasd',
    content: ({ goTo }) => (
      <>
        <h6 className="border-bottom">Add Document Tutorial</h6>
        <p>
          This tutorial will guide you through uploading documents to property.
          Please click the next button after completing each instruction to
          continue.
        </p>
        <p>
          You can click the close button on any instruction to get out of the
          tutorial.
        </p>
        <p>
          <Button size="sm" variant="info" onClick={() => goTo(1)}>
            Start
          </Button>
        </p>
      </>
    )
  },
  {
    selector: '#BrowseFileButton',
    content: () => (
      <>
        <h6 className="border-bottom">Add Document Tutorial</h6>
        <p>
          Click &nbsp;{' '}
          <i>
            <b>Browse OS and RR Files</b>
          </i>{' '}
          &nbsp; button to upload documents to property.
        </p>
      </>
    )
  },
  {
    selector: '#MaximisedUploadProgress',
    content: ({ goTo, inDOM }) => {
      if (!inDOM) {
        goTo(1);
        return null;
      }

      return (
        <>
          <h6 className="border-bottom">Add Document Tutorial</h6>
          <p>This popup shows progress of documents uploads.</p>
          <p>
            Use the minimise/maximise button to toggle the compact/full view.
          </p>
          <p>Use the close button to hide the popup</p>
          <p>Please wait till the status icon turns to a green tick.</p>
        </>
      );
    },
    action: (node) => node && node.focus()
  },
  {
    selector: '#Document-0',
    content: () => {
      document.getElementById('documentsView').scrollTop = 0;
      return (
        <>
          <h6 className="border-bottom">Add Document Tutorial</h6>
          <p>Your document is successfully uploaded.</p>
        </>
      );
    }
  },
  {
    selector: '#Document-0',
    content: () => (
      <>
        <h6 className="border-bottom">Add Document Tutorial</h6>
        <p>
          Click the document to see a preview of the document on right side of
          the page.
        </p>
      </>
    ),
    action: (node) => node && node.focus()
  },
  {
    selector: '#DocumentPreviewWrapper',
    content: ({ goTo, inDOM }) => {
      if (!inDOM) {
        goTo(4);
      }

      return (
        <>
          <h6 className="border-bottom">Add Document Tutorial</h6>
          <p>This section shows a preview of the uploaded document</p>
        </>
      );
    }
  },
  {
    selector: '.actionButton',
    content: () => {
      document.getElementsByClassName('actionButton')[0].scrollTop = 0;
      return (
        <>
          <h6 className="border-bottom">Add Document Tutorial</h6>
          <p>
            As the next step, after ending this guide click on the action icon
            to continue with document tagging.
          </p>
        </>
      );
    },
    action: (node) => node && node.focus()
  },
  {
    selector: '#asdasdasdasdasd',
    content: () => (
      <>
        <h6 className="border-bottom">Add Document Tutorial</h6>
        <p>You may end this tutorial now.</p>
        <p>
          <Button size="sm" variant="warning" onClick={onClose}>
            End Tutorial
          </Button>
        </p>
      </>
    ),
    action: (node) => node && node.focus()
  }
];

function AddFileTour({ show, onClose, step }) {
  const steps = useMemo(() => addFileSteps(onClose), [onClose]);

  return (
    <Tour
      steps={steps}
      isOpen={show}
      onRequestClose={onClose}
      rounded={5}
      accentColor="#5cb7b7"
      startAt={0}
      closeWithMask={false}
      disableInteraction={false}
      showNavigation={false}
      disableKeyboardNavigation={false}
      showButtons
      goToStep={step}
    />
  );
}

export default AddFileTour;
