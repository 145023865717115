import React from 'react';
import DynamicAssetTypesList from '../../app/DynamicAssetTypesList/DynamicAssetTypesList';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function SuperAdminDynamicAssetTypesPage() {
  return (
    <SuperAdminDashboard>
      <DynamicAssetTypesList />
    </SuperAdminDashboard>
  );
}

export default SuperAdminDynamicAssetTypesPage;