import React, { useCallback, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Row, Col, Button } from 'react-bootstrap';
import { sumBy } from 'lodash';
import DocExtractionAffixTableWrapper from '../../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import { setChargeCodeConfig } from '../../../../store/currentDocument';
import { chargeCodeConfigSelector, readOnlyAccessSelector, staticChargeCodeSelector, unitChargeCodeSelector } from '../../../../store/selectors';
import ChargeCodeOptions from './ChargeCodeOptions';
import {
  validateChargeCode, 
  DisplayAmountFormat, 
  formatAmountTitleTooltip,
  getSortedJson
} from '../../../../utils/utils';
import RentRollConfigHeader from '../../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../../DocExtractionSummary/DocExtractionWithoutSummary';
import DocExtractionAffixTotalCard from '../../DocExtractionAffixTotalCard/DocExtractionAffixTotalCard';
import { affixTotalColors } from '../../../../constants';
import style from './DocExtractionRetailChargeCodeConfig.module.scss';
import messages from '../../../../../locales/en-US';
import FormSubmitButton from '../../../core/Button/FormSubmitButton';
import { DocExtractionChargeCodeMenu } from '../../DocExtractionChargeCodeMenu/DocExtractionChargeCodeMenu';
import DocExtractionChargeCodeMergeComponent from '../../DocExtractionChargeCodeMergeComponent/DocExtractionChargeCodeMergeComponent';


function DocExtractionRetailChargeCodeConfig({
  chargeCodeConfigData, staticChargeCodes, chargeCodeUnits, store, onConfigSubmit, onSlidePaneClose,
  onMergeChargeCodeColumn, toastrService, readOnlyAccess
}) {
  const [activeChargeCodeMergeColView, setActiveChargeCodeMergeColView] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [ccConfig, setCcConfig] = useState(chargeCodeConfigData);
  const [activeTableView, setActiveTableView] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidChange, setIsValidChange] = useState(false);
  const [sortedChargeCodeConfigData, setSortedChargeCodeConfigData] = useState({});

  const {
    RENT_ROLL: {
      MULTIFAMILY: {
        CHARGE_CODE_TOTAL
      }
    }
  } = affixTotalColors;

  const onSubmit = useCallback(async (...args) => {
    const [formValue] = args;
    setIsSubmitting(true);
    store.dispatch(setChargeCodeConfig(formValue));
    await onConfigSubmit();
    setIsSubmitting(false);
    setIsValidChange(false);
  }, [onConfigSubmit, store]);

  const onChargeCodeMerge = useCallback(args => {
    setIsValidChange(true);
    onMergeChargeCodeColumn(args);
  }, [])

  useEffect(() => {
    const chargeCodeConfigValues = getSortedJson(chargeCodeConfigData, 'key', 'label');
    setSortedChargeCodeConfigData(chargeCodeConfigValues)
  }, [chargeCodeConfigData]);

  return (
    <>
      {
        Reflect.ownKeys(chargeCodeConfigData).length > 0 ? (
          <>
            <RentRollConfigHeader title="Configure Charge Code" hideChartSwitcher onTableViewSwitch={(isTableView) => setActiveTableView(isTableView)} />
            <Formik
              enableReinitialize
              onSubmit={onSubmit}
              initialValues={chargeCodeConfigData}
              render={({
                setFieldValue
              }) => (
                <Form>
                  <DocExtractionAffixTableWrapper>
                    {
                      activeTableView ? (
                        <>
                          <Row className={style.chargeCodeHeader}>
                            <Col className={`col-md-auto ${style.widthId}`}>#</Col>
                            <Col className={`col-md-auto ${style.widthChargeCode}`}>Charge Code</Col>
                            <Col className={`col-md-auto ${style.widthTotalAmount}`}>Amount</Col>
                            <Col className={`col-md-auto ${style.widthOptionDrop}`}>Category</Col>
                            <Col className={`col-md-auto ${style.widthOptionDrop}`}>Unit</Col>
                          </Row>
                          <Row className="position-relative">
                            <Col className={`${style.chargeCode} ${isMenuOpen ? `overflow-hidden` : 'overflow-auto'}`}>

                              {sortedChargeCodeConfigData.length && 
                                sortedChargeCodeConfigData.map((chargeCode, rowIndex) => (
                                  <ul key={chargeCode.key}>
                                    <li className={`col-md-auto ${style.widthId}`}>
                                      <div title={rowIndex + 1} className="w-100"><span className="text-with-ellipsis w-100 d-inline-block">{rowIndex + 1}</span></div>
                                    </li>
                                    <li className={`col-md-auto ${style.widthChargeCode}`}>
                                      <div title={validateChargeCode(chargeCode.key)} className="w-100 text-with-ellipsis">
                                          {validateChargeCode(chargeCode.key)}
                                      </div>
                                    </li>
                                    <li className={`col-md-auto ${style.widthTotalAmount}`}>
                                      <div title={formatAmountTitleTooltip(chargeCodeConfigData[chargeCode.key]?.total)} className="w-100 text-with-ellipsis">
                                          <DisplayAmountFormat number={chargeCode.total} />
                                      </div>
                                    </li>
                                    <li className={style.widthOptionDrop}>
                                      <ChargeCodeOptions
                                        chargeCodeConfigData={chargeCodeConfigData}
                                        chargeCode={chargeCode.key}
                                        staticChargeCodes={staticChargeCodes}
                                        chargeCodeUnits = {chargeCodeUnits}
                                        toastrService= {toastrService}
                                        ccConfig = {ccConfig}
                                        setCcConfig = {(config) => setCcConfig(config)}
                                        setCurrentChargeCodeCategory={(dropdownValue) => {
                                          setIsValidChange(true);
                                          setFieldValue(chargeCode.key, dropdownValue);
                                        }}
                                        onMenuClose={() => setIsMenuOpen(false)}
                                        onMenuOpen={() => setIsMenuOpen(true)}
                                      />
                                    </li>
                                    <li className={style.dottedIcon}>
                                      <DocExtractionChargeCodeMenu
                                        onMenuSelect={() => setActiveChargeCodeMergeColView(rowIndex)}
                                      />
                                    </li>
                                    {
                                      activeChargeCodeMergeColView === rowIndex && (
                                        <DocExtractionChargeCodeMergeComponent
                                          currentChargeCode={chargeCode.key}
                                          activeColumn={rowIndex}
                                          chargeCodeConfigData={chargeCodeConfigData}
                                          onClose={() => setActiveChargeCodeMergeColView(null)}
                                          onMergeClick={(args) => onChargeCodeMerge(args)}
                                        />
                                      )
                                    }
                                  </ul>
                                ))
                              }
                            </Col>
                          </Row>
                        </>
                      ) : null
                    }

                    <DocExtractionAffixTotalCard
                      cards={[
                        { title: 'Total Amount', color: CHARGE_CODE_TOTAL, content: <DisplayAmountFormat number={sumBy(Object.values(chargeCodeConfigData), 'total')} /> }
                      ]}
                    />
                  </DocExtractionAffixTableWrapper>
                  <Row className={`${style.chargeCodeFooter} ${style.footerFixedBottom}`}>
                    <Button variant="outline-secondary btn-sm" onClick={onSlidePaneClose}>Cancel</Button>
                    <FormSubmitButton
                      isValid={isValidChange && !readOnlyAccess}
                      isSubmitting={isSubmitting}
                      id="chargeCodeConfig"
                    />
                  </Row>
                </Form>
              )}
            />
          </>
        ) : <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
      }
    </>
  );
}

const mapStateToProps = state => ({
  chargeCodeConfigData: chargeCodeConfigSelector(state),
  staticChargeCodes: staticChargeCodeSelector(state),
  chargeCodeUnits : unitChargeCodeSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state)
});

export default connect(mapStateToProps)(withServices('store', 'toastrService')(DocExtractionRetailChargeCodeConfig));
