import React, { useState, useMemo, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import CompanyTemplateFormComponent from './CompanyTemplateFormComponent';
import messages from '../../../../locales';
import { TEMPLATE_TAGS as TemplateTags } from '../../../constants';
import { currentCompanySelector, companiesDetailsSelector, templateKeysToMaskSelector, dynamicAssetTypesAsObjSelector } from '../../../store/selectors';

const initialTemplate = {
  templateName: '',
  tag: TemplateTags.SERVICING.key,
  assetTypes: [],
  keysToMask: [],
  isReportEnabled: false,
  file: null
};

function CompanyTemplateForm({
  onSubmitSuccess,
  bindSubmission,
  templateService,
  companyUUID,
  companyDetail,
  keysToMask,
  dynamicAssetTypes,
  isGlobal = false
}) {
  const [templateFile, setTemplateFile] = useState(null);
  const [dictionaryFile, setDictionaryFile] = useState(null);
  const [categorySequenceFile, setCategorySequenceFile] = useState(null);
  const [summarySheetTotalConfigFile, setSummarySheetTotalConfigFile] = useState(null);
  const [isReportEnabled, setReportEnabled] = useState(false);

  const CompanyTemplateFormSchema = useMemo(() => Yup.object().shape({
    templateName: Yup.string()
      .trim()
      .test(
        'slashes',
        "Please remove slashes",
        (value) => (value || '').indexOf('/') < 0,
      )
      .transform(value => (value === '' ? undefined : value))
      .required(messages.errors.templateName),                                   
    tag: Yup.mixed().oneOf(Reflect.ownKeys(TemplateTags)),
    assetTypes: Yup
      .array().min(1).required().label('Asset Type')
      .of(Yup.mixed().oneOf(Reflect.ownKeys(dynamicAssetTypes), 'This asset type is not allowed'), 'Please select a valid asset type.')
      
  }), [dynamicAssetTypes]);

  const assetTypes = useMemo(() => {
    if (isGlobal) {
      return dynamicAssetTypes;
    }
    const { assetTypes } = companyDetail;
    return assetTypes ? pick(dynamicAssetTypes, assetTypes) : dynamicAssetTypes;
  }, [companyDetail, dynamicAssetTypes, isGlobal]);


  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      const templateObj = {
        ...values, 
        isReportEnabled, 
        templateFile, 
        dictionaryFile, 
        categorySequenceFile, 
        summarySheetTotalConfigFile
      };
      const uploadPromise = isGlobal ?
        templateService.uploadGlobalTemplate(templateObj) :
        templateService.uploadTemplate(companyUUID, templateObj)
      await uploadPromise;

      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }, [
    companyUUID, 
    onSubmitSuccess, 
    templateFile, 
    dictionaryFile, 
    categorySequenceFile, 
    summarySheetTotalConfigFile,
    isReportEnabled, 
    isGlobal
  ]);

  return (
    <Formik
      initialValues={{ ...initialTemplate, isReportEnabled }}
      validationSchema={CompanyTemplateFormSchema}
      onSubmit={onSubmit}
      component={
        formikProps => (
          <CompanyTemplateFormComponent
            {...formikProps}
            assetTypes={assetTypes}
            keysToMask={keysToMask}
            maskedTemplate={false}
            isReportEnabled={isReportEnabled}
            bindSubmission={bindSubmission}
            selectedCategorySequenceFile={categorySequenceFile}
            selectedSummarySheetTotalConfigFile={summarySheetTotalConfigFile}
            templateFileName="templateFile"
            selectedTemplateFile={templateFile}
            onTemplateFileChange={e => setTemplateFile(e.target.files[0])}
            onRemoveTemplateFile={() => setTemplateFile(null)}
            dictionaryFileName="dictionaryFile"
            selectedDictionaryFile={dictionaryFile}
            onDictionaryFileChange={e => setDictionaryFile(e.target.files[0])}
            onRemoveDictionaryFile={() => setDictionaryFile(null)}
            onCategorySequenceFileChange={e => setCategorySequenceFile(e.target.files[0])}
            onCategorySequenceRemoveFile={() => setCategorySequenceFile(null)}
            onSummarySheetTotalConfigChange={e => setSummarySheetTotalConfigFile(e.target.files[0])}
            onSummarySheetTotalConfigRemoveFile= {() => setSummarySheetTotalConfigFile(null)}
            onChangeReportEnabled={(isReportEnabled) => setReportEnabled(isReportEnabled)}
          />
        )
      }
    />
  );
}

const mapStateToProps = state => {
  const currentCompany = currentCompanySelector(state);
  return ({
    companyDetail: companiesDetailsSelector(state)[currentCompany],
    keysToMask: templateKeysToMaskSelector(state),
    dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
  });
};

export default connect(mapStateToProps)(withServices('templateService')(CompanyTemplateForm));
