import mixpanel from 'mixpanel-browser';
import { appConfig, eventTracking } from '../config';
import { authSelector } from '../store/selectors';

class EventTrackerService {
  constructor(store) {
    this.store = store;
    const debug = appConfig.environment !== 'production';
    const { isEventTrackingEnabled, mixPanelProjectToken } = eventTracking;
    this.isEventTrackingEnabled = isEventTrackingEnabled;
    if (isEventTrackingEnabled) {
      mixpanel.init(mixPanelProjectToken, { debug });
    }
  }

  track(eventName, eventParams) {
    if (this.isEventTrackingEnabled) {
      // eslint-disable-next-line no-unused-vars
      const { token, ...rest } = authSelector(this.store.getState());
      mixpanel.track(eventName, { ...eventParams, env: appConfig.environment, ...rest });
    }
  }

  identify(userId) {
    if (this.isEventTrackingEnabled) {
      mixpanel.identify(userId);
    }
  }

  setPeople(params) {
    if (this.isEventTrackingEnabled) {
      mixpanel.people.set({ ...params, '$email': params.email, '$name': `${params.firstName} ${params.lastName}` });
    }
  }

  setGroup(groupKey, groupId) {
    if (this.isEventTrackingEnabled) {
      mixpanel.set_group(groupKey, groupId);
    }
  }

  setCompany(companyName) {
    if (this.isEventTrackingEnabled) {
      this.setGroup('Company', companyName);
      this.setGroup('$company', companyName);
    }
  }

  reset() {
    if (this.isEventTrackingEnabled) {
      mixpanel.reset();
    }
  }
}

export default EventTrackerService;
