import React, { useCallback, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Row, Col, Button } from 'react-bootstrap';
import { sumBy } from 'lodash';
import DocExtractionAffixTableWrapper from '../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import { setChargeCodeConfig } from '../../../store/currentDocument';
import {
  chargeCodeConfigSelector,
  staticChargeCodeSelector,
  readOnlyAccessSelector
} from '../../../store/selectors';
import ChargeCodeOptions from './ChargeCodeOptions';
import {
  appendClassName,
  DisplayAmountFormat,
  validateChargeCode,
  getSortedJson
} from '../../../utils/utils';
import style from './DocExtractionChargeCodeConfig.module.scss';
import RentRollConfigHeader from '../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../DocExtractionSummary/DocExtractionWithoutSummary';
import DocExtractionAffixTotalCard from '../DocExtractionAffixTotalCard/DocExtractionAffixTotalCard';
import { affixTotalColors } from '../../../constants';
import messages from '../../../../locales/en-US';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import { DocExtractionChargeCodeMenu } from '../DocExtractionChargeCodeMenu/DocExtractionChargeCodeMenu';
import DocExtractionChargeCodeMergeComponent from '../DocExtractionChargeCodeMergeComponent/DocExtractionChargeCodeMergeComponent';

function DocExtractionChargeCodeConfig({
  chargeCodeConfigData,
  staticChargeCodes,
  store,
  onConfigSubmit,
  onSlidePaneClose,
  onMergeChargeCodeColumn,
  readOnlyAccess
}) {
  const [activeChargeCodeMergeColView, setActiveChargeCodeMergeColView] =
    useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTableView, setActiveTableView] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidChange, setIsValidChange] = useState(false);
  const [sortedChargeCodeConfigData, setSortedChargeCodeConfigData] = useState({});

  useEffect(() => {
    const chargeCodeConfigValues = getSortedJson(chargeCodeConfigData, 'key', 'category');
    setSortedChargeCodeConfigData(chargeCodeConfigValues)
  }, [chargeCodeConfigData]);

  const {
    RENT_ROLL: {
      MULTIFAMILY: { CHARGE_CODE_TOTAL }
    }
  } = affixTotalColors;

  const onSubmit = useCallback(
    async (...args) => {
      const [formValues] = args;
      setIsSubmitting(true);
      store.dispatch(setChargeCodeConfig(formValues));
      await onConfigSubmit();
      setIsSubmitting(false);
      setIsValidChange(false);
    },
    [onConfigSubmit, store]
  );

  const onChargeCodeMerge = useCallback((args) => {
    setIsValidChange(true);
    onMergeChargeCodeColumn(args);
  }, []);

  return (
    <>
      {Reflect.ownKeys(chargeCodeConfigData).length > 0 ? (
        <>
          <RentRollConfigHeader
            title="Configure Charge Code"
            hideChartSwitcher
            onTableViewSwitch={(isTableView) => setActiveTableView(isTableView)}
          />
          <Formik
            key="chargeCodeConfig"
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={chargeCodeConfigData}
            render={({ setFieldValue }) => (
              <Form>
                <DocExtractionAffixTableWrapper>
                  {activeTableView ? (
                    <>
                      <Row
                        className={`configure-table-thead ${style.chargeCodeHeader}`}>
                        <Col className={`col-md-auto ${style.widthId}`}>#</Col>
                        <Col className={`col-md-auto ${style.widthChargeCode}`}>
                          Charge Code
                        </Col>
                        <Col
                          className={`col-md-auto ${style.widthTotalAmount}`}
                        >
                          Amount
                        </Col>
                        <Col>Category</Col>
                      </Row>
                      <Row
                        className={appendClassName(
                          'position-relative',
                          style['remove-margin']
                        )}
                      >
                        <Col
                          className={`${style.chargeCode} ${
                            isMenuOpen ? `overflow-hidden` : 'overflow-auto'
                          }`}
                        >
                          {sortedChargeCodeConfigData.length && sortedChargeCodeConfigData.map(
                            (chargeCode, rowIndex) => (
                              <ul
                                key={chargeCode.key}
                                className={style.chargeCodeList}
                              >
                                <li className={`col-md-auto ${style.widthId}`}>
                                  {rowIndex + 1}
                                </li>
                                <li
                                  className={`col-md-auto ${style.widthChargeCode}`}
                                >
                                  <div
                                    className="w-100 text-with-ellipsis"
                                  >
                                    <div
                                      title={validateChargeCode(chargeCode.key)}
                                      className="w-100 text-with-ellipsis"
                                    >
                                      {validateChargeCode(chargeCode.key)}
                                    </div>
                                  </div>
                                </li>
                                <li
                                  className={`col-md-auto text-with-ellipsis ${style.widthTotalAmount}`}
                                >
                                  <DisplayAmountFormat
                                    number={
                                      chargeCodeConfigData[chargeCode.key]?.total
                                    }
                                  />
                                </li>
                                <li className={style.widthChargeCodeOption}>
                                  <ChargeCodeOptions
                                    chargeCodeConfigData={chargeCodeConfigData}
                                    chargeCode={chargeCode.key}
                                    staticChargeCodes={staticChargeCodes}
                                    setCurrentChargeCodeCategory={(
                                      dropdownValue
                                    ) => {
                                      setIsValidChange(true);
                                      setFieldValue(chargeCode.key, dropdownValue);
                                    }}
                                    onMenuClose={() => setIsMenuOpen(false)}
                                    onMenuOpen={() => setIsMenuOpen(true)}
                                  />
                                </li>
                                <li className={style.dottedIcon}>
                                  <DocExtractionChargeCodeMenu
                                    onMenuSelect={() =>
                                      setActiveChargeCodeMergeColView(rowIndex)
                                    }
                                  />
                                </li>

                                {activeChargeCodeMergeColView === rowIndex && (
                                  <DocExtractionChargeCodeMergeComponent
                                    currentChargeCode={chargeCode.key}
                                    activeColumn={rowIndex}
                                    chargeCodeConfigData={chargeCodeConfigData}
                                    onClose={() =>
                                      setActiveChargeCodeMergeColView(null)
                                    }
                                    onMergeClick={(args) =>
                                      onChargeCodeMerge(args)
                                    }
                                  />
                                )}
                              </ul>
                            )
                          )}
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  <DocExtractionAffixTotalCard
                    cards={[
                      {
                        title: 'Total Amount',
                        color: CHARGE_CODE_TOTAL,
                        content: (
                          <DisplayAmountFormat
                            number={sumBy(
                              Object.values(chargeCodeConfigData),
                              'total'
                            )}
                          />
                        )
                      }
                    ]}
                  />
                </DocExtractionAffixTableWrapper>
                <Row
                  className={`configure-table-tfooter footerFixedBottom ${style.chargeCodeFooter}`}
                >
                  <Button
                    variant="outline-secondary btn-sm"
                    onClick={onSlidePaneClose}
                  >
                    Cancel
                  </Button>
                  <FormSubmitButton
                    id="ChargeCodeSubmitButton"
                    isValid={isValidChange && !readOnlyAccess}
                    isSubmitting={isSubmitting}
                  />
                </Row>
              </Form>
            )}
          />
        </>
      ) : (
        <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  chargeCodeConfigData: chargeCodeConfigSelector(state),
  staticChargeCodes: staticChargeCodeSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state),
});

export default connect(mapStateToProps)(
  withServices('store')(DocExtractionChargeCodeConfig)
);
