import React, { useState } from 'react';
import ReactSelect from 'react-select';
import IconButton from '../../core/Button/IconButton';
import mergeIcon from '../../../assets/images/merge-icon.svg';
import closeIcon from '../../../assets/images/close-icon.svg';
import style from './DocExtractionChargeCodeMergeComponent.module.scss';
import { validateChargeCode } from '../../../utils/utils';

function DocExtractionChargeCodeMergeComponent({
  currentChargeCode, chargeCodeConfigData, onClose, onMergeClick
}) {
  const [userSelectedChargeCode, setUserSelectedChargeCode] = useState({});

  const chargeCodeTargetOption = Reflect.ownKeys(chargeCodeConfigData).filter(item => item !== currentChargeCode);


  return (
    <>
      <li className={style.overlayView}>
        <ul className={style.overlayViewInputs}>
          <li className={style.overlayViewOptions}>
            <ReactSelect
              placeholder={validateChargeCode(currentChargeCode)}
              classNamePrefix="react-select chargeCodeSelect text-with-ellipsis"
              value={currentChargeCode}
              defaultValue={currentChargeCode}
              maxMenuHeight={200}
              isDisabled
            />
          </li>
          <li className={style.textDesciption}>
            to be merged in
          </li>
          <li className={style.overlayViewOptions}>
            <ReactSelect
              placeholder="Select Column"
              classNamePrefix="react-select chargeCodeSelect"
              options={chargeCodeTargetOption.map(category => (
                { value: category, label: validateChargeCode(category) }
              ))}
              maxMenuHeight={200}
              onChange={
                (selectedChargeCode) => {
                  setUserSelectedChargeCode({
                    sourceColumnRef: { 
                      ...chargeCodeConfigData[currentChargeCode], key: currentChargeCode 
                    },
                    targetcolumnRef: { 
                      ...chargeCodeConfigData[selectedChargeCode.value], key: selectedChargeCode.value 
                    }
                  });
                }
              }
            />
          </li>
          <li>
            <IconButton
              title="Merge Column" variant="light" iconImg={mergeIcon} className={style.overlayViewButton} onClick={() => {
                onMergeClick(userSelectedChargeCode);
                onClose();
              }} />
            <IconButton title="Close" variant="light" iconImg={closeIcon} className={`${style.overlayViewButton} ${style.closeIcon}`} onClick={onClose} />
          </li>
        </ul>

      </li>
    </>
  );
}

export default DocExtractionChargeCodeMergeComponent;