import React, {
  useState, useCallback, useMemo, useEffect
} from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Col, Row, Container } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import ProspectPopups from '../ProspectPopups/ProspectPopups';
import EditProspect from '../ProspectForm/EditProspectForm';
import ProspectInvoice from '../ProspectInvoice/ProspectInvoice';
import { prospectsListSelector } from '../../../store/selectors';
import ProspectInvoicesListPageLink from '../../core/AccountsExecLinks/links';
import { displayDateFormat, displayCurrencyFormat } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';
import style from './ProspectsList.module.scss';
import './ProspectsList.scss';
import { PROSPECT_CONVERSION_STATUSES } from '../../../constants';

function ProspectsList({ prospects, prospectsService, isSuperAdmin=false }) {
  const regexFilter = useMemo(() => ({
    type: 'TextFilter',
    placeholder: 'Filter',
    delay: 1000
  }), []);

  useEffect(() => {
    const interval = setInterval(async () => {
      await prospectsService.loadProspects();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  const initialValues = {
    prospect: {
      companyName: '',
      companyAdminFirstName: '',
      companyAdminLastName: '',
      companyAddress: {
        address: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      },
      companyPhone: '',
      companyAdminEmail: '',
      numberOfLicenses: 1,
      amountPerLicense: 500,
      totalAmount: 500,
      licenseRenewal: 'YEARLY'
    }
  };

  const [showCreateProspectPopup, setShowProspectFormPopup] = useState(false);
  const [prospect, setProspect] = useState(initialValues);


  const hidePopups = useCallback(
    () => {
      setShowProspectFormPopup(false);
    },
    [],
  );

  const onAddProspect = useCallback(() => {
    setProspect(initialValues);
    setShowProspectFormPopup(true);
  }, [initialValues]);

  const conversionStatusFormatter = ((cell, row) => (
    <span>
      {PROSPECT_CONVERSION_STATUSES[cell].icon}
      {' '}
      {PROSPECT_CONVERSION_STATUSES[cell].label}
      {' '}
          &nbsp;
    </span>
  ));


  const formatAction = useCallback((cell, row) => {
    const convertedProspect = row.conversionStatus === PROSPECT_CONVERSION_STATUSES.CONVERTED.key;
    return (
      <div className="d-flex flex-row">
        {!convertedProspect && (
        <>
          <EditProspect prospect={{ prospect: row }} label={<IconButton className="smallIconButton" placement="left" title="Edit Prospect" icon={faEdit} />} />
          <ProspectInvoice prospect={{ prospect: row }} label={<IconButton className="smallIconButton" placement="left" title="Send Invoice" icon={faPaperPlane} />} />
        </>
        )}
        {convertedProspect && (
        <>
          <IconButton className="smallIconButton" title="Edit Prospect" icon={faEdit} disabled />
          <IconButton className="smallIconButton" title="Send Invoice" icon={faPaperPlane} disabled />
        </>
        )}
        <ProspectInvoicesListPageLink title="List Invoices" text="Invoices" prospect={row} isSuperAdmin={isSuperAdmin} />

      </div>
    );
  }, []);

  return (
    <>
      <ProspectPopups
        onHide={hidePopups}
        showCreateProspect={showCreateProspectPopup}
        prospect={prospect}
      />
      <Container fluid className={`p-2 pb-4 ${style.projectsSubHeader}`}>
        <Row className="align-items-center">
          <Col sm="auto">
            <h5 className="mb-0">Prospects</h5>
          </Col>
          <Col sm="auto" className="pl-0 tourIconBtn">
            <IconButton
              title="Add Prospect" className="btn-circle btn-shadow" variant="outline-secondary" size="sm" icon={faPlus} onClick={e => {
                onAddProspect();
              }} id="AddProspectButton" />
          </Col>
          <Col />
        </Row>
      </Container>
      <div className="prospectsTable">
        <BootstrapTable
          data={prospects}
          tableStyle={{ background: '#fafafa' }}
          pagination
          bordered
          condensed
          hover
        >
          <TableHeaderColumn
            dataField="uuid"
            isKey
            hidden
            dataSort
            width="60"
            dataAlign="center"
          >
        S.N.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="companyName"
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataSort
            filter={regexFilter}
            width="12%"
          >
        Company
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="companyAdminEmail"
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataSort
            filter={regexFilter}
            width="12%"
          >
        Email
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="numberOfLicenses"
            dataSort
            width="8%"
          >
        Licenses
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="amountPerLicense"
            dataFormat={displayCurrencyFormat}
            dataSort
            width="10%"
          >
        Per License
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="totalAmount"
            dataFormat={displayCurrencyFormat}
            dataSort
            width="12%"
          >
        Total (USD)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="licenseRenewal"
            dataSort
            filter={regexFilter}
            width="10%"
          >
        Renewal
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="conversionStatus"
            dataSort
            width="12%"
            dataFormat={conversionStatusFormatter}
          >
        Conversion
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="createdAt"
            dataFormat={displayDateFormat}
            dataSort
            width="10%"
          >
        Created On
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Actions"
            dataFormat={formatAction}
            width="11%"
          >
        Actions
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  prospects: prospectsListSelector(state)
});


export default connect(mapStateToProps)(withServices('prospectsService')(ProspectsList));
