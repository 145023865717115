import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import messages from '../../../../locales';
import TemplateModelFormComponent from './TemplateModelFormComponent';
import { companyTemplatesList,
  dynamicAssetTypesAsObjSelector,
  getGlobalTemplateMappingsAsListSelector 
} from '../../../store/selectors';

function TemplateModelForm({
  initialValues, onSubmitSuccess, bindSubmission,
  templateModelService, companyUUID, templateUUID,
  companyTemplates, dynamicAssetTypes, globalTemplates
}) {
  const [templateFile, setTemplateFile] = useState(null);
  const [reportTemplateFile, setReportTemplateFile] = useState(null);
  let assetType;
  if(companyUUID) {
    let {assetTypes} = companyTemplates[templateUUID]
    assetType = assetTypes ? pick(dynamicAssetTypes, assetTypes) : assetTypes;
  } else {
    let globalTemplateAssetType = globalTemplates.find(template => template.uuid == templateUUID).assetTypes;
    assetType = globalTemplateAssetType ? pick(dynamicAssetTypes, globalTemplateAssetType) : globalTemplateAssetType;
  }

  const ModelFormSchema = useMemo(() => Yup.object().shape({
    modelName: Yup.string().required(messages.errors.templateName).test(
      'slashes',
      "Please remove slashes",
      (value) => (value || '').indexOf('/') < 0,
    ),
    assetType: Yup.mixed()
      .oneOf(Reflect.ownKeys(dynamicAssetTypes), 'Please select a valid asset type.')
  }), [dynamicAssetTypes]);

  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      if (!templateFile) {
        // eslint-disable-next-line no-alert
        alert('Please select model file');
        actions.setSubmitting(false);
        return;
      }
      await templateModelService.uploadTemplateModel(companyUUID, templateUUID, 
        { ...values, modelFile: templateFile, reportTemplateFile }
      );
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ModelFormSchema}
      onSubmit={onSubmit}
      component={formikProps => (
        <TemplateModelFormComponent
          {...formikProps}
          bindSubmission={bindSubmission}
          selectedFile={templateFile}
          onFileChange={e => setTemplateFile(e.target.files[0])}
          reportTemplateFile={reportTemplateFile}
          setReportTemplateFile={setReportTemplateFile}
          onRemoveFile={() => setTemplateFile(null)}
          assetTypes={assetType} />
      )
      }
    />
  );
}

const mapStateToProps = state => ({
  companyTemplates: companyTemplatesList(state),
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state),
  globalTemplates: getGlobalTemplateMappingsAsListSelector(state),
});

export default connect(mapStateToProps)(withServices('templateModelService')(TemplateModelForm));
