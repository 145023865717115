import React from 'react';
import { Form, Field } from 'formik';
import { components } from 'reaf';
import { Form as BSForm, Card, Alert } from 'react-bootstrap';
import clikLogoImg from '../../../assets/images/clikai-logo.svg';
import style from './TrialUserRegistrationFormComponent.module.scss';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import useBool from '../../../hooks/useBool';
import Link from '../../core/Link/Link';

function TrialUserRegistrationFormComponent({
  status, isSubmitting, showMessageModal, user
}) {
  const [agreedToTerms, , , toggleAgreement] = useBool(false);
  return (
    <Card className="form-card">
      <Card.Body className={`form-card-body ${style.formCardBody}`}>
        <div className="loginFormLogo">
          <img src={clikLogoImg} alt="Clik Logo" />
        </div>

        <Alert variant="info" className="d-flex align-items-center">
          {
            showMessageModal ? <p>
              Welcome {user.firstName} {user.lastName}! We just need a few more details to create your account!
            </p> : <p className="m-0">Fill Form to Complete registration.</p>
          }
        </Alert>
        {
          status && status.error &&
          (
            <Alert variant="danger" className="d-flex align-items-center">
              <p className="m-0">{status.error}</p>
            </Alert>
          )
        }

        <Form autoComplete="off">

          <div className="form-group">
            <label htmlFor="company.name">Company Name *</label>
            <Field id="company.name" className="form-control" name="company.name" type="text" />
            <FieldErrorMessage name="company.name" />
          </div>

          <div className="form-group">
            <label htmlFor="admin.firstName">First Name</label>
            <Field id="admin.firstName" disabled className="form-control" name="admin.firstName" type="text" />
            <FieldErrorMessage name="admin.firstName" />
          </div>
          <div className="form-group">
            <label htmlFor="admin.lastName">Last Name</label>
            <Field id="admin.lastName" disabled className="form-control" name="admin.lastName" type="text" />
            <FieldErrorMessage name="admin.lastName" />
          </div>

          <div className="form-group">
            <label htmlFor="admin.email">Email</label>
            <Field id="admin.email" disabled className="form-control" name="admin.email" type="email" />
            <FieldErrorMessage name="admin.email" />
          </div>

          <div className="form-group">
            <label htmlFor="company.phone">Phone Number</label>
            <Field id="company.phone" className="form-control" name="company.phone" type="text" />
            <FieldErrorMessage name="company.phone" />
          </div>

          <BSForm.Group controlId="agree">
            <BSForm.Check
              checked={agreedToTerms}
              onChange={toggleAgreement}
              type="checkbox"
              label={(
                <span>
                  <small>
                    By clicking Next you agree to our&nbsp;
                    <br />
                    <Link route="termsPage">Terms of Service</Link>
                  &nbsp;and&nbsp;
                    <Link route="privacyPolicyPage">Privacy Policy</Link>
.
                  </small>
                </span>
              )}
            />
          </BSForm.Group>

          <FormSubmitButton
            className={`btn-block ${style.btnPrimary}`}
            isSubmitting={isSubmitting}
            isValid={agreedToTerms}
            title="Next"
          />
        </Form>
      </Card.Body>
    </Card>
  );
}

export default components.withServices('store')(TrialUserRegistrationFormComponent);
