import BaseEvent from '../../../core/Spreadjs/Events/BaseEvent';
import { TARGET_SHEET } from '../DataSpreadSheet';

class OSClipboardPastingEvent extends BaseEvent {
  handle(osSpreadsheet, sheet, args) {
    const sheetIndex = osSpreadsheet.spreadsheet.getActiveSheetIndex();
    if (sheetIndex === TARGET_SHEET) {
      const { cellRange: { row, col, rowCount, colCount } } = args;

      const { foundColumnIndex: headIndex } = osSpreadsheet.datasheet.customSearchColumnWithValue(
        TARGET_SHEET, 0, 'Head', {},
      );

      const { foundColumnIndex: categoryIndex } = osSpreadsheet.datasheet.customSearchColumnWithValue(
        TARGET_SHEET, 0, 'Category', {},
      );

      const { foundColumnIndex: subCategoryIndex } = osSpreadsheet.datasheet.customSearchColumnWithValue(
        TARGET_SHEET, 0, 'Sub Category', {},
      );

      if (col <= headIndex && headIndex < col + colCount) {
        let previousCategoryValues = [];
        let previousSubCategoryValues = [];
        const previousHeadValues = osSpreadsheet.spreadsheet.getColumnValues(
          TARGET_SHEET, row, rowCount, headIndex,
        );

        if (categoryIndex > -1) {
          previousCategoryValues = osSpreadsheet.spreadsheet.getColumnValues(
            TARGET_SHEET, row, rowCount, categoryIndex,
          );
        }

        if (subCategoryIndex > -1) {
          previousSubCategoryValues = osSpreadsheet.spreadsheet.getColumnValues(
            TARGET_SHEET, row, rowCount, subCategoryIndex,
          );
        }

        setTimeout(() => osSpreadsheet.workbook.commandManager().execute({
          cmd: 'osUpdateRowColorCommand',
          sheetIndex,
          previousHeadValues,
          previousCategoryValues,
          previousSubCategoryValues,
          range: { row, col, rowCount, colCount },
          headIndex,
          categoryIndex,
          subCategoryIndex,
        }), 0);
      }
    }
  }
}

export const osClipboardPastingEvent = OSClipboardPastingEvent.get();
