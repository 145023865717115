/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { mapKeys, isNil } from 'lodash';
import { OS_HEADS, OS_CHART_TYPES } from '../../../constants';
import DocExtractionAffixChartFilter from '../DocExtractionAffixChart/DocExtractionAffixChartFilter';
import DocExtractionAffixPieChart from '../DocExtractionAffixChart/DocExtractionAffixPieChart';
import DocExtractionWithoutSummary from './DocExtractionWithoutSummary';
import DocExtractionRentalIncomeChart from './DocExtractionRentalIncomeChart';
import './DocExtractionSummaryChart.scss';

function DocExtractionSummaryChart({
  style, summaryData, netRentalIncome, showNetRentalIncomeChart = false, assetType
}) {
  const [showChart, setShowChart] = useState(false);
  const [operatingExpenseData, setOperatingExpenseData] = useState([]);
  const [currentChartType, setCurrentChartType] = useState(OS_CHART_TYPES.OPERATING_EXPENSES);
  const { summaryDetail } = summaryData;

  const getOperatingExpenseData = () => {
    const chartData = summaryDetail.filter(head => head.title === OS_HEADS.EXPENSE && head);
    const categories = [];
    if (chartData.length) {
      mapKeys(chartData[0].children, (chart) => {
        categories.push({ name: chart.title, value: chart.total, EGI: `${chart.egi} %` });
      });
      return categories;
    }
  };

  useEffect(() => {
    if (!isNil(summaryData)) {
      const refinedDataKeys = getOperatingExpenseData();
      setOperatingExpenseData(refinedDataKeys);
      setShowChart(true);
    }
  }, [summaryData]);

  return (
    <div className={style.chartWrapper}>
      {
        showChart && (
        <>
          {
            showNetRentalIncomeChart && (
              <div className={style.filterWrapper}>
                <DocExtractionAffixChartFilter
                  filterLabel="Select Chart"
                  filterInitialValue={currentChartType}
                  filterChartData={Object.values(OS_CHART_TYPES)}
                  onChartFilterChange={(selectedChartType) => setCurrentChartType(selectedChartType)}
                  isFixedElement
                />
              </div>
            )}


          <div className={`${style.chart} documentSummaryChartListing documentSummaryChart`}>
            <h4>{currentChartType}</h4>
            {
              currentChartType === OS_CHART_TYPES.OPERATING_EXPENSES ? (
                !isNil(operatingExpenseData) ? (
                  <DocExtractionAffixPieChart
                    data={operatingExpenseData}
                    basicChart
                  />
                ) : <DocExtractionWithoutSummary message="Expense head does not have any mapped category" />

              ) : showNetRentalIncomeChart && (
                !isNil(netRentalIncome) ? (
                  <DocExtractionRentalIncomeChart
                    data={netRentalIncome}
                    assetType={assetType}
                  />
                ) : <DocExtractionWithoutSummary message="Months are not found in the document." />
              )
            }

          </div>

        </>
        )
      }
    </div>
  );
}

export default DocExtractionSummaryChart;
