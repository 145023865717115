import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withServices } from 'reaf';
import ProjectsView from '../../app/ProjectsView/ProjectsView';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import ProjectsPageTour from './ProjectsPageTour';
import AddProjectsTour from './AddProjectsTour';
import TourIconButton from '../../app/IconButtons/TourIconButton';
import { markFirstTourDone } from '../../../store/productTour';
import style from './ProjectsPage.module.scss';
import { ShowTourGuides } from '../../../constants/eventTrackerMessage';
import { userRoleSelector } from '../../../store/selectors';

function ProjectsPageHeader({ onHelpClick, hideTourButton, router, role }) {
  return (
    <Container fluid className="bg-light border-bottom sub-header">
      <Row className="align-items-center">
        <Col sm="auto" className="align-middle p2">
          <Breadcrumbs items={[]} />
        </Col>
        <Col />
        <Col sm="auto" className="pl-0 tourIconBtn">
          {
            !hideTourButton && (
              <TourIconButton
                onClick={onHelpClick}
                className={`btn-xs ${style.buttonAlignment}`}
                id="ShowTourButton"
              />
            )
          }
        </Col>
      </Row>
    </Container>
  );
}

function ProjectsPage({
  startTour,
  store,
  router,
  queries,
  eventTrackerService,
  role
}) {
  const [showTour, setShowTour] = useState(startTour);
  const [hideTourButton, setHideTourButton] = useState(true);
  const [showAddProjectTour, setShowAddProjectTour] = useState(false);

  const onAddProjectTutorial = useCallback(() => {
    store.dispatch(markFirstTourDone('projectsPage'));
    setShowTour(false);
    setShowAddProjectTour(true);
  }, []);

  const closeTours = useCallback(() => {
    setShowTour(false);
    setShowAddProjectTour(false);
    store.dispatch(markFirstTourDone('projectsPage'));
  }, []);

  const onHelpClick = useCallback(() => {
    setShowTour(true);
    document.getElementById('projectsContainer').scrollTop = 0;
    eventTrackerService.track(ShowTourGuides, {
      page: 'Projects Page'
    });
  }, [showTour]);

  return (
    <LoggedInUserPage>
      <ProjectsPageHeader role={role} onHelpClick={onHelpClick} hideTourButton={hideTourButton} router={router} />
      <ProjectsView queries={queries} setHideTourButton={setHideTourButton} />
      {showTour && (
        <ProjectsPageTour
          show={showTour}
          onClose={closeTours}
          onAddProjectTutorial={onAddProjectTutorial}
          isListView
        />
      )}
      {showAddProjectTour && (
        <AddProjectsTour show={showAddProjectTour} onClose={closeTours} />
      )}
    </LoggedInUserPage>
  );
}

const mapStateToProps = (store) => ({
  startTour: false,
  role: userRoleSelector(store)
});

export default connect(mapStateToProps)(
  withServices('store', 'router', 'eventTrackerService')(ProjectsPage)
);
