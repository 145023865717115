import React from 'react';
import { Form, Field } from 'formik';
import { Form as BootstrapForm } from 'react-bootstrap';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import PasswordViewer from '../PasswordViewer/PasswordViewer';
import { USER_ROLES } from '../../../constants';
import ReactSelect from 'react-select';
import { isEmpty } from 'lodash';

function CompanyFormComponent({
  status, isSubmitting, bindSubmission, submitForm, companyTemplates, selectAll, setSelectAll, selectedTemplates,
  selectAllForWrite, setSelectAllForWrite,  companyTemplatesMap, formikProps, values, ...form
}) {
  bindSubmission(submitForm, isSubmitting);

  return (
    <div>
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <label htmlFor="user.firstName">First Name</label>
          <Field id="user.firstName" className="form-control" name="user.firstName" type="text" />
          <FieldErrorMessage name="user.firstName" />
        </div>
        <div className="form-group">
          <label htmlFor="user.lastName">Last Name</label>
          <Field id="user.lastName" className="form-control" name="user.lastName" type="text" />
          <FieldErrorMessage name="user.lastName" />
        </div>
        <div className="form-group">
          <label htmlFor="user.email">Email</label>
          <Field id="user.email" className="form-control" name="user.email" type="email" />
          <FieldErrorMessage name="user.email" />
        </div>
        <div className="form-group passwordGroupRow">
          <label htmlFor="user.password">Password</label>
          <PasswordViewer id="user.password" name="user.password" />
          <FieldErrorMessage name="user.password" />
        </div>
        <div className="form-group">
          <label htmlFor="role">Role</label>
          <Field id="role" className="form-control" name="role" component="select">
            <option 
              value={USER_ROLES.COMPANY_USER.key} 
              key={USER_ROLES.COMPANY_USER.key}
            >
              {USER_ROLES.COMPANY_USER.label}
            </option>
            <option
              value={USER_ROLES.SUPPORT_USER.key} 
              key={USER_ROLES.SUPPORT_USER.key}
            >{USER_ROLES.SUPPORT_USER.label}
            </option>
          </Field>
          <FieldErrorMessage name="role" />
        </div>
        <div className="form-group">
          <label htmlFor="readAccessTemplate">Read access</label>
          <BootstrapForm.Group controlId="formBasicCheckbox-1">
            <BootstrapForm.Check
              type="checkbox" checked={selectAll} onChange={e => {
                setSelectAll(e.target.checked);
                form.setFieldValue('readAccessTemplate', companyTemplatesMap);
              }} label="Allow all templates." />
          </BootstrapForm.Group>
          <ReactSelect
            isMulti
            name="readAccessTemplate"
            isDisabled={selectAll}
            value={values.readAccessTemplate}
            onChange={templates => {
              form.setFieldValue('readAccessTemplate', templates);
            }}
            options={companyTemplatesMap} />
          {isEmpty(values.readAccessTemplate) && <div className="text-danger">
              <small>Please select atleast one template</small>
          </div>}
        </div>
        <div className="form-group">
          <label htmlFor="writeAccessTemplate">Write access</label>
          <BootstrapForm.Group controlId="formBasicCheckbox-2">
            <BootstrapForm.Check
              type="checkbox" checked={selectAllForWrite} onChange={e => {
                setSelectAllForWrite(e.target.checked);
                form.setFieldValue('writeAccessTemplate', companyTemplatesMap);
              }} label="Allow all templates." />
          </BootstrapForm.Group>
          <ReactSelect
            isMulti
            name="writeAccessTemplate"
            isDisabled={selectAllForWrite}
            value={values.writeAccessTemplate}
            onChange={templates => {
              form.setFieldValue('writeAccessTemplate', templates);
            }}
            options={companyTemplatesMap} />
        </div>

      </Form>
    </div>
  );
}

export default CompanyFormComponent;
