import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  categories: {},
  videoList: {}
};

const [
  {
    setCategories, setVideoList
  },
  reducer
] = createDuck('autouw/videoTutorial', {
  _initialState: initialState,

  setCategories: categories => state => ({
    ...state,
    categories
  }),
  setVideoList: list => state => ({
    ...state,
    videoList: normalize(list, 'uuid')
  })
});

export {
    setCategories, setVideoList
};

export default reducer;
