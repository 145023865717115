import { dilite } from 'reaf';
import AuthService from '../services/AuthService';
import Routes from '../routes/Routes';
import ApiClient from '../services/ApiClient';
import ProjectsService from '../services/ProjectsService';
import App from '../../App';
import DocumentsService from '../services/DocumentsService';
import CompanyService from '../services/CompanyService';
import CompanyListService from '../services/CompanyListService';
import { appBaseUrl } from './index';
import UserService from '../services/UserService';
import TemplateService from '../services/TemplateService';
import TemplateModelService from '../services/TemplateModelService';
import TicketService from '../services/TicketService';
import ToastrService from '../services/ToastrService';
import UtilsService from '../services/UtilsService';
import AccountsExecutiveService from '../services/AccountsExecutiveService';
import VideoTutorialsService from '../services/VideoTutorialsService';
import ProspectsService from '../services/ProspectsService';
import ProjectExporterService from '../services/ProjectExporterService';
import SsoConfigurationService from '../services/SsoConfigurationService';
import AppConfigurationService from '../services/AppConfigurationService';
import PlacesService from '../services/PlacesService';
import ReportLinkService from '../services/ReportLinkService';
import CaptureEmailService from '../services/CaptureEmailService';
import EventTrackerService from '../services/EventTrackerService';
import DynamicAssetTypesService from '../services/DynamicAssetTypesService';
import PaymentService from '../services/PaymentService';
import PaymentLinksService from '../services/PaymentLinksService';
import FeatureFlagService from '../services/FeatureFlagService';
import DictionaryService from '../services/DictionaryService';
import ProjectReportService from '../services/ProjectReportService';
import FallbackExtractionService from '../services/FallbackExtractionService';

const { ctor, value } = dilite;

const services = {
  eventTrackerService: ctor(EventTrackerService, ['store']),
  authService: ctor(
    AuthService,
    ['store', 'eventTrackerService', 'toastrService'],
    [
      ['apiClient', 'setApiClient'],
      ['router', 'setRouter']
    ]
  ),

  routes: ctor(Routes, [
    'authService',
    'projectsService',
    'documentsService',
    'companyService',
    'companyListService',
    'store',
    'appBaseUrl',
    'userService',
    'templateService',
    'paymentService',
    'templateModelService',
    'accountsExecutiveService',
    'prospectsService',
    'projectExporterService',
    'ticketService',
    'appConfigurationService',
    'videoTutorialService',
    'reportLinkService',
    'eventTrackerService',
    'dynamicAssetTypesService',
    'toastrService',
    'paymentLinksService'
  ]),

  apiClient: ctor(ApiClient, [], [['authService', 'setAuthService']]),
  projectReportService: ctor(ProjectReportService, ['apiClient', 'store', 'toastrService']),
  projectsService: ctor(ProjectsService, [
    'apiClient',
    'store',
    'eventTrackerService',
    'toastrService'
  ]),

  documentsService: ctor(DocumentsService, [
    'apiClient',
    'store',
    'projectsService',
    'eventTrackerService',
    'projectReportService'
  ]),

  companyService: ctor(
    CompanyService,
    [
      'apiClient',
      'store',
      'companyListService',
      'authService',
      'eventTrackerService'
    ],
    [['toastrService', 'setToastrService']]
  ),

  companyListService: ctor(CompanyListService, ['apiClient', 'store']),

  userService: ctor(UserService, ['apiClient', 'store']),

  templateService: ctor(TemplateService, ['apiClient', 'store']),

  templateService: ctor(TemplateService, ['apiClient', 'store']),

  utilsService: ctor(UtilsService, ['apiClient', 'store']),

  templateModelService: ctor(TemplateModelService, [
    'apiClient',
    'authService',
    'store'
  ]),

  toastrService: ctor(ToastrService, ['store']),

  accountsExecutiveService: ctor(AccountsExecutiveService, [
    'apiClient',
    'store'
  ]),

  videoTutorialsService: ctor(VideoTutorialsService, ['apiClient', 'store']),

  prospectsService: ctor(ProspectsService, ['apiClient', 'store']),

  projectExporterService: ctor(ProjectExporterService, ['apiClient', 'store']),

  ticketService: ctor(TicketService, ['apiClient', 'store']),
  featureFlagService: ctor(FeatureFlagService, ['apiClient', 'store']),
  dictionaryService: ctor(DictionaryService, ['apiClient', 'store', 'toastrService']),
  ssoConfigurationService: ctor(SsoConfigurationService, [
    'apiClient',
    'store'
  ]),
  appConfigurationService: ctor(AppConfigurationService, [
    'apiClient',
    'store'
  ]),
  videoTutorialService: ctor(VideoTutorialsService, ['apiClient', 'store']),
  placesService: ctor(PlacesService, ['apiClient', 'store']),
  fallbackExtractionService: ctor(FallbackExtractionService, ['apiClient']),
  reportLinkService: ctor(ReportLinkService, ['apiClient', 'store']),
  captureEmailService: ctor(CaptureEmailService, ['apiClient', 'store']),
  dynamicAssetTypesService: ctor(DynamicAssetTypesService, [
    'apiClient',
    'store'
  ]),
  paymentService: ctor(PaymentService, ['apiClient', 'authService', 'toastrService','store']),
  paymentLinksService: ctor(PaymentLinksService, ['apiClient', 'store', 'toastrService']),
  rootComponent: value(App),
  appBaseUrl: value(appBaseUrl)
};

export default services;
