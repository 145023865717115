// eslint-disable-next-line import/no-cycle
import { TARGET_SHEET_NAME } from "../../../app/DocExtractionComponent/DataSpreadSheet";
import BaseEvent from "./BaseEvent";

export default class RowChangedEvent extends BaseEvent {
	handle(datasheet, _, args) {
		const { sheetName, propertyName } = args;
		if (sheetName === TARGET_SHEET_NAME) {
			if (datasheet.isOSBaseType) {
				datasheet.props.documentRef.operatingStatementEvents();
			}
			if (datasheet.isRentRollBaseType && propertyName === 'deleteRows') {
				setTimeout(() => datasheet.props.documentRef.rentRollEvents(), 0);
			}
		}
	}
}