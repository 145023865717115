import { createDuck } from 'reaf';

const initialState = {
  document: null,
  workbookData: null,
  workbookDataCopy: null,
  extractedData: {
    data: {
      source: {
        columns: [],
        rows: []
      },
      target: {
        columns: [],
        rows: []
      }
    },
    raw: null
  },
  summaryData: {},
  cashFlowPeriodIdentifier: 'Col0',
  netRentalIncome: null,
  isTaggedPeriodEqualsMLReturnedPeriod: false,
  rowReverseMapping: [],
  useMonthlyData: false,
  floorPlanSummary: {},
  rentRolllConfig: {
    chargeCode: {
      config: {},
      chargeCodeDropdown: [],
      chargeCodeDropDownColMappings: [],
      chargeCodeUnitTypes: [],
    },
    mfRentRollConfig: {
      unitStatus: {
        unitStatusDropdown: [],
        config: {},
        tenantNameConfig: {}
      },
      floorPlan: {
        config: {},
        bedMapping: [],
        bathMapping: {}
      },
      leaseConfig: {
        leaseStartRange: [],
        leaseExpirtyRange: []
      },
      unitMixSummary: {
        summary: {},
        leaseTypeDropDown: []
      }
    },
    commercialRentRollConfig: {
      occupancyStatus: {
        config: [],
        occupancyMapping: []
      },
      leaseType: {
        config: {},
        leaseTypes: []
      },
      tenantType: {
        config: {},
        commercialMajorTenantTypePercent: 10,
        tenantTypes: []
      }
    }
  },
  projectDictionaryExists: false,
  assetType: '',
  templateTag: '',
  apiConfigIntegration: {}
};

const [
  {
    setDocument, setRawExtractedData, setExtractedData, setWorkbookData, setWorkbookDataCopy, setColumnHeadersList,
    setSummaryData, clearSummaryData, setStaticChargeCode, setStaticChargeCodeMapping, setChargeCodeUnitTypes,
    setChargeCodeConfig, isTaggedPeriodEqualsMLReturnedPeriod, setUnitTypeDropdown, setUnitStatus, setFloorPlan,
    setFloorPlanSummary, setFloorPlanBedMapping, setFloorPlanBathMapping, setLeaseConfig, setLeaseTypeDropDown, 
    setUnitMixSummary, setTenantNameConfig, setRetailOccupancy, setRetailOccupancyMapping, setProjectDictionaryExists,
    setRetailLeaseTypeConfig, setRetailLeaseTypeDropDown, setRetailTenantTypeConfig, setRetailMajorTenantTypePercent,
    setRetailTenantTypeDropDown, setNetRentalIncome, setRowReverseMapping, setUseMonthlyData, setAssetType, 
    setTemplateTag, setApiConfigIntegration, setCashFlowPeriodIdentifier
  },
  reducer
] = createDuck('autouw/currentDocument', {
  _initialState: initialState,

  setDocument: document => state => ({
    ...state,
    document
  }),

  setRawExtractedData: data => state => ({
    ...state,
    extractedData: {
      ...state.extractedData,
      raw: data
    }
  }),

  setWorkbookData: data => state => ({
    ...state,
    workbookData: data
  }),

  setWorkbookDataCopy: data => state => ({
    ...state,
    workbookDataCopy: data
  }),

  setColumnHeadersList: data => state => ({
    ...state,
    columnHeadersList: data
  }),

  setExtractedData: data => state => ({
    ...state,
    extractedData: {
      ...state.extractedData,
      data
    }

  }),

  setSummaryData: data => state => ({
    ...state,
    summaryData: data
  }),

  setCashFlowPeriodIdentifier: data => state => ({
    ...state,
    cashFlowPeriodIdentifier: data
  }),

  clearSummaryData: () => state => ({
    ...state,
    ...initialState
  }),

  isTaggedPeriodEqualsMLReturnedPeriod: isEqualDates => state => ({
    ...state,
    isTaggedPeriodEqualsMLReturnedPeriod: isEqualDates
  }),

  setRowReverseMapping: rowReverseMapping => state => ({
    ...state,
    rowReverseMapping
  }),
  setUseMonthlyData: useMonthlyData => state => ({
    ...state,
    useMonthlyData
  }),
  setChargeCodeConfig: config => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      chargeCode: {
        ...state.rentRolllConfig.chargeCode,
        config
      }
    }
  }),

  setStaticChargeCode: data => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      chargeCode: {
        ...state.rentRolllConfig.chargeCode,
        chargeCodeDropdown: data
      }
    }
  }),

  setStaticChargeCodeMapping: chargeCodeDropDownColMappings => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      chargeCode: {
        ...state.rentRolllConfig.chargeCode,
        chargeCodeDropDownColMappings
      }
    }
  }),

  setChargeCodeUnitTypes: data => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      chargeCode: {
        ...state.rentRolllConfig.chargeCode,
        chargeCodeUnitTypes: data
      }
    }
  }),

  setUnitTypeDropdown: unitStatusDropdown => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        unitStatus: {
          ...state.rentRolllConfig.mfRentRollConfig.unitStatus,
          unitStatusDropdown
        }
      }
    }
  }),

  setLeaseTypeDropDown: leaseTypeDropDown => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        unitMixSummary: {
          ...state.rentRolllConfig.mfRentRollConfig.unitMixSummary,
          leaseTypeDropDown
        }
      }
    }
  }),

  setUnitMixSummary: summary => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        unitMixSummary: {
          ...state.rentRolllConfig.mfRentRollConfig.unitMixSummary,
          summary
        }
      }
    }
  }),

  setUnitStatus: config => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        unitStatus: {
          ...state.rentRolllConfig.mfRentRollConfig.unitStatus,
          config
        }
      }
    }
  }),

  setTenantNameConfig: tenantNameConfig => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        unitStatus: {
          ...state.rentRolllConfig.mfRentRollConfig.unitStatus,
          tenantNameConfig
        }
      }
    }
  }),

  setFloorPlan: config => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        floorPlan: {
          ...state.rentRolllConfig.mfRentRollConfig.floorPlan,
          config
        }
      }
    }
  }),

  setFloorPlanSummary: config =>  state => ({
    ...state,
    floorPlanSummary: config
  }),

  setFloorPlanBedMapping: bedMapping => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        floorPlan: {
          ...state.rentRolllConfig.mfRentRollConfig.floorPlan,
          bedMapping
        }
      }
    }
  }),

  setFloorPlanBathMapping: bathMapping => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        floorPlan: {
          ...state.rentRolllConfig.mfRentRollConfig.floorPlan,
          bathMapping
        }
      }
    }
  }),

  setLeaseConfig: (leaseStartRange, leaseExpirtyRange) => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      mfRentRollConfig: {
        ...state.rentRolllConfig.mfRentRollConfig,
        leaseConfig: {
          ...state.rentRolllConfig.mfRentRollConfig.leaseConfig,
          leaseStartRange,
          leaseExpirtyRange
        }
      }
    }
  }),

  setRetailOccupancy: config => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      commercialRentRollConfig: {
        ...state.rentRolllConfig.commercialRentRollConfig,
        occupancyStatus: {
          ...state.rentRolllConfig.commercialRentRollConfig.occupancyStatus,
          config
        }
      }
    }
  }),

  setRetailOccupancyMapping: occupancyMapping => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      commercialRentRollConfig: {
        ...state.rentRolllConfig.commercialRentRollConfig,
        occupancyStatus: {
          ...state.rentRolllConfig.commercialRentRollConfig.occupancyStatus,
          occupancyMapping
        }
      }
    }
  }),

  setProjectDictionaryExists: projectDictionaryExists => state => ({
    ...state,
    projectDictionaryExists
  }),
  setAssetType: assetType => state => ({
    ...state,
    assetType
  }),
  setTemplateTag: templateTag => state => ({
    ...state,
    templateTag
  }),

  setRetailLeaseTypeConfig: config => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      commercialRentRollConfig: {
        ...state.rentRolllConfig.commercialRentRollConfig,
        leaseType: {
          ...state.rentRolllConfig.commercialRentRollConfig.leaseType,
          config
        }
      }
    }
  }),

  setRetailLeaseTypeDropDown: leaseTypes => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      commercialRentRollConfig: {
        ...state.rentRolllConfig.commercialRentRollConfig,
        leaseType: {
          ...state.rentRolllConfig.commercialRentRollConfig.leaseType,
          leaseTypes
        }
      }
    }
  }),

  setRetailTenantTypeConfig: config => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      commercialRentRollConfig: {
        ...state.rentRolllConfig.commercialRentRollConfig,
        tenantType: {
          ...state.rentRolllConfig.commercialRentRollConfig.tenantType,
          config
        }
      }
    }
  }),

  setRetailMajorTenantTypePercent: commercialMajorTenantTypePercent => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      commercialRentRollConfig: {
        ...state.rentRolllConfig.commercialRentRollConfig,
        tenantType: {
          ...state.rentRolllConfig.commercialRentRollConfig.tenantType,
          commercialMajorTenantTypePercent
        }
      }

    }
  }),

  setRetailTenantTypeDropDown: tenantTypes => state => ({
    ...state,
    rentRolllConfig: {
      ...state.rentRolllConfig,
      commercialRentRollConfig: {
        ...state.rentRolllConfig.commercialRentRollConfig,
        tenantType: {
          ...state.rentRolllConfig.commercialRentRollConfig.tenantType,
          tenantTypes
        }
      }
    }
  }),

  setNetRentalIncome: netRentalIncome => state => ({
    ...state,
    netRentalIncome
  }),

  setApiConfigIntegration: config => state => ({
    ...state,
    apiConfigIntegration: config
  })

});

export {
  setDocument, setRawExtractedData, setExtractedData, setWorkbookData, setWorkbookDataCopy, setColumnHeadersList,
  setSummaryData, clearSummaryData, setChargeCodeConfig, setStaticChargeCode, setChargeCodeUnitTypes, 
  isTaggedPeriodEqualsMLReturnedPeriod, setUnitTypeDropdown, setUnitStatus, setFloorPlan, setFloorPlanSummary,
  setFloorPlanBedMapping, setFloorPlanBathMapping, setLeaseConfig, setLeaseTypeDropDown, setUnitMixSummary,
  setTenantNameConfig, setRetailOccupancy, setRetailOccupancyMapping, setProjectDictionaryExists, 
  setRetailLeaseTypeConfig, setRetailLeaseTypeDropDown, setRetailTenantTypeConfig, setRetailMajorTenantTypePercent, 
  setRetailTenantTypeDropDown, setNetRentalIncome, setRowReverseMapping, setUseMonthlyData, setStaticChargeCodeMapping,
  setAssetType, setTemplateTag, setApiConfigIntegration, setCashFlowPeriodIdentifier
};

export default reducer;
