import React from 'react'
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { loadingBackdropWithProgressSelector } from '../../../store/selectors';

function ProjectReportSkeletonLoader({ show, percentage }) {
  return (
    <>
      {
        show && (
          <div className="project-report-skeleton position-absolute zindex-modal-backdrop w-100 vh-100 bg-white">
            <div className="row mb-2">
              <div className="col-md-6"><Skeleton count={1} height="4vh" /></div>
              <div className="col-md-3" />
              <div className="col-md-2">
                <Skeleton count={1} height="4vh" />
              </div>
              <div className="col-md-1 pl-1">
                <Skeleton count={1} height="4vh" />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-1"><Skeleton count={1} height="3vh" /></div>
              <div className="col-md-11 pl-0"><Skeleton count={1} height="3vh" /></div>
            </div>
            <div className="row mb-2">
              {
                Array(12).fill(0).map((gridItem, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="col-sm-1 px-2" key={`${gridItem}_${index}`}>
                    <Skeleton count={28} height="2vh" />
                  </div>
                ))
              }

            </div>
            <div className="row">
              <div className="col-md-12">
                <Skeleton count={1} height="25vh" />
              </div>
            </div>
            <div className="position-fixed fixed-bottom text-right mb-1 mr-2">
              <div className="d-flex align-items-center justify-content-end">
                <div className="mr-2" style={{ width: '40px' }}>
                <CircularProgressbar 
                  strokeWidth={10} 
                  value={percentage} 
                  text={`${percentage}%`} 
                  styles={
                    buildStyles({
                      textColor: '#000',
                      trailColor: '#d6d6d6',
                      textSize: '20px',
                    })
                  }
                />
                </div>
                
                <span>Please wait..., while we generate your report</span>

              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const lbState = loadingBackdropWithProgressSelector(state);
  return {
    show: lbState.show,
    percentage: lbState.loadingPercent
  };
};

export default connect(mapStateToProps)(ProjectReportSkeletonLoader);