/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { authSelector } from '../../../store/selectors';
import StaticPageFooterNavigation from '../../core/StaticPageWithNav/StaticPageFooterNavigation';
import StaticPageWithNav from '../../core/StaticPageWithNav/StaticPageWithNav';

function PrivacyPolicy({ style, isLoggedIn }) {
  return (
    <div className={`container-fluid ${style.pageOuterSection}`}>
      <div className="row">
        <div className={`col-12 d-flex border-bottom ${style.sectionHeader} ${!isLoggedIn && 'px-5 py-4'}`}>
          <h3 className={!isLoggedIn && 'px-4 ml-1'}>Privacy Policy</h3>
          <span>Last Update: January 5, 2018</span>
        </div>
      </div>
      <StaticPageWithNav
        isLoggedIn={!!isLoggedIn}
        content={
          <section>
            <div>
              <p>
                This privacy policy (the &quot;
                      <strong>Privacy Policy</strong>
          &quot; or &quot;
                      <strong>Policy</strong>
          &quot;) sets out the terms
                      and conditions under which you may accessor download content that we offer on the website located at
                      https://clik.ai/ (the &quot;
                      <strong>Website</strong>
          &quot;), and use our services and other features of the Website
                      (collectively, the &quot;
                      <strong>Services</strong>
          &quot;). This Policy applies to all users of the Website and the
                      Services and is incorporated into and made a part of the Terms of Service.&quot;We&quot;, &quot;us&quot; or &quot;our&quot; is defined
                      as
                      <strong>Clik Technologies Inc</strong>
          .(&quot;
                      <strong>Clik</strong>
          &quot;) and its affiliates, officers, agents,
                      partners, and employees. Where applicable, &quot;you&quot; or &quot;your&quot; is defined as an individual user of the Website
                      and/or Services. Your use of the Website and Services means that you accept, and agree to abide by, all
                      provisions of this Privacy Policy.
                    </p>
              <div id="application" className="border-bottom pb-4">
                <h2 className="clikai-subHeading">Application of This Policy</h2>
                <p>
                  We have created this Privacy Policy to explain the type of information gathered from your visit to and use
                  of the Website and Services and how such information may be used. This Privacy Policy will be continuously
                  assessed against new technologies, business practices and our users’ needs. Please check this Privacy Policy
                  from time to time for updates by checking the date of the &quot;Last Update&quot; at the top of this page. We will
                  post any Privacy Policy changes on this page and, if the changes are significant, we will provide a more
                  prominent notice (including, for certain Services, email notification of Privacy Policy changes). You
                  acknowledge and agree that your access to our Website and your use of the Services following any posted
                  changes to the Privacy Policy indicate your acceptance of such changes.
                </p>
              </div>
              <div id="personalInfo" className="border-bottom pb-4">
              <h2 className="clikai-subHeading">What is Personal Information?</h2>
              <p>
                This policy complies with Canada’s Personal Information Protection and Electronic Documents Act
                (&quot;
                      <strong>PIPEDA</strong>
          &quot;). In this Privacy Policy, &quot;
                      <strong>Personal Information</strong>
          &quot; means
                      information that identifies or can be used to identify a living individual, including, without limitation,
                      your name, address, e-mail address, country of residence, and other information when such information is
                      combined with other information.Personal Information does not include business contact information and also
                      does not include &quot;
                      <strong>aggregate</strong>
          &quot; information, which is data we collect about and through the
                      use of our Website and the Services and may include anonymized data which does not identify any individuals.
                      Our Privacy Policy does not restrict or limit our collection and use of aggregate information or other types
                      of non-personal information.
                    </p>
              </div>
              <div id="consent" className="border-bottom pb-4">
                <h2 className="clikai-subHeading">Consent</h2>
                <p>
                  By accessing the Website and using the Services, you fully understand, accept, and voluntarily consent to
                  the collection, use, retention and disclosure of Personal Information in accordance with this Privacy
                  Policy. It is always your choice whether or not you provide us with your Personal Information; however, a
                  decision to withhold Personal Information may restrict or prevent us from providing you certain features of
                  the Services.
                        {' '}
                </p>
              </div>
              <div id="collectionPersonalInfo" className="border-bottom pb-4">
                <h2 className="clikai-subHeading">Collection of Personal Information</h2>
                <p>
                  Certain features of the Services require you to first complete a registration process and establish an
                  account (&quot;
                        <strong>Account</strong>
            &quot;). In doing so, you will be required to provide basic information such as
                        your first and last name, valid email address and establish a password (&quot;
                        <strong>
                    Account
                    Information
                        </strong>
            &quot;). We will retain Account Information and associate it with your Account. You are
                        responsible for updating and maintaining the truth and accuracy of the Personal Information you provide to
                        us relating to your Account.
                      </p>
                </div>
                <div id="howToOpt" className="border-bottom pb-4">
                  <h2 className="clikai-subHeading">How to Opt-Out / Withdraw Consent</h2>
                  <p>
                    You can withdraw your consent for the use of your Personal Information at any time by requesting the
                    deletion of your Account or by providing written notice to us of that withdrawal. However, you acknowledge
                    that we may retain Personal Information for audit or archival purposes or as required by applicable federal
                    and provincial laws. You can also review and update your Personal Information through the user features in
                    your Account.If you opted-in to receive marketing communications, but wish to discontinue receiving the
                    same, you may follow the &quot;
                          <strong>unsubscribe</strong>
              &quot; instructions included within each e-mail
                          communication. Though you may choose to not receive marketing communications, you cannot opt-out of
                          receiving transactional or Account communications related to the Services that you have requested (e.g.
                          service confirmation e-mails and receipts) or important updates from us from time to time relating to the
                          Services or updates to the legal terms that govern your use of the Website and the Services.
                        </p>
                </div>
                <div id="howWeUse" className="border-bottom pb-4">
                  <h2 className="clikai-subHeading">How We Use Your Information</h2>
                    <p>
                      We collect Personal Information by fair and lawful means and limit the collection of Personal Information
                      to what is necessary for the purpose for which it was collected. You acknowledge that we may use Personal
                      Information that you voluntarily disclose to us to:
                          </p>
                    <ul>
                      <li>process your transactions;</li>
                      <li>respond to your request for products or services offered through the Website;</li>
                      <li>enhance products or services to better suit your needs; and</li>
                      <li>
                        notify you about products, services, and other opportunities we think will be of interest to you, at all
                        times in compliance with applicable laws.
                            </li>
                    </ul>
                  </div>
                  <div id="discloseInfo" className="border-bottom pb-4">
                  <h2 className="clikai-subHeading">Disclosure of Your Personal Information</h2>
                <p>
                  Except as otherwise disclosed to you, we will not sell or rent your Personal Information to any other party
                  and will only use and disclose your Personal Information for the purposes for which it was collected as
                  described in this Privacy Policy. However, you agree that we may disclose your Personal Information in the
                  circumstances set out below:
                      </p>
                <ul>
                  <li>we may disclose your Personal Information with your prior consent;</li>
                  <li>
                    we may be required by law to disclose Personal Information without your consent in the event of
                    emergency situations or when required by government or other legal authority;
                        </li>
                  <li>
                    we also disclose Personal Information to third parties in connection with a corporate re-organization,
                    merger or amalgamation, or the sale of all or substantially all of our assets, provided that, where
                    appropriate, any party to whom the information is disclosed is bound by agreements or obligations, and
                    required to use or disclose your Personal Information in a manner consistent with the use and disclosure
                    provisions of this Privacy Policy, unless you consent otherwise; and
                        </li>
                  <li>
                    we may share your Personal Information with service providers who perform certain of the Services on our
                    behalf. For example, we may hire other companies to handle the processing of payments, collect debts, to
                    provide data storage, to host websites, to fulfill orders, to assist in direct marketing, to conduct
                    audits, etc. Those companies will be permitted to obtain only the Personal Information they need only as
                    necessary to provide services to us. Each company is bound by agreement or obligations with us to
                    implement and maintain reasonable security procedures and practices in order to use and disclose your
                    Personal Information in a manner consistent with the use and disclosure provisions of this Privacy Policy,
                    unless you consent otherwise. We endeavor to engage only such services as can guarantee that your Personal
                    Information remains in Canada unless it is in transit to or from you in another jurisdiction. We take all
                    reasonable measures and precautions to protect your Personal Information when it is being handled by our
                    service providers. If you wish to obtain more information on our use of service providers and ourpolicies
                    and practices regarding such service providers, please contact us as set forth below.
                        </li>
                </ul>
                </div>
                <div id="retention" className="border-bottom pb-4">
                <h2 className="clikai-subHeading">Retention of Personal Information</h2>
                <p>
                  We maintain information about you for as long as we provide Services to you, and for as long as it remains
                  necessary for identified purposes or as required by law which may extend beyond the termination of our
                  relationship with you. We may retain certain data as necessary to prevent fraud or future abuse, or for
                  legitimate business purposes, such as analysis of aggregated, non-personally-identifiable data, Account
                  recovery, or if required by law. All retained Personal Information will remain subject to the terms of this
                  Privacy Policy. If you request that your name be removed from our databases, it may not be possible to
                  completely delete all your Personal Information due to technological and legal constraints.
                      </p>
                </div>
                <div id="safeguards" className="border-bottom pb-4">
                <h2 className="clikai-subHeading">Safeguards We Have in Place</h2>
                <p>
                  We store and process your Personal Information on our servers in Canada as well other jurisdictions in
                  which our Website and Services’ technology platform resides. Accordingly, your data may be available to
                  government bodies or their agencies in Canada as well other jurisdictions under a lawful order, irrespective
                  of the safeguards we have put in place for the protection of your Personal Information. We employ reasonable
                  managerial and technical measures to ensure that your Personal Information is secure. Notwithstanding the
                  forgoing, we cannot guarantee that our security measures will prevent our computers from being illegally
                  accessed, and the data on them stolen or altered. We assume no liability or responsibility to you or to any
                  third party arising out of any loss, misuse, destruction, or alteration of your Personal Information.
                </p>
                </div>            
                <div id="collectionNonPersonalInfo" className="border-bottom pb-4">
                <h2 className="clikai-subHeading">Collection of Non-Personal Information</h2>
                <p>
                  You acknowledge and agree that from time to time we may collect non-personal information from your use of
                  the Services, which may include, without limitation, server log files, computer type, IP addresses, or areas
                  of a site being clicked on most frequently, OS version, Internet browser, and Internet browser version, in
                  order to help us better deliver the Services and user experience. We and our service providers (including ad
                  servers, outbound email vendors, affiliate vendors and search engine advertising agencies, and providers of
                  web analytics tools) and business partners may also use &quot;cookies&quot; as well as so-called &quot;pixel tags,&quot; &quot;web
                  beacons,&quot; &quot;clear GIFs&quot; and other similar technologies to help us deliver a better service and user
                  experience and to facilitate your ongoing access to and use of the Services.Cookies are files saved to your
                  computers through your Web browser to enable websites or service providers systems to recognize your browser
                  and capture and remember certain information. We use cookies to understand and save your preferences for
                  future visits and to compile aggregate data about site traffic and site interaction, so that we can offer
                  better site experiences and tools in the future. If you do not want information collected through the use of
                  cookies, there is a simple procedure in most browsers that allows you to automatically decline cookies, or
                  to be given the choice of declining or accepting the transfer of a particular cookie, or cookies from a
                  particular website, to your computer. If you choose to reject all cookies, you may not be able to access the
                  Services.
                      </p>
                </div>       
                <div id="googleFb" className="border-bottom pb-4">
                <h2 className="clikai-subHeading">Google; Facebook</h2>
                <p>
                  We use remarketing services to advertise on third party websites to you after you have visited our
                  Services. We, and our third party vendors, use Cookies to inform, optimize and serve ads based on your past
                  visits to our Services. In certain circumstances, your implied consent to our accessing, using or disclosing
                  your Personal Information is provided if you do not instruct your web browser to refuse all Cookies or to
                  indicate when a Cookie is sent or if you do not opt out of Google Analytics or other similar services, as
                  indicated below.
                        {' '}
                </p>
                <p>
                  <strong>(a) Google Inc. (&quot;Google&quot;):</strong>
                  {' '}
                Google AdWords is a remarketing service provided by Google.
                            You may opt-out of Google Analytics for Display Advertising and customize Google’s Display Network ads by
                            visiting Google’s Ads Setting page. Google also recommends installing the Google Analytics Opt-Out Browser
                            Add-On. This service provided by Google allows visitors to prevent their data from being collected and used
                            by Google Analytics.
                          </p>
                    <p>
                      <strong>(b) Facebook, Inc. (&quot;Facebook&quot;):</strong>
                      {' '}
                We may use Facebook’s advertising services from time to
                            time. You can opt-out of interest-based advertising from Facebook by visiting their website.
                          </p>
                
              </div>  
              <div id="thirdPartyLinks"  className="border-bottom pb-4">
                <h2 className="clikai-subHeading">Third-Party Links</h2>
                <p>We do not include or offer third-party products or services on the Website through the use of links.</p>
              </div>

              <div id="paymentProcessor"  className="border-bottom pb-4">
              <h2 className="clikai-subHeading">Payment Processor</h2>
                <p>
                  We provide payment processors with the minimal set of personal and billing information required to process
                  your payment and minimize fraudulent payment risk. You acknowledge and agree that we make no representation,
                  warranty, covenant, or claim regarding, and we expressly disclaim and deny any responsibility or liability
                  for, the privacy practices in regard to any Personal Information that we disclose on your behalf or you
                  provide to these providers directly or any other party in relation to purchasing a product or service from
                  our Website. When payment is made by credit card, credit card information, including the card number, expiry
                  date and security code, are securely collected by us and passed directly to payment processors over a secure
                  socket layer. Credit card data is not retained by us in any form.
                      </p>
              </div>

               <div id="interpretation"  className="border-bottom pb-4"> 
                <h2 className="clikai-subHeading">Interpretation of This Privacy Policy</h2>
                <p>
                  This Privacy Policy does not create or confer upon any individual any rights, or impose any obligations on
                  us, in addition to any rights or obligations imposed by Canada’s applicable federal and provincial privacy
                  laws. Should there be any inconsistency between this Privacy Policy and Canada’s applicable federal and
                  provincial privacy laws, this Privacy Policy will be interpreted to comply with the applicable privacy laws.
                  Unless otherwise stated above, the Services that we provide are controlled and operated by us from Canada,
                  and we do not intend that the Services subject us to the laws or jurisdiction of any state, country or
                  territory other than that of Canada. We do not represent or warrant that the Services are appropriate or
                  available for use in any particular jurisdiction. Those who choose to access the Website and the Services do
                  so on their own initiative and at their own risk, and are responsible for complying with all local laws,
                  rules and regulations. We may limit the Services’ and Website’s availability, in whole or in part, to any
                  person, geographic area or jurisdiction we choose, at any time and in our sole discretion.
                      </p>
              </div>   
             <div id="privacyPolicyInfo"  className="border-bottom pb-4">
              <h2 className="clikai-subHeading">Privacy Policy Information</h2>
                <p>
                  If you have any questions about this Privacy Policy or concerns about how we manage your personal
                  information, please contact us by mail or email at:
                        <a href="mailto:info@clik.ai" className="text-primary">info@clik.ai</a>

            or by mail
                        addressed to Clik Technologies Inc. 10 Dundas Street East, Toronto, ON M5B 2G9 Canada.
                      </p>

                <p>
                  We will endeavor to answer your questions and advise you of any steps taken to address the issues raised by
                  you.
                      </p>
              </div> 
            </div>
          </section>
        }
        navigation={[
          { id: 'application', name: 'Application of This Policy' },
          { id: 'personalInfo', name: 'What is Personal Information?' },
          { id: 'consent', name: 'Consent' },
          { id: 'collectionPersonalInfo', name: 'Collection of Personal Information' },
          { id: 'howToOpt', name: 'How to Opt-Out / Withdraw Consent' },
          { id: 'howWeUse', name: 'How We Use Your Information' },
          { id: 'discloseInfo', name: 'Disclosure of Your Personal Information' },
          { id: 'retention', name: 'Retention of Personal Information' },
          { id: 'safeguards', name: 'Safeguards We Have in Place' },
          { id: 'collectionNonPersonalInfo', name: 'Collection of Non-Personal Information' },
          { id: 'googleFb', name: 'Google; Facebook' },
          { id: 'thirdPartyLinks', name: 'Third-Party Links' },
          { id: 'paymentProcessor', name: 'Payment Processor' },
          { id: 'interpretation', name: 'Interpretation of This Privacy Policy' },
          { id: 'privacyPolicyInfo', name: 'Privacy Policy Information' },
        ]}
        footerNavigation = {
          !!isLoggedIn && <StaticPageFooterNavigation
            leftMenuInfo = {{ route: 'tips', displayName: 'Tutorial' }} 
          />
        }
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: authSelector(state)?.token
});

export default connect(mapStateToProps)(
  withServices('store')(PrivacyPolicy)
);

