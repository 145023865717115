import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import SpreadSheet from '../../core/SpreadSheet/SpreadSheet';
import loadingIcon from '../../../assets/images/Gear-4s-60px.svg';
import SpreadSheets from '../../core/Spreadjs/SpreadSheets';
import { setCurrentActiveViewerSheet } from '../../../store/currentProject';
import { currentProjectViewerActiveSheetSelector } from '../../../store/selectors';
import { useFeatureFlag } from '../../../providers/FeatureFlagProvider';

function XLViewer({
  project,
  document,
  currentActiveSheetName,
  documentsService,
  store,
  onDataLoad,
  sheetHeight = {}
}) {
  const [data, setData] = useState(null);
  const [showFallback, setShowFallback] = useState(false);
  const spreadSheetRef = useRef();
  const [canUseSpreadV2] = useFeatureFlag('spreadV2', false);

  useEffect(() => {
    documentsService
      .getJsonFromXLDocumentFile(project, document)
      .then((jsonData) => {
        setShowFallback(false);
        setData(jsonData);
      })
      .catch(() => {
        setShowFallback(true);
      });
  }, [project, document, documentsService]);

  useEffect(() => {
    if (data && spreadSheetRef.current) {
      const sheet = spreadSheetRef.current;
      sheet.workbook.fromJSON(data);
      sheet.setReadonly(true);
      const sheetNames = sheet.getSheetNames();

      onDataLoad &&
        onDataLoad({
          sheetNames
        });
    }
  }, [data, spreadSheetRef.current]);

  useEffect(() => {
    if (!!currentActiveSheetName && spreadSheetRef.current) {
      spreadSheetRef.current.setActiveSheet(currentActiveSheetName);
    }
  }, [currentActiveSheetName, store, spreadSheetRef.current]);

  // Only for Spread V1
  useEffect(() => {
    if (currentActiveSheetName) {
      store.dispatch(setCurrentActiveViewerSheet(currentActiveSheetName));
    }
  }, [store, currentActiveSheetName]);

  const onSheetChange = useCallback(
    (sheetName) => {
      store.dispatch(setCurrentActiveViewerSheet(sheetName));
    },
    [store]
  );

  return (
    <div className="vertical-section">
      {
        // eslint-disable-next-line no-nested-ternary
        data ? (
          canUseSpreadV2 ? (
            <SpreadSheets
              ref={spreadSheetRef}
              showToolbar={false}
              readonly
              onReady={() => {}}
              sheetHeight={sheetHeight}
            />
          ) : (
            <SpreadSheet
              readonly
              data={data}
              onDataLoad={onDataLoad}
              currentActiveSheetName={currentActiveSheetName}
              setCurrentActiveSheetName={onSheetChange}
              hasAutoWidth
              sheetHeight={sheetHeight}
            />
          )
        ) : !showFallback ? (
          <div className="d-flex justify-content-center mt-5">
            <img src={loadingIcon} height={20} alt="Loading Icon" />
            <span className="h6 ml-2 mt-auto mb-auto">Loading Document</span>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <span className="h6 ml-2 mt-auto mb-auto">
              App viewers do not support XLS file format. Please save the file
              to XLSX format and upload it again.
            </span>
          </div>
        )
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentActiveSheetName: currentProjectViewerActiveSheetSelector(state)
});

export default connect(mapStateToProps)(
  withServices('documentsService', 'store')(XLViewer)
);
