import { setPaymentLinks } from "../store/paymentLinks";


class PaymentLinksService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  async createPaymentLink(values) {
    const { name, amount, quantity } = values;
    const link = await this.apiClient.post(
      '/payments/createPaymentLink',
      { name, quantity, amount: amount * 100 }, // converting amount to dollar
    );
    await this.listPaymentLinks();
    return link;
  }

  async listPaymentLinks() {
    const result = await this.apiClient.get('/payments/listPaymentLinks');
    const { data } = result.response;
    this.store.dispatch(setPaymentLinks(data));
    return data;
  }
}

export default PaymentLinksService;
