import React, { useCallback, useRef } from 'react';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import SocialLoginForm from '../../app/SocialLoginForm/SocialLoginForm';
import clikaiBannerImg from '../../../assets/images/clikai-hero.gif';
import bellWeatherClientLogo from '../../../assets/images/client-logo/bell-weather.png';
import northMarqClientLogo from '../../../assets/images/client-logo/northMarq.png';
import redCapitalClientLogo from '../../../assets/images/client-logo/red-capital.png';
import newMarkClientLogo from '../../../assets/images/client-logo/ngkf.png';
import orixClientLogo from '../../../assets/images/client-logo/orix.png';
import style from './TrialLoginPage.module.scss';
import config from '../../../config';

function TrialLoginPage() {
  const scheduleAppointment = useRef(null)

  const onScheduleAppointment = useCallback(() => {
    scheduleAppointment.current.scrollIntoView({ behavior: 'smooth', block: 'start' });    
  }, [scheduleAppointment])

  return (
    <LoggedOutUserPage isTrial onScheduleAppointment = {onScheduleAppointment}>
      <div className="vertical-section bg-light">
        <div className="container mt-3">
          <div className={`row ${style.responsiveTopMargin}`}>
            <div className="col-md-6 col-12">
              <h1 className={style.clikaiTitle}>
                <span>#1 Automation software</span> for Banks, CRE Investors and Servicers
              </h1>
              <p className={`my-4 ${style.clikaiSubTitle}`}>Get started with Clik tools, and upgrade as you grow.</p>
              <SocialLoginForm />
              <ul className={style.clikFeatures}>
                <li>Easy Setup</li>
                <li>Fast and Secure</li>
                <li>24 X 7 Support</li>
              </ul>
            </div>
            <div className={`col-md-6 col-12 text-right ${style.bannerContainer}`}>
              <div className={`${style.clikaiBanner}`}>
                <img src={clikaiBannerImg} alt="Clik Banner" />
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xs-12 col-md-12 col-sm-12 mt-5">
                <ul className={`d-flex justify-content-between ${style.clikClientList}`}>
                    <li className="my-2"><img src={bellWeatherClientLogo} alt="bell weather"/></li>
                    <li className="my-2"><img src={northMarqClientLogo} alt="north marq"/></li>
                    <li className="my-2"><img src={redCapitalClientLogo} alt="red capital"/></li>
                    <li className="my-2"><img src={newMarkClientLogo} alt="new mark"/></li>
                    <li className="my-2"><img src={orixClientLogo} alt="orix"/></li>
                </ul>
            </div>
          </div>  
          <div className="row mt-4" ref={scheduleAppointment}>
            <div className="col-xs-12 col-md-12 col-sm-12">
                <h3 className={style.heading}>Schedule an Appointment</h3>
                <iframe
                  title='Calendar'
                  src={config.calendly.url}
                  width="100%"
                  height="1000px"
                  frameBorder="0"
                />
            </div>
          </div>
        </div>

      </div>
    </LoggedOutUserPage>
  );
}

export default TrialLoginPage;
