import React from 'react';
import { connect } from 'react-redux';
import { companyTemplatesSelector, currentProjectSelector } from '../../../store/selectors';

const UpdateDDConfirmationMessage = ({ template }) => (
  <div>
    The changes made are irreversible and will impact the future predictions for
    {`"${template.templateName}"`}
    template.
    <br />
    <br />
    Press confirm if you are sure about the classification changes made.
  </div>
);

const mapStateToProps = (state) => {
  const project = currentProjectSelector(state);
  const template = companyTemplatesSelector(state).find(template => template.uuid === project.templateUuid);
  return {
    template
  };
};

export default connect(mapStateToProps)(UpdateDDConfirmationMessage);
