import React from 'react';
import style from './DocExtractionAffixTableWrapper.module.scss';

function DocExtractionAffixTableWrapper({ children }) {
  return (
    <div className={style.tableWrapper}>
      {children}
    </div>
  );
}

export default DocExtractionAffixTableWrapper;
