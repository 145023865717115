import React from 'react';
import { withServices } from 'reaf';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../core/Button/IconButton';

const DownloadTicketAttachment = ({ ticketService, ticket, ticketAttachment }) => (
  ticketAttachment && (
    <span>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          ticketService.downloadTicketAtttachment(ticket, ticketAttachment);
        }}
        variant="outline-primary"
        title={ticketAttachment.fileName}
        icon={faPaperclip}
      />
    </span>
  )
);

export default withServices('ticketService')(DownloadTicketAttachment);
