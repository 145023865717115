import React from 'react';
import {DoucmentValidationStatus as ValidationStatus} from '../../../constants';

function DisplayDocumentValidationStatus({status}) {
  const displayJobStatus = ValidationStatus[status];
  return (
    <span>
      {displayJobStatus && (
        <span>
          {displayJobStatus.icon}
          {' '}
          {displayJobStatus.label}
          {' '}
        </span>
      )}
    </span>
  );
}

export default DisplayDocumentValidationStatus;
