import React, { useCallback, useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { sumBy, isNil, groupBy } from 'lodash';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import UnitStatusOptions from './UnitStatusOptions';
import DocExtractionAffixTableWrapper from '../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import DocExtractionAffixTotalCard from '../DocExtractionAffixTotalCard/DocExtractionAffixTotalCard';
import {
  currentDocumentUnitStatusSelector,
  currentDocumentStaticUnitStatusSelector,
  readOnlyAccessSelector
} from '../../../store/selectors';
import { setUnitStatus } from '../../../store/currentDocument';
import {
  DisplayAmountFormat,
  displayNumberInUSFormat
} from '../../../utils/utils';
import {
  rentRollConfigTabNames,
  rentRollAffixTabViewName,
  affixTotalColors
} from '../../../constants';
import style from './DocExtractionUnitStatusConfig.module.scss';
import RentRollConfigHeader from '../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../DocExtractionSummary/DocExtractionWithoutSummary';
import DocExtractionAffixChart from '../DocExtractionAffixChart/DocExtractionAffixChart';
import messages from '../../../../locales/en-US';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import {
  AffixConfigSaved,
  AffixSidebarViewChanged
} from '../../../constants/eventTrackerMessage';

function DocExtractionUnitStatusConfig({
  unitStatusConfigData,
  staticUnitStatus,
  onConfigSubmit,
  onSlidePaneClose,
  store,
  eventTrackerService,
  readOnlyAccess
}) {
  const { TABLE_VIEW } = rentRollAffixTabViewName;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeViewName, setActiveViewName] = useState(TABLE_VIEW);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    RENT_ROLL: {
      MULTIFAMILY: { TOTAL_MARKET_RENT, TOTAL_MONTHLY_RENT, TOTAL_UNITS }
    }
  } = affixTotalColors;

  const onSubmit = useCallback(
    async (...args) => {
      const [formValue] = args;
      setIsSubmitting(true);
      store.dispatch(setUnitStatus(formValue));
      await onConfigSubmit();
      eventTrackerService.track(AffixConfigSaved);
      setIsSubmitting(false);
    },
    [onConfigSubmit, store]
  );

  const getUnitPercentage = useCallback(
    (unit = 0) => {
      const unitCount = sumBy(Object.values(unitStatusConfigData), 'count');
      return !isNil(unitCount)
        ? Number((unit / unitCount) * 100).toFixed(2)
        : 0;
    },
    [unitStatusConfigData]
  );

  const getChartData = useCallback(() => {
    const chartConfig = [];
    const userConfigData = groupBy(unitStatusConfigData, 'category');
    Reflect.ownKeys(userConfigData).forEach((uniStatusKeyName) => {
      const totalCountSum = sumBy(userConfigData[uniStatusKeyName], 'count');
      chartConfig.push({
        name: uniStatusKeyName,
        value: totalCountSum,
        tabName: rentRollConfigTabNames.UNIT_STATUS
      });
    });

    const chartFilterConfig = Reflect.ownKeys(chartConfig[0]);
    return { chartConfig, chartFilterConfig };
  }, [unitStatusConfigData]);

  const validateCardTotals = useCallback((keyName, isCountTotal = false) => {
    if (isCountTotal) {
      return !isNil(sumBy(Object.values(unitStatusConfigData), keyName))
        ? sumBy(Object.values(unitStatusConfigData), keyName)
        : 0;
    } else {
      return !isNil(
        displayNumberInUSFormat(
          sumBy(Object.values(unitStatusConfigData), keyName)
        )
      ) ? (
        <DisplayAmountFormat
          number={sumBy(Object.values(unitStatusConfigData), keyName)}
        />
      ) : (
        0
      );
    }
  });

  return (
    <>
      {Reflect.ownKeys(unitStatusConfigData).length > 0 ? (
        <>
          <RentRollConfigHeader
            title="Configure Occupancy Code/Unit Status"
            activeViewName={activeViewName}
            onTabViewChange={(activeView) => {
              setActiveViewName(activeView);
              eventTrackerService.track(AffixSidebarViewChanged, {
                view: activeView
              });
            }}
          />
          <Formik
            key="unitStatus"
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={unitStatusConfigData}
            render={({ setFieldValue, isValid }) => (
              <Form>
                <DocExtractionAffixTableWrapper>
                  {activeViewName === TABLE_VIEW ? (
                    <>
                      <Row
                        className={`configure-table-thead ${style.unitStatusHeader}`}
                      >
                        <Col
                          className={`col-md-auto ${style.documentStatusHeader}`}
                        >
                          Occupancy Code
                        </Col>
                        <Col className={`col-md-auto ${style.marketHeader}`}>
                          Market Rent
                        </Col>
                        <Col className={`col-md-auto ${style.monthlyHeader}`}>
                          Monthly Rent
                        </Col>
                        <Col className={`col-md-auto ${style.unitHeader}`}>
                          #Units
                        </Col>
                        <Col className={`col-md-auto ${style.statusHeader}`}>
                          Status
                        </Col>
                      </Row>
                      <Row className="position-relative">
                        <Col
                          className={`${style.unitStatus} ${
                            isMenuOpen ? `overflow-hidden` : 'overflow-auto'
                          }`}
                        >
                          <Table>
                            <tbody>
                              {Reflect.ownKeys(unitStatusConfigData).map(
                                (unitStatus) => (
                                  <tr key={unitStatus}>
                                    <td
                                      title={unitStatus}
                                      className={`col-md-auto text-with-ellipsis ${style.documentStatusLabel}`}
                                    >
                                      {unitStatus}
                                    </td>
                                    <td className={style.monthlyLabel}>
                                      <DisplayAmountFormat
                                        number={
                                          unitStatusConfigData[unitStatus]
                                            .marketRentTotal
                                        }
                                      />
                                    </td>
                                    <td className={style.marketLabel}>
                                      <DisplayAmountFormat
                                        number={
                                          unitStatusConfigData[unitStatus]
                                            .monthlyRentTotal
                                        }
                                      />
                                    </td>
                                    <td
                                      className={style.unitLabel}
                                      title={
                                        unitStatusConfigData[unitStatus]
                                          .count || 0
                                      }
                                    >
                                      {unitStatusConfigData[unitStatus].count}
                                      <small
                                        title={`(${getUnitPercentage(
                                          unitStatusConfigData[unitStatus].count
                                        )} %)`}
                                      >
                                        {`(${getUnitPercentage(
                                          unitStatusConfigData[unitStatus].count
                                        )} %)`}
                                      </small>
                                    </td>
                                    <td className={style.statusLabel}>
                                      <UnitStatusOptions
                                        unitStatusConfigData={
                                          unitStatusConfigData
                                        }
                                        unitStatus={unitStatus}
                                        staticUnitStatus={staticUnitStatus}
                                        setCurrentUnitStatusCategory={(
                                          dropDownValue
                                        ) => {
                                          setFieldValue(
                                            unitStatus,
                                            dropDownValue
                                          );
                                        }}
                                        onMenuClose={() => setIsMenuOpen(false)}
                                        onMenuOpen={() => setIsMenuOpen(true)}
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <DocExtractionAffixChart
                      data={getChartData().chartConfig}
                      showChartTypeFilter={false}
                      parentStyle={style.unitStatusChartWrapper}
                      xAxisKeyName="name"
                      yAxisKeyName="Market Rent"
                    />
                  )}

                  <DocExtractionAffixTotalCard
                    cards={[
                      {
                        title: 'Total Market Rent',
                        color: TOTAL_MARKET_RENT,
                        content: validateCardTotals('marketRentTotal')
                      },
                      {
                        title: 'Total Monthly Rent',
                        color: TOTAL_MONTHLY_RENT,
                        content: validateCardTotals('monthlyRentTotal')
                      },
                      {
                        title: 'Total Units',
                        color: TOTAL_UNITS,
                        content: `${validateCardTotals(
                          'count',
                          true
                        )} (100.00%)`
                      }
                    ]}
                  />
                </DocExtractionAffixTableWrapper>
                <Row
                  className={`configure-table-tfooter footerFixedBottom ${style.unitStatusFooter}`}
                >
                  <Button
                    variant="outline-secondary btn-sm"
                    onClick={onSlidePaneClose}
                  >
                    Cancel
                  </Button>
                  <FormSubmitButton
                    isSubmitting={isSubmitting}
                    isValid={isValid && !readOnlyAccess}
                    id="unitStatusSubmit"
                  />
                </Row>
              </Form>
            )}
          />
        </>
      ) : (
        <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  unitStatusConfigData: currentDocumentUnitStatusSelector(state),
  staticUnitStatus: currentDocumentStaticUnitStatusSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state)
});

export default connect(mapStateToProps)(
  withServices('store', 'eventTrackerService')(DocExtractionUnitStatusConfig)
);
