import Card from 'react-bootstrap/Card';
import React from 'react';
import Badge from 'react-bootstrap/Badge';
import withClicks from '../../core/withClicks';
import style from './LargeTile.module.scss';

function LargeTile({
  name, icon, onDoubleClick, onClick, selected, tlBadgeText, trIcon, children, ...restProps
}) {
  let className = `${style.largeTile} mb-3 cursor-pointer productItem`;
  if (selected) {
    className = `${className} bg-info ${style.bgInfo}`;
  }

  return (
    <Card className={className} onDoubleClick={onDoubleClick} onClick={onClick} onContextMenu={onClick} {...restProps}>
      <Card.Body className="p-3 text-center no-user-select">
        {
          tlBadgeText &&
          <Badge variant="info" className={`position-absolute ${style.badgeInfo}`}>{tlBadgeText}</Badge>
        }
        {
          trIcon &&
          <div className={`${style.statusComplete}`}>{trIcon}</div>
        }
        <div>
          <div className={`${style.folderIcon}`}>
            <img src={icon} alt="file icon" />
          </div>
          <small>
            <span className="text-with-ellipsis w-100 d-inline-block no-selection-highlight">{name}</span>
          </small>
        </div>
        <div>
          {children}
        </div>
      </Card.Body>
    </Card>
  );
}


export default withClicks()(LargeTile);
