import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import EditUserFormComponent from './EditUserFormComponent';
import messages from '../../../../locales';
import { companyTemplatesAsListSelector } from '../../../store/selectors';
import { getTemplateDropdownMap } from '../../../utils/utils';
import { isEmpty } from 'lodash';

const { errors: { validationError } } = messages;

const UserFormSchema = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required(validationError.firstName),
    lastName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required(validationError.lastName),
    email: Yup.string().email(validationError.invalidEmail).required(validationError.requiredEmail)
  }),
  role: Yup.string().required(validationError.role),
  readAccess: Yup.array()
  .min(1, 'At least one email is required.')
  .required('At least one email is required.'),
});

function EditUserForm({
  onSubmitSuccess, bindSubmission, user, userService, companyTemplates
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      await userService.updateCompanyUser(values);
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  const companyTemplatesMap = useMemo(() => getTemplateDropdownMap(companyTemplates),
  [companyTemplates]);

  const writeAccessTemplatesMap = useMemo(() => {
    const { UserTemplateAccesses } = user.user;
    return isEmpty(UserTemplateAccesses) ? [] : UserTemplateAccesses
    .filter(t => t.writeAccess)
    .map(userTemplate => {
      const template = companyTemplates.find(t => t.id === userTemplate.templateId);
      return { label: template.templateName, value: template.uuid }
    }) 
  }, [user, companyTemplates]);

  const readAccessTemplatesMap = useMemo(() => {
    const { UserTemplateAccesses } = user.user;
    return  isEmpty(UserTemplateAccesses) ? [] : UserTemplateAccesses
    .filter(t => t.readAccess)
    .map(userTemplate => {
      const template = companyTemplates.find(t => t.id === userTemplate.templateId);
      return { label: template.templateName, value: template.uuid }
    }) 
  }, [user, companyTemplates]);
  
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllForWrite, setSelectAllForWrite] = useState(false);

  return (
    <Formik
      initialValues={{
        ...user,
        writeAccess: writeAccessTemplatesMap,
        readAccess: readAccessTemplatesMap
      }}
      validationSchema={UserFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <EditUserFormComponent 
        {...formikProps}
        formikProps={formikProps}
        bindSubmission={bindSubmission} 
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        companyTemplatesMap={companyTemplatesMap}
        setSelectAllForWrite={setSelectAllForWrite}
        selectAllForWrite={selectAllForWrite}
      />
      }
    />
  );
}

const mapStateToProps = state => ({
  companyTemplates: [
    ...companyTemplatesAsListSelector(state)
  ]
})

export default connect(mapStateToProps)(withServices('userService')(EditUserForm));
