import React from 'react';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import Tickets from '../../app/Tickets/Tickets';

function TicketsPage({queries}) {
  return (
    <SuperAdminDashboard>
      <Tickets queries={queries} />
    </SuperAdminDashboard>
  );
}
export default TicketsPage;
