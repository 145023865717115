import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import PasswordViewer from '../PasswordViewer/PasswordViewer';
import { PasswordStrengthInfo } from '../PasswordStrengthInfo/PasswordStrengthInfo';
import style from './ResetUserPasswordFormComponent.module.scss';
import { SpriteLockIcon } from '../../core/SpriteIcon/SpriteIcon';

function ResetUserPasswordFormComponent({
  status, isSubmitting, bindSubmission, submitForm
}) {
  bindSubmission(submitForm, isSubmitting);

  return (
    <div className="passwordGroupRow">
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <label htmlFor="newPassword">
New Password
            <PasswordStrengthInfo />
          </label>
          <PasswordViewer id="newPassword" name="newPassword" />
          <FieldErrorMessage name="newPassword" />
        </div>
        <div className="form-group position-relative">
          <label htmlFor="confirmPassword">Confirm Password</label>
            <div className="loginFormIcon passwordIcon confirmResetPassword">
               <SpriteLockIcon />
            </div>
          <Field id="confirmPassword" className={`form-control ${style.formControl}`} name="confirmPassword" type="password"  placeholder="Confirm New Password"/>
          <FieldErrorMessage name="confirmPassword" />
        </div>
      </Form>
    </div>
  );
}

export default ResetUserPasswordFormComponent;
