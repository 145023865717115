import React from 'react';
import {
  Tabs, Tab, Container, Row, Col, Form
} from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import './ProjectInfoFormComponent.scss';
import { getApiIntergationFormField } from '../../../utils/utils';
import FormikTextField from '../../core/FormikTextField/FormikTextField';
import FormikSelectField from '../../core/FormikSelectField/FormikSelectField';
import FormikFieldWithPrepend from '../../core/FormikFieldWithPrepend/FormikFieldWithPrepend';
import { TEMPLATE_TAG } from '../../../constants';
import { dynamicAssetTypesAsObjSelector } from '../../../store/selectors';

function ProjectAcquisitionFormComponent({
  acquisitionTab, setAcquisitionTab, status,
  submitForm, isSubmitting, bindSubmission,
  dynamicAssetTypes, apiConfigIntegrationValues, errors
}) {
  bindSubmission(submitForm, isSubmitting);
  const getErrorClass = (keyName) => {
    if(keyName === 'api_integration' && errors[keyName]){
      return 'error';
    } else {
      return get(errors, keyName, false) ? 'error' : ''
    }
  }

  return (
    <div className="projectInfoModalForm">
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <div className="clearfix">
        <Form>
          <Tabs onSelect={k => setAcquisitionTab(k)} activeKey={acquisitionTab} transition={false} id="noanim-tab-example">

            <Tab eventKey="property_information" title="Property Information" tabClassName={getErrorClass('acquisition.property_information')}>
              <Container fluid className="py-4">
                <Row className="justify-content-center">
                  <Col className="col-lg-8">

                    <FormikSelectField
                      label="Property Type"
                      name="acquisition.property_information.property_type"
                      disabled
                    >
                      <option value="">Select Property Type</option>
                      {
                        Reflect.ownKeys(dynamicAssetTypes).map(assetType => (
                          <option key={assetType} value={assetType}>{dynamicAssetTypes[assetType].label}</option>
                        ))
                      }
                    </FormikSelectField>

                    <FormikTextField
                      disabled
                      label="Property Name"
                      placeholder="Enter Property Name"
                      name="acquisition.property_information.property_name"
                    />

                    <FormikTextField
                      hidden
                      label="Property Address"
                      placeholder="Enter Property Address"
                      name="acquisition.property_information.formattedAddress"
                    />
                    <FormikTextField
                      hidden
                      label="Google Place Id"
                      placeholder="Enter Google Place Id"
                      name="acquisition.property_information.googlePlaceId"
                    />
                    <FormikTextField
                      hidden
                      label="Lattitude"
                      placeholder="Enter Lattitude"
                      name="acquisition.property_information.lat"
                    />
                    <FormikTextField
                      hidden
                      label="Longitude"
                      placeholder="Enter Longitude"
                      name="acquisition.property_information.lng"
                    />

                    <FormikTextField
                      label="Property Address"
                      placeholder="Enter Property Address"
                      name="acquisition.property_information.property_address"
                    />

                    <FormikTextField
                      label="City"
                      placeholder="Enter City"
                      name="acquisition.property_information.city"
                    />

                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          label="State"
                          placeholder="Enter State"
                          name="acquisition.property_information.state"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          label="Zip"
                          type="text"
                          placeholder="Enter Zip Code"
                          name="acquisition.property_information.zip"
                        />
                      </Col>
                    </Form.Row>

                    <FormikTextField
                      label="No. of Units"
                      placeholder="0"
                      type="number"
                      name="acquisition.property_information.no_of_units"
                    />
                    <FormikTextField
                      label="Total Sq. Ft."
                      placeholder="0"
                      type="number"
                      name="acquisition.property_information.total_sq_ft"
                    />

                    <FormikFieldWithPrepend
                      prependText="$"
                      placeholder="0"
                      label="Purchase Price"
                      name="acquisition.property_information.purchase_price"
                    />
                    <FormikTextField
                      label="Build Year"
                      placeholder="Enter Build Year"
                      type="number"
                      name="acquisition.property_information.year_built"
                    />
                    <FormikTextField
                      label="Last Renovated Year"
                      placeholder="Enter Last Renovated Year"
                      type="number"
                      name="acquisition.property_information.year_renovated"
                    />
                  </Col>
                </Row>
              </Container>
            </Tab>

            <Tab eventKey="deal_information" title="Deal Information" tabClassName={getErrorClass('acquisition.deal_information')}>
              <Container fluid className="py-4">
                <Row className="justify-content-center">
                  <Col className="col-lg-8">
                    <FormikTextField
                      label="Deal Name"
                      placeholder="Enter Deal Name"
                      name="acquisition.deal_information.deal_name"
                    />
                    <FormikTextField
                      label="Hold Period"
                      placeholder="10"
                      type="number"
                      name="acquisition.deal_information.hold_period"
                    />
                  </Col>
                </Row>
              </Container>
            </Tab>
            {!isEmpty(apiConfigIntegrationValues) && (
              <Tab eventKey="api" title="Api Integration" tabClassName={getErrorClass('api_integration')}>
                <Container fluid className="py-4">
                  <Row className="justify-content-center">
                    <Col className="col-lg-8">
                      {apiConfigIntegrationValues?.map((object, index) => {
                        return getApiIntergationFormField(object,index);
                      })}
                    </Col>
                  </Row>
                </Container>
              </Tab>
            )}
          </Tabs>
        </Form>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => ({
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
})
export default connect(mapStateToProps)(withServices('placesService')(ProjectAcquisitionFormComponent));
