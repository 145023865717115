import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { hubspot } from '../../../config';
import { authSelector } from '../../../store/selectors';
import closeButtonIcon from '../../../assets/images/close-button-icon.svg';
import './ContactSalesForm.scss';

function ContactSalesForm({ auth, onCancel }) {
  useEffect(() => {
    const hbFormInstance = window.hbspt.forms.create({
      portalId: hubspot.portalId,
      target: '#form-target',
      formId: hubspot.formId
    });
    const authStateValues = {
      firstname: auth.user.firstName,
      lastname: auth.user.lastName,
      email: auth.user.email,
      phone: auth.company.phone,
      company: auth.company.name
    };
    hbFormInstance.onReady(() => {
      Reflect.ownKeys(authStateValues).forEach((fieldName) => hbFormInstance.setFieldValue(fieldName, authStateValues[fieldName] || ''));
    });
  }, []);
  // Note: auth is not in dependency [] because then it will rerender the form again and hence it will throw error.,

  return (
    <div className="formContainer">
      <div className="hubspot-paneHeader d-flex justify-content-between align-items-center">
        <h2 className="heading-sm">Upgrade Your Account</h2>
        <button type="button" className="smallIconButton closeIconButton m-0" onClick={onCancel}>
          <span>
            <img src={closeButtonIcon} alt="close" />
          </span>
        </button>
      </div>
      <div className="hubspot-form-wrapper">
        <div id="form-target" data-hs-forms-root="true">
          <div className="text-center mt-5">
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          </div>
      </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  auth: authSelector(state)
});

export default connect(mapStateToProps)(ContactSalesForm);
