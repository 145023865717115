import { strToU8, compressSync } from 'fflate';
import { loaderManager } from '../utils/utils';
import { hideBackdrop, showBackdrop } from '../store/loadingBackdrop';
import { showToastr } from '../store/notificationToastr';

class ProjectReportService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  async saveReportData(project, reportData) {
    const buffer = strToU8(JSON.stringify(reportData));
    const compressedPayload = compressSync(buffer, { level: 6, mem: 8 });

    const promise = this.apiClient.post(
      `/projects/${project.uuid}/projectReport`,
      { payload: compressedPayload },
    );

    await loaderManager(
      promise,
      () => this.store.dispatch(showBackdrop('Saving...')),
      () => this.store.dispatch(hideBackdrop())
    );
    this.store.dispatch(
      showToastr('success', 'Successfully saved project report', 'top')
    );
  }

  async clearReportData(project) {
    await this.apiClient.put(
      `/projects/${project.uuid}/clearProjectReport`,
    );
  }
  
  async getReportData(projectUuid, reportUuid, isOpen) {
    const url = isOpen ?  `/shared/reports/${reportUuid}/reportData` :  `/projects/${projectUuid}/projectReport`
    const response = (await this.apiClient.get(url))['response']
    return response.reportData
  }
}

export default ProjectReportService;
