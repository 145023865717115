import React, { useCallback } from "react";
import { withServices } from 'reaf';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import ApiFrequencyFormComponent from './ApiFrequencyFormComponent';
import messages from '../../../../locales';

function ApiFrequencyForm({
  bindSubmission, onSubmitSuccess, projectsService, integrationConfigrationUuid, project,
  documentsUuidsForModel, toastrService, selectedFrequency, setSelectedFrequency }) {
  const getProjectFormComponent = useCallback(
    (formikProps) => (
      <ApiFrequencyFormComponent
        {...formikProps}
        bindSubmission={bindSubmission}
        setSelectedFrequency={setSelectedFrequency}
        selectedFrequency={selectedFrequency}
      />
    ),
    [bindSubmission]
  );

  const ApiFrequencyFormSchema = Yup.object().shape({
    frequency: Yup.string().
      required('Please provide a user defined frequency')
  });

  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      const { frequency, userDefType } = values;
      toastrService.info(messages.toastMessage.API_INTEGRATION_PUSH_IN_PROCESS, null, 7000);
      await projectsService.configureApiIntegration(project.uuid, integrationConfigrationUuid, documentsUuidsForModel, frequency, userDefType);
      toastrService.success(messages.toastMessage.API_INTEGRATION_PUSH_SUCCESS, null, 10000);
      onSubmitSuccess();
    } catch (error) {
      toastrService.error(messages.toastMessage.API_INTEGRATION_PUSH_ERROR, null, 7000);
    }
  }
  return (
    <Formik
      initialValues={{ frequency: '', userDefType: 'ACTUAL' }}
      onSubmit={onSubmit}
      component={getProjectFormComponent}
      validationSchema={ApiFrequencyFormSchema}
    />
  );
}

export default withServices('projectsService', 'toastrService')(ApiFrequencyForm);