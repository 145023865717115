import BaseCommand from './BaseCommand';

class SetFormatCommand extends BaseCommand {
  run(spreadsheet, options) {
    const { activeSheetIndex, format, alignment } = options;
    const sheet = spreadsheet.getSheet(activeSheetIndex);
    const selections = sheet.getSelections();

    selections.forEach((selection) => {
      const range = sheet.getRange(selection.row, selection.col, selection.rowCount, selection.colCount);
      range.formatter(format).hAlign(alignment);
    });
  }
}

const setFormatCommand = SetFormatCommand.get();

export default setFormatCommand;
