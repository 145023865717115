import React from 'react';
import fileNotFoundFile from '../../../assets/images/file-not-found.svg';
import style from './DocExtractionWithoutSummary.module.scss';


function DocExtractionWithoutSummary({ message }) {
  return (
    <div className="d-flex justify-content-center pt-5">
      <div className={`${style.documentNotFoundCol} pt-5 mt-5`}>
        <div className={`${style.fileNotFoundImage}`}><img src={fileNotFoundFile} alt="Clik File Not Found" /></div>
        <span className="h6 ml-2 mt-auto mb-auto">{message}</span>
      </div>

    </div>
  );
}

export default DocExtractionWithoutSummary;
