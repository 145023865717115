import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import DocExtractionRetailChargeCodeConfig
  from '../DocExtractionRetailRentRoll/DocExtractionRetailChargeCodeConfig/DocExtractionRetailChargeCodeConfig';
import DocExtractionRetailOccupancy
  from '../DocExtractionRetailRentRoll/DocExtractionRetailOccupancy/DocExtractionRetailOccupancy';
import DocExtractionRetailTenantType
  from '../DocExtractionRetailRentRoll/DocExtractionRetailTenantType/DocExtractionRetailTenantType';
import DocExtractonRetailLeaseExpiration
  from '../DocExtractionRetailRentRoll/DocExtractonRetailLeaseExpiration/DocExtractonRetailLeaseExpiration';
import DocExtractionRetailLeaseType
  from '../DocExtractionRetailRentRoll/DocExtractionRetailLeaseType/DocExtractionRetailLeaseType';
import './DocExtractionRentRollConfigComponent.scss';

function DocExtractionRentRolRetailConfig({
  onRentRollConfigSubmit, onSlidePaneClose, onMergeChargeCodeColumn, onTenantTypeCalcChange, activeTabKey = 'chargeCode', setActiveTabKey
}) {
  const [activeTab, setActiveTab] = useState(activeTabKey);

  return (
    <>
      <div className="projectSlideTabPanel affixVerticalTabs">
        <Tab.Container 
          id="retail-rr" 
          defaultActiveKey="chargeCode"
          activeKey={activeTab}
          onSelect={(tabName) => {
            if (tabName !== activeTabKey) {
              setActiveTabKey(tabName)
              setActiveTab(tabName)
            } else {
              onSlidePaneClose()
            }
          }}
          unmountOnExit>
            <Tab.Content>
              <Tab.Pane eventKey="chargeCode">
                <DocExtractionRetailChargeCodeConfig
                  onSlidePaneClose={onSlidePaneClose}
                  onMergeChargeCodeColumn={onMergeChargeCodeColumn}
                  onConfigSubmit={() => onRentRollConfigSubmit('retailChargeCode')}
              />
              </Tab.Pane>
              <Tab.Pane eventKey="occupancy">
                <DocExtractionRetailOccupancy
                  onConfigSubmit={() => onRentRollConfigSubmit()}
                  onSlidePaneClose={onSlidePaneClose}
                  />
              </Tab.Pane>
              <Tab.Pane eventKey="leaseType">
                <DocExtractionRetailLeaseType
                  onConfigSubmit={() => onRentRollConfigSubmit()}
                  onSlidePaneClose={onSlidePaneClose}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="tenantType">
                <DocExtractionRetailTenantType
                  onConfigSubmit={() => onRentRollConfigSubmit()}
                  onTenantTypeCalcChange={(value) => onTenantTypeCalcChange(value)}
                  onSlidePaneClose={onSlidePaneClose}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="analysis">
                <DocExtractonRetailLeaseExpiration />
              </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
}

export default DocExtractionRentRolRetailConfig;
