import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { withServices } from 'reaf';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import clikLogoImg from '../../../assets/images/clikai-logo.svg';
import style from './VerifyEmailPage.module.scss';

function VerifyEmailPage({ email, token, authService, toastrService, router }) {

  const isLoggedIn = useMemo(() => authService.isLoggedIn(), []);
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {
    if (email && token) {
      setIsLoading(true);
      setTimeout(() => {
        authService
        .verifyEmail(email, token)
        .then(() => {
          toastrService.success('Your email has been verified successfully!');
        })
        .catch(e => {
          console.log(e);
          if(e.message === 'Invalid token provided.') {
            toastrService.error('Link expired!');
          }
        })
        .finally(() => {
          // eslint-disable-next-line max-nested-callbacks
          setTimeout(() => {
            setIsLoading(false);
            router.setRoute('loginPage');
          }, 2000);
        })
      }, 3000)
    }
  }, [email, token]);

  const handleResendEmail = useCallback(() => {
    setIsLoading(true);
    authService.resendVerifyEmail().then(() => {
      toastrService.success('We have sent you an email. Please check your inbox.');
      setIsLoading(false);
    });
  }, []);

  return (
    <LoggedOutUserPage canLogout>
      <div className="vertical-section dotted-bg bg-light">
        <Card className="form-card">
          <Card.Body className="form-card-body">
            <div className="loginFormLogo">
              <img src={clikLogoImg} alt="Clik Logo" />
            </div>
            <div className="row align-items-center">
              <div className="col-12">
                <h4 className="mb-0"><strong>Verify your email</strong></h4>
              </div>
            </div>
            <div className={`my-4 ${style.clikaiSubTitle}`}>
              {
                token ? (
                  <>
                  <p>Your email address is being verified.</p>
                  <Button variant="btn btn-primary" disabled>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    &nbsp; Please wait...
                  </Button>
                  </>
                ) : (
                  <>
                  <p>You need to verify your email to continue. <br/> 
                    If you have not received the verification email, please check your &quot;Spam&quot; or
                    &quot;Bulk Email&quot; folder. 
                  </p>
                  </>
                )
              }
            </div>
            {
              isLoggedIn && !token && (<Button variant="btn btn-primary" disabled={isLoading}onClick={handleResendEmail}>{isLoading ? <Spinner as="span" animation="border" size="sm" />: 'Resend verification email'}</Button>)
            }

          </Card.Body>
        </Card>


      </div>
    </LoggedOutUserPage>
  );
}

export default withServices('authService', 'toastrService', 'router')(VerifyEmailPage);
