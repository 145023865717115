import React from 'react';
import TutorialVideoGrid from '../TutorialVideoGrid/TutorialVideoGrid';
import TutorialPlayer from '../TutorialPlayer/TutorialPlayer';
import style from './GettingStarted.module.scss';

function GettingStarted({ router, section, videoUuid, tutorials }) {
  const View = videoUuid ? TutorialPlayer : TutorialVideoGrid;
  return (
    <div className={`container-fluid ${style.pageOuterSection}`}>
      <div className="row">
        <div className={`col-12 d-flex border-bottom ${style.sectionHeader}`}>
          <h3>Getting Started</h3>
        </div>
      </div>
      <section className={`p-4 ${style.mainSection}`}>
        <View {...{ section, router, tutorials, videoUuid }} />
      </section>
    </div>
  );
}

export default GettingStarted;
