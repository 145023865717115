import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Field } from 'formik';
import { isNil } from 'lodash';
import moment from 'moment';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import SwitchButton from '../../core/SwitchButton/SwitchButton';
import {
  DocumentFileTypes,
  EXCLUDE_DOC_FILE_TYPES,
  HIDE_RENT_ROLL_ASSET_TYPES,
  OS_MONTHS_DATA
} from '../../../constants';
import Checkbox from '../../core/Checkbox/Checkbox';
import DatePicker from '../../core/DatePicker/DatePicker';
import { dynamicAssetTypesListSelector } from '../../../store/selectors';
import { Alert, Card } from 'react-bootstrap';

const ToggleButton = ({ isActive, title, fieldName, setFieldValue }) => (
  <div className="d-flex justify-content-between">
    <label>{title}</label>
    <div>
      <SwitchButton
        isActive={isActive}
        onChange={(value) => setFieldValue(fieldName, value)}
      />
    </div>
  </div>
);

function CompanyFieldsComponent({ values, setFieldValue, dynamicAssetTypes }) {
  const documentTypes = useMemo(() =>
    Reflect.ownKeys(DocumentFileTypes).filter(
      (docType) => !EXCLUDE_DOC_FILE_TYPES.includes(docType)
    )
  );

  const rrRestrictedAssetTypes = dynamicAssetTypes.filter((assetType) => assetType.canRestrictRentRoll);
  const {
    company: {
      paymentDueDate,
      canShareReport,
      allowProjectJson,
      canDownloadReportWorkbook,
      canDownloadModel,
      isTrialOffering,
      isEnterpriseCompany,
      isMultifactorAuthEnabled,
      canEditProjectDetails,
      isBetaParserEnabled,
      trialDays,
      shouldAllowApiDataTransfer,
      createdAt = (new Date()).toISOString()
    }
  } = values;

  const getAssetTypeDisabled = useCallback(
    (key) => {
      const docTypes = values.company.documentTypes;
      return (
        docTypes.length === 1 &&
        docTypes[0] === 'RENT_ROLL' &&
        HIDE_RENT_ROLL_ASSET_TYPES.includes(key)
      );
    },
    [values.company.documentTypes]
  );

  const getDisabledRentRollRestrictedAssetType = useCallback(
    (key) => {
      const assetTypes = values.company.assetTypes || [];
      const allowedDocumentTypes = values.company.documentTypes || [];
      return (
        !assetTypes.includes(key) || !allowedDocumentTypes.includes('RENT_ROLL')
      );
    },
    [values.company.assetTypes, values.company.documentTypes]
  );

  const onDocTypeChange = (docTypes) => {
    if (docTypes.length === 1 && docTypes[0] === 'RENT_ROLL') {
      setFieldValue('company.assetTypes', [
        ...values.company.assetTypes.filter(
          (assetType) => !HIDE_RENT_ROLL_ASSET_TYPES.includes(assetType)
        )
      ]);
    }
  };

  return (
    <>
      <Card className='mb-3'>
        <Card.Body>
          <div className="form-group pb-0">
            <label htmlFor="company.name">Company Name</label>
            <Field
              id="company.name"
              className="form-control mt-2"
              name="company.name"
              type="text"
              disabled
            />
            <FieldErrorMessage name="company.name" />
          </div>
          <div className="form-group pb-2 border-bottom">
            <label
              htmlFor="company.assetTypes"
              style={{ display: 'block', fontWeight: 500 }}
            >
              Asset Types
            </label>
            <div className="py-2" style={{ marginLeft: '-5px' }}>
              {dynamicAssetTypes.map((key) => (
                <Checkbox
                  name="company.assetTypes"
                  disabled={getAssetTypeDisabled(key.key)}
                  className="ml-2 mr-1"
                  value={key.assetType}
                  key={key.assetType}
                  label={key.baseClass !== key.assetType ? `${key.label}[${key.baseClass}]` : key.label}
                />
              ))}
            </div>
            <FieldErrorMessage name="company.assetTypes" />
          </div>
          <div className="form-group pb-2 border-bottom">
            <label
              htmlFor="company.documentTypes"
              style={{ display: 'block', fontWeight: 500 }}
            >
              Allowed document types
            </label>
            <div className="py-2" style={{ marginLeft: '-5px' }}>
              {documentTypes.map((key) => (
                <Checkbox
                  name="company.documentTypes"
                  onChange={onDocTypeChange}
                  className="ml-2 mr-1"
                  value={key}
                  key={key}
                  label={DocumentFileTypes[key].label}
                />
              ))}
            </div>
            <FieldErrorMessage name="company.documentTypes" />
          </div>
          <div className="form-group  mb-0">
            <label
              htmlFor="company.rentRollRestrictedAssetTypes"
              style={{ display: 'block', fontWeight: 500 }}
            >
              Restrict Rent Rolls For
            </label>
            <div className="py-2" style={{ marginLeft: '-5px' }}>
              {rrRestrictedAssetTypes.map((key) => (
                <Checkbox
                  name="company.rentRollRestrictedAssetTypes"
                  disabled={getDisabledRentRollRestrictedAssetType(key.assetType)}
                  className="ml-2 mr-1"
                  value={key.assetType}
                  key={key.assetType}
                  label={key.label}
                />
              ))}
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className='mb-3'>
        <Card.Header className="py-2">
          Company Settings
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div className="form-group mb-0 w-50 pr-5 border-right">
              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Can Share Report Link',
                  isActive: canShareReport,
                  fieldName: 'company.canShareReport'
                }}
              />

              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Can download Model',
                  isActive: canDownloadModel,
                  fieldName: 'company.canDownloadModel'
                }}
              />
              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Can download report workbook',
                  isActive: canDownloadReportWorkbook,
                  fieldName: 'company.canDownloadReportWorkbook'
                }}
              />
              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Can download Project Json',
                  isActive: allowProjectJson,
                  fieldName: 'company.allowProjectJson'
                }}
              />
              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Can Edit Project Details',
                  isActive: canEditProjectDetails,
                  fieldName: 'company.canEditProjectDetails'
                }}
              />
            </div>
            <div className="form-group mb-0 pl-5 w-50">
              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Is Trial Company',
                  isActive: isTrialOffering,
                  fieldName: 'company.isTrialOffering'
                }}
              />
              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Is Enterprise Company',
                  isActive: isEnterpriseCompany,
                  fieldName: 'company.isEnterpriseCompany'
                }}
              />
              <FieldErrorMessage name="company.isEnterpriseCompany" />
              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Is Multi factor Auth Enabled',
                  isActive: isMultifactorAuthEnabled,
                  fieldName: 'company.isMultifactorAuthEnabled'
                }}
              />
              <ToggleButton
                {...{
                  setFieldValue,
                  title: 'Is Beta Parser Enabled',
                  isActive: isBetaParserEnabled,
                  fieldName: 'company.isBetaParserEnabled'
                }}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      {
        isTrialOffering && (
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="company.trialDays">Trial days from date of creation</label>
              <Field
                id="company.trialDays"
                className="form-control"
                name="company.trialDays"
                type="number"
              />
              <FieldErrorMessage name="company.trialDays" />
            </div>
            <div className="form-group col-6">
              <label htmlFor="">Trial ends on</label>
              <span className="d-block">
                {
                  moment(createdAt).add(trialDays, 'days').format('MM/DD/YYYY')
                }
              </span>
            </div>
          </div>
        )
      }
      <Card className='mb-3'>
        <Card.Header className="py-2">
          Support Ticket Configuration
        </Card.Header>
        <Card.Body>
        <div className="form-group">
            <label htmlFor="company.ticketConfig.emails">Email Address 
            <small className="ml-2 text-muted">For multiple email address, seperate with comma</small></label>
            <Field
              id="company.ticketConfig.emails"
              className="form-control"
              name="company.ticketConfig.emails"
              type="text"
            />
            <FieldErrorMessage name="company.ticketConfig.emails" />
          </div>
        <div className="form-group  mb-0">
            <label htmlFor="company.ticketConfig.subject">Email Subject</label>
            <Field
              id="company.ticketConfig.subject"
              className="form-control"
              name="company.ticketConfig.subject"
              type="text"
            />
            <FieldErrorMessage name="company.ticketConfig.subject" />
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header className="py-2">
          Additional Configuration
        </Card.Header>
        <Card.Body>

          <div className="form-group">
            <label htmlFor="company.notificationText">Notification Text</label>
            <Field
              id="company.notificationText"
              className="form-control"
              name="company.notificationText"
              type="text"
            />
            <FieldErrorMessage name="company.notificationText" />
          </div>
          <div className="d-flex justify-content-between">
            <div className="form-group w-100 pr-2">
              <label htmlFor="company.numberOfLicenses">Number of Licenses</label>
              <Field
                id="company.numberOfLicenses"
                className="form-control"
                name="company.numberOfLicenses"
                type="number"
              />
              <FieldErrorMessage name="company.numberOfLicenses" />
            </div>
            <div className="form-group w-100 pl-2">
              <label htmlFor="company.phone">Phone Number</label>
              <Field
                id="company.phone"
                className="form-control"
                name="company.phone"
                type="text"
              />
              <FieldErrorMessage name="company.phone" />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="company.address.address">Address</label>
            <Field
              id="company.address.address"
              className="form-control"
              name="company.address.address"
              type="text"
            />
            <FieldErrorMessage name="company.address.address" />
            {!!values.company.address &&
              !!values.company.address.address &&
              values.company.address.address.length > 0 && (
                <>
                  <label className='mt-3' htmlFor="company.address.zip">Postal Code</label>
                  <Field
                    id="company.address.zip"
                    className="form-control"
                    name="company.address.zip"
                    type="text"
                  />
                  <FieldErrorMessage name="company.address.zip" />
                  <label className='mt-3' htmlFor="company.address.country">Country</label>
                  <Field
                    id="company.address.country"
                    className="form-control"
                    name="company.address.country"
                    type="text"
                  />
                  <FieldErrorMessage name="company.address.country" />
                </>
              )}
          </div>
          <Alert variant='info' className='m-0'>
            <div className="form-group m-0 align-items-center  justify-content-between d-flex mb-0">
              <label className='col-8 pl-0' htmlFor="OSMonthsToHide">Hide OS months data, if extracted months are less than</label>
              <Field
                id="OSMonthsToHide"
                className="form-control col-2"
                name="OSMonthsToHide"
                component="select"
              >
                {OS_MONTHS_DATA.map(month => (
                  <option value={month}>{`${month} month${month !== 1 ? 's' : ''}`}</option>
                ))
                }
              </Field>
            </div>
          </Alert>
          {isNil(paymentDueDate) && (
            <div className="form-group m-0 mt-2">
              <label htmlFor="paymentDueDate">Payment Due Date</label>
              <Field
                name="paymentDueDate"
                id="paymentDueDate"
                component={DatePicker}
                selected={values.paymentDueDate || null}
                className="form-control"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          )}
          {!isNil(paymentDueDate) && paymentDueDate !== '' && (
            <>
              <div className="form-group m-0 mt-2">
                <label htmlFor="paymentDueDate">Payment Due Date</label>
                <Field
                  className="form-control"
                  value={values.previousDueDate}
                  type="text"
                  readOnly
                />
                <label htmlFor="paymentStatus">Payment Status</label>
                <Field
                  id="paymentStatus"
                  className="form-control"
                  name="paymentStatus"
                  component="select"
                >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                </Field>
              </div>
              <div className="form-group m-0 mt-2">
                <label htmlFor="paymentStatus">Grace Period</label>
                <Field
                  id="gracePeriod"
                  className="form-control"
                  name="company.graceDays"
                  type="number"
                />
              </div>
            </>
          )}
        </Card.Body>
      </Card>

    </>
  );
}

const mapStateToProps = state => ({
  dynamicAssetTypes: dynamicAssetTypesListSelector(state) || []
});


export default connect(mapStateToProps)(CompanyFieldsComponent);
