import { setActiveSubscriptions, setInActiveSubscriptions } from '../store/subscriptionsList'

class PaymentService {
  constructor(apiClient, authService, toastrService, store) {
    this.apiClient = apiClient;
    this.authService = authService;
    this.toastrService = toastrService;
    this.store = store;
  }

  async validatePayment(paymentIntent, subscriptionId, redirect = false) {
    await this.apiClient.post('/payments/validatePayment', { paymentIntent, subscriptionId });
    await this.authService.refreshAuthState();
    this.toastrService.success('Payment Successful! Thank you for your payment.');
    if (redirect) {
      this.authService.resetRoute();
    }
  }

  async validatePaymentWithTrial(paymentIntent, subscriptionId, redirect = false) {
    await this.apiClient.post('/payments/validatePaymentWithTrial', { paymentIntent, subscriptionId });
    await this.authService.refreshAuthState();
    this.toastrService.success('Payment Successful! Thank you for your payment.');
    if (redirect) {
      this.authService.resetRoute();
    }
  }

  async createSubscription(priceId) {
    return this.apiClient.post('/payments/createSubscription', {
      priceId
    });
  }

  async getSubscriptionList() {
    const result = await this.apiClient.get('/admin/subscriptionsList');
    const {activeSubscriptions, inActiveSubscriptions} = result.response;
    this.store.dispatch(setActiveSubscriptions(activeSubscriptions));
    this.store.dispatch(setInActiveSubscriptions(inActiveSubscriptions));
    return result.response;
  }

  async deactivateSubscriptionViaAdmin(subscriptionId, status, subscriptionType) {
    const response = await this.apiClient.post('/admin/deactivatesubscription', {
      subscriptionId,
      status,
      subscriptionType
    });
    return response.response;
  }

  async createSubscriptionWithTrial(priceId) {
    return this.apiClient.post('/payments/createSubscriptionWithTrial', {
      priceId
    });
  }

  async listPrices() {
    return this.apiClient.get('/payments/listPrices')
  }

  async getPaymentHistory() {
    const response = await this.apiClient.get('/payments/paymentHistory')
    return response.response;
  }

  async cancelSubscription(subscriptionId) {
    const response = await this.apiClient.post('/payments/cancelSubscription', {
      subscriptionId
    })
    await this.authService.refreshAuthState();
    return response.response;
  }

  async deleteSubscription(subscriptionId) {
    return this.apiClient.post('/payments/deleteSubscription', {
      subscriptionId
    })
  }
}

export default PaymentService;
