/* eslint-disable max-params */
import React from 'react';
import * as PropTypes from 'prop-types';
import { Row, Container, Col } from 'react-bootstrap';
import { ACTIONS, SpreadSheetToolbar } from './Toolbar/SpreadSheetToolbar';
import { setHorizontalAlignmentCommand } from './Commands/SetHorizontalAlignmentCommand';
import { freezeCommand } from './Commands/FreezeCommand';
import { sortCommand } from './Commands/SortCommand';
import {
  boldTextFormatCommand,
  italicTextFormatCommand,
  textFormatCommand,
  underlineTextFormatCommand
} from './Commands/TextFormatCommand';
import setFormatCommand from './Commands/SetFormatCommand';
import setColorCommand from './Commands/SetColorCommand';
import setFilterCommand from './Commands/SetFilterCommand';
import pasteFormatCommand from './Commands/PasteFormatCommand';
import { FORMATS } from './Toolbar/FormatMenu';
import { GCConfig } from '../../../config';
import SelectionChangedEvent from './Events/SelectionChangedEvent';
import SelectionChangingEvent from './Events/SelectionChangingEvent';
import { noopFunc } from '../../../lib/utils/noop';
import { deleteRowColumnCommand } from './Commands/RowColDeletionCommand';
import { insertRowColumnCommand } from './Commands/RowColInsertionCommand';
import selectRowCommand from './Commands/SelectRowCommand';
import selectColumnCommand from './Commands/SelectColumnCommand';
import { setAbsoluteFormulaCommand } from './Commands/SetAbsoluteFormulaCommand';
import fillDataCommand from './Commands/FillDataCommand';
import styles from './SpreadSheets.module.scss';

let GC;
let GCExcel;

const SELECTION_TYPES = {
  LAST: 0,
  FIRST: 1,
  ALL: 2
};

const customCommand = [
  { name: 'gc.spread.deleteRows', cmd: 'deleteRowColumnCommand' },
  { name: 'gc.spread.insertRows', cmd: 'insertRowColumnCommand' },
  { name: 'gc.spread.insertColumns', cmd: 'insertRowColumnCommand' },
  { name: 'gc.spread.deleteColumns', cmd: 'deleteRowColumnCommand' }
];
export default class SpreadSheets extends React.Component {
  constructor(props) {
    super(props);
    this.spreadEl = React.createRef();
    this.formulaEl = React.createRef();
    this.statusEl = React.createRef();

    this.workbook = null;

    this.state = {
      selectedCellRange: 'A1',
      copiedFormat: null
    };
  }

  componentDidMount() {
    const { sheetCount, onReady, isProjectReport } = this.props;
    let pGC = import('@grapecity/spread-sheets');
    let pGCExcel = import('@grapecity/spread-excelio');

    // import GC and GCExcel dynamically and on resolve of theirs invoke methods written below
    Promise.all([pGC, pGCExcel]).then(([rGC, rGCExcel]) => {
      GC = rGC;
      GCExcel = rGCExcel;
      if (GCConfig.licenseKey) {
        GC.Spread.Sheets.LicenseKey = GCConfig.licenseKey;
      }

      this.workbook = new GC.Spread.Sheets.Workbook(this.spreadEl.current, {
        sheetCount: sheetCount,
        grayAreaBackColor: '#FFFFFF',
        allowExtendPasteRange: true,
        newTabVisible: false,
        tabStripRatio: 0.4,
        tabEditable: false,
      });

      this.formulaBox = new GC.Spread.Sheets.FormulaTextBox.FormulaTextBox(
        this.formulaEl.current, {});
      this.statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(
        this.statusEl.current, {});
      this.statusBar.bind(this.workbook);
      this.formulaBox.workbook(this.workbook);
      if (!isProjectReport) {
        this.registerCommands();
        this.registerShortcutKeys();
        this.bindEvents();
      }
      onReady();
    });
  }

  get GC() {
    return GC;
  }

  get GCExcel() {
    return GCExcel;
  }

  get isActiveSheetProtected() {
    return this.workbook.sheets[this.workbook.getActiveSheetIndex()].options.isProtected;
  }

  getActiveSheetIndex() {
    return this.workbook.getActiveSheetIndex();
  }

  setSheetData(sheetIndex, data, readonly = false) {
    this.suspend();
    this.workbook.sheets[sheetIndex].setDataSource(data);
    this.setColumnWidths(sheetIndex, 180);
    this.addAdditionalRowsColumns(sheetIndex, 50, 50);
    this.setSheetReadOnly(sheetIndex, readonly);
    this.resume();
  }

  setSheetReadOnly(sheetIndex, readonly) {
    const sheet = this.workbook.sheets[sheetIndex];
    sheet.options.isProtected = readonly;
    sheet.options.protectionOptions.allowResizeRows = true;
    sheet.options.protectionOptions.allowResizeColumns = true;
    sheet.options.protectionOptions.allowFilter = true;
  }

  setSheetColumnCount(sheetIndex, count) {
    const sheet = this.getSheet(sheetIndex);
    sheet.setColumnCount(count);
  }

  addAdditionalRowsColumns(sheetIndex, rowCount, colCount) {
    const sheet = this.workbook.sheets[sheetIndex];
    const rc = sheet.getRowCount();
    const cc = sheet.getColumnCount();
    sheet.addColumns(cc, colCount);
    sheet.addRows(rc, rowCount);
  }

  suspend() {
    this.workbook.suspendPaint();
    this.workbook.suspendCalcService(false);
    this.workbook.suspendEvent();
  }

  resume() {
    this.workbook.resumeCalcService(true);
    this.workbook.resumeEvent();
    this.workbook.resumePaint();
  }

  setColumnWidths(sheetIndex, width = 200) {
    this.suspend();
    const sheet = this.workbook.sheets[sheetIndex];
    const colCount = sheet.getColumnCount();
    for (let i = 0; i < colCount; i++) {
      sheet.setColumnWidth(i, width);
    }
    this.resume();
  }

  setSheetName(sheetIndex, name) {
    this.getSheet(sheetIndex).name(name);
  }

  getSheetNames() {
    return this.workbook.sheets.map(sheet => sheet.name());
  }

  setReadonly(readonly) {
    this.workbook.sheets.forEach((_, i) => this.setSheetReadOnly(i, readonly));
  }

  setCellFormat(sheetIndex, row, col, format) {
    this.suspend();
    const sheet = this.getSheet(sheetIndex);
    sheet.getCell(row, col).formatter(format);
    this.resume();
  }

  getSheet(sheetIndex) {
    if (sheetIndex !== undefined) {
      return this.workbook.sheets[sheetIndex];
    }
    return this.workbook.getActiveSheet();
  }

  getSheets() {
    return this.workbook.sheets;
  }

  getCellValue(sheetIndex, row, col) {
    return this.getSheet(sheetIndex).getCell(row, col).value();
  }

  getCellStyle(sheetIndex, row, col) {
    return this.getSheet(sheetIndex).getStyle(row, col);
  }

  setCellValue(sheetIndex, row, col, value) {
    this.getSheet(sheetIndex).setValue(row, col, value);
  }

  setCellText(sheetIndex, row, col, value) {
    this.getSheet(sheetIndex).setText(row, col, value);
  }

  getColumnValues(sheetIndex, row, rowCount, columnIndex) {
    const cellValues = [];
    for (let i = row; i < rowCount + row; i++) {
      cellValues.push(this.getCellValue(sheetIndex, i, columnIndex));
    }
    return cellValues;
  }

  getColumnStyles(sheetIndex, row, rowCount, columnIndex) {
    const cellStyles = [];
    for (let i = row; i < rowCount + row; i++) {
      cellStyles.push(this.getCellStyle(sheetIndex, i, columnIndex));
    }
    return cellStyles;
  }

  setColumnHeader(sheetIndex, col, text) {
    const sheet = this.getSheet(sheetIndex);
    sheet.setText(0, col, text, GC.Spread.Sheets.SheetArea.colHeader);
  }

  setCellStyle(sheetIndex, row, col, style) {
    this.getSheet(sheetIndex).setStyle(row, col, style);
  }

  /*
   * Add named style on sheet or on workbook if sheetIndex is null
   */
  addStyle(sheetIndex, styleName, {
    backColor, foreColor, borderBottom, borderLeft, borderRight, hAlign = false, vAlign = false, font
  }) {
    const style = new GC.Spread.Sheets.Style();
    style.name = styleName;
    style.backColor = backColor;
    style.foreColor = foreColor;

    if (borderBottom) {
      style.borderBottom =
        new GC.Spread.Sheets.LineBorder(borderBottom.color, GC.Spread.Sheets.LineStyle[borderBottom.lineStyle]);
    }

    if (borderLeft) {
      style.borderLeft =
        new GC.Spread.Sheets.LineBorder(borderLeft.color, GC.Spread.Sheets.LineStyle[borderLeft.lineStyle]);
    }

    if (borderRight) {
      style.borderRight =
        new GC.Spread.Sheets.LineBorder(borderRight.color, GC.Spread.Sheets.LineStyle[borderRight.lineStyle]);
    }

    if (hAlign) {
      style.hAlign = GC.Spread.Sheets.HorizontalAlign.right;
    }

    if (vAlign) {
      style.vAlign = GC.Spread.Sheets.VerticalAlign.center;
    }

    if (font) {
      style.font = font;
    }

    if (sheetIndex !== null) {
      this.getSheet(sheetIndex).addNamedStyle(style);
    } else {
      this.workbook.addNamedStyle(style);
    }
  }

  registerCommands() {
    customCommand.forEach((e) => {
      const menuIndex = this.workbook.contextMenu.menuData.map((item) => item.name).indexOf(e.name);
      this.workbook.contextMenu.menuData[menuIndex].command = e.cmd;
    });

    this.registerCustomCommand('setHAlignCommand', setHorizontalAlignmentCommand);
    this.registerCustomCommand('freezeCommand', freezeCommand);
    this.registerCustomCommand('sortCommand', sortCommand);
    this.registerCustomCommand('setTextFormat', textFormatCommand);
    this.registerCustomCommand('setFormatCommand', setFormatCommand);
    this.registerCustomCommand('setColorCommand', setColorCommand);
    this.registerCustomCommand('setFilterCommand', setFilterCommand);
    this.registerCustomCommand('pasteFormatCommand', pasteFormatCommand);
    this.registerCustomCommand('deleteRowColumnCommand', deleteRowColumnCommand);
    this.registerCustomCommand('insertRowColumnCommand', insertRowColumnCommand);
    this.registerCustomCommand('selectRowCommand', selectRowCommand);
    this.registerCustomCommand('selectColumnCommand', selectColumnCommand);
    this.registerCustomCommand('setItalicTextCommand', italicTextFormatCommand);
    this.registerCustomCommand('setBoldTextCommand', boldTextFormatCommand);
    this.registerCustomCommand('setUnderlineTextCommand', underlineTextFormatCommand);
    this.registerCustomCommand('setAbsoluteFormulaCommand', setAbsoluteFormulaCommand, this, 115);
    this.registerCustomCommand('fillDataCommand', fillDataCommand, this, 'D'.charCodeAt(0), true, false, true);
  }

  registerShortcutKeys() {
    this.registerShortcutKey('selectRowCommand', GC.Spread.Commands.Key.space, true, false, false, false);
    this.registerShortcutKey('selectColumnCommand', GC.Spread.Commands.Key.enter, true, false, false, false);
    // this.registerShortcutKey('deleteRowColumnCommand', 109, true, false, true, false);
    this.registerShortcutKey('deleteRowColumnCommand', 189, true, false, true, false);
    // this.registerShortcutKey('insertRowColumnCommand', 107, true, false, true, false);
    this.registerShortcutKey('insertRowColumnCommand', 187, true, false, true, false);

    this.registerShortcutKey('setItalicTextCommand', 73, true, false, false, false);
    this.registerShortcutKey('setBoldTextCommand', 'B'.charCodeAt(0), true, false, false, false);
    this.registerShortcutKey('setUnderlineTextCommand', 'U'.charCodeAt(0), true, false, false, false);
  }

  registerShortcutKey(command, key, ctrl, shift, alt, meta) {
    this.workbook.commandManager().setShortcutKey(command, key, ctrl, shift, alt, meta);
  }

  updateSelectionState() {
    const sheet = this.getSheet();
    const selection = sheet.getSelections().pop();
    if (selection) {
      const selectedRange = this.getSelectedRangeString(sheet, selection);
      this.setState({
        selectedCellRange: selectedRange
      });
    }
  }

  bindEvents() {
    this.registerEvent(GC.Spread.Sheets.Events.SelectionChanged, SelectionChangedEvent.get());
    this.registerEvent(GC.Spread.Sheets.Events.SelectionChanging, SelectionChangingEvent.get());
  }

  autoFitColumns(sheetIndex) {
    this.suspend();
    const sheet = this.getSheet(sheetIndex);
    const colCount = sheet.getColumnCount();
    for (let i = 0; i < colCount; i++) {
      // ignore collapsed columns
      const cell = sheet.getCell(-1, i);
      const originalWidth = cell.width();
      if (originalWidth > 2) {
        sheet.autoFitColumn(i);
        cell.width(cell.width() + 20);
        cell.resizable(true);
      } else {
        cell.resizable(false);
      }
    }

    this.resume();
  }

  setCellRangeAttr(sheetIndex, row, col, rowCount, colCount, attr, value) {
    this.getSheet(sheetIndex).getRange(row, col, rowCount, colCount)[attr](value);
  }

  getWorkbookData() {
    return this.workbook.toJSON({
      includeBindingSource: true,
      ignoreStyle: false,
      ignoreFormula: false,
      rowHeadersAsFrozenColumns: false,
      columnHeadersAsFrozenRows: false
    });
  }

  getSheetData(sheetIndex) {
    const sheet = this.getSheet(sheetIndex);
    const rowCount = sheet.getRowCount();
    const colCount = sheet.getColumnCount();
    const csv = sheet.getCsv(0, 0, rowCount, colCount, '\r', ',');
    return csv;
  }

  getCellRangeFromSelection(sheetIndex, selection) {
    const {
      row, col, rowCount, colCount
    } = selection;
    return this.getSheet(sheetIndex).getRange(row, col, rowCount, colCount);
  }

  getRange(row, col, rowCount, colCount) {
    return new GC.Spread.Sheets.Range(row, col, rowCount, colCount);
  }

  evaluateFormula(sheet, formula) {
    return GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, formula);
  }

  getSelectedRangeString(sheet, range) {
    let selectionInfo = '';
    const {
      rowCount, colCount, row, col
    } = range;

    if (rowCount === 1 && colCount === 1) {
      selectionInfo = this.getCellPositionString(row + 1, col);
    } else if (rowCount < 0 && colCount > 0) {
      selectionInfo = `${colCount}C`;
    } else if (colCount < 0 && rowCount > 0) {
      selectionInfo = `${rowCount}R`;
    } else if (rowCount < 0 && colCount < 0) {
      selectionInfo = `${sheet.getRowCount()}R x ${sheet.getColumnCount()}C`;
    } else {
      selectionInfo = `${rowCount}R x ${colCount}C`;
    }
    return selectionInfo;
  }

  getCellPositionString(row, column) {
    if (row < 1 || column < 1) {
      return '';
    }

    if (this.workbook.options.referenceStyle === GC.Spread.Sheets.ReferenceStyle.a1) {
      return `${this.toAlphabetColumnName(column)}${row}`;
    }
    return `R${row}C${column}`;
  }

  bindSheetHeader(sheet) {
    const count = sheet.getColumnCount();
    this.suspend();
    for (let i = 0; i < count; i++) {
      sheet.setText(0, i, this.toAlphabetColumnName(i), GC.Spread.Sheets.SheetArea.colHeader);
    }
    this.resume();
  }

  toAlphabetColumnName(x) {
    let num = x + 1;
    let str = '';
    for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
      str = String.fromCharCode(Number((num % b) / a) + 65) + str;
    }
    return str;
  }

  getSelections(sheetIndex, type = SELECTION_TYPES.ALL) {
    const selections = this.getSheet(sheetIndex).getSelections();
    if (selections.length > 0) {
      // eslint-disable-next-line no-nested-ternary
      return type === SELECTION_TYPES.FIRST ? selections[0] :
        type === SELECTION_TYPES.LAST ? selections.pop() :
          selections;
    }

    return type === SELECTION_TYPES.ALL ? [] : null;
  }

  freezeRow(row) {
    this.getSheet().frozenRowCount(row);
  }

  freezeColumn(col) {
    this.getSheet().frozenColumnCount(col);
  }

  resetRowFreeze() {
    this.getSheet().frozenRowCount(1);
  }

  resetColFreeze() {
    this.getSheet().frozenColumnCount(1);
  }

  undo() {
    !this.isActiveSheetProtected && this.workbook.undoManager().undo();
  }

  redo() {
    !this.isActiveSheetProtected && this.workbook.undoManager().redo();
  }

  unfreeze() {
    !this.isActiveSheetProtected && this.resetColFreeze();
    !this.isActiveSheetProtected && this.resetRowFreeze();
  }

  clearCopyFormat() {
    this.setState({
      copiedFormat: null
    });
  }

  async loadExcelFileBlob(fileBlob) {
    const jsonData = await this.getJsonFromBlob(fileBlob);
    this.workbook.fromJSON(jsonData);
    return jsonData;
  }

  getJsonFromBlob(blob) {
    const excelIO = new this.GCExcel.IO();

    return new Promise((resolve, reject) => {
      excelIO.open(
        blob,
        (json) => {
          resolve(json);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  handlePaintFormat() {
    const { copiedFormat } = this.state
    if (!copiedFormat) {
      this.copyFormat();
    } else {
      this.clearCopyFormat();
    }
  }

  registerEvent(event, handler, spreadsheet = null) {
    this.workbook.bind(event, handler.handleEvent.bind(handler, spreadsheet || this));
  }

  registerCustomCommand(
    commandName, command, spreadsheet = null, key = null, ctrl = false, shift = false, alt = false, meta = false,
  ) {
    const commands = GC.Spread.Sheets.Commands;
    this.workbook.commandManager().register(commandName, {
      canUndo: true,
      canRedo: true,
      execute: (context, options, isUndo) => {
        if (isUndo) {
          commands.undoTransaction(context, options);
          if (command.hasUndo) {
            command.undoCommand(spreadsheet || this, options);
          }
          return true;
        }

        commands.startTransaction(context, options);
        command.runCommand(spreadsheet || this, options);
        commands.endTransaction(context, options);

        return true;
      }
    }, key, ctrl, shift, alt, meta);
  }

  setTextFormat(formatType) {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetProtected && this.workbook.commandManager().execute({
      cmd: 'setTextFormat', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex, formatType
    });
  }

  setHAlign(alignment) {
    const activeSheetIndex = this.workbook.getActiveSheetIndex();
    !this.isActiveSheetProtected && this.workbook.commandManager().execute({
      cmd: 'setHAlignCommand', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex, alignment
    });
  }

  sort(ascending) {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetProtected && this.workbook.commandManager().execute({
      cmd: 'sortCommand', sheetName: this.getSheet(activeSheetIndex).name(), activeSheetIndex, ascending
    });
  }

  freeze() {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetProtected && this.workbook.commandManager().execute({
      cmd: 'freezeCommand',
      sheetName: this.getSheet(activeSheetIndex).name(),
      activeSheetIndex
    });
  }

  setFormat(format) {
    const activeSheetIndex = this.getActiveSheetIndex();
    const alignment = format === FORMATS.NUMBER ?
      GC.Spread.Sheets.HorizontalAlign.right :
      GC.Spread.Sheets.HorizontalAlign.left;

    !this.isActiveSheetProtected && this.workbook.commandManager().execute({
      cmd: 'setFormatCommand',
      sheetName: this.getSheet(activeSheetIndex).name(),
      activeSheetIndex,
      format,
      alignment
    });
  }

  setColor(isForeColor, color) {
    const activeSheetIndex = this.getActiveSheetIndex();
    const selections = this.getSheet(activeSheetIndex).getSelections();
    !this.isActiveSheetProtected && this.workbook.commandManager().execute({
      cmd: 'setColorCommand',
      sheetName: this.getSheet(activeSheetIndex).name(),
      activeSheetIndex,
      selections,
      isForeColor,
      color
    });
  }

  copyFormat() {
    if (!this.isActiveSheetProtected) {
      const { row, col } = this.getSelections(undefined, SELECTION_TYPES.FIRST);
      const copiedFormat = this.getSheet().getStyle(row, col) || new GC.Spread.Sheets.Style();
      this.setState({
        copiedFormat
      });
    }
  }

  pasteFormat() {
    const { copiedFormat } = this.state;
    if (copiedFormat) {
      const activeSheetIndex = this.getActiveSheetIndex();
      const selections = this.getSheet(activeSheetIndex).getSelections();
      !this.isActiveSheetProtected && this.workbook.commandManager().execute({
        cmd: 'pasteFormatCommand',
        sheetName: this.getSheet(activeSheetIndex).name(),
        activeSheetIndex,
        selections,
        copiedFormat: copiedFormat
      });
      this.clearCopyFormat();
    }
  }

  setFilter() {
    const activeSheetIndex = this.getActiveSheetIndex();
    !this.isActiveSheetProtected &&
      this.workbook.commandManager().execute({
        cmd: 'setFilterCommand',
        sheetName: this.getSheet(activeSheetIndex).name(),
        activeSheetIndex
      });
  }

  handleToolbarAction = (action, data) => {
    switch (action) {
      case ACTIONS.UNDO:
        this.undo();
        return;
      case ACTIONS.REDO:
        this.redo();
        return;
      case ACTIONS.ALIGN_LEFT:
        this.setHAlign('left');
        return;
      case ACTIONS.ALIGN_CENTER:
        this.setHAlign('center');
        return;
      case ACTIONS.ALIGN_RIGHT:
        this.setHAlign('right');
        return;
      case ACTIONS.FREEZE:
        this.freeze();
        return;
      case ACTIONS.UNFREEZE:
        this.unfreeze();
        return;
      case ACTIONS.SORT_ASCENDING:
        this.sort(true);
        return;
      case ACTIONS.SORT_DESCENDING:
        this.sort(false);
        return;
      case ACTIONS.TEXT_BOLD:
        this.setTextFormat('bold');
        return;
      case ACTIONS.TEXT_ITALIC:
        this.setTextFormat('italic');
        return;
      case ACTIONS.TEXT_UNDERLINE:
        this.setTextFormat('underline');
        return;
      case ACTIONS.FORMAT_CELL:
        this.setFormat(data?.format);
        return;
      case ACTIONS.FILL_CELL:
        this.setColor(false, data.color.hex);
        return;
      case ACTIONS.TEXT_COLOR:
        this.setColor(true, data.color.hex);
        return;
      case ACTIONS.PAINT_FORMAT:
        this.handlePaintFormat();
        return;
      case ACTIONS.FILTER:
        this.setFilter();
        return;
      default:
        console.error('Unknown action to handle');
    }
  };

  onRowColDeletion(isUndo, sheet, row, col, rowCount, colCount) {
    const { onRowColDeletion } = this.props;
    onRowColDeletion(isUndo, sheet, row, col, rowCount, colCount);
  }

  onRowColInsertion(isUndo, sheet, row, col, rowCount, colCount) {
    const { onRowColInsertion } = this.props;
    onRowColInsertion(isUndo, sheet, row, col, rowCount, colCount);
  }

  absoluteFormulaReference(sheet, row, col) {
    const { hasFormula } = sheet.getFormulaInformation(row, col);
    const cursorPosition = this.formulaBox.caret();
    const formulaExpression = this.formulaEl.current.value;
    const cellRefRegex = /[a-zA-Z]{1,2}(\d{1,3})/g;

    if (hasFormula && formulaExpression.charAt(0) === '=') {
      const absoluteCellRef = [...formulaExpression.matchAll(cellRefRegex)];
      const cellAddress = absoluteCellRef.filter((_, i) => absoluteCellRef[i].index <= cursorPosition).pop();

      if (cellRefRegex.test(cellAddress[0])) {
        const newCellRef = this.addDollarToString(cellAddress[0]);
        const formula = this.replaceMatchedString(
          formulaExpression, cellAddress.index, cellAddress[0].length, newCellRef,
        );
        sheet.setFormula(row, col, formula);
        this.formulaBox.text(formula);
        sheet.setSelection(row, col, 1, 1);
      }
    }
  }

  addDollarToString(str) {
    const hasString = /[a-zA-Z]/g;
    let absoluteRef = '$';
    for (let i = 0; i < str.length; i++) {
      hasString.test(str[i]) ? absoluteRef += `${str[i]}$` : absoluteRef += str[i];
    }
    return absoluteRef;
  }

  replaceMatchedString(formulaExpression, cellAddress, stringLength, newCellRef) {
    const replaceFormulaExp = formulaExpression.split('');
    replaceFormulaExp.splice(cellAddress, stringLength);
    replaceFormulaExp.splice(cellAddress, 0, newCellRef);
    return replaceFormulaExp.join('');
  }

  // eslint-disable-next-line max-len
  getMatchedCellValues(sheetIndex, rowCount, sourceColumnIndex, targetColumnIndex, cellValue, isExactDataTypeMatch = true) {
    this.suspend();
    const sheet = this.getSheet(sheetIndex);
    const foundRowIndex = [];
    for (let row = 1; row < rowCount; row++) {
      const columnValue = sheet.getCell(row, sourceColumnIndex).value();
      // eslint-disable-next-line eqeqeq
      const validatedCellValue = isExactDataTypeMatch ? columnValue === cellValue : columnValue == cellValue;
      if (validatedCellValue) {
        foundRowIndex.push(sheet.getCell(row, targetColumnIndex).value());
      }
    }
    this.resume();
    return foundRowIndex;
  }

  setCellFormula(sheet, row, col, formulaExpression) {
    this.suspend();
    sheet.setFormula(row, col, formulaExpression);
    this.resume();
  }

  setActiveSheet(sheetName) {
    !!sheetName && this.workbook.setActiveSheet(sheetName);
  }

  setColumnsFont(sheet, row, columns = [], font) {
    this.suspend();
    columns.forEach(column => {
      sheet.getCell(row, column).font(font);
    })
    this.resume();
  }

  render() {
    const { sheetHeight, showToolbar, toolbarCustomButtons, toolbarCustomRightChild } = this.props;
    const { copiedFormat, selectedCellRange } = this.state;
    return (
      <div className="vertical-section" style={sheetHeight}>
        {
          showToolbar && (
            <Container fluid className="p-0">
              <SpreadSheetToolbar
                customToolbarButtons={toolbarCustomButtons}
                customRightChild={toolbarCustomRightChild} onAction={this.handleToolbarAction}
                paintFormatActive={!!copiedFormat} />
              <Row className={`pr-2 ${styles.formulaBarInputContainer}`}>
                <Col xs="auto" className="pr-0">
                  <span id="SSCellBox" className={`position-relative d-inline-block ${styles.formulaBarLabel}`}>
                    {selectedCellRange}
                  </span>
                </Col>
                <Col className={`${styles.formulaBarInput}`}>
                  <input id="SSFormulaBox" ref={this.formulaEl} className={`position-relative d-inline-block m-0 ${styles.formulaBar} ${styles.cellInput}`} style={{ flexGrow: 0 }} />
                </Col>
              </Row>
            </Container>
          )
        }
        <div className="vertical-section position-relative">
          <div ref={this.spreadEl} className="fill-parent" />
        </div>
        <div ref={this.statusEl} style={{ flexGrow: 0 }} />
      </div>
    );
  }
}

SpreadSheets.propTypes = {
  showToolbar: PropTypes.bool,
  sheetCount: PropTypes.number,
  onReady: PropTypes.func.isRequired,
  toolbarCustomButtons: PropTypes.node,
  toolbarCustomRightChild: PropTypes.node,
  onRowColDeletion: PropTypes.func,
  onRowColInsertion: PropTypes.func,
  sheetHeight: PropTypes.object,
  isProjectReport: PropTypes.bool
};

SpreadSheets.defaultProps = {
  showToolbar: true,
  sheetCount: 1,
  onRowColDeletion: noopFunc,
  onRowColInsertion: noopFunc,
  sheetHeight: {},
  isProjectReport: false,
  toolbarCustomButtons: null,
  toolbarCustomRightChild: null
};