import React, { useCallback, useState } from 'react';
import { withServices } from 'reaf';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row, Container, InputGroup, Modal, Table } from 'react-bootstrap';
import Switch from 'react-switch';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faCopy as copyIcon } from '@fortawesome/free-solid-svg-icons/faCopy';
import { get } from 'lodash';
import { faTrash as deleteProjectIcon } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faMailBulk as emailIcon } from '@fortawesome/free-solid-svg-icons/faMailBulk';
import { faUserCheck as verifiedEmailIcon } from '@fortawesome/free-solid-svg-icons/faUserCheck';
import { faUser as unVerifiedEmailIcon } from '@fortawesome/free-solid-svg-icons/faUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '../../core/Button/IconButton';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import styles from './SharedReportLinks.module.scss';

const SharedReportLinks = ({
  reportLinks: initialReportLinks,
  reportLinkService,
  projectsService,
  toastrService
}) => {
  const [reportLinks, setReportLinks] = useState(initialReportLinks);
  const [showDeleteReportPopup, setShowDeleteReportPopup] = useState(false);
  const [selectedReportLink, setSelectedReportLink] = useState(false);
  const [showCapturedEmails, setShowCapturedEmails] = useState(false);

  const refetchReportLinks = useCallback(async () => {
    const reportLinks = await reportLinkService.getSharedReportLinks();
    setReportLinks(reportLinks);
  }, []);

  const onShowCapturedEmails = useCallback((selectedReportLink) => {
    setSelectedReportLink(selectedReportLink);
    setShowCapturedEmails(true);
  }, []);

  const onConfirmDelete = useCallback(async () => {
    await reportLinkService.deleteAdminReportLink(selectedReportLink);
    refetchReportLinks();
    setShowDeleteReportPopup(false);
    setSelectedReportLink(null);
  }, [selectedReportLink]);

  const updateCanDownloadDocument = useCallback(
    async (sharedReportLinkDetail) => {
      const value = !sharedReportLinkDetail.canDownloadDocument;
      await reportLinkService.updateAdminReportLink(sharedReportLinkDetail, {
        canDownloadDocument: value
      });
      refetchReportLinks();
    },
    []
  );

  const deleteCanDownloadDocument = useCallback(
    async (sharedReportLinkDetail) => {
      setShowDeleteReportPopup(true);
      setSelectedReportLink(sharedReportLinkDetail);
    },
    []
  );

  const sortFunc = useCallback(
    (fieldName) => (a, b, order) => {
      if (order === 'desc') {
        if (get(a, fieldName) > get(b, fieldName)) {
          return -1;
        } else if (get(a, fieldName) < get(b, fieldName)) {
          return 1;
        }
        return 0;
      }
      if (get(a, fieldName) < get(b, fieldName)) {
        return -1;
      } else if (get(a, fieldName) > get(b, fieldName)) {
        return 1;
      }
      return 0;
    },
    []
  );

  const onCopyText = useCallback(() => {
    toastrService.success('Report Link Successfully Copied', 'top');
  }, [toastrService]);

  const onHide = useCallback(() => {
    setShowDeleteReportPopup(false);
    setShowCapturedEmails(false);
    setSelectedReportLink(null);
  }, []);

  return (
    <>
      <Container fluid className="p-2 pb-3">
        <Row className="align-items-center">
          <Col sm="auto">
            <h5>Shared Report Links</h5>
          </Col>
        </Row>
      </Container>
      <div className="prospectInvoicesTable">
        <BootstrapTable
          data={reportLinks}
          tableStyle={{ background: '#fafafa' }}
          condensed
          hover
          pagination
        >
          <TableHeaderColumn
            dataField="uuid"
            isKey
            hidden
            dataSort
            width="60"
            dataAlign="center"
          >
            S.N.
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Project"
            sortFunc={sortFunc('Project.name')}
            dataFormat={(cell) => cell['name']}
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataSort
          >
            Project Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="Company"
            sortFunc={sortFunc('Company.name')}
            dataFormat={(cell) => cell['name']}
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataSort
          >
            Company Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="templateMapping"
            sortFunc={sortFunc('Project.templateMapping.templateName')}
            dataFormat={(cell, row) =>
              get(row['Project'], 'templateMapping.templateName', '')
            }
            tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
            thStyle={{ whiteSpace: 'normal' }}
            dataSort
          >
            Template
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="canDownloadDocument"
            sortFunc={sortFunc('canDownloadDocument')}
            width="80"
            text
            className="text-center"
            dataFormat={(canDownloadDocument, row) => (
              <Switch
                onChange={(value) => {
                  updateCanDownloadDocument(row);
                }}
                height={23}
                width={45}
                borderRadius={0}
                checkedIcon={false}
                uncheckedIcon={false}
                checked={canDownloadDocument}
              />
            )}
          >
            Can Download
            <br /> Document
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="createdAt"
            sortFunc={sortFunc('createdAt')}
            dataSort
            width="110"
            dataFormat={(date) => <DisplayDate date={date} />}
          >
            Shared on
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="canDownloadDocument"
            width="350"
            dataFormat={(canDownloadDocument, row) => (
              <div className="d-flex flex-row">
                <InputGroup>
                  <input
                    type="text"
                    className="form-control"
                    value={row['reportLinkUrl']}
                  />
                  <InputGroup.Append>
                    <CopyToClipboard text={row['reportLinkUrl']}>
                      <IconButton
                        id="RefreshProjectDocumentButton"
                        title="Copy Link"
                        placement="left"
                        onClick={onCopyText}
                        className="btn-xs"
                        icon={copyIcon}
                      />
                    </CopyToClipboard>
                  </InputGroup.Append>
                </InputGroup>
                <IconButton
                  title="Delete"
                  className=" deleteIcon"
                  variant="outline-danger"
                  icon={deleteProjectIcon}
                  onClick={() => deleteCanDownloadDocument(row)}
                />
                <IconButton
                  title="Show captured emails"
                  className=" deleteIcon"
                  variant="outline-primary"
                  icon={emailIcon}
                  onClick={() => onShowCapturedEmails(row)}
                />
              </div>
            )}
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>
      </div>

      {selectedReportLink && showDeleteReportPopup && (
        <ConfirmationModal
          show={showDeleteReportPopup}
          title="Delete shared report link"
          message={`Are you sure you want to delete the shared project report link of ${selectedReportLink['Project'].name}`}
          onConfirm={onConfirmDelete}
          onCancel={onHide}
        />
      )}

      {selectedReportLink && showCapturedEmails && (
        <Modal
          show={showCapturedEmails}
          backdrop="static"
          animation={false}
          onHide={onHide}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Captured Emails</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedReportLink.CaptureEmails &&
            selectedReportLink.CaptureEmails.length > 0 ? (
              <Table hover>
                <tbody>
                  {selectedReportLink.CaptureEmails.map(
                    ({ email, isVerified, createdAt }) => (
                      <tr key={email}>
                        <td>
                          <FontAwesomeIcon
                            className={isVerified ? styles.verified : ''}
                            icon={
                              isVerified
                                ? verifiedEmailIcon
                                : unVerifiedEmailIcon
                            }
                          />
                        </td>
                        <td>{email}</td>
                        <td>
                          <DisplayDate date={createdAt} />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            ) : (
              <div>No email captured</div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default withServices(
  'reportLinkService',
  'projectsService',
  'toastrService'
)(SharedReportLinks);
