import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, Fade } from 'react-bootstrap';
import moment from 'moment';
import { loggedInCompanySelector, readOnlyAccessSelector, routerStateSelector } from '../../../store/selectors';
import config from '../../../config';
import styles from './RibbonNotification.module.scss';
import { isUndefined } from 'lodash';

function RibbonNotification({ loggedInCompany, readOnlyAccess, router }) {
  let showRibbonCondition = false;
  let notificationMessage = '';
  if (config.app.isFreebieApp) {
    showRibbonCondition = moment().diff(moment(loggedInCompany.createdAt), 'days') > 20;
    notificationMessage = 'Your trial is getting expired, please connect with our sales team to get some exciting offers';
  } else {
    showRibbonCondition = Reflect.ownKeys(loggedInCompany).length > 0 &&
      loggedInCompany.paymentDueDate !== null &&
      loggedInCompany.paymentDueDate !== '' &&
      moment().isAfter(moment(loggedInCompany.paymentDueDate));
    // eslint-disable-next-line no-prototype-builtins
    if (loggedInCompany?.hasOwnProperty('notificationText') && loggedInCompany.notificationText !== null) {
      showRibbonCondition = true;
    }
    showRibbonCondition && (notificationMessage = loggedInCompany.notificationText || 'Your account will be downgraded soon due to pending payment. Please contact your company administrator to avoid cancellation.');
  }

  const [showRibbon, setShowRibbon] = useState(showRibbonCondition);
  const [showNotificationText, setShowNotificationText] = useState(notificationMessage);
  const [showReadOnlyRibbon, setShowReadOnlyRibbon] = useState(false);

  useEffect(() => {
    setShowRibbon(showRibbonCondition);
    setShowNotificationText(notificationMessage);
  }, [loggedInCompany])

  useEffect(() => {
    if(router.pathname.includes('/projects/') && readOnlyAccess) {
      setShowReadOnlyRibbon(true);
      setShowRibbon(true);
      notificationMessage = 'You have readonly access, for write please contact admin';
      setShowNotificationText(notificationMessage);
    } else if((showReadOnlyRibbon && !router.pathname.includes('/projects/') || showReadOnlyRibbon && !readOnlyAccess)) {
      setShowReadOnlyRibbon(false);
      showRibbonCondition= false;
      notificationMessage= '';
      setShowRibbon(false)
      setShowNotificationText(notificationMessage);
    }
  }, [readOnlyAccess, router])

  return (
    <>
      {
        showRibbon && (
          <div className={`text-center ${styles.paymentReminderRibbon}`}>
            <Fade>
              <Alert 
                bsPrefix={`d-inline-block position-relative alert-warning p-2 ${styles.alertWarning}`} 
                onClose={() => setShowRibbon(false)} dismissible={router.pathname.includes('/projects/') && readOnlyAccess ? false : true}
              >
                {showNotificationText}
              </Alert>
            </Fade>
          </div>
        )}
    </>
  );
}

const mapStateToProps = state => ({
  loggedInCompany: loggedInCompanySelector(state),
  readOnlyAccess: readOnlyAccessSelector(state),
  router: routerStateSelector(state)
});

export default connect(mapStateToProps)(RibbonNotification);
