
import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';
import config from '../../../config';

const stripePromise = loadStripe(config.stripe.publishableKey);
const StripePaymentForm = ({ clientSecret, formWrapperClass, onSuccess, onCancel }) => {
  const appearance = {
    theme: 'stripe',
  };

  return <>
      {
        clientSecret && (
          <Elements options={{
            clientSecret,
            appearance,
          }} stripe={stripePromise}>
            <CheckoutForm formWrapperClass={formWrapperClass} onSuccess={onSuccess} onCancel={onCancel} />
          </Elements>
        )
      }
    </>
  
};

export default StripePaymentForm;
