import React, { useState } from 'react';
import DocExtractionAffixChartFilter from './DocExtractionAffixChartFilter';
import DocExtractionAffixPieChart from './DocExtractionAffixPieChart';
import DocExtractionAffixBarChart from './DocExtractionAffixBarChart';
import DocExtractionAffixLineChart from './DocExtractionAffixLineChart';
import { chartType } from '../../../constants';

function DocExtractionAffixChart({
  data, parentStyle, showChartTypeFilter = true, filterChartData, xAxisKeyName, yAxisKeyName
}) {
  const [selectedChartType, setSelctedChartType] = useState(chartType.PIE);
  return (
    <>
      <div className={parentStyle}>
        {
          showChartTypeFilter && <DocExtractionAffixChartFilter 
            filterChartData={filterChartData} 
            onChartChange={(chartType) => setSelctedChartType(chartType)} 
          />
        }
        {selectedChartType === chartType.PIE && <DocExtractionAffixPieChart data={data} />}
        {selectedChartType === chartType.BAR && <DocExtractionAffixBarChart 
          data={data} 
          xAxisKeyName={xAxisKeyName} 
          yAxisKeyName={yAxisKeyName} 
        />}
        {selectedChartType === chartType.LINE && <DocExtractionAffixLineChart 
          data={data} 
          xAxisKeyName={xAxisKeyName} 
          yAxisKeyName={yAxisKeyName} /> 
        }
      </div>
    </>
  );
}

export default DocExtractionAffixChart;
