import React from 'react';
import FormModal from '../FormModal/FormModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ProjectExporterForm from '../ProjectExporterForm/ProjectExporterForm';
import useModalForm from '../../../hooks/useModalForm';

function ProjectExporterPopups({
  projectExporter, onHide, showCreateProjectExporter, showDeleteProjectExporter, onDeleteConfirm, onDeleteCancel
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {
        showCreateProjectExporter &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showCreateProjectExporter} title="Add Property Exporter">
            <ProjectExporterForm 
              bindSubmission={bindSubmission} 
              onSubmitSuccess={onHide} 
              projectExporter={projectExporter} 
            />
          </FormModal>
        )
      }
      {
        showDeleteProjectExporter &&
        (
          <ConfirmationModal
            show={showDeleteProjectExporter}
            title={`Delete Property Exporter ${projectExporter.name}?`}
            message="Are you sure you want to delete this property exporter?"
            onConfirm={onDeleteConfirm}
            onCancel={onDeleteCancel}
          />
        )
      }
    </div>
  );
}

export default ProjectExporterPopups;
