import React, { useState, useMemo, useCallback } from 'react';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import style from './DownloadProgress.module.scss';
import { ListGroup } from 'react-bootstrap';
import { FILE_TYPES } from '../../../constants';

export default function DownloadProgress({
    fileName = '',  onDownloadCloseClick
}) {
  return (
    <div className={`mb-2 ${style.uploadStatusDialog} ${style.uploadProgressPopup}`}>
      <div className={`modal-header text-white ${style.modalHeader}`}>
        <Container fluid>
          <Row>
            <Col xs="auto" className={`${style.modalTitle}`}>
            <FontAwesomeIcon spin icon={faCircleNotch} className="text-white" />

                <span className='ml-2'>Preparing download</span>
            </Col>
            <Col />
            <Col xs="auto" className={`${style.modalClose}`}>
              <button type="button" className={`close ${style.close}`} onClick={onDownloadCloseClick}>
                <span aria-hidden="true">×</span>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      <ListGroup className={`${style.uploadListGroup}`}>
        <ListGroup.Item className={`${style.listGroupItem}`}>
          <Container fluid>
            <Row>
              <Col xs="auto" className="p-1">
                <img src={FILE_TYPES.XL.icon} width={20} height={20}/>
              </Col>
              <Col xs="auto" className="p-1 d-flex align-items-center">
                <small
                  className={`${style.uploadFileName} text-with-ellipsis d-inline-block`}
                >
                 {fileName}
                </small>
              </Col>
              <Col className="p-1" />
            </Row>
          </Container>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
}