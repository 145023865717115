
import { setProspectsList, setProspectInvoicesList, setCurrentProspectUuid } from '../store/prospects';

class ProspectsService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  async createProspect(values) {
    const { numberOfLicenses, amountPerLicense } = values.prospect;
    const prospect = await this.apiClient.post(
      '/prospects',
      { ...values.prospect, totalAmount: numberOfLicenses * amountPerLicense },
    );
    await this.loadProspects();
    return prospect;
  }

  async loadProspects() {
    const result = await this.apiClient.get('/prospects');
    const { prospects } = result.response;
    this.store.dispatch(setProspectsList(prospects));
    return prospects;
  }

  async updateProspect(prospect, values) {
    const { numberOfLicenses, amountPerLicense } = values.prospect;
    await this.apiClient.put(`/prospects/${prospect.prospect.uuid}`, 
    { ...values.prospect, totalAmount: numberOfLicenses * amountPerLicense });
    return this.loadProspects();
  }

  async sendProspectInvoice({ prospect }, values) {
    const { numberOfLicenses, amountPerLicense } = values.prospect;
    if (prospect.numberOfLicenses !== numberOfLicenses || prospect.amountPerLicense !== amountPerLicense) {
      await this.updateProspect({ prospect }, values);
      await this.loadProspects();
    } 
    const result = await this.apiClient.post(`/prospects/${prospect.uuid}/invoices`, {});
    return result;
  }

  async loadProspectInvoices(prospectUuid) {
    const result = await this.apiClient.get(`/prospects/${prospectUuid}/invoices`);
    const { invoices } = result.response;
    await this.loadProspects();
    this.store.dispatch(setProspectInvoicesList(invoices));
    this.store.dispatch(setCurrentProspectUuid(prospectUuid));
    return invoices;
  }


  async voidInvoice(prospect, invoice) {
    const result = await this.apiClient.put(`/prospects/${prospect.uuid}/invoices/${invoice.uuid}/void`);
    await this.loadProspects();
    await this.loadProspectInvoices(prospect.uuid);
    return result;
  }
}

export default ProspectsService;
