import React from 'react';
import { get } from 'lodash';

function filter(searchText, filterHandler, nestedSearchKey) {
  filterHandler({
    callback: searchValue => (searchText ?
      searchValue &&
        get(searchValue, nestedSearchKey, '').toLowerCase().indexOf(searchText.toLowerCase()) !== -1 :
      true)
  });
}

const CustomFilter = (props) => {
  const { filterHandler, nestedSearchKey } = props;
  return (
    <input type="text" className="filter text-filter form-control" placeholder="Filter" 
      onChange={e => filter(e.target.value, filterHandler, nestedSearchKey)} />
  );
}

const CustomSelectFilter = (props) => {
  const { options, filterHandler, nestedSearchKey } = props;
  const filterOptions = Reflect.ownKeys(options).map(
    key => (<option value={key} key={key}>{options[key].label}</option>));

  return (
    <select className="filter select-filter form-control placeholder-selected" 
      onChange={e => filter(e.target.value, filterHandler, nestedSearchKey)}>
      <option value="">Choose...</option>
      {
        filterOptions
      }
    </select>
  );
}

export { CustomSelectFilter }

export default CustomFilter;
