import React, { useState } from 'react'
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import { Alert, Card } from 'react-bootstrap';
import ReactSelect from 'react-select';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import { dynamicAssetTypesListSelector, loggedInCompanySelector } from '../../../store/selectors';
import DocumentFileDrop from './DocumentFileDrop';


const documentTypes = [
  { label: 'RENT ROLL', value: 'RENT_ROLL' },
  // { label: 'OPERATING STATEMENT', value: 'OS' },
]

const assetTypes = [
  { label: 'COMMERCIAL', value: 'RETAIL' },
  // { label: 'MULTIFAMILY', value: 'MULTIFAMILY' },
  // { label: 'HOTEL', value: 'HOTEL' },
  // { label: 'OFFICE', value: 'OFFICE' },
  // { label: 'INDUSTRIAL', value: 'INDUSTRIAL' },
  // { label: 'HEALTHCARE', value: 'HEALTHCARE' },
  // { label: 'SENIOR HOSUING', value: 'SENIOR_HOSUING' },
  // { label: 'PARKING GARAGE', value: 'PARKING_GARAGE' },
]

const versions = [
  { label: 'Charlie', value: 'charlie' },
  { label: 'Oscar', value: 'oscar' },
  { label: 'Golf', value: 'golf' },
  { label: 'Christopher (Beta)', value: 'christopher' },
]

function DropDownWithLabel({
  title = '', dropDownsOptions, onDropdownChange
}) {
  const onChange = ({ value }) => {
    onDropdownChange(value)
  }

  return (
    <div className="col form-group">
      <label htmlFor="file" className="tagLabel-heading">{title}</label>
      <ReactSelect
        placeholder={`Select ${title}`}
        classNamePrefix="react-select"
        options={dropDownsOptions}
        defaultValue={dropDownsOptions[0]}
        menuPosition="fixed"
        onChange={onChange}
        menuPlacement="auto" />
    </div>

  )
}

function InputFieldWithLabel({ title = '', name = '' }) {
  return (
    <div className="col form-group">
      <label htmlFor={name}>{title} </label>
      <Field id={name} name={name} className="form-control" />
    </div>
  )
}



function FallbackApproachFormComponent({ isLoading, onSubmit }) {
  const [uploadedFile, setUploadedFile] = useState(null);

  const onDeleteUploadedFile = (props) => {
    setUploadedFile(null);
    props.setFieldValue('file', null);
  }

  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center container-fluid overflow-auto h-100 w-75 my-5">
        <div className='text-center'>
          <h2 className='text-muted mb-3'>Parser Beta</h2>
        </div>
        <Formik
          initialValues={{
            document_title: 'RENT_ROLL',
            assetType: 'RETAIL',
            period_from: '',
            period_to: '',
            file: null,
            from: '',
            to: '',
            version: 'charlie'
          }}
          onSubmit={onSubmit}
        >
          {props => (
            <Form className='form-group w-100'>
              <DocumentFileDrop onChangeFile={(uploadedFile) => {
                setUploadedFile(uploadedFile[0]);
                props.setFieldValue('file', uploadedFile[0]);
              }
              } />
              {
                uploadedFile && (
                  <Alert variant="success" onClose={() => onDeleteUploadedFile(props)} dismissible>
                    {uploadedFile?.path}
                  </Alert>
                )
              }
              <Card className='shadow-sm'>
                <Card.Body>
                  <div className="form-row">
                    <DropDownWithLabel
                      title="Document Type"
                      dropDownsOptions={documentTypes}
                      onDropdownChange={(value) => {
                        props.setFieldValue('document_title', value);
                      }}
                    />
                    <DropDownWithLabel
                      title="Asset Type"
                      dropDownsOptions={assetTypes}
                      onDropdownChange={(value) => {
                        props.setFieldValue('assetType', value);
                      }}
                    />
                    {/* <InputFieldWithLabel title="Period From" name='period_from' />
                    <InputFieldWithLabel title="Period To" name='period_to' /> */}
                    <InputFieldWithLabel title="Page From" name='from' />
                    <InputFieldWithLabel title="Page To" name='to' />
                    <DropDownWithLabel
                      title="Version"
                      dropDownsOptions={versions}
                      onDropdownChange={(value) => {
                        props.setFieldValue('version', value);
                      }}
                    />
                  </div>

                  <div className="form-row mt-1">
                    <div className="col form-group text-center mb-0">
                      <FormSubmitButton
                        id="formSubmitButton"
                        isValid={!isLoading}
                        title="Extract Document"
                        processingText='Please wait...'
                        isSubmitting={isLoading}
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}


const mapStateToProps = (state) => {
  const currentCompany = loggedInCompanySelector(state);
  return {
    company: currentCompany,
    assetTypes: dynamicAssetTypesListSelector(state),
  };
};

export default connect(mapStateToProps)(
  withServices('fallbackExtractionService')(FallbackApproachFormComponent)
);
