import React from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FormModal from '../FormModal/FormModal';
import CompanyForm from '../CompanyForm/CompanyForm';
import EditCompanyForm from '../CompanyForm/EditCompanyForm';
import useModalForm from '../../../hooks/useModalForm';
import CompanySSOForm from '../CompanyForm/CompanySSOForm';
import SsoConfigForm from '../SsoConfigForm/SsoConfigForm';
import ModalWrapper from '../../core/ModalWrapper/ModalWrapper';
import ApiIntegratonForm from '../ApiConfigForm/ApiConfigForm';
import CompanyGlobalTemplateAccess from '../CompanyForm/CompanyGlobalTemplateAccess';

function CompanyPopups({
  company,
  onHide,
  onEditSuccess,
  onAddSuccess,
  showCreateCompany,
  showDeleteConfirm,
  showCompanySAMLForm,
  onDeleteConfirm,
  showEditCompanyForm,
  showGlobalTemplateAccessFrom,
  onDeleteCancel,
  showCompanySSOForm,
  showApiIntegrationForm,
  apiConfigDetails,
  setApiConfigDetails
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
  const [isSubmittingEditForm, bindSubmissionEditForm, onSubmitEditForm] = useModalForm();

  return (
    <div>
      {
        showCreateCompany &&
        (
          <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showCreateCompany} title={company && company.company && company.company.uuid ? 'Edit Company' : 'Add Company'}>
            <CompanyForm
              bindSubmission={bindSubmission}
              onSubmitSuccess={(company) => {
                onHide();
                onAddSuccess(company)
              }}
              company={{
                ...company,
                company: {
                  ...company.company,
                  canDownloadModel: false,
                  canDownloadReportWorkbook: false
                }
              }}
            />
          </FormModal>
        )
      }
      {
        showEditCompanyForm &&
        (
          <FormModal isSubmitting={isSubmittingEditForm} onSubmit={onSubmitEditForm} onHide={onHide} show={showEditCompanyForm} title={company && company.company && company.company.uuid ? 'Edit Company' : 'Add Company'}>
            <EditCompanyForm bindSubmission={bindSubmissionEditForm} onSubmitSuccess={(company) => {
              onHide();
              onEditSuccess(company)
            }} company={company} />
          </FormModal>
        )
      }
      {
        showCompanySSOForm &&
        (
          <FormModal
            isSubmitting={isSubmitting} onSubmit={onSubmit}
            onHide={onHide} show={showCompanySSOForm} size=""
            title="Single Sign-on configuration">
            <CompanySSOForm bindSubmission={bindSubmission} onSubmitSuccess={(company) => {
              onHide();
              onEditSuccess(company)
            }} companyDetails={company} />
          </FormModal>
        )
      }
      {
        showCompanySAMLForm &&
        (
          <ModalWrapper
            isSubmitting={isSubmitting} onSubmit={onSubmit}
            onHide={onHide} show={showCompanySAMLForm}
            content={<SsoConfigForm company={company.company} />}
            title="SAML configuration" />
        )
      }
      {
        showDeleteConfirm &&
        (
          <ConfirmationModal
            show={showDeleteConfirm}
            title={`Delete Company ${company.company.name}?`}
            message={`Are you sure you want to delete the Company ${company.company.name}`}
            onConfirm={onDeleteConfirm}
            onCancel={onDeleteCancel}
          />
        )
      }
      {
        showApiIntegrationForm &&
        (
          <FormModal
            isSubmitting={isSubmitting} onSubmit={onSubmit}
            onHide={onHide} show={showApiIntegrationForm} size="xl"
            title="Api Integration configuration">
            <ApiIntegratonForm
              bindSubmission={bindSubmission}
              onSubmitSuccess={() => {
                onHide();
                onEditSuccess(company);
              }}
              apiConfigDetails={apiConfigDetails}
              companyUuid={company.company.uuid}
              interationConfigFileName="interationConfigFile"
              setApiConfigDetails={setApiConfigDetails}
            />
          </FormModal>
        )
      }
      {
        showGlobalTemplateAccessFrom && (
          <FormModal
          isSubmitting={isSubmitting} onSubmit={onSubmit}
          onHide={onHide} show={showGlobalTemplateAccessFrom} size=""
          title="Global Template Access">
          <CompanyGlobalTemplateAccess
            bindSubmission={bindSubmission}
            onSubmitSuccess={() => {
              onHide();
              onEditSuccess(company)
            }}
            company={company}
            companyUuid={company.company.uuid}
          />
        </FormModal>
        )
      }
    </div>
  );
}

export default CompanyPopups;
