/* eslint-disable no-prototype-builtins */
/* eslint-disable max-depth */
import { isNil, isNumber } from 'lodash';
import BaseCommand from '../../../core/Spreadjs/Commands/BaseCommand';
import { TARGET_SHEET } from '../DataSpreadSheet';

class ReverseRowSignCommand extends BaseCommand {
  hasUndo = true;

  suspend(spreadsheet) {
    spreadsheet.spreadsheet.suspend();
  }

  resume(spreadsheet) {
    spreadsheet.spreadsheet.resume();
  }

  run(osSpreadsheet, options) {
    const {
      selection: { row, col, rowCount, colCount },
      columnCount: { sourceSheetColCount, targetSheetColCount },
      undoState: {
        previousSerialNumberValues, previousHeadValues, previousCategoryValues, previousSubCategoryValues,
      },
      columnIndex: {
        serialNumberIndex, headIndex, categoriesColIndex, subCategoriesColIndex,
      },
      activeSheetIndex,
      isUndo,
    } = options;

    const { spreadsheet, datasheet } = osSpreadsheet;
    if (col === -1) {
      const activeSheet = spreadsheet.getSheet(activeSheetIndex);
      for (let i = 0; i < rowCount; i++) {
        const rowId = row + i;
        for (let j = 0; j < colCount; j++) {
          const colIndex = col + j;
          const targetValue = spreadsheet.getCellValue(TARGET_SHEET, rowId, colIndex);
          if (colIndex !== serialNumberIndex) { // ignoring serial number  
            if (!isNil(targetValue) && isNumber(targetValue)) {
              datasheet.setValue(activeSheetIndex, rowId, colIndex, targetValue * -1);
            }
          }
        }
        const style = isNil(activeSheet.getRange(rowId, col, 1, colCount)['foreColor']()) ? 'red' : undefined;
        const serialNumberValue = spreadsheet.getCellValue(activeSheetIndex, rowId, serialNumberIndex);
        spreadsheet.setCellRangeAttr(activeSheetIndex, rowId, col, 1, colCount, 'foreColor', style);
        osSpreadsheet.onTargetHeadValueChange(
          rowId, -1, isUndo ? previousHeadValues[i] : '', sourceSheetColCount, targetSheetColCount,
        );
        spreadsheet.setCellValue(activeSheetIndex, rowId, headIndex, isUndo ? previousHeadValues[i] : '');
        spreadsheet.setCellValue(activeSheetIndex, rowId, categoriesColIndex, isUndo ? previousCategoryValues[i] : '');
        spreadsheet.setCellValue(
          activeSheetIndex, rowId, subCategoriesColIndex, isUndo ? previousSubCategoryValues[i] : '',
        );
        if (isUndo) {
          osSpreadsheet.rowReverseMapping[previousSerialNumberValues[i]] = !osSpreadsheet.rowReverseMapping[previousSerialNumberValues[i]];
        } else if (osSpreadsheet.rowReverseMapping?.hasOwnProperty(serialNumberValue)) {
          osSpreadsheet.rowReverseMapping[serialNumberValue] = !osSpreadsheet.rowReverseMapping[serialNumberValue];
        } else {
          osSpreadsheet.rowReverseMapping = { ...osSpreadsheet.rowReverseMapping, [serialNumberValue]: true };
        }
      }
    }
  }

  undo(spreadsheet, options) {
    options.isUndo = true;
    this.run(spreadsheet, options);
  }
}

export const reverseRowSignCommand = ReverseRowSignCommand.get();
