import React from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, ListGroup, Button, DropdownButton, Dropdown, Bedge, Badge
} from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faFileCode } from '@fortawesome/free-solid-svg-icons/faFileCode';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import Link from '../../core/Link/Link';
import AssetTypeBadge from '../AssetTypeBadge/AssetTypeBadge';
import TemplateTagBadge from '../TemplateTagBadge/TenplateTagBadge';
import IconButton from '../../core/Button/IconButton';
import { allowUserToUpdateTemplate } from '../../../store/selectors';

function CompanyTemplateListItem({
  template,
  companyUUID,
  onDeleteTemplate,
  onDownloadTemplate,
  onUpdateTemplate,
  onDownloadDictionary,
  onDownloadCategorySequence,
  onDownloadSummaryTotalConfig,
  isUserWithTemplateAccess
}) {
  const assetTypes = template.assetTypes &&
    template.assetTypes.map(assetType => (<AssetTypeBadge key={assetType} AssetType={assetType} />));

  const templateTag = (<TemplateTagBadge key={template.tag} TagType={template.tag} />);
  const globalTag = template.isGlobal ? (<TemplateTagBadge key="GLOBAL" TagType="GLOBAL" />) : '';

  return (
    <ListGroup.Item>
      <Row>
        <Col xs lg="4">
          {template.templateName}
          {assetTypes}
          <Row>
            <Col>
              <div className="d-flex mt-2">
                {template?.isReportEnabled && (
                  <span className='mr-2'>
                    <Badge variant='success'>Report Enabled</Badge>
                  </span>
                )}

              {template?.hasSubCategory && (
                  <span>
                    <Badge variant='info'>Has Sub Category</Badge>
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs lg="2" className='p-0'>
          {
            !template.isGlobal && (
              <Button
                variant="outline-primary"
                className="btn btn-default btn-sm py-1 px-2 ml-0"
                onClick={() => onUpdateTemplate(template)}
              >
              Edit Template
              </Button>
            )}
        </Col>
        <Col xs lg="3">
        <DropdownButton
          title={`Download Files`}
          variant="outline-" 
          bsPrefix="btn btn-sm btn-outline-secondary py-1 px-2"
          size="sm"
        >
            <Dropdown.Item onClick={() => onDownloadTemplate(template)}>
              Download Template
            </Dropdown.Item>
            {
            template.isDictionaryAvailable && (
              <Dropdown.Item  onClick={() => onDownloadDictionary(template)}>
                Download Dictionary
              </Dropdown.Item>
            )}
          {
            template.isCategorySequenceAvailable && (
              <Dropdown.Item
                onClick={() => onDownloadCategorySequence(template)}
              >
                Download Category Sequence
             </Dropdown.Item>
            )}
            {
              template.isAllowedCustomSummarySheet && (
                <Dropdown.Item
                  onClick={() => onDownloadSummaryTotalConfig(template)}
                >
                  Download Summary Total Config
                </Dropdown.Item>
              )}
        </DropdownButton>   
         <Badge variant="secondary" className='mr-1'>Template</Badge>
         {template.isDictionaryAvailable && <Badge className='mr-1' variant="success">Dictionary</Badge>}
         {template.isCategorySequenceAvailable && <Badge className='mr-1' variant="info">Category Sequence</Badge>}
         {template.isAllowedCustomSummarySheet && <Badge className='mr-1' variant="danger">Summary Total Config</Badge>}
        </Col>
        
        <Col xs lg="2">
          {!template.isGlobal && (
            <>
              <Link
                route={`${isUserWithTemplateAccess ? 'templateAccessModelManagement' : 'superAdminModelManagement'}`}
                params={{ companyUUID, templateUUID: template.uuid }}
                className="btn btn-sm btn-outline-primary"
                style={{ marginLeft: '.5em' }}>
                Manage Model
              </Link>
              <Row>
                <Col>
                  <span>{globalTag}</span>
                  <span>{templateTag}</span>
                </Col>
              </Row>
            </>
           )}
        </Col>
        <Col xs lg="1">
          {
            !template.isGlobal && (
              <Button
                variant="outline-danger"
                bsPrefix="btn btn-sm btn-outline-danger py-1 px-2"
                onClick={() => onDeleteTemplate(template)}
              >
              Delete
              </Button>
            )}
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

const mapStateToProps = state => ({
  isUserWithTemplateAccess: allowUserToUpdateTemplate(state)
});

export default connect(mapStateToProps)(CompanyTemplateListItem);
