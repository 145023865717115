import React from 'react';
import { Badge } from 'react-bootstrap';
import { TEMPLATE_TAGS } from '../../../constants';

function TemplateTagBadge({ TagType }) {
  const displayType = TEMPLATE_TAGS[TagType];
  return (
    <span>{displayType && <Badge 
      // eslint-disable-next-line no-nested-ternary
      variant={TagType === TEMPLATE_TAGS.SERVICING.key ? 'warning' : TagType === TEMPLATE_TAGS.ACQUISITION.key ? 'info': 'success'} 
      className="ml-2">{displayType.badgeText}
      </Badge>}
    </span>
  );
}

export default TemplateTagBadge;
