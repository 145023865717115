import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import AccountsExecutiveFormsComponent from './AccountsExecutiveFormsComponent';
import messages from '../../../../locales';
import { PASSWORD_SCHEMA } from '../../../constants';

const AccountsExecutiveFormSchema = Yup.object().shape({
  accountsExecutive: Yup.object().shape({
    firstName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid first name.'),
    lastName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid last name.'),
    email: Yup.string().email('Please provide a valid email.').required('Please provide a valid email'),
    password: PASSWORD_SCHEMA
  })
});


function AccountsExecutiveForm({
  onSubmitSuccess, bindSubmission, accountsExecutive, accountsExecutiveService, toastrService
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      await accountsExecutiveService.createAccountsExecutive(values);
      onSubmitSuccess();
      const message = messages.toastMessage.CREATE_ACCOUNTS_EXECUTIVE;
      toastrService.success(message);
    } catch (error) {
      const errorMessage = error.message === 'email must be unique' ? 'This email is already registered in the system with a same or different role' : error.message;
      actions.setStatus({ error: errorMessage });
    }
    actions.setSubmitting(false);
  }
  return (
    <Formik
      initialValues={accountsExecutive}
      validationSchema={AccountsExecutiveFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <AccountsExecutiveFormsComponent {...formikProps} bindSubmission={bindSubmission} />
      }
    />
  );
}

export default withServices('accountsExecutiveService', 'toastrService')(AccountsExecutiveForm);
