import { createDuck } from 'reaf';

const initialState = {
    activeSubscriptions: [],
    inActiveSubscriptions: []
};

const [
  { setActiveSubscriptions, setInActiveSubscriptions },
  reducer
] = createDuck('autouw/subscriptionsList', {
  _initialState: initialState,

  setActiveSubscriptions: subscriptionsList => state => {
    return ({
    ...state,
    activeSubscriptions: [...subscriptionsList]
  })},

  setInActiveSubscriptions: subscriptionsList => state => {
    return ({
      ...state,
      inActiveSubscriptions: [...subscriptionsList]
  })},

});

export {setActiveSubscriptions, setInActiveSubscriptions};

export default reducer;