import { setCompaniesDetails, updateCompany } from '../store/companyList';

class CompanyListService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  async loadCompanyList() {
    const result = await this.apiClient.get(
      '/companies/listCompanies',
    );
    const companiesDetails = result.response.rows;
    this.store.dispatch(setCompaniesDetails(companiesDetails));
    return companiesDetails;
  }

  updateCompany(company) {
    this.store.dispatch(updateCompany(company));
    return company;
  }
}

export default CompanyListService;
