import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { withServices } from 'reaf';
import { getAppConfigurationsSelector } from '../../../store/selectors';
import SwitchButton from '../../core/SwitchButton/SwitchButton';

function AppConfigurationList({ appConfigurations, appConfigurationService }) {
  const updateConfiguration = useCallback((updateConfiguration, value) => {
    appConfigurationService.updateConfiguration(updateConfiguration, value);
  }, [appConfigurationService]);
  return (
    <div>
      <div className="header mt-2 mb-4 pb-1">
        <h3>App Configuration List</h3>
      </div>
      <Table striped bordered hover>
        <tbody>

          {appConfigurations.map((appConfiguration, index) => (
            <tr key={index}>
              <td>{appConfiguration.configCode}</td>
              <td>{appConfiguration.label}</td>
              <td>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <SwitchButton onChange={(value) => {updateConfiguration(appConfiguration, value === true ? 'true' : 'false')}} onLabel="Yes" offLabel="No" isActive={appConfiguration.value === 'true'} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => ({
  appConfigurations: getAppConfigurationsSelector(state)
})

export default connect(mapStateToProps)(withServices('appConfigurationService')(AppConfigurationList));
