import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { saveAs } from 'file-saver';
import * as NewWindow from 'react-new-window/umd/react-new-window';
import { isNil, get } from 'lodash';
import SplitPane from 'react-split-pane';
import SpreadSheet from '../../core/SpreadSheet/SpreadSheet';
import OSSpreadSheet from './OSSpreadSheet';
import {
  currentDocumentSelector,
  currentProjectSelector,
  currentProjectTemplateHeadCategoriesSelector,
  extractedDataSelector,
  workbookDataSelector,
  columnHeadersSelector,
  commercialRentRollConfigSelector,
  currentProjectCategorySequenceSelector,
  chargeCodeConfigSelector,
  mfRentRollConfigSelector,
  rowReverseMappingSelector,
  isTaggedPeriodEqualsMLReturnedPeriod,
  currentDocumentTypeSelector,
  currentProjectTemplateMappingsSelector,
  currentDocumentTaggingInfoSelector,
  isMonthlyDataUsedSelector,
  currentTemplateTagSelector,
  dynamicAssetTypesAsObjSelector,
  workbookDataCopySelector,
  currentProjectSummarySheetTotalConfigSelector,
  companyTemplatesSelector,
  loggedInCompanySelector,
  cashflowDropdownsSelector,
  cashflowPeriodIdentifierSelector
} from '../../../store/selectors';
import { setSummaryData, clearSummaryData, setWorkbookDataCopy } from '../../../store/currentDocument';
import DocExtractionWorkbookManager from '../../../services/DocExtractionWorkbookManager';
import DocExtractionSlidePane from '../DocExtractionSlidePane/DocExtractionSlidePane';
import DocExtractionShortCutKeys from '../DocExtractionShortcutKeys/DocExtractionShortcutKeys';
import DocExtractionRentRollConfig from '../DocExtractionRentRollConfig/DocExtractionRentRollConfig';
import DocExtractionSummary from '../DocExtractionSummary/DocExtractionSummary';
import Toast from '../../core/Toast/Toast';
import {
  DocumentFileTypes,
  affixComponentName,
  sheetNames,
  TEMPLATE_TAGS,
  PROPERTY_TYPES,
  dummyFallbackData
} from '../../../constants';
import ChargeCodeConfigContextMenu from '../ChargeCodeConfigContextMenu/ChargeCodeConfigContextMenu';
import DocExtractionAffixWrapper from '../DocExtractionAffixWrapper/DocExtractionAffixWrapper';
import { isCustomMonthsExist, isOSBaseType } from '../../../utils/utils';
import { appConfig } from '../../../config';
import RRSpreadSheet from './RRSpreadSheet';
import './DocExtractionComponent.scss';
import style from './DocExtractionComponent.module.scss';
import {
  ExtractionPageTourGuideOpened,
  ReferenceWindowOpened,
  WorkbookSaved
} from '../../../constants/eventTrackerMessage';
import { useFeatureFlag } from '../../../providers/FeatureFlagProvider';
import ExtractionPageHeader from '../../pages/ExtractionPage/ExtractionPageHeader';
import messages from '../../../../locales/en-US';
import DocExtractionCashflowSummary from '../DocExtractionCashflowSummary/DocExtractionCashflowSummary';

function DocExtractionComponent({
  projectTemplateHeadCategories,
  projectHeadCategorySequence,
  projectSummarySheetTotalConfig,
  projectTemplateMappings,
  store,
  router,
  toastrService,
  currentCompany,
  project,
  document,
  chargeCodeConfig,
  mfRentRollConfig,
  commercialRentRollConfig,
  isEqualTaggedPeriodToMLPeriod,
  currentDocumentType,
  dynamicAssetType,
  taggingInfo,
  template,
  templateTag,
  extractedData,
  workbookData,
  columnHeadersList,
  documentsService,
  showAffix = true,
  showToolbar = true,
  onlySource = false,
  sheetHeight = {},
  onToggleSubHeader,
  eventTrackerService,
  workbookDataCopy,
  showSubHeader,
  setShowTour,
  readOnlyAccess,
  cashflowDropdowns,
  cashflowPeriodIdentifier,
  fallbackExtractionService,
}) {
  const [manager, setManager] = useState(null);
  const [showToast, setShowToast] = useState(null);
  const [toastConfig, setToastConfig] = useState({
    message: '',
    type: '',
    autohide: true,
    wrapperClass: '',
    headerMessage: ''
  });
  const spreadRef = useRef();
  const [showSlidePane, setShowSlidePane] = useState(false);
  const [activeComponent, setActiveComponent] = useState('');
  const [showPopupWindow, setShowPopupWindow] = useState(false);
  const [isBetaParserLoading, setIsBetaParserLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('');
  const [size, setSize] = useState('calc(100% - 48px)');
  const [isLoading, setIsLoading] = useState(false);
  const [canUseSpreadV2] = useFeatureFlag('spreadV2', false);
  const [isTenantFiltered, setIsTenantFiltered] = useState(false);
  const [isFutureTenantEnabled, setIsFutureTenantEnabled] = useState(false);
  const [isDocumentValidated, setIsDocumentValidated] = useState(document?.isValidated || false);
  const [isActive, setIsActive] = useState(false);
  const [isDocumentValidating, setIsDocumentValidating] = useState(false);

  const isAllowedCustomSummarySheet = useMemo(
    () => !isNil(projectSummarySheetTotalConfig) && DocumentFileTypes[currentDocumentType].baseType ===
    DocumentFileTypes.OPERATING_STATEMENT.baseType,
    [projectSummarySheetTotalConfig, currentDocumentType]
  );

  const isAcquisition = useMemo(
    () => templateTag === TEMPLATE_TAGS.ACQUISITION.key,
    [templateTag]
  );

  const docExtractionStatus = useMemo(() => 
    document?.extractionJob?.status
  , [document])

  const bindShowToast = useCallback(
    (setShowToastState) => setShowToast(() => setShowToastState),
    []
  );
  const assetType = useMemo(() => project.assetType, [project]);

  const baseClass = useMemo(() => project.baseClass, [project]);

  const currentWorkbookManager = useMemo(
    () => manager || spreadRef.current,
    [manager, spreadRef.current]
  );

  const setWorkbookToast = useCallback(
    ({ message, type, autohide, wrapperClass, headerMessage }) => {
      setToastConfig({ message, type, autohide, wrapperClass, headerMessage });
      showToast && showToast();
    },
    [showToast]
  );

  const setSummaryDataToStore = useCallback((summaryData) => {
    store.dispatch(setSummaryData(summaryData));
  }, []);

  useEffect(() => {
    manager && manager.updateOptions({ setSummaryDataToStore });
  }, [manager, setSummaryDataToStore]);

  useEffect(() => {
    manager && manager.updateOptions({ isEqualTaggedPeriodToMLPeriod });
  }, [manager, isEqualTaggedPeriodToMLPeriod]);

  // not used in new implementation
  const createManager = useCallback((wbManager) => {
    const m = new DocExtractionWorkbookManager(wbManager, isAllowedCustomSummarySheet);
    m.updateOptions({
      projectTemplateHeadCategories,
      projectHeadCategorySequence,
      projectTemplateMappings,
      docExtractionStatus,
      projectSummarySheetTotalConfig,
      template,
      readOnlyAccess,
      cashflowDropdowns
    });
    setManager(m);
  }, []);

  useEffect(() => {
    manager && manager.updateOptions({ chargeCodeConfig, store });
  }, [manager, chargeCodeConfig]);

  useEffect(() => {
    manager && manager.updateOptions({ baseClass});
  }, [manager, baseClass]);

  useEffect(() => {
    manager && manager.updateOptions({ setWorkbookToast });
  }, [manager, setWorkbookToast]);

  useEffect(() => {
    if (manager) {
      if (workbookData) {
        manager.setWorkbookData(workbookData, onlySource);
      } else if (extractedData) {
        manager.setData(extractedData, onlySource);
      }
      if(currentWorkbookManager.isWorkbookHasRentRollTypeDoc() && currentWorkbookManager.validateDocumentAssetType(PROPERTY_TYPES.MULTIFAMILY.baseClass)) {
        setIsFutureTenantEnabled(true);
      }
    }
  }, [extractedData, manager, workbookData, columnHeadersList, onlySource]);

  useEffect(() => {
    if (DocumentFileTypes[currentDocumentType].baseType === DocumentFileTypes.RENT_ROLL.baseType) {
      if (currentWorkbookManager && currentWorkbookManager.getSheetData()) {
        const workbookdata = currentWorkbookManager.getSheetData();
        const dataTable = get(workbookdata, 'targetSheetData.data.dataTable');
        if (dataTable) {
          currentWorkbookManager && currentWorkbookManager.reCalculateTentativeStatus(mfRentRollConfig);
        }
      }
    }
  }, [
    mfRentRollConfig.unitStatus.config
  ]);

  useEffect(() => {
    async function updateAndSaveData() {
      if(currentWorkbookManager && isActive) {
        if(!workbookDataCopy) {
          const workbookData = currentWorkbookManager.workbookManager.getWorkbookData();
          await store.dispatch(setWorkbookDataCopy(workbookData));
        }
        await currentWorkbookManager.filterFutureTenants();
      } else if(currentWorkbookManager && !isActive && workbookDataCopy) {
        await currentWorkbookManager.setWorkbookData(workbookDataCopy);
      }
    }
    updateAndSaveData();
  }, [isActive, taggingInfo.asOnDate]);

  useEffect(() => {
    if(currentWorkbookManager && 
      currentWorkbookManager.isWorkbookHasRentRollTypeDoc() && 
      currentWorkbookManager.validateDocumentAssetType(PROPERTY_TYPES.MULTIFAMILY.baseClass)
    ) {
      currentWorkbookManager.getUniqueFloorPlan('onDataBind');
      currentWorkbookManager.setRRSummarySheetData();
    }
  }, [taggingInfo.asOnDate]);

  useEffect(() => {
    if (
      manager &&
      projectTemplateHeadCategories &&
      Reflect.ownKeys(projectTemplateHeadCategories).length > 0
    ) {
      manager.updateOptions({
        projectTemplateHeadCategories
      });
    }
  }, [manager, projectTemplateHeadCategories]);

  useEffect(() => {
    if (
      manager &&
      projectHeadCategorySequence &&
      Reflect.ownKeys(projectHeadCategorySequence).length > 0
    ) {
      manager.updateOptions({
        projectHeadCategorySequence
      });
    }
  }, [manager, projectHeadCategorySequence]);

  useEffect(() => {
    if (
      manager && readOnlyAccess
    ) {
      manager.updateOptions({
        readOnlyAccess
      });
    }
  },  [manager, readOnlyAccess])
  useEffect(() => {
    if (manager && projectTemplateMappings && !isNil(projectTemplateMappings)) {
      manager.updateOptions({
        projectTemplateMappings
      });
    }
  }, [manager, projectTemplateMappings]);

  useEffect(() => {
    if (
      manager &&
      manager.workbookManager &&
      manager.workbookManager.workbook
    ) {
      manager.workbookManager.workbook.refresh();
    }
  }, [manager, showToolbar]);

  useEffect(
    () => () => {
      if (currentWorkbookManager && saveData && !readOnlyAccess) {
        const data = canUseSpreadV2
          ? currentWorkbookManager.spreadsheet.getWorkbookData()
          : currentWorkbookManager.workbookManager.getWorkbookData();
        // eslint-disable-next-line no-alert
        if (
          window.confirm(
            "You are about to leave this page, click 'OK' to save any changes that you may have made or 'Cancel' to discard any unsaved changes."
          )
        ) {
          saveData(data, true, { showBackdrop: true, isAutoSave: false }).catch(
            console.log
          );
        }
        store.dispatch(clearSummaryData());
      }
    },
    [currentWorkbookManager, canUseSpreadV2]
  );

  useEffect(() => {
    if (
      currentWorkbookManager &&
      DocumentFileTypes[currentDocumentType].baseType ===
        DocumentFileTypes.RENT_ROLL.baseType
    ) {
      // no await to make it async,
      if(readOnlyAccess) return
      onRentRollConfigSubmit(null, true);
    }
  }, [currentWorkbookManager]);


  useEffect(() => {
    if (currentWorkbookManager && !onlySource && (
      DocumentFileTypes[currentDocumentType].baseType ===
      DocumentFileTypes.OS_FULL.baseType && !!cashflowPeriodIdentifier
    )) {
      currentWorkbookManager.getCFSummary();
    }
  }, [cashflowPeriodIdentifier, currentWorkbookManager, onlySource])

  const onValidateAndSave = async () => {
    if (currentWorkbookManager) {
      setIsDocumentValidating(true)
      const data = canUseSpreadV2
        ? currentWorkbookManager.spreadsheet.getWorkbookData()
        : currentWorkbookManager.workbookManager.getWorkbookData();

      if (!isDocumentValidated) {
        await saveData(data, true, { showBackdrop: false, isAutoSave: false, isShowToastr: false }).catch(
          console.log
        );
      }

      await documentsService.updateDocumentValidationStatus(
        project,
        document,
        { isValidated: !isDocumentValidated }
      )
      setTimeout(() => {
        toastrService['success'](
          isDocumentValidated ?
            messages.toastMessage.DOC_VALIDATION_ERROR :
            messages.toastMessage.DOC_VALIDATION_SUCCESS
        );
        setIsDocumentValidated(!isDocumentValidated);
        setIsDocumentValidating(false)
      }, 0)
    } else {
      toastrService['error']('Something went wrong!')
    }
  };

  async function saveData(
    data,
    ignoreDelay = false,
    options = { showBackdrop: true, isAutoSave: false, isShowToastr: true },
  ) {
    if(!readOnlyAccess) {
      try {
        const {
          taggingData: { documentType }
        } = document;
        isOSBaseType(documentType) &&
          currentWorkbookManager &&
          currentWorkbookManager.setRowReverseMappingsToStore();
        const ccConfig = chargeCodeConfigSelector(store.getState()) || {};
        const mfRRConfig = mfRentRollConfigSelector(store.getState()) || {};
        const commercialRRConfig =
          commercialRentRollConfigSelector(store.getState()) || {};
        const rowReverseConfig =
          rowReverseMappingSelector(store.getState()) || [];
        const useMonthlyData = isMonthlyDataUsedSelector(store.getState()) || isCustomMonthsExist(data, isOSBaseType(documentType));
        const isInvalidSheet = spreadRef?.current
          ? currentWorkbookManager?.datasheet.validateTargetSheet()
          : currentWorkbookManager.validateTargetSheet();
        const timer = ignoreDelay ? 0 : isInvalidSheet ? 2000 : 0;
        setTimeout(async () => {
          await documentsService.saveWorkbookData({
            project,
            document,
            data,
            ccConfig,
            mfRentRollConfig: mfRRConfig,
            commercialRentRollConfig: commercialRRConfig,
            rowReverseConfig,
            useMonthlyData,
            hideBackdropLoader: !options.showBackdrop,
            isAutoSave: options.isAutoSave,
            isTenantFiltered,
            isShowToastr: options.isShowToastr
          });
          await store.dispatch(setWorkbookDataCopy(null));
          setIsActive(false);
        }, timer);
      } catch (e) {
        setWorkbookToast({ message: e.message, type: 'danger' });
        showToast();
      }
    }
  }

  function downloadXLS(blob) {
    const fileName = [
      ...document.fileName.split('.').slice(0, -1),
      'xlsx'
    ].join('.');
    saveAs(blob, fileName);

    eventTrackerService.track(WorkbookSaved, { fileName, document });
  }

  function getSheetCount() {
    return isAllowedCustomSummarySheet ? 5 : 4;
  }

  const onRefereceWindowOpen = useCallback(() => {
    setShowPopupWindow(!showPopupWindow);
    eventTrackerService.track(ReferenceWindowOpened, {
      showPopupWindow: !showPopupWindow,
      document
    });
  }, [showPopupWindow]);

  function onSlidePaneOpen({ selectedTab, currentComponent, affixWidth = 600 }) {
    setActiveTabKey(selectedTab);
    setTimeout(() => setShowSlidePane(true), 0);
    setActiveComponent(currentComponent);
    setSize(window.innerWidth - affixWidth);
  }

  function onSlidePaneClose() {
    setActiveTabKey('');
    setShowSlidePane(false);
    setSize('calc(100% - 48px)');
    setIsLoading(false);
  }

  async function onRentRollConfigSubmit(type, initialRender = false) {
    if (currentWorkbookManager && showAffix && !readOnlyAccess) {
      const {
        taggingData: { documentType }
      } = document;
      if (!initialRender || isNil(workbookData)) {
        //delaying below method execution to pick latest charge code from state
        setTimeout(() => {
          currentWorkbookManager.setChargeCodeAfterColumnMerge(true, type);
          currentWorkbookManager.setRetailChargeCode();
        }, 0)
       
      }
      isOSBaseType(documentType) &&
        currentWorkbookManager &&
        currentWorkbookManager.setRowReverseMappingsToStore();
      return new Promise((resolve) => {
        setTimeout(async () => {
          const ccConfig = chargeCodeConfigSelector(store.getState()) || {};
          const mfRRConfig = mfRentRollConfigSelector(store.getState()) || {};
          const commercialRRConfig =
            commercialRentRollConfigSelector(store.getState()) || {};
          const rowReverseConfig =
            rowReverseMappingSelector(store.getState()) || [];
          const data = canUseSpreadV2
            ? currentWorkbookManager.spreadsheet.getWorkbookData()
            : currentWorkbookManager.workbookManager.getWorkbookData();
          if (Reflect.ownKeys(data.sheets).length > 1) {
            await documentsService.saveWorkbookData({
              project,
              document,
              data,
              ccConfig,
              mfRentRollConfig: mfRRConfig,
              commercialRentRollConfig: commercialRRConfig,
              rowReverseConfig,
              hideBackdropLoader: true,
              isAutoSave: initialRender,
              isTenantFiltered
            });
          }
          if(currentWorkbookManager.isWorkbookHasRentRollTypeDoc() && currentWorkbookManager.validateDocumentAssetType(PROPERTY_TYPES.MULTIFAMILY.baseClass)) {
            currentWorkbookManager.getUniqueFloorPlan('onDataBind');
            currentWorkbookManager.setRRSummarySheetData();
            currentWorkbookManager.calculateUnitMixSummary();
          }
          resolve();
        }, 0);
      });
    }
  }

  function onMergeChargeCodeColumn(columnRef) {
    currentWorkbookManager.mergeChargeCodeColumns(columnRef);
  }

  function onTenantTypeCalcChange(value) {
    currentWorkbookManager.calculateTenantType(value);
  }

  function onToggleMonths() {
    manager.showHideMonthColumns();
  }

  async function onBetaParserClick(document, project) {
    setIsBetaParserLoading(true)
    try {
      const response = await fallbackExtractionService.convertDocToTable({
        documentUuid: document.uuid,
        projectUuid: project.uuid,
        document_title: 'RENT_ROLL',
        assetType: project.assetType,
        period_from: null,
        period_to: null,
        from: document.taggingData.splitFrom,
        to: document.taggingData.splitTo,
        version: 'christopher'
      });
      if (response.data !== null) {
        manager.setAiParserData(response.data || []);
      } else {
        toastrService['error'](
          messages.toastMessage.BETA_PARSER_ERROR
        );
        setIsBetaParserLoading(false);
      }
    } catch (err) {
      toastrService['error'](
        messages.toastMessage.BETA_PARSER_ERROR
      );
      setIsBetaParserLoading(false);
    }
    setIsBetaParserLoading(false);
  }

  const SlidePaneComponent = useCallback(() => {
    if (activeComponent === affixComponentName.RENT_ROLL) {
      return (
        <DocExtractionSlidePane
          heading="Rent Roll Configuration"
          contextMenu={<ChargeCodeConfigContextMenu />}
          component={
            <DocExtractionRentRollConfig
              onSaveData={saveData}
              assetType={assetType}
              onRentRollConfigSubmit={(type) => onRentRollConfigSubmit(type)}
              onSlidePaneClose={onSlidePaneClose}
              onMergeChargeCodeColumn={(columnRef) =>
                onMergeChargeCodeColumn(columnRef)
              }
              onTenantTypeCalcChange={(value) => onTenantTypeCalcChange(value)}
              setActiveTabKey={setActiveTabKey}
              activeTabKey={activeTabKey}
              isAcquisition={isAcquisition}
              readOnlyAccess={readOnlyAccess}
            />
          }
          onSlidePaneClose={onSlidePaneClose}
        />
      );
    } else if (activeComponent === affixComponentName.SHORTCUT_KEYS) {
      return (
        <DocExtractionSlidePane
          heading="Shortcut Keys"
          component={<DocExtractionShortCutKeys />}
          onSlidePaneClose={onSlidePaneClose}
        />
      );
    } else if (
      DocumentFileTypes[activeComponent].baseType ===
      affixComponentName.OPERATING_STATEMENT
    ) {
      return (
        <DocExtractionSlidePane
          heading="Document Summary"
          component={<DocExtractionSummary />}
          onSlidePaneClose={onSlidePaneClose}
        />
      );
    } else if (
      DocumentFileTypes[activeComponent].baseType ===
      affixComponentName.OS_FULL
    ) {
      return (
        <DocExtractionSlidePane
          heading="Cashflow Summary"
          component={
            <DocExtractionCashflowSummary/>
          }
          onSlidePaneClose={onSlidePaneClose}
        />
      );
    }
  }, [activeTabKey, activeComponent]);

  const affixDocType = useMemo(() => {
    if (
      DocumentFileTypes[currentDocumentType].baseType ===
      affixComponentName.OPERATING_STATEMENT
    ) {
      return affixComponentName.OPERATING_STATEMENT;
    } else if ( 
      DocumentFileTypes[currentDocumentType].baseType ===
      affixComponentName.OS_FULL
    ) {
      return affixComponentName.OS_FULL;
    } else {
      return affixComponentName.RENT_ROLL;
    }
  }, []);

  return (
    <>
    {showSubHeader && (
      <ExtractionPageHeader
        document={document}
        project={project}
        isBetaParserEnabled={currentCompany?.isBetaParserEnabled || false}
        isBetaParserLoading={isBetaParserLoading}
        onValidateAndSave={onValidateAndSave}
        isDocumentValidated={isDocumentValidated}
        isDocumentValidating={isDocumentValidating}
        readOnlyAccess={readOnlyAccess}
        onHelpClick={() => {
          setShowTour(true);
          eventTrackerService.track(ExtractionPageTourGuideOpened);
        }}
        onBetaParserClick={() => onBetaParserClick(document, project)}
      />
    )}
    <div className="horizontal-section">
      <Toast
        bindShowToast={bindShowToast}
        autohide={toastConfig.autohide}
        message={toastConfig.message}
        variant={toastConfig.type}
        wrapperClass={toastConfig.wrapperClass}
        headerMessage={toastConfig.headerMessage}
        textVariant="light"
      />
      <div className="horizontal-section">
        <SplitPane
          split="vertical"
          size={size}
          maxSize={size}
          className={style.extractionSplitPane}
          resizerClassName={showSlidePane ? style.extractionAffixResizer : ''}
          onChange={() => setIsLoading(true)}
          onDragFinished={() => {
            window.dispatchEvent(new Event('resize'));
            setIsLoading(false);
          }}
        >
          <>
            {canUseSpreadV2 ? (
              isOSBaseType(document.taggingData.documentType) ? (
                <OSSpreadSheet
                  workbookData={workbookData}
                  data={extractedData}
                  showToolbar={showToolbar}
                  document={document}
                  taggingInfo={taggingInfo}
                  className="mt-n3 pt-3"
                  assetType={assetType}
                  isEqualTaggedPeriodToMLPeriod={isEqualTaggedPeriodToMLPeriod}
                  onSaveData={saveData}
                  onDownloadXLS={downloadXLS}
                  onRefereceWindowOpen={onRefereceWindowOpen}
                  onSlidePaneOpen={(selectedTab, currentComponent) =>
                    onSlidePaneOpen({ selectedTab, currentComponent })
                  }
                  setWorkbookToast={setWorkbookToast}
                  style={{ ...sheetHeight }}
                  sheetCount={getSheetCount()}
                  onlySource={onlySource}
                  onInitManager={(manager) => {
                    spreadRef.current = manager;
                  }}
                  project={project}
                />
              ) : (
                <RRSpreadSheet
                  workbookData={workbookData}
                  data={extractedData}
                  document={document}
                  project={project}
                  showToolbar={showToolbar}
                  taggingInfo={taggingInfo}
                  className="mt-n3 pt-3"
                  assetType={assetType}
                  style={{ ...sheetHeight }}
                  onSaveData={saveData}
                  onDownloadXLS={downloadXLS}
                  onRefereceWindowOpen={onRefereceWindowOpen}
                  onSlidePaneOpen={(selectedTab, currentComponent) =>
                    onSlidePaneOpen({ selectedTab, currentComponent })
                  }
                  setWorkbookToast={setWorkbookToast}
                  sheetCount={getSheetCount()}
                  onlySource={onlySource}
                  onInitManager={(manager) => {
                    spreadRef.current = manager;
                  }}
                />
              )
            ) : (
              <SpreadSheet
                showToolbar={showToolbar}
                onInitManager={createManager}
                sheetCount={getSheetCount()}
                assetType={assetType}
                docType={currentDocumentType}
                taggingInfo={taggingInfo}
                className="mt-n3 pt-3"
                onSaveData={saveData}
                project={project}
                document={document}
                onDownloadXLS={downloadXLS}
                sheetHeight={sheetHeight}
                onToggleSubHeader={onToggleSubHeader}
                onRefereceWindowOpen={onRefereceWindowOpen}
                onSlidePaneOpen={(selectedTab, currentComponent) =>
                  onSlidePaneOpen({ selectedTab, currentComponent })
                }
                onToggleMonths={onToggleMonths}
                setWorkbookToast={setWorkbookToast}
                isTenantFiltered={isTenantFiltered}
                setIsTenantFiltered={setIsTenantFiltered}
                isFutureTenantEnabled={isFutureTenantEnabled}
                isActive={isActive}
                setIsActive={setIsActive}
              />
            )}
            {isLoading && <div className={style.extractionOverlay} />}
          </>

          {showAffix && (
            <DocExtractionAffixWrapper
              component={<SlidePaneComponent />}
              isSlidePaneOpen={showSlidePane}
              openSlidePane={(props) => {
                setShowSlidePane(props);
              }}
              onConfigOpen={({ selectedTab, affixWidth }) => {
                onSlidePaneOpen({
                  selectedTab,
                  currentComponent: affixDocType,
                  affixWidth
                });
              }}
              onSlidePaneClose={(props) => {
                onSlidePaneClose(props);
              }}
              isAcquisition={isAcquisition}
              activeTabKey={activeTabKey}
            />
          )}
        </SplitPane>
      </div>
      {showPopupWindow && (
        <NewWindow
          url={router.getUrl('docExtractionPage', {
            documentUuid: document.uuid,
            projectUuid: project.uuid,
            popup: 'true'
          })}
          onUnload={() => setShowPopupWindow(false)}
          features={{ location: false }}
        />
      )}
    </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const project = currentProjectSelector(state);
  const template = companyTemplatesSelector(state).find(
    (template) => template.uuid === project.templateUuid
  );
  return {
    currentCompany: loggedInCompanySelector(state),
    project,
    document: currentDocumentSelector(state),
    extractedData: extractedDataSelector(state),
    workbookData: workbookDataSelector(state),
    columnHeadersList: columnHeadersSelector(state),
    projectTemplateHeadCategories:
      currentProjectTemplateHeadCategoriesSelector(state),
    projectHeadCategorySequence: currentProjectCategorySequenceSelector(state),
    projectSummarySheetTotalConfig: currentProjectSummarySheetTotalConfigSelector(state),
    projectTemplateMappings: currentProjectTemplateMappingsSelector(state),
    chargeCodeConfig: chargeCodeConfigSelector(state),
    mfRentRollConfig: mfRentRollConfigSelector(state),
    commercialRentRollConfig: commercialRentRollConfigSelector(state),
    isEqualTaggedPeriodToMLPeriod: isTaggedPeriodEqualsMLReturnedPeriod(state),
    currentDocumentType: currentDocumentTypeSelector(state),
    taggingInfo: currentDocumentTaggingInfoSelector(state),
    templateTag: currentTemplateTagSelector(state),
    template,
    dynamicAssetType: dynamicAssetTypesAsObjSelector(state),
    workbookDataCopy: workbookDataCopySelector(state),
    cashflowDropdowns: cashflowDropdownsSelector(state),
    cashflowPeriodIdentifier: cashflowPeriodIdentifierSelector(state),
  };
};



export default connect(mapStateToProps)(
  withServices(
    'documentsService',
    'store',
    'router',
    'toastrService',
    'eventTrackerService',
    'fallbackExtractionService'
  )(DocExtractionComponent)
);
