import React from 'react';
import { connect } from 'react-redux';
import { leaseStartRangeSelector, leaseEndRangeSelector } from '../../../store/selectors';
import DocExtractionAffixLeaseChart from '../DocExtractionAffixLeaseChart/DocExtractionAffixLeaseChart';
import './DocExtractionAffixAnalysis.scss';
import RentRollConfigHeader from '../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../DocExtractionSummary/DocExtractionWithoutSummary';
import messages from '../../../../locales/en-US';

function DocExtractionAffixAnalysis({ startLeaseRange, endLeaseRange }) {
  return (
    <>
      {
        !!Reflect.ownKeys(startLeaseRange).length && !!Reflect.ownKeys(endLeaseRange).length ? (
          <>
            <RentRollConfigHeader
              title="Lease Trend Analysis"
              hideChartSwitcher
            />
            <div className="analysisChartWrapper">
              <DocExtractionAffixLeaseChart title="Lease Start Trend" defaultChartColor={['#4cbbb9']} tanentInfo={startLeaseRange} />
              <DocExtractionAffixLeaseChart title="Lease Expiration Trend" defaultChartColor={['#c70039']} tanentInfo={endLeaseRange} />
            </div>
          </>
        ) : <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
      }

    </>
  );
}

const mapStateTopProps = state => ({
  startLeaseRange: leaseStartRangeSelector(state),
  endLeaseRange: leaseEndRangeSelector(state)
});


export default connect(mapStateTopProps)(DocExtractionAffixAnalysis);
