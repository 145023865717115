import React, { useMemo } from 'react';
import { Form, Field } from 'formik';
import { connect } from 'react-redux';
import ReactSelect from 'react-select';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import { getVideoTutorialsCategorySelector } from '../../../store/selectors';

function VideoTutorialsFormComponent({
  categories, status, isSubmitting, bindSubmission, submitForm, values, setFieldValue, isEdit = false
}) {
  bindSubmission(submitForm, isSubmitting);

  const defaultCategoryValue = categories && values.videoTutorial.category ? 
    { value: values.videoTutorial.category, label: categories[values.videoTutorial.category].label } : ''
  const [selectedCategory, setSelectedCategory] = React.useState(defaultCategoryValue);

  const categoryOptions = useMemo(() => Reflect.ownKeys(categories).map(
      key => ({ value: key, label: categories[key].label })
    ),
  [categories]);
  
  return (
    <div>

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <Form>
        <h5>Video Tutorial Details</h5>
        <hr />
        <div className="form-group">
          <label htmlFor="videoTutorial.title">Title</label>
          <Field id="videoTutorial.title" className="form-control" name="videoTutorial.title" placeholder="Title" type="text" />
          <FieldErrorMessage name="videoTutorial.title" />
        </div>
        <div className="form-group">
          <label htmlFor="videoTutorial.videoId">Video Id</label>
          <Field id="videoTutorial.videoId" className="form-control" name="videoTutorial.videoId" placeholder="Video Id" type="text" disabled={isEdit} />
          <FieldErrorMessage name="videoTutorial.videoId" />
        </div>

        <div className="form-group">
          <label htmlFor="videoTutorial.description">Description</label>
          <Field id="videoTutorial.description" className="form-control" name="videoTutorial.description" placeholder="Description" type="text" />
          <FieldErrorMessage name="videoTutorial.description" />
        </div>

        <div id="CategoryField" className="form-group">
          <label htmlFor="videoTutorial.category">Category</label>
          <ReactSelect
            placeholder="Select Category..." name="videoTutorial.category"
            options={categoryOptions}
            value={selectedCategory}
            onChange={
              (categoryValue) => {
                setFieldValue('videoTutorial.category', categoryValue.value);
                setSelectedCategory({ value: categoryValue.value, label: categories[categoryValue.value].label });
              }
            }
          />
          <FieldErrorMessage name="videoTutorial.category" />
        </div>

      </Form>
    </div>
  );
}


const mapStateToProps = state => ({
  categories: getVideoTutorialsCategorySelector(state),
});
export default connect(mapStateToProps)(VideoTutorialsFormComponent);
