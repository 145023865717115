
import { saveAs } from 'file-saver';
import { setCompanyProjectExportersList } from '../store/companyProjectExporters';
import { setCurrentCompany } from '../store/company';
import { getJsonFileData, isStringFunction } from '../utils/utils';

class ProjectExporterService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  getResourceUrl(companyUUID) {
    return `/admin/companies/${companyUUID}/projectExporters`;
  }

  async createProjectExporter(companyUUID, { projectExporter, mapperFile, mappingFunctionFile }) {
    const formData = new FormData();
    formData.append('name', projectExporter.name);
    formData.append('outputFormat', projectExporter.outputFormat);
    if (mapperFile !== null) {
      formData.append('mapper', mapperFile);
    }
    if (mappingFunctionFile !== null) {
      formData.append('mappingFunction', mappingFunctionFile);
    }
    const mappingFunctionFileContent = await mappingFunctionFile.text();
    const mappingFunctionJson = getJsonFileData(mappingFunctionFileContent, mappingFunctionFile.name);
    // eslint-disable-next-line no-prototype-builtins
    if (!mappingFunctionJson?.hasOwnProperty('functionKey')) {
      throw new Error('Mapping function must have function key');
    }
    isStringFunction(mappingFunctionJson.functionKey);
    await this.apiClient.post(
      this.getResourceUrl(companyUUID),
      formData,
    );
    return this.getCompanyProjectExporters(companyUUID);
  }

  async deleteProjectExporter(companyUUID, projectExporterUuid) {
    await this.apiClient.delete(`${this.getResourceUrl(companyUUID)}/${projectExporterUuid}`);
    return this.getCompanyProjectExporters(companyUUID);
  }

  async getProjectExporterFile(companyUUID, projectExporterUuid, fileType) {
    const response = await this.apiClient.get(
      `${this.getResourceUrl(companyUUID)}/${projectExporterUuid}/files?file=${fileType}`, { responseType: 'blob' },
    );
    saveAs(response, `${fileType}.json`);
  }

  async getCompanyProjectExporters(companyUUID) {
    const result = await this.apiClient.get(this.getResourceUrl(companyUUID));
    const projectExpoters = result.response.projectExporters;
    this.store.dispatch(setCompanyProjectExportersList(projectExpoters));
    this.store.dispatch(setCurrentCompany(companyUUID));
    return projectExpoters;
  }
}

export default ProjectExporterService;
