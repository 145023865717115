import React from "react";
import { withServices } from "reaf";
import linkedinLogo from "../../../../assets/images/linkedin.svg";
import { setSelfSignupStatus } from "../../../../store/auth";
import styles from "./LinkedInSignUpButton.module.scss";

const LinkedInSignUpButton = ({ authService, store }) => {
  
  const onLinkedInSignUp = React.useCallback(async () => { 
    await authService.linkedInLogin()
    store.dispatch(setSelfSignupStatus(true));
  }, [])

  return (
    <div
      onClick={onLinkedInSignUp}
      className={`${styles.linkedInButtonWrapper} abcRioButton `}
    >
      <div className="cursor-pointer position-relative">
        <div className={styles.linkedInIconWrapper}>
          <img
            alt="LinkedIn logo"
            src={linkedinLogo}
            className={styles.linkedInLogo}
          />
        </div>
        <span className={`abcRioButtonContents ${styles.linkedInTextWrapper}`}>
          <span>Sign up with LinkedIn</span>
        </span>
      </div>
    </div>
  )
};

export default withServices("authService", "store")(LinkedInSignUpButton);
