import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { withServices } from 'reaf';
import uploadDocIcon from '../../../assets/images/upload-doc.svg';
import { BrowseOSRRButtonClicked } from '../../../constants/eventTrackerMessage';
import style from './AddDocumentForm.module.scss';

function AddDocumentForm({
  project,
  documentsService,
  BrowseButton,
  onSubmit,
  toastrService,
  eventTrackerService,
  ...restProps
}) {
  const fileInput = useRef();
  const formRef = useRef();

  function onFileSelect() {
    const { files } = fileInput.current;
    documentsService.createDocuments(project, files).catch((e) => {
      toastrService.error(e.message);
      console.log(e);
    });
    onSubmit && onSubmit();
    fileInput.current.value = null;
  }

  function submitForm(event) {
    event.preventDefault();
    const { files } = fileInput.current;
    documentsService.createDocuments(project, files).catch((e) => {
      toastrService.error(e.message);
      console.log(e);
    });
  }

  return (
    <form ref={formRef} onSubmit={submitForm}>
      <div className="form-group m-0">
        {BrowseButton ? (
          <BrowseButton
            onClick={() => {
              fileInput.current.click();
              eventTrackerService.track(BrowseOSRRButtonClicked);
            }}
            {...restProps}
          />
        ) : (
          <Button
            variant="light"
            className={`btn-sm ${style.browseBtn}`}
            type="button"
            onClick={() => {
              fileInput.current.click();
              eventTrackerService.track(BrowseOSRRButtonClicked);
            }}
            {...restProps}
          >
            <img src={uploadDocIcon} alt="upload doc" />
            Browse OS and RR Files
          </Button>
        )}
        <input
          multiple
          type="file"
          name="file"
          hidden
          ref={fileInput}
          onChange={() => onFileSelect()}
        />
      </div>
    </form>
  );
}

export default withServices(
  'documentsService',
  'toastrService',
  'eventTrackerService'
)(AddDocumentForm);
