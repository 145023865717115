import React, { useState, useEffect } from 'react';
import RBToast from 'react-bootstrap/Toast';

function Toast({
  message, bindShowToast, variant = 'info', textVariant = 'light', autohide = true, wrapperClass = '', headerMessage = '',
  delay = 3000
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    bindShowToast && bindShowToast(() => setShow(true));
  }, [bindShowToast]);


  return (
    show ?
      (
        <RBToast
          show={show}
          delay={delay}
          autohide={autohide}
          onClose={() => setShow(false)}
          className={`text-center ${wrapperClass}`}
          style={{
            position: 'absolute', top: 5, left: '50%', marginLeft: -175
          }}
        >
          {!!headerMessage && <RBToast.Header>{headerMessage}</RBToast.Header>}
          {
            !!message && 
            <RBToast.Body className={`bg-${variant} text-${textVariant} pt-2 pb-2`}>
             <strong>{message}</strong>
            </RBToast.Body>
          }
        </RBToast>
      ) : <></>
  );
}

export default Toast;
