import React from 'react';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import TemplateList from '../../app/TemplateList/TemplateList';

function SuperAdminTemplatesPage({ queries }) {
  return (
    <SuperAdminDashboard>
      <TemplateList queries={queries} />
    </SuperAdminDashboard>
  );
}

export default SuperAdminTemplatesPage;
