import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import { Form as BootstrapForm } from 'react-bootstrap';
import { USER_ROLES } from '../../../constants';
import ReactSelect from 'react-select';
import { isEmpty } from 'lodash';

// eslint-disable-next-line no-unused-vars
const { SUPER_ADMIN, ...allowedUserRoles } = USER_ROLES;

function EditUserFormComponent({
  status, isSubmitting, bindSubmission, submitForm, companyTemplates, selectAll, setSelectAll, selectedTemplates,
  selectAllForWrite, setSelectAllForWrite,  companyTemplatesMap, values, formikProps, ...form
}) {
  bindSubmission(submitForm, isSubmitting);
  
  return (
    <div>
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <label htmlFor="user.firstName">First Name</label>
          <Field id="user.firstName" className="form-control" name="user.firstName" type="text" />
          <FieldErrorMessage name="user.firstName" />
        </div>
        <div className="form-group">
          <label htmlFor="user.lastName">Last Name</label>
          <Field id="user.lastName" className="form-control" name="user.lastName" type="text" />
          <FieldErrorMessage name="user.lastName" />
        </div>
        <div className="form-group">
          <label htmlFor="user.email">Email</label>
          <Field id="user.email" className="form-control" name="user.email" type="email" />
          <FieldErrorMessage name="user.email" />
        </div>
        <div>
          <label htmlFor="role">Roles</label>
          <Field component="select" className="form-control" name="role">
            {
              Reflect.ownKeys(allowedUserRoles).map(role => (
                <option key={role} value={role}>{USER_ROLES[role].label}</option>)
              )
            }
          </Field>
          <FieldErrorMessage name="role" />
        </div>
        <div className="form-group">
          <label htmlFor="readAccess">Read access</label>
          <BootstrapForm.Group controlId="formBasicCheckbox-1">
            <BootstrapForm.Check
              type="checkbox" checked={selectAll} onChange={e => {
                setSelectAll(e.target.checked);
                form.setFieldValue('readAccess', companyTemplatesMap);
              }} label="Allow all templates." />
          </BootstrapForm.Group>
          <ReactSelect
            isMulti
            name="readAccess"
            isDisabled={selectAll}
            value={values.readAccess}
            onChange={templates => {
              form.setFieldValue('readAccess', templates);
            }}
            options={companyTemplatesMap} />
          {isEmpty(values.readAccess) && <div className="text-danger">
              <small>Please select atleast one template</small>
          </div>}
        </div>
        <div className="form-group">
          <label htmlFor="writeAccess">Write access</label>
          <BootstrapForm.Group controlId="formBasicCheckbox-2">
            <BootstrapForm.Check
              type="checkbox" checked={selectAllForWrite} onChange={e => {
                setSelectAllForWrite(e.target.checked);
                form.setFieldValue('writeAccess', companyTemplatesMap);
              }} label="Allow all templates." />
          </BootstrapForm.Group>
          <ReactSelect
            isMulti
            name="writeAccess"
            isDisabled={selectAllForWrite}
            value={values.writeAccess}
            onChange={templates => {
              form.setFieldValue('writeAccess', templates);
            }}
            options={companyTemplatesMap} />
        </div>
      </Form>
    </div>
  );
}

export default EditUserFormComponent;
