import React from 'react';
import WithTooltip from '../WithTooltip/WithTooltip';
import styles from './SheetIcon.module.scss';

const SheetIcon = ({
  className, buttonSizeClassName='', id = '', title, size = 'sm', onClick
}) => (
  <WithTooltip placement="bottom" tooltipText={title}>
    <div className="position-relative d-inline-block" id={id}>
      <div className={`position-relative d-inline-block ${size === 'sm' ? styles.spreadsheetToolbarButton : styles.spreadsheetButtonIcon}`} onClick={onClick}>
        <div className={`position-relative d-inline-block ${buttonSizeClassName} ${size === 'sm' ? styles.spreadsheetIcon : styles.spreadsheetButtonSize}`}>
          <div className={`${className} ${styles.spreadsheetSpriteIcon} ${styles.spreadsheetSpriteImg}`}>&nbsp;</div>
        </div>
      </div>
    </div>
  </WithTooltip>
);

export default SheetIcon;
