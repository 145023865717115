import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import UsersListWithServerSidePagination from '../../app/User/UsersListWithServerSidePagination';

function UsersPageHeader() {
  return (
    <Container fluid className="p-2 light border-bottom-light-blue sa-breadcrumb">
      <Row className="align-items-center">
        <Col>
          <Breadcrumbs rootRoute="usersPage" items={[{ to: '#', content: 'Users' }]} />
        </Col>
      </Row>
    </Container>
  );
}

function UsersListPage({ queries }) {
  return (
    <SuperAdminDashboard>
      <UsersPageHeader />
      <UsersListWithServerSidePagination queries={queries} />
    </SuperAdminDashboard>
  );
}
export default UsersListPage;
