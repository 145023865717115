import React, { useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Tour from '../../core/Tour/Tour';

const onProjectListViewTour = (onAddProjectTutorial, onClose) => [{
  selector: '#sdadadasd',
  content: ({ goTo }) => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>
        This guide will walk you through various elements on the page.
        Please click the next button or use arrow keys to see next item.
      </p>
      <p>
        You can click the close button at any step to get out of this guide.
      </p>
      <p>
        <Button size="sm" variant="primary" onClick={() => goTo(1)}>Start</Button>
      </p>
    </>
  )
},
{
  selector: '#AddProjectButton',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Click here to add new property or project.</p>
    </>
  )
},
{
  selector: '#BreadcrumbHome',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Click here to return to property view at any time.</p>
    </>
  )
},
{
  selector: '.projectNameFilter',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Search across all your property by entering your query here.</p>
    </>
  )
},
{
  selector: '#AssetTypeFilter',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Use this drop down to filter property by their asset class.</p>
    </>
  )
},
{
  selector: '.modelUsedFilter',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Search your property by their model used.</p>
    </>
  )
},
{
  selector: '.productItem',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Double click a property to open the property, or right click for more options.</p>
    </>
  )
},
{
  selector: '.editProjectIcon',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Click here to edit property.</p>
    </>
  )
},
{
  selector: '.deleteIcon',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Click here to delete property.</p>
    </>
  )
},
{
  selector: '.needSupportIcon',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Click here to get support.</p>
    </>
  )
},
{
  selector: '#ShowTourButton',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>Click here to revisit the page tutorial.</p>
    </>
  )
},
{
  selector: '#asdasdasdasdasd',
  content: () => (
    <>
      <h6 className="border-bottom">Page Guide</h6>
      <p>
        Check other tutorials on this page:
        <p>
          <Button variant="secondary" size="sm" className="btn-xs" onClick={onAddProjectTutorial}>Add New Property</Button>
        </p>
      </p>

      <p>
        <p>
          Or, you may end the guide.
        </p>
        <Button size="sm" variant="warning" onClick={onClose}>End Guide</Button>
      </p>
    </>
  ),
  action: node => node && node.focus()
}
];

function ProjectsPageTour({
  show, onClose, step, onAddProjectTutorial
}) {

  const steps = useMemo(() => onProjectListViewTour(onAddProjectTutorial, onClose), [onAddProjectTutorial]);
  return (
    <Tour
      steps={steps}
      isOpen={show}
      onRequestClose={onClose}
      rounded={5}
      accentColor="#2196f2"
      startAt={0}
      goToStep={step}
      updateDelay={300}
      update={Math.random()}
      closeWithMask={false}
      disableInteraction
    />
  );
}

export default ProjectsPageTour;
