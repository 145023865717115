import React from 'react';
import { Badge } from 'react-bootstrap';
import { DocumentFileTypes } from '../../../constants';

function DocumentTypeBadge({ documentType }) {
  const displayType = DocumentFileTypes[documentType];
  return (
    <span>{displayType && <Badge variant="primary" className="ml-2">{displayType.badgeText}</Badge>}</span>
  );
}

export default DocumentTypeBadge;
