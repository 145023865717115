/* eslint-disable import/no-cycle */
import { TARGET_SHEET, TARGET_SHEET_NAME } from "../../../app/DocExtractionComponent/DataSpreadSheet";
import BaseEvent from "./BaseEvent";

export default class CellClickEvent extends BaseEvent {
	handle(datasheet, _, args) {
		const { spreadsheet } = datasheet;
		const {
			row, col, sheet, sheetName, sheetArea
		} = args;
		if (row === 0 && sheetName === TARGET_SHEET_NAME && sheetArea > TARGET_SHEET) {
			datasheet.clipboardData.lastCellValue = spreadsheet.getCellValue(TARGET_SHEET, row, col);
			sheet.setActiveCell(row, col);
			sheet.startEdit(true);
		}
	}
}