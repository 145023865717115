import BaseEvent from '../../../core/Spreadjs/Events/BaseEvent';
import { SOURCE_SHEET_NAME, TARGET_SHEET_NAME } from '../DataSpreadSheet';

export class RRCellChangedEvent extends BaseEvent {
  handle(rrSpreadsheet, _, args) {
    const {
      sheet, sheetName, col, row, newValue, oldValue,
    } = args;
    if (sheetName === TARGET_SHEET_NAME && oldValue !== newValue) {
      // Id value changed
      if (row !== 0) { // It runs on every cell change event
        setTimeout(() => {
          rrSpreadsheet.validateMonthlyRentOnCellChanged({
            sheet, row, col
          });
        }, 0)
      
      }

      if (col === 0) {
        const [sourceSheetData] = rrSpreadsheet.datasheet.getSheetSpecificData([SOURCE_SHEET_NAME],);
        const sourceSheetColCount = rrSpreadsheet.datasheet.getColumnCount(sourceSheetData);
        rrSpreadsheet.onTargetIdValueChange(row, newValue, oldValue, sourceSheetColCount);
      }
    }
  }
}

export const rrCellChangedEvent = RRCellChangedEvent.get();
