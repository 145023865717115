/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { Table as BTable } from 'react-bootstrap';
import { faSort as sortIcon } from '@fortawesome/free-solid-svg-icons/faSort';
import { faCaretUp as sortUpIcon } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCaretDown as sortDownIcon } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { withServices } from 'reaf';
import style from './CustomTableUI.module.scss';
import IconButton from '../../core/Button/IconButton';
import { ProjectListSorted } from '../../../constants/eventTrackerMessage';

const SortIcon = ({ onClick, icon, id }) => (
  <IconButton
    className="sortIcon"
    onClick={onClick}
    variant="light"
    icon={icon}
    id={id}
  />
);

const CustomTableUI = ({
  columns,
  rows,
  onOpen,
  queries,
  fetchProjects,
  eventTrackerService
}) => {
  const isSorted = useCallback(
    (column) => {
      const order = { ...(queries._order || {}) };
      return !!order[column.accessor];
    },
    [queries._order]
  );

  const isSortedDesc = useCallback(
    (column) => {
      const order = { ...(queries._order || {}) };
      return order[column.accessor] === 'DESC';
    },
    [queries._order]
  );

  const onOrderChanged = useCallback(
    (column) => {
      let order = { ...(queries._order || {}) };
      if (order[column.accessor] === 'DESC') {
        fetchProjects({ ...queries, _order: {} });
        eventTrackerService.track(ProjectListSorted, {
          columnName: column.accessor,
          value: ''
        });
      } else {
        order =
          order[column.accessor] === 'ASC'
            ? { [column.accessor]: 'DESC' }
            : { [column.accessor]: 'ASC' };
        fetchProjects({ ...queries, _offset: 0, _order: order });
        eventTrackerService.track(ProjectListSorted, {
          columnName: column.accessor,
          value: order[column.accessor]
        });
      }
    },
    [queries, fetchProjects]
  );

  return (
    <BTable hover size="sm" key="ProjectTable">
      <thead className={style.reactTableHeader}>
        <tr>
          {columns.map((column) => (
            <th key={column.accessor} width={column.width}>
              <div
                className="d-flex align-items-center"
                key={`${column.accessor}_div`}
              >
                <div className={column.accessor}>
                  <span>{column.Header}</span>
                  {column.canSort && (
                    <span>
                      {isSorted(column) ? (
                        isSortedDesc(column) ? (
                          <SortIcon
                            onClick={() => onOrderChanged(column)}
                            icon={sortDownIcon}
                            id={`sort_${column.accessor}_desc`}
                          />
                        ) : (
                          <SortIcon
                            onClick={() => onOrderChanged(column)}
                            icon={sortUpIcon}
                            id={`sort_${column.accessor}_asc`}
                          />
                        )
                      ) : (
                        <SortIcon
                          onClick={() => onOrderChanged(column)}
                          icon={sortIcon}
                          id={`sort_${column.accessor}_sort`}
                        />
                      )}
                    </span>
                  )}
                </div>
                <div key={`${column.accessor}_Filter_div`}>
                  {column.Filter
                    ? column.Filter({ accessor: column.accessor })
                    : null}
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index} className={`productItem ${style.noselect}`}>
            {columns.map((column, index2) => (
              <td key={index2} onDoubleClick={() => onOpen(row)}>
                <column.Cell
                  value={row[column.accessor]}
                  row={{ original: row }}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </BTable>
  );
};

export default withServices('eventTrackerService')(CustomTableUI);
