import React from 'react';
import { Formik } from 'formik';
import { withServices } from 'reaf';
import VideoTutorialsFormComponent from './VideoTutorialsFormComponent';
import messages from '../../../../locales';
import { VideoTutorialsFormSchema } from './VideoTutorialFormSchema';


function EditVideoTutorialsForm({
  onSubmitSuccess, bindSubmission, videoTutorial, videoTutorialsService, toastrService
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      await videoTutorialsService.updateVideoTutorial(values);
      onSubmitSuccess();
      const message = messages.toastMessage.EDIT_VIDEO_TUTORIAL;
      toastrService.success(message);
    } catch (error) {
      const errorMessage = 'Could not update the video.'
      actions.setStatus({ error: errorMessage });
    }
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ videoTutorial }}
      validationSchema={VideoTutorialsFormSchema}
      onSubmit={onSubmit}
      // eslint-disable-next-line react/no-children-prop
      children={formikProps => 
        <VideoTutorialsFormComponent {...formikProps} bindSubmission={bindSubmission} isEdit />
      }
    />
  );
}

export default withServices('videoTutorialsService', 'toastrService')(EditVideoTutorialsForm);
