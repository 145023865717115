import { sortBy } from 'lodash';
import { setTemplates, setTemplateModels, setNumberOfOSMonthsToHide } from '../store/company';
import { setAuth } from '../store/auth';
import { forceUTCTimeZone, isBlankString } from '../utils/utils';
import { API_INTEGRATION_CONFIG_KEYS } from '../constants';
import { SupportTicketCreated } from '../constants/eventTrackerMessage';

class CompanyService {
  constructor(
    apiClient,
    store,
    companyListService,
    authService,
    eventTrackerService
  ) {
    this.authService = authService;
    this.apiClient = apiClient;
    this.store = store;
    this.companyListService = companyListService;
    this.eventTrackerService = eventTrackerService;
  }

  setToastrService(toastrService) {
    this.toastrService = toastrService;
  }

  async loadCompanyTemplates() {
    const result = await this.apiClient.get('/companies/templates');
    const { templates } = result.response;
    const sortedTemplates = sortBy(templates, [
      (template) => template.templateName.toLowerCase()
    ]);
    this.store.dispatch(setTemplates(sortedTemplates));
    return templates;
  }

  async loadAllGlobalTemplates() {
    const result = await this.apiClient.get(`/companies/allGlobalTemplates`);
    const { templates } = result.response;
    return templates;
  }

  async loadCompanyGlobalTemplates(companyUuid) {
    const result = await this.apiClient.get(`/companies/${companyUuid}/companyGlobalTemplates`);
    const { templates } = result.response;
    return templates;
  }

  async updateCompanyGlobalTemplate(companyUuid, values) {
    const result = await this.apiClient.put(`/companies/${companyUuid}/companyGlobalTemplates`, values);
    return result;
  }

  async upsertCompany(company, values) {
    let responseCompany;
    const notificationText = isBlankString(values.company.notificationText)
      ? null
      : values.company.notificationText;
    const numberOfLicenses =
      // eslint-disable-next-line no-nested-ternary
      typeof values.company.numberOfLicenses === 'string'
        ? isBlankString(values.company.numberOfLicenses)
          ? null
          : values.company.numberOfLicenses
        : values.company.numberOfLicenses;
    const trialDays = values.company.isTrialOffering ? values.company.trialDays : null;
    if (company.uuid) {
      let paymentDueDate =
        values.paymentDueDate !== ''
          ? forceUTCTimeZone(values.paymentDueDate)
          : company.paymentDueDate;
      if (
        values.previousDueDate !== '' &&
        values.paymentStatus === 'Completed'
      ) {
        paymentDueDate = null;
      }
      const updatedCompany = {
        ...values.company,
        paymentDueDate,
        OSMonthsToHide: values.OSMonthsToHide,
        notificationText,
        numberOfLicenses,
        trialDays
      };
      responseCompany = await this.updateCompany(company.uuid, {
        company: updatedCompany
      });
    } else {
      responseCompany = await this.createCompany({
        company: {
          ...values.company,
          paymentDueDate: null,
          OSMonthsToHide: values.OSMonthsToHide,
          notificationText,
          numberOfLicenses,
          trialDays
        },
        admin: values.admin
      });
    }
    return responseCompany;
  }

  async setCompanyAccess(company, accessEnabled) {
    const result = await this.apiClient.put(`/companies/${company.uuid}`, {
      company: {
        accessEnabled
      }
    });
    return result.response;
  }

  async setCompanyAcl(company, isAclEnabled) {
    const result = await this.apiClient.put(`/companies/${company.uuid}`, {
      company: {
        isAclEnabled
      }
    });
    return result.response;
  }

  async updateCompanySSO(company, { isSSOEnabled, slug }) {
    const result = await this.apiClient.put(`/companies/${company.uuid}`, {
      company: {
        isSSOEnabled,
        slug
      }
    });
    const companyDetails = result.response;
    return companyDetails;
  }

  async createCompany(values) {
    const result = await this.apiClient.post('/companies', values);
    return result.response;
  }

  async updateCompany(uuid, values) {
    const result = await this.apiClient.put(`/companies/${uuid}`, values);

    return result.response;
  }

  async deleteCompany({ company }) {
    const result = await this.apiClient.delete(
      `/admin/companies/${company.company.uuid}`
    );
    return result.response;
  }

  async getAllCompanies() {
    const result = await this.apiClient.get(`/companies`);
    return result.response;
  }

  async getApiConfiguraion(companyUuid) {
    const result = await this.apiClient.get(`/companies/${companyUuid}/apiIntegration`);
    return result.response;
  }

  async downloadCompanyApiConfig(companyUuid) {
    const result = await this.apiClient.get(`/companies/${companyUuid}/downlaod/config`);
    const response = result.response;
    const fileType = response.fileName.split('.').pop() === 'csv' ? 'text/csv;charset=utf-8;': 'application/json';
    const blob = new Blob([response.formattedApiConfigFile], { type: fileType });
    saveAs(blob, `${response.fileName}`); 
    return response;
  }

  async updateApiConfiguration(apiConfigDetails) {
    const formData = new FormData();
    for (const key of API_INTEGRATION_CONFIG_KEYS) {
      formData.append(key, apiConfigDetails[key]);
    }
    formData.append('config', JSON.stringify(apiConfigDetails.config));
    formData.append('apiKeys', JSON.stringify(apiConfigDetails.apiKeys));
    const result = await this.apiClient.post(`/companies/${apiConfigDetails.companyUuid}/apiIntegration/createOrUpdate`,
      formData
    );
    return result
  }

  async updateConfigOfApiConfiguration(companyUuid) {
    const result = await this.apiClient.put(
      `/companies/${companyUuid}/apiIntegration/deteleConfig`,
    );
    return result.response;
  }

  async loadCompanyTemplateModels(companyUuid) {
    const result = await this.apiClient.get(
      `/companies/${companyUuid}/templatesWithModel`
    );
    return result.response;
  }

  registerSideEffects(sideEffect) {
    sideEffect.after(
      setAuth,
      async ([user, token, role, company], { dispatch }) => {
        if (!user.hasAgreedTerms) {
          return false;
        }

        if (
          !(
            this.authService.isSuperAdmin() ||
            this.authService.isAccountsExecutive()
          ) &&
          company
        ) {
          const templateModels = await this.loadCompanyTemplateModels(
            company.uuid
          );
          const sortedTemplateModels = sortBy(templateModels, [
            (template) => template.templateName.toLowerCase()
          ]);
          dispatch(setTemplateModels(sortedTemplateModels));
          dispatch(setNumberOfOSMonthsToHide(+company?.OSMonthsToHide || 3));
        }
      }
    );
  }

  async createSupport(values, selectedAttachments) {
    const endPoint = `/tickets`;
    const formData = new FormData();
    Reflect.ownKeys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    selectedAttachments.forEach((selectedAttachment) => {
      formData.append('file', selectedAttachment);
    });

    const result = await this.apiClient.post(endPoint, formData);

    this.eventTrackerService.track(SupportTicketCreated, {
      ...values,
      hasAttachment: selectedAttachments.length > 0,
      attachmentsCount: selectedAttachments.length
    });

    this.toastrService.success(result.message);
    return result;
  }
}

export default CompanyService;
