import React from 'react';
import { connect } from 'react-redux';
import UserHeader from '../../app/UserHeader/UserHeader';
import Footer from '../../app/Footer/Footer';
import { loggedInCompanySelector, routerStateSelector, userRoleSelector } from '../../../store/selectors';

function LoggedInUserPage({ children, noFooter, hideMenus=false }) {
  return (
    <div className="page">
      <UserHeader hideMenus={hideMenus}/>
      <div className="vertical-section">
        {children}
      </div>
      {
        !noFooter &&
        <Footer />
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  company: loggedInCompanySelector(state),
  userRole: userRoleSelector(state),
  routerState: routerStateSelector(state)
});

export default connect(mapStateToProps)(LoggedInUserPage);
