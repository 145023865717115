import React, { useCallback } from 'react';
import { components } from 'reaf';
import GoogleLoginButton from './GoogleLoginButton';
import { SOCIAL_LOGIN_TYPES } from '../../../constants';
import { hideBackdrop, showBackdrop } from "../../../store/loadingBackdrop";
import LinkedInButton from './LinkedInLoginButton';
import styles from "./SocialLoginForm.module.scss";

const SocialLoginForm = ({ authService, store, wrapperClass = '', linkedInWrapperClass, toastrService }) => {
  const onSuccess = useCallback(async (googleUser) => {
    store.dispatch(showBackdrop("Please wait..."))
    try {
      await authService.socialLogin({
        idToken: googleUser.getAuthResponse().id_token,
        socialLoginType: SOCIAL_LOGIN_TYPES.GOOGLE
      });
      store.dispatch(hideBackdrop())
    } catch(e) {
      toastrService.error(e.message);
      authService.socialLogout();
      store.dispatch(hideBackdrop())
    }
   
  }, [store]);

  const onFailure = useCallback((error) => {
    console.log(error);
  });

  return (
    <div className={`googleLoginButton d-flex ${wrapperClass} ${styles.buttonContainer}`}>
      <GoogleLoginButton onSuccess={onSuccess} onFailure={onFailure} />
      <LinkedInButton linkedInWrapperClass={linkedInWrapperClass} />
    </div>
  );
};

const SocialLoginFormSimple = components.withServices('store', 'authService', 'toastrService')(SocialLoginForm);

export const SocialLoginFormMemo = React.memo(SocialLoginFormSimple);
export default SocialLoginFormSimple;
