import * as Yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const VideoTutorialsFormSchema = Yup.object().shape({
    videoTutorial: Yup.object().shape({
      title: Yup.string().required('Please provide a valid title.'),
      videoId: Yup.string().required('Please provide a valid id.'),
      description: Yup.string().required('Please provide a valid description.'),
      category: Yup.string().required('Please provide a valid category.')
    })
  });