import React, { useState, useEffect, useCallback } from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import ListHeader from '../ListHeader/ListHeader';

function List({
  onAdd, listItems, addButtonText, ListItem, title, filterListItem,
  getKey, utilityButtons, onExportCSV, recordsPerPage = 10, isGlobal
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(recordsPerPage);
  const [searchText, setSearchText] = useState('');
  const [filteredListItems, setFilteredListItems] = useState(listItems);
  const [visibleListItems, setVisibleListItems] = useState([]);

  useEffect(() => {
    const filteredList = !searchText ? listItems : listItems.filter(item => filterListItem(item, searchText));
    setFilteredListItems(filteredList);
  }, [listItems, searchText]);

  useEffect(() => {
    const visibleListItems = filteredListItems.slice(((currentPage * pageSize) - pageSize), (currentPage * pageSize));
    setVisibleListItems(visibleListItems);
  }, [currentPage, filteredListItems, pageSize]);

  useEffect(() => {
    if (filteredListItems.length > 0 && (currentPage - 1) * pageSize > filteredListItems.length - 1) {
      setCurrentPage(Math.ceil(filteredListItems.length / pageSize));
    }
  }, [pageSize, filteredListItems]);

  const onDownloadCSV = useCallback(() => {
    onExportCSV(filteredListItems);
  }, [filteredListItems, onExportCSV]);

  return (
    <>
      <h4 className="mt-2">{title}</h4>
      <div className="superAdmin-containerWrap">
        <div className="table-header">
          <ListHeader
            onPageChange={setCurrentPage}
            onExportCSV={onExportCSV ? onDownloadCSV : null}
            currentPage={currentPage}
            onAdd={onAdd}
            utilityButtons={utilityButtons}
            totalPages={Math.ceil(filteredListItems.length / pageSize)}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            searchText={searchText}
            onSearch={setSearchText}
            addButtonText={addButtonText}
            isGlobal={isGlobal}
          />
        </div>
        <Container fluid className="table-listing-wra px-0">
          <ListGroup variant="flush">
            {
              visibleListItems.map(listItem => (
                <ListItem key={getKey(listItem)} item={listItem} />
              ))
            }
          </ListGroup>
        </Container>
      </div>
    </>
  );
}

export default List;
