import React from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { withServices } from 'reaf';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';
import RecentUntaggedDocumentsComponent from '../../app/RecentDocumentsComponent/RecentUntaggedDocumentsComponent';
import LoggedInUserPage from '../LoggedInUserPage/LoggedInUserPage'

function RecentDocumentsPageHeader() {
  return (
    <Container fluid className="bg-light border-bottom sub-header">
      <Row className="align-items-center">
        <Col sm="auto" className="align-middle p2">
          <Breadcrumbs items={[
            { to: '#', content: 'Recent Documents' },
            ]} />
        </Col>
        <Col />
      </Row>
    </Container>
  );
}

function RecentUnTaggedDocumentsPage({ queries, router }) {

  const onTabChange = React.useCallback((pageRouteName) => {
    router.setRoute(pageRouteName);
  }, [])

  return (
    <LoggedInUserPage noFooter>
      <RecentDocumentsPageHeader/>
      <Container fluid className="m-0 vertical-section">
        <Row className="vertical-section">
          <Col className="vertical-section p-0">
            <div className="fill-parent overflow-auto p-3">
              <Tabs unmountOnExit defaultActiveKey="recentUntaggedDocuments" onSelect={onTabChange}>
                <Tab eventKey="recentTaggedDocuments" title="Recent Tagged Documents">
                </Tab>
                <Tab eventKey="recentUntaggedDocuments" title="Recent Untagged Documents">
                  <RecentUntaggedDocumentsComponent queries={queries}/>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </LoggedInUserPage>
  )
}


export default withServices('router')(RecentUnTaggedDocumentsPage)