import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { withServices } from 'reaf';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import DocumentTaggingFormComponent from './DocumentTaggingFormComponent';
import DocumentsService from '../../../services/DocumentsService';
import { allowTaggingForCommercials, DocumentFileTypes, HIDE_RENT_ROLL_ASSET_TYPES_ON_TAGGING, PROPERTY_TYPES } from '../../../constants';
import messages from '../../../../locales';
import { currentProjectViewerActiveSheetSelector, loggedInCompanySelector, dynamicAssetTypesListSelector } from '../../../store/selectors';
import { isOSBaseType, isRRBaseType } from '../../../utils/utils';
import { setCurrentActiveViewerSheet } from '../../../store/currentProject';

const invalidDateMsg = 'Please provide a valid date.';

// eslint-disable-next-line no-unused-vars
const {NONE, ...displayTaggingData} = DocumentFileTypes;

const DocumentTaggingFormSchema=(project,pageCount,sheetCount) => Yup.object().shape({
  taggingDetails: Yup.array().of(
    Yup.object().shape({
      documentType: Yup.mixed().oneOf(Reflect.ownKeys(displayTaggingData), 'Please select document type!'),
      splitFrom: Yup.number()
        .transform((cv, ov) => (ov === '' ? undefined : cv))
        .min(1, 'From page can not be less than 1')
        .max(pageCount, `This document has only ${pageCount} pages.`),
      splitTo: Yup.number()
        .transform((cv, ov) => (ov === '' ? undefined : cv))
        .min(1, 'To page can not be less than 1')
        .max(pageCount, `This document has only ${pageCount} pages.`),
      sheet: Yup.number().min(1).max(sheetCount || 1),
      asOnDate: Yup.date().typeError(invalidDateMsg)
        .when('documentType', {
          is: 'RENT_ROLL',
          then: Yup.date().required('Please select as of date'),
          otherwise: Yup.date()
      }),
      periodFrom: Yup.date().typeError(invalidDateMsg).when('documentType', {
        is: (documentType) => documentType && isOSBaseType(documentType),
        then: Yup.date().typeError(invalidDateMsg).when('periodTo', {
          is: v => v != null,
          then: s => s.max(Yup.ref('periodTo'), `Period From should be earlier then Period To date.`)
        }).required('Please select period from date'),
        otherwise: Yup.date().typeError(invalidDateMsg)
      }),
      periodTo: Yup.date().typeError(invalidDateMsg).when('documentType', {
        is: (documentType) => documentType && isOSBaseType(documentType),
        then: Yup.date().typeError(invalidDateMsg)
          .min(Yup.ref('periodFrom'), 'Period To date should be later then Period From date.')
          .required('Please select period to date'),
        otherwise: Yup.date().typeError(invalidDateMsg)
      })
    })
  )
});

function DocumentTaggingForm({
  project, document, documentsService, onSuccess, pageCount, store, dynamicAssetTypesList,
  sheetNames, toastrService, company, currentActiveSheetName, onCancel, onSheetChange
}) {
  const defaultValues = useMemo(() => ({
    documentType: company.documentTypes && company.documentTypes.length === 1 ? company.documentTypes[0] : null,
    splitFrom: undefined,
    splitTo: undefined,
    sheet: 1,
    asOnDate: undefined,
    periodTo: undefined,
    periodFrom: undefined
  }), [company.documentTypes]);

  const hasRenRollOption = () => {
   
    const rentRollRestrictedAssetTypes = company.rentRollRestrictedAssetTypes || [];
    const assetType = dynamicAssetTypesList.find(assetType => assetType.assetType == project.assetType);
    if (HIDE_RENT_ROLL_ASSET_TYPES_ON_TAGGING.includes(project.assetType)) {
      return false;
    }
    if (rentRollRestrictedAssetTypes.includes(project.assetType)) {
      return false;
    } 
    if(assetType && assetType.canRestrictRentRoll) {
      return false;
    }
    return true;
  };

  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      const response = await documentsService.tagDocument(project, document, values.taggingDetails);
      onSuccess(response);
      toastrService.success(messages.toastMessage.DOCUMENT_TAG);
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  const validationSchema = useMemo(() => DocumentTaggingFormSchema(
    project, pageCount, sheetNames.length
  ), [project, pageCount, sheetNames.length]);

  const taggingFormValidation = useCallback((values) => {
    const errors = {};
    let hasRR = false;
    values.taggingDetails.forEach((taggingDetail, index) => {
      const { documentType, splitFrom, splitTo } = taggingDetail;
      if (documentType === 'RENT_ROLL') {
        if (hasRR) {
          errors.taggingDetails = errors.taggingDetails || [];
          errors.taggingDetails[index] = errors.taggingDetails[index] || {};
          errors.taggingDetails[index].documentType = 'Only one rent roll tagging is allowed';
        } else {
          hasRR = true;
        }
      }

      if (splitFrom && splitTo) {
        if (splitFrom > splitTo) {
          errors.taggingDetails = errors.taggingDetails || [];
          errors.taggingDetails[index] = errors.taggingDetails[index] || {};
          errors.taggingDetails[index].splitTo = 'Split to can\'t be greater than split from';
        }
      }
      if (!splitFrom && splitTo) {
        errors.taggingDetails = errors.taggingDetails || [];
        errors.taggingDetails[index] = errors.taggingDetails[index] || {};
        errors.taggingDetails[index].splitFrom = 'Split from can\'t be empty';
      }
    });
    return errors;
  }, [validationSchema]);

  const onResetSheetSelection = useCallback(() => {
    store.dispatch(setCurrentActiveViewerSheet(null));
  }, [store])

  return (
    <Formik
      initialValues={{
        taggingDetails: [
          defaultValues
        ]
      }}
      onSubmit={onSubmit}
      validate={taggingFormValidation}
      validationSchema={DocumentTaggingFormSchema(project, pageCount, sheetNames.length)}
      children={formikProps => (
        <DocumentTaggingFormComponent
          {...formikProps}
          sheetNames={sheetNames}
          company={company}
          project={project}
          document={document}
          key="docTaggingForm"
          defaultValues={defaultValues}
          docType={DocumentsService.getDocumentType(document)}
          onCancel={onCancel}
          showRentRollOption={hasRenRollOption}
          currentActiveSheetName={currentActiveSheetName}
          onSheetChange={onSheetChange}
          onResetSheetSelection={onResetSheetSelection}
        />
      )}
    />
  );
}

const mapStateToProps = state => ({
  company: loggedInCompanySelector(state),
  currentActiveSheetName: currentProjectViewerActiveSheetSelector(state),
  dynamicAssetTypesList: dynamicAssetTypesListSelector(state)
});

export default connect(mapStateToProps)(withServices('documentsService', 'store', 'toastrService')(DocumentTaggingForm));
