/* eslint-disable max-depth */
import BaseEvent from './BaseEvent';
import { sheetIndex } from '../../../../constants';

export default class ClipboardPastedEvent extends BaseEvent {
  handle(datasheet, _, options) {
    const { spreadsheet } = datasheet;
    const { targetSheetIndex } = sheetIndex;
    const activeSheetIndex = spreadsheet.getActiveSheetIndex();
    spreadsheet.suspend();

    if (datasheet.clipboardData.copiedSourceColumnRange) {
      const { col: targetColumn } = spreadsheet.getSelections(activeSheetIndex)[0];
      const { row, rowCount, colCount } = datasheet.clipboardData.copiedSourceColumnRange;
      if (row < 0 && 
        (activeSheetIndex === targetSheetIndex || activeSheetIndex === spreadsheet.getWorkingSheetIndex())
      ) {
        if (datasheet.isColumnNotEmpty(1, 0)) {
          for (let i = 0; i < colCount; i++) {
            const targetColumnIndex = targetColumn + i;
            datasheet.workbook.commandManager().execute({
              cmd: 'pasteCommand', headerNames: datasheet.clipboardData.copiedHeaderColumnValues,
            });
            // eslint-disable-next-line max-len
            spreadsheet.setCellValue(
              activeSheetIndex,
              0,
              targetColumnIndex,
              datasheet.clipboardData.copiedHeaderColumnValues[i].columnValue,
            );
          }
          datasheet.setCellFormatInUSNumber(activeSheetIndex, row, targetColumn, rowCount, colCount);
        }
      }
    } else {
      this.onRangePaste(datasheet, activeSheetIndex, options);
    }
    spreadsheet.resume();
  }

  onRangePaste(datasheet, activeSheetIndex, options) {
    const { spreadsheet, clipboardData } = datasheet;
    if (spreadsheet.getActiveSheetIndex() === activeSheetIndex && clipboardData.copiedSourceSelectedRange) {
      const pastedRange = spreadsheet.getSelections(activeSheetIndex)[0];
      const targetColumn = pastedRange.col;
      const targetRow = pastedRange.row;
      datasheet.copySourceRangeToTarget(
        activeSheetIndex, clipboardData.copiedSourceSelectedRange, targetRow, targetColumn,
      );
    } else if (spreadsheet.getActiveSheetIndex() === activeSheetIndex && !this.copiedSourceSelectedRange) {
      datasheet.onRangePasteToTargetSheet(activeSheetIndex, options);
    }
    datasheet.copiedSourceSelectedRange = null;
  }
}
