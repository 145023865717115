import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';

import styles from './SuperAdminDashboardCounts.module.scss';

const CountCard = ({
  count, title, background = "#1d456d", icon = "building", onClick
}) => (
  <div className={styles.item} style={{ background }} onClick={onClick}>
    <i className={`${styles.i} fa fa-${icon}`} />
    <p className={`${styles.p} ${styles.number}`}>
      <CountUp start={23} end={count} />
    </p>
    <span className={styles.span} />
    <p className={styles.p}>{title}</p>
  </div>
);

const CountCards = ({ router, counts }) => (
  <>
    <div className={styles.sectiontitle}>
      <h2>Companies statistics</h2>
      <span className={styles.headerLine} />
    </div>
    <div id="projectFacts" className={styles.sectionClass}>
      <div className={styles.fullWidth}>
        <div className={styles.projectFactsWrap}>
          <CountCard
            count={counts.companies}
            title="Companies"
            onClick={() => router.setRoute('superAdminPage')}
            background="#1d456d" icon="building" />
          <CountCard
            count={counts.projects}
            title="Projects"
            onClick={() => router.setRoute('superAdminProjectPage')}
            background="#193c5e" icon="folder" />
          <CountCard
            count={counts.documents}
            title="Documents"
            onClick={() => router.setRoute('superAdminDocumentsPage')}
            background="#1d456d" icon="file" />
          <CountCard 
            count={counts.users}
            title="Users" 
            onClick={() => router.setRoute('usersPage')}
            background="#193c5e" icon="users" />
        </div>
      </div>
    </div>
  </>
);

export default function SuperAdminDashboardCounts({ router, apiClient }) {
  const [counts, setCounts] = useState({
    projects: 0,
    companies: 0,
    users: 0,
    documents: 0
  });

  useEffect(() => {
    apiClient.get('/admin/getDashboardCounts')
      .then((response) => setCounts(response['response']));
  }, [apiClient]);
  return (
    <CountCards router={router} counts={counts} />
  );
}
