import { IO } from '@grapecity/spread-excelio';
import { saveAs } from 'file-saver';
import { isNil, isDate } from 'lodash';
import * as JSZip from 'jszip';
import { strToU8, compressSync } from 'fflate';
import {
  deleteDocument,
  setDocument,
  updateDocument
} from '../store/currentProject';
import {
  setDocument as setCurrentDocument,
  setExtractedData,
  setWorkbookData,
  setColumnHeadersList,
  setChargeCodeConfig,
  setStaticChargeCode,
  isTaggedPeriodEqualsMLReturnedPeriod,
  setUnitTypeDropdown,
  setUnitStatus,
  setFloorPlan,
  setFloorPlanBedMapping,
  setFloorPlanBathMapping,
  setTenantNameConfig,
  setProjectDictionaryExists,
  setRetailLeaseTypeDropDown,
  setRetailTenantTypeConfig,
  setRetailTenantTypeDropDown,
  setRetailMajorTenantTypePercent,
  setRetailLeaseTypeConfig,
  setRetailOccupancy,
  setRetailOccupancyMapping,
  setChargeCodeUnitTypes,
  setStaticChargeCodeMapping,
  setTemplateTag,
  setAssetType,
  setLeaseTypeDropDown,
  setUnitMixSummary,
  setUseMonthlyData
} from '../store/currentDocument';
import { apiConfig } from '../config';
import { authSelector } from '../store/selectors';
import {
  addStatus,
  updateStatus,
  clearQueue,
  updateErrorMessage
} from '../store/fileUploadStatus';
import { FILE_EXT_TYPES } from '../constants';
import { showBackdrop, hideBackdrop } from '../store/loadingBackdrop';
import {
  loaderManager,
  forceUTCTimeZone,
  updateWorkBookSheetName
} from '../utils/utils';
import { setAllDocuments } from '../store/documents';
import Service from './Service';
import { showToastr } from '../store/notificationToastr';
import {
  ClearedChildDocumentTagging,
  DocumentDeleted,
  DocumentRenamed,
  DocumentTagged,
  DocUploaded,
  WorkbookSaved
} from '../constants/eventTrackerMessage';

const getUTCOrNull = (date) => (isDate(date) ? forceUTCTimeZone(date) : null);

const processDocumentList = (response) => response.rows;
let uploadId = 1;
class DocumentsService extends Service {
  static getDocumentType(document) {
    return FILE_EXT_TYPES[document.fileExtension];
  }

  static getDocumentTypeFromName(name) {
    const ext = name.split('.').pop();
    return FILE_EXT_TYPES[ext.toUpperCase()] || FILE_EXT_TYPES.UNKNOWN;
  }

  constructor(apiClient, store, projectsService, eventTrackerService) {
    super(apiClient, store);
    this.apiClient = apiClient;
    this.store = store;
    this.excelIO = new IO();
    this.projectsService = projectsService;
    this.eventTrackerService = eventTrackerService;
  }

  clearUploadQueue() {
    this.store.dispatch(clearQueue());
  }

  async clearTagging(project, document) {
    const result = await this.apiClient.post(
      `/projects/${project.uuid}/documents/${document.uuid}/clearTagging`
    );
    this.store.dispatch(updateDocument(result.response.document));
    this.projectsService.getProjectDocumentSummary(project).catch(console.log);
    this.projectsService.loadCurrentProject(project.uuid).catch(console.log);
  }

  async downloadCSV(project, document) {
    const result = await this.apiClient.get(
      `/projects/${project.uuid}/documents/${document.uuid}/exportCsv`
    );
    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    const fileName = [...document.fileName.split('.').slice(0, -1), 'csv'].join(
      '.'
    );

    saveAs(blob, fileName);
  }

  async tagDocument(project, document, tags) {
    const taggingDetails = tags.map(
      ({
        documentType,
        splitFrom,
        splitTo,
        sheet,
        asOnDate,
        periodFrom,
        periodTo
      }) => {
        if (isDate(periodFrom)) {
          periodFrom.setDate(1);
        }

        if (isDate(periodTo)) {
          periodTo.setMonth(periodTo.getMonth() + 1);
          periodTo.setDate(0);
        }

        return {
          documentType,
          splitFrom,
          splitTo,
          sheet,
          documentDetails: null,
          asOnDate: getUTCOrNull(asOnDate),
          periodFrom: getUTCOrNull(periodFrom),
          periodTo: getUTCOrNull(periodTo)
        };
      }
    );

    const taggedDocumentInfo = await this.apiClient.post(
      `/projects/${project.uuid}/documents/${document.uuid}/tag`,
      {
        taggingDetails
      }
    );

    taggingDetails.forEach((taggingDetail) =>
      this.eventTrackerService.track(DocumentTagged, {
        ...taggingDetail,
        project,
        document
      })
    );

    await this.projectsService.loadCurrentProjectDocuments(project.uuid);
    await this.projectsService
      .getProjectDocumentSummary(project)
      .catch(console.log);
    await this.projectsService
      .loadCurrentProject(project.uuid)
      .catch(console.log);
    return taggedDocumentInfo;
  }

  async updateTagDocument(project, document, tags) {
    const taggingDetails = tags.map(
      ({
        documentType,
        splitFrom,
        splitTo,
        sheet,
        asOnDate,
        periodFrom,
        periodTo
      }) => {
        if (isDate(periodFrom)) {
          periodFrom.setDate(1);
        }

        if (isDate(periodTo)) {
          periodTo.setMonth(periodTo.getMonth() + 1);
          periodTo.setDate(0);
        }

        return {
          documentType,
          splitFrom,
          splitTo,
          sheet,
          documentDetails: null,
          asOnDate: getUTCOrNull(asOnDate),
          periodFrom: getUTCOrNull(periodFrom),
          periodTo: getUTCOrNull(periodTo)
        };
      }
    );
    await this.apiClient.put(
      `/projects/${project.uuid}/documents/${document.uuid}/tag`,
      {
        taggingDetails
      }
    );
    const currentProjectDocuments = await this.projectsService.loadCurrentProjectDocuments(project.uuid);
    const currentDocument = currentProjectDocuments?.response['documents'] && currentProjectDocuments?.response['documents'].find(doc => doc.uuid === document.uuid);
    currentDocument && this.store.dispatch(setCurrentDocument(currentDocument));
    await this.projectsService
      .getProjectDocumentSummary(project)
      .catch(console.log);
    await this.projectsService
      .loadCurrentProject(project.uuid)
      .catch(console.log);
  }

  async reExtractDocument(project, document) {
    await this.apiClient.post(
      `/projects/${project.uuid}/documents/${document.uuid}/reExtract`
    );
  }

  getDocumentFileUrlObject(project, document) {
    return {
      url: `${apiConfig.baseUrl}/projects/${project.uuid}/documents/${document.uuid}/file`,
      token: `Bearer ${authSelector(this.store.getState()).token}`
    };
  }

  async getDocumentFile(project, document) {
    return this.apiClient.get(
      `/projects/${project.uuid}/documents/${document.uuid}/file`,
      {
        responseType: 'blob'
      }
    );
  }

  async getJsonFromXLDocumentFile(project, document) {
    const fileData = await this.getDocumentFile(project, document);
    return new Promise((resolve, reject) => {
      this.excelIO.open(
        fileData,
        (json) => {
          resolve(json);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async deleteDocument(project, document) {
    const result = await this.apiClient.delete(
      `/projects/${project.uuid}/documents/${document.uuid}`
    );
    this.eventTrackerService.track(DocumentDeleted, {
      project,
      document
    });

    this.store.dispatch(deleteDocument(result.response.document));
    this.projectsService.getProjectDocumentSummary(project).catch(console.log);
    this.projectsService.loadCurrentProject(project.uuid).catch(console.log);
  }

  async clearChildDocumentTagging(project, document) {
    const result = await this.apiClient.delete(
      `/projects/${project.uuid}/documents/${document.uuid}/clearChildDocumentTagging`
    );
    this.eventTrackerService.track(ClearedChildDocumentTagging, {
      project,
      document
    });
    this.store.dispatch(deleteDocument(document));
    this.projectsService.getProjectDocumentSummary(project).catch(console.log);
    this.projectsService.loadCurrentProject(project.uuid).catch(console.log);
    return result;
  }

  async loadCurrentDocument(projectUuid, documentUuid) {
    const result = await this.apiClient.get(
      `/projects/${projectUuid}/documents/${documentUuid}`
    );
    this.store.dispatch(setCurrentDocument(result.response.document));
    return result.response.document;
  }

  async saveWorkbookData({
    project,
    document,
    data,
    ccConfig,
    mfRentRollConfig,
    commercialRentRollConfig,
    useMonthlyData = false,
    rowReverseConfig,
    hideBackdropLoader = false,
    isAutoSave = false,
    isTenantFiltered,
    isShowToastr = true,
  }) {
    try {
      const wbData = updateWorkBookSheetName(data, false);
      const payload = {
        workbook: wbData,
        ccConfig,
        mfRentRollConfig,
        commercialRentRollConfig,
        rowReverseConfig,
        useMonthlyData,
        isAutoSave,
        isTenantFiltered
      };
      const buffer = strToU8(JSON.stringify(payload));
      const compressedPayload = compressSync(buffer, { level: 6, mem: 8 });

      const promise = this.apiClient.post(
        `/projects/${project.uuid}/documents/${document.uuid}/workbook`,
        { payload: compressedPayload },
      );

      if (!hideBackdropLoader) {
        await loaderManager(
          promise,
          () => this.store.dispatch(showBackdrop('Saving...')),
          () => this.store.dispatch(hideBackdrop())
        );
      }

      this.eventTrackerService.track(WorkbookSaved, {
        project,
        document
      });

      {
        isShowToastr && this.store.dispatch(
          showToastr('success', 'Successfully saved workbook', 'top')
        );
      }
      this.projectsService.loadCurrentProject(project.uuid).catch(console.log);
      this.projectsService.loadCurrentProjectDocuments(project.uuid).catch(console.log);
    } catch (e) {
      console.error(e);
      this.store.dispatch(showToastr('danger', 'Error in saving workbook', 'top'));
    }
  }

  async loadWorkbookData(project, document) {
    const result = await this.apiClient.get(
      `/projects/${project.uuid}/documents/${document.uuid}/workbook`
    );
    const updatedWorkbookData = updateWorkBookSheetName(
      result.response.workbook
    );

    this.store.batchDispatch([
      setWorkbookData(updatedWorkbookData),
      setProjectDictionaryExists(result.response.projectDictionaryExists),
      setTemplateTag(result.response.templateTag),
      setAssetType(result.response.assetType)
    ]);

    const chargeCodesConfig = result.response.chargeCodesConfiguration.ccConfig;
    const chargeCodesMappedConfig =
      result.response.chargeCodesConfiguration.chargeCodeConfig;
    const { useMonthlyData } = result.response;

    const {
      response: {
        mfRentRollConfig: { floorPlan, unitStatus, unitMixSummary },
        commercialRentRollConfig: { leaseType, tenantType, occupancyStatus }
      }
    } = result;

    if (!isNil(chargeCodesConfig)) {
      this.store.dispatch(
        setChargeCodeConfig({ ...chargeCodesConfig, ...chargeCodesMappedConfig })
      );
    }

    if (!isNil(unitStatus)) {
      this.store.batchDispatch([
        setUnitStatus(unitStatus.config),
        setUnitTypeDropdown(unitStatus.unitStatusDropdown),
        setTenantNameConfig(unitStatus.tenantNameUnitStatusConfig)
      ]);
    }

    if (!isNil(unitMixSummary)) {
      this.store.batchDispatch([
        setLeaseTypeDropDown(unitMixSummary.leaseTypeList),
        setUnitMixSummary(unitMixSummary.summary)
      ]);
    }

    if (!isNil(floorPlan)) {
      this.store.batchDispatch([
        setFloorPlan(floorPlan.config),
        setFloorPlanBedMapping(floorPlan.floorPlanBedMapping),
        setFloorPlanBathMapping(floorPlan.floorPlanBathMapping)
      ]);
    }

    if (!isNil(occupancyStatus)) {
      this.store.dispatch(setRetailOccupancy(occupancyStatus.config));
      this.store.dispatch(
        setRetailOccupancyMapping(occupancyStatus.occupancyStatuses)
      );
    }

    if (!isNil(leaseType)) {
      this.store.dispatch(setRetailLeaseTypeConfig(leaseType.config));
      this.store.dispatch(setRetailLeaseTypeDropDown(leaseType.leaseTypes));
    }

    if (!isNil(tenantType)) {
      this.store.batchDispatch([
        setRetailTenantTypeConfig(tenantType.config),
        setRetailTenantTypeDropDown(tenantType.tenantTypes),
        setRetailMajorTenantTypePercent(
          tenantType.commercialMajorTenantTypePercent
        )
      ]);
    }

    this.store.batchDispatch([
      setStaticChargeCode(
        result.response.chargeCodesConfiguration.staticChargeCodes
      ),
      setStaticChargeCodeMapping(
        result.response.chargeCodesConfiguration
          .staticChargeCodeToCalculatedColumnsMap || []
      ),
      setChargeCodeUnitTypes(
        result.response.chargeCodesConfiguration.unitsDropdown
      ),
      isTaggedPeriodEqualsMLReturnedPeriod(
        result.response.isEqualTaggedPeriodToMLPeriod
      ),
      setUseMonthlyData(useMonthlyData)
    ]);

    return result.response.workbook;
  }

  async loadWorkbookHeadersList(project, document) {
    const result = await this.apiClient.get(
      `/projects/${project.uuid}/documents/${document.uuid}/columnHeaders`
    );
    this.store.dispatch(setColumnHeadersList(result.response.columnHeaders));
    return result.response.columnHeaders;
  }

  async createDocuments(project, fileList) {
    const files = [];
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }

    const responses = await Promise.all(
      files.map((file) => {
        const id = uploadId++;
        this.store.dispatch(
          addStatus(
            id,
            file.name,
            DocumentsService.getDocumentTypeFromName(file.name)
          )
        );

        const formData = new FormData();
        formData.append('file', file);

        return this.apiClient
          .post(`/projects/${project.uuid}/documents`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((r) => {
            this.store.batchDispatch([
              updateStatus(id, 'success'),
              setDocument(r.response.document)
            ]);
            this.projectsService
              .getProjectDocumentSummary(project)
              .catch(console.log);
            this.projectsService
              .loadCurrentProject(project.uuid)
              .catch(console.log);
            this.eventTrackerService.track(DocUploaded, {
              projectName: project.name,
              ...r.response.document
            });
            return r;
          })
          .catch((e) => {
            this.store.batchDispatch([
              updateStatus(id, 'failed'),
              updateErrorMessage(id, e.message)
            ]);
            throw e;
          });
      })
    );
    return responses;
  }

  async loadExtractedData(projectUuid, documentUuid) {
    const result = await this.apiClient.get(
      `/projects/${projectUuid}/documents/${documentUuid}/finalExtractedData`
    );
    const data = result.response.extractedData;
    this.store.batchDispatch([
      setExtractedData(data.data),
      setProjectDictionaryExists(result.response.projectDictionaryExists),
      setTemplateTag(result.response.templateTag),
      setAssetType(result.response.assetType)
    ]);
  }

  async getAllDocuments() {
    const endPoint = '/admin/documents';
    const maxRowCountPerPage = 1000;
    await this.fetchList(
      endPoint,
      maxRowCountPerPage,
      setAllDocuments,
      processDocumentList
    );
  }

  async getCompanyDocuments(companyUuid) {
    const options = {
      queryParams: {
        companyUuid
      }
    };
    const endPoint = '/admin/documents';
    const maxRowCountPerPage = 1000;
    await this.fetchList(
      endPoint,
      maxRowCountPerPage,
      setAllDocuments,
      processDocumentList,
      null,
      options
    );
  }

  async getAdminDocumentFile(company, project, document) {
    const result = await this.apiClient.get(
      `/admin/companies/${company.uuid}/project/${project.uuid}/document/${document.uuid}/file`,
      {
        responseType: 'blob'
      }
    );
    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, document.fileName);
  }

  async getDocumentFileBlob(reportUuid, project, document) {
    const result = await this.apiClient.get(
      `/shared/reports/${reportUuid}/projects/${project.uuid}/documents/${document.uuid}/file`,
      {
        responseType: 'blob'
      }
    );
    return new Blob([result], { type: 'text/csv;charset=utf-8' });
  }

  async getWorkbookData(reportUuid, project, document) {
    let workbookBlob = null;
    const result = await this.apiClient.get(
      `/shared/reports/${reportUuid}/projects/${project.uuid}/extractedDocuments/${document.uuid}/workbook`
    );

    const workbookData = updateWorkBookSheetName(result.response.workbook);
    const fileName = document.fileName
      ? document.fileName.replace(/(\.[^/.]+)+$/, '')
      : document.fileName;

    if (workbookData !== null) {
      workbookBlob = new Promise((resolve, reject) => {
        this.excelIO.save(
          workbookData,
          (blob) => {
            resolve(blob);
          },
          (err) => {
            reject(err);
          }
        );
      }).then((blob) => blob);
    }

    return { fileName, workbookBlob };
  }

  async getTaggedDocumentList(reportUuid, project) {
    const result = await this.apiClient.get(
      `/shared/reports/${reportUuid}/projects/${project.uuid}/documents`
    );
    return result.response.documents;
  }

  async downloadWorkbookWithDocumentsZip({
    project,
    reportUuid,
    xlsxBlob,
    documents,
    canDownloadDocument
  }) {
    const fileName = `${project.name}-report.xlsx`;
    if (!canDownloadDocument) {
      saveAs(xlsxBlob, fileName);
    } else {
      const zip = new JSZip();
      const docBlobs = await Promise.all(
        documents.map((document) =>
          this.getDocumentFileBlob(reportUuid, project, document)
        )
      );
      const documentList = await this.getTaggedDocumentList(
        reportUuid,
        project
      );
      const validateDocuments = documentList.filter(
        (document) =>
          // eslint-disable-next-line no-prototype-builtins
          document?.hasOwnProperty('taggingData') &&
          Reflect.ownKeys(document.taggingData).length > 0
      );
      const workbookData = await Promise.all(
        validateDocuments.map((document) =>
          this.getWorkbookData(reportUuid, project, document)
        )
      );

      zip.file(`Reports/${fileName}`, xlsxBlob);
      workbookData.forEach(({ fileName, workbookBlob }) => {
        if (workbookBlob !== null) {
          zip.file(`Extracted Documents/${fileName}.xlsx`, workbookBlob);
        }
      });
      documents.forEach((document, index) => {
        zip.file(`Original Documents/${document.fileName}`, docBlobs[index]);
      });
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${project.name}.zip`);
      });
    }
  }

  async updateDocument(project, document, values) {
    const endPoint = `/projects/${project.uuid}/documents/${document.uuid}`;
    const result = await this.apiClient.put(endPoint, { document: values });
    this.eventTrackerService.track(DocumentRenamed, {
      document,
      updatedValues: values
    });
    await this.projectsService.loadCurrentProjectDocuments(project.uuid);
    this.store.dispatch(updateDocument(result.response.document));
    return result.response.document;
  }

  async updateDocumentValidationStatus(project, document, options = {}) {
    await this.apiClient.put(
      `/projects/${project.uuid}/documents/${document.uuid}/updateValidationStatus`, options
    );
  }
}

export default DocumentsService;
