import React, { useCallback, useState, useEffect } from 'react';
import {
  Bar, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine
} from 'recharts';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import useSvgToImageDownload from '../../../hooks/useSvgToImageDownload';
import { getRandomColor, displayNumberFormat, displayCurrencyFormat } from '../../../utils/utils';
import style from './DocExtractionAffixChart.module.scss';
import IconButton from '../../core/Button/IconButton';

function DocExtractionAffixBarStackChart({
  data,
  defaultChartColor = [],
  filterChart,
  xAxisKeyName = "",
  width = 460,
  height = 300,
  showLineChart,
  lineChartKeyName,
  yAxisLabelName = ""
}) {
  const [chartColors] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [getPng, { ref, handleDownload }] = useSvgToImageDownload();

  const setColor = () => {
    data && data.forEach(() => {
      chartColors.push(getRandomColor());
    });
    setShowChart(true);
  };

  useEffect(() => {
    setColor();
  }, []);

  const onDownloadClick = useCallback(async () => {
    const png = await getPng();
    handleDownload(png);
  }, [handleDownload, getPng]);

  const setCartColor = useCallback((colIndex) => (
    defaultChartColor.length > 0 ? defaultChartColor[colIndex] : chartColors[colIndex])
  );

  const ComposedBarChartComponent = useCallback(() => (
    <div className={style.chartContainer}>
      <ComposedChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 0, right: 30, left: 20, bottom: 20
        }}
        ref={ref}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKeyName} interval={0} height={50} angle={-45} textAnchor="end" />
        <YAxis allowDataOverflow label={{ value: yAxisLabelName, angle: -90, position: 'insideLeft' }} />
        <Tooltip formatter={(value, name) => (name === 'Avg. SqFt' ? displayNumberFormat(value) : displayCurrencyFormat(value))} />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        {
          filterChart && filterChart.map((keyName, index) => <Bar key={keyName} dataKey={keyName} stackId="barStack" fill={setCartColor(index)} />)
        }
        {
          showLineChart && <Line type="monotone" dataKey={lineChartKeyName} stroke="#E0C25C" />
        }

      </ComposedChart>
      <div className={`${style.downloadBtnContainer} ${style.downloadBarStack}`}>
        <IconButton icon={faDownload} onClick={onDownloadClick} className={style.downloadImageBtn} text="" />
      </div>
    </div>
  ));


  return (
    <div className={style.chartWrapper}>
      {showChart && <ComposedBarChartComponent />}
    </div>
  );
}

export default DocExtractionAffixBarStackChart;
