import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  companyTemplates: {},
  currentTemplateUuid: ''
};

const [
  {
    setCompanyTemplates, upsertCompanyTemplate, setCurrentTemplate, removeCompanyTemplate
  },
  reducer
] = createDuck('autouw/companyTemplates', {
  _initialState: initialState,
  setCompanyTemplates: companyTemplates => state => ({
    ...state,
    companyTemplates: normalize(companyTemplates, 'uuid')
  }),
  setCurrentTemplate: companyTemplateUuid => state => ({
    ...state,
    currentTemplateUuid: companyTemplateUuid
  }),
  upsertCompanyTemplate: companyTemplate => state => ({
    ...state,
    companyTemplates: {
      ...state.companyTemplates,
      [companyTemplate.uuid]: companyTemplate
    }
  }),
  removeCompanyTemplate: companyTemplate => state => {
    const templateList = { ...state.companyTemplates };
    Reflect.deleteProperty(templateList, companyTemplate.uuid);
    return ({
      ...state,
      companyTemplates: templateList
    });
  }
});

export {
  setCompanyTemplates,
  upsertCompanyTemplate,
  setCurrentTemplate,
  removeCompanyTemplate
};

export default reducer;
