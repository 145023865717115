import BaseCommand from './BaseCommand';

class SelectColumnCommand extends BaseCommand {
  run(spreadsheet, options) {
    const sheet = spreadsheet.getSheet();
    sheet.setSelection(0, sheet.getActiveColumnIndex(), sheet.getRowCount(), 1);
  }
}

const selectColumnCommand = SelectColumnCommand.get();

export default selectColumnCommand;
