import React, { useCallback, useState } from 'react';
import ProspectPopups from '../ProspectPopups/ProspectPopups';

const defaultLabel = (
  <span className="text-primary" style={{ cursor: 'pointer' }}>Edit Prospect</span>
);
const EditProspect = ({ label = defaultLabel, prospect }) => {
  const [showEditProspectForm, setShowEditProspectForm] = useState(false);
  const onEdit = useCallback(() => {
    setShowEditProspectForm(true);
  }, []);

  return (
    <>
      <div onClick={() => onEdit()}>
        {label}
      </div>
      <ProspectPopups 
        showEditProspect={showEditProspectForm} 
        prospect={prospect} 
        onHide={() => setShowEditProspectForm(false)} 
      />
    </>
  );
};

export default EditProspect;
