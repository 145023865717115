import React, { useState, useEffect, useCallback } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row, Container } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { withServices } from 'reaf';
import SwitchButton from '../../core/SwitchButton/SwitchButton';
import IconButton from '../../core/Button/IconButton';
import FeatureFlagPopups from '../FeatureFlagPopups/FeatureFlagPopups';


const FeatureFlagsList = ({ apiClient }) => {
  const [showFeatureFlagPopup, setShowFeatureFlagPopup] = useState(false);
  const [featureFlags, setFeatureFlags] = useState([]);

  const refetchFeatureFlags = useCallback(() => {
    apiClient.get('/admin/featureFlag').then((response) => {
      setFeatureFlags(response.response);
    });
  }, []);

  useEffect(() => {
    refetchFeatureFlags();
  }, []);

  const onChange = useCallback((isActive, { uuid }) => {
    apiClient
      .put(`/admin/featureFlag/${uuid}`, { isActive: !isActive })
      .then(refetchFeatureFlags);
  }, []);

  const onAddFeatureFlag = useCallback(() => {
    setShowFeatureFlagPopup(true);
  }, []);

  const hidePopups = useCallback(
    () => {
      setShowFeatureFlagPopup(false);
      refetchFeatureFlags();
    },
    [refetchFeatureFlags],
  );

  return (
    <>
       <FeatureFlagPopups
        onHide={hidePopups}
        showFeatureFlagPopup={showFeatureFlagPopup}
      />
      <Container fluid className="p-2 pb-3">
        <Row className="align-items-center">
          <Col sm="auto">
            <h5>Feature Flags</h5>
          </Col>
          <Col sm="auto" className="pl-0 tourIconBtn">
            <IconButton
              title="Add Feature Flag" className="btn-circle btn-shadow"
              variant="outline-secondary" size="sm" icon={faPlus}
              onClick={e => {
                onAddFeatureFlag();
              }
              }
              id="addFeatureFlag"
            />
          </Col>
          <Col />
        </Row>
      </Container>
      <div className="table-pagination-position-static">
        <BootstrapTable pagination striped hover condensed data={featureFlags}>
          <TableHeaderColumn
            dataField="uuid"
            isKey
            dataAlign="right"
            width="50"
            dataFormat={(uuid) => uuid.substr(uuid.length - 4, uuid.length)}
          >
            #
          </TableHeaderColumn>
          <TableHeaderColumn dataField="featureName" dataSort>
            Feature Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="isActive"
            dataFormat={(isActive, featureFlag) => (
              <SwitchButton
                isActive={isActive}
                onChange={() => onChange(isActive, featureFlag)}
                offLabel=""
                onLabel=""
              />
            )}
          >
            Is Active
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    </>
  );
};
export default withServices('apiClient')(FeatureFlagsList);
