import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import ProspectInvoiceFormComponent from './ProspectInvoiceFormComponent';
import messages from '../../../../locales';

const ProspectInvoiceFormSchema = Yup.object().shape({
  prospect: Yup.object().shape({
    numberOfLicenses: Yup.number().min(1).typeError('Please provide valid number of licenses').required('Please provide number of licenses'),
    amountPerLicense: Yup.number().typeError('Please provide a valid amount per license').required(),
    totalAmount: Yup.number().integer().required()
  })
});


function ProspectInvoiceForm({
  onSubmitSuccess, bindSubmission, prospect, prospectsService, toastrService
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      await prospectsService.sendProspectInvoice(prospect, values);
      const message = messages.toastMessage.SEND_INVOICE;
      onSubmitSuccess();
      toastrService.success(message);
    } catch (error) {
      actions.setStatus({ error: error.message });
      toastrService.error(error.message);
    }
    actions.setSubmitting(false);
  }
  return (
    <Formik
      initialValues={prospect}
      validationSchema={ProspectInvoiceFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <ProspectInvoiceFormComponent 
        {...formikProps} 
        prospect={prospect} 
        bindSubmission={bindSubmission} 
      />}
    />
  );
}

export default withServices('prospectsService', 'toastrService')(ProspectInvoiceForm);
