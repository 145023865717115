import { createDuck } from 'reaf';

const initialState = {
  featureFlags: {}
};

const [{ setAllFeatureFlags }, reducer] = createDuck('autouw/featureFlags', {
  _initialState: initialState,

  setAllFeatureFlags: (featureFlags) => (state) => ({
    ...state,
    featureFlags
  })
});

export { setAllFeatureFlags };

export default reducer;
