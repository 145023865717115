import React from 'react';
import { Formik } from 'formik';
import { withServices } from 'reaf';
import VideoTutorialsFormComponent from './VideoTutorialsFormComponent';
import messages from '../../../../locales';
import { VideoTutorialsFormSchema } from './VideoTutorialFormSchema';

function VideoTutorialsForm({
  onSubmitSuccess, bindSubmission, videoTutorial, videoTutorialsService, toastrService
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      await videoTutorialsService.createVideoTutorial(values);
      onSubmitSuccess();
      const message = messages.toastMessage.CREATE_VIDEO_TUTORIAL;
      toastrService.success(message);
    } catch (error) {
      const errorMessage = 'Video could not be saved.';
      actions.setStatus({ error: errorMessage });
    }
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={videoTutorial}
      validationSchema={VideoTutorialsFormSchema}
      onSubmit={onSubmit}
      // eslint-disable-next-line react/no-children-prop
      children={formikProps => 
        <VideoTutorialsFormComponent {...formikProps} bindSubmission={bindSubmission} />
      }
    />
  );
}

export default withServices('videoTutorialsService', 'toastrService')(VideoTutorialsForm);
