import React, { useState } from "react";
import { Form, Field } from 'formik';
import { apiConfigFrequencyDropdown, userDefTypeDropdown } from '../../../constants';
import ReactSelect from 'react-select';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

function ApiFrequencyFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, selectedFrequency, setSelectedFrequency, ...form
}) {
  const [selectedUserDefType, setSelectedUserDefType] = useState(userDefTypeDropdown[0]);
  bindSubmission(submitForm, isSubmitting);

  return (
    <div id="ApiFrequencyFormComponent">
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <Form>
        <div id="frequencyField" className="form-group">
          <label htmlFor="frequency">Frequency</label>
          <ReactSelect
            placeholder="Select frequency..." 
            value={selectedFrequency} 
            name="frequency" 
            options={apiConfigFrequencyDropdown.map(dropdownOption => ({ value: dropdownOption.value, label: dropdownOption.label }))}
            onChange={(frequency) => {
              setSelectedFrequency(frequency);
              form.setFieldValue('frequency', frequency.value);
            }}
          />
          <FieldErrorMessage name="frequency" />
        </div>
        <div id="userDefTypeField" className="form-group d-none">
          <label htmlFor="userDefType">User Defined Type</label>
          <ReactSelect
            placeholder="Select user defined type..." 
            value={selectedUserDefType} 
            name="userDefType" 
            options={userDefTypeDropdown.map(dropdownOption => ({ value: dropdownOption.value, label: dropdownOption.label }))}
            onChange={(type) => {
              setSelectedUserDefType(type);
              form.setFieldValue('userDefType', type.value);
            }}
          />
          <FieldErrorMessage name="userDefType" />
        </div>

      </Form>
    </div>
  )
}

export default ApiFrequencyFormComponent;