import React from 'react';
import Footer from '../../app/Footer/Footer';
import AnonymousHeader from '../../app/AnonymousHeader/AnonymousHeader';
import clikLogo from '../../../assets/images/clikai-logo.svg'
import style from './LoggedOutUserPage.module.scss';


function LoggedOutUserPage({ children, noFooter, noHeader = false, isTrial = false,
  onScheduleAppointment = () => { }, canLogout = false }) {
  return (
    <div className="page">
      {
        !noHeader ? (
          isTrial ? (
            <div className="container">
              <div className="row m-2 my-3 justify-content-between">
                <div className="col-xs-6 trialHeader">
                  <img alt="Clik Logo" src={clikLogo} className="img-responsive img-fluid" />
                </div>
                <button type="button" className={`btn btn-outline-success ${style.appointmentButton}`} onClick={onScheduleAppointment}>
                  Schedule an Appointment
                </button>
              </div>
            </div>
          )
            : <AnonymousHeader canLogout={canLogout} />
        ) : null
      }
      <div className="vertical-section overflow-scroll">
        {children}
      </div>
      {
        !noFooter &&
        <Footer isTrial />
      }
    </div>
  );
}

export default LoggedOutUserPage;
