import React, { useEffect } from 'react';
import './GoogleLoginButton.scss';

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  useEffect(() => {
    window.gapi.load('auth2', () => {
      window.gapi.signin2.render('google-signin', {
        scope: 'profile email',
        width: 240,
        height: 50,
        longtitle: true,
        theme: 'light',
        onsuccess: onSuccess,
        onfailure: onFailure
      });
    });
  }, []);

  return (
    <div className="g-signin2" id="google-signin" data-onsuccess="onSignIn" data-theme="dark" />
  );
};

export default GoogleLoginButton;
