import React, { useState, useCallback } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faCopy as copyIcon } from '@fortawesome/free-solid-svg-icons/faCopy';
import { withServices } from 'reaf';
import { faClipboardCheck as copyIconCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import IconButton from '../../core/Button/IconButton';
import styles from './ClipBoardInput.module.scss';
import { ReportLinkCopiedToClipboard } from '../../../constants/eventTrackerMessage';

function ClipBoardInput({ text, eventTrackerService, hideInputControl = false }) {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = useCallback(() => {
    setIsCopied(true);
    eventTrackerService.track(ReportLinkCopiedToClipboard, { reportLink: text });
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, []);

  return (
    <InputGroup className={`mb-3 ${styles.clipboardInput}`}>
      {!hideInputControl && (
        <FormControl
          placeholder="Recipient's username"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          value={text}
          className={`${styles.inputBorder}`}
        />
      )}
      <InputGroup.Append className={`${styles.check}`}>
        <CopyToClipboard text={text}>
          <IconButton
            id="RefreshProjectDocumentButton"
            title="Copy Link"
            onClick={onCopyText}
            className={`btn-xs ${isCopied ? styles.check : ''}`}
            icon={isCopied ? copyIconCheck : copyIcon}
          />
        </CopyToClipboard>
      </InputGroup.Append>
    </InputGroup>
  );
}

export default withServices('eventTrackerService')(ClipBoardInput);
