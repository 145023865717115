/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import * as PropTypes from 'prop-types';
import { noopFunc } from '../../../../utils/noop';
import { useBindCallback } from '../../../../hooks/useBindCallback';
import SheetIcon from '../../Button/SheetIcon';

export default function SpreadSheetToolbarButton({ title, action, onClick = noopFunc, className = '', ...rest }) {
  const handleClick = useBindCallback(onClick, action);
  return (
    <SheetIcon title={title} className={className}  onClick={handleClick} {...rest} />
  );
}

SpreadSheetToolbarButton.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
