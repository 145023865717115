import { createDuck } from 'reaf';

const initialState = {
  appMetaData: {}
};

const [
  { setAppMetaData },
  reducer
] = createDuck('autouw/metadata', {
  _initialState: initialState,

  setAppMetaData: appMetaData => state => ({
    ...state,
    appMetaData
  })
});

export {
  setAppMetaData
};

export default reducer;
