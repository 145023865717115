/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Formik } from 'formik';
import { saveAs } from 'file-saver';
import * as Yup from 'yup';
import messages from '../../../../locales';
import UserlogqueryFormComponent from './UserlogqueryFormComponent';
import { withServices } from 'reaf';


function UserlogqueryForm({
  onSubmitSuccess, bindSubmission, projectsService, csvEndEndPoint
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      const options = {
        params: {
          _search: {
            createdFrom: values.startDate,
            createdTo: values.endDate,
            'User.Companies.name': values.company?.label
          }
        }
      }
      projectsService.downloadProjectCSV(csvEndEndPoint, options, 'userlog.csv')
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error });
    }
    actions.setSubmitting(false);
  }
  return (
    <Formik
      initialValues={{
        startDate: '',
        endDate: '',
        company: ''
      }}
      onSubmit={onSubmit}
      component={
        formikProps => <UserlogqueryFormComponent {...formikProps} bindSubmission={bindSubmission} />
      }
  />
  );
}

export default withServices('projectsService')(UserlogqueryForm);
