import React ,{useState} from 'react';
import {
    Row, Col, ListGroup, Badge, OverlayTrigger, Popover 
} from 'react-bootstrap';
import AssetTypeBadge from '../AssetTypeBadge/AssetTypeBadge';
import SwitchButton from "../../core/SwitchButton/SwitchButton";
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faInfo as infoIcon } from '@fortawesome/free-solid-svg-icons/faInfo';
import IconButton from '../../core/Button/IconButton';
import { withServices } from "reaf";
import WithTooltip from '../../core/WithTooltip/WithTooltip';

function TemplateModelListItem({
  model, template, templateModelService, onDownloadModel, onDownloadReportTemplate, 
  onDeleteTemplateModel, onEditModel, onModelInformation,
}) {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleButtonClick = () => {
    setPopoverVisible(!popoverVisible);
  };

  const handleClosePopover = () => {
    setPopoverVisible(false);
  };

  const generatePopoverOverlay = (model) => (
    <Popover
      id="popover-trigger-hover-focus"
      title="Model Information"
      visible={popoverVisible}
      onClose={handleClosePopover}
    >
      <strong>Model Name</strong> {model.modelName}<br/>
      <strong>File Path</strong> {model.modelFilePath}<br/>
      <strong>Updated At</strong> {model.updatedAt}<br/>
    </Popover>
  );

  function ToggleButton({ isActive, title }) {
    const [isActiveToggle, setIsActiveToggle] = React.useState(isActive)

    const onToggle = React.useCallback(async () => {
        setIsActiveToggle(!isActiveToggle);
        await templateModelService.updateGlobalModelConsolidatedModelAccess(template, model, !isActiveToggle);
    }, [isActiveToggle, templateModelService, template, model]);

    return (
        <div className="d-flex justify-content-around align-items-center">
            <label>{title}</label>
            <div>
                <SwitchButton
                    isActive={isActiveToggle}
                    onChange={onToggle}
                />
            </div>
        </div>
    );
  }

  const assetType = model.assetType &&
  (<AssetTypeBadge key={model.assetType} AssetType={model.assetType} />);

  return (
    <ListGroup.Item>
      <Row>
        <Col>
          <WithTooltip tooltipText={model?.modelFilePath}>
            <div>
              {model.modelName}
              {assetType}
            </div>
          </WithTooltip>
          <Badge pill variant="info"></Badge>
        </Col>
        <Col>
            <ToggleButton
                isActive={model.allowConsolidatedModel}
                title="Allow Download consolidated model"
            />
        </Col>
        <Col xs lg="4">
          <IconButton
            className="btn btn-default btn-sm"
            variant="outline-primary"
            onClick={() => onDownloadModel(model)}
            title="Download Model"
            text="Model Template"
            icon={faDownload}
          />

          {
            !!model.hasReportTemplateFile && <IconButton
              className="ml-2 btn btn-default btn-md"
              variant="outline-primary"
              onClick={() => onDownloadReportTemplate(model)}
              title="Download Report Template"
              text="Report Template"
              icon={faDownload}
            />
          }
        </Col>
        <Col xs lg="3">
          <>
            <IconButton
              className="btn btn-default btn-sm"
              title="Delete Report Template"
              variant="outline-danger"
              onClick={() => onDeleteTemplateModel(model)}
              icon={faTrash}
              text="Delete"
            />
            <IconButton
              className="btn btn-default btn-sm"
              title="Update Report Template"
              variant="outline-primary"
              onClick={() => onEditModel(model)}
              icon={faPencilAlt}
              text="Update"
            />
            <IconButton
              className="btn btn-default btn-sm"
              title="Model Information"
              variant="outline-primary"
              onClick={() => onModelInformation(model)}
              icon={infoIcon}
            />
          </>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default withServices('templateModelService')(TemplateModelListItem);
