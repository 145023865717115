import React from 'react';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import ProspectsList from '../../app/ProspectsList/ProspectsList';

function SuperAdminProspectList() {
  return (
    <SuperAdminDashboard>
      <ProspectsList isSuperAdmin/>
    </SuperAdminDashboard>
  );
}

export default SuperAdminProspectList;
