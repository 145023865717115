import React, { useCallback, useState, useRef, useEffect } from 'react';
import { omit, pick, sortBy, isNil } from 'lodash';
import { Form, Field, FieldArray, getIn } from 'formik';
import { Badge } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import * as moment from 'moment';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faTrash as deleteProjectIcon } from '@fortawesome/free-solid-svg-icons/faTrash';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import DatePicker from '../../core/DatePicker/DatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FILE_TYPES, DocumentFileTypes, PROPERTY_TYPES } from '../../../constants';
import './DocumentTaggingFormComponent.scss';
import IconButton from '../../core/Button/IconButton';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import { appendClassName, isOSBaseType } from '../../../utils/utils';
import style from './DocumentTaggingFormComponent.module.scss';
import DocumentsService from '../../../services/DocumentsService';

const ErrorMessage = ({ name, errors }) => (
  <Field
    name={name}
    render={() => {
      const error = getIn(errors, name);
      return error ? (
        <div className="text-danger">
          <small>{error}</small>
        </div>
      ) : null;
    }}
  />
);

function ToggleButtonGroupControlled({ options, ...otherFieldProps }) {
  return (
    <ToggleButtonGroup
      className="toggleButtonGroup btn-block d-flex justify-content-between align-items-center"
      {...otherFieldProps}
    >
      {options.map((docType) => (
        <ToggleButton
          key={docType.key}
          title={docType.label}
          value={docType.key}
        >
          {docType.badgeText}
          {docType?.isBeta && <Badge className='ml-2 position-absolute beta-bedge' variant="success">Beta</Badge>}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

function DocumentTaggingFormComponent({
  status,
  isSubmitting,
  touched,
  document,
  onCancel,
  onSheetChange,
  onResetSheetSelection,
  sheetNames,
  docType,
  showRentRollOption,
  values,
  currentActiveSheetName,
  setFieldValue,
  isValid,
  errors,
  company,
  project,
  defaultValues,
  isValidating
}) {
  const oldActiveSheetName = useRef();
  let arrayHelpersVar = null;
  const [minPeriodToDate, setMinPeriodToDate] = useState();
  const [enabledAccord, setEnabledAccord] = useState(0);

  useEffect(() => {
    if (sheetNames.length) {
      if (currentActiveSheetName) {
        if (oldActiveSheetName.current !== currentActiveSheetName) {
          const activeSheetIndex =
            sheetNames.indexOf(currentActiveSheetName) + 1;
          const value = values.taggingDetails[enabledAccord];
          const currentActiveSheetIndex = activeSheetIndex || value.sheet;
          oldActiveSheetName.current = currentActiveSheetName;
          setFieldValue(
            `taggingDetails.${enabledAccord}.sheet`,
            currentActiveSheetIndex
          );
        }
      } else {
        setFieldValue(`taggingDetails.${enabledAccord}.sheet`, 1);
      }
    }
  }, [
    setFieldValue,
    values,
    sheetNames,
    currentActiveSheetName,
    enabledAccord
  ]);

  const fileTypeIcon = (document) => {
    const docType = DocumentsService.getDocumentType(document);
    const icon = FILE_TYPES[docType] ? FILE_TYPES[docType].icon : '';
    return <img src={icon} alt="file icon" />;
  };

  const addMoreTaggingForm = useCallback(() => {
    arrayHelpersVar && arrayHelpersVar.push(defaultValues);
    setEnabledAccord(values.taggingDetails.length);
  }, [values.taggingDetails.length, defaultValues]);

  const changePeriodTo = useCallback(
    (changedValues, index) => {
      const value = values.taggingDetails[index];

      setFieldValue(`taggingDetails.${index}.period`, changedValues.period);
      const period = changedValues.period || value.period;
      const periodFrom = changedValues.periodFrom || value.periodFrom;
      if (periodFrom) {
        setFieldValue(
          `taggingDetails.${index}.periodTo`,
          moment(periodFrom).clone().toDate()
        );
      }
      if (!period || !periodFrom) {
        return;
      }
      const periodToDateObj = moment(periodFrom)
        .clone()
        .add(period - 1, 'months')
        .toDate();
      setFieldValue(`taggingDetails.${index}.periodTo`, periodToDateObj);
    },
    [setFieldValue, values]
  );

  const changeSheet = useCallback(
    (activeSheetIndex) => {
      onSheetChange(sheetNames[activeSheetIndex - 1]);
    },
    [onSheetChange, sheetNames]
  );

  const getDocumentTypeOptions = useCallback((showRentRollOption) => {
    const omitType = showRentRollOption() ? ['NONE'] : ['NONE', 'RENT_ROLL'];
    const visibleDocType = omit(
      pick(DocumentFileTypes, company.documentTypes),
      omitType
    );
    const validDocTypes = Reflect.ownKeys(visibleDocType).map(
      (fileType) => DocumentFileTypes[fileType]
    );
    return sortBy(validDocTypes, 'index');
  }, []);

  const renderSelectedDates = useCallback((taggingDetail) => {
    const getFormatedDate = (str) => moment(new Date(str)).format('MMM YY');

    if (taggingDetail.documentType === 'RENT_ROLL') {
      return (
        taggingDetail.asOnDate && (
          <span>{getFormatedDate(taggingDetail.asOnDate)}</span>
        )
      );
    } else {
      return (
        <>
          {taggingDetail.periodFrom && (
            <span>{getFormatedDate(taggingDetail.periodFrom)}</span>
          )}{' '}
          {taggingDetail.periodTo && (
            <span>{getFormatedDate(taggingDetail.periodTo)}</span>
          )}
        </>
      );
    }
  }, []);

  const onAccordClick = useCallback(
    (currentActiveIndex) => {
      const activeAccord =
        enabledAccord === currentActiveIndex ? null : currentActiveIndex;
      setEnabledAccord(activeAccord);
      if (activeAccord !== null) {
        const value = values.taggingDetails[activeAccord].sheet;
        onSheetChange(sheetNames[value - 1]);
      }
    },
    [enabledAccord, values, sheetNames, onSheetChange]
  );

  const renderSelectedDocType = useCallback(
    (taggingDetail) =>
      DocumentFileTypes[taggingDetail.documentType] && (
        <span>{DocumentFileTypes[taggingDetail.documentType].badgeText}</span>
      )
  );

  const allowTaggingForCommercials=
    [PROPERTY_TYPES.RETAIL.key,PROPERTY_TYPES.OFFICE.key,PROPERTY_TYPES.INDUSTRIAL.key];

  return (
    <div className={`${style.formContainer}`}>
      <div className="tagDocument-paneHeader d-flex justify-content-between align-items-center">
        <div className={style['title']}>
          <h3>Tag Document</h3>
          <h4
            className={appendClassName(style['fileName'], 'text-secondary')}
            title={document.fileName}
          >
            {fileTypeIcon(document)}
            {document.fileName}
          </h4>
        </div>
        <IconButton
          id="AddMoreTaggingForm"
          title={
            isValid
              ? 'Add more tags'
              : 'Fill valid details before adding more tags'
          }
          onClick={addMoreTaggingForm}
          disabled={!isValid}
          className={`addIconButton m-0 ${style.addIconButton}`}
          variant="light"
          icon={faPlus}
        />
      </div>
      <Form className={style['taggingForm']}>
        <div className="taggingDocument-wrapper">
          {status && status.error && (
            <div className="alert alert-danger m-2">
              <p className="mb-0">{status.error}</p>
            </div>
          )}
          <Accordion
            className="tagDocAccordion"
            defaultActiveKey={0}
            activeKey={enabledAccord}
          >
            <FieldArray
              name="taggingDetails"
              render={(arrayHelpers) => {
                arrayHelpersVar = arrayHelpers;
                return values.taggingDetails.map((taggingDetail, index) => (
                  <Card key={index}>
                    <Card.Header className={appendClassName("position-relative", style.buttonHeader)}>
                      <Accordion.Toggle
                        as={Button}
                        onClick={() => onAccordClick(index)}
                        className={`${
                          enabledAccord === index
                            ? 'rstcustom-collapseButton'
                            : 'rstcustom-expandButton'
                        } accordion-button d-flex justify-content-between align-items-center`}
                        variant="link"
                        eventKey={index}
                      >
                        <div className="badges-tags">
                          {renderSelectedDocType(taggingDetail)}
                          {renderSelectedDates(taggingDetail)}
                        </div>
                        {
                          !!index && (
                            <IconButton
                              title="Delete Tag"
                              disabled={!index}
                              onClick={() => arrayHelpers.remove(index)}
                              className="addIconButton m-0"
                              variant="light"
                              icon={deleteProjectIcon}
                            />
                          )
                        }
                        
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index}>
                      <Card.Body>
                        <div className="form-group" id="DocumentTypeField">
                          <div className="tagLabel-heading">Document Type</div>
                          <ToggleButtonGroupControlled
                            name={`taggingDetails.${index}.documentType`}
                            value={taggingDetail.documentType}
                            options={getDocumentTypeOptions(showRentRollOption)}
                            onChange={(value) =>
                              setFieldValue(
                                `taggingDetails.${index}.documentType`,
                                value
                              )
                            }
                          />

                          <ErrorMessage
                            name={`taggingDetails[${index}].documentType`}
                            errors={errors}
                          />
                        </div>

                        {docType === FILE_TYPES.PDF.key && (
                          <div id="SplittingSection">
                            <div className="tagLabel-heading">Splitting</div>
                            <div className="form-row">
                              <div className="col form-group">
                                <label htmlFor="splitFrom">Page From</label>
                                <Field
                                  className="form-control"
                                  name={`taggingDetails.${index}.splitFrom`}
                                  type="number"
                                  placeholder="Page Number"
                                />
                                <FieldErrorMessage
                                  name={`taggingDetails[${index}].splitFrom`}
                                />
                              </div>
                              <div className="col form-group">
                                <label htmlFor="splitTo">Page To</label>
                                <Field
                                  id="splitTo"
                                  className="form-control"
                                  name={`taggingDetails.${index}.splitTo`}
                                  type="number"
                                  placeholder="Page Number"
                                />
                                <FieldErrorMessage
                                  name={`taggingDetails[${index}].splitTo`}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {docType === FILE_TYPES.XL.key && (
                          <div id="SheetSelectionSection">
                            <div className="tagLabel-heading">
                              Sheet Selection
                            </div>
                            <div className="form-group">
                              <label htmlFor="sheet">Sheet</label>
                              <Field
                                className="form-control"
                                name={`taggingDetails.${index}.sheet`}
                                component="select"
                                onChange={(e) => {
                                  const activeSheetIndex = Number(
                                    e.target.value
                                  );
                                  changeSheet(activeSheetIndex);
                                }}
                              >
                                {sheetNames.map((sheetName, index) => (
                                  <option key={sheetName} value={index + 1}>
                                    {sheetName}
                                  </option>
                                ))}
                              </Field>
                              <FieldErrorMessage
                                name={`taggingDetails[${index}].sheet`}
                              />
                            </div>
                          </div>
                        )}

                        <div id="DatesSection">
                        {taggingDetail.documentType === DocumentFileTypes.RENT_ROLL.key && ( 
                            <div id="AsOnDateSection">
                              <div className="form-group asOfDateInputRow mb-1">
                                <label htmlFor="asOnDate">As Of Date</label>
                                <Field
                                  name={`taggingDetails.${index}.asOnDate`}
                                  id="asOnDate"
                                  component={DatePicker}
                                  selected={taggingDetail.asOnDate || null}
                                  className="form-control"
                                  dateFormat="MM/dd/yyyy"
                                  placeholderText="MM/DD/YYYY"
                                />
                                <FieldErrorMessage
                                  name={`taggingDetails[${index}].asOnDate`}
                                />
                              </div>
                            </div>
                          )}
                          {!isNil(taggingDetail.documentType) &&
                            isOSBaseType(taggingDetail.documentType) && (
                              <div id="PeriodDatesSection">
                                <div>
                                  <div className="tagLabel-heading">Period</div>
                                  <div className="form-group">
                                    <label htmlFor="period">
                                      Select Period Months
                                    </label>
                                    <Field
                                      name={`taggingDetails.${index}.period`}
                                      component="select"
                                      className="form-control"
                                      onChange={(e) => {
                                        const period = e.target.value;
                                        changePeriodTo({ period }, index);
                                      }}
                                    >
                                      <option value="">Select Period</option>
                                      <option value="3">3 Months</option>
                                      <option value="6">6 Months</option>
                                      <option value="9">9 Months</option>
                                      <option value="12">12 Months</option>
                                    </Field>
                                    <FieldErrorMessage
                                      name={`taggingDetails[${index}].period`}
                                    />
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="col form-group mb-1">
                                    <label htmlFor="periodFrom">
                                      Period From
                                    </label>
                                    <Field
                                      name={`taggingDetails.${index}.periodFrom`}
                                      component={DatePicker}
                                      selected={
                                        taggingDetail.periodFrom || null
                                      }
                                      className="form-control"
                                      dateFormat="MM/yyyy"
                                      placeholderText="MM/YYYY"
                                      showMonthYearPicker
                                      onSelect={(periodFrom) => {
                                        changePeriodTo(
                                          {
                                            periodFrom,
                                            period: taggingDetail.period
                                          },
                                          index
                                        );
                                        setMinPeriodToDate(periodFrom);
                                      }}
                                    />
                                    <FieldErrorMessage
                                      name={`taggingDetails[${index}].periodFrom`}
                                    />
                                  </div>
                                  <div className="col form-group mb-1">
                                    <label htmlFor="periodTo">Period To</label>
                                    <Field
                                      name={`taggingDetails.${index}.periodTo`}
                                      component={DatePicker}
                                      selected={taggingDetail.periodTo||null}
                                      className="form-control"
                                      dateFormat="MM/yyyy"
                                      placeholderText="MM/YYYY"
                                      minDate={minPeriodToDate||null}
                                      showMonthYearPicker
                                    />
                                    <FieldErrorMessage
                                      name={`taggingDetails[${index}].periodTo`}
                                    />
                                  </div>
                                </div>
                                <div />
                                <div />
                              </div>
                            )}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ));
              }}
            />
          </Accordion>
        </div>
        <div className="tagDocumentFooter d-flex justify-content-end">
          <Button
            type="button"
            variant="outline-danger"
            size="sm"
            className="mr-2"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="reset"
            onClick={onResetSheetSelection}
            variant="outline-secondary"
            size="sm"
            className="mr-2"
          >
            Reset
          </Button>
          <FormSubmitButton
            id="TaggingFormSubmitButton"
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </div>
      </Form>
    </div>
  );
}

export default DocumentTaggingFormComponent;
