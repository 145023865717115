import { createDuck } from 'reaf';
import { normalize } from './utils';

const initialState = {
  companyProjectExporters: {},
  currentProjectExporterUuid: ''
};

const [
  { setCompanyProjectExportersList, setCurrentProjectExporter },
  reducer
] = createDuck('autouw/companyProjectExporters', {
  _initialState: initialState,

  setCompanyProjectExportersList: companyProjectExporters => state => ({
    ...state,
    companyProjectExporters: normalize(companyProjectExporters, 'uuid')
  }),
  setCurrentProjectExporter: currentProjectExporterUuid => state => ({
    ...state,
    currentProjectExporterUuid: currentProjectExporterUuid
  })

});

export { setCompanyProjectExportersList, setCurrentProjectExporter };

export default reducer;
