import React, { useMemo } from 'react';
import { withServices } from 'reaf';
import { get } from 'lodash';
import {
  faCheck, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../core/Button/IconButton';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import Link from '../../core/Link/Link';
import { usDateFormat } from '../../../utils/utils';
import UserRoleBadge from '../UserRoleBadge/UserRoleBadge';

function CompanyList({
  queries,
  apiClient,
  router,
  projectsService,
}) {

  const { data, loading, pageCount, onDownloadCSV, fetchData } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    routeName: 'usersPage',
    resourceEndPoint: '/admin/users',
    fileName: 'Users.csv',
    csvDownloadEndPoint: '/admin/users/downloadCSV'
  });

  const columns = useMemo(() => [
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'Last Name',
      accessor: 'lastName'
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Company',
      accessor: 'Companies.name',
      Cell: ({ row: { original } }) => get(original, 'Companies[0].name', 'Super Admin')
    },
    {
      Header: 'Active',
      disableFilters: true,
      accessor: 'isActive',
      Cell: ({ row: { original: { isActive } } }) => (
        <IconButton
          className={isActive ? 'btn-outline-success' : 'btn-outline-danger'}
          style={{ width: '100%' }}
          icon={isActive ? faCheck : faTimesCircle}
          title={isActive ? 'Active' : 'Inactive'}/>
      )
    },
    {
      Header: 'Role',
      accessor: 'UserRole.role',
      Cell: ({ row: { original: { UserRole } } }) => <UserRoleBadge userRole={UserRole.role} />
    },
    {
      Header: 'Created At',
      disableFilters: true,
      accessor: 'createdAt',
      Cell: ({ row: { original: { createdAt } } }) => (
        <div style={{ width: '100%', textAlign: 'center', fontWeight: createdAt ? '700': '500' }}>
          {createdAt === null ? '--' : usDateFormat(createdAt)}
        </div>
      )
    },
    {
      Header: 'Links',
      accessor: 'links',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original: { Companies, UserRole: { role } } } }) => (
        <>
          {role === 'SUPER_ADMIN' ? (<span>Super Admin</span>) : <Link
            route="superAdminUserManagement"
            params={{ companyUUID: Companies[0].uuid }}
            className="btn btn-link btn-xs"
          >
            List company&rsquo;s users
          </Link>}
        </>
      )
    },

  ], [data]);

  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        onDownloadCSV={onDownloadCSV}
        queries={queries}
        loading={loading}
        fetchData={fetchData}
        pageCount={pageCount}
      />
    </>
  );
}


export default withServices('apiClient', 'router', 'projectsService')(CompanyList);
