import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { components } from 'reaf';
import AddCardDetailComponent from './AddCardDetailComponent';

function AddCardDetailForm({ authService, store, redirectOnSuccess = true }) {

  const onSubmit = useCallback(async (values, actions) => {
    // onSubmit logic here
  }, []);

  return (
    <Formik
      initialValues={null}
      validationSchema={null}
      onSubmit={onSubmit}
      component={
        formikProps => (
          <AddCardDetailComponent
            {...formikProps}
          />
        )}
    />
  );
}

const AddCardDetailFormSimple = components.withServices('store', 'authService')(AddCardDetailForm);

export const AddCardDetailFormMemo = React.memo(AddCardDetailFormSimple);

export default AddCardDetailFormMemo;
