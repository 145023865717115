import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import DynamicAssetTypeFormComponent from './DynamicAssetTypesFormComponent';
import messages from '../../../../locales';
import { allowedMLBaseClasses } from '../../../constants';

const DyanmicAssetTypesFormSchema = Yup.object().shape({
    dynamicAssetType: Yup.object().shape({
    assetType: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid asset type name.'),
    label: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid label.'),
    badgeText: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid badge text.'),
    baseClass: Yup.mixed().oneOf(
        allowedMLBaseClasses,
        'Please select a valid base class.'
      )
  })
});


function DynamicAssetTypesForm({
  onSubmitSuccess, bindSubmission, dynamicAssetType, dynamicAssetTypesService, toastrService, showCreateDynamicAssetType=false,
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      showCreateDynamicAssetType ? await dynamicAssetTypesService.createDynamicAssetTypes(values) :  await dynamicAssetTypesService.editDynamicAssetTypes(values);
      onSubmitSuccess();
      const message = showCreateDynamicAssetType ? messages.toastMessage.CREATE_DYNAMIC_ASSET_TYPE :  messages.toastMessage.EDIT_DYNAMIC_ASSET_TYPE ;
      toastrService.success(message);
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }
  return (
    <Formik
      initialValues={dynamicAssetType}
      validationSchema={DyanmicAssetTypesFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <DynamicAssetTypeFormComponent 
        {...formikProps} 
        bindSubmission={bindSubmission} 
        allowedMLBaseClasses={allowedMLBaseClasses} 
      />
      }
    />
  );
}


export default withServices('dynamicAssetTypesService', 'toastrService')(DynamicAssetTypesForm);