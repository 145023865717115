import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormSubmitButton from '../../core/Button/FormSubmitButton';

function FormModal({
  show, onHide, onSubmit, isSubmitting, children, title, entity = '', showFooter = true, size = "md", className = '', submitButtonText = 'Save', readOnlyAccess = false
}) {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" size={size} dialogClassName={className}>
      <Modal.Header closeButton>
        {
          title &&
          (
            <Modal.Title>
              {title}
            </Modal.Title>
          )
        }
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      {
        showFooter && (
          <Modal.Footer>
            <Button variant="outline-danger" size="sm" type="button" disabled={isSubmitting} onClick={onHide}>Cancel</Button>
            <FormSubmitButton id={`${entity}FormSaveButton`} title={submitButtonText} isValid isSubmitting={isSubmitting} onClick={onSubmit} disabled={readOnlyAccess} />
          </Modal.Footer>
        )}

    </Modal>
  );
}

export default FormModal;
