import React from 'react';
import { Field } from 'formik';
import FieldErrorMessage from '../../app/FieldErrorMessage/FieldErrorMessage';

const FormikTextArea = ({
  name, label, ...props
}) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <Field
      id={name}
      className="form-control"
      component="textarea"
      name={name}
      {...props}
    />
    <FieldErrorMessage name={name} />
  </div>
);

export default FormikTextArea;
