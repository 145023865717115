import BaseCommand from './BaseCommand';
import { sheetIndex } from '../../../../constants';

class CopyCommand extends BaseCommand {
  run(dataSpreadSheet) {
    const { spreadsheet } = dataSpreadSheet;
    const { sourceSheetIndex } = sheetIndex;
    if (spreadsheet.getActiveSheetIndex() === sourceSheetIndex) {
      const copiedRange = spreadsheet.getSheet(sourceSheetIndex).getSelections()[0];
      dataSpreadSheet.clipboardData.setCopiedRange(copiedRange);
    } else {
      dataSpreadSheet.clipboardData.reset();
    }
  }
}

const copyCommand = CopyCommand.get();

export default copyCommand;