import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Col, Row, Container } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {
  faEdit, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { noop } from 'lodash';
import { getVideoTutorialsListSelector } from '../../../store/selectors';
import { displayDateFormat } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';
import VideoTutorialPopups from './VideoTutorialPopups';
import messages from '../../../../locales';

const initialValues = {
  videoTutorial: {
    title: '',
    id: '',
    description: '',
    category: ''
  }
};

function VideoTutorialsList({ videoTutorialList, videoTutorialService, toastrService }) {
  const regexFilter = useMemo(() => ({
    type: 'RegexFilter',
    placeholder: 'Filter',
    delay: 1000
  }), []);

  const [showAddVideoFormPopup, setShowAddVideoFormPopupPopup] = useState(false);
  const [showEditVideoFormPopup, setShowEditVideoFormPopupPopup] = useState(false);
  const [showDeleteVideoConfirmation, setShowDeleteVideoConfirmation] = useState(false);
  const [video, setVideo] = useState(initialValues);

  const hidePopups = useCallback(
    () => {
      setShowAddVideoFormPopupPopup(false);
      setShowEditVideoFormPopupPopup(false);
    },
    [],
  );

  const onAddVideoTutorial = useCallback(() => {
    setVideo(initialValues);
    setShowAddVideoFormPopupPopup(true);
  }, [initialValues]);

  const onDeleteConfirm = useCallback(async () => {
    try {
      await videoTutorialService.deleteVideoTutorial(video);
      toastrService.success(messages.toastMessage.DELETE_VIDEO__TUTORIAL);
    } catch (e) {
      toastrService.error(e.message);
    }
    setShowDeleteVideoConfirmation(false);
  }, [video]);

  const onDelete = useCallback(async (video) => {
    setVideo(video);
    setShowDeleteVideoConfirmation(true);
  }, [video]);

  const onEdit = useCallback(async (video) => {
    setVideo(video);
    setShowEditVideoFormPopupPopup(true);
  }, [video]);

  return (
    <>
      <Container fluid className="p-2 pb-4">
        <Row className="align-items-center">
          <Col sm="auto">
            <h5 className="mb-0">Video Tutorials</h5>
          </Col>
          <Col sm="auto" className="pl-0 tourIconBtn">
            <IconButton
              title="Add Video Tutorials" className="btn-circle btn-shadow"
              variant="outline-secondary" size="sm" icon={faPlus}
              onClick={e => onAddVideoTutorial()}
              id="AddVideoTutorial"
            />
          </Col>
          <Col />
        </Row>
      </Container>

      <VideoTutorialPopups 
        onHide={hidePopups}
        onEditSuccess={noop}
        showEditVideoForm={showEditVideoFormPopup}
        showDeleteConfirm={showDeleteVideoConfirmation}
        showAddVideoFormPopup={showAddVideoFormPopup}
        video={video}
        onDeleteConfirm={onDeleteConfirm}
        onDeleteCancel={() => setShowDeleteVideoConfirmation(false)}
      />

      <BootstrapTable
        data={videoTutorialList}
        tableStyle={{ background: '#fafafa' }}
        condensed
        hover
      >
        <TableHeaderColumn
          dataField="uuid"
          isKey
          hidden
          dataSort
          width="60"
          dataAlign="center"
        >
          S.N.
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="title"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Video Title
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="description"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Video Description
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="videoId"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Video Id
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="duration"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Video Duration
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="createdAt"
          dataFormat={displayDateFormat}
          dataSort
        >
          Created On
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="action"
          dataSort
          dataFormat={(cell, row) => (
            <>
              <IconButton
                onClick={() => onEdit(row)}
                variant="outline-primary"
                title="Edit Video"
                className="smallIconButton"
                placement="left"
                icon={faEdit} />
              <IconButton
                onClick={() => onDelete(row)}
                variant="outline-danger"
                title="Delete Video"
                placement="right"
                className="smallIconButton"
                icon={faTrash} />
            </>
          )}
        >
          Action
        </TableHeaderColumn>
      </BootstrapTable>

    </>
  );
}

const mapStateToProps = state => ({
  videoTutorialList: getVideoTutorialsListSelector(state)
});


export default connect(mapStateToProps)(withServices('videoTutorialService', 'toastrService')(VideoTutorialsList));
