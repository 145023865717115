import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import { displayCurrencyFormat } from '../../../../utils/utils';
import './DocExtractionRetailTenantTypeChart.scss';

export default function DocExtractionRetailTenantTypeChart({ data }) {
  return (
    <>
      <div className="tenantTypeChart">
        <BarChart
          width={460}
          height={300}
          data={data}
          margin={{
            top: 20, right: 30, left: 20, bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={(value) => displayCurrencyFormat(value)} />
          <Legend />
          <Bar dataKey="Rent" fill="#8884d8" />
          <Bar dataKey="Recoveries" fill="#82ca9d" />
        </BarChart>
      </div>
    </>
  );
}
