import { faClock as pendingIcon } from '@fortawesome/free-solid-svg-icons/faClock';
import { faCheckCircle as successIcon } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle as failedIcon } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faFileMedicalAlt as convertedIcon } from '@fortawesome/free-solid-svg-icons/faFileMedicalAlt';
import { faBan as cancelledIcon } from '@fortawesome/free-solid-svg-icons/faBan';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as Yup from 'yup';
import { appConfig } from '../config';
import pdfFileIcon from '../assets/images/pdf.svg';
import xlFileIcon from '../assets/images/excel-icon.svg';
import textFileIcon from '../assets/images/file-symbol.svg';
import messages from '../../locales';


export const DocumentFileTypes = {
  OPERATING_STATEMENT: {
    key: 'OPERATING_STATEMENT', baseType: 'OPERATING_STATEMENT', label: 'Operating Statement', badgeText: 'OS', index: 1, isBeta: false,
  },
  RENT_ROLL: {
    key: 'RENT_ROLL', baseType: 'RENT_ROLL', label: 'Rent Roll', badgeText: 'RR', index: 2, isBeta: false,
  },
  OS_BUDGETED: {
    key: 'OS_BUDGETED', baseType: 'OPERATING_STATEMENT', label: 'OS Budgeted', badgeText: 'OSB', index: 3, isBeta: false,
  },
  OS_PROFORMA: {
    key: 'OS_PROFORMA', baseType: 'OPERATING_STATEMENT', label: 'Proforma Statement', badgeText: 'PS', index: 4, isBeta: false,
  },
  OS_FULL: {
    key: 'OS_FULL', baseType: 'OS_FULL', label: 'Cash Flow', badgeText: 'CF', index: 5, isBeta: true,
  },
  NONE: {
    key: 'NONE', baseType: 'NONE', label: 'None', badgeText: '', index: 99, isBeta: false,
  }
};

export const CompanyTypeFilters = {
  'Enterprise': true,
  'Trial': false
}

export const CompanyTypes = {
  ENTERPRISE: 'Enterprise',
  TRIAL: 'Trial',
  ALL: 'ALL'
}

export const CompanyStatusRowColor = {
  EXPIRED: '#cc2a114f',
  EXPIRING: '#eab6769e'
}

export const EXCLUDE_DOC_FILE_TYPES = [
  'NONE'
];

export const OS_MONTHS_DATA = [1,2,3,4,5,6,7,8,9,10,11,12];

export const DICTIONARY_KEYS = {
  HEAD: 'head',
  CATEGORY: 'Category',
  SUBCATEGORY: 'subCategory',
  NOT_CLASSIFIED: 'notClassified',
}

export const TICKET_STATUS = {
  BACKLOG: 'BACKLOG',
  INPROGRESS: 'INPROGRESS',
  CLOSED: 'CLOSED',
}

export const USER_ACTION_TYPES = {
  USER_ENABLED: 'Enabled',
  USER_DISABLED: 'Disabled',
  USER_CREATED: 'Created',
  USER_DELETED: 'Deleted',
  REMOVED_DOCUMENT_TAGGING: 'Removed Document Tagging',
  DOCUMENT_UPLOADED: 'Uploaded Document',
  DOCUMENT_DELETED: 'Deleted Document',
  WORKBOOK_SAVED: 'Saved Workbook',
  DOCUMENT_TAGGED: 'Tagged Document',
  DOCUMENT_UPDATED: 'Updated Document',
  DOCUMENT_VALIDATED: 'Validated Document',
  DOCUMENT_INVALIDATED: 'Invalidated Document',
  DOCUMENT_TAGGING_UPDATED: 'Updated Document Tagging',
  PROJECT_TAGGING_REMOVED: 'Removed Project Tagging',
  PROJECT_CREATED: 'Created Project',
  PROJECT_DELETED: 'Deleted Project',
  PROJECT_UPDATED: 'Updated Project',
  PROJECT_ASSUMPTION_UPDATED: 'Updated Project Assumption',
  MF_RR_UPDATED: 'Mf Rr Updated',
  DD_UPDATED : 'Dd Updated'
}

export const PROJECT_REPORT_URL_ERROR_MSG = 'Url for the Project Report seems to be expired, please ask the owner to share the Url again';

export const ProjectIsCompleted = {
  true: { key: true, label: 'COMPLETED', icon: <FontAwesomeIcon className="text-success" icon={successIcon} /> },
  false: { key: false, label: 'INCOMPLETE', icon: <FontAwesomeIcon className="text-warning" icon={pendingIcon} /> }
};

export const DoucmentValidationStatus = {
  VALIDATED: {
    key: 'VALIDATED',
    label: 'Validated',
    icon: <FontAwesomeIcon className="text-success" icon={successIcon} />
  },
  NOT_VALIDATED: {
    key: 'NOT_VALIDATED',
    label: 'Not Validated',
    icon: <FontAwesomeIcon className="text-warning" icon={pendingIcon} />
  },
}

export const API_INTEGRATION_CONFIG_KEYS = [
  'integrationConfigFile',
  'companyId',
  'companyUuid',
  'configFileName',
  'isConfigEnabled',
  'name',
  'typeOfConfig',
  'emails',
  'emailSubject',
  'updatedAt',
];

export const ExtractionJobStatus = {
  ACTIVE: {
    key: 'ACTIVE',
    label: 'Active',
    icon: <FontAwesomeIcon className="text-warning" icon={pendingIcon} />
  },
  DEFAULT: { key: 'DEFAULT', label: 'Default' },
  PENDING: {
    key: 'PENDING',
    label: 'Pending',
    icon: <FontAwesomeIcon className="text-warning" icon={pendingIcon} />
  },
  CONVERTED: {
    key: 'CONVERTED',
    label: 'Converted',
    icon: <FontAwesomeIcon icon={convertedIcon} />
  },
  SUCCESSFUL: {
    key: 'SUCCESSFUL',
    label: 'Successful',
    icon: <FontAwesomeIcon className="text-success" icon={successIcon} />
  },
  FAILED: {
    key: 'FAILED',
    label: 'Failed',
    icon: <FontAwesomeIcon className="text-danger" icon={failedIcon} />
  },
  CANCELED: {
    key: 'CANCELED',
    label: 'Cancelled',
    icon: <FontAwesomeIcon icon={cancelledIcon} />
  }
};

export const ExtractionJobStatusAdminFilter = {
  ACTIVE: { key: 'ACTIVE', label: 'Active', icon: <FontAwesomeIcon className="text-warning" icon={pendingIcon} /> },
  PENDING: { key: 'PENDING', label: 'Pending', icon: <FontAwesomeIcon className="text-warning" icon={pendingIcon} /> },
  SUCCESSFUL: {
    key: 'SUCCESSFUL',
    label: 'Successful',
    icon: <FontAwesomeIcon className="text-success" icon={successIcon} />
  },
  FAILED: { key: 'FAILED', label: 'Failed', icon: <FontAwesomeIcon className="text-danger" icon={failedIcon} /> }
};

export const PROPERTY_TYPES = {
  MULTIFAMILY: {
    key: 'MULTIFAMILY', label: 'Multi Family', badgeText: 'MF', isRentRollRestricted: false, baseClass: 'MULTIFAMILY'
  },
  STORAGE: {
    key: 'STORAGE', label: 'Storage', badgeText: 'ST', isRentRollRestricted: false, baseClass: 'MULTIFAMILY'
  },
  CONDO: {
    key: 'CONDO', label: 'Condo', badgeText: 'CND', isRentRollRestricted: false, baseClass: 'MULTIFAMILY'
  },
  MOBILE_HOME_PARK: {
    key: 'MOBILE_HOME_PARK', label: 'Mobile Home Park', badgeText: 'MHP', isRentRollRestricted: false, baseClass: 'MULTIFAMILY'
  },
  HEALTHCARE: {
    key: 'HEALTHCARE', label: 'Healthcare', badgeText: 'HC', isRentRollRestricted: false, baseClass: 'HEALTHCARE'
  },
  HOTEL: {
    key: 'HOTEL', label: 'Hotel', badgeText: 'HTL', isRentRollRestricted: true, baseClass: 'HOTEL'
  },
  OFFICE: {
    key: 'OFFICE', label: 'Office', badgeText: 'OFC', isRentRollRestricted: false, baseClass: 'COMMERCIAL'
  },
  RETAIL: {
    key: 'RETAIL', label: 'Retail', badgeText: 'RTL', isRentRollRestricted: false, baseClass: 'COMMERCIAL'
  },
  INDUSTRIAL: {
    key: 'INDUSTRIAL', label: 'Industrial', badgeText: 'IND', isRentRollRestricted: false, baseClass: 'COMMERCIAL'
  },
  PARKING_GARAGE: {
    key: 'PARKING_GARAGE', label: 'Parking Garage', badgeText: 'PG', isRentRollRestricted: true, baseClass: 'COMMERCIAL'
  },
  SENIOR_HOUSING: {
    key: 'SENIOR_HOUSING', label: 'Senior Housing', badgeText: 'SH', isRentRollRestricted: true, baseClass: 'MULTIFAMILY'
  },
  WAREHOUSE: {
    key: 'WAREHOUSE', label: 'Warehouse', badgeText: 'WH', isRentRollRestricted: false, baseClass: 'COMMERCIAL'
  },
};

export const allowedMLBaseClasses = ['MULTIFAMILY', 'OFFICE', 'RETAIL', 'HOTEL', 'SENIOR_HOUSING', 'HEALTHCARE'];

export const retailBaseClassesForExtraction = ['OFFICE', 'RETAIL', 'INDUSTRIAL', 'PARKING_GARAGE', 'WAREHOUSE'];
export const multifamilyBaseClassesForExtraction = ['MULTIFAMILY', 'STORAGE', 'CONDO', 'MOBILE_HOME_PARK', 'HEALTHCARE'];

export const USER_ROLES = {
  SUPER_ADMIN: { key: "SUPER_ADMIN", label: "Super Admin" },
  COMPANY_ADMIN: { key: "COMPANY_ADMIN", label: "Company Admin" },
  COMPANY_USER: { key: "COMPANY_USER", label: "User" },
  SUPPORT_USER: { key: "SUPPORT_USER", label: "Support User" },
  ACCOUNTS_EXECUTIVE: { key: "ACCOUNTS_EXECUTIVE", label: "Accounts Executive" },
};

export const TEMPLATE_TAGS = {
  SERVICING: { key: "SERVICING", label: "Servicing", badgeText: 'SRVC' },
  PRODUCTION: { key: "PRODUCTION", label: "Production", badgeText: 'PROD' },
  ACQUISITION: { key: "ACQUISITION", label: "Investment Sales", badgeText: 'INVT' },
  GLOBAL: { key: "GLOBAL", label: "Global", badgeText: 'GLOBAL' }
};

export const FILE_TYPES = {
  PDF: { key: 'PDF', label: 'PDF File', icon: pdfFileIcon },
  XL: { key: 'XL', label: 'Excel File', icon: xlFileIcon },
  UNKNOWN: { key: 'UNKNOWN', label: 'Unknown file', icon: textFileIcon }
};

export const PROJECT_STATUS = {
  NEW: { key: 'NEW', label: 'New' },
  OLD: { key: 'OLD', label: 'Old' }
};

export const FILE_EXT_TYPES = {
  PDF: 'PDF',
  XLSX: 'XL',
  XLS: 'XL',
  UNKNOWN: 'UNKNOWN'
};

export const PROSPECT_LICENSE_RENEWAL = {
  "MONTHLY": { key: 'MONTHLY', label: 'Monthly', months: 1 },
  "QUARTERLY": { key: 'QUARTERLY', label: ' Quarterly', months: 3 },
  'HALF-YEARLY': { key: 'HALF-YEARLY', label: ' Half-Yearly', months: 6 },
  "YEARLY": { key: 'YEARLY', label: ' Yearly', months: 12 }
};

export const PROSPECT_CONVERSION_STATUSES = {
  PENDING: { key: 'PENDING', label: 'Pending', icon: <FontAwesomeIcon className="text-warning" icon={pendingIcon} /> },
  CONVERTED: { key: 'CONVERTED', label: 'Converted', icon: <FontAwesomeIcon className="text-success" icon={successIcon} /> },
  FAILED: { key: 'FAILED', label: 'Failed', icon: <FontAwesomeIcon className="text-danger" icon={failedIcon} /> },
  CANCELED: { key: 'CANCELED', label: 'Canceled', icon: <FontAwesomeIcon className="text-danger" icon={cancelledIcon} /> }
};

export const summaryConstant = {
  OS_HEADS: [{ key: 'Income', text: 'Total Income' }, { key: 'Expense', text: 'Total Expense' }, { key: 'Capital Expense', text: 'Total Capital Expense' }],
  OS_NOI_NCF: [{ key: 'NOI', text: 'Net Operating Income' }, { key: 'NCF', text: 'Net Cash Flow' }, {}, { key: 'NOI_DIFF', text: '' }],
  WITHOUT_EXCLUDED_ITEMS: [{ key: 'header', text: 'W/O Excluded Items' }, {}, { key: 'Income', text: 'Total Income:' }, { key: 'Expense', text: 'Total Expense:' }, { key: 'Capital Expense', text: 'Total Capital Expense:' }, { key: 'NOI', text: 'Net Operating Income:' }, { key: 'NCF', text: 'Net Cash Flow:' }],
  OS_EXCLUDED_ITEM: [
    { category: 'Excluded Expense', head: 'Expense' }, { category: 'Excluded Expenses', head: 'Expense' },
    { category: 'Omitted Expense', head: 'Expense' }, { category: 'Omitted Expenses', head: 'Expense' },
    { category: 'Excluded Income', head: 'Income' }, { category: 'Excluded Incomes', head: 'Income' },
    { category: 'Omitted Income', head: 'Income' }, { category: 'Omitted Incomes', head: 'Income' },
    { category: 'Eliminated Income', head: 'Income' }, { category: 'Eliminated Incomes', head: 'Income' },
    { category: 'Eliminated Expense', head: 'Expense' }, { category: 'Eliminated Expenses', head: 'Expense' },
    { category: 'Excluded Income Items', head: 'Income' }, { category: 'Excluded Income Item', head: 'Income' },
    { category: 'Excluded Expense Items', head: 'Expense' }, { category: 'Excluded Expense Item', head: 'Expense' },
    { category: 'Excluded Capital Expense', head: 'Capital Expense' }, { category: 'Excluded Capital Expenses', head: 'Capital Expense' },
    { category: 'Omitted Capital Expense', head: 'Capital Expense' }, { category: 'Omitted Capital Expenses', head: 'Capital Expense' },
    { category: 'Eliminated Capital Expense', head: 'Capital Expense' }, { category: 'Eliminated Capital Expenses', head: 'Capital Expense' },
    { category: 'Excluded Capital Expenditure', head: 'Capital Expense' }, { category: 'Excluded Capital Expenditures', head: 'Capital Expense' },
    { category: 'Omitted Capital Expenditure', head: 'Capital Expense' }, { category: 'Omitted Capital Expenditures', head: 'Capital Expense' },
    { category: 'Eliminated Capital Expenditure', head: 'Capital Expense' }, { category: 'Eliminated Capital Expenditures', head: 'Capital Expense' },
    { category: 'Excluded Cap Ex', head: 'Capital Expense' }, { category: 'Excluded CapEx.', head: 'Capital Expense' }, { category: 'Excluded CapEx', head: 'Capital Expense' },
    { category: 'Excluded Capex', head: 'Capital Expense' }, { category: 'Excluded Capex.', head: 'Capital Expense' }, { category: 'Excluded Capex', head: 'Capital Expense' },
    { category: 'Omitted Cap Ex', head: 'Capital Expense' }, { category: 'Omitted CapEx.', head: 'Capital Expense' }, { category: 'Omitted CapEx', head: 'Capital Expense' },
    { category: 'Omitted Capex', head: 'Capital Expense' }, { category: 'Omitted Capex.', head: 'Capital Expense' }, { category: 'Omitted Capex', head: 'Capital Expense' },
    { category: 'Eliminated Cap Ex', head: 'Capital Expense' }, { category: 'Eliminated CapEx.', head: 'Capital Expense' }, { category: 'Eliminated CapEx', head: 'Capital Expense' },
    { category: 'Eliminated Capex', head: 'Capital Expense' }, { category: 'Eliminated Capex.', head: 'Capital Expense' }, { category: 'Eliminated Capex', head: 'Capital Expense' },
    { category: 'Eliminated Capital Items', head: 'Capital Expense' }, { category: 'Omit', head: 'Expense' }, { category: 'Omit', head: 'Income' }, { category: 'Not Applicable (remove)', head: 'Expense' },
    { category: 'Not Applicable (remove)', head: 'Income' }
  ]
};

export const VALID_TEMPLATE_FILE_EXTENSION = ['json', 'csv'];
export const VALID_TEMPLATE_MODEL_FILE_EXTENSION = ['xlsm'];
export const VALID_TEMPLATE_REPORT_FILE_EXTENSION = ['xlsx'];
export const VALID_DICTIONARY_FILE_EXTENSION = ['json', 'csv'];
export const VALID_INTEGRATION_CONFIG_FILE_EXTENSION = ['json', 'csv'];

export const VALID_PROJECT_EXPORTER_MAPPER_FILE_EXTENSION = ['json'];
export const VALID_PROJECT_EXPORTER_MAPPING_FUNC_FILE_EXTENSION = ['json'];
export const VALID_SUMMMARY_SHEET_TOTAL_MAPPING_FILE_EXTENSION = ['json', 'csv'];

export const VALID_PROJECT_FILE_EXTENSION = ['json', 'csv'];


export const cellNumberFormat = '#,##0.00_);(#,##0.00)';
export const USCurrencyFormat = '_("$"* #,##0.00_);_("$"* (#,##0.00);_("$"* "-"??_);_(@_)';
export const USDateFormat = 'mm/dd/yyyy;@';

export const sheetContextMenuData = ['gc.spread.insertSheet', 'gc.spread.deleteSheet', 'gc.spread.hideSheet'];

export const sheetNames = {
  sourceSheetName: 'Source Data', 
  targetSheetName: 'Extracted Data', 
  summarySheetName: 'Summary',
  workingSheetName: 'Blank Sheet', 
};

export const sheetMappings = [
  { codeName: 'Source Data', displayName: 'Source Data' },
  { codeName: 'Target Data', displayName: 'Extracted Data' },
  { codeName: 'Summary', displayName: 'Summary' },
  { codeName: 'Working Sheet', displayName: 'Blank Sheet' },
];

export const aiParserSheet = {
  name: 'Parser Sheet',
  sheetIndex: 4
}

export const aiParserIgnoredColumnsForFormatter = ['unit', 'tenant-name', 'status', 'sq-ft'];
export const aiParserNumberColumns = ['sq-ft']

export const sheetIndex = {
  sourceSheetIndex: 0, 
  targetSheetIndex: 1, 
  summarySheetIndex: 2, 
  workingSheetIndex: 3, 
  customSummarySheetIndex: 2,
  summarySheetIndexWithCustomSummary: 3
};

export const rrSheetIndex = {
  sourceSheetIndex: 0, targetSheetIndex: 1, summarySheetIndex: 2, workingSheetIndex: 3
};

export const RECENT_DAYS_COUNT = [30, 60, 90];

export const floorPlanWithRentDetailsHeader = {
  key: 'Floor Plan',
  marketRent: 'Market Rent',
  occupiedUnitCount: '#Occupied',
  occupiedMarketRent: 'Market Rent.',
  occupiedMonthlyRent: 'In-Place Rent',
  percentOfMarketRent: '% of Market Rent',
  recentFiveLeaseCount: '#Recent 5',
  recentFiveLeaseCountAvgRent: 'In Place Rent Avg',
  last90Days: 'Leases last 90 days',
  last90DaysAvgRent: 'In place Rent Avg(90 days)',
  last60Days: 'Leases last 60 days',
  last60DaysAvgRent: 'In place Rent Avg(60 days)',
  last30Days: 'Leases last 30 days',
  last30DaysAvgRent: 'In place Rent Avg(30 days)',
};
export const floorPlanWithUnitDetailsHeader = {
  key: 'Floor Plan',
  BD: 'Beds',
  BA: 'Baths',
  avgSquareFt: 'Square Feet',
  totalUnitCount: '#units',
  percentOfTotalUnits: '%of Total units',
  percentageOccupied: '%Occupied',
  percentageVacant: '%Vacant',
  percentageNonRevenue: '%Non Revenue',
  occupiedUnitCount: 'Occupied',
  vacant: 'Vacant',
  nonRevenue: 'Non Revenue',
  renovatedCount: 'Renovated',
  down: 'Down',
  unRenovated: 'Un Renovated',
};
export const recentLeaseAverageRentkey = {
  30: ['last30Days', 'last30DaysAvgRent'],
  60: ['last60Days', 'last60DaysAvgRent'],
  90: ['last90Days', 'last90DaysAvgRent'],
};
export const sheetHeaderObj = [
  { row: 0, col: 1, value: 'Property Information' },
  { row: 1, col: 1, value: 'Property Name:' },
  { row: 2, col: 1, value: 'Rent Roll As on:' }
];
export const floorPlanWithRentDetailsGroupedCol = [
  { col: 1, colCount: 2, mergeColTitle: 'ALL UNITS' },
  { col: 3, colCount: 4, mergeColTitle: 'OCCUPIED UNITS' },
  { col: 7, colCount: 8, mergeColTitle: 'RECENT LEASE (By Move-In-Date)' },
];
export const floorPlanWithUnitDetailsGroupedCol = [
  { col: 1, colCount: 6, mergeColTitle: 'UNIT INFORMATION' },
  { col: 7, colCount: 3, mergeColTitle: 'OCCUPANCY STATUS(% Of Units)' },
  { col: 10, colCount: 3, mergeColTitle: 'OCCUPANCY STATUS(No. Of Units)' },
  { col: 13, colCount: 3, mergeColTitle: 'RENOVATION STATUS' },
];
export const floorPlanWithRentDetailsFooterConfig = [
  { colKey: 'Floor Plan', type: 'Totals/Avg', col: 0 },
  { colKey: 'Market Rent', type: 'Avg', col: 1 },
  { colKey: '#Occupied', type: 'Sum', col: 2 },
  { colKey: 'Market Rent.', type: 'Avg', col: 3 },
  { colKey: 'In-Place Rent', type: 'Avg', col: 4 },
  { colKey: '% of Market Rent', type: '%OfMarketRent', col: 5 },
  { colKey: '#Recent 5', type: 'Sum', col: 6 },
  { colKey: 'In Place Rent Avg', type: 'Avg', col: 7 },
  { colKey: 'Leases last 90 days', type: 'Sum', col: 8 },
  { colKey: 'In-place Rent Avg', type: 'Avg', col: 9 },
  { colKey: 'Leases last 60 days', type: 'Sum', col: 10 },
  { colKey: 'In-place Rent Avg.', type: 'Avg', col: 11 },
  { colKey: 'Leases last 30 days', type: 'Sum', col: 12 },
  { colKey: 'In place Rent Avg', type: 'Avg', col: 13 },
];
export const floorPlanWithUnitDetailsFooterConfig = [
  { colKey: 'Floor Plan', type: 'Totals/Avg', col: 0 },
  { colKey: 'Square Feet', type: 'Avg', col: 3 },
  { colKey: '#units', type: 'Sum', col: 4 },
  { colKey: '%of Total units', type: '100', col: 5 },
  { colKey: 'Occupied', type: 'percentOfTotalUnit', col: 6 },
  { colKey: 'Vacant', type: 'percentOfTotalUnit', col: 7 },
  { colKey: 'Non Revenue', type: 'percentOfTotalUnit', col: 8 },
  { colKey: 'Occupied', type: 'Sum', col: 9 },
  { colKey: 'Vacant', type: 'Sum', col: 10 },
  { colKey: 'Non Revenue', type: 'Sum', col: 11 },
  { colKey: 'Renovated', type: 'Sum', col: 12 },
  { colKey: 'Down', type: 'Sum', col: 13 },
  { colKey: 'Un Renovated', type: 'Sum', col: 14 },
]
export const chargeCodeSummaryFooterConfig = [
  { colKey: 'Category', type: 'Total', col: 0 },
  { colKey: 'Amount', type: 'Sum', col: 1 },
  { colKey: 'chargeCode', type: '', col: 2 },
];
export const unitStatusSummaryFooterConfig = [
  { colKey: 'Occupancy Code', type: 'Total', col: 0 },
  { colKey: 'Market Rent', type: 'Sum', col: 1 },
  { colKey: 'Monthly Rent', type: 'Sum', col: 2 },
  { colKey: 'Units', type: 'Sum', col: 3 },
];
export const unitStatusSummaryHeader = {
  occupancyCodeHeader: 'Occupancy Code',
  marketRentHeader: 'Market Rent',
  monthlyRentHeader: 'Monthly Rent',
  unitsHeader: 'Units'
};

export const rrSummaryColorConfig = {
  headerBgColor: '#1d456d',
  textColor: '#fff',
  tableHeaderBgColor: '#a9d8fe',
  propertyInfoBgColor: '#e7e7e7',
  black: '#000000',
  footerBgColor: '#eae6da',
  borderColor: '#c0c0c0'
}

export const summarySheetTableTitles = {
  chargeCodeSummayTitle: 'Charge Code Summary',
  unitStatusSummaryTitle: 'Occupancy Summary',
  floorPlanWithRentDetailsTitle: 'Floor Plan Summary',
}

export const summarySheetFooterOperations = {
  colSum: 'Sum',
  colAvg: 'Avg',
  colPercentage: 'percentOfTotalUnit',
  percentOfMarketRent: '%OfMarketRent',
}

export const occupiedRentAvg = {
  marketRentAvg: 'Market Rent.',
  monthlyRentAvg: 'In-Place Rent',
  lastFiveLease: 'In Place Rent Avg',
  marketRent: 'Market Rent',
  occupiedUnits: '#Occupied',
  totalUnits: '#units',
  floorPlan: 'Floor Plan',
  last90DaysLeaseAvg: 'In-place Rent Avg',
  last60DaysLeaseAvg: 'In-place Rent Avg.',
  last30DaysLeaseAvg: 'In place Rent Avg',
}

export const summarySheetHeaders = ['Head', 'Category', 'Amount', 'Adjustments', 'Comments', 'Notes'];

export const summarySheetHeadSequence = ['Income', 'Expense', 'Capital Expense', 'NOI'];

export const KEY_CODES = {
  UNDO_KEY: 90,
  DELETE_KEY: 46,
  BACKSPACE_KEY: 8,
  MINUS_KEY: 189,
  MINUS_NUM_KEY: 109,
  PLUS_KEY: 187,
  PLUS_NUM_KEY: 107,
  I_KEY: 73,
  B_KEY: 66,
  U_KEY: 85,
  D_KEY: 68,
  F4_KEY: 115
};

export const spreadShortcutKeys = [
  { key: 'CTRL ALT +', description: 'Insert a new Row/Column' },
  { key: 'CTRL ALT -', description: 'Delete the selected Row/Column' },
  { key: 'CTRL SPACEBAR', description: 'Select current column' },
  { key: 'CTRL ENTER', description: 'Select current Row' },
  { key: 'CTRL I', description: 'Change font style to italic' },
  { key: 'CTRL B', description: 'Change font style to bold' },
  { key: 'CTRL U', description: 'Change font style to underline' },
  { key: 'CTRL ALT D', description: 'Fill same value to the selected cell' },
  { key: 'F4', description: 'Absolute Formula Reference' }
];

export const OS_FIXED_COLUMNS = [
  'S.N.',
  'Line Item',
  'Head',
  'Category',
  'Sub Category',
  'Amount',
  'Adjustments'
];

export const PROTECTED_OS_COLUMNS = [
  'S.N.',
  'Line Item',
  'Head',
  'Category',
  'Sub Category',
  'Amount',
  'Adjustments',
  'Total',
  'Notes'
];

export const RED_BACKGROUND_BASE64_IMG = 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAJQAlAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wgARCACAAIADASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAABAb/2gAMAwEAAhADEAAAAZwn2YAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH//EABQQAQAAAAAAAAAAAAAAAAAAAID/2gAIAQEAAQUCAH//xAAUEQEAAAAAAAAAAAAAAAAAAABg/9oACAEDAQE/AQH/xAAUEQEAAAAAAAAAAAAAAAAAAABg/9oACAECAQE/AQH/xAAUEAEAAAAAAAAAAAAAAAAAAACA/9oACAEBAAY/AgB//8QAFBABAAAAAAAAAAAAAAAAAAAAgP/aAAgBAQABPyEAf//aAAwDAQACAAMAAAAQ/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/wD/AP8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAYP/aAAgBAwEBPxAB/8QAFBEBAAAAAAAAAAAAAAAAAAAAYP/aAAgBAgEBPxAB/8QAFBABAAAAAAAAAAAAAAAAAAAAgP/aAAgBAQABPxAAf//Z';


export const TEMPLATE_TAG = {
  SERVICING: { key: 'SERVICING', label: 'Servicing' },
  PRODUCTION: { key: 'PRODUCTION', label: 'Production' },
  ACQUISITION: { key: 'ACQUISITION', label: 'Investment Sales' }
};

export const SUPPORT_TYPES = {
  ISSUE: { key: 'ISSUE', label: 'Issue' },
  SUPPORT: { key: 'SUPPORT', label: 'Support' }
};

export const EXCLUDED_TEMPLATES = appConfig.isFreebieApp ? {
  'c237ed0e-2b42-4864-956c-ac8841600979': 'Fannie mae Rollup'
} : {};

export const RENT_ROLL_MF_COLUMN_NAME = [
  'Floor Plan',
  'Unit Type',
  'Sq Ft'
];

export const excludedRentRollMFColValue = [
  'N/A',
  'Model',
  "",
  'Lease Charges'
];

export const chartType = {
  PIE: 'Pie Chart',
  BAR: 'Bar Chart',
  LINE: 'Line Chart'
};

export const rentRollConfigTabNames = {
  FP: 'Floor Plan',
  UNIT_STATUS: 'Unit Status',
  CHARGE_CODE: 'Charge Code'
};

export const rentRollAffixTabViewName = {
  TABLE_VIEW: 'Table View',
  CHART_VIEW: 'Chart View',
  CONFIG_VIEW: 'Config View',
  RENOVATION_VIEW: 'Renovation View',
  AFFORDABLE_VIEW: 'Affordable View'
};

export const affixChartColors = [
  '#8864ee',
  '#9c46d0',
  '#c758d0',
  '#e01e84',
  '#52d726',
  '#1baa2f',
  '#ff7300',
  '#ffaf00',
  '#5ea3d9',
  '#4b6c81',
  '#8399eb',
  '#13d5a8',
  '#58b6d5',
  '#ffec00',
  '#d5f30b',
  '#ff0000',
  '#007ed6',
  '#7cdddd'];

export const affixComponentName = {
  RENT_ROLL: 'RENT_ROLL',
  SHORTCUT_KEYS: 'SHORTCUT_KEYS',
  OPERATING_STATEMENT: 'OPERATING_STATEMENT',
  OS_BUDGETED: 'OS_BUDGETED',
  OS_FULL: 'OS_FULL',
};

export const floorPlanChartFilter = {
  SQFT: 'Unit Analysis',
  VACANCY_LOSS: 'Vacancy Loss'
};

export const floorPlanChartDataPoints = {
  SQFT: ["name", "Avg. Market Rent", "Avg. Monthly Rent", "Avg. Loss", "Avg. SqFt"],
  VACANCY_LOSS: ["name", "Market Rent", "Monthly Rent", "Vacancy Loss", "Avg. SqFt", "Avg. Loss"]
};

export const affixTotalColors = {
  RENT_ROLL: {
    MULTIFAMILY: {
      CHARGE_CODE_TOTAL: '#E5EEFF',
      TOTAL_MARKET_RENT: '#f4f4f4',
      TOTAL_MONTHLY_RENT: '#E5EEFF',
      TOTAL_UNITS: '#F9EAD7',
      AVG_SQFT: '#ffffff',
      AVG_MARKET_RENT: '#f4f4f4',
      AVG_MONTHLY_RENT: '#cdfbd9',
      BEDS_BATHS: '#FEFFBB'
    }
  }
};

export const OS_HEADS = {
  INCOME: 'Income',
  EXPENSE: 'Expense',
  CAPEX: 'Capital Expense'
};

export const OS_CHART_TYPES = {
  OPERATING_EXPENSES: 'Operating Expenses',
  NET_RENTAL_INCOME: 'Net Rental Income'
};

export const CLIK_OS_CATEGORY_MAPPING = {
  MULTIFAMILY: {
    RENTAL_INCOME: 'Rental Income',
    VACANCY_LOSS: 'Vacancy & Collection Loss'
  },
  COMMERCIAL: {
    RENTAL_INCOME: 'Gross Potential Rent',
    VACANCY_LOSS: 'Vacancy'
  },
  RETAIL: {
    RENTAL_INCOME: 'Gross Potential Rent',
    VACANCY_LOSS: 'Vacancy'
  }
};

export const tenantTypeColumnCalc = {
  TENANT_TYPE: 'Tenant Type',
  SQFT: 'Sq Ft',
  BASE_RENT: 'Base Rent',
  CAM_RE: 'CAM Reimbursement',
  TAX_RE: 'Tax Reimbursement',
  INS_RE: 'Insurance Reimbursement',
  MONTLY_RENT_CALC: 'Monthly Rent (Calc)',
  MONTLY_CAM_CALC: 'Monthly Cam Reimbursement (Calc)',
  MONTLY_TAX_CALC: 'Monthly Tax Reimbursement (Calc)',
  MONTLY_INC_CALC: 'Monthly Insurance Reimbursement (Calc)'
};

export const PASSWORD_SCHEMA = Yup.string()
  .required(messages.errors.auth.requiredPassword)
  .min(8, messages.errors.auth.passwordMinLength)
  .max(20, messages.errors.auth.passwordMaxLength)
  .matches(/[a-z]/, messages.errors.auth.passwordSmallCase)
  .matches(/[A-Z]/, messages.errors.auth.passwordUpperCase)
  .matches(/[0-9]/, messages.errors.auth.passwordNumericCase)
  .matches(/[!@#$%^&*]/, messages.errors.auth.passwordSpecialCharacter)
  .matches(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/, `Your password must be between 8 and 30 characters.
      Your password must have at least one uppercase, lowercase, number, and special symbol.`)
  .test('hasSpace', 'Password should not start or end with space.', (value = '') => value.length === value.trim().length);

export const HIDE_RENT_ROLL_ASSET_TYPES = [
  PROPERTY_TYPES.HEALTHCARE.key, PROPERTY_TYPES.HOTEL.key, PROPERTY_TYPES.PARKING_GARAGE.key,
  PROPERTY_TYPES.SENIOR_HOUSING.key
];
export const HIDE_RENT_ROLL_ASSET_TYPES_ON_TAGGING = [PROPERTY_TYPES.HOTEL.key];

export const RENT_ROLL_RESTRICTED_ASSET_TYPES = {
  HEALTHCARE: {
    key: 'HEALTHCARE', label: 'Healthcare'
  },
  PARKING_GARAGE: {
    key: 'PARKING_GARAGE', label: 'Parking Garage'
  },
  SENIOR_HOUSING: {
    key: 'SENIOR_HOUSING', label: 'Senior Housing'
  },
  HOTEL: {
    key: 'HOTEL', label: 'Hotel'
  }
};

export const reportSheetName = {
  financialSheetName: 'Financials',
  cfSummarySheetName: 'CF Summary',
  t12AnalysisSheetName: 'T-12 Analysis',
  rrWorkingSheetName: 'RR Working',
  rrRentStepsSheetName: 'Rent Steps',
  rrSummarySheetName: 'RR Summary & Charts',
  evaluationVersion: 'Evaluation Version',
  constantSheetName: 'Constants'
};

export const reportColMappings = {
  monthTotal: ['Amount', '/ Unit', '% EGI', '% Change'],
  budget: ['Amount', '/ Unit', '% EGI']
};

export const ncfSummary = {
  'Effective Gross Income': false,
  'Total Expenses': false,
  'Total Capital Expenditure': false,
  'Net Operating Income': true,
  'Net Cash Flow': true
};

export const occupancyHeader = ['Status', 'Units', '% Occupancy', 'Market Rent', 'In-Place'];
export const unitTypeHeader = ['Unit Type', 'Market Rent', 'In-Place', 'Units', 'SqFt', 'Market Rent Range', 'In-Place Range'];
export const chargeCodesHeader = ['Charge Codes', 'Amount'];
export const leaseInfo = ['Date', 'No. Of Units', ''];

export const tenantNameType = ["Office", "Down", "Admin", "Employee", "Empl", "Corporate", "Vacant", "Model", "MGR", "Courtesy Officer", "Staff", "Maint", "Maintenance", "Super", "Manager", "Supervisor"];

// eslint-disable-next-line max-len
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const usCanadaPhoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const trailingTweleveHeader = ['Head', 'Category', 'Sub Category', 'T3', 'T6', 'T9', 'T12'];

export const tableDesignStyle = {
  "Income": 'medium2',
  "Expense": 'medium3',
  'Capital Expense': 'medium5'
};

export const SOCIAL_LOGIN_TYPES = {
  GOOGLE: 'GOOGLE',
  LINKEDIN: 'LINKEDIN',
  MICROSOFT: 'MICROSOFT'
};

export const MAX_ALLOWED_EXTRACTION = 10;
export const VIDEO_TUT_URL = [
  'https://www.youtube.com/embed/TxqqKk8giJQ'
];

export const GLOBAL_TEMPLATES_UUID = {
  LOAN_SIZER: '2faf49d2-be62-4d7d-929b-89e825257e86',
  MF_SYNDICATOR_MODEL: 'f8d710d1-fcaa-4d4c-80a0-62a1466d2ef5',
  MULTIFAMILY_REPORT: '6d0d0ace-736a-4e86-817a-650192057588',
  MF_DEAL_ANALYZER: '6d7f7749-05c3-49f1-8bab-62cbe1130953'
};

export const RETAIL_CHARGE_CODE_FORMULA = {
  EMPTY: "",
  MONTHLY: "Monthly",
  ANNUAL: "Annual",
  ANNUAL_PER_SQFT: "Annual Per Sq Ft",
  MONTHLY_PER_SQFT: "Monthly Per Sq Ft"
};

export const ALLOWED_RETAIL_CC_CATEGORY = ['Other Recoveries'];

export const projectReportsConstantsMonthRows = {
  yearCol: 1,
  monthCol: 2,
  periodFromCol: 3,
  periodToCol: 4,
  monthStatusCol: 5,
  yearStartRow: 0,
  yearEndRow: 9
};

export const projectReportsConstantsYTDColRows = [{
  yearCol: 1, monthCol: 2, periodFromCol: 3, periodToCol: 4, monthStatusCol: 5, yearRow: 10
}];
export const projectReportsConstantsTrailingColRows = [{
  yearCol: 1, monthCol: 2, periodFromCol: 3, periodToCol: 4, monthStatusCol: 5, yearRow: 11
}];
export const projectReportsConstantsBudgetedColRows = [{
  yearCol: 1, monthCol: 2, periodFromCol: 3, periodToCol: 4, monthStatusCol: 5, yearRow: 12
}];
export const projectReportsConstantsPerformaColRows = [
  {
    yearCol: 1, monthCol: 2, periodFromCol: 3, periodToCol: 4, monthStatusCol: 5, yearRow: 13
  },
  {
    yearCol: 1, monthCol: 2, periodFromCol: 3, periodToCol: 4, monthStatusCol: 5, yearRow: 14
  },
  {
    yearCol: 1, monthCol: 2, periodFromCol: 3, periodToCol: 4, monthStatusCol: 5, yearRow: 15
  },
  {
    yearCol: 1, monthCol: 2, periodFromCol: 3, periodToCol: 4, monthStatusCol: 5, yearRow: 16
  },
  {
    yearCol: 1, monthCol: 2, periodFromCol: 3, periodToCol: 4, monthStatusCol: 5, yearRow: 17
  }
];

export const projectReportRRWorkingAsOnDate = {
  column: 1, row: 18
};

export const CF_UNIT_STATUS_COL_INFO = {
  column: 1,
  row: 19
};

export const projectReportTaggedDocumentColumnInfo = {
  rowIndex: 0,
  docNameColIndex : 7,
  docTypeColIndex : 8,
  docAsOfDateColIndex : 9,
  docPeriodFromColIndex : 10, 
  docPeriodToColIndex : 11, 
}

export const projectReportAssumptionColumnInfo = {
  rowIndex: 0,
  propertyKeyName : 13,
  propertyValueName : 14,
}


export const PAGINATION_DEFAULT = { pageSize: 50, pageSizes: [50, 100, 150, 200] };

export const columnDataTypeFormatters = {
  currency : USCurrencyFormat,
  string: '',
  number: 0,
  date: USDateFormat,
}

export const validDateFormats = [
  'YYYY-MM-DD',
  'YYYY_MM_DD',
  'M/D/YY',
  'M-D-YY',
  'M_D_YY',
  'MM/D/YY',
  'MM-D-YY',
  'MM_D_YY',
  'M/DD/YY',
  'M-DD-YY',
  'M_DD_YY',
  'M/D/YYYY',
  'M-D-YYYY',
  'M_D_YYYY',
  'MM/D/YYYY',
  'MM-D-YYYY',
  'MM_D_YYYY',
  'M/DD/YYYY',
  'M-DD-YYYY',
  'M_DD_YYYY',
  'M/D/YY',
  'M-D-YY',
  'M_D_YY',
  'MM/DD/YY',
  'MM-DD-YYYY',
  'MM_DD_YYYY',
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'MM_DD_YYYY',
  'DD MMM YY', 'DD MMM YYYY', 'DD MMMM YYYY', 'MMM D, YY', 'MMM D, YYYY', 'MMM DD, YYYY', 'MMMM D, YYYY', 'MMMM DD, YYYY', 'YYYY.MM.DD.HHmm', 'YYYY-MM-DD-HHmm', 'YYYY-MM-DD_HHmm', 'YYYY.MM.DD.HH.mm', 'YYYY-MM-DD-HH-mm', 'YYYY-MM-DD HH:mm', 'YYYY-MM-DD h:mm A', 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD @ h:mm A', 'YYYY.MM.DD.HHmmss', 'YYYY-MM-DD-HHmmss', 'YYYY-MM-DD_HHmmss', 'YYYY-MM-DD_HHmm.ss', 'YYYY.MM.DD.HH.mm.ss', 'YYYY-MM-DD-HH-mm-ss', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm.ss', 'YYYY-MM-DD h:mm:ss A', 'YYYY-MM-DD hh:mm:ss A', 'YYYY-MM-DD @ h:mm:ss A', 'dd MMM D YY', 'ddd MMM D YY', 'ddd MMM D YYYY', 'ddd MMM DD YYYY', 'dddd, MMM D YYYY', 'dddd, MMMM D, YYYY', 'dddd, MMMM DD, YYYY', 'ddd MMM D YY h:mm A', 'ddd MMM D YYYY h:mm A', 'ddd MMM DD YYYY h:mm A', 'dddd, MMM D YYYY h:mm A', 'dddd, MMMM D, YYYY h:mm A', 'dddd, MMMM DD, YYYY h:mm A', 'ddd MMM D YY hh:mm A', 'ddd MMM D YYYY hh:mm A', 'ddd MMM DD YYYY hh:mm A', 'dddd, MMM D YYYY hh:mm A', 'dddd, MMMM D, YYYY hh:mm A', 'dddd, MMMM DD, YYYY hh:mm A', 'ddd MMM D YY @ h:mm A', 'ddd MMM D YYYY @ h:mm A', 'ddd MMM DD YYYY @ h:mm A', 'dddd, MMM D YYYY @ h:mm A', 'dddd, MMMM D, YYYY @ h:mm A', 'dddd, MMMM DD, YYYY @ h:mm A'];

export const spreadColorConfig = { invalidCellColor: '#da3125' }
export const spreadCellFormatter = {
  staticChargeCodeHeader: USCurrencyFormat,
  rentStepMonthly: USCurrencyFormat,
  rentStepDate: USDateFormat
}

export const spreadRangeChangedActionCode = {
    dragDrop: 1,
    dragFill: 1,
    clear: 2,
    paste: 3,
    sort: 4,
    setArrayFormula: 5,
    evaluateFormula: 6
}

export const affordableRnotationOptions = {
  YES: 'Yes',
  NO: 'No'
}

export const assumptionKeysDataType = {
  'amortization': 'number',
  'cap_rate': 'number',
  'cf_haircut': 'currency',
  'cost_of_improvements': 'currency',
  'debt_yield': 'number',
  'debt_yield(ncf)': 'number',
  'debt_yield(noi)': 'number',
  'dscr': 'number',
  'dscr(ncf)': 'number',
  'estimated_property_value_criteria': 'currency',
  'funding_date': 'number',
  'index_rate': 'number',
  'inflators': 'number',
  'interest_rate': 'number',
  'interest_rate_type': 'number',
  'internal_loan_number': 'number',
  'io_period': 'number',
  'loan_term': 'number',
  'ltv': 'number',
  'maximum_ltv': 'number',
  'no_of_units': 'number',
  'property_value': 'currency',
  'purchase_price': 'currency',
  'request_loan_amount': 'currency',
  'requested_loan_amount': 'currency',
  'total_cost_basis': 'currency',
  'year_built': 'number',
  'year_renovated': 'number',
  'zip': 'number'
}

export const unitMixNonRevenueUnits = [
  'Admin/Down', 'Model', 'Non Revenue'
]

export const leaseTypeList = {
  AFFORDABLE: 'Affordable',
  MARKET_RENT: 'Market Rent',
  RENT_CONTROLLED: 'Rent Controlled',
  SECTION8: 'Section 8',
  OTHER: 'Other'
}

export const allowTaggingForCommercials=
  [PROPERTY_TYPES.RETAIL.key, PROPERTY_TYPES.OFFICE.key, PROPERTY_TYPES.INDUSTRIAL.key];

export const companyApiIntegrationTypes = {
  DEFAULT: 'Select',
  BASIC_AUTH: 'Basic Auth',
  BEARER_TOKEN: 'Bearer Token',
  API_KEY: 'Api Key'
};

export const companyApiIntegrationApiMethods = {
  DEFAULT: 'Select',
  GET: 'GET',
  POST: 'POST'
}

export const apiConfigFrequencyDropdown = [
  {label: '3_MONTHS', value: '3_MONTHS'},
  {label: '6_MONTHS', value: '6_MONTHS'},
  {label: '9_MONTHS', value: '9_MONTHS'},
  {label: '12_MONTHS', value: '12_MONTHS'}
];

export const userDefTypeDropdown = [
  {label: 'ACTUAL', value: 'ACTUAL'}
]

export const readWriteColors = {
  READ_COLOR: {variant: 'secondary', label: 'Read Access'},
  READ_WRITE_COLOR: {variant: 'success', label: 'Read & Write Access'},
}

export const ReadWriteColorIndicator = () => {
  return (
    <div className='mb-3'>
      {
        Reflect.ownKeys(readWriteColors).map((userAccess) => {
          return (
            <td >
              <Badge className='px-2 mx-1' pill key={readWriteColors[userAccess].variant}
                variant={readWriteColors[userAccess].variant}>
                {readWriteColors[userAccess].label}
              </Badge>
            </td>
          )
        })
      }
    </div>
  )
}

export const projectActionValues = [
  { value: 'selectProjectAction', label: 'Select Project Action' },
  { value: 'showBulkProjectUpload', label: 'Bulk Project Upload' },
  { value: 'showBulkProjectUpdate', label: 'Bulk Project Update' },
  { value: 'showBulkProjectJson', label: 'Bulk Project Json' },
];

