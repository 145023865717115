import React, { useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { withServices } from 'reaf';
import { connect } from 'react-redux';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';
import { Alert } from 'react-bootstrap';
import ClipBoardInput from '../ClipBoardInput/ClipBoardInput';
import './ShareReportLinkModal.scss';
import { ReportLinkSharedOnSocialMedia } from '../../../constants/eventTrackerMessage';
import { loggedInCompanySelector } from '../../../store/selectors';

function ShareReportLinkModal({
  title,
  message,
  company,
  onConfirm,
  onCancel,
  show,
  isConfirm = true,
  isClipboard = false,
  sharedReportLinkDetail,
  projectsService,
  eventTrackerService
}) {
  const buttonText = isConfirm ? 'Confirm' : 'OK';
  const [doubleClick, setDoubleClick] = useState(false);
  const [canDownloadDocument, setCanDownloadDocument] = useState(
    sharedReportLinkDetail.canDownloadDocument
  );
  const updateCanDownloadDocument = useCallback(async () => {
    const value = !canDownloadDocument;
    await projectsService.updateReportLink(sharedReportLinkDetail, {
      canDownloadDocument: value
    });
    setCanDownloadDocument(value);
  }, [canDownloadDocument, sharedReportLinkDetail]);

  const onSocialShareReportLink = useCallback(
    (platform) => () => {
      eventTrackerService.track(ReportLinkSharedOnSocialMedia, {
        platform,
        ...sharedReportLinkDetail
      });
    },
    []
  );
  const { canDownloadReportWorkbook } = company;

  return (
    <Modal show={show} onHide={onCancel} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title title={title}>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
       <Alert variant="info" className="py-1 px-2">
        <small>Please save the report to share the latest version</small>
       </Alert>

        {isClipboard ? <ClipBoardInput text={message} /> : <p>{message}</p>}
        <div className="shareOptionsContainer">
          <span className="shareOptionsText">Share via:</span>
          <div className="shareOptionsContent">
            <EmailShareButton
              url={message}
              className="shareOption"
              beforeOnClick={onSocialShareReportLink('Email')}
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
            <FacebookShareButton
              url={message}
              className="shareOption"
              beforeOnClick={onSocialShareReportLink('Facebook')}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <LinkedinShareButton
              url={message}
              className="shareOption"
              beforeOnClick={onSocialShareReportLink('LinkedIn')}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <TwitterShareButton
              url={message}
              className="shareOption"
              beforeOnClick={onSocialShareReportLink('Twitter')}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
              url={message}
              className="shareOption"
              beforeOnClick={onSocialShareReportLink('Whatsapp')}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </div>
        {canDownloadReportWorkbook && (
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={canDownloadDocument}
                onChange={updateCanDownloadDocument}
              />{' '}
              Allow anyone with this link to access files
            </label>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        {isConfirm && (
          <Button
            variant="outline-danger"
            size="sm"
            type="button"
            className="deleteCancel"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          variant="primary"
          type="button"
          disabled={doubleClick}
          className="deleteConfirm"
          onClick={() => {
            onConfirm();
            setDoubleClick(true);
          }}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  company: loggedInCompanySelector(state)
});
export default connect(mapStateToProps)(
  withServices('projectsService', 'eventTrackerService')(ShareReportLinkModal)
);
