import { setDynamicAssetTypesList } from '../store/dynamicAssetType';

class DynamicAssetTypesService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  async createDynamicAssetTypes(values) {
    const dynamicAssetType = await this.apiClient.post(
      '/admin/assetTypes',
      { dynamicAssetType: values.dynamicAssetType },
    );
    await this.loadDynamicAssetTypes();
    return dynamicAssetType;
  }

  async editDynamicAssetTypes(values) {
    const dynamicAssetType = await this.apiClient.put(
      '/admin/assetTypes',
      { dynamicAssetType: values.dynamicAssetType },
    );
    await this.loadDynamicAssetTypes();
    return dynamicAssetType;
  }

  async loadDynamicAssetTypes() {
    const result = await this.apiClient.get('/admin/assetTypes');
    const dynamicAssetTypesList = result.response.dynamicAssetTypes;
    this.store.dispatch(setDynamicAssetTypesList(dynamicAssetTypesList));
    return dynamicAssetTypesList;
  }

  async deleteDynamicAssetType({ assetType }) {
    const result = await this.apiClient.delete('/admin/assetTypes', { assetType });
    const dynamicAssetTypesList = result.response.dynamicAssetTypes;
    this.store.dispatch(setDynamicAssetTypesList(dynamicAssetTypesList));
    return dynamicAssetTypesList;
  }
}

export default DynamicAssetTypesService;