/* eslint-disable no-irregular-whitespace */
import React from 'react';
import SortableTree from 'react-sortable-tree';
import { isNil } from 'lodash';
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
import style from './TreeView.module.scss';
import './TreeView.scss';
import { DisplayAmountFormat } from '../../../utils/utils';

function TreeView({
  treeData,canDrag,rowHeight,onChange, ...props
}) {
  return (
    <>
      <SortableTree
        treeData={treeData}
        theme={FileExplorerTheme}
        rowHeight={rowHeight}
        canDrag={canDrag}
        onChange={onChange}
        {...props}
        generateNodeProps={(({ node }) => ({
          title: (
            <div style={{ backgroundColor: `${node.color}` }} className={style.sort__table__title}>
              {node.title}
              <small className="font-weight-bold ml-2">
                {node.showEGI && node.expanded ? '(EGI%)' : ''}
                {' '}
              </small>
              {' '}
              <small className="font-weight-bold ml-1">{!isNil(node.egi) ? <DisplayAmountFormat number={node.egi} showCurency={false} appendString="%" /> : ''}</small>
              <span className="font-weight-bold">
                <DisplayAmountFormat number={node.displayTotal} />
              </span>
            </div>)
        }))}
      />
    </>
  );
}

export default TreeView;
