/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */

import BaseCommand from './BaseCommand';

export class SetHorizontalAlignmentCommand extends BaseCommand {
  run(spreadsheet, options) {
    const { activeSheetIndex, alignment } = options;
    for (const selection of spreadsheet.getSelections(activeSheetIndex)) {
      const range = spreadsheet.getCellRangeFromSelection(undefined, selection);
      range.hAlign(spreadsheet.GC.Spread.Sheets.HorizontalAlign[alignment]);
    }
  };
}

export const setHorizontalAlignmentCommand = SetHorizontalAlignmentCommand.get();
