import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { withServices } from 'reaf';
import textDocumentIcon from '../../../assets/images/text-documents.svg';
import style from './DocumentFileDrop.module.scss';
import './DocumentFileDrop.scss';

function DocumentFileDrop({ onChangeFile }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChangeFile(acceptedFiles);
    },
    []
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="my-3">
      <div
        {...getRootProps({
          className:
            'vertical-section dropzone justify-content-center h-50 bg-light dropzone-file-uploads-container w-100'
        })}
      >
        <input {...getInputProps()} />
        <div className={`text-center ${style.documentFileDragdrop}`}>
          <div className={`${style.documentFileIcon}`}>
            <img src={textDocumentIcon} alt="text document icon" />
          </div>
          <h4 className="mt-3">Drop your Rent Roll files here</h4>
          <p>
            or &nbsp;
            <strong className="text-primary cursor-pointer">
              click to select file
            </strong>{' '}
          </p>
          <small>
            Please upload&nbsp;
            <span className="text-primary">.pdf, .xlsx</span> &nbsp;file
            formats.
          </small>
        </div>
      </div>
    </div>
  );
}

export default withServices(
  'documentsService',
)(DocumentFileDrop);
