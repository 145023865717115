import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CompanyList from '../../app/Company/CompanyList';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';
import Breadcrumbs from '../../app/Breadcrumbs/Breadcrumbs';

function CompaniesPageHeader() {
  return (
    <Container fluid className="p-2 light border-bottom-light-blue sa-breadcrumb">
      <Row className="align-items-center">
        <Col>
          <Breadcrumbs rootRoute="superAdminHomePage" items={[{ to: '#', content: 'Companies' }]} />
        </Col>
      </Row>
    </Container>
  );
}

function CompanyListPage({ queries }) {
  return (
    <SuperAdminDashboard>
      <CompaniesPageHeader />
      <CompanyList queries={queries} />
    </SuperAdminDashboard>
  );
}
export default CompanyListPage;
