import React from "react";
import { withServices } from "reaf";
import linkedinLogo from "../../../assets/images/linkedin.svg";
import styles from "./LinkedInLoginButton.module.scss";

const LinkedInButton = ({ linkedInWrapperClass="abcRioButtonIcon", authService }) => (
  <div
    onClick={authService.linkedInLogin}
    className={`${styles.linkedInButtonWrapper} abcRioButton `}
  >
    <div className="cursor-pointer">
      <div className={linkedInWrapperClass}>
        <div className={styles.linkedInIconWrapper}>
          <img
            alt="LinkedIn logo"
            src={linkedinLogo}
            className={styles.linkedInLogo}
          />
        </div>
      </div>
      <span className={`abcRioButtonContents ${styles.linkedInTextWrapper}`}>
        <span>Sign in with LinkedIn</span>
      </span>
    </div>
  </div>
);

export default withServices("authService")(LinkedInButton);
