import React, { useState } from 'react';
import ApiFrequencyForm from '../ApiFrequencyForm/ApiFrequencyForm';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';


function ApiFrequencyFormModal({ show, onHide, integrationConfigrationUuid, project, documentsUuidsForModel }) {
    const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
    const [selectedFrequency, setSelectedFrequency] = useState();
  
    return (
      <FormModal size="xs" show={show} onHide={onHide} onSubmit={onSubmit} isSubmitting={isSubmitting} title={'Frequency'}>
        <ApiFrequencyForm 
          bindSubmission={bindSubmission} 
          onSubmitSuccess={onHide}
          integrationConfigrationUuid={integrationConfigrationUuid}
          project={project}
          documentsUuidsForModel={documentsUuidsForModel}
          setSelectedFrequency={setSelectedFrequency}
          selectedFrequency={selectedFrequency}
        />
      </FormModal>
    );
  }
  
  export default ApiFrequencyFormModal;