import { saveAs } from 'file-saver';
import { sumBy } from 'lodash';
import Service from './Service';

class UtilsService extends Service {
  constructor(apiClient, store) {
    super(apiClient, store);
    this.apiClient = apiClient;
  }

  async downloadChargeCodeExcel(chargeCodeConfigData, floorPlanConfigData, unitStatusConfigData) {
    const chargeCodeData = Reflect
      .ownKeys(chargeCodeConfigData)
      .map(chargeCode => ({
        "Charge Code": chargeCode,
        "Amount": chargeCodeConfigData[chargeCode].total,
        "Category": chargeCodeConfigData[chargeCode].category
      }));

    chargeCodeData.push({
      "Charge Code": "Total",
      "Amount": sumBy(chargeCodeData, 'Amount')
    });

    const floorPlan = Reflect
      .ownKeys(floorPlanConfigData)
      .map(floorplan => ({
        "Floor Plan": floorplan,
        "Beds": floorPlanConfigData[floorplan].BD,
        "Baths": floorPlanConfigData[floorplan].BA,
        "Units": floorPlanConfigData[floorplan].count,
        "Market rent": floorPlanConfigData[floorplan].marketRentTotal,
        "Average Area (Sq ft)": floorPlanConfigData[floorplan].squareFtAvgTotal
      }));

    floorPlan.push({
      "Floor Plan": 'Total',
      "Beds": sumBy(floorPlan, 'Beds'),
      "Baths": sumBy(floorPlan, 'Baths'),
      "Units": sumBy(floorPlan, 'Units'),
      "Market rent": sumBy(floorPlan, 'Market rent'),
      "Average Area (Sq ft)": sumBy(floorPlan, 'Average Area (Sq ft)')
    });

    const unitStatus = Reflect
      .ownKeys(unitStatusConfigData)
      .map(unitstatus => ({
        "Unit Status": unitstatus,
        "Category": unitStatusConfigData[unitstatus].category,
        "Market Rent": unitStatusConfigData[unitstatus].marketRentTotal,
        "Unit Count": unitStatusConfigData[unitstatus].count
      }));

    unitStatus.push({
      "Unit Status": 'Total',
      "Category": '',
      "Market Rent": sumBy(unitStatus, 'Market Rent'),
      "Unit Count": sumBy(unitStatus, 'Unit Count')
    });

    return this.downloadExcel({
      "Charge Code": chargeCodeData,
      "Floor Plan": floorPlan,
      "Unit Status": unitStatus
    }, 'ChargeCodeConfigData.xlsx');
  }

  async downloadExcel(filedata, filename) {
    const url = '/utils/downloadExcel';
    const response = await this.apiClient.post(
      url, { filedata, filename }, { responseType: 'blob' }
    );
    saveAs(response, filename);
  }
}

export default UtilsService;
