import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import PasswordViewer from '../PasswordViewer/PasswordViewer';
import CompanyFieldsComponent from './CompanyFieldsComponent';
import { Card } from 'react-bootstrap';

function CompanyFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div className="passwordGroupRow">

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <Form>
        <CompanyFieldsComponent setFieldValue={setFieldValue} values={values} />
        <Card className="my-3">
          <Card.Header className="py-2">
            Admin Detail
          </Card.Header>
          <Card.Body>
            <div className="d-flex">
              <div className="form-group  w-100 pr-2">
                <label htmlFor="admin.firstName">First Name</label>
                <Field id="admin.firstName" className="form-control" name="admin.firstName" type="text" />
                <FieldErrorMessage name="admin.firstName" />
              </div>
              <div className="form-group  w-100 pl-2">
                <label htmlFor="admin.lastName">Last Name</label>
                <Field id="admin.lastName" className="form-control" name="admin.lastName" type="text" />
                <FieldErrorMessage name="admin.lastName" />
              </div>
            </div>
            <div className="d-flex">
              <div className="form-group m-0 w-100 pr-2">
                <label htmlFor="admin.email">Email</label>
                <Field id="admin.email" className="form-control" name="admin.email" type="email" />
                <FieldErrorMessage name="admin.email" />
              </div>

              <div className="form-group m-0 w-100 pl-2">
                <label htmlFor="admin.password">Password</label>
                <PasswordViewer id="admin.password" name="admin.password" />
                <FieldErrorMessage name="admin.password" />
              </div>
            </div>
          </Card.Body>
        </Card>
      </Form>
    </div>
  );
}

export default CompanyFormComponent;
