/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import * as moment from 'moment';
import { DocumentFileTypes, DoucmentValidationStatus, EXCLUDE_DOC_FILE_TYPES, ExtractionJobStatus, PROPERTY_TYPES, CompanyTypeFilters, TICKET_STATUS } from '../../../constants';

export function SelectDocumentTypeFilter({
  column: { filterValue, setFilter },
  gotoPage,
}) {
  return (
    <select
      className="form-control"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
        gotoPage(0);
      }}
    >
      <option value="">All</option>
      {Reflect
        .ownKeys(DocumentFileTypes)
        .filter(docType => !EXCLUDE_DOC_FILE_TYPES.includes(docType))
        .map((docTypeKey, i) => (
          <option key={i} value={docTypeKey}>
            {DocumentFileTypes[docTypeKey].badgeText}
          </option>
        ))}
    </select>
  )
}

export function SelectCompanyType({
  column: { filterValue, setFilter },
  gotoPage,
}) {
  return (
    <select
      className="form-control"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined)
        gotoPage(0);
      }}
    >
      <option value="">All</option>
      {Reflect
        .ownKeys(CompanyTypeFilters)
        .map((key, i) => (
          <option key={i} value={CompanyTypeFilters[key]}>
            {key}
          </option>
        ))}
    </select>
  )
}

export function SelectExtractionStatusFilter({
  column: { filterValue, setFilter },
  gotoPage,
}) {
  return (
    <select
      className="form-control"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
        gotoPage(0);
      }}
    >
      <option value="">All</option>
      {Reflect
        .ownKeys(ExtractionJobStatus)
        .map((docTypeKey, i) => (
          <option key={i} value={docTypeKey}>
            {ExtractionJobStatus[docTypeKey].label}
          </option>
        ))}
    </select>
  )
}

export function SelectDocumentAssetTypeFilter({
  column: {filterValue, setFilter},
  gotoPage,
}) {
  const selectedValue = !!filterValue ? filterValue.split(',').map(assetType => {
    return {label: assetType, value: assetType}
  }) : '';
  return (
    <ReactSelect
      maxMenuHeight={230}
      menuPlacement="auto"
      menuPosition="fixed"
      value={selectedValue}
      isMulti
      options={
        Reflect.ownKeys(PROPERTY_TYPES).map((assetType, i) => ({ value: assetType, label: assetType }))
      }
      onChange={
        (filteredAssetType) => {
          if (filteredAssetType) {
            const filterValue = filteredAssetType.map(assetType => assetType.value);
            setFilter(filterValue.toString());
          } else {
            setFilter('')
          }
        }
      }
    />
  )
}

export function SelectDocumentValidationStatusFilter({
  column: {filterValue, setFilter},
  gotoPage,
}) {
  return (
    <select
      className="form-control"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
        gotoPage(0);
      }}
    >
      <option value="">All</option>
      {Reflect
        .ownKeys(DoucmentValidationStatus)
        .map((docTypeKey, i) => (
          <option key={i} value={docTypeKey}>
            {DoucmentValidationStatus[docTypeKey].label}
          </option>
        ))}
    </select>
  )
}

export function SelectAssetTypeFilter({
  column: { filterValue, setFilter },
  gotoPage,
  assetTypesOptions,
  setPropertyTypeFilter
}) {

  return (
    <select
      id="AssetTypeFilter"
      className="form-control"
      value={filterValue || ''}
      onChange={e => {
        const v = e.target.value;
        setFilter(v || undefined);
        setPropertyTypeFilter(v);
        setTimeout(() => gotoPage(0), 50);
      }}
    >
      <option value="">All Asset Classes</option>
      {Reflect.ownKeys(assetTypesOptions).map(uuid => 
          (<option value={uuid} key={uuid}>{assetTypesOptions[uuid]}</option>))}
    </select>
  )
}

export function SelectTicketStatusFilter({
  column: { filterValue, setFilter },
  gotoPage
}) {

  return (
    <select
      id="ticketStatusFilter"
      className="form-control"
      style={{width: 130}}
      value={filterValue || ''}
      onChange={e => {
        const v = e.target.value;
        setFilter(v || undefined);
        setTimeout(() => gotoPage(0), 50);
      }}
    >
      <option value="">All</option>
      {Reflect.ownKeys(TICKET_STATUS).map(uuid => 
          (<option value={uuid} key={uuid}>{TICKET_STATUS[uuid]}</option>))}
    </select>
  )
}

export function SelectModelUsedFilter({
  column: { filterValue, setFilter },
  assetTypesOptions,
  setPropertyTypeFilter
}) {
  return (
    <select
      className="form-control"
      value={filterValue || ''}
      onChange={e => {
        const v = e.target.value;
        setFilter(v || undefined);
        setPropertyTypeFilter(v)
      }}
    >
      <option value="">All</option>
      {Reflect.ownKeys(assetTypesOptions).map(uuid => 
        (<option value={uuid} key={uuid}>{assetTypesOptions[uuid]}</option>))}
    </select>
  )
}

export function DefaultColumnFilter({
  column: { setFilter, filterValue, Header },
  gotoPage
}) {
  const onChange = (value) => {
    setFilter(value);
    gotoPage(0);
  };

  const [localValue, setLocalValue] = useState(filterValue || '');
  const [timer, setTimer] = useState();
  useEffect(() => {
    setTimer(() => setLocalValue(filterValue || ''),  200);
  }, [filterValue]);

  return (
    <input
      type="text"
      value={localValue}
      className="form-control defaultColumnFilter"
      style={{ border: 'none', padding: '5px' }}
      onChange={e => {
        setLocalValue(e.target.value || '');
        onChange(e.target.value || '')
        timer && clearTimeout(timer);
      }}
      placeholder={`Search ${Header}...`}
    />
  )
}

export const createdAtFilter = ({
  column: { filterValue, setFilter, Header },
  gotoPage,
}) => {

  const onChange = (value) => {
    const date = moment(value).isValid() ? moment(value).format('MM/DD/YYYY') : '';
    setFilter(`${date}`);
    gotoPage(0);
  };

  const [localValue, setLocalValue] = useState(filterValue || '');
  const [timer, setTimer] = useState();

  useEffect(() => {
    setTimer(() => setLocalValue(filterValue || ''), 200);
  }, [filterValue]);

  return (
    <DatePicker
      className="form-control defaultColumnFilter mr-2 border-0"
      placeholderText={`Search ${Header}...`}
      selected={!!localValue ? new Date(localValue) : ''}
      popperPlacement="left" 
      onChange={date => {
        setLocalValue(date || '');
        onChange(date || '')
        timer && clearTimeout(timer);
      }}
    />
  )
}