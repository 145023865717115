import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

function ProspectPaymentLinkFormComponent({
  status, isSubmitting, bindSubmission, submitForm
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <Form>
        <div className="form-row">
          <div className="col-md-12 mt-2">
            <h5>Link Details</h5>
            <hr />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="name">Product Name</label>
            <Field id="name" className="form-control" name="name" placeholder="Autouw underwriting" type="text" />
            <FieldErrorMessage name="name" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="amount">Amount</label>
            <Field id="amount" className="form-control" name="amount" placeholder="5000" type="number" />
            <FieldErrorMessage name="amount" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyAdminFirstName">Quantity</label>
            <Field id="quantity" className="form-control" name="quantity" placeholder="1" type="number" />
            <FieldErrorMessage name="quantity" />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ProspectPaymentLinkFormComponent;
