import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { withServices } from 'reaf';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { authSelector } from '../../../store/selectors';
import messages from '../../../../locales';
import TrialUserRegistrationFormComponent from './TrialUserRegistrationFormComponent';

// eslint-disable-next-line max-len
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const TrialUserRegistrationFormSchema = Yup.object().shape({
  company: Yup.object().shape({
    name: Yup.string()
      .trim()
      .transform((value) => (value === '' ? undefined : value))
      .required(messages.errors.validationError.companyName),
    phone: Yup.string()
      .trim()
      .matches(phoneRegExp, 'Please provide a valid phone number.')
  }),
  admin: Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .transform((value) => (value === '' ? undefined : value))
      .required('Please provide a valid first name.'),
    lastName: Yup.string()
      .trim()
      .transform((value) => (value === '' ? undefined : value))
      .required('Please provide a valid last name.'),
    email: Yup.string()
      .email('Please provide a valid email.')
      .required('Please provide a valid email')
  })
});

function TrialUserRegistrationForm({
  authService,
  auth: { company, user, isSelfSignup }
}) {
  const [showMessageModal, setShowMessageModal] = React.useState(!isSelfSignup);
  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      await authService.completeTrialUsersRegistration(values);
    } catch (e) {
      console.log(e);
      actions.setStatus({ error: e.message });
    }

    actions.setSubmitting(false);
  }, []);

  user = user || {}

  return (
    <>
      <Formik
        initialValues={{
          company: { name: company.name, phone: company.phone },
          admin: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          }
        }}
        validationSchema={TrialUserRegistrationFormSchema}
        onSubmit={onSubmit}
        component={(formikProps) => (
          <TrialUserRegistrationFormComponent
            {...formikProps}
            showMessageModal={showMessageModal}
            user={user}
          />
        )}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: authSelector(state)
});

export default connect(mapStateToProps)(
  withServices('authService')(TrialUserRegistrationForm)
);
