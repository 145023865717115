import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import * as moment from 'moment';
import { withServices } from 'reaf';
import EditCompanyFormComponent from './EditCompanyFormComponent';
import companyFormSchema from './companyFormSchema';
import { dynamicAssetTypesListSelector } from '../../../store/selectors';

const CompanyFormSchema = (dynamicAssetTypes) => Yup.object().shape({
  company: companyFormSchema(dynamicAssetTypes)
});

function EditCompanyForm({
  onSubmitSuccess, bindSubmission, company, companyService, dynamicAssetTypes
}) {
  async function onSubmit(values, actions) {
    actions.setStatus({});
    try {
      const { company: updatedCompany } = await companyService.upsertCompany(company.company, values);
      onSubmitSuccess(updatedCompany);
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }

  const paymentStatus = useMemo(() => (company.company.paymentDueDate === null ? 'Pending' : 'Completed'), [company.company]);
  const previousDueDate = useMemo(() => (company.company.paymentDueDate === null ? '' : moment(company.company.paymentDueDate).format('MM/DD/YYYY')), [company.company]);
  const OSMonthsToHide = useMemo(() => company.company.OSMonthsToHide, [company.company]);
  return (
    <Formik
      initialValues={{
        ...company, paymentStatus, previousDueDate, paymentDueDate: '', OSMonthsToHide
      }}
      validationSchema={CompanyFormSchema(dynamicAssetTypes)}
      onSubmit={onSubmit}
      component={formikProps => <EditCompanyFormComponent {...formikProps} bindSubmission={bindSubmission} />
      }
    />
  );
}

const mapStateToProps = state => ({
  dynamicAssetTypes: dynamicAssetTypesListSelector(state) || []
});

export default connect(mapStateToProps)(withServices('companyService')(EditCompanyForm));
