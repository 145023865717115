class CaptureEmailService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  async captureEmail({ email, reportLinkUuid }) {
    return this.apiClient.post('/shared/captureEmail', { email, reportLinkUuid });
  }

  async updateCapturedEmail({ email, isVerified }) {
    return this.apiClient.post('/shared/updateCapturedEmail', {
      email,
      isVerified
    });
  }
}

export default CaptureEmailService;
