export default class BaseCommand {
  static instance = null;

  static get() {
    if (!this.instance) {
      this.instance = new this();
    }

    return this.instance;
  }

  hasUndo = false;

  suspend(spreadsheet) {
    if (!spreadsheet.spreadsheet) {
      spreadsheet.suspend();
    } else {
      spreadsheet.spreadsheet.suspend();
    }
  }

  resume(spreadsheet) {
    if (!spreadsheet.spreadsheet) {
      spreadsheet.resume();
    } else {
      spreadsheet.spreadsheet.resume();
    }
  }

  runCommand = (spreadsheet, options) => {
    this.suspend(spreadsheet);
    this.run(spreadsheet, options);
    this.resume(spreadsheet);
  };

  undoCommand = (spreadsheet, options) => {
    this.suspend(spreadsheet);
    this.undo(spreadsheet, options);
    this.resume(spreadsheet);
  };

  run() {
    throw new Error('Implement this method in derived class');
  }

  undo() {
    console.warn('Undo not implemented for this command');
  }
}
