
class PlacesService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
  }

  async autocomplete(searchText) {
    const response = await this.apiClient.post(
      '/places/autocomplete',
      { searchText },
    );

    return response.response.predictions
  }

  async getDetailsByPlaceId(placeId) {
    const response = await this.apiClient.post(
      '/places/details',
      { placeId },
    );

    return response.response.result
  }
}

export default PlacesService;
