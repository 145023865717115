import React from 'react';
import FormModal from '../FormModal/FormModal';
import useModalForm from '../../../hooks/useModalForm';
import UserlogqueryForm from './UserlogqueryForm';

function UserlogQueryPopup({
  onHide, showUserlogQueryPopup, csvEndEndPoint
}) {
  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();

  return (
    <div>
      {showUserlogQueryPopup && 
        <FormModal isSubmitting={isSubmitting} onSubmit={onSubmit} onHide={onHide} show={showUserlogQueryPopup} title="Add Date Range">
          <UserlogqueryForm bindSubmission={bindSubmission} onSubmitSuccess={onHide} csvEndEndPoint={csvEndEndPoint} />
        </FormModal>
      }
    </div>
  );
}

export default UserlogQueryPopup;