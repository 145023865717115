import React from 'react';
import Tour from 'reactour';

const documentPageSteps = showSplitting => [
  {
    selector: '#AddTagButton',
    content: () => (
      <>
        <h6 className="border-bottom">Add Document Tutorial</h6>
        <p>Click this button to add tagging.</p>
      </>
    ),
    action: node => node && node.focus()
  },
  {
    selector: '#AddMoreTaggingForm',
    content: () => (
      <>
        <h6 className="border-bottom">Tag Document Guide</h6>
        <p>Click to add more taggings</p>
      </>
    ),
    action: node => node && node.focus()
  },
  {
    selector: '#DocumentTypeField',
    content: () => (
      <>
        <h6 className="border-bottom">Tag Document Guide</h6>
        <p>Choose the type of document from here</p>
      </>
    ),
    action: node => node && node.focus()
  },
  ...(showSplitting ? [{
    selector: '#SplittingSection',
    content: () => (
      <>
        <h6 className="border-bottom">Tag Document Guide</h6>
        <p>Specify the page range to be considered for extraction. Leave this section empty to consider all pages</p>
      </>
    ),
    action: node => node && node.focus()
  }] : [{
    selector: '#SheetSelectionSection',
    content: () => (
      <>
        <h6 className="border-bottom">Tag Document Guide</h6>
        <p>Specify the sheet to be considered for extraction</p>
      </>
    ),
    action: node => node && node.focus()
  }]),
  {
    selector: '#TaggingFormSubmitButton',
    content: () => (
      <>
        <h6 className="border-bottom">Tag Document Guide</h6>
        <p>Click &rsquo;Next&rsquo; button to save the document tagging and trigger data extraction.</p>
      </>
    ),
    action: node => node && node.focus()
  },
  {
    selector: '#ShowTourButton',
    content: () => (
      <>
        <h6 className="border-bottom">Tag Document Guide</h6>
        <p>Click this button to show this help tour again.</p>
      </>
    )
  }
];

function DocumentPageTour({
  show, onClose, showSplitting, step
}) {
  return (
    <Tour
      steps={documentPageSteps(showSplitting)}
      isOpen={show}
      onRequestClose={onClose}
      rounded={5}
      accentColor="#5cb7b7"
      startAt={0}
      updateDelay={300}
      closeWithMask={false}
      disableInteraction={false}
      showNavigation={false}
      disableKeyboardNavigation={false}
      showButtons
      goToStep={step}
    />
  );
}


export default DocumentPageTour;
