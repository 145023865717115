import React from 'react';
import {
  Tabs, Tab, Container, Row, Col, Form
} from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import './ProjectInfoFormComponent.scss';
import { connect } from 'react-redux';
import { getApiIntergationFormField } from '../../../utils/utils';
import FormikTextField from '../../core/FormikTextField/FormikTextField';
import FormikSelectField from '../../core/FormikSelectField/FormikSelectField';
import { TEMPLATE_TAG } from '../../../constants';
import { reviews } from './ProjectInfoFormValidationSchema';
import { isFreeBieApp } from '../../../utils/utils';
import { dynamicAssetTypesAsObjSelector } from '../../../store/selectors';

function ProjectServiceFormComponent({
  servicingTab, setServicingTab, status,
  submitForm, isSubmitting, bindSubmission,
  dynamicAssetTypes, apiConfigIntegrationValues, errors
}) {
  bindSubmission(submitForm, isSubmitting);
  const getErrorClass = (keyName) => {
    if(keyName === 'api_integration' && errors[keyName]){
      return 'error';
    } else {
      return get(errors, keyName, false) ? 'error' : ''
    }
  }

  return (
    <div className="projectInfoModalForm">
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <div className="clearfix">
        <Form>
          <Tabs onSelect={k => setServicingTab(k)} activeKey={servicingTab} transition={false} id="noanim-tab-example">
            <Tab eventKey="general" title="General" tabClassName={getErrorClass('servicing.general')}>
              <Container fluid className="py-4">
                <Row className="justify-content-center">
                  <Col className="col-lg-8">
                    <FormikTextField
                      label="Property Name"
                      placeholder="Enter Property Name"
                      name="servicing.general.project_name"
                      disabled
                    />
                    <FormikSelectField
                      label="Asset Types"
                      name="servicing.general.asset_type"
                      disabled
                    >
                      <option value="">All Asset Types</option>
                      {
                        Reflect.ownKeys(dynamicAssetTypes).map(assetType => (
                          <option key={assetType} value={assetType}>{dynamicAssetTypes[assetType].label}</option>
                        ))
                      }
                    </FormikSelectField>

                    {
                      !isFreeBieApp && (
                        <FormikTextField
                          label="Model"
                          name="servicing.general.model"
                          disabled
                        />
                      )
                    }


                    <FormikSelectField
                      label="Review"
                      name="servicing.general.review"
                    >
                      <option value="">Select Type</option>
                      {
                        reviews.map(reviewKey => (<option key={reviewKey} value={reviewKey}>{reviewKey}</option>))
                      }
                    </FormikSelectField>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="property" title="Property Information" tabClassName={getErrorClass('servicing.property_information')}>
              <Container fluid className="py-4">
                <Row className="justify-content-center">
                  <Col className="col-lg-8">
                    <FormikTextField
                      label="Borrower Name"
                      placeholder="Enter Borrower Name"
                      name="servicing.property_information.borrower_name"
                    />

                    <FormikTextField
                      label="Property Name"
                      placeholder="Enter Property Name"
                      name="servicing.property_information.property_name"
                    />

                    <FormikTextField
                      label="Property Address"
                      placeholder="Enter Property Address"
                      name="servicing.property_information.property_address"
                    />

                    <FormikTextField
                      label="City"
                      placeholder="Enter City"
                      name="servicing.property_information.city"
                    />

                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          label="State"
                          placeholder="Enter State"
                          name="servicing.property_information.state"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          label="Zip"
                          type="text"
                          placeholder="Enter Zip Code"
                          name="servicing.property_information.zip"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          label="Year Built"
                          type="number"
                          placeholder="Year Built"
                          name="servicing.property_information.year_built"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          label="Year Renovated"
                          type="number"
                          placeholder="Year Renovated"
                          name="servicing.property_information.year_renovated"
                        />
                      </Col>
                    </Form.Row>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="lender" title="Lender Information" tabClassName={getErrorClass('servicing.lender_information')}>
              <Container fluid className="py-4">
                <Row className="justify-content-center">
                  <Col className="col-lg-8">
                    <FormikTextField
                      label="SF/Units/Pads/Beds"
                      name="servicing.lender_information.SF/Units/Pads/Beds"
                    />

                    <FormikTextField
                      label="Lender Loan Number"
                      placeholder="Lender Loan Number"
                      name="servicing.lender_information.lender_loan_number"
                    />

                    <FormikTextField
                      label="Original Loan Balance"
                      placeholder="Original Loan Balance"
                      name="servicing.lender_information.original_loan_balance"
                    />

                    <FormikTextField
                      label="Funding Date"
                      placeholder="Funding Date"
                      name="servicing.lender_information.funding_date"
                    />

                    <FormikTextField
                      label="Interest Rate"
                      type="number"
                      placeholder="Interest Rate"
                      name="servicing.lender_information.interest_rate"
                    />

                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="servicer" title="Servicer Information" tabClassName={getErrorClass('servicing.servicer_information')}>
              <Container fluid className="py-4">
                <Row className="justify-content-center">
                  <Col className="col-lg-8">

                    <FormikTextField
                      label="Internal Loan Number"
                      placeholder="Internal Loan Number"
                      name="servicing.servicer_information.internal_loan_number"
                    />

                    <FormikTextField
                      label="Correspondent Name"
                      placeholder="Correspondent Name"
                      name="servicing.servicer_information.correspondent_name"
                    />

                    <FormikTextField
                      label="Maturity Date"
                      placeholder="Maturity Date"
                      name="servicing.servicer_information.maturity_date"
                    />

                    <FormikTextField
                      label="Monthly Payement"
                      placeholder="Monthly Payement"
                      name="servicing.servicer_information.monthly_payment"
                    />

                    <FormikTextField
                      label="Correspondent Office"
                      placeholder="Correspondent Office"
                      name="servicing.servicer_information.correspondent_office"
                    />

                  </Col>
                </Row>
              </Container>
            </Tab>
            {!isEmpty(apiConfigIntegrationValues) && (
              <Tab eventKey="api" title="Api Integration" tabClassName={getErrorClass('api_integration')}>
                <Container fluid className="py-4">
                  <Row className="justify-content-center">
                    <Col className="col-lg-8">
                      {apiConfigIntegrationValues?.map((object, index) => {
                        return getApiIntergationFormField(object, index);
                      })}
                    </Col>
                  </Row>
                </Container>
              </Tab>
            )}
          </Tabs>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
})
export default connect(mapStateToProps)(ProjectServiceFormComponent);
