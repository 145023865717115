import { setAccountsExecutiveList } from '../store/accountsExecutive';
import { USER_ROLES } from '../constants';

class AccountsExecutiveService {
  constructor(apiClient, store) {
    this.apiClient = apiClient;
    this.store = store;
  }

  async createAccountsExecutive(values) {
    const accountsExecutive = await this.apiClient.post(
      '/admin/accountsExecutives/create',
      { user: values.accountsExecutive, role: USER_ROLES.ACCOUNTS_EXECUTIVE.key },
    );

    await this.loadAccountsExecutives();
    return accountsExecutive;
  }

  async loadAccountsExecutives() {
    const result = await this.apiClient.get('/admin/accountsExecutives');
    const accountsExecutiveList = result.response.accountExecutives;
    this.store.dispatch(setAccountsExecutiveList(accountsExecutiveList));
    return accountsExecutiveList;
  }
}

export default AccountsExecutiveService;
