import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { faArrowCircleLeft as prevIcon } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight as nextIcon } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import { faSearchMinus as zoomOutIcon } from '@fortawesome/free-solid-svg-icons/faSearchMinus';
import { faSearchPlus as zoomInIcon } from '@fortawesome/free-solid-svg-icons/faSearchPlus';
import { faRedo as rotateIcon } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faVectorSquare as resetZoomIcon } from '@fortawesome/free-solid-svg-icons/faVectorSquare';
import { Document, Page, pdfjs } from 'react-pdf';
import React, { useState, useMemo } from 'react';
import { withServices } from 'reaf';
import loadingIcon from '../../../assets/images/Gear-4s-60px.svg';
import IconButton from '../../core/Button/IconButton';
import style from './PdfViewer.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfHeader({
  page, width, pageCount, setPage, setWidth, onPageChange, onScrollToggle, scroll, onSetAngle, angle, defaultWidth
}) {
  return (
    <Container fluid className={`${style.pdfViewerToolbar}`}>
      <Row className="justify-content-center">
        <Col xs="auto" className={`${style.toolbarViewer}`}>
          <IconButton onClick={() => setPage(page - 1)} disabled={page <= 1 || scroll} icon={prevIcon} title="Previous Page" className="smallIconButton" />
          <IconButton onClick={() => setPage(page + 1)} disabled={page >= pageCount || scroll} icon={nextIcon} title="Next Page" className="smallIconButton mr-2" />
          <span className="small">
          Page&nbsp;
            <input
              disabled={scroll}
              type="number"
              value={page}
              max={pageCount}
              min={1}
              className={`d-inline-block ${style.pageNumber}`}
              onChange={onPageChange}
              onFocus={e => e.target.select()}
            />
            &nbsp;of&nbsp;
            {' '}
            {pageCount}
            {' '}
&nbsp;
          </span>
        </Col>
        <div className={`position-relative d-inline-block ${style.toolbarSeparator}`} aria-disabled="true" role="separator line" />
        <Col className={`${style.toolbarViewer}`}>
          <IconButton onClick={() => onSetAngle((angle + 90) % 360)} title="Rotate clockwise" icon={rotateIcon} className="smallIconButton" />
          <IconButton onClick={() => setWidth(width - 100)} icon={zoomOutIcon} title="Zoom out" disabled={width < 300} className="smallIconButton" />
          <IconButton onClick={() => setWidth(defaultWidth)} icon={resetZoomIcon} title="Reset zoom" className="smallIconButton" />
          <IconButton onClick={() => setWidth(width + 100)} variant="outline-secondary" title="Zoom in" icon={zoomInIcon} className="smallIconButton" />
        </Col>
        <Col xs="auto" className={`${style.toolbarViewer}`}>
          <Button size="sb" variant="outline-secondary" className={`${style.btnView} btn-xs`} onClick={onScrollToggle} active={scroll}>{scroll ? 'Page View' : 'Scroll View'}</Button>
        </Col>
      </Row>
    </Container>
  );
}

function PdfViewer({
  onSetPageCount, fileObj, small = false, viewerHeight = 'auto'
}) {
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [width, setWidth] = useState(small ? 600 : 900);
  const [scrollPages, setScrollPages] = useState(false);
  const [angle, setAngle] = useState(0);

  const file = useMemo(() => ({
    url: fileObj.url,
    httpHeaders: {
      Authorization: fileObj.token
    }
  }), [fileObj]);

  function onDocumentLoad({ numPages }) {
    setPageCount(numPages);
    onSetPageCount(numPages);
  }

  function onPageChange(e) {
    const requestedPage = +e.target.value;
    if (requestedPage < 1 || requestedPage > pageCount) {
      return;
    }
    setPage(requestedPage);
  }

  return (
    <div className="vertical-section">
      <PdfHeader
        page={page}
        width={width}
        pageCount={pageCount}
        setPage={setPage}
        setWidth={setWidth}
        defaultWidth={small ? 600 : 900}
        onPageChange={onPageChange}
        onScrollToggle={() => setScrollPages(!scrollPages)}
        scroll={scrollPages}
        angle={angle}
        onSetAngle={setAngle}
      />
      <div className="flex-grow-1 position-relative">
        <div
          className="position-absolute"
          style={{
            overflow: 'auto', top: 0, left: 0, right: 0, bottom: 0, height: `calc(${viewerHeight} - ${90}px)`
          }}
        >
          <Document
            renderMode="canvas"
            file={file}
            onLoadSuccess={onDocumentLoad}
            loading={(
              <div className="d-flex justify-content-center mt-5">
                <img src={loadingIcon} height={20} alt="Loading Icon" />
                <span className="h6 ml-2 mt-auto mb-auto">Loading Document</span>
              </div>
            )}
            rotate={angle}
          >
            {
              pageCount > 0 &&
              (
                scrollPages ?
                  (new Array(pageCount)).fill(0).map((_, i) => (
                    <React.Fragment>
                      <span className="d-block text-center bg-secondary shadow-sm text-light m-2">
                        Page {' '}
                        {i + 1}
                        {' '}
&nbsp;of {' '}
                        {pageCount}
                      </span>
                      <Page pageNumber={i + 1} width={width} loading="" className={`${style.pdfPage}`} scale={1.5} />
                    </React.Fragment>

                  )) :
                  <Page pageNumber={page} width={width} loading="" className={`${style.pdfPage}`} scale={1.5} />
              )
            }
          </Document>
        </div>
      </div>

    </div>
  );
}

export default withServices('documentsService')(PdfViewer);
