import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import React, { useCallback, useState, useEffect } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import useSvgToImageDownload from '../../../hooks/useSvgToImageDownload';
import { getRandomColor } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';
import style from './DocExtractionAffixChart.module.scss';

function DocExtractionAffixBarChart({
  data, defaultChartColor = [], xAxisKeyName, width = 500, height = 300, yAxisKeyName = "", yAxisLabelName = ""
}) {
  const [chartColors] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [getPng, { ref, handleDownload }] = useSvgToImageDownload();



  const setColor = () => {
    data && data.forEach(() => {
      chartColors.push(getRandomColor());
    });
    setShowChart(true);
  };

  useEffect(() => {
    setColor();
  }, []);

  const setCartColor = useCallback((colIndex) => (
    defaultChartColor.length > 0 ? defaultChartColor[colIndex] : chartColors[colIndex])
  );

  
  const onDownloadClick = useCallback(async () => {
    const png = await getPng();
    handleDownload(png);
  }, [handleDownload, getPng]);

  const BarChartComponent = useCallback(() => (
    <div className={style.chartContainer}>
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 0, right: 30, left: 20, bottom: 20
        }}
        ref={ref}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKeyName} interval={0} angle={-45} textAnchor="end" height={80} />
        <YAxis dataKey={yAxisKeyName} label={{ value: yAxisLabelName, angle: -90 }} allowDataOverflow />
        <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
        {
          Reflect.ownKeys(data[0]).filter(keyName => !['tabName', 'name'].includes(keyName)).map((keyName, index) => <Bar key={keyName} dataKey={keyName} fill={setCartColor(index)} />)
        }
      </BarChart>
      <div className={`${style.downloadBtnContainer} ${style.downloadBar}`}>
        <IconButton icon={faDownload} onClick={onDownloadClick} className={style.downloadImageBtn} text="" />
      </div>
    </div>
  ));


  return (
    <div className={style.chartWrapper}>
      {showChart && <BarChartComponent />}
    </div>
  );
}

export default DocExtractionAffixBarChart;
