import React, { useEffect, useState } from 'react';
import { components } from 'reaf';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import style from './LoginFormComponent.module.scss';
import clikLogoImg from '../../../assets/images/clik-dark-logo.svg';


function AdminLoginForm({ authService, store, redirectOnSuccess = true }) {
  const [cgLoginUrl, setCGLoginUrl] = useState('');

  useEffect(async () => {
    async function fetchUrl() {
      const { response } = await authService.getCGAdminAuthUrl();
      setCGLoginUrl(response);
    }
    fetchUrl();
  }, [])

  return (
    <Card className={`${style.formCard}`}>
      <Card.Body className={style.formCardBody}>
        <div className="loginFormDarkLogo mb-5">
          <img src={clikLogoImg} alt="Clik Logo" />
        </div>
        <div className="row align-items-center">
          <div className="col-12">
            <div className='mb-3'>
              <h6><strong>If you are a super admin</strong></h6>
            </div>
              <div className="mb-3">
              <a href={cgLoginUrl}>
                <Button
                  className={`btn btn-primary ${style.btnPrimary}`}
                  type="button"
                >
                  Login Via Clik Account
                </Button>
            </a>
            </div>
          </div>
        </div>
      </Card.Body>
      </Card>
  );
}

const AdminLoginFormSimple = components.withServices('store', 'authService')(AdminLoginForm);

export const AdminLoginFormMemo = React.memo(AdminLoginFormSimple);

export default AdminLoginFormSimple;
