import React from 'react';
import VideoTutorialsList from '../../app/VideoTutorialsList/VideoTutorialsList';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function VideoTutorialsPage() {
  return (
    <SuperAdminDashboard>
      <VideoTutorialsList />
    </SuperAdminDashboard>
  );
}

export default VideoTutorialsPage;
