import { useState, useCallback } from "react";

export default () => {
  const [collapsedRowsUuids, setCollapsedRowsUuids] = useState(new Set());

  const isExpanded = useCallback((parentDocument) => {
    return !collapsedRowsUuids.has(parentDocument.uuid)
}, [collapsedRowsUuids]);

  const expandDocument = useCallback((parentDocument) => {
    collapsedRowsUuids.delete(parentDocument.uuid);
    setCollapsedRowsUuids(new Set(collapsedRowsUuids));
  }, [collapsedRowsUuids]);

  const collapseDocument = useCallback((parentDocument) => {
    collapsedRowsUuids.add(parentDocument.uuid);
    setCollapsedRowsUuids(new Set(collapsedRowsUuids));
  }, [collapsedRowsUuids]);

  const toggleCollapse = useCallback((parentDocument) => {
    if (collapsedRowsUuids.has(parentDocument.uuid)) {
      expandDocument(parentDocument);
    } else {
      collapseDocument(parentDocument);
    }
  }, [collapsedRowsUuids]);

  return {
    isExpanded, expandDocument, collapseDocument, toggleCollapse
  };
};
