import { createDuck } from 'reaf';

const initialState = {
  projectsPage: {
    meta: {
      firstTourDone: false
    },
    pageTour: {},
    addProjectTour: {}
  },
  projectPage: {
    meta: {
      firstTourDone: false
    },
    pageTour: {},
    addFileTour: {}
  },
  documentPage: {
    meta: {
      firstTourDone: false
    },
    pageTour: {}
  },
  extractionPage: {
    meta: {
      firstTourDone: false
    },
    pageTour: {}
  },
  resetPasswordPage: {
    meta: {
      firstTourDone: false
    },
    pageTour: {}
  }
};

const [
  { markFirstTourDone, setProductTourState },
  reducer
] = createDuck('autouw/productTour', {
  _initialState: initialState,

  markFirstTourDone: page => state => ({
    ...state,
    [page]: {
      ...state[page],
      meta: {
        ...state[page].meta,
        firstTourDone: true
      }
    }
  }),

  setProductTourState: newState => state => ({
    ...state,
    ...newState
  })
});

export {
  markFirstTourDone,
  setProductTourState
};

export default reducer;
