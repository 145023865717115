import { hideToastr, showToastr } from "../store/notificationToastr";

class ToastrService {
  constructor(store) {
    this.store = store;
  }

  success(msg, position, delay, autohide) {
    this.store.dispatch(showToastr('success', msg, position, delay, autohide));
  }

  error(msg, position, delay, autohide) {
    this.store.dispatch(showToastr('danger', msg, position, delay, autohide));
  }

  info(msg, position, delay, autohide) {
    this.store.dispatch(showToastr('info', msg, position, delay, autohide));
  }

  hideInfo() {
    this.store.dispatch(hideToastr('info'));
  }
}

export default ToastrService;
