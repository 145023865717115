import React from 'react';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import ReleaseNotes from '../../app/ReleaseNotes/ReleaseNotes';

function ReleaseNotesPage() {
  return (
    <LoggedOutUserPage>
      <ReleaseNotes />
    </LoggedOutUserPage>
  );
}

export default ReleaseNotesPage;
