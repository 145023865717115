/* eslint-disable prefer-destructuring */
import '@grapecity/spread-sheets-charts';
import "@grapecity/spread-sheets-io";
import { uniq } from 'lodash';


const originalSheetIndex = 0;



class FallbackWorkbookManager {
  constructor(workbookManager, options = {}) {
    this.workbookManager = workbookManager;
    this.options = options;
  }

  updateOptions(options) {
    this.options = {
      ...this.options,
      ...options
    };
  }


  addStyles() {
    this.workbookManager.addStyle(originalSheetIndex, 'dataHeader', {
      backColor: 'rgb(43, 142, 255)',
      foreColor: '#fff',
      borderBottom: {
        color: 'black',
        lineStyle: 'thin'
      }
    });
    this.headerStyleMap = {
      DATA: 'dataHeader',
    };
  }

  setData(data) {
    this.workbookManager.suspend();
    let workbookData = [];
    if (data && typeof data === 'object') {
      workbookData = Reflect.ownKeys(data).map(d => data[d])
    } 

    if (data && Array.isArray(data)) {
      workbookData = data
    }
    this.addStyles();
    this.workbookManager.setSheetName(originalSheetIndex, 'Original Data')
    this.setSheetData(originalSheetIndex, workbookData, false)
    this.workbookManager.resume();
  }

  setSheetData(sheetIndex, data, readonly) {
    this.workbookManager.suspend();
    const sheet = this.workbookManager.getSheet(originalSheetIndex)
    const { columns, rows } = this.sanitizeData(data);  
    this.workbookManager.setSheetColumnCount(sheetIndex, columns.length + 50);
    this.workbookManager.setSheetData(sheetIndex, rows, 300, columns.length + 50, 250);
    this.workbookManager.bindSheetColumns(sheetIndex, columns, 250);
    this.setTargetSheetHeaderNames(columns);
    this.setTargetColumnStyles(columns);
    this.resizeCell(sheet, { row: 0, col: 0, rowCount: 300, colCount: columns.length })
    this.workbookManager.resume();
  }


  setTargetColumnStyles(columns) {
    this.workbookManager.suspend();
    columns.forEach((column, col) => {
      const style = this.headerStyleMap[`DATA`];
      if (style) {
        this.workbookManager.setCellStyle(originalSheetIndex, 0, col, style);
      }
    });
    this.workbookManager.resetRowFreeze(0);
    this.workbookManager.resume();
  }

  setTargetSheetHeaderNames(columns) {
    this.workbookManager.addRows(originalSheetIndex, 0, 1);
    this.workbookManager.setRowData(originalSheetIndex, 0, columns.map(c => c.headerName));
    columns.forEach((column, i) => {
      this.workbookManager.setCellStyle(originalSheetIndex, 0, i, this.headerStyleMap[column.type]);
    });
  }

  sanitizeData(data) {
    const rows = this.getDataRow(data);
    const columns = this.getColumns(rows);
    return {
      columns, rows
    }
  }

  removeSpecialChar(txt) {
    if (txt !== undefined) {
      return txt.replace(/[^a-zA-Z0-9]/g, ' ').replace(/\b\w/g, function(l) {
        return l.toUpperCase();
      });
    }
  }

  toColumnName(num) {
    let ret;
    let a;
    let b;
    for (ret = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
      ret = String.fromCharCode(Number((num % b) / a) + 65) + ret;
    }
    return ret;
  }

  getUniqueKeysFromData(rows) {
    return Object.keys(
      rows.reduce(function (result, obj) {
        return Object.assign(result, obj);
      }, {}),
    );
  }

  getColumns(rows) {
    const columns = [];
    const uniqueKeys = this.getUniqueKeysFromData(rows);
    uniqueKeys.forEach((key) => {
        columns.push({ name: key, size: 180, headerName: this.removeSpecialChar(key) });
    });

    return columns.map((c, i) => ({
      ...c,
      displayName: this.toColumnName(i + 1),
    }));
  }

  resizeCell(sheet, cellInfo) {
    this.workbookManager.suspend()
    sheet.getRange(cellInfo.row, cellInfo.col, cellInfo.rowCount, cellInfo.colCount).wordWrap(true);
    for (let row = cellInfo.row; row < cellInfo.rowCount; row++) {
      sheet.autoFitRow(row);
    }
    this.workbookManager.resume();
  }

  getDataRow(rows) {
    const filterRows = rows.map((row) => {
      const rowObj = Reflect.ownKeys(row).reduce((acc, key) => {
        if (key !== "id") {
          let values = row[key];
          if (
            Array.isArray(values) &&
            values.some((value) => typeof value === "string")
          ) {
            values = values.join("\n");
          }
          if (Array.isArray(values) && values.length === 0) {
            values = "";
          }    

          if (
            Array.isArray(values) &&
            values.some((value) => typeof value === "object")
          ) {
            let str = "";
            values.map((value) => {
            
              Reflect.ownKeys(value).forEach((key) => {
                const keyValue = JSON.stringify(value[key]) || '';
                str += `${key} - ${keyValue.replace(/\"/g, "")}` + ",\n";
                if (typeof value[key] === "object") {
                  str += '\n'
                }

              })

            str += '\n'
            });
            values = str;
          }
          acc[key] = values;
        }
        return acc;
      }, {});
      return rowObj;
    });
    return filterRows
  }

}
export default FallbackWorkbookManager;
