/* eslint-disable max-len */
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { get, sumBy, countBy, mapValues } from 'lodash';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { ErrorMessage, Field, FieldArray } from 'formik';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import style from './DocExtractionUnitMixConfig.module.scss';
import {
  appendClassName,
  displayNumberInUSFormat,
  displayNumberInUSFormatWithOptions
} from '../../../utils/utils';
import { rentRollAffixTabViewName } from '../../../constants';

const { CONFIG_VIEW } = rentRollAffixTabViewName;

const getUnitCountsValue = (values, floorPlan, floorPlanConfigData) => {
  const sumV = sumBy(
    values[floorPlan].children.filter((_, index) => index !== 0),
    ({ unitCounts }) => +unitCounts
  );
  return +get(floorPlanConfigData, `['${floorPlan}'].unitCounts`) - sumV;
};

const getOccupiedUnitCountsValue = (values, floorPlan, floorPlanConfigData) => {
  const sumV = sumBy(
    values[floorPlan].children.filter((_, index) => index !== 0),
    ({ occupancyConfig: { occupiedUnitCount } }) => +occupiedUnitCount
  );
  return (
    +get(
      floorPlanConfigData,
      `['${floorPlan}'].occupancyConfig.occupiedUnitCount`
    ) - sumV
  );
};

const getVaccantUnitCountsValue = (values, floorPlan, floorPlanConfigData) => {
  const sumV = sumBy(
    values[floorPlan].children.filter((_, index) => index !== 0),
    ({ occupancyConfig: { vacantUnitCount } }) => +vacantUnitCount
  );
  return (
    +get(
      floorPlanConfigData,
      `['${floorPlan}'].occupancyConfig.vacantUnitCount`
    ) - sumV
  );
};

const removeZeroIndex = (arr) => arr.filter((_, index) => index !== 0);

const getNonRevenueUnitCountsValue = (
  values,
  floorPlan,
  floorPlanConfigData
) => {
  const sumV = sumBy(
    values[floorPlan].children.filter((_, index) => index !== 0),
    ({ nonRevenueUnits }) => +nonRevenueUnits
  );

  return +get(floorPlanConfigData, `['${floorPlan}'].nonRevenueUnits`) - sumV;
};

const getRevonatedUnitCountsValue = (
  values,
  floorPlan,
  floorPlanConfigData
) => {
  const sumV = sumBy(
    values[floorPlan].children.filter((_, index) => index !== 0),
    ({ renovationConfig: { renovated } }) => +renovated
  );

  return (
    +get(floorPlanConfigData, `['${floorPlan}'].renovationConfig.renovated`) -
    sumV
  );
};

const validateHorizontalUnitCounts = (values, floorPlan, index) => {
  const occupiedUnitCount = +get(
    values,
    `['${floorPlan}'].children.${index}.occupancyConfig.occupiedUnitCount`
  );
  const vacantUnitCount = +get(
    values,
    `['${floorPlan}'].children.${index}.occupancyConfig.vacantUnitCount`
  );
  const nonRevenueUnits = +get(
    values,
    `['${floorPlan}'].children.${index}.nonRevenueUnits`
  );
  const unitCounts = +get(
    values,
    `['${floorPlan}'].children.${index}.unitCounts`
  );

  if (occupiedUnitCount + vacantUnitCount + nonRevenueUnits > unitCounts) {
    return true;
  } else return false;
};

const SelectField = ({ options, field, form, ...rest }) => (
  <Select
    options={options}
    name={field.name}
    value={options ? options.find((option) => option.value == field.value) : ''}
    onChange={(option) => form.setFieldValue(field.name, option.value)}
    onBlur={(e) => {
      field.onBlur(e);
      form.setFieldTouched(field.name);
    }}
    {...rest}
  />
);

const AffixTableView = ({
  getAverageTotals,
  isMenuOpen,
  activeViewName,
  isActive,
  floorPlanConfigData,
  leaseTypeList = [],
  values,
  errors,
  floorPlanBedMapping,
  floorPlanBathMapping,
  setFieldValue
}) => {
  const [editableRow, setEditableRow] = useState({});
  const [openRow, setOpenRow] = useState(
    mapValues(floorPlanConfigData, () => true)
  );

  if (!isActive) {
    return null;
  }

  return (
    <>
      <div className={`position-relative `}>
        <div
          className={`${style.floorPlan} ${
            isMenuOpen ? `overflow-hidden` : 'overflow-auto'
          } ${activeViewName === CONFIG_VIEW ? 'configurefpWrapper' : ''}`}
        >
          <Table>
            <thead>
              <tr>
                <th className={style.action}> </th>
                <th style={{ width: '60px' }}>
                  Floor
                  <br /> Plans
                </th>
                <th className={style.bedBaths}>Beds</th>
                <th className={style.bedBaths}>Baths</th>
                <th className={style.bedBaths}>
                  Avg. <br /> Unit Size
                </th>
                <th>#Unit</th>
                <th>
                  #Occupied <br />
                  Units
                </th>
                <th>
                  #Vacant <br />
                  Units
                </th>
                <th>
                  #Non-Rev <br />
                  Units
                </th>
                <th>
                  Avg. <br /> Market Rent
                </th>
                <th>
                  Avg. <br />
                  Monthly Rent
                </th>
                <th className={style.leaseType}>Lease Type</th>
                <th>
                  #Renov <br />+ New
                </th>
                <th>
                  Avg. <br /> Renov Cost
                </th>
                <th>
                  Avg. <br /> Renov Bump
                </th>
                <th>
                  Avg. <br /> Subsidy
                </th>
              </tr>
            </thead>
            <tbody>
              {Reflect.ownKeys(floorPlanConfigData).map((floorPlan) => (
                <>
                  <tr key={floorPlan}>
                    <td className={style.action}>
                      <FieldArray
                        name={`['${floorPlan}'].children`}
                        render={(arrayHelpers) => (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            color={errors[floorPlan] ? '#cbcdcf' : '#2096f2'}
                            disabled={errors[floorPlan]}
                            title={
                              errors[floorPlan]
                                ? 'Please resolve error to add new row'
                                : 'Add new row'
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (errors[floorPlan]) {
                                return false;
                              }
                              const { children } = values[floorPlan];
                              const defaultLeaseType = leaseTypeList.map(
                                (value) => ({
                                  value,
                                  label: value
                                })
                              )[1].value;
                              if (children && children.length > 0) {
                                arrayHelpers.push({
                                  ...floorPlanConfigData[floorPlan],
                                  floorPlan,
                                  BD: values[floorPlan].BD,
                                  BA: values[floorPlan].BA,
                                  occupancyConfig: {
                                    vacantUnitCount: 0,
                                    occupiedUnitCount: 0
                                  },
                                  marketRentConfig: {
                                    total: 0,
                                    min: 0,
                                    max: 0,
                                    avg: floorPlanConfigData[floorPlan]
                                      .marketRentConfig.avg
                                  },
                                  monthlyRentConfig: {
                                    total: 0,
                                    min: 0,
                                    max: 0,
                                    avg: floorPlanConfigData[floorPlan]
                                      .occupiedMonthlyRentConfig.avg
                                  },
                                  unitCounts: 0,
                                  nonRevenueUnits: 0,
                                  leaseType: defaultLeaseType,
                                  renovationConfig: {
                                    avgRenovationBump: 0,
                                    avgRenovationCost: 0,
                                    renovationBump: 0,
                                    renovationCost: 0,
                                    ...floorPlanConfigData[floorPlan]
                                      .renovationConfig,
                                    renovated: 0
                                  },
                                  affordableConfig: {
                                    avgSubsidy: 0
                                  }
                                });

                                setEditableRow({
                                  [floorPlan]: children.length || 0
                                });
                              } else {
                                arrayHelpers.push({
                                  ...floorPlanConfigData[floorPlan],
                                  BD: values[floorPlan].BD,
                                  BA: values[floorPlan].BA,
                                  floorPlan,
                                  marketRentConfig: {
                                    total: 0,
                                    min: 0,
                                    max: 0,
                                    avg: floorPlanConfigData[floorPlan]
                                      .marketRentConfig.avg
                                  },
                                  monthlyRentConfig: {
                                    total: 0,
                                    min: 0,
                                    max: 0,
                                    avg: floorPlanConfigData[floorPlan]
                                      .occupiedMonthlyRentConfig.avg
                                  },
                                  renovationConfig: {
                                    ...floorPlanConfigData[floorPlan]
                                      .renovationConfig
                                  }
                                });
                                arrayHelpers.push({
                                  ...floorPlanConfigData[floorPlan],
                                  BD: values[floorPlan].BD,
                                  BA: values[floorPlan].BA,
                                  floorPlan,
                                  unitCounts: 0,
                                  marketRentConfig: {
                                    total: 0,
                                    min: 0,
                                    max: 0,
                                    avg: floorPlanConfigData[floorPlan]
                                      .marketRentConfig.avg
                                  },
                                  monthlyRentConfig: {
                                    total: 0,
                                    min: 0,
                                    max: 0,
                                    avg: floorPlanConfigData[floorPlan]
                                      .occupiedMonthlyRentConfig.avg
                                  },
                                  occupancyConfig: {
                                    vacantUnitCount: 0,
                                    occupiedUnitCount: 0
                                  },
                                  nonRevenueUnits: 0,
                                  leaseType: defaultLeaseType,
                                  renovationConfig: {
                                    ...floorPlanConfigData[floorPlan]
                                      .renovationConfig,
                                    renovated: 0
                                  },
                                  affordableConfig: {
                                    avgSubsidy: 0
                                  }
                                });
                                setEditableRow({ [floorPlan]: 1 });
                              }
                              setOpenRow({ ...openRow, [floorPlan]: true });
                            }}
                          />
                        )}
                      />
                      {values[floorPlan].children &&
                        values[floorPlan].children.length > 0 && (
                          <big
                            onClick={() => {
                              setOpenRow({
                                ...openRow,
                                [floorPlan]: !openRow[floorPlan]
                              });
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                openRow[floorPlan] ? faAngleUp : faAngleDown
                              }
                              color="#000"
                            />
                          </big>
                        )}
                    </td>
                    <td className="Floor Plan">
                      <span>{floorPlan}</span>
                    </td>
                    <td className="#Beds">
                      <Field
                        style={{
                          width: '50px'
                        }}
                        // component={SelectField}
                        placeholder=""
                        options={
                          floorPlanBedMapping &&
                          floorPlanBedMapping.map((category) => ({
                            value: category,
                            label: category
                          }))
                        }
                        name={`['${floorPlan}'].BD`}
                      >
                        {({ field, form, ...rest }) => (
                          <SelectField
                            options={
                              floorPlanBedMapping &&
                              floorPlanBedMapping.map((category) => ({
                                value: category,
                                label: category
                              }))
                            }
                            {...{ field, form, ...rest }}
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                              if (
                                values[floorPlan].children &&
                                values[floorPlan].children.length > 0
                              ) {
                                values[floorPlan].children.forEach(
                                  (_, index) => {
                                    form.setFieldValue(
                                      `['${floorPlan}'].children.${index}.BD`,
                                      option.value
                                    );
                                  }
                                );
                              }
                            }}
                            onBlur={(e) => {
                              field.onBlur(e);
                              form.setFieldTouched(field.name);
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="#Baths">
                      <Field
                        style={{
                          width: '50px'
                        }}
                        component={SelectField}
                        placeholder=""
                        options={
                          floorPlanBathMapping &&
                          floorPlanBathMapping.map((category) => ({
                            value: category,
                            label: category
                          }))
                        }
                        name={`['${floorPlan}'].BA`}
                      >
                        {({ field, form, ...rest }) => (
                          <SelectField
                            options={
                              floorPlanBathMapping &&
                              floorPlanBathMapping.map((category) => ({
                                value: category,
                                label: category
                              }))
                            }
                            {...{ field, form, ...rest }}
                            onChange={(option) => {
                              form.setFieldValue(field.name, option.value);
                              if (
                                values[floorPlan].children &&
                                values[floorPlan].children.length > 0
                              ) {
                                values[floorPlan].children.forEach(
                                  (_, index) => {
                                    form.setFieldValue(
                                      `['${floorPlan}'].children.${index}.BA`,
                                      option.value
                                    );
                                  }
                                );
                              }
                            }}
                            onBlur={(e) => {
                              field.onBlur(e);
                              form.setFieldTouched(field.name);
                            }}
                          />
                        )}
                      </Field>
                    </td>
                    <td className="Unit Size">
                      <span>
                        {displayNumberInUSFormat(
                          get(floorPlanConfigData[floorPlan], 'sqFtConfig.avg')
                        )}
                      </span>
                    </td>
                    <td className="#Units">
                      <span>
                        {displayNumberInUSFormat(
                          floorPlanConfigData[floorPlan].unitCounts
                        )}
                      </span>
                    </td>
                    <td className="#Occupied">
                      <span>
                        {displayNumberInUSFormat(
                          get(
                            floorPlanConfigData[floorPlan],
                            'occupancyConfig.occupiedUnitCount'
                          )
                        )}
                      </span>
                    </td>
                    <td className="#Vacant">
                      <span>
                        {displayNumberInUSFormat(
                          get(
                            floorPlanConfigData[floorPlan],
                            'occupancyConfig.vacantUnitCount'
                          )
                        )}
                      </span>
                    </td>
                    <td className="#Non-Rev">
                      <span>
                        {displayNumberInUSFormat(
                          get(floorPlanConfigData[floorPlan], 'nonRevenueUnits')
                        )}
                      </span>
                    </td>
                    <td className="Market Rent">
                      <span>
                        $
                        {displayNumberInUSFormat(
                          get(
                            floorPlanConfigData[floorPlan],
                            'marketRentConfig.avg'
                          )
                        )}
                      </span>
                    </td>
                    <td className="Monthly Rent">
                      <span>
                        $
                        {displayNumberInUSFormat(
                          get(
                            floorPlanConfigData[floorPlan],
                            'occupiedMonthlyRentConfig.avg'
                          )
                        )}
                      </span>
                    </td>
                    <td className="leaseType">
                      <Field
                        component={SelectField}
                        placeholder=""
                        options={leaseTypeList.map((value) => ({
                          value,
                          label: value
                        }))}
                        name={`['${floorPlan}'].leaseType`}
                      />
                    </td>
                    <td className="Renov+New">
                      <span>
                        {get(
                          floorPlanConfigData[floorPlan],
                          'renovationConfig.renovated',
                          0
                        )}
                      </span>
                    </td>
                    <td className="avg renovation cost">
                      <span>
                        $
                        {displayNumberInUSFormatWithOptions(
                          get(
                            floorPlanConfigData[floorPlan],
                            'renovationConfig.avgRenovationCost',
                            0
                          ),
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )}
                      </span>
                    </td>
                    <td className="avg renovation bump">
                      <span>
                        $
                        {displayNumberInUSFormatWithOptions(
                          get(
                            floorPlanConfigData[floorPlan],
                            'renovationConfig.avgRenovationBump',
                            0
                          ),
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )}
                      </span>
                    </td>
                    <td className="avg subsidy">
                      <span>
                        $
                        {displayNumberInUSFormatWithOptions(
                          get(
                            floorPlanConfigData[floorPlan],
                            'affordableConfig.avgSubsidy',
                            0
                          ),
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )}
                      </span>
                    </td>
                  </tr>
                  {values[floorPlan].children &&
                    values[floorPlan].children.length > 0 &&
                    openRow[floorPlan] && (
                      <FieldArray
                        name={`['${floorPlan}'].children`}
                        render={(arrayHelpers) => (
                          <>
                            {values[floorPlan].children.map(
                              (floorPlanObj, index) => (
                                  <tr
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`fp__${index}`}
                                    className={appendClassName(
                                      editableRow[floorPlan] !== index
                                        ? style.parentRow
                                        : style.childRow,
                                      index === 0 ? style.firstRow : ''
                                    )}
                                    onClick={() => {
                                      if (index !== 0) {
                                        setEditableRow({
                                          [floorPlan]: index
                                        });
                                      }
                                    }}
                                  >
                                    <td className={style.action}>
                                      {(index > 0 ||
                                        values[floorPlan].children.length ===
                                          1) && (
                                        <>
                                          <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            color="#b92121ab"
                                            disabled={index === 0}
                                            onClick={() => {
                                              if (
                                                values[floorPlan].children
                                                  .length === 2
                                              ) {
                                                arrayHelpers.remove(index);
                                                arrayHelpers.remove(0);
                                              } else {
                                                arrayHelpers.remove(index);
                                                const fieldName1 = `['${floorPlan}'].children.0.unitCounts`;
                                                const fieldName2 = `['${floorPlan}'].children.${index}.unitCounts`;
                                                const unitCounts =
                                                  +get(values, fieldName1) +
                                                  +get(values, fieldName2);
                                                setFieldValue(
                                                  fieldName1,
                                                  unitCounts
                                                );

                                                const occFieldName1 = `['${floorPlan}'].children.0.occupancyConfig.occupiedUnitCount`;
                                                const occFieldName2 = `['${floorPlan}'].children.${index}.occupancyConfig.occupiedUnitCount`;
                                                const occuUnitCounts =
                                                  +get(values, occFieldName1) +
                                                  +get(values, occFieldName2);
                                                setFieldValue(
                                                  occFieldName1,
                                                  occuUnitCounts
                                                );

                                                const vacFieldName1 = `['${floorPlan}'].children.0.occupancyConfig.vacantUnitCount`;
                                                const vacFieldName2 = `['${floorPlan}'].children.${index}.occupancyConfig.vacantUnitCount`;
                                                const vacUnitCounts =
                                                  +get(values, vacFieldName1) +
                                                  +get(values, vacFieldName2);
                                                setFieldValue(
                                                  vacFieldName1,
                                                  vacUnitCounts
                                                );

                                                const nonRenovFieldName1 = `['${floorPlan}'].children.0.nonRevenueUnits`;
                                                const nonRenovFieldName2 = `['${floorPlan}'].children.${index}.nonRevenueUnits`;
                                                const nonRenovUnitCounts =
                                                  +get(
                                                    values,
                                                    nonRenovFieldName1
                                                  ) +
                                                  +get(
                                                    values,
                                                    nonRenovFieldName2
                                                  );
                                                setFieldValue(
                                                  nonRenovFieldName1,
                                                  nonRenovUnitCounts
                                                );
                                              }
                                              if (
                                                values[floorPlan].children
                                                  .length === 1
                                              ) {
                                                floorPlanConfigData[
                                                  floorPlan
                                                ].isModfied = false;
                                              }
                                            }}
                                          />
                                        </>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <>
                                          <ErrorMessage
                                            name={`['${floorPlan}'].children.${index}.floorPlan`}
                                          />
                                          <Field
                                            style={{
                                              width: '60px'
                                            }}
                                            name={`['${floorPlan}'].children.${index}.floorPlan`}
                                          />
                                        </>
                                      ) : (
                                        <span>{floorPlanObj['floorPlan']}</span>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <Field
                                          style={{
                                            width: '60px'
                                          }}
                                          component={SelectField}
                                          placeholder=""
                                          options={
                                            floorPlanBedMapping &&
                                            floorPlanBedMapping.map(
                                              (category) => ({
                                                value: category,
                                                label: category
                                              })
                                            )
                                          }
                                          name={`['${floorPlan}'].children.${index}.BD`}
                                        />
                                      ) : (
                                        <span>{floorPlanObj.BD}</span>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <Field
                                          style={{
                                            width: '60px'
                                          }}
                                          component={SelectField}
                                          placeholder=""
                                          options={
                                            floorPlanBathMapping &&
                                            floorPlanBathMapping.map(
                                              (category) => ({
                                                value: category,
                                                label: category
                                              })
                                            )
                                          }
                                          name={`['${floorPlan}'].children.${index}.BA`}
                                        />
                                      ) : (
                                        <span>{floorPlanObj.BA}</span>
                                      )}
                                    </td>
                                    <td>
                                      <span>
                                        {displayNumberInUSFormat(
                                          get(floorPlanObj, 'sqFtConfig.avg')
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <>
                                          <ErrorMessage
                                            className="errorMessage"
                                            name={`['${floorPlan}'].children.${index}.unitCounts`}
                                          />
                                          <Field
                                            style={{
                                              width: '60px'
                                            }}
                                            validate={(value) => {
                                              const unitCountValue =
                                                getUnitCountsValue(
                                                  values,
                                                  floorPlan,
                                                  floorPlanConfigData
                                                );

                                              if (unitCountValue < 0) {
                                                return (
                                                  <small>
                                                    Values exceeding maximum
                                                    limit
                                                  </small>
                                                );
                                              }

                                              if (
                                                sumBy(
                                                  removeZeroIndex(
                                                    values[floorPlan].children
                                                  ),
                                                  ({ unitCounts }) => +unitCounts
                                                ) >
                                                +values[floorPlan].unitCounts
                                              ) {
                                                return (
                                                  <small>
                                                    Unit counts sum should not
                                                    exceed total count
                                                  </small>
                                                );
                                              }
                                              return false;
                                            }}
                                            name={`['${floorPlan}'].children.${index}.unitCounts`}
                                          >
                                            {({ field, form }) => (
                                              <input
                                                {...field}
                                                onBlur={(e) => {
                                                  const unitCountValue =
                                                    getUnitCountsValue(
                                                      values,
                                                      floorPlan,
                                                      floorPlanConfigData
                                                    );
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.0.unitCounts`,
                                                    unitCountValue < 0
                                                      ? 0
                                                      : unitCountValue
                                                  );

                                                  const { value } = e.target;
                                                  if (!value) {
                                                    form.setFieldValue(
                                                      `['${floorPlan}'].children.${index}.unitCounts`,
                                                      0
                                                    );
                                                  }
                                                  field.onBlur(e);
                                                }}
                                                style={{ width: '60px' }}
                                              />
                                            )}
                                          </Field>
                                        </>
                                      ) : (
                                        <span>
                                          {displayNumberInUSFormat(
                                            get(floorPlanObj, 'unitCounts')
                                          )}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <>
                                          <ErrorMessage
                                            name={`['${floorPlan}'].children.${index}.occupancyConfig.occupiedUnitCount`}
                                          />
                                          <Field
                                            style={{
                                              width: '60px'
                                            }}
                                            validate={(value) => {
                                              const unitCountValue =
                                                getOccupiedUnitCountsValue(
                                                  values,
                                                  floorPlan,
                                                  floorPlanConfigData
                                                );

                                              if (unitCountValue < 0) {
                                                return (
                                                  <small>
                                                    Values exceeding maximum
                                                    limit
                                                  </small>
                                                );
                                              }

                                              if (
                                                validateHorizontalUnitCounts(
                                                  values,
                                                  floorPlan,
                                                  index
                                                )
                                              ) {
                                                return (
                                                  <small>
                                                    Exceeding Total unit counts
                                                  </small>
                                                );
                                              }

                                              if (
                                                sumBy(
                                                  removeZeroIndex(
                                                    values[floorPlan].children
                                                  ),
                                                  ({
                                                    occupancyConfig: {
                                                      occupiedUnitCount
                                                    }
                                                  }) => +occupiedUnitCount
                                                ) >
                                                +values[floorPlan]
                                                  .occupancyConfig
                                                  .occupiedUnitCount
                                              ) {
                                                return (
                                                  <small>
                                                    Occupancy unit sum should
                                                    not exceed total count
                                                  </small>
                                                );
                                              }
                                              return false;
                                            }}
                                            name={`['${floorPlan}'].children.${index}.occupancyConfig.occupiedUnitCount`}
                                          >
                                            {({ field, form }) => (
                                              <input
                                                {...field}
                                                onBlur={(e) => {
                                                  const unitCountValue =
                                                    getOccupiedUnitCountsValue(
                                                      values,
                                                      floorPlan,
                                                      floorPlanConfigData
                                                    );
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.0.occupancyConfig.occupiedUnitCount`,
                                                    unitCountValue < 0
                                                      ? 0
                                                      : unitCountValue
                                                  );

                                                  const { value } = e.target;
                                                  if (!value) {
                                                    form.setFieldValue(
                                                      `['${floorPlan}'].children.${index}.occupancyConfig.occupiedUnitCount`,
                                                      0
                                                    );
                                                  }

                                                  field.onBlur(e);
                                                }}
                                                style={{ width: '60px' }}
                                              />
                                            )}
                                          </Field>
                                        </>
                                      ) : (
                                        <span>
                                          {displayNumberInUSFormat(
                                            get(
                                              floorPlanObj,
                                              'occupancyConfig.occupiedUnitCount'
                                            )
                                          )}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <>
                                          <ErrorMessage
                                            name={`['${floorPlan}'].children.${index}.occupancyConfig.vacantUnitCount`}
                                          />
                                          <Field
                                            style={{
                                              width: '60px'
                                            }}
                                            validate={() => {
                                              const unitCountValue =
                                                getVaccantUnitCountsValue(
                                                  values,
                                                  floorPlan,
                                                  floorPlanConfigData
                                                );

                                              if (unitCountValue < 0) {
                                                return (
                                                  <small>
                                                    Values exceeding maximum
                                                    limit
                                                  </small>
                                                );
                                              }
                                              if (
                                                validateHorizontalUnitCounts(
                                                  values,
                                                  floorPlan,
                                                  index
                                                )
                                              ) {
                                                return (
                                                  <small>
                                                    Exceeding Total unit counts
                                                  </small>
                                                );
                                              }
                                              if (
                                                sumBy(
                                                  removeZeroIndex(
                                                    values[floorPlan].children
                                                  ),
                                                  ({
                                                    occupancyConfig: {
                                                      vacantUnitCount
                                                    }
                                                  }) => +vacantUnitCount
                                                ) >
                                                +values[floorPlan]
                                                  .occupancyConfig
                                                  .vacantUnitCount
                                              ) {
                                                return (
                                                  <small>
                                                    Occupancy status sum should
                                                    not exceed total count
                                                  </small>
                                                );
                                              }
                                              return false;
                                            }}
                                            name={`['${floorPlan}'].children.${index}.occupancyConfig.vacantUnitCount`}
                                          >
                                            {({ field, form }) => (
                                              <input
                                                {...field}
                                                onBlur={(e) => {
                                                  const unitCountValue =
                                                    getVaccantUnitCountsValue(
                                                      values,
                                                      floorPlan,
                                                      floorPlanConfigData
                                                    );
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.0.occupancyConfig.vacantUnitCount`,
                                                    unitCountValue < 0
                                                      ? 0
                                                      : unitCountValue
                                                  );

                                                  const { value } = e.target;
                                                  if (!value) {
                                                    form.setFieldValue(
                                                      `['${floorPlan}'].children.${index}.occupancyConfig.vacantUnitCount`,
                                                      0
                                                    );
                                                  }

                                                  field.onBlur(e);
                                                }}
                                                style={{ width: '60px' }}
                                              />
                                            )}
                                          </Field>
                                        </>
                                      ) : (
                                        <span>
                                          {displayNumberInUSFormat(
                                            get(
                                              floorPlanObj,
                                              'occupancyConfig.vacantUnitCount'
                                            )
                                          )}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <>
                                          <ErrorMessage
                                            name={`['${floorPlan}'].children.${index}.nonRevenueUnits`}
                                          />
                                          <Field
                                            style={{
                                              width: '60px'
                                            }}
                                            validate={(value) => {
                                              const unitCountValue =
                                                getNonRevenueUnitCountsValue(
                                                  values,
                                                  floorPlan,
                                                  floorPlanConfigData
                                                );
                                              if (unitCountValue < 0) {
                                                return (
                                                  <small>
                                                    Values exceeding maximum
                                                    limit
                                                  </small>
                                                );
                                              }

                                              if (
                                                validateHorizontalUnitCounts(
                                                  values,
                                                  floorPlan,
                                                  index
                                                )
                                              ) {
                                                return (
                                                  <small>
                                                    Exceeding Total unit counts
                                                  </small>
                                                );
                                              }

                                              if (
                                                sumBy(
                                                  removeZeroIndex(
                                                    values[floorPlan].children
                                                  ),
                                                  ({ nonRevenueUnits }) =>
                                                    +nonRevenueUnits
                                                ) >
                                                +values[floorPlan]
                                                  .nonRevenueUnits
                                              ) {
                                                return (
                                                  <small>
                                                    Non Renov&rsquo;s sum should not
                                                    exceed total Non Renov Units
                                                  </small>
                                                );
                                              }
                                              return false;
                                            }}
                                            name={`['${floorPlan}'].children.${index}.nonRevenueUnits`}
                                          >
                                            {({ field, form }) => (
                                              <input
                                                {...field}
                                                onBlur={(e) => {
                                                  const unitCountValue =
                                                    getNonRevenueUnitCountsValue(
                                                      values,
                                                      floorPlan,
                                                      floorPlanConfigData
                                                    );
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.0.nonRevenueUnits`,
                                                    unitCountValue < 0
                                                      ? 0
                                                      : unitCountValue
                                                  );

                                                  const { value } = e.target;
                                                  if (!value) {
                                                    form.setFieldValue(
                                                      `['${floorPlan}'].children.${index}.nonRevenueUnits`,
                                                      0
                                                    );
                                                  }

                                                  field.onBlur(e);
                                                }}
                                                style={{ width: '60px' }}
                                              />
                                            )}
                                          </Field>
                                        </>
                                      ) : (
                                        <span>
                                          {displayNumberInUSFormat(
                                            get(floorPlanObj, 'nonRevenueUnits')
                                          )}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <Field
                                          style={{
                                            width: '60px'
                                          }}
                                          name={`['${floorPlan}'].children.${index}.marketRentConfig.avg`}
                                        >
                                          {({ field, form }) => (
                                            <input
                                              {...field}
                                              onBlur={(e) => {
                                                const { value } = e.target;
                                                if (!value) {
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.${index}.marketRentConfig.avg`,
                                                    0
                                                  );
                                                }
                                                field.onBlur(e);
                                              }}
                                              style={{ width: '60px' }}
                                            />
                                          )}
                                        </Field>
                                      ) : (
                                        <span>
                                          $
                                          {displayNumberInUSFormat(
                                            get(
                                              floorPlanObj,
                                              'marketRentConfig.avg'
                                            )
                                          )}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <>
                                          <Field
                                            style={{
                                              width: '60px'
                                            }}
                                            name={`['${floorPlan}'].children.${index}.occupiedMonthlyRentConfig.avg`}
                                          >
                                            {({ field, form }) => (
                                              <input
                                                {...field}
                                                onBlur={(e) => {
                                                  const { value } = e.target;
                                                  if (!value) {
                                                    form.setFieldValue(
                                                      `['${floorPlan}'].children.${index}.occupiedMonthlyRentConfig.avg`,
                                                      0
                                                    );
                                                  }
                                                  field.onBlur(e);
                                                }}
                                                style={{ width: '60px' }}
                                              />
                                            )}
                                          </Field>
                                        </>
                                      ) : (
                                        <span>
                                          $
                                          {displayNumberInUSFormat(
                                            get(
                                              floorPlanObj,
                                              'occupiedMonthlyRentConfig.avg'
                                            )
                                          )}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <>
                                          {get(
                                            errors,
                                            `['${floorPlan}'].children.${index}.leaseType`
                                          )}
                                          <Field
                                            style={{
                                              width: '60px'
                                            }}
                                            component={SelectField}
                                            validate={(value) => {
                                              if (!value) {
                                                return (
                                                  <small>
                                                    Lease type is required!
                                                  </small>
                                                );
                                              }
                                              const { children } =
                                                values[floorPlan];
                                              const uniqueRow = countBy(
                                                children,
                                                (item) =>
                                                  item.leaseType +
                                                  item.floorPlan
                                              );

                                              const key =
                                                children[index].leaseType +
                                                children[index].floorPlan;

                                              if (uniqueRow[key] > 1) {
                                                return (
                                                  <small>
                                                    Lease type should be
                                                    different
                                                  </small>
                                                );
                                              }

                                              return false;
                                            }}
                                            options={leaseTypeList.map(
                                              (value) => ({
                                                value,
                                                label: value
                                              })
                                            )}
                                            name={`['${floorPlan}'].children.${index}.leaseType`}
                                          >
                                            {({ field, form, ...rest }) => (
                                              <SelectField
                                                options={leaseTypeList.map(
                                                  (value) => ({
                                                    value,
                                                    label: value
                                                  })
                                                )}
                                                {...{ field, form, ...rest }}
                                                onBlur={(e) => {
                                                  field.onBlur(e);
                                                  form.setFieldTouched(
                                                    field.name
                                                  );
                                                  const value = get(
                                                    values,
                                                    `['${floorPlan}'].children.${index}.leaseType`
                                                  );
                                                  if (!value) {
                                                    form.setFieldValue(
                                                      `['${floorPlan}'].children.${index}.leaseType`,
                                                      leaseTypeList.map(
                                                        (value) => ({
                                                          value,
                                                          label: value
                                                        })
                                                      )[1].value
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          </Field>
                                        </>
                                      ) : (
                                        <span>
                                          {get(floorPlanObj, 'leaseType')}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      {editableRow[floorPlan] === index ? (
                                        <>
                                          <ErrorMessage
                                            name={`['${floorPlan}'].children.${index}.renovationConfig.renovated`}
                                          />
                                          <Field
                                            style={{
                                              width: '60px'
                                            }}
                                            validate={(value) => {
                                              const unitCountValue =
                                                getRevonatedUnitCountsValue(
                                                  values,
                                                  floorPlan,
                                                  floorPlanConfigData
                                                );
                                              if (unitCountValue < 0) {
                                                return (
                                                  <small>
                                                    Values exceeding maximum
                                                    limit
                                                  </small>
                                                );
                                              }

                                              if (
                                                sumBy(
                                                  removeZeroIndex(
                                                    values[floorPlan].children
                                                  ),
                                                  ({
                                                    renovationConfig: {
                                                      renovated
                                                    }
                                                  }) => +renovated
                                                ) >
                                                +values[floorPlan]
                                                  .renovationConfig.renovated
                                              ) {
                                                return (
                                                  <small>
                                                    Renovated sum should not
                                                    exceed total Renovated Units
                                                  </small>
                                                );
                                              }
                                              return false;
                                            }}
                                            name={`['${floorPlan}'].children.${index}.renovationConfig.renovated`}
                                          >
                                            {({ field, form }) => (
                                              <input
                                                {...field}
                                                onBlur={(e) => {
                                                  const unitCountValue =
                                                    getRevonatedUnitCountsValue(
                                                      values,
                                                      floorPlan,
                                                      floorPlanConfigData
                                                    );
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.0.renovationConfig.renovated`,
                                                    unitCountValue < 0
                                                      ? 0
                                                      : unitCountValue
                                                  );

                                                  const { value } = e.target;
                                                  if (!value) {
                                                    form.setFieldValue(
                                                      `['${floorPlan}'].children.${index}.renovationConfig.renovated`,
                                                      0
                                                    );
                                                  }
                                                  field.onBlur(e);
                                                }}
                                                style={{ width: '60px' }}
                                              />
                                            )}
                                          </Field>
                                        </>
                                      ) : (
                                        <span>
                                          {displayNumberInUSFormat(
                                            get(
                                              floorPlanObj,
                                              'renovationConfig.renovated',
                                              0
                                            )
                                          )}
                                        </span>
                                      )}
                                    </td>

                                    <td className="avg renovation cost">
                                      {editableRow[floorPlan] === index ? (
                                        <Field
                                          style={{
                                            width: '60px'
                                          }}
                                          name={`['${floorPlan}'].children.${index}.renovationConfig.avgRenovationCost`}
                                        >
                                          {({ field, form }) => (
                                            <input
                                              {...field}
                                              onBlur={(e) => {
                                                const { value } = e.target;
                                                if (!value) {
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.${index}.renovationConfig.avgRenovationCost`,
                                                    0
                                                  );
                                                }
                                                field.onBlur(e);
                                              }}
                                              style={{ width: '60px' }}
                                            />
                                          )}
                                        </Field>
                                      ) : (
                                        <span>
                                          $
                                          {displayNumberInUSFormatWithOptions(
                                            get(
                                              floorPlanObj,
                                              'renovationConfig.avgRenovationCost',
                                              0
                                            ),
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }
                                          )}
                                        </span>
                                      )}
                                    </td>
                                    <td className="avg renovation bump">
                                      {editableRow[floorPlan] === index ? (
                                        <Field
                                          style={{
                                            width: '60px'
                                          }}
                                          name={`['${floorPlan}'].children.${index}.renovationConfig.avgRenovationBump`}
                                        >
                                          {({ field, form }) => (
                                            <input
                                              {...field}
                                              onBlur={(e) => {
                                                const { value } = e.target;
                                                if (!value) {
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.${index}.renovationConfig.avgRenovationBump`,
                                                    0
                                                  );
                                                }
                                                field.onBlur(e);
                                              }}
                                              style={{ width: '60px' }}
                                            />
                                          )}
                                        </Field>
                                      ) : (
                                        <span>
                                          $
                                          {displayNumberInUSFormatWithOptions(
                                            get(
                                              floorPlanObj,
                                              'renovationConfig.avgRenovationBump',
                                              0
                                            ),
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }
                                          )}
                                        </span>
                                      )}
                                    </td>
                                    <td className="avg subsidy">
                                      {editableRow[floorPlan] === index ? (
                                        <Field
                                          style={{
                                            width: '60px'
                                          }}
                                          name={`['${floorPlan}'].children.${index}.affordableConfig.avgSubsidy`}
                                        >
                                          {({ field, form }) => (
                                            <input
                                              {...field}
                                              onBlur={(e) => {
                                                const { value } = e.target;
                                                if (!value) {
                                                  form.setFieldValue(
                                                    `['${floorPlan}'].children.${index}.affordableConfig.avgSubsidy`,
                                                    0
                                                  );
                                                }
                                                field.onBlur(e);
                                              }}
                                              style={{ width: '60px' }}
                                            />
                                          )}
                                        </Field>
                                      ) : (
                                        <span>
                                          $
                                          {displayNumberInUSFormatWithOptions(
                                            get(
                                              floorPlanObj,
                                              'affordableConfig.avgSubsidy',
                                              0
                                            ),
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2
                                            }
                                          )}
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                )
                            )}
                          </>
                        )}
                      />
                    )}
                </>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default AffixTableView;
