import React from 'react';
import FeatureFlagsList from '../../app/FeatureFlagsList/FeatureFlagsList';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function FeatureFlagsPage() {
  return (
    <SuperAdminDashboard>
      <FeatureFlagsList />
    </SuperAdminDashboard>
  );
}
export default FeatureFlagsPage;
