import React, { useState } from 'react';
import ReactSelect from 'react-select';

function DocExtractionAffixLeaseChartFilter({ yearRange, selectedYear, onFilterChange }) {
  const [currentChartYear, setCurrentChartYear] = useState({ value: selectedYear, label: selectedYear });
  return (
    <>
      <div className="selectbox">
        <ReactSelect
          classNamePrefix="react-select chargeCodeSelect chartDropdown"
          placeholder="Select Year"
          defaultValue={currentChartYear}
          maxMenuHeight={180}
          menuPlacement="auto"
          menuPosition="fixed"
          options={yearRange && yearRange.map(yearName => ({ value: yearName, label: yearName }))}
          onChange={
            (year) => {
              setCurrentChartYear({ ...year, ...currentChartYear });
              onFilterChange(year.value);
            }
          }
        />
      </div>

    </>
  );
}

export default DocExtractionAffixLeaseChartFilter;
