import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import ChangePasswordFormComponent from './ChangePasswordFormComponent';
import messages from '../../../../locales';
import { PASSWORD_SCHEMA } from '../../../constants';
import useClikGatewayUrl from '../../../hooks/useClikGatewayUrl';

const formSchema = Yup.object().shape({
  oldPassword: Yup.string().required(messages.errors.auth.currentPassword),
  newPassword: PASSWORD_SCHEMA,
  confirmPassword: Yup.string().required(messages.errors.auth.reenterPassword)
    .oneOf([Yup.ref('newPassword'), null], messages.errors.auth.passwordMismatch)
});

function ChangePasswordForm({ authService }) {
  const { cgOriginUrl } = useClikGatewayUrl(authService);

  async function onSubmit(values, actions) {
    actions.setStatus({});

    try {
      await authService.changePassword(values);
      actions.setStatus({ success: 'Your password was changed successfully.' });
    } catch (e) {
      console.log(e);
      if (e.status === 400) {
        actions.setStatus({ error: e.message });
      }
    }

    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}
      validationSchema={formSchema}
      onSubmit={onSubmit}
      component={() => <ChangePasswordFormComponent cgOriginUrl={cgOriginUrl}/>}
    />
  );
}

export default withServices('authService')(ChangePasswordForm);
