export default class BaseEvent {
  static instance = null;

  static get() {
    if (!this.instance) {
      this.instance = new this();
    }
    return this.instance;
  }

  suspend(spreadsheet) {
    if (!spreadsheet.spreadsheet) {
      spreadsheet.suspend();
    } else {
      spreadsheet.spreadsheet.suspend();
    }
  }

  resume(spreadsheet) {
    if (!spreadsheet.spreadsheet) {
      spreadsheet.resume();
    } else {
      spreadsheet.spreadsheet.resume();
    }
  }

  handleEvent(spreadsheet, ...args) {
    this.suspend(spreadsheet);
    this.handle(spreadsheet, ...args);
    this.resume(spreadsheet);
  }

  handle() {
    throw new Error('Implement in derived event handler');
  }
}
