import React, { useState, useCallback } from "react";
import { withServices } from 'reaf';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import IconButton from "../../core/Button/IconButton";
import ProjectPopups from "../ProjectPopups/ProjectPopups";

const EditDictionary = ({
    project, onEditSuccess, dictionaryService
}) => {
    const [showEditDictionary, setShowEditDictionary] = useState(false);
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const onEdit = useCallback(async() => {
      setIsLoading(true);
      setShowEditDictionary(true);
      const dictionaryResponse = await dictionaryService.getDictionaryData(project);
      setData(dictionaryResponse);
      setIsLoading(false);
    }, [project]);
    return(
        <>
          <div onClick={onEdit}>
            <IconButton className="smallIconButton" placement="left" title="Edit Dictionary" icon={faEdit} />
          </div>
          <ProjectPopups 
            onEditSuccess={onEditSuccess} 
            showEditDictionary={showEditDictionary} 
            project={project}
            onHide={() => setShowEditDictionary(false)} 
            data={data}
            isLoading={isLoading}
            setShowEditDictionary={setShowEditDictionary}
          />
        </>
    )
}

export default withServices('dictionaryService')(EditDictionary);

