import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import FileUploader from '../FileUploader/FileUploader';
import { VALID_PROJECT_EXPORTER_MAPPING_FUNC_FILE_EXTENSION, VALID_PROJECT_EXPORTER_MAPPER_FILE_EXTENSION } from '../../../constants';

function ProjectExporterFormComponent({
  status, isSubmitting, bindSubmission, submitForm, mapperFileName, selectedMapperFile, onMapperFileChange, 
  onRemoveMapperFile, mappingFunctionFileName, selectedMappingFunctionFile, onMappingFunctionFileChange, 
  onRemoveMappingFunctionFile
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <span style={{ whiteSpace: "pre-line" }}>{status.error}</span>
          </div>
        )
      }
      <Form>
        <div className="form-group">
          <label htmlFor="projectExporter.name">Name</label>
          <Field id="projectExporter.name" className="form-control" name="projectExporter.name" type="text" placeholder="Exporter Name" />
          <FieldErrorMessage name="projectExporter.name" />
        </div>
        <div className="form-group">
          <label htmlFor="projectExporter.outputFormat">Output Format</label>
          <Field id="projectExporter.outputFormat" className="form-control" name="projectExporter.outputFormat" type="text" readOnly />
          <FieldErrorMessage name="templateName" />
        </div>

        <FileUploader
          onFileChange={onMapperFileChange}
          validExtensions={VALID_PROJECT_EXPORTER_MAPPER_FILE_EXTENSION}
          onRemoveFile={onRemoveMapperFile}
          title="Mapper"
          selectedFile={selectedMapperFile}
          fileName={mapperFileName}
        />
        <FileUploader
          onFileChange={onMappingFunctionFileChange}
          validExtensions={VALID_PROJECT_EXPORTER_MAPPING_FUNC_FILE_EXTENSION}
          onRemoveFile={onRemoveMappingFunctionFile}
          title="Mapping Function"
          selectedFile={selectedMappingFunctionFile}
          fileName={mappingFunctionFileName}
        />
      </Form>
    </div>
  );
}

export default ProjectExporterFormComponent;
