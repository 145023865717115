import React from 'react'
import { Card } from 'react-bootstrap';
import SubscriptionForm from '../SubscriptionForm/SubscriptionForm';

export default function AddCardDetailComponent() {
  return (
    <Card className="form-card payment-form-card">
      <Card.Body className="form-card-body">
        <SubscriptionForm />
      </Card.Body>
    </Card>
  )
}
