import React, { useEffect, useState } from 'react';
import { Form, Field } from 'formik';
import { withServices } from 'reaf';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import DatePicker from '../../core/DatePicker/DatePicker';
import ReactSelect from "react-select";

function UserlogqueryFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue, companyService
}) {
  const [selectedValue, setSelectedValue] = useState('');
  const [dropdownList, setDropdownList] = useState([]);
  bindSubmission(submitForm, isSubmitting);
  useEffect(() => {
    async function fetchData() {
    const result = await companyService.getAllCompanies();
    setDropdownList(result.rows);
    }
    fetchData();
  }, [])

  return (
    <div>
      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <Form>
        <div className="form-row">
          <div className="col-md-12 mt-2">
            <h5>Date Range for user log</h5>
            <hr />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="stateDate">Start date</label>
            <Field 
              id="startDate" 
              className="form-control" 
              name="startDate" 
              component={DatePicker}
              selected={values.startDate}
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/DD/YYYY"
            />
            <FieldErrorMessage name="startDate" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="stateDate">End date</label>
            <Field 
              id="endDate" 
              className="form-control" 
              name="endDate" 
              component={DatePicker}
              selected={values.endDate}
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/DD/YYYY"
            />
            <FieldErrorMessage name="endDate" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="company">Company</label>
              <ReactSelect 
                id="company"
                placeholder='Select Company'
                value={selectedValue||values.company}
                name='company'
                options={dropdownList.map(option => ({value: option.uuid, label: option.name}))}
                onChange={selectedCompany => {
                  setFieldValue('company', selectedCompany);
                  setSelectedValue(selectedCompany);
                }}
              />
            <FieldErrorMessage name="company" />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default withServices('companyService')(UserlogqueryFormComponent);
