import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import PasswordViewer from '../PasswordViewer/PasswordViewer';

function AccountsExecutiveFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <Form>
        <h5>Accounts Executive Details</h5>
        <hr />
        <div className="form-group">
          <label htmlFor="accountsExecutive.firstName">First Name</label>
          <Field id="accountsExecutive.firstName" className="form-control" name="accountsExecutive.firstName" placeholder="John" type="text" />
          <FieldErrorMessage name="accountsExecutive.firstName" />
        </div>
        <div className="form-group">
          <label htmlFor="accountsExecutive.lastName">Last Name</label>
          <Field id="accountsExecutive.lastName" className="form-control" name="accountsExecutive.lastName" placeholder="Doe" type="text" />
          <FieldErrorMessage name="accountsExecutive.lastName" />
        </div>

        <div className="form-group">
          <label htmlFor="accountsExecutive.email">Email</label>
          <Field id="accountsExecutive.email" className="form-control" name="accountsExecutive.email" placeholder="john_doe@clik.ai" type="email" />
          <FieldErrorMessage name="accountsExecutive.email" />
        </div>

        <div className="form-group passwordGroupRow">
          <label htmlFor="accountsExecutive.password">Password</label>
          <PasswordViewer id="accountsExecutive.password" name="accountsExecutive.password" placeholder="Password@123" />
          <FieldErrorMessage name="accountsExecutive.password" />
        </div>

      </Form>
    </div>
  );
}

export default AccountsExecutiveFormComponent;
