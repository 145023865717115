import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Row, Button } from 'react-bootstrap';
import { sumBy, isEqual, get, uniqBy, pick } from 'lodash';
import { setUnitMixSummary } from '../../../store/currentDocument';
import {
  currentDocumentFloorPlanBedSelector,
  currentDocumentFloorPlanBathSelector,
  unitMixSummaryConfigSelector,
  leaseTypeListSelector,
  currentDocumentSelector,
  readOnlyAccessSelector
} from '../../../store/selectors';
import DocExtractionAffixTableWrapper from '../DocExtractionAffixTableWrapper/DocExtractionAffixTableWrapper';
import style from './DocExtractionUnitMixConfig.module.scss';
import {
  displayNumberInUSFormat,
  DisplayAmountFormat
} from '../../../utils/utils';
import { rentRollAffixTabViewName } from '../../../constants';
import messages from '../../../../locales/en-US';
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import AffixTableView from './AffixTableView';
import RentRollConfigHeader from '../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionWithoutSummary from '../DocExtractionSummary/DocExtractionWithoutSummary';

const { TABLE_VIEW } = rentRollAffixTabViewName;

const message = 'Do you want to save changes?';

const getUnitMixRow = (floorPlan, unitMixSummaryConfigData) => [{
  'Floor Plans': floorPlan,
  'Beds': +unitMixSummaryConfigData.BD ? +unitMixSummaryConfigData.BD : 0,
  'Baths': +unitMixSummaryConfigData.BA,
  'Avg. Unit Size': get(unitMixSummaryConfigData, 'sqFtConfig.avg'),
  '#Unit': unitMixSummaryConfigData.unitCounts,
  '#Occupied Units': get(unitMixSummaryConfigData, 'occupancyConfig.occupiedUnitCount'),
  '#Vacant Units': get(unitMixSummaryConfigData, 'occupancyConfig.vacantUnitCount'),
  '#Non-Rev Units': get(unitMixSummaryConfigData, 'nonRevenueUnits'),
  'Avg. Market Rent': `$${get(unitMixSummaryConfigData, 'marketRentConfig.avg')}`,
  'Avg. Monthly Rent': `$${get(unitMixSummaryConfigData, 'monthlyRentConfig.avg')}`,
  'Lease Type': get(unitMixSummaryConfigData, 'leaseType', ''),
  '#Renov + New': get(unitMixSummaryConfigData, 'renovationConfig.renovated', 0),
  'Avg. Renov Cost': get(unitMixSummaryConfigData, 'renovationConfig.avgRenovationCost', 0),
  'Avg. Renov Bump': get(unitMixSummaryConfigData, 'renovationConfig.avgRenovationBump', 0),
  'Avg. Subsidy': get(unitMixSummaryConfigData, 'affordableConfig.avgSubsidy', 0)
}];

function DocExtractionFloorPlanConfig({
  unitMixSummaryConfigData,
  floorPlanBedMapping,
  floorPlanBathMapping,
  isAcquisition,
  store,
  utilsService,
  onConfigSubmit,
  leaseTypeList,
  onSlidePaneClose,
  currentDocument,
  readOnlyAccess
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeViewName, setActiveViewName] = useState(TABLE_VIEW);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef();

  const downloadXLS = useCallback((sheetData, sheetName) => {
    utilsService.downloadExcel({ 'Unit Mix Summary': sheetData }, sheetName)
  }, [utilsService])

  const onSubmit = useCallback(
    async (formValue) => {
      setIsSubmitting(true);
      Reflect.ownKeys(unitMixSummaryConfigData).forEach((floorPlan) => {
        const obj1 = unitMixSummaryConfigData[floorPlan];
        const obj2 = formValue[floorPlan];

        if (!isEqual(obj1, obj2)) {
          formValue[floorPlan].isModfied = true;
        }
      });
      store.dispatch(setUnitMixSummary(formValue));
      await onConfigSubmit();
      setIsSubmitting(false);
    },
    [onConfigSubmit, store, unitMixSummaryConfigData]
  );

  useEffect(
    () => () => {
      // eslint-disable-next-line no-alert
      if (formRef.current.dirty && window.confirm(message)) {
        onSubmit(formRef.current.values);
      }
    },
    []
  );

  const activeViewClassName = useCallback((activeClassName) =>
    activeViewName === activeClassName ? '' : 'd-none'
  );

  const getUnitPercentage = useCallback(
    (unit = 0, countKey = 'count') => {
      const unitCount = sumBy(
        Object.values(unitMixSummaryConfigData),
        countKey
      );
      if (unitCount === 0) {
        return 0;
      } else {
        return Number((unit / unitCount) * 100).toFixed(2);
      }
    },
    [unitMixSummaryConfigData]
  );

  const getAverageTotals = useCallback(
    (ObjKey, totalAmount, totalCount, displayAmount = true) => {
      if (unitMixSummaryConfigData[ObjKey]) {
        const total = Math.round(
          unitMixSummaryConfigData[ObjKey][totalAmount] /
          unitMixSummaryConfigData[ObjKey][totalCount]
        );
        return displayAmount ? (
          <DisplayAmountFormat number={total} />
        ) : (
          displayNumberInUSFormat(total)
        );
      } else {
        return 0;
      }
    },
    [unitMixSummaryConfigData]
  );

  return (
    <>
      {Reflect.ownKeys(unitMixSummaryConfigData).length > 0 ? (
        <>
          <RentRollConfigHeader
            title="Configure Unit Mix"
            customTitle="Configure Unit Mix"
            downloadTitle='Download XLSX'
            isCustomOptionVisible
            hideChartSwitcher
            isAcquisition={isAcquisition}
            activeViewName={activeViewName}
            onTabViewChange={(activeView) => setActiveViewName(activeView)}
            onDownload={() => {
              const data = Reflect.ownKeys(unitMixSummaryConfigData).map(
                (floorPlan) => {
                  const row = [
                    getUnitMixRow(
                      floorPlan,
                      unitMixSummaryConfigData[floorPlan]
                    ),
                    ...unitMixSummaryConfigData[floorPlan].children.map(
                      (unitmix) => getUnitMixRow(unitmix.floorPlan, unitmix)
                    )
                  ];
                  return row;
                }
              ).flat();
              const uniqueByFloorPlan = uniqBy(data.flat(), (elem) => JSON.stringify(pick(elem, ['Floor Plans', 'Lease Type'])));
              downloadXLS(uniqueByFloorPlan, `${currentDocument.fileName}.xlsx`);
            }}
          />

          <Formik
            onSubmit={onSubmit}
            innerRef={formRef}
            key="floorPlanKey"
            enableReinitialize
            initialValues={unitMixSummaryConfigData}
          >
            {({
              setFieldValue,
              values,
              isValid,
              errors,
              initialValues,
              handleSubmit
            }) => (
              <Form key="form1">
                <DocExtractionAffixTableWrapper key="form2">
                  <AffixTableView
                    {...{
                      values,
                      leaseTypeList,
                      getUnitPercentage,
                      getAverageTotals,
                      setActiveViewName,
                      activeViewClassName,
                      isMenuOpen,
                      activeViewName,
                      floorPlanConfigData: unitMixSummaryConfigData,
                      isActive: activeViewName === TABLE_VIEW,
                      setFieldValue,
                      setIsMenuOpen,
                      floorPlanBedMapping,
                      floorPlanBathMapping,
                      isAcquisition,
                      errors,
                      initialValues,
                      handleSubmit
                    }}
                    key="form"
                  />
                </DocExtractionAffixTableWrapper>
                <Row
                  className={`configure-table-tfooter footerFixedBottom ${style.floorPlanFooter}`}
                >
                  <Button
                    variant="outline-secondary btn-sm"
                    onClick={onSlidePaneClose}
                  >
                    Cancel
                  </Button>
                  <FormSubmitButton
                    isValid={formRef.current?.dirty && !readOnlyAccess}
                    isSubmitting={isSubmitting}
                    id="floorPlanConfigSubmit"
                  />
                </Row>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  unitMixSummaryConfigData: unitMixSummaryConfigSelector(state),
  leaseTypeList: leaseTypeListSelector(state),
  floorPlanBedMapping: currentDocumentFloorPlanBedSelector(state),
  floorPlanBathMapping: currentDocumentFloorPlanBathSelector(state),
  currentDocument: currentDocumentSelector(state),
  readOnlyAccess: readOnlyAccessSelector(state)
});

export default connect(mapStateToProps)(
  withServices('store', 'utilsService')(DocExtractionFloorPlanConfig)
);
