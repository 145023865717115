import React from 'react'
import SignUpForm from '../../app/SignUpForm/SignUpForm';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage'

export default function SignupPage() {
  return (
    <LoggedOutUserPage>
      <div className="vertical-section dotted-bg bg-light">
        <SignUpForm />
      </div>
    </LoggedOutUserPage>
  )
}

export function SignupPageWithoutVerification() {
  return (
    <LoggedOutUserPage>
      <div className="vertical-section dotted-bg bg-light">
        <SignUpForm noVerification onlyEmailSignup redirect={false}/>
      </div>
    </LoggedOutUserPage>
  )
}
