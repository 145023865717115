import React, { useState } from 'react';
import ReactSelect from 'react-select';
import style from './DocExtractionAffixChart.module.scss';

function DocExtractionAffixChartFilter({
  filterLabel, filterInitialValue, filterChartData, onChartFilterChange, isFixedElement = false
}) {
  const [currentFilterValue, setCurrentFilterValue] = useState({ 
    value: filterInitialValue, 
    label: filterInitialValue 
  });

  return (
    <>
      <div className="row">
        <div className={`col-12 ${style.innerColumn} ${isFixedElement ? style.opExpSelectChart : ''}`}>
          <span>{filterLabel}</span>
          <div className="selectbox">
            <ReactSelect
              classNamePrefix="react-select chargeCodeSelect chartDropdown"
              defaultValue={currentFilterValue}
              maxMenuHeight={230}
              menuPlacement="auto"
              menuPosition="fixed"
              options={filterChartData.map(filterValue => ({ value: filterValue, label: filterValue }))}
              onChange={
                (filterValue) => {
                  setCurrentFilterValue({ ...filterValue, ...currentFilterValue });
                  onChartFilterChange(filterValue.value);
                }
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DocExtractionAffixChartFilter;
