import React, { useState, useMemo, useCallback } from 'react';
import { Formik } from 'formik';
import { withServices } from 'reaf';
import * as Yup from 'yup';
import NeedSupportFormComponent from "./NeedSupportFormComponent";
import { SUPPORT_TYPES } from '../../../constants';

const NeedSupportFormSchema = Yup.object().shape({
  description: Yup.string()
    .trim().transform(value => (value === '' ? undefined : value)).required('Please provide description'),
  type: Yup.mixed()
    .oneOf(Reflect.ownKeys(SUPPORT_TYPES), 'Please select valid issue type.')
});

function NeedSupportForm({
  project,
  document,
  onSubmitSuccess,
  companyService,
  bindSubmission
}) {
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const onSubmit = useCallback(async (values, actions) => {
    actions.setStatus({});
    try {
      await companyService.createSupport(values, selectedAttachments);
      onSubmitSuccess();
    } catch (error) {
      actions.setStatus({ error: error.message });
    }
    actions.setSubmitting(false);
  }, [selectedAttachments]);

  const initialValues = useMemo(() => ({
    projectID: project.uuid,
    documentID: document ? document.uuid : null,
    description: '',
    type: Reflect.ownKeys(SUPPORT_TYPES)[0]
  }), [project, document, SUPPORT_TYPES]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={NeedSupportFormSchema}
      onSubmit={onSubmit}
      component={(formikProps => (
        <NeedSupportFormComponent
          bindSubmission={bindSubmission}
          {...formikProps}
          onAttachmentChange={setSelectedAttachments}
          selectedAttachments={selectedAttachments}
        />
      ))}
    />
  );
}

export default withServices('companyService')(NeedSupportForm);
