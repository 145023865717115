import React from 'react';
import {
  Tabs, Tab, Container, Row, Col, Form
} from 'react-bootstrap';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import './ProjectInfoFormComponent.scss';
import { getApiIntergationFormField } from '../../../utils/utils';
import FormikTextField from '../../core/FormikTextField/FormikTextField';
import FormikFieldWithPrepend from '../../core/FormikFieldWithPrepend/FormikFieldWithPrepend';
import FormikSelectField from '../../core/FormikSelectField/FormikSelectField';
import {
  validLoanTypes,
  validLoanPurposes,
  validBorrowTypes,
  validEstimatedPropertyValueCriterias,
  validInterestRateTypes,
  validAccuralMethods,
  validUwIncomeSources
} from './ProjectInfoFormValidationSchema';
import { TEMPLATE_TAG } from '../../../constants';
import { dynamicAssetTypesAsObjSelector } from '../../../store/selectors';

function ProjectProductionFormComponent({
  loanInfoChildTab, setLoanInfoChildTab, mainTab,
  setMainTab, status, submitForm, isSubmitting,
  bindSubmission, dynamicAssetTypes, apiConfigIntegrationValues, errors
}) {
  bindSubmission(submitForm, isSubmitting);
  const getErrorClass = (keyName) => {
    if(keyName === 'api_integration' && errors[keyName]){
      return 'error';
    } else {
      return get(errors, keyName, false) ? 'error' : ''
    }
  }

  return (
    <div className="projectInfoModalForm">
      {
        status && status.error &&
        (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <div className="clearfix">
        <Form>
          <Tabs activeKey={mainTab} onSelect={k => setMainTab(k)} transition={false} id="noanim-tab-example">
            <Tab eventKey="executeLoan" title="Execute Loan Information" tabClassName={getErrorClass('production.execute_loan_information')}>
              <Container fluid className="sub-tabs pb-1">
                <Tabs activeKey={loanInfoChildTab} onSelect={k => setLoanInfoChildTab(k)} transition={false} id="left-tabs-example">
                  <Tab eventKey="propertyInformation" title="Property Information" tabClassName={getErrorClass('production.execute_loan_information.property_information')}>
                    <Row className="justify-content-center">
                      <Col className="col-lg-8">
                        <FormikTextField
                          label="Property Name"
                          placeholder="Enter Property Name"
                          disabled
                          name="production.execute_loan_information.property_information.property_name"
                        />
                        <FormikTextField
                          label="No. of Units"
                          placeholder="12345"
                          type="number"
                          name="production.execute_loan_information.property_information.no_of_units"
                        />
                        <FormikFieldWithPrepend
                          label="Property Value"
                          prependText="$"
                          placeholder="9000000"
                          name="production.execute_loan_information.property_information.property_value"
                        />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="loanInformation" title="Loan Information" tabClassName={getErrorClass('production.execute_loan_information.loan_information')}>
                    <Row className="justify-content-center">
                      <Col className="col-lg-8">
                        <FormikSelectField
                          label="Property Type"
                          name="production.execute_loan_information.loan_information.property_type"
                          disabled
                        >
                          <option value="">Select Property Type</option>
                          {
                            Reflect.ownKeys(dynamicAssetTypes).map(assetType => (
                              <option key={assetType} value={assetType}>{dynamicAssetTypes[assetType].label}</option>
                            ))
                          }
                        </FormikSelectField>
                        <FormikSelectField
                          label="Loan Type"
                          name="production.execute_loan_information.loan_information.loan_type"
                        >
                          <option value="">Select Loan Type</option>
                          {
                            validLoanTypes.map(loanType => (
                              <option key={loanType} value={loanType}>{loanType}</option>
                            ))
                          }
                        </FormikSelectField>

                        <FormikSelectField
                          label="Loan Purpose"
                          name="production.execute_loan_information.loan_information.loan_purpose"
                        >
                          <option value="">Select Loan Purpose</option>
                          {
                            validLoanPurposes.map(loanPurpose => (
                              <option key={loanPurpose} value={loanPurpose}>{loanPurpose}</option>
                            ))
                          }
                        </FormikSelectField>
                        <FormikFieldWithPrepend
                          prependText="$"
                          placeholder="5000000"
                          label="Total Cost Basis"
                          name="production.execute_loan_information.loan_information.total_cost_basis"
                        />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="borrowerInformation" title="Borrower Information" tabClassName={getErrorClass('production.execute_loan_information.borrower_information')}>
                    <Row className="justify-content-center">
                      <Col className="col-lg-8">
                        <FormikTextField
                          label="Loan Name"
                          placeholder="Enter Loan Name"
                          name="production.execute_loan_information.borrower_information.loan_name"
                        />
                        <FormikTextField
                          placeholder="Enter Borrower Name"
                          label="Borrower Name"
                          name="production.execute_loan_information.borrower_information.borrower_name"
                        />
                        <FormikSelectField
                          label="Borrower Type"
                          name="production.execute_loan_information.borrower_information.borrow_type"
                        >
                          <option value="">Select Borrower Type</option>
                          {
                            validBorrowTypes.map(borrowType => (
                              <option key={borrowType} value={borrowType}>{borrowType}</option>
                            ))
                          }
                        </FormikSelectField>
                        <FormikFieldWithPrepend
                          prependText="$"
                          placeholder="4000000"
                          label="Requested Loan Amount"
                          name="production.execute_loan_information.borrower_information.request_loan_amount"
                        />
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="buildingInformation" title="Building Information" tabClassName={getErrorClass('production.execute_loan_information.building_information')}>
                    <Row className="justify-content-center">
                      <Col className="col-lg-8">
                        <FormikTextField
                          label="Building Address"
                          placeholder="Enter Building Address"
                          name="production.execute_loan_information.building_information.building_address"
                        />
                        <FormikTextField
                          label="Zip"
                          placeholder="Enter Zip Code"
                          name="production.execute_loan_information.building_information.zip"
                        />
                        <FormikTextField
                          label="City"
                          placeholder="Enter City"
                          name="production.execute_loan_information.building_information.city"
                        />
                        <FormikTextField
                          label="State"
                          placeholder="Enter State"
                          name="production.execute_loan_information.building_information.state"
                        />
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Container>
            </Tab>
            <Tab eventKey="loanSizing" title="Loan Sizing Parameters" tabClassName={getErrorClass('production.loan_sizing_parameters')}>
              <Container fluid className="py-4">
                <Row className="justify-content-center">
                  <Col className="col-lg-8">
                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          label="LTV"
                          name="production.loan_sizing_parameters.ltv"
                          type="number"
                          placeholder="65"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          label="DSCR (NCF)"
                          name="production.loan_sizing_parameters.dscr(ncf)"
                          placeholder="1.3"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          label="Maximum LTV"
                          type="number"
                          name="production.loan_sizing_parameters.maximum_ltv"
                          placeholder="75"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          label="Loan Term"
                          type="number"
                          name="production.loan_sizing_parameters.loan_term"
                          placeholder="60"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          label="Debt Yield (NOI)"
                          type="number"
                          name="production.loan_sizing_parameters.debt_yield(noi)"
                          placeholder="10"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          label="IO Period"
                          type="number"
                          name="production.loan_sizing_parameters.io_period"
                          placeholder="12"
                        />
                      </Col>
                    </Form.Row>
                    <FormikTextField
                      label="Debt Yield (NCF)"
                      type="number"
                      name="production.loan_sizing_parameters.debt_yield(ncf)"
                      placeholder="9"
                    />
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey="calculationAssumptions" title="Calculation Assumptions" tabClassName={getErrorClass('production.calculation_assumption')}>
              <Container fluid className="py-4">
                <Row className="justify-content-center">
                  <Col className="col-lg-8">
                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          label="Amortization"
                          type="number"
                          placeholder="360"
                          name="production.calculation_assumption.amortization"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          label="Index Rate"
                          placeholder="1.26"
                          name="production.calculation_assumption.index_rate"
                        />
                      </Col>
                    </Form.Row>
                    <FormikSelectField
                      label="Interest Rate Type"
                      name="production.calculation_assumption.interest_rate_type"
                    >
                      <option value="">Select Interest Rate Type</option>
                      {
                        validInterestRateTypes.map(interestRateType => (
                          <option key={interestRateType} value={interestRateType}>{interestRateType}</option>
                        ))
                      }
                    </FormikSelectField>
                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          placeholder="0"
                          type="number"
                          label="CF Haircut"
                          name="production.calculation_assumption.cf_haircut"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          placeholder="7.75"
                          type="number"
                          label="Cap Rate"
                          name="production.calculation_assumption.cap_rate"
                        />
                      </Col>
                    </Form.Row>
                    <FormikSelectField
                      label="Estimated Property Value Criteria"
                      name="production.calculation_assumption.estimated_property_value_criteria"
                    >
                      <option value="">Select Criteria</option>
                      {
                        validEstimatedPropertyValueCriterias.map(propertyValueCriteria => (
                          <option key={propertyValueCriteria} value={propertyValueCriteria}>
                            {propertyValueCriteria}
                          </option>
                        ))
                      }
                    </FormikSelectField>
                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          placeholder="2"
                          type="number"
                          label="Inflators"
                          name="production.calculation_assumption.inflators"
                        />
                      </Col>
                      <Col>
                        <FormikSelectField
                          label="Accrual Method"
                          name="production.calculation_assumption.accural_method"
                        >
                          <option value="">Accrual Method</option>
                          {
                            validAccuralMethods.map(accuralMethod => (
                              <option key={accuralMethod} value={accuralMethod}>{accuralMethod}</option>
                            ))
                          }
                        </FormikSelectField>
                      </Col>
                    </Form.Row>
                    <Form.Row className="form-group">
                      <Col>
                        <FormikTextField
                          placeholder="4.50"
                          label="Spread"
                          type="number"
                          name="production.calculation_assumption.spread"
                        />
                      </Col>
                      <Col>
                        <FormikTextField
                          placeholder="1"
                          type="number"
                          label="Floor"
                          name="production.calculation_assumption.floor"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="form-group">
                      <Col>
                        <FormikSelectField
                          label="UW Income Source"
                          name="production.calculation_assumption.uw_income_source"
                        >
                          <option value="">Select Option</option>
                          {
                            validUwIncomeSources.map(incomeSource => (
                              <option key={incomeSource} value={incomeSource}>{incomeSource}</option>
                            ))
                          }
                        </FormikSelectField>
                      </Col>
                      <Col>
                        <FormikTextField
                          label="Physical Vacancy"
                          placeholder="25"
                          type="number"
                          name="production.calculation_assumption.physical_vacancy"
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="7" controlId="exampleForm">
                        <FormikTextField
                          label="UW Vacancy"
                          placeholder="UW Vacancy"
                          name="production.calculation_assumption.uw_vacancy"
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="2" controlId="exampleForm">
                        <Form.Label />
                        <span className="plusIcon">+</span>
                      </Form.Group>
                      <Form.Group as={Col} md="3" controlId="exampleForm">
                        <FormikTextField
                          label=""
                          type="number"
                          placeholder="5"
                          name="production.calculation_assumption.uw_vacancy+"
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Row>
              </Container>
            </Tab>
            {!isEmpty(apiConfigIntegrationValues) && (
              <Tab eventKey="api" title="Api Integration" tabClassName={getErrorClass('api_integration')}>
                <Container fluid className="py-4">
                  <Row className="justify-content-center">
                    <Col className="col-lg-8">
                      {apiConfigIntegrationValues?.map((object, index) => {
                        return getApiIntergationFormField(object, index);
                      })}
                    </Col>
                  </Row>
                </Container>
              </Tab>
            )}
          </Tabs>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
})
export default connect(mapStateToProps)(ProjectProductionFormComponent);
