import { createDuck } from 'reaf';

const initialState = {
  show: false,
  message: '',
  isFetchingList: false,
};

const [
  { showBackdrop, hideBackdrop, isFetchingList },
  reducer
] = createDuck('autouw/loadingbackdrop', {
  _initialState: initialState,

  showBackdrop: message => state => ({
    ...state,
    show: true,
    message
  }),

  hideBackdrop: () => state => ({
    ...state,
    show: false,
    message: '',
  }),

  isFetchingList: (isFetchingList) => state => ({
    ...state,
    isFetchingList
  })
});

export {
  showBackdrop,
  hideBackdrop,
  isFetchingList
};

export default reducer;
