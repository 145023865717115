import React from 'react';
import { Form, Field } from 'formik';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

function ProspectFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>

      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }

      <Form>
        <div className="form-row">
          <div className="col-md-12 mt-2">
            <h5>Company Details</h5>
            <hr />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyName">Company Name</label>
            <Field id="prospect.companyName" className="form-control" name="prospect.companyName" placeholder="Prospect Company Name" type="text" />
            <FieldErrorMessage name="prospect.companyName" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyAdminEmail">Admin Email</label>
            <Field id="prospect.companyAdminEmail" className="form-control" name="prospect.companyAdminEmail" placeholder="john_doe@prospect.com" type="email" />
            <FieldErrorMessage name="prospect.companyAdminEmail" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyAdminFirstName">Admin First Name</label>
            <Field id="prospect.companyAdminFirstName" className="form-control" name="prospect.companyAdminFirstName" placeholder="John" type="text" />
            <FieldErrorMessage name="prospect.companyAdminFirstName" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyAdminLastName">Admin Last Name</label>
            <Field id="prospect.companyAdminLastName" className="form-control" name="prospect.companyAdminLastName" placeholder="Doe" type="text" />
            <FieldErrorMessage name="prospect.companyAdminLastName" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyPhone">Phone Number</label>
            <Field id="prospect.companyPhone" className="form-control" name="prospect.companyPhone" placeholder="604 555 5555" type="text" />
            <FieldErrorMessage name="prospect.companyPhone" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyAddress.address">Address</label>
            <Field id="prospect.companyAddress.address" className="form-control" name="prospect.companyAddress.address" placeholder="#123, Jane Doe St, Toronto" type="text" />
            <FieldErrorMessage name="prospect.companyAddress.address" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyAddress.zip">Postal Code</label>
            <Field id="prospect.companyAddress.zip" className="form-control" name="prospect.companyAddress.zip" placeholder="M4B1B3" type="text" />
            <FieldErrorMessage name="prospect.companyAddress.zip" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.companyAddress.country">Country</label>
            <Field id="prospect.companyAddress.country" className="form-control" name="prospect.companyAddress.country" placeholder="Canada" type="text" />
            <FieldErrorMessage name="prospect.companyAddress.country" />
          </div>
          <div className="col-md-12 mt-3">
            <h5>Payment Details</h5>
            <hr />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.numberOfLicenses">Number of Licenses</label>
            <Field id="prospect.numberOfLicenses" className="form-control" name="prospect.numberOfLicenses" placeholder="1" type="number" required />
            <FieldErrorMessage name="prospect.numberOfLicenses" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.licenseRenewal">License Renewal</label>
            <Field 
              id="prospect.licenseRenewal" className="form-control" 
              name="prospect.licenseRenewal" 
              value= "Yearly" readOnly />
          
            <FieldErrorMessage name="prospect.licenseRenewal" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.amountPerLicense">Amount Per License (USD)</label>
            <Field id="prospect.amountPerLicense" className="form-control" name="prospect.amountPerLicense" placeholder="500" type="text" required />
            <FieldErrorMessage name="prospect.amountPerLicense" />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="prospect.totalAmount">Total Amount</label>
            <Field
              id="prospect.totalAmount" className="form-control"
              name="prospect.totalAmount"
              value={values.prospect.numberOfLicenses * values.prospect.amountPerLicense} readOnly />
            <FieldErrorMessage name="prospect.totalAmount" />
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ProspectFormComponent;
