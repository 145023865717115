import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import ResetPasswordForm from '../../app/ResetPasswordForm/ResetPasswordForm';
import { routerStateSelector } from '../../../store/selectors';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import { markFirstTourDone } from '../../../store/productTour';
import ResetPasswordPageTour from './ResetPasswordPageTour';

function ResetPasswordPage({
  email, token, errorMessage, startTour, store
}) {
  const [showTour, setShowTour] = useState(startTour);

  const closeTours = useCallback(() => {
    setShowTour(false);
    store.dispatch(markFirstTourDone('resetPasswordPage'));
  }, []);

  return (
    <LoggedOutUserPage>
      <div className="vertical-section dotted-bg bg-light">
        <ResetPasswordForm email={email} token={token} errorMessage={errorMessage} />
      </div>
      <ResetPasswordPageTour show={showTour} onClose={closeTours} />
    </LoggedOutUserPage>
  );
}

const mapStateToProps = (state) => {
  const routerState = routerStateSelector(state);
  return ({
    email: routerState.queries.email,
    token: routerState.queries.token,
    errorMessage: routerState.queries.errorMessage,
    startTour: false
  });
};

export default connect(mapStateToProps)(withServices('store')(ResetPasswordPage));
