import React from 'react';
import { Form } from 'formik';
import CompanyFieldsComponent from './CompanyFieldsComponent';

function EditCompanyFormComponent({
  status, isSubmitting, bindSubmission, submitForm, values, setFieldValue
}) {
  bindSubmission(submitForm, isSubmitting);
  return (
    <div>
      {
        status && status.error && (
          <div className="alert alert-danger">
            <p>{status.error}</p>
          </div>
        )
      }
      <Form>
        <CompanyFieldsComponent setFieldValue={setFieldValue} values={values} />
      </Form>
    </div>
  );
}

export default EditCompanyFormComponent;
