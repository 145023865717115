import React, { useMemo } from 'react';
import Tour from 'reactour';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import {
  currentProjectIsReportEnabledSelector,
  currentProjectisReportShareLinkEnabledSelector
} from '../../../store/selectors';

const projectPageReportButtonInfo = [
  {
    selector: '#projectReport',
    content: () => (
      <>
        <h6 className="border-bottom">Page Guide</h6>
        <p>
          {`Click the 'Project Report' button to open consolidated summary of the
          project.`}
        </p>
      </>
    )
  }
];

const projectPageReportShareButtonInfo = [
  {
    selector: '#ReportSharableLink',
    content: () => (
      <>
        <h6 className="border-bottom">Page Guide</h6>
        <p>Click the button to open sharable project report link.</p>
      </>
    )
  }
];

const projectPageSteps = (
  isReportEnabled,
  isReportShareLinkEnabled,
  onAddFileTutorial,
  onClose
) =>
  [
    [
      {
        selector: '#BrowseFileButton',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>
              {`Click the 'Browse OS and RR Files' button to add documents to the
              property.`}
            </p>
          </>
        )
      },
      {
        selector: '#ShowEditProjectButton',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>Click this button to edit property details.</p>
          </>
        )
      },
      {
        selector: '#ClearProjectTaggingButton',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>
              Use this button to clear all tagging and extraction data for all
              documents under the property.
            </p>
          </>
        )
      },
      {
        selector: '#projectInfo',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>Click this button to update Property Assumptions.</p>
          </>
        )
      },
      {
        selector: '#RefreshProjectDocumentButton',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>Click this button to refresh document extraction status.</p>
          </>
        )
      }
    ],
    isReportShareLinkEnabled ? projectPageReportShareButtonInfo : [],
    isReportEnabled ? projectPageReportButtonInfo : [],
    [
      {
        selector: '#MarkProjectCompletedButton',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>
              Click this button to update template dictionary (Not valid for
              global templates).
            </p>
          </>
        )
      },
      {
        selector: '#DocumentTypeFilter',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>Use the drop down to filter documents based on their type.</p>
          </>
        )
      },
      {
        selector: '#ModelIntegrationButton',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>
              Use this button to download raw extracted data and the template
              model to generate full report.
            </p>
          </>
        )
      },
      {
        selector: '#DocumentSearchBox',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>Use this search box to filter documents by name.</p>
          </>
        )
      },
      {
        selector: '#Document-0',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>Click a document to preview file contents.</p>
            <p>
              {`Click on action button to tag the document for extraction or view
              extracted data if it's already extracted.`}
            </p>
          </>
        )
      },
      {
        selector: '#ShowTourButton',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>Click this button to show this help tour again.</p>
          </>
        )
      },
      {
        selector: '#asdasdasdasdasd',
        content: () => (
          <>
            <h6 className="border-bottom">Page Guide</h6>
            <p>
              Check other tutorials on this page:
              <Button
                variant="secondary"
                size="sm"
                className="btn-xs"
                onClick={onAddFileTutorial}
              >
                Add New Documents
              </Button>
            </p>

            <p>
              Or, you may end the guide. <br />
              <Button size="sm" variant="warning" onClick={onClose}>
                End Guide
              </Button>
            </p>
          </>
        ),
        action: (node) => node && node.focus()
      }
    ]
  ].flat();

function ProjectPageTour({
  show,
  isReportEnabled,
  isReportShareLinkEnabled,
  onClose,
  onAddFileTutorial
}) {
  const steps = useMemo(
    () =>
      projectPageSteps(
        isReportEnabled,
        isReportShareLinkEnabled,
        onAddFileTutorial,
        onClose
      ),
    [isReportEnabled, isReportShareLinkEnabled, onClose, onAddFileTutorial]
  );

  return (
    <Tour
      steps={steps}
      isOpen={show}
      onRequestClose={onClose}
      rounded={5}
      accentColor="#2196f2"
      startAt={0}
      disableInteraction
    />
  );
}

const mapStateToProps = (state) => {
  const isReportEnabled = currentProjectIsReportEnabledSelector(state);
  const isReportShareLinkEnabled =
    currentProjectisReportShareLinkEnabledSelector(state);
  return {
    isReportEnabled,
    isReportShareLinkEnabled
  };
};

export default connect(mapStateToProps)(ProjectPageTour);
