import React, { useEffect, useState } from "react";
import { Modal, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { withServices } from "reaf";
import { hideBackdrop, showBackdrop } from "../../../store/loadingBackdrop";

function CheckoutForm({ formWrapperClass='', onSuccess, onCancel, store }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment Successful! Thank you for your payment.");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    store.dispatch(showBackdrop("Please wait..."))
    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (error) {
      const errorMessage = error.type === "card_error" || error.type === "validation_error"
        ? error.message
        : "An unexpected error occured."
      setMessage(errorMessage);
    } else if (paymentIntent) {
      onSuccess(paymentIntent);
    }

    store.dispatch(hideBackdrop())
    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className={formWrapperClass}>
      <Modal.Body style={{ minHeight: '302px' }}>
        {message && <div id="payment-message">{message}</div>}
        <PaymentElement id="payment-element" />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="primary"  type="submit" disabled={isLoading || !stripe || !elements}>
          {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> : 'Pay now'}
        </Button>
      </Modal.Footer>
    </form>
  );
}

export default withServices('store')(CheckoutForm);
