import React, { useState, useEffect, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function ItemWithContextMenu({
  TileComponent, ContextMenuComponent, InfoComponent, openOnClick = false, afterShowContextMenu
}) {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [location, setLocation] = useState(null);
  const node = useRef();

  useEffect(() => {
    function handleClick(e) {
      if (!node.current || node.current.contains(e.target)) {
        return;
      }

      hideContextMenu();
    }

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  function onContextmenu(e) {
    e.preventDefault();
    e.stopPropagation();
    const element = e.target;
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = element.getBoundingClientRect();
    const offset = elemRect.top - bodyRect.top - 160;

    setLocation({ x: e.pageX, y: elemRect.bottom > (bodyRect.bottom - 300) ? offset : e.pageY });
    setShowContextMenu(true);
    afterShowContextMenu && afterShowContextMenu();
  }

  function hideContextMenu() {
    setShowContextMenu(false);
  }

  return (
    <div>
      <OverlayTrigger
        placement="auto"
        rootClose
        delay={{ show: 600, hide: 20 }}
        overlay={
          ({
            outOfBoundaries, scheduleUpdate, show, arrowProps, ...props
          }) => (
            <div {...props} hidden={showContextMenu} className="shadow-lg">{InfoComponent && <InfoComponent />}</div>
          )}
      >
        {openOnClick ?
          <div onClick={onContextmenu}><TileComponent /></div> :
          <div onContextMenu={onContextmenu}><TileComponent /></div>
        }
      </OverlayTrigger>

      {
        showContextMenu &&
        (
          <div
            ref={node}
            style={{
              position: 'fixed', left: `${location.x}px`, top: `${location.y}px`, zIndex: 999
            }}
          >
            <ContextMenuComponent hideContextMenu={hideContextMenu} />
          </div>
        )
      }
    </div>
  );
}

export default ItemWithContextMenu;
