import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { dynamicAssetTypesListSelector } from '../../../store/selectors';

function DisplayAssetType({ AssetType, assetTypes }) {
  const assetTypesMap = useMemo(
    () => new Map(assetTypes.map((assetType) => [assetType.assetType, assetType])), [assetTypes]
  );

  return (
    <span>{assetTypesMap.has(AssetType) && <span>{assetTypesMap.get(AssetType).label}</span>}</span>
  );
}

const mapStateToProps = (state) => ({
assetTypes: dynamicAssetTypesListSelector(state)
});

export default connect(mapStateToProps)(DisplayAssetType);
