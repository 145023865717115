import { createDuck } from 'reaf';

const initialState = {
  /*
  id: { name, status }
   */
};

const [
  { addStatus, updateStatus, clearQueue, updateErrorMessage },
  reducer
] = createDuck('autouw/fileUploadStatus', {
  _initialState: initialState,

  addStatus: (id, name, fileType) => state => ({
    ...state,
    [id]: {
      name,
      status: 'active',
      fileType,
      message: ''
    }
  }),

  updateStatus: (id, status) => state => (state[id] ?
    ({
      ...state,
      [id]: {
        ...state[id],
        status
      }
    }) : state),
  
  updateErrorMessage: (id, message) => state => (state[id] ?
    ({
      ...state,
      [id]: {
        ...state[id],
        message
      }
    }) : state),

  clearQueue: () => () => initialState

});

export {
  addStatus, updateStatus, clearQueue, updateErrorMessage
};

export default reducer;
