import React, {
  useState, useMemo, useEffect, useCallback
} from "react";
import { Form, Col } from "react-bootstrap";
import ReactSelect from "react-select";
import { pick, get } from "lodash";
import { useForm, Controller } from "react-hook-form";
import { withServices } from "reaf";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  EXCLUDED_TEMPLATES as excludedTemplates,
  TEMPLATE_TAG
} from "../../../constants";
import {
  companyTemplatesAsListSelector,
  companyTemplatesList,
  dynamicAssetTypesAsObjSelector
} from "../../../store/selectors";
import style from './ProjectFormComponent.module.scss';
import { getLocationDetails } from "../../../utils/utils";
import placeSearchResult from "./placeSearchResult";
import config from '../../../config'
import Autocomplete from "../../core/Autocomplete/Autocomplete";

const { shouldShowGoogleApiField } = config.featureFlags;

const getReactSelectOptions = list => Reflect.ownKeys(list).map(key => ({ value: key, label: list[key].label }));

const FormField = ({
  label,
  type = "text",
  placeholder,
  name,
  ...fieldProps
}) => (
  <Form.Group {...fieldProps}>
    <Form.Label>{label}</Form.Label>
    <Form.Control
      type={type}
      placeholder={placeholder}
      name={name}
      {...fieldProps}
    />
  </Form.Group>
);

/**
 * dropdownList: Array = [{value, label}, {value, label}, {value, label}];
 * setSelectedValue: Fn = ({value, label}) => { doSomething(value) };
 * selectedValue: Object = {value, label};
 */
const ReactSelectField = ({
  label,
  placeholder,
  name,
  dropdownList,
  selectedValue,
  setSelectedValue
}) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <ReactSelect
      placeholder={placeholder}
      value={selectedValue}
      name={name}
      options={dropdownList}
      onChange={selectedAssetTypes => {
        setSelectedValue(selectedAssetTypes);
      }}
    />
  </div>
);

const menuStyle = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  zIndex: '999',
  maxHeight: '50%',
};

const addressTextKey = 'name';
const ProjectAddresDetailFormComponents = ({ control, projectAddress }) => {
  if(!shouldShowGoogleApiField) {
    return null;
  }
  return (
  <div className={style.addressWrapper}>
    <fieldset>
      <Controller
          as={<FormField
            label="Property Address"
            placeholder="Enter Property Address"
            name="address.formattedAddress"
          />}
          name="address.formattedAddress"
          defaultValue={projectAddress.formattedAddress}
          control={control}
        />

        <Controller
          as={<FormField
            hidden
            label="Google Place Id"
            placeholder="Enter Google Place Id"
            name="address.googlePlaceId"
          />}
          name="address.googlePlaceId"
          defaultValue={projectAddress.googlePlaceId}
          control={control}
        />
        <Controller
          as={<FormField
            hidden
            label="Lattitude"
            placeholder="Enter Lattitude"
            name="address.lat"
          />}
          name="address.lat"
          defaultValue={projectAddress.lat}
          control={control}
        />
        <Controller
          as={<FormField
            hidden
            label="Longitude"
            placeholder="Enter Longitude"
            name="address.lng"
          />}
          name="address.lng"
          defaultValue={projectAddress.lng}
          control={control}
        />
        <Controller
          as={<FormField
            label="City"
            placeholder="Enter City"
            name="address.city"
          />}
          name="address.city"
          defaultValue={projectAddress.city}
          control={control}
        />

    <Form.Row className="form-group">
      <Col>

        <Controller
          as={<FormField
            label="State"
          placeholder="Enter State"
          name="address.state"
          />}
          name="address.state"
          defaultValue={projectAddress.state}
          control={control}
        />
      </Col>
      <Col>
      <Controller
          as={<FormField
            label="Zip"
          type="text"
          placeholder="Enter Zip Code"
          name="address.zip"
          />}
          name="address.zip"
          defaultValue={projectAddress.zip}
          control={control}
        />
      </Col>
    </Form.Row>
    </fieldset>
  </div>)
};

const ProjectFormComponentHook = ({
  project,
  company,
  onSubmitSuccess,
  templatesList,
  templates,
  bindSubmission,
  projectsService,
  placesService,
  dynamicAssetTypes
}) => {
  // eslint-disable-next-line no-unused-vars
  const { control, handleSubmit, formState, setValue } = useForm({
    defaultValues: pick(project, [addressTextKey])
  });
  const [formError, setFormError] = useState(null);
  const [selectedAssetType, setSelectedAssetType] = useState({
    label: dynamicAssetTypes[project.assetType].label,
    value: project.assetType
  });

  const [places, setPlaces] = useState([]);
  const [projectAddress, setProjectAddress] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [projectName, setProjectName] = useState(project.name);

  const onChangeInput = useCallback((searchText) => {
    setValue(addressTextKey, searchText)
    setProjectName(searchText)
    placesService.autocomplete(searchText).then(setPlaces)
  }, [placesService]);

  const onSelectPlace = useCallback(async (placeId) => {
    const placeDetails =  await placesService.getDetailsByPlaceId(placeId);
    setValue(addressTextKey, placeDetails.name)
    setProjectName(placeDetails.name)
    const details = getLocationDetails(placeDetails);
    Reflect.ownKeys(details).forEach(key => setValue(`address.${key}`, details[key]))
  }, [placesService]);

  useEffect(() => {
    if(shouldShowGoogleApiField) {
      setIsLoading(true)
      if (project.uuid) {
        projectsService.fetchAddress(project).then(setProjectAddress).finally(() => setIsLoading(false))
      } else {
        setIsLoading(false)
      }
    }
  }, [project.uuid])

  
  useEffect(() => {
    const label =
      templates[project.templateUuid] &&
      templates[project.templateUuid].templateName;
    setSelectedTemplate({ label, value: project.templateUuid });
  }, [templates, project]);

  const allowedAssetTypes = useMemo(
    () => pick(dynamicAssetTypes, company.assetTypes),
    [dynamicAssetTypes, company]
  );

  const filteredTemplates = useMemo(() => templatesList && templatesList
    .filter(template => !Reflect.ownKeys(excludedTemplates)
    .includes(template.uuid) && (!template.assetTypes || template.assetTypes.indexOf(selectedAssetType.value) !== -1))
    .filter((template) => (project.templateUuid && templates[project.templateUuid] ? 
      templates[project.templateUuid].tag === template.tag : true))
    .filter((template) => 
      ((project.templateUuid && templates[project.templateUuid] && 
        templates[project.templateUuid].keysToMask && 
        templates[project.templateUuid].keysToMask.length === 0) ? 
      template.keysToMask.length === 0 : true)),
    [templatesList, selectedAssetType, templates]
  );

  const onSubmit = useCallback(
    async form => {

      const template = templates[selectedTemplate.value];
      if(!(!template.assetTypes || template.assetTypes.indexOf(selectedAssetType.value) !== -1)) {
        setFormError(`${selectedAssetType.label} doesn't support ${selectedTemplate.label}`);
        return;
      }
      try {
        const updatedProject = await projectsService.updateProjectByAdmin(company, project, {
          ...form,
          name: shouldShowGoogleApiField ? projectName : form.name,
          assetType: selectedAssetType.value,
          templateUuid: selectedTemplate.value
        });
        onSubmitSuccess(updatedProject);
      } catch (error) {
        setFormError(error.message);
      }
    },
    [project, company, selectedAssetType, selectedTemplate, projectName]
  );

  bindSubmission(handleSubmit(onSubmit), formState.isSubmitting);

  if (isLoading) {
    return null
  }
  return (
    <>
      {formError && (
        <div className="alert alert-danger">
          <p>{formError}</p>
        </div>
      )}

      <Form>

        {
          !shouldShowGoogleApiField
            ? (
              <Controller
                as={<FormField label="Project Name" name="name" />}
                name="name"
                control={control}
                defaultValue={project.name}
              />
            )
          : (
            <div id="ProjectNameField" className="form-group">
              <label htmlFor="name">Property Name</label>
              <Autocomplete
                value={projectName}
                itemToString={(item) => item ? get(item, 'structured_formatting.main_text') : projectName}
                getItemValue={(item) => item.place_id}
                inputProps={{ className: 'filter text-filter form-control modelUsedFilter' }}
                renderInput={(props) => (<input placeholder="Property name" {...props} />)}
                items={places}
                menuStyle={menuStyle}
                wrapperStyle={{ display: 'unset' }}
                renderItem={(item, isHighlighted) => placeSearchResult(item, isHighlighted)}
                onChange={(value) => onChangeInput(value)}
                onSelect={(item, { clearSelection }) => {
                  if (item) {
                    const { place_id: placeId } = item;
                    onSelectPlace(placeId);
                    clearSelection();
                  }
                }}

              />
            </div>
          )
        }
        
        <ReactSelectField
          name="assetType"
          label="Asset Type"
          selectedValue={selectedAssetType}
          setSelectedValue={setSelectedAssetType}
          dropdownList={getReactSelectOptions(allowedAssetTypes)}
        />
        <ReactSelectField
          name="templateUuid"
          label="Template"
          selectedValue={selectedTemplate}
          setSelectedValue={setSelectedTemplate}
          dropdownList={filteredTemplates.map(temp => ({
            value: temp.uuid,
            label: temp.templateName
          }))}
        />

        <ProjectAddresDetailFormComponents  
          templateWithModels={templates} 
          selectedTemplate={selectedTemplate} 
          control={control} 
          projectAddress={projectAddress}
        />
      </Form>
    </>
  );
};

const mapStateToProps = state => ({
  templatesList: companyTemplatesAsListSelector(state),
  templates: companyTemplatesList(state),
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
});

ProjectFormComponentHook.propTypes = {
  project: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired
};
export default withServices("projectsService", "placesService")(
  connect(mapStateToProps)(ProjectFormComponentHook)
);
