import React from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import Link from '../Link/Link';
import IconButton from '../Button/IconButton';

function ProspectInvoicesListPageLink({ title, prospect, isSuperAdmin }) {
  const route = isSuperAdmin ? "superAdminProspectInvoicePage" : "accountsExecutiveProspectInvoicePage";
  return (
    <Link route={route} params={{ prospectUuid: prospect.uuid }}><IconButton className="smallIconButton" placement="left" title={title} icon={faList} /></Link>
  );
}

export default ProspectInvoicesListPageLink;
