import React, { useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { withServices } from 'reaf';
import avatarFile from '../../../assets/images/avatar.svg';
import { SpriteLogoutIcon } from '../../core/SpriteIcon/SpriteIcon';

import clikLogoFile from '../../../assets/images/logo-symbol.png';
import Link from '../../core/Link/Link';
import style from './AnonymousHeader.module.scss';
import { CalendlyLinkClickedOnReportPage } from '../../../constants/eventTrackerMessage';
import { UDWrapper } from '../UserHeader/UserHeader';

function AnonymousHeader({ logoUrl = '', eventTrackerService, authService, canLogout = false }) {
  const logout = useCallback(() => authService.logout(), []);
  return (
    <Container fluid className={`bg-dark text-light shadow ${style.header}`}>
      <div className="d-inline-flex flex-row justify-content-between w-100">
        <div className={`${style.logo}`}>
          {logoUrl ? (
            <a
              href={logoUrl}
              onClick={() => {
                eventTrackerService.track(CalendlyLinkClickedOnReportPage);
              }}
              className="text-decoration-none text-light"
            >
              <img src={clikLogoFile} width={48} alt="Clik Logo" />
              <span className="ml-2">
                <small>
                  <strong>Clik.Ai</strong>
                </small>
              </span>
            </a>
          ) : (
            <Link
              route="projectsPage"
              className="text-decoration-none text-light"
            >
              <img src={clikLogoFile} width={48} alt="Clik Logo" />
              <span className="ml-2">
                <small>
                  <strong>Clik.Ai</strong>
                </small>
              </span>
            </Link>
          )}
        </div>
        <div>
        {canLogout ? <Dropdown className={style.avatar}>
            <Dropdown.Toggle as={UDWrapper}>
              <div className={`clearfix ${style.userBdrHover}`}>
                <span className={style.avatarIcon}>
                  <img src={avatarFile} alt="User avatar" />
                </span>
                <span>
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className={style.dropdownMenuRight}>
              <Dropdown.Item onClick={logout}>
                <SpriteLogoutIcon />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> : null }
        </div>
      </div>
    </Container>
  );
}

export default withServices('eventTrackerService', 'authService')(AnonymousHeader);
