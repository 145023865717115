import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ProjectPopups from '../ProjectPopups/ProjectPopups';

const defaultLabel = (
  <span className="text-primary" style={{ cursor: 'pointer' }}>Edit Project</span>
);
const EditProject = ({
  label = defaultLabel, project, onEditSuccess
}) => {
  const [showEditProject, setShowEditProject] = useState(false);
  const onEdit = useCallback(() => {
    setShowEditProject(true);
  }, []);

  return (
    <>
      <div onClick={onEdit}>
        {label}
      </div>
      <ProjectPopups 
        onEditSuccess={onEditSuccess} 
        showUpdateProject={showEditProject} 
        project={project} 
        company={project.Company} 
        onHide={() => setShowEditProject(false)} 
      />
    </>
  );
};

EditProject.propTypes = {
  label: PropTypes.node,
  project: PropTypes.object.isRequired
};

export default EditProject;
