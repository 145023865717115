import Service from './Service';

class FeatureFlagService extends Service {
  constructor(apiClient, store) {
    super(apiClient, store);
    this.apiClient = apiClient;
    this.store = store;
  }

  async createFeatureFlag(payload) {
    return this.apiClient.post('/admin/featureFlag', payload)
  }
}

export default FeatureFlagService;
