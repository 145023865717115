import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { isNil } from 'lodash';
import TreeView from '../../core/TreeView/TreeView';
import { setSummaryData } from '../../../store/currentDocument';
import {
  summaryDataSelector, netRentalIncomeSelector,
  currentPojectModelNameSelector, currentProjectAssetTypeSelector,
  dynamicAssetTypesAsObjSelector
} from '../../../store/selectors';
import DocExtractionSummaryTotal from './DocExtractionSummaryTotal';
import DocExtractionWithoutSummary from './DocExtractionWithoutSummary';
import style from './DocExtractionSummary.module.scss';
import { CLIK_OS_CATEGORY_MAPPING, rentRollAffixTabViewName } from '../../../constants';
import RentRollConfigHeader from '../RentRollConfigHeader/RentRollConfigHeader';
import DocExtractionSummaryChart from './DocExtractionSummaryChart';
import messages from '../../../../locales/en-US';
import { fetchBaseTypeByAssetType } from '../../../utils/utils';


function DocExtractionSummary({
  store, summaryData, netRentalIncome, currentModel, assetType, dynamicAssetTypes
}) {
  const { TABLE_VIEW, CHART_VIEW } = rentRollAffixTabViewName;
  const { summaryDetail, summaryTotal } = summaryData;
  const [activeViewName, setActiveViewName] = useState(CHART_VIEW);

  const onChange = useCallback((summaryDetail) => {
    store.dispatch(setSummaryData({ summaryDetail, summaryTotal }));
  }, [summaryDetail]);

  const hasSummaryData = useMemo(() => (!!summaryDetail && summaryDetail.length > 0) && !!summaryTotal, [summaryData]);

  const isClikModel = useMemo(() => currentModel.toLowerCase() === 'clik' && !isNil(CLIK_OS_CATEGORY_MAPPING[fetchBaseTypeByAssetType(assetType, dynamicAssetTypes)])
    , [currentModel]);

  return (
    hasSummaryData ? (
      <div className={`col-12 ${style.summaryView}`}>
        <div className={style.switchWrapper}>
          <RentRollConfigHeader summaryTitle="Summary" activeViewName={activeViewName} onTabViewChange={(viewName) => setActiveViewName(viewName)} />
        </div>
        {
          activeViewName === TABLE_VIEW &&
          <TreeView title="Summary" treeData={summaryDetail} canDrag={false} rowHeight={40} onChange={onChange} style={{ height: `calc(100vh - 260px)` }} className="summaryTreeView" />
        }

        {
          activeViewName === CHART_VIEW && (
            <DocExtractionSummaryChart
              style={style}
              showNetRentalIncomeChart={isClikModel}
              netRentalIncome={netRentalIncome}
              assetType={assetType}
              summaryData={summaryData} />
          )}

        <DocExtractionSummaryTotal summaryTotal={summaryTotal} />
      </div>
    ) : <DocExtractionWithoutSummary message={messages.affixMessage.notFound} />
  );
}

const mapStateToProps = (state) => ({
  summaryData: summaryDataSelector(state),
  netRentalIncome: netRentalIncomeSelector(state),
  currentModel: currentPojectModelNameSelector(state),
  assetType: currentProjectAssetTypeSelector(state),
  dynamicAssetTypes: dynamicAssetTypesAsObjSelector(state)
});

export default connect(mapStateToProps)(withServices('store')(DocExtractionSummary));
