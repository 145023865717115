import { createDuck } from 'reaf';

const initialState = {
  dynamicAssetTypesList: []
};

const [
  { setDynamicAssetTypesList },
  reducer
] = createDuck('autouw/dynamicAssetTypesList', {
  _initialState: initialState,

  setDynamicAssetTypesList: dynamicAssetTypesList => state => ({
    ...state,
    dynamicAssetTypesList
  })
});

export { setDynamicAssetTypesList };

export default reducer;