/* eslint-disable import/no-cycle */
import React from 'react';
import * as PropTypes from 'prop-types';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { ACTIONS } from './SpreadSheetToolbar';
import styles from '../SpreadSheets.module.scss';

export const FORMATS = {
  GENERAL: '',
  NUMBER: '#,##0.00_);(#,##0.00)',
  CURRENCY: '$#,##0.00',
  ACCOUNTING: '_($* #,##0.00_);_($* (#,##0.00);_($* \'-\'??_);_(@_)',
  SHORT_DATE: 'm/d/yyyy',
  LONG_DATE: 'dddd, mmmm dd, yyyy',
  TIME: 'h:mm:ss AM/PM',
  PERCENTAGE: '0%',
  SCIENTIFIC: '0.00E+00',
  FRACTION: '# ?/?',
  TEXT: '@',
};

export function FormatMenu({ onSelect }) {
  return (
    <div className={`position-relative d-inline-block ${styles.docsToolbarButton}`}>
      <DropdownButton title="123" className={`dropdown-btn-xs ${styles.spreadsheetToolbarDropdownButton}`} variant="light" size="sm">
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.GENERAL })}>
          General
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.NUMBER })}>
          Number
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.CURRENCY })}>
          Currency
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.ACCOUNTING })}>
          Accounting
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.SHORT_DATE })}>
          Short Date
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.LONG_DATE })}>
          Long Date
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.TIME })}>
          Time
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.PERCENTAGE })}>
          Percentages
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.FRACTION })}>
          Fraction
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.SCIENTIFIC })}>
          Scientific
        </Dropdown.Item>
        <Dropdown.Item onClick={() => onSelect(ACTIONS.FORMAT_CELL, { format: FORMATS.TEXT })}>
          Text
        </Dropdown.Item>
      </DropdownButton>
    </div>
  )
}

FormatMenu.propTypes = {
  onSelect: PropTypes.func.isRequired,
};
