import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts';
import useSvgToImageDownload from '../../../hooks/useSvgToImageDownload';
import { getRandomColor } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';
import style from './DocExtractionAffixChart.module.scss';


function DocExtractionAffixLineChart({ data, xAxisKeyName, yAxisKeyName }) {
  const [chartColors] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [getPng, { ref, handleDownload }] = useSvgToImageDownload();

  const setColor = () => {
    data && data.forEach(() => {
      chartColors.push(getRandomColor());
    });
    setShowChart(true);
  };

  useEffect(() => {
    setColor();
  }, []);

  const onDownloadClick = useCallback(async () => {
    const png = await getPng();
    handleDownload(png);
  }, [handleDownload, getPng]);

  const LineChartComponent = useCallback(() => (
    <div className={style.chartContainer}>
      <LineChart
        width={460}
        height={300}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5
        }}
        ref={ref}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKeyName} />
        <YAxis dataKey={yAxisKeyName} allowDataOverflow />
        <Tooltip />
        {
          Reflect.ownKeys(data[0]).filter(keyName => !['tabName', 'name'].includes(keyName)).map((keyName, index) => <Line dataKey={keyName} key={keyName} stroke={chartColors[index]} />)
        }
      </LineChart>
      <div className={style.downloadBtnContainer}>
        <IconButton icon={faDownload} onClick={onDownloadClick} className={style.downloadImageBtn} text="" />
      </div>
    </div>
  ));

  return (
    <div className={style.chartWrapper}>
      {showChart && <LineChartComponent />}
    </div>
  );
}

export default DocExtractionAffixLineChart;
