import React from 'react';
import { Form, Field } from 'formik';
import {
  Form as BSForm, Row, Col, Table
} from 'react-bootstrap';
import { faEye, faEyeSlash, faInfo } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import useBool from "../../../hooks/useBool";
import FormSubmitButton from '../../core/Button/FormSubmitButton';
import { apiConfig, appConfig, appBaseUrl } from '../../../config';
import WithTooltip from '../../core/WithTooltip/WithTooltip';

const certificatePlaceholder = `-----BEGIN CERTIFICATE-----
MIIDdDCCAlygAwIBAgIGAXZJAVJDMA0GCSqGSIb3DQEBCwUAMHsxFDASBgNVBAoTC0dvb2dsZSBJ
bmMuMRYwFAYDVQQHEw1Nb3VudGFpbiBWaWV3MQ8wDQYDVQQDEwZHb29nbGUxGDAWBgNVBAsTD0dv
b2dsZSBGb3IgV29yazELMAkGA1UEBhMCVVMxEzARBgNVBAgTCkNhbGlmb3JuaWEwHhcNMjAxMjA5
MTkzNjMxWhcNMjUxMjA4MTkzNjMxWjB7MRQwEgYDVQQKEwtHb29nbGUgSW5jLjEWMBQGA1UEBxMN
TW91bnRhaW4gVmlldzEPMA0GA1UEAxMGR29vZ2xlMRgwFgYDVQQLEw9Hb29nbGUgRm9yIFdvcmsx
CzAJBgNVBAYTAlVTMRMwEQYDVQQIEwpDYWxpZm9ybmlhMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8A
MIIBCgKCAQEA0xELEGS/IhYPxRWmaegImN64Ke6cL+Q5aBn48aXDt1hz5MaTqEM/q2HtqjwifhRS
gong0lH7qy8p6rZM4Iv+BiLoSU46frILbw8BYrVBOw3hCxTclnliTpmpzAwrTXuZrOB0VuR0rz1c
KL/g9+b4QWAGL3r8t5UXcJHlA5WTQn5t6IN7+hQex/h2onclRtaSiZZ8Yn5OVbqP5gOdRis/GQdL
8SCNjc6OFG49q8F8nM/lF4IOlGwKZoXIRZceQukVV+St7+88SCq/fF5CtU9OXzz04jfAVteJkbNC
iexmfqcOaSel+SGGwZ9QnCQOUg73O0dZ4bK3kj4mGTdPG4aXIQIDAQABMA0GCSqGSIb3DQEBCwUA
A4IBAQCSmpQgb3ZitRQMXS/cVvSDS3BoakI6Epr3Fy01QWIPc0PpnzYhBZNYz97VjZKtvqIu3a7s
YIuhKIgLXAVv1oby6UffhpA6mLZad4cGc5TyilUMtUs6hp47YZhj0m7T+yv6a/4ICBL058xSi+5q
8AIbKMUUleAX+6gM4JTGMoFU7vBABLLlP4LS+EMY+ZRkexvDreAPyDcStV6pmnXgUYxiAaeAAhax
20/HBXseBLr6JHD8RJpFDuG+4YIT0+1DZ23hyjmK53DVrJoX1paLMtOrZO8vmmNcQHxu6bdWKG0s
nXgK5DkUEmh9LPCB6aeLF+u75m4UBSmqU5Iyb4R5ytvX
-----END CERTIFICATE-----`;

const SsoConfigComponent = ({
  status,
  isSubmitting,
  isValid,
  company
}) => {
  const [isCertificateVisible, , , toggleCertificateVisibility] = useBool(true);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {
            status && status.error &&
            (
              <div className="alert alert-danger">
                <p>{status.error}</p>
              </div>
            )
          }
        </div>
      </div>

      <Form>
        <div className="row">
          <div className="col-6">
            <BSForm.Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="nameId">Name Id</label>
                  <Field id="nameId" className="form-control" name="nameId" type="text" readOnly />
                  <FieldErrorMessage name="nameId" />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="userAttribute">User Attribute</label>
                  <Field id="userAttribute" className="form-control" name="userAttribute" type="text" readOnly />
                  <FieldErrorMessage name="userAttribute" />
                </div>
              </Col>
            </BSForm.Row>

            <BSForm.Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="binding">Binding</label>
                  <Field id="binding" className="form-control" name="binding" component="select" readOnly>
                    <option value="HTTP-POST" key="HTTP-POST">HTTP-POST</option>
                    <option value="HTTP-Redirect" key="HTTP-Redirect">HTTP-Redirect</option>
                  </Field>
                  <FieldErrorMessage name="role" />
                </div>
              </Col>
            </BSForm.Row>

            <div className="form-group">
              <label htmlFor="signonPageUrl">
                Sign On Page Url (SP)
                {' '}
                <WithTooltip placement="right" tooltipText="Save it in IDP configuration.">
                  <FontAwesomeIcon className="ml-2" icon={faInfo} />
                </WithTooltip>

              </label>
              <BSForm.Control type="text" disabled value={`${apiConfig.baseUrl}/auth/saml/${company.slug}`} />
            </div>

            <div className="form-group">
              <label htmlFor="signonPageUrl">
                Login Page
                {' '}
                <WithTooltip placement="right" tooltipText="Share it with team to login from this screen.">
                  <FontAwesomeIcon className="ml-2" icon={faInfo} />
                </WithTooltip>
              </label>
              <BSForm.Control type="text" disabled value={`${appConfig.hostUrl}${appBaseUrl}/auth/saml/${company.slug}`} />
            </div>


            <div className="form-group">
              <label htmlFor="signinPageUrl">Sign-in Page Url (IDP)</label>
              <Field id="signinPageUrl" className="form-control" name="signinPageUrl" type="url" />
              <FieldErrorMessage name="signinPageUrl" />
            </div>

            <div className="form-group">
              <label htmlFor="issuer">Issuer</label>
              <Field id="issuer" placeholder="urn:yourcompany:auth0.com" className="form-control" name="issuer" />
              <FieldErrorMessage name="issuer" />
            </div>
          </div>
          <div className="col-6">

            <div className="form-group">
              <label htmlFor="requiredAttributes">
                Required Attributes
              </label>
              <Table bordered>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>email</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>firstName</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>lastName</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div className="form-group">
              <label htmlFor="idpCertificate">
                IDP Certificate
                <FontAwesomeIcon className="ml-2" icon={isCertificateVisible ? faEyeSlash : faEye} onClick={toggleCertificateVisibility} />
              </label>
              {isCertificateVisible &&
                <Field id="idpCertificate" placeholder={certificatePlaceholder} rows={8} className="form-control" name="idpCertificate" component="textarea" />
              }

              <FieldErrorMessage name="idpCertificate" />
            </div>
          </div>

        </div>


        <Row>
          <Col className="d-flex justify-content-center">
            <FormSubmitButton isSubmitting={isSubmitting} isValid={isValid} title="Save configuration" />
          </Col>
        </Row>


      </Form>
    </div>
  );
};

export default SsoConfigComponent;
