import React, { useEffect } from "react";
import withServices from "reaf/lib/components/withServices";
import { SOCIAL_LOGIN_TYPES } from "../../../constants";
import { hideBackdrop, showBackdrop } from "../../../store/loadingBackdrop";
import { loaderManager } from "../../../utils/utils";
import TrialLoginPage from "../TrialLoginPage/TrialLoginPage";
import { appConfig } from "../../../config/index";
import LoginPage from "../LoginPage/LoginPage";

function LinkedInCallbackPage({ code, authService, store }) {
  const socialLogin = async () => authService.socialLogin({
    idToken: code,
    socialLoginType: SOCIAL_LOGIN_TYPES.LINKEDIN
  });

  useEffect(() => {
    if (!code) {
      return authService.linkedInLogin();
    }
    loaderManager(
      socialLogin(),
      () => store.dispatch(showBackdrop("Logging in application...")),
      () => store.dispatch(hideBackdrop()),
      50,
      300
    );
  }, []);
  return <>{appConfig.isFreebieApp ? <TrialLoginPage /> : <LoginPage />}</>;
}

export default withServices("authService", "store")(LinkedInCallbackPage);
