import BaseCommand from './BaseCommand';

export class SetAbsoluteFormulaCommand extends BaseCommand {
  run(spreadsheet, options) {
    if (!spreadsheet.isActiveSheetProtected) {
      const sheet = spreadsheet.getSheet();
      if (sheet) {
        const selections = sheet.getSelections();
        if (selections.length > 0) {
          const { row, col } = selections[0];
          spreadsheet.absoluteFormulaReference(sheet, row, col);
        }
      }
    }
  }
}

export const setAbsoluteFormulaCommand = SetAbsoluteFormulaCommand.get();
