import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { withServices } from 'reaf';
import { Col, Row, Container } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import AccountsExecutivePopups from '../AccountsExecutivePopups/AccountsExecutivePopups';
import { accountsExecutivesListSelector } from '../../../store/selectors';
import { displayDateFormat } from '../../../utils/utils';
import IconButton from '../../core/Button/IconButton';

function AccountsExecutiveList({ accountsExecutives, accountsExecutiveService }) {
  const regexFilter = useMemo(() => ({
    type: 'RegexFilter',
    placeholder: 'Filter',
    delay: 1000
  }), []);

  const initialValues = {
    accountsExecutive: {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    }
  };

  const [showCreateAccountsExecutiveFormPopup, setShowAccountsExecutiveFormPopup] = useState(false);
  const [accountsExecutive, setAccountsExecutive] = useState(initialValues);

  const hidePopups = useCallback(
    () => {
      setShowAccountsExecutiveFormPopup(false);
    },
    [],
  );

  const onAddAccountsExecutive = useCallback(() => {
    setAccountsExecutive(initialValues);
    setShowAccountsExecutiveFormPopup(true);
  }, [initialValues]);

  return (
    <>
      <AccountsExecutivePopups
        onHide={hidePopups}
        showCreateAccountsExecutive={showCreateAccountsExecutiveFormPopup}
        accountsExecutive={accountsExecutive}
      />
      <Container fluid className="p-2 pb-4">
        <Row className="align-items-center">
          <Col sm="auto">
            <h5 className="mb-0">Accounts Executives</h5>
          </Col>
          <Col sm="auto" className="pl-0 tourIconBtn">
            <IconButton
              title="Add Accounts Executive" className="btn-circle btn-shadow"
              variant="outline-secondary" size="sm" icon={faPlus}
              onClick={e => {
                onAddAccountsExecutive();
              }
              }
              id="AddAccountsExecutive"
            />
          </Col>
          <Col />
        </Row>
      </Container>

      <BootstrapTable
        data={accountsExecutives}
        tableStyle={{ background: '#fafafa' }}
        condensed
        hover
      >
        <TableHeaderColumn
          dataField="uuid"
          isKey
          hidden
          dataSort
          width="60"
          dataAlign="center"
        >
          S.N.
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="firstName"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          First Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lastName"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Last Name
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="email"
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort
          filter={regexFilter}
        >
          Email
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="createdAt"
          dataFormat={displayDateFormat}
          dataSort
        >
          Created On
        </TableHeaderColumn>
      </BootstrapTable>

    </>
  );
}

const mapStateToProps = state => ({
  accountsExecutives: accountsExecutivesListSelector(state)
});


export default connect(mapStateToProps)(withServices('accountsExecutiveService')(AccountsExecutiveList));
