import * as Yup from 'yup';
import { startCase } from 'lodash';

export const validLoanTypes = [
  'Sr. Debt',
  'Mezzanine Loan'
];

export const validLoanPurposes = [
  'Purchase',
  'Refinance',
  'New Development',
  'Redevelopment',
  'Bridge Transaction'
];

export const validBorrowTypes = [
  'Individual',
  'Corp',
  'LLC',
  'Trust',
  'Ltd. On Gen Partnership',
  'Other'
];

export const validInterestRateTypes = [
  'ARM',
  'Fixed',
  'Hybrid'
];

export const validEstimatedPropertyValueCriterias = [
  'Lower of Appraisal and Estimated Underwriting Value',
  'Appraised Value If It Exists',
  'Underwriting Estimated Value'
];

export const validAccuralMethods = [
  'Actual/360',
  '30/360',
  'Actual/Actual'
];

export const validUwIncomeSources = [
  'NCF',
  'NOI'
];

export const reviews = [
  "Monthly",
  "Quarterly",
  "Annual"
];

const getFieldLabel = (fieldName, exactText = false) => (exactText ? fieldName : startCase(fieldName));

const numberValidation = (fieldName, exactText = false) => Yup.number()
  .transform((cv, ov) => (ov === '' ? undefined : cv))
  .typeError(`${getFieldLabel(fieldName, exactText)} must be a number!`);

const requiredFieldMessage = (fieldName, exactText = false) => `${getFieldLabel(fieldName, exactText)} is required.`;

const numberRequiredValidation = (fieldName, exactText = false) => numberValidation(fieldName, exactText);

const positiveNumberRequiredValidation = (fieldName, exactText = false) => 
  numberRequiredValidation(fieldName, exactText)
  .positive(`${getFieldLabel(fieldName, exactText)} must be a positive number!`);

const selectValidValueFieldMessage = (fieldName, exactText = false) => 
  `Please select vaid ${getFieldLabel(fieldName, exactText)}`;

const zipValidation = Yup.string();

const propertyInformationValidation = Yup.object().shape({
  property_name: Yup.string(),
  no_of_units: positiveNumberRequiredValidation('no_of_units'),
  property_value: positiveNumberRequiredValidation('property_value')
});

const loanInfoValidation = Yup.object().shape({
  property_type: Yup.string(),
  loan_type: Yup.mixed()
    .oneOf(validLoanTypes, selectValidValueFieldMessage('loan_type')),
  loan_purpose: Yup.mixed()
    .oneOf(validLoanPurposes, selectValidValueFieldMessage('loan_purpose')),
  total_cost_basis: positiveNumberRequiredValidation('total_cost_basis')
});

const borrowerInfoValidation = Yup.object().shape({
  loan_name: Yup.string(),
  borrower_name: Yup.string(),
  borrow_type: Yup.mixed()
    .oneOf(validBorrowTypes, selectValidValueFieldMessage('borrow_type')),
  request_loan_amount: positiveNumberRequiredValidation('request_loan_amount')
});

const buildingInfoValidation = Yup.object().shape({
  building_address: Yup.string(),
  zip: zipValidation,
  city: Yup.string(),
  state: Yup.string()
});

const executeLoanInfoValidation = Yup.object().shape({
  property_information: propertyInformationValidation,
  loan_information: loanInfoValidation,
  borrower_information: borrowerInfoValidation,
  building_information: buildingInfoValidation
}).required('Execute Loan Information is required.');

const loadSizingParamValidation = Yup.object().shape({
  "ltv": numberRequiredValidation('ltv', true)
    .min(50, `LTV should be in between 50%-100%`).max(100, `LTV should be in between 50%-100%`),
  "dscr(ncf)": numberRequiredValidation('DSCR (NCF)', true)
    .min(0, `DSCR (NCF) should be in between 0-5`).max(5, `DSCR (NCF) should be in between 0-5`),
  "maximum_ltv": numberRequiredValidation('Maximum LTV', true)
    .min(50, `Maximum LTV should be in between 50%-100%`).max(100, `Maximum LTV should be in between 50%-100%`),
  "loan_term": numberRequiredValidation('loan_term'),
  "debt_yield(noi)": numberRequiredValidation('Debt Yield(NOI)', true),
  "io_period": numberRequiredValidation('io_period'),
  "debt_yield(ncf)": numberRequiredValidation('Debt Yield(NCF)', true)
});

const calculationAssumptionValidation = Yup.object().shape({
  "amortization": numberRequiredValidation('amortization'),
  "index_rate": numberRequiredValidation('index_rate'),
  "interest_rate_type": Yup.mixed()
    .oneOf(validInterestRateTypes, selectValidValueFieldMessage('interest_rate_type')),
  "cf_haircut": numberRequiredValidation('CF Haircut', true),
  "cap_rate": numberRequiredValidation('cap_rate', true),
  "estimated_property_value_criteria": Yup.mixed()
    .oneOf(validEstimatedPropertyValueCriterias, selectValidValueFieldMessage('estimated_property_value_criteria')),
  "inflators": numberRequiredValidation('inflators'),
  "accural_method": Yup.mixed()
    .oneOf(validAccuralMethods, selectValidValueFieldMessage('accural_method')),
  "spread": numberRequiredValidation('spread'),
  "floor": numberRequiredValidation('floor'),
  "uw_income_source": Yup.mixed()
    .oneOf(validUwIncomeSources, selectValidValueFieldMessage('uw_income_source')),
  "physical_vacancy": numberRequiredValidation('physical_vacancy'),
  "uw_vacancy": Yup.string(),
  "uw_vacancy+": numberRequiredValidation('uw_vacancy+')
});


const generalValidation = Yup.object().shape({
  project_name: Yup.string(),
  asset_type: Yup.string(),
  model: Yup.string(),
  review: Yup.mixed()
    .oneOf(reviews, selectValidValueFieldMessage('review'))
    .required(requiredFieldMessage('review'))
});

const servicingPropertyInformationValidation = Yup.object().shape({
  borrower_name: Yup.string(),
  property_name: Yup.string(),
  property_address: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zip: zipValidation,
  year_built: numberRequiredValidation('year_built'),
  year_renovated: numberRequiredValidation('year_renovated')
});

const lenderInformation = Yup.object().shape({
  'SF/Units/Pads/Beds': Yup.string(),
  "lender_loan_number": Yup.string(),
  "original_loan_balance": Yup.string(),
  "funding_date": Yup.string(),
  "interest_rate": numberRequiredValidation('interest_rate')
});

const servicerInformation = Yup.object().shape({
  internal_loan_number: Yup.string(),
  maturity_date: Yup.string(),
  monthly_payment: Yup.string(),
  correspondent_name: Yup.string(),
  correspondent_office: Yup.string()
});

const acquisitionPropertyInfoValidation = Yup.object().shape({
  property_type: Yup.string(),
  property_name: Yup.string(),
  property_address: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zip: zipValidation,
  no_of_units: positiveNumberRequiredValidation('no_of_units'),
  purchase_price: positiveNumberRequiredValidation('purchase_price'),
  year_built: numberRequiredValidation('year_built'),
  year_renovated: numberRequiredValidation('year_renovated'),
  total_sq_ft: positiveNumberRequiredValidation('total_sq_ft'),
});

const acquisitionDealInfoValidation = Yup.object().shape({
  deal_name: Yup.string(),
  hold_period: numberRequiredValidation('hold_period', false)
    .min(0, 'Hold period should be in between 0-10')
    .max(15, 'Hold period should be in between 0-15')
});

export const productionValidationSchema = Yup.object().shape({
  production: Yup.object().shape({
    execute_loan_information: executeLoanInfoValidation,
    loan_sizing_parameters: loadSizingParamValidation,
    calculation_assumption: calculationAssumptionValidation
  })
});

export const servicingValidationSchema = Yup.object().shape({
  servicing: Yup.object().shape({
    general: generalValidation,
    property_information: servicingPropertyInformationValidation,
    lender_information: lenderInformation,
    servicer_information: servicerInformation
  })
});

export const acquisitionValidationSchema = Yup.object().shape({
  acquisition: Yup.object().shape({
    property_information: acquisitionPropertyInfoValidation,
    deal_information: acquisitionDealInfoValidation,
  })
});
