import React, { useState, useCallback } from 'react';
import {
  Form as BSForm, Card, Button, Alert
} from 'react-bootstrap';
import { withServices } from 'reaf';
import clikLogoImg from '../../../assets/images/clikai-logo.svg';
import Link from '../../core/Link/Link';
import useBool from '../../../hooks/useBool';

const AcceptTermsAndConditionsForm = ({ authService }) => {
  const [agreedToTerms, , , toggleAgreement] = useBool(false);
  const [errorMessage, setErrorMessage] = useState('');

  const acceptTermsAndCondition = useCallback(async (event) => {
    event.preventDefault();
    if (agreedToTerms) {
      try {
        setErrorMessage('');
        await authService.acceptTerms();
      } catch (error) {
        const { message } = error;
        setErrorMessage(message);
      }
    }
  }, [agreedToTerms]);

  return (
    <Card className="form-card">
      <Card.Body className="form-card-body">
        <div className="loginFormLogo">
          <img src={clikLogoImg} alt="Clik Logo" />
        </div>
        {
          !!errorMessage &&
          (
            <Alert variant="danger">
              <div>{errorMessage}</div>
            </Alert>
          )
        }
        <BSForm autoComplete="off" onSubmit={acceptTermsAndCondition}>
          <BSForm.Group controlId="agree">
            <BSForm.Check
              checked={agreedToTerms}
              onChange={toggleAgreement}
              type="checkbox"
              label={(
                <span>
                  <small>
                    By clicking Login you agree to our&nbsp;
                    <br />
                    <Link route="termsPage">Terms of Service</Link>
                  &nbsp;and&nbsp;
                    <Link route="privacyPolicyPage">Privacy Policy</Link>
.
                  </small>
                </span>
              )}
            />
          </BSForm.Group>
          <Button
            className="btn btn-primary btn-block"
            type="submit"
            disabled={!agreedToTerms}
          >
            Accept
          </Button>
        </BSForm>
      </Card.Body>
    </Card>
  );
};

export default withServices('authService')(AcceptTermsAndConditionsForm);
