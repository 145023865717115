import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import style from './Footer.module.scss';

function Footer({ isTrial = false }) {
  return (
    <Container fluid className={`text-light bg-dark ${isTrial ? style.trialFooter : `${style.footer}`}`}>
      <Row className={style.footerRow}>
        <Col xs="auto"><span><small>Clik.ai | Your CRE Analyst on Cloud</small></span></Col>
        <Col xs="auto"><span><small>© {moment().format('YYYY')} Clik.ai. All Rights Reserved.</small></span></Col>
      </Row>
    </Container>
  );
}

export default Footer;
