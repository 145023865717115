import React from 'react';
import AppConfigurationList from '../../app/AppConfigurationList/AppConfigurationList';
import SuperAdminDashboard from '../SuperAdminDashboard/SuperAdminDashboard';

function SuperAdminAppConfigurationPage() {
  return (
    <SuperAdminDashboard>
      <AppConfigurationList />
    </SuperAdminDashboard>
  );
}

export default SuperAdminAppConfigurationPage;
