import { createDuck } from 'reaf';

const initialState = {
  prospectsList: [],
  invoiceList: [],
  currentProspectUuid: ''
};

const [
  { setProspectsList, setProspectInvoicesList, setCurrentProspectUuid },
  reducer
] = createDuck('autouw/prospectsList', {
  _initialState: initialState,

  setProspectsList: prospectsList => state => ({
    ...state,
    prospectsList
  }),

  setProspectInvoicesList: invoiceList => state => ({
    ...state,
    invoiceList
  }),

  setCurrentProspectUuid: currentProspectUuid => state => ({
    ...state,
    currentProspectUuid
  })
});

export { setProspectsList, setProspectInvoicesList, setCurrentProspectUuid };

export default reducer;
