import React, { useState, useEffect, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import { withServices } from 'reaf';
import { saveAs } from 'file-saver';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Formik, Form, Field } from 'formik';
import { Spinner } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import {
  currentProjectReportSelector,
  currentProjectSelector,
  loggedInCompanySelector
} from '../../../store/selectors';
import { appendClassName, showPaymentPopup } from '../../../utils/utils';

import SpreadSheet from '../../core/SpreadSheet/SpreadSheet';
import ProjectReportWorkbookManager from './ProjectReportWorkbookManager';
import { jotoForm } from '../../../config';
import ReportSpreadSheet from '../DocExtractionComponent/ReportSpreadSheet';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import ShareReportLinkModal from '../ShareReportLinkModal/ShareReportLinkModal';
import ThankYouIcon from '../../../assets/images/email-verify-icon.png';
import styles from './ProjectReport.module.scss';
import {
  DocumentsDownloadedOnReportPage,
  DownloadWorkbookClickedOnReportPage,
  OTPSent
} from '../../../constants/eventTrackerMessage';
import { useFeatureFlag } from '../../../providers/FeatureFlagProvider';
import ProjectReportSkeletonLoader from './ProjectReportSkeletonLoader';

const { formId, apiKey, emailFieldId } = jotoForm;

const saveEmailToJotoForm = async ({ email }) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append(`submission[${emailFieldId}]`, email);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
  };

  return fetch(
    `https://api.jotform.com/form/${formId}/submissions?apiKey=${apiKey}`,
    requestOptions
  ).then((response) => response.json());
};

const useCountdownTimer = (startTime) => {
  const [timeLeft, setTimeLeft] = useState(startTime);
  const startTimer = (onTimerEnd) => {
    setTimeLeft(startTime);
    const interval = setInterval(() => {
      console.log(timeLeft, timeLeft - 1);
      setTimeLeft((timeLeft) => {
        const newTimeLeft = timeLeft - 1;
        if (newTimeLeft === 0) {
          clearInterval(interval);
          onTimerEnd();
        }
        return newTimeLeft;
      });
    }, 1000);
  };
  return { timeLeft, startTimer };
};
const DownloadReportDocuments = ({
  show,
  onHide,
  projectsService,
  documentsService,
  projectUuid,
  reportUuid,
  companyUuid,
  xlsxBlob,
  project,
  authService,
  captureEmailService,
  eventTrackerService,
  canDownloadDocument = false
}) => {
  const title = 'Verify email to download files';
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const { timeLeft, startTimer } = useCountdownTimer(30);

  const otpFormValidation = useMemo(
    () =>
      isOTPSent
        ? Yup.object().shape({
            email: Yup.string()
              .email('Please provide a valid email.')
              .required('Please provide a valid email'),
            otp: Yup.string()
              .required('OTP is required!')
              .matches(/^[0-9]+$/, 'Must be only digits')
          })
        : Yup.object().shape({
            email: Yup.string()
              .email('Please provide a valid email.')
              .required('Please provide a valid email')
          }),
    [isOTPSent]
  );

  const onDownloadDocument = useCallback(async () => {
    if (canDownloadDocument) {
      await projectsService
        .getPublicDocumentsList(reportUuid, projectUuid)
        .then((documents) => {
          const documentIds = uniqBy(
            documents.filter((document) => document.groupId),
            'groupId'
          ).map((document) => document.groupId);
          setIsEmailVerified(true);
          return documentsService.downloadWorkbookWithDocumentsZip({
            reportUuid,
            project,
            companyUuid,
            xlsxBlob,
            documents: documents.filter((document) =>
              documentIds.includes(document.uuid)
            ),
            canDownloadDocument
          });
        });
    } else {
      const fileName = `${project.name}-report.xlsx`;
      saveAs(xlsxBlob, fileName);
    }

    eventTrackerService.track(DocumentsDownloadedOnReportPage, {
      canDownloadDocument,
      ...project,
      reportUuid
    });
  }, [
    documentsService,
    reportUuid,
    projectUuid,
    companyUuid,
    xlsxBlob,
    project,
    canDownloadDocument
  ]);

  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        actions.setStatus({});
        if (isOTPSent) {
          await authService.verifyEmailOTP(values);
          await saveEmailToJotoForm(values);
          await onDownloadDocument();
          captureEmailService.updateCapturedEmail({
            ...values,
            isVerified: true
          });
          onHide();
        } else {
          setIsResendButtonDisabled(true);
          await authService.sendVerifyEmailOTP(values);
          captureEmailService.captureEmail({
            ...values,
            reportLinkUuid: reportUuid
          });
          eventTrackerService.track(OTPSent, {
            ...values
          });
          startTimer(() => setIsResendButtonDisabled(false));
          setIsOTPSent(true);
        }
      } catch (error) {
        actions.setStatus({ error: error.message });
      }
      actions.setSubmitting(false);
    },
    [isOTPSent, onDownloadDocument, reportUuid]
  );

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title title={title}>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isEmailVerified ? (
          <div className={styles.thankYouWrapper}>
            <div className="icon">
              <img
                src={ThankYouIcon}
                className="rounded mx-auto d-block w-33"
                alt="Thank you"
              />
            </div>
            <div className="text-center mt-2">
              <h5>Your email is successfully verified</h5>
              <p className="text-muted">
                Please wait, while are preparing for download
              </p>
            </div>
          </div>
        ) : (
          <Formik initialValues={{email: ''}} validationSchema={otpFormValidation} onSubmit={onSubmit}>
            {({
              setFieldValue,
              values,
              isValid,
              status,
              isSubmitting,
              setStatus
            }) => (
              <Form>
                {status && status.error && (
                  <div className="alert alert-danger p-2 mb-3">
                    <small>{status && status.error}</small>
                  </div>
                )}
                {status && status.message && (
                  <div className="alert alert-success p-2 mb-3">
                    <small>{status && status.message}</small>
                  </div>
                )}
                {isOTPSent ? (
                  <div className="border-bottom pb-2">
                    <span>{values['email']}</span>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        setStatus({});
                        setIsOTPSent(false);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                ) : (
                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <Field
                      id="email"
                      className="form-control"
                      name="email"
                      type="email"
                    />
                    <FieldErrorMessage name="email" />
                  </div>
                )}

                {isOTPSent && (
                  <div className="mt-4 mb-5">
                    {/* <label htmlFor="otp">OTP</label> */}
                    <OtpInput
                      inputStyle={appendClassName(
                        'form-control',
                        styles['otpField']
                      )}
                      containerStyle={styles['otpContainer']}
                      onChange={(otp) => {
                        setStatus({});
                        setFieldValue('otp', otp);
                      }}
                      value={values['otp']}
                      separator=" "
                      isInputNum
                    />
                    <FieldErrorMessage name="otp" />
                  </div>
                )}

                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div>
                    {isSubmitting ? (
                      <Button
                        type="button"
                        disabled
                        className="w-33 btn btn-primary"
                      >
                        {' '}
                        <Spinner animation="border" size="sm" />{' '}
                        {isOTPSent ? 'Verify OTP' : 'Send OTP'}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        className="w-33 btn btn-primary"
                      >
                        {isOTPSent ? 'Verify OTP' : 'Send OTP'}
                      </Button>
                    )}
                  </div>
                  <div className="d-flex flex-row align-items-center m-1">
                    {isOTPSent && (
                      <div className="d-flex flex-row align-items-center justify-content-between text-muted">
                        <small>Didn&rsquo;t recieve yet?</small>{' '}
                        <button
                          className="btn btn-link p-1"
                          type="button"
                          disabled={isResendButtonDisabled}
                          onClick={() => {
                            setIsResendButtonDisabled(true);
                            authService.sendVerifyEmailOTP(values).then(() => {
                              setStatus({ message: 'A new OTP has been sent' });
                              startTimer(() =>
                                setIsResendButtonDisabled(false)
                              );
                            });
                          }}
                        >
                          Resend {!timeLeft && 'Now'}
                        </button>
                        {isOTPSent && timeLeft !== 0 && (
                          <small>in {timeLeft}s</small>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
};

function ProjectReport({
  project,
  publicProject,
  reports,
  store,
  toastrService,
  apiClient,
  currentCompany,
  projectsService,
  authService,
  companyUuid,
  projectUuid,
  templateUuid,
  modelUuid,
  selectedDocuments,
  isOpen,
  reportUuid,
  documentsService,
  canDownloadDocument = false,
  isGlobalReport = false,
  captureEmailService,
  projectReportService,
  canDownloadReportWorkbook: canDownloadReportWorkbookGlobal,
  eventTrackerService
}) {
  const [manager, setManager] = useState(null);
  const [reportData, setReportData] = useState(null)
  const [showReportLinkModal, setShowReportLinkModal] = useState(false);
  const [reportLink, setReportLink] = useState('');
  const [showDownloadDocumentPopup, setShowDownloadDocumentPopop] =
    useState(false);
  const [xlsxBlob, setXlsxBlob] = useState();
  const [canUseSpreadV2, isLoading] = useFeatureFlag('spreadV2', false);

  const createManager = useCallback(async (wbManager) => {
    await fetchReportData()
    setManager(
      new ProjectReportWorkbookManager(wbManager, {
        projectUuid,
        templateUuid,
        apiClient,
        modelUuid,
        selectedDocuments,
        companyUuid: companyUuid,
        reportUuid,
        isOpen
      })
    );
  }, []);

  useEffect(() => {
    manager && manager.updateOptions({ store, toastrService });
  }, [manager, store, toastrService]);

  useEffect(() => {
    if (manager) {
      manager.setReportData(store, reportData);
    }
  }, [manager, store, reportData]);

  const onReportSave = useCallback(async () => {
    const reportData = manager?.workbookManager?.getWorkbookData();
    await projectReportService.saveReportData(project, reportData);
  }, [manager, project])

  const downloadXLS = useCallback(
    (blob) => {
      const fileName = `${project.name || publicProject.name}-report.xlsx`;
      if (isGlobalReport) {
        setXlsxBlob(blob);
        setShowDownloadDocumentPopop(true);
      } else {
        saveAs(blob, fileName);
      }
      eventTrackerService.track(DownloadWorkbookClickedOnReportPage, {
        isGlobalReport,
        isFromSharedReportLink: isGlobalReport,
        project,
        publicProject,
        fileName
      });
    },
    [isGlobalReport]
  );

  const onModalHide = useCallback(() => {
    setShowReportLinkModal(false);
  }, []);

  const onShowReportLink = useCallback(async () => {
    if (isOpen) {
      setReportLink({ reportLink: window.location.href });
    } else {
      setReportLink(await projectsService.createReportLink(project.uuid));
    }
    setShowReportLinkModal(true);
  }, [isOpen, window]);
  const canDownloadReportWorkbook = isGlobalReport
    ? canDownloadReportWorkbookGlobal
    : !showPaymentPopup(currentCompany, 'canDownloadReportWorkbook');

  const fetchReportData = useCallback(async () => {
    const data = await projectReportService.getReportData(projectUuid, reportUuid, isOpen);
    setReportData(data);
  }, [projectUuid, reportUuid, isOpen])

  if (isLoading) {
    return null;
  }

  return (
    <div className="vertical-section position-relative">
      {canUseSpreadV2 ? (
        <ReportSpreadSheet
          showToolbar
          isGlobalReport={isGlobalReport}
          sheetCount={1}
          store={store}
          company={currentCompany}
          reports={reports}
          project={project}
          projectUuid={projectUuid}
          templateUuid={templateUuid}
          apiClient={apiClient}
          modelUuid={modelUuid}
          companyUuid={companyUuid}
          isOpen={isOpen}
          reportUuid={reportUuid}
          canShareReport={currentCompany.canShareReport}
          canDownloadReportWorkbook={canDownloadReportWorkbook}
          onShowReportLink={onShowReportLink}
        />
      ) : (
        <SpreadSheet
          showToolbar
          isProjectReport
          sheetCount={1}
          isGlobalReport={isGlobalReport}
          canDownloadDocument={canDownloadDocument}
          canShareReport={currentCompany.canShareReport}
          canDownloadReportWorkbook={canDownloadReportWorkbook}
          onInitManager={createManager}
          onShowReportLink={onShowReportLink}
          onDownloadXLS={downloadXLS}
          onReportSave={onReportSave}
          company={currentCompany}
          setShowDownloadDocumentPopop={setShowDownloadDocumentPopop}
        />
      )}
      <ProjectReportSkeletonLoader/>
      {showDownloadDocumentPopup && (
        <DownloadReportDocuments
          show={showDownloadDocumentPopup}
          onHide={() => setShowDownloadDocumentPopop(false)}
          {...{
            projectsService,
            authService,
            documentsService,
            project: publicProject,
            companyUuid,
            reportUuid,
            projectUuid,
            xlsxBlob,
            canDownloadDocument,
            captureEmailService,
            eventTrackerService
          }}
        />
      )}

      {isGlobalReport ? (
        <>
          {' '}
          {showReportLinkModal && (
            <ConfirmationModal
              show={showReportLinkModal}
              title={
                isOpen ? 'Report Link' : `Report link for  ${project.name}`
              }
              message={reportLink.reportLink}
              isConfirm={false}
              isClipboard={showReportLinkModal}
              onConfirm={() => onModalHide()}
              onCancel={() => onModalHide()}
            />
          )}
        </>
      ) : (
        <>
          {showReportLinkModal && (
            <ShareReportLinkModal
              show={showReportLinkModal}
              sharedReportLinkDetail={reportLink}
              title={`Report link for  ${project.name}`}
              message={reportLink.reportLink}
              isConfirm={false}
              isClipboard={showReportLinkModal}
              onConfirm={() => onModalHide()}
              onCancel={() => onModalHide()}
            />
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  project: currentProjectSelector(state),
  reports: currentProjectReportSelector(state),
  currentCompany: loggedInCompanySelector(state)
});

export default connect(mapStateToProps)(
  withServices(
    'store',
    'apiClient',
    'toastrService',
    'projectsService',
    'documentsService',
    'authService',
    'captureEmailService',
    'eventTrackerService',
    'projectReportService',
  )(ProjectReport)
);
