/* eslint-disable import/no-cycle */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/aria-role */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import SpreadSheetToolbarButton from './SpreadSheetToolbarButton';
import styles from '../SpreadSheets.module.scss';
import { FormatMenu } from './FormatMenu';
import ColorPicker from './ColorPicker';

export const ACTIONS = {
  UNDO: 0,
  REDO: 1,

  FREEZE: 2,
  UNFREEZE: 3,

  SORT_ASCENDING: 4,
  SORT_DESCENDING: 5,

  FILL_CELL: 6,
  TEXT_COLOR: 7,

  TEXT_BOLD: 8,
  TEXT_ITALIC: 9,
  TEXT_UNDERLINE: 10,

  PAINT_FORMAT: 11,
  FILTER: 12,

  ALIGN_LEFT: 13,
  ALIGN_CENTER: 14,
  ALIGN_RIGHT: 15,

  FORMAT_CELL: 16
};


export function SpreadSheetToolbar(
  {
    onAction,
    className = '',
    customToolbarButtons = null,
    customRightChild = null,
    paintFormatActive = false
  }
) {
  const [showFillCellColor, setShowFillCellColor] = useState(false);
  const [showTextCellColor, setShowTextCellColor] = useState(false);
  const colorPopUpWrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutsideForColorPopUp(event) {
      if (colorPopUpWrapperRef.current && !colorPopUpWrapperRef.current.contains(event.target)) {
        setShowFillCellColor(false);
        setShowTextCellColor(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutsideForColorPopUp);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutsideForColorPopUp);
    };
  }, [colorPopUpWrapperRef]);


  const onColorChange = useCallback((action, color) => {
    onAction(action, { color });
    action === ACTIONS.FILL_CELL ?
      setShowFillCellColor(!showFillCellColor) :
      setShowTextCellColor(!showTextCellColor)
  }, [showFillCellColor, showTextCellColor])

  return (
    <Row>
      <Col>
        <div className={`${styles.spreadsheetsContainer}`}>
          <div className={`${styles.spreadsheetToolbarWrapper}`}>
            <div className={`${styles.spreadsheetToolbar}`}>
              <SpreadSheetToolbarButton className={`${styles.undoIcon}`} title="Undo" action={ACTIONS.UNDO} onClick={onAction} />
              <SpreadSheetToolbarButton className={`${styles.redoIcon}`} title="Redo" action={ACTIONS.REDO} onClick={onAction} />
              <div className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`} aria-disabled="true" role="separator line"> &nbsp; </div>
              <SpreadSheetToolbarButton className={`${styles.lockIcon}`} title="Freeze Rows/Columns" action={ACTIONS.FREEZE} onClick={onAction} />
              <SpreadSheetToolbarButton className={`${styles.unlockIcon}`} title="Unfreeze Rows/Columns" action={ACTIONS.UNFREEZE} onClick={onAction} />
              <div className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`} aria-disabled="true" role="separator line"> &nbsp; </div>
              <SpreadSheetToolbarButton className={`${styles.ascendingIcon}`} title="Sort Ascending" action={ACTIONS.SORT_ASCENDING} onClick={onAction} />
              <SpreadSheetToolbarButton className={`${styles.descendingIcon}`} title="Sort Descending" action={ACTIONS.SORT_DESCENDING} onClick={onAction} />
              <div className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`} aria-disabled="true" role="separator line"> &nbsp; </div>
              <div className={`position-relative d-inline-block ${styles.docsToolbarButton}`}>
                <FormatMenu onSelect={onAction} />
              </div>
              <div className="position-relative d-inline-block">
                <SpreadSheetToolbarButton className={`${styles.fillCellColorIcon}`} title="Fill Cell Color" onClick={() => setShowFillCellColor(!showFillCellColor)} />
                {
                  showFillCellColor && 
                  <div className={styles.colorPopUp} ref={colorPopUpWrapperRef}>
                    <ColorPicker onChangeComplete={(color) => onColorChange(ACTIONS.FILL_CELL, color)} />
                  </div>
                }
              </div>

              <div className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`} aria-disabled="true" role="separator line"> &nbsp; </div>
              <SpreadSheetToolbarButton className={`${styles.boldIcon}`} title="Bold" action={ACTIONS.TEXT_BOLD} onClick={onAction} />
              <SpreadSheetToolbarButton className={`${styles.italicIcon}`} title="Italic" action={ACTIONS.TEXT_ITALIC} onClick={onAction} />
              <SpreadSheetToolbarButton className={`${styles.underlineIcon}`} title="Underline" action={ACTIONS.TEXT_UNDERLINE} onClick={onAction} />
              <div className="position-relative d-inline-block">
                <SpreadSheetToolbarButton className={`${styles.textColorIcon}`} title="Text Color" onClick={() => setShowTextCellColor(!showTextCellColor)} />
                {
                  showTextCellColor && 
                  <div className={styles.colorPopUp} ref={colorPopUpWrapperRef}>
                    <ColorPicker onChangeComplete={(color) => onColorChange(ACTIONS.TEXT_COLOR, color)} />
                  </div>
                }
              </div>
              <div className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`} aria-disabled="true" role="separator line"> &nbsp; </div>
              <SpreadSheetToolbarButton className={paintFormatActive ? `${styles.paintIcon} text-warning` : `${styles.paintIcon} text-auto`} title="Paint Format" action={ACTIONS.PAINT_FORMAT} onClick={onAction} />
              <SpreadSheetToolbarButton className={`${styles.autofilterIcon}`} title="Apply Row Filter" action={ACTIONS.FILTER} onClick={onAction} />
              <div className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`} aria-disabled="true" role="separator line"> &nbsp; </div>
              <SpreadSheetToolbarButton className={`${styles.alignLeftIcon}`} title="Align Left" action={ACTIONS.ALIGN_LEFT} onClick={onAction} />
              <SpreadSheetToolbarButton className={`${styles.alignCenterIcon}`} title="Align Center" action={ACTIONS.ALIGN_CENTER} onClick={onAction} />
              <SpreadSheetToolbarButton className={`${styles.alignRightIcon}`} title="Align Right" action={ACTIONS.ALIGN_RIGHT} onClick={onAction} />
              <div className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`} aria-disabled="true" role="separator line"> &nbsp; </div>
              {customToolbarButtons && (
                <>
                  <div className="position-relative d-inline-block">
                    {customToolbarButtons}
                  </div>
                  <div className={`position-relative d-inline-block ${styles.spreadsheetToolbarSeparator}`} aria-disabled="true" role="separator line"> &nbsp; </div>
                </>
              )}
            </div>
            <div>
              {customRightChild}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

SpreadSheetToolbar.propTypes = {
  className: PropTypes.string,
  onAction: PropTypes.func.isRequired,
  customToolbarButtons: PropTypes.node,
  customRightChild: PropTypes.node,
  paintFormatActive: PropTypes.bool
};

