import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withServices } from 'reaf';
import FeatureFlagFormComponent from './FeatureFlagFormComponent';

const FeatureFlagFormSchema = Yup.object().shape({
    featureName: Yup.string().trim().transform(value => (value === '' ? undefined : value)).required('Please enter feature flag name'),
});

const initialValue = {
  featureName: '',
  isActive: false,
}

function FeatureFlagForm({
  onSubmitSuccess, bindSubmission, featureFlagService, toastrService
}) {
  async function onSubmit(values, actions) {
    actions.setSubmitting(true);
    await featureFlagService.createFeatureFlag(values);
    onSubmitSuccess();
    toastrService.success('Feature flag created successfully')
    actions.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={FeatureFlagFormSchema}
      onSubmit={onSubmit}
      component={formikProps => <FeatureFlagFormComponent {...formikProps} bindSubmission={bindSubmission} />
      }
    />
  );
}

export default withServices('featureFlagService', 'toastrService')(FeatureFlagForm);
