import React from 'react';
import LoggedOutUserPage from '../LoggedOutUserPage/LoggedOutUserPage';
import PrivacyPolicy from '../../app/PrivacyPolicy/PrivacyPolicy';

function PrivacyPage() {
  return (
    <LoggedOutUserPage>
      <PrivacyPolicy style={{}}/>
    </LoggedOutUserPage>
  );
}

export default PrivacyPage;
