import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import DocExtractionChargeCodeConfig from '../DocExtractionChargeCodeConfig/DocExtractionChargeCodeConfig';
import DocExtractionUnitStatusConfig from '../DocExtractionUnitStatusConfig/DocExtractionUnitStatusConfig';
import DocExtractionFloorPlanConfig from '../DocExtractionFloorPlanConfig/DocExtractionFloorPlanConfig';
import DocExtractionAffixAnalysis from '../DocExtractionAffixAnalysis/DocExtractionAffixAnalysis';
import './DocExtractionRentRollConfigComponent.scss';
import DocExtractionUnitMixConfig from '../DocExtractionUnitMixConfig/DocExtractionUnitMixConfig';

function DocExtractionRentRollMFConfig({
  onRentRollConfigSubmit, onSlidePaneClose, onMergeChargeCodeColumn, activeTabKey = 'chargeCode', isAcquisition, setActiveTabKey
}) {
  const [activeTab, setActiveTab] = useState(activeTabKey);

  return (
    <>
      <div className="projectSlideTabPanel affixVerticalTabs">
        <Tab.Container id="mf-rr" defaultActiveKey="chargeCode" activeKey={activeTab}
          onSelect={(tabName) => { 
            if (tabName !== activeTabKey) {
              setActiveTabKey(tabName)
              setActiveTab(tabName)
            } else {
              onSlidePaneClose()
            }
          }}
          unmountOnExit>
            <Tab.Content>
              <Tab.Pane eventKey="chargeCode">
                <DocExtractionChargeCodeConfig 
                  onConfigSubmit={() => onRentRollConfigSubmit('mfChargeCode')} 
                  onSlidePaneClose={onSlidePaneClose} 
                  onMergeChargeCodeColumn={(colRef) => onMergeChargeCodeColumn(colRef)}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="unitStatus">
                <DocExtractionUnitStatusConfig   
                  onConfigSubmit={() => onRentRollConfigSubmit()} 
                  onSlidePaneClose={onSlidePaneClose} 
                />
              </Tab.Pane>
              <Tab.Pane eventKey="floorPlan">
                <DocExtractionFloorPlanConfig 
                  isAcquisition={isAcquisition}
                  onConfigSubmit={() => onRentRollConfigSubmit()}
                  onSlidePaneClose={onSlidePaneClose} 
                />
              </Tab.Pane>
              <Tab.Pane eventKey="unitMix">
                <DocExtractionUnitMixConfig
                  isAcquisition={isAcquisition}
                  onConfigSubmit={() => onRentRollConfigSubmit()}
                  onSlidePaneClose={onSlidePaneClose} 
                />
              </Tab.Pane>
              <Tab.Pane eventKey="analysis">
                <DocExtractionAffixAnalysis />
              </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
}

export default DocExtractionRentRollMFConfig;
