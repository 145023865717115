
import React, { useEffect, useState, useCallback } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import { withServices } from 'reaf';
import Button from 'react-bootstrap/Button';
import style from './SubscriptionForm.module.scss';
import useModalForm from '../../../hooks/useModalForm';
import { appendClassName } from '../../../utils/utils';
import StripePaymentForm from './StripePaymentForm';
import clikLogoImg from '../../../assets/images/clikai-logo.svg';



const PriceValidation = Yup.object().shape({
  price: Yup.string()
    .trim().transform(value => (value === '' ? undefined : value)).required('Please provide a valid price.')
});

const ProFeature = () => (
 <div className="text-left">
  <h5 className="m-0">Clik.ai Auto Underwriting</h5>
  <span className="text-muted">Please select a subscription plan.</span>
</div>
);

const CardDetails = () => (
  <div className="text-left">
   <h5 className="m-0">Clik.ai Auto Underwriting</h5>
   <span className="text-muted">Please enter your card details</span>
 </div>
 );

const PaymentFormComponent = ({
  message,
  prices,
  values,
  isSubmitting,
  isValid,
  handleSubmit,
}) => (
    <>
      <div className="mb-3 mr-2"> 
        <div className="text-center mt-2">
          {message}
          {
            (
              <Form onSubmit={handleSubmit}>
                <div className="row px-2 flex-column mt-3">
                  {
                    prices.map((paymentMethod) => {
                      const { title, discount, plan } = paymentMethod.metadata;
                    return (
                      <div key={paymentMethod.id} className={`${values.price === paymentMethod.id ? style.activePaymentOption : ''} col cursor-pointer p-0 mx-1 text-left card shadow-sm my-1`}>
                        <label className="p-2 d-flex align-middle align-items-center mb-0" htmlFor={paymentMethod.id}>
                          <Field type="radio" name="price" id={paymentMethod.id} value={paymentMethod.id} disabled={isSubmitting}/>
                          <div className="container d-flex justify-content-between">
                            <div className="p-0">
                              <div className="d-flex align-items-baseline">
                                {title}
                              </div>
                            </div>

                            <div className={appendClassName("card-header border-0 p-0 bg-white", style.discount)}>
                              {discount}
                            </div>
                            <div className={appendClassName("card-header border-0 p-0 bg-white text-right", style.price)}>
                            {plan}
                           </div>
                          </div>

                        

                        </label>
                      </div>
                    )})
                  }
                </div>
              </Form>
            )
          }

        </div>
      </div>
      <Button variant="primary" type="submit" disabled={isSubmitting || !isValid} onClick={handleSubmit}>
        {!isSubmitting ? "Get Started" : <Spinner  as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
      </Button>
    </>
  );


const SubscriptionForm = ({ paymentService, toastrService }) => {
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingPrices, setIsFetchingPrices] = useState(true);

  const handleClose = useCallback(() => {
    setShowPaymentForm(false);
  }, [subscriptionId]);

  const [isSubmitting, bindSubmission, onSubmit] = useModalForm();
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    setIsFetchingPrices(true);
    paymentService.listPrices().then(({ response: prices }) => {
      setPrices(prices);
    }).catch(console.log).finally(() => {
      setIsFetchingPrices(false);
    });
  }, []);

  const message = <ProFeature />;

  const handleSubmit = useCallback(({ price }, actions) => paymentService.createSubscription(price)
    .then(({ response: { clientSecret, subscriptionId } }) => {
      setClientSecret(clientSecret);
      setSubscriptionId(subscriptionId);
      setShowPaymentForm(true);
      actions.setSubmitting(false);
    })
    .catch(() => {
      const errorMessage = 'Payment can\'t be process, Please check card details.';
      actions.setStatus({ error: errorMessage });
      actions.setSubmitting(false);
      toastrService.error(errorMessage)
    }), []);

  const handleSuccess = useCallback((paymentIntent) => {
    setIsLoading(true);
    paymentService.validatePayment(paymentIntent, subscriptionId, true).then(() => {
      handleClose();
      setIsLoading(false);
    });
  }, [subscriptionId]);

  const handleCancelPayment = useCallback(() => {
    setShowPaymentForm(false);
    subscriptionId && paymentService.deleteSubscription(subscriptionId);
  }, [subscriptionId]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center flex-column">
        <Spinner as="span" variant="secondary" animation="border" size="lg" role="status" aria-hidden="true" />
        <h6 className="mt-3">Please wait, while we are preparing your dashboard</h6>
      </div>
  )}

  if (isFetchingPrices) {
    return (
      <div className="d-flex justify-content-center align-items-center flex-column">
        <Spinner as="span" variant="secondary" animation="border" size="lg" role="status" aria-hidden="true" />
        <h6 className="mt-3">Please wait, while we are fetching price details</h6>
      </div>
    );
  }

  return (
    <>
       <div className="loginFormLogo">
          <img src={clikLogoImg} alt="Clik Logo" />
        </div>
      {
        showPaymentForm && clientSecret
        ? (
          <>
            <CardDetails/>
            <StripePaymentForm 
              formWrapperClass={style.cardDetailWrapper}
              clientSecret={clientSecret} 
              onSuccess={handleSuccess} 
              onCancel={handleCancelPayment} 
            />
          </>
        )
        : (
        <>  <Formik
              initialValues={{}}
              validationSchema={PriceValidation}
              onSubmit={handleSubmit}
              component={formikProps => <PaymentFormComponent
                  {...formikProps}
                  message={message}
                  bindSubmission={bindSubmission}
                  prices={prices}
                  handleClose={handleClose}
                />}
            />
          </>
        )
      }
    </>
  )
};

export default withServices('paymentService', 'toastrService')(React.memo(SubscriptionForm, () => true));
