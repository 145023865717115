import React from 'react';
import Switch from 'react-switch';

const ToggleOption = ({
  label, isActive, className, onClick, activeColor
}) => (
  <span
    style={{ cursor: 'pointer' }}
    className={`${className} ${isActive ? activeColor : 'text-muted'}`}
    onClick={onClick}>
    {label}
  </span>
);

const SwitchButton = ({
  isActive, onChange, offLabel, onLabel, isDisabled = false, offLabelColor
}) => (
  <>
    <ToggleOption
      label={offLabel}
      isActive={!isActive}
      activeColor={offLabelColor ? offLabelColor : 'text-error'}
      onClick={() => onChange(!isActive)}
      className="mr-1" />
    <Switch
      className="toggle-switch"
      checked={isActive}
      onChange={onChange}
      width={36}
      height={16}
      handleDiameter={12}
      offColor="#c8063c"
      onColor="#007bfe"
      checkedIcon={false}
      uncheckedIcon={false}
      disabled={isDisabled}
    />
    <ToggleOption
      label={onLabel}
      activeColor='text-primary'
      isActive={isActive}
      onClick={() => onChange(!isActive)}
      className="ml-1" />
  </>
);

export default SwitchButton;
