import React from 'react';
import { withServices } from 'reaf';
import DisplayAssetType from '../DisplayAssetType/DisplayAssetType';
import { ProjectDocumentsPageLink } from '../../core/adminLinks/links';
import DisplayDate from '../../core/DisplayDate/DisplayDate';
import WithTooltip from '../../core/WithTooltip/WithTooltip';
import ReactTable, { useReactTable } from '../../core/ReactTable/ReactTable';
import { createdAtFilter, UpdateAtFilter } from '../../core/ReactTableFilters/ReactTableFilters';

const RecentProjectsComponent = ({ queries, apiClient, router, projectsService }) => {
  const {
    data: projects,
    loading,
    pageCount,
    setData: setProjects,
    onDownloadCSV,
    onSendCSV,
    fetchData: fetchProjects,
  } = useReactTable({
    queries,
    apiClient,
    router,
    projectsService,
    routeName: "recentProjects",
    resourceEndPoint: "/projects/recent",
    csvSendEndPoint: '/projects/recent/sendCsvMail',
    csvDownloadEndPoint: "/projects/recent/downloadCSV",
  });


  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      fixedWidth: 'auto'
    },
    {
      Header: 'Owner',
      accessor: 'Users.firstName',
      fixedWidth: 180,
      Cell: ({ row: { original: { Users } } }) => (Users && Users[0] ? <WithTooltip tooltipText={Users[0].email}><span>{`${Users[0].firstName} ${Users[0].lastName}`}</span></WithTooltip> : 'Deleted')
    },
    {
      Header: 'Company',
      accessor: 'Company.name',
      fixedWidth: 180,
      Cell: ({ row: { original: { Company } } }) => (Company ? `${Company.name}` : 'Deleted')
    },
    {
      Header: 'Asset',
      accessor: 'assetType',
      fixedWidth: 180,
      Cell: ({ value: assetType }) => <DisplayAssetType AssetType={assetType} />
    },
    {
      Header: 'Template',
      fixedWidth: 180,
      accessor: 'TemplateMapping.templateName',
      Cell: ({ row }) => <span>{row.original.TemplateMapping.templateName} </span>
    },
    {
      Header: 'Updated From',
      accessor: 'updatedFrom',
      disableSortBy: true,
      Filter: createdAtFilter,
      Cell: ({ row }) => <DisplayDate date={row.original.updatedAt} />
    },
    {
      Header: 'Updated To',
      accessor: 'updatedTo',
      disableSortBy: true,
      Filter: createdAtFilter,
      Cell: ({ row }) => <DisplayDate date={row.original.updatedAt} />
    },
    {
      Header: 'Actions',
      accessor: 'action',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { original: project } }) => (
        (
          <div className="d-flex flex-row">
            <ProjectDocumentsPageLink title="List Documents" text="Documents" route="recentTaggedDocuments" project={project} />
          </div>
        )
      )
    }
  ], [projects]);

  return <ReactTable
    columns={columns}
    data={projects}
    title="Recent Projects"
    onDownloadCSV={onDownloadCSV}
    onSendCSV={onSendCSV}
    queries={queries}
    loading={loading}
    fetchData={fetchProjects}
    pageCount={pageCount}
  />
};

export default withServices('apiClient', 'router', 'projectsService')(RecentProjectsComponent);
