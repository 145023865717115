/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */

import BaseCommand from './BaseCommand';

class PasteFormatCommand extends BaseCommand {
  run(spreadsheet, options) {
    const { copiedFormat, activeSheetIndex, selections } = options;

    for (const selection of selections) {
      const { row, rowCount, col, colCount } = spreadsheet.getCellRangeFromSelection(undefined, selection);
      for (let r = row; r < row + rowCount; r++) {
        for (let c = col; c < col + colCount; c++) {
          spreadsheet.setCellStyle(activeSheetIndex, r, c, copiedFormat.clone());
        }
      }
    }
  }
}

const pasteFormatCommand = PasteFormatCommand.get();

export default pasteFormatCommand;
